import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const getAdminWalletBalance = createAsyncThunk(
	'general/getAdminWalletBalance',
	async (userId: any) => {
		const res = await baseuri.get('/admin/wallets/user-wallet-balance', {
			params: { userId },
		});
		return res.data.data;
	}
);

export const updateAdminProfile = createAsyncThunk(
	'general/updateAdminProfile',
	async (data: any) => {
		await baseuri.put('/user/profile', data);
	}
);

export const retrieveCustodialWallets = createAsyncThunk(
	'general/retrieveCustodialWallets',
	async () => {
		const res = await baseuri.get('/admin/custodialWallet/get-addresses');
		return res.data.data;
	}
);

export const addCustodialWallet = createAsyncThunk(
	'general/addCustodialWallet',
	async (data: any) => {
		const res = await baseuri.post(
			'/admin/custodialWallet/create-address',
			data
		);
		return res.data.data;
	}
);
export const removeCustodialWallet = createAsyncThunk(
	'general/remvoeCustodialWallet',
	async (addressId: any) => {
		const res = await baseuri.delete('/admin/custodialWallet/delete-address', {
			params: { addressId },
		});
		return res.data.data;
	}
);

export const updateAdminProfileImage = async (
	data: any,
	onSuccess: Function,
	onError: Function
) => {
	try {
		await baseuri.put('/user/profile', data);
		onSuccess('OK');
	} catch (e) {
		onError(e);
	}
};

export const getTodaysApy = createAsyncThunk(
	'general/getTodaysApy',
	async () => {
		const res = await baseuri.get('/data/app/apy/daily/today');
		return res.data.data;
	}
);

export const setAppApy = createAsyncThunk(
	'general/setAppApy',
	async (data: any) => {
		const res = await baseuri.post(
			'/admin/savings/stable-currency/apy/set-today-apr',
			data
		);
		return res.data.data;
	}
);

export const getGlobalEnumsData = createAsyncThunk(
	'general/getGlobalEnumsData',
	async () => {
		const res = await baseuri.get('/data/app/enum/global');
		return res.data.data;
	}
);

const generalRx = createSlice({
	name: 'general',
	initialState: {
		todaysApy: {
			apy: 0,
			updatedAt: '',
			lastUpdatedByUserId: {
				email: '',
				username: '',
			},
		},
		todayApyArray: [],
		loading: false,
		cryptoBalances: [],
		fiatBalances: [],
		walletAddress: '',
		custodialWallets: {
			itemsList: [],
		},
		loadingActivities: [],
		globalEnums: {
			TRANSACTION_STATEMENT_FORM: {},
			GAMES_ENUM: {
				reward: {},
				form: {},
			},
		},
	},
	reducers: {
		addToLoadingQueue: (state, action) => {
			const store = state;
			const listOfLoadingIds: any = store.loadingActivities;

			if (!listOfLoadingIds.includes(action.payload)) {
				return {
					...store,
					loadingActivities: listOfLoadingIds.concat(action.payload),
				};
			}
			return store;
		},
		removeFromLoadingQueue: (state, action) => {
			const store = state;
			const listOfLoadingIds: any = store.loadingActivities;

			return {
				...store,
				loadingActivities: listOfLoadingIds.filter(
					(x: any) => x !== action.payload
				),
			};
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(getAdminWalletBalance.fulfilled, (store, action: any) => {
				let state = store;
				state = { ...state, ...action.payload };
				return state;
			})
			.addCase(retrieveCustodialWallets.fulfilled, (store, action) => {
				const state = store;
				state.custodialWallets = action.payload;
			})
			.addCase(getGlobalEnumsData.fulfilled, (store, action) => {
				const state = store;
				state.globalEnums = action.payload;
			})
			.addCase(getTodaysApy.fulfilled, (store, action) => {
				const state = store;
				const { todayApy, todayApyArray } = action.payload;
				if (todayApy) {
					state.todaysApy = todayApy;
				}
				if (todayApyArray) {
					state.todayApyArray = todayApyArray;
				}
			}),
});

export const { removeFromLoadingQueue, addToLoadingQueue } = generalRx.actions;

export default generalRx.reducer;
