import moment from 'moment';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';
import ViewSavingsAndPayoutReport from './ViewSavingsAndPayoutReport';

interface IApyHistoryItem {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const ApyHistoryItem = ({ data }: IApyHistoryItem) => (
	<Style>
		<div>
			<p style={{ color: 'var(--green-color)' }}>
				<strong>{commas(Number(data.apy) * 100)}%</strong>
			</p>
			<p className="label">Apy</p>
		</div>
		<div>
			<p>
				{data.day} / {data.month} / {data.year}
			</p>
			<p className="label">Day</p>
		</div>
		<div>
			<p>
				{data.marketOpenTime
					? moment(data.marketOpenTime).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Market Open Time</p>
		</div>
		<div>
			<p>
				{data.marketCloseTime
					? moment(data.marketCloseTime).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Market Close Time</p>
		</div>
		<div>
			<ViewSavingsAndPayoutReport
				data={data.savingsAndPayoutReport}
				day={data.day}
				month={data.month}
				year={data.year}
			/>
		</div>
	</Style>
);

export default ApyHistoryItem;
