import { Tag } from 'antd';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { useAppSelector } from '../../methods/redux/hooks';

const StatusCountStyle = styled.div`
	& > * {
		padding: 7px 15px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
`;

const LoanStatusCount = () => {
	const { loanStatuses } = useAppSelector((store) => store.loan);

	return (
		<StatusCountStyle>
			<Tag color="orange">
				<p>Pending</p>
				<p>
					<NativeNumber value={loanStatuses.pendingLoan} />
				</p>
			</Tag>
			<Tag color="blue">
				<p>Processing</p>
				<p>
					<NativeNumber value={loanStatuses.processingLoan} />
				</p>
			</Tag>
			<Tag color="green">
				<p>Running</p>
				<p>
					<NativeNumber value={loanStatuses.runningLoan} />
				</p>
			</Tag>
			<Tag color="darkgreen">
				<p>Completed</p>
				<p>
					<NativeNumber value={loanStatuses.completedLoan} />
				</p>
			</Tag>
			<Tag color="gray">
				<p>Rejected</p>
				<p>
					<NativeNumber value={loanStatuses.rejectedLoan} />
				</p>
			</Tag>
		</StatusCountStyle>
	);
};

export default LoanStatusCount;
