import { Button, Divider, Input, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useState } from 'react';
import { ItemSection } from '.';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { handleSwitch } from '../../../methods/redux/reducers/fundsRobots';

interface IMaxOutgoingBUSD {
	cronDetails: any[];
}

const MaxOutgoingBUSD: FC<IMaxOutgoingBUSD> = ({ cronDetails }) => {
	const [visible, setVisible] = useState(false);
	const [selectedUpdate, setSelectedUpdate] = useState(
		cronDetails?.find((a) => a?.name === 'maximumOutGoingWithdrawalAmount')
			?.value
	);

	const dispatch = useAppDispatch();

	const showModal = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	const handleUpdate = (id: any) => {
		dispatch(handleSwitch({ id, value: selectedUpdate }));
		setVisible(false);
	};
	return (
		<ItemSection>
			{cronDetails
				?.filter((a) => a?.name === 'maximumOutGoingWithdrawalAmount')
				?.map((data: any) => (
					<ItemSection key={data._id}>
						<h4>
							Max Outgoing <strong>USD</strong> Withdrawal that CRON can Handle
						</h4>{' '}
						<span>
							{data?.value || 0} <strong>USD</strong>
							{'  '}
							<Button type="primary" onClick={showModal}>
								EDIT
							</Button>
						</span>
						<Modal
							visible={visible}
							width={300}
							footer={[
								<Popconfirm
									title="Are you sure?"
									onConfirm={() => handleUpdate(data._id)}
								>
									<Button disabled={!selectedUpdate} type="primary">
										Update
									</Button>
								</Popconfirm>,
							]}
							onCancel={onClose}
						>
							<Divider />

							<p>
								Modify Maximum Outgoing BUSD Withdrawal Amount that CRON can
								handle{' '}
							</p>

							<Input
								value={selectedUpdate}
								defaultValue={data?.value}
								onChange={(e: any) => setSelectedUpdate(e.target.value)}
							/>
						</Modal>
					</ItemSection>
				))}
		</ItemSection>
	);
};

export default MaxOutgoingBUSD;
