import { Badge, Card, Divider } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveLoanRequest } from '../../methods/redux/reducers/loan';
import { retrieveUserStakings } from '../../methods/redux/reducers/staking';
import { retrieveSwapRequest } from '../../methods/redux/reducers/swap';
import { getWithdrawalRequests } from '../../methods/redux/reducers/withdrawal-requests';

const Style = styled(Card)`
	& .core-module-pill {
		display: inline-block;
		margin-right: 20px;
		padding: 5px;
	}

	& .item {
		display: flex;
		// gap: 20px;
		flex-wrap: wrap;
		margin-top: 15px;

		& .overview {
			width: 100%;
			max-width: 200px;
			padding: 20px;
			min-width: 150px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-right: 1px solid #ddd;
			& .title {
				display: flex;
				& > *:first-child {
					margin-right: 10px;
				}
			}
			& .count {
				margin-top: 10px;
				display: flex;
				justify-content: space-between;

				& p {
					font-size: 18px;
					font-weight: 600;
				}

				& a {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					width: 30px;
					height: 30px;

					&:hover {
						background-color: #bbdcff;
					}
				}
			}
		}
	}
`;

const UserTransactionsSection = () => {
	const dispatch = useAppDispatch();

	const { id } = useParams();
	const { user } = useAppSelector((store) => store.userProfile);
	const { swapRequestPaginator } = useAppSelector((store) => store.swap);
	const { paginator } = useAppSelector((store) => store.withdrawalRequests);
	const { stakings } = useAppSelector((store) => store.staking);

	useEffect(() => {
		dispatch(retrieveSwapRequest({ perPage: 1, userId: id }));
		dispatch(getWithdrawalRequests({ perPage: 1, userId: id }));
		dispatch(retrieveUserStakings({ perPage: 1, userId: id }));
		dispatch(retrieveLoanRequest({ perPage: 1, userId: id }));
	}, [id]);

	return (
		<Style>
			<p>
				<strong>Transactions Overview</strong>
			</p>

			<Divider />

			<div className="core-module-pill">
				<p>
					Withdrawal Requests{' '}
					<Badge
						count={paginator.itemCount}
						showZero
						style={{ backgroundColor: 'gray' }}
					/>
				</p>
				<Link to={`/transactions/withdraw-requests/${id}`}>View</Link>
			</div>
			<div className="core-module-pill">
				<p>
					Swaps{' '}
					<Badge
						count={swapRequestPaginator.itemCount}
						showZero
						style={{ backgroundColor: 'gray' }}
					/>
				</p>
				<Link to={`/core-modules/swap/${id}`}>View</Link>
			</div>
			<div className="core-module-pill">
				<p>
					Staking{' '}
					<Badge
						count={stakings.paginator?.itemCount}
						showZero
						style={{ backgroundColor: 'gray' }}
					/>
				</p>
				<Link to={`/core-modules/staking/${id}`}>View</Link>
			</div>
			<div className="core-module-pill">
				<p>
					External Funding {/* <Badge */}
					{/* 	count={stakings.paginator?.itemCount} */}
					{/* 	showZero */}
					{/* 	style={{ backgroundColor: 'gray' }} */}
					{/* /> */}
				</p>
				<Link to={`/transactions/in-credit/${id}`}>View</Link>
			</div>
			<div className="core-module-pill">
				<p>
					External Withdrawals {/* <Badge */}
					{/* 	count={stakings.paginator?.itemCount} */}
					{/* 	showZero */}
					{/* 	style={{ backgroundColor: 'gray' }} */}
					{/* /> */}
				</p>
				<Link to={`/transactions/out-debit/${id}`}>View</Link>
			</div>

			<Divider />

			<div className="item">
				<div className="overview">
					<div>
						<div className="title">
							<div>
								<span className="material-icons">receipt_long</span>
							</div>
							<p>Transaction Statements</p>
						</div>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.transactions}</p>
						<Link to={`/transactions/statements/${id}`}>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
				<div className="overview">
					<div className="title">
						<div>
							<span className="material-icons">money</span>
						</div>
						<p>Inapp Transfers</p>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.inappTransfers}</p>
						<Link
							to={`/transactions/statements/${id}?transactionForm=inapp_transfer`}
						>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
				<div className="overview">
					<div>
						<div className="title">
							<div>
								<span
									style={{ color: 'var(--green-color)' }}
									className="material-icons"
								>
									arrow_circle_down
								</span>
							</div>
							<p>Deposits</p>
						</div>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.deposits}</p>
						<Link to={`/transactions/statements/${id}?transactionType=credit`}>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
				<div className="overview">
					<div>
						<div className="title">
							<div>
								<span
									style={{ color: 'var(--red-color)' }}
									className="material-icons"
								>
									outbound
								</span>
							</div>
							<p>Withdrawals</p>
						</div>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.withdrawals}</p>
						<Link to={`/transactions/statements/${id}?transactionType=debit`}>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
				<div className="overview">
					<div>
						<div className="title">
							<div>
								<span className="material-icons">swap_horizontal_circle</span>
							</div>
							<p>Swap</p>
						</div>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.swap}</p>
						<Link to={`/transactions/statements/${id}?transactionForm=swap`}>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
				<div className="overview">
					<div>
						<div className="title">
							<div>
								<span className="material-icons">token</span>
							</div>
							<p>Staking</p>
						</div>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.staking}</p>
						<Link to={`/transactions/statements/${id}?transactionForm=staking`}>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
				<div className="overview">
					<div>
						<div className="title">
							<div>
								<span className="material-icons">real_estate_agent</span>
							</div>
							<p>Loan Requests</p>
						</div>
					</div>
					<div className="count">
						<p>{user.transactionsCount?.staking}</p>
						<Link to={`/core-modules/loan-requests/${id}`}>
							<span className="material-icons">arrow_forward</span>
						</Link>
					</div>
				</div>
			</div>
		</Style>
	);
};
export default UserTransactionsSection;
