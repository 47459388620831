import { useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { createRole } from '../../../methods/redux/reducers/roles';

const CreateRole = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const { permissions } = useAppSelector((store) => store.roles);

	const [state, setState] = useState({
		title: '',
		description: '',
		permissions: [],
	});

	const submit = () => {
		dispatch(createRole(state));
	};

	return (
		<>
			<Button type="primary" onClick={() => setVisible(true)}>
				Create Role
			</Button>

			<Modal
				title="Create New Role"
				okText="Create"
				onOk={submit}
				width={400}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Title</p>
					<Input
						value={state.title}
						onChange={(e) => setState({ ...state, title: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Description</p>
					<Input.TextArea
						value={state.description}
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<p>Permissions for role</p>
					<Select
						mode="multiple"
						allowClear
						defaultValue={state.permissions}
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, permissions: e })}
					>
						{permissions.map((item) => (
							<Select.Option key={item}>{item}</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</>
	);
};

export default CreateRole;
