import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';
import ApproveTerminationRequest from './ApproveTerminationRequest';
import CancelTerminationRequest from './CancelTerminationRequest';

interface ITerminationRequestItem {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;

	& .amount {
		display: flex;
		font-weight: bold;
		font-size: 16px;
		& img {
			margin-right: 7px;
			width: 20px;
			height: 20px;
		}
	}

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 12px;
		padding: 15px 0;

		& > * {
			display: inline-block;
			min-width: 200px;
			margin-bottom: 7px;
			padding: 5px;
		}

		& .label {
			font-size: 13px;
			color: #808080;
			font-style: italic;
		}
	}
`;

const TerminationrequestsItem = ({ data }: ITerminationRequestItem) => (
	<Style>
		<div>
			<Link
				to={`/core-modules/fixed-savings/single/${data._id}`}
				className="title"
			>
				<div className="amount">
					<img alt="" src={data.currencyId?.iconUrl} />
					<p>
						{commas(Number(data.cacheCapital) + Number(data.cacheProfit))}{' '}
						{data?.currencyId?.symbol || data?.currencyId?.name}
					</p>
				</div>
			</Link>
		</div>
		<div className="details">
			<div>
				<Link
					title="View Customer Profile"
					to={`/user-profile/${data.userId?._id}`}
				>
					@{data.userId?.username}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<Link
					to={`/core-modules/fixed-savings/single/${data._id}`}
					className="title"
				>
					<p>{data.title}</p>
					<p className="label">TItle</p>
				</Link>
			</div>
			<div>
				<p>{data.requestTerminationStatus}</p>
				<p className="label">Termination Status</p>
			</div>
			<div>
				<p>
					{moment(data.requestTerminationDate).format(
						'MMM DD, YY / hh:mm:ss a'
					)}
				</p>
				<p className="label">Date of Request</p>
			</div>
			<div>
				<p>
					{commas(data?.cacheCapital)}{' '}
					{data?.currencyId?.symbol || data?.currencyId?.name}
				</p>
				<p className="label">Capital</p>
			</div>
			<div>
				<p>
					{commas(data?.cacheProfit)}{' '}
					{data?.currencyId?.symbol || data?.currencyId?.name}
				</p>
				<p className="label">Profit</p>
			</div>
			<div>
				<strong>
					{Number(data?.RequestTerminationPenaltyPercentage) * 100} %
				</strong>
				<p className="label">Termination Penalty Percentage</p>
			</div>
			<div>
				<p style={{ color: 'var(--red-color)' }}>
					<strong>
						{commas(
							(Number(data.cacheCapital) + Number(data.cacheProfit)) *
								Number(data?.RequestTerminationPenaltyPercentage)
						)}{' '}
						{data?.currencyId?.symbol || data?.currencyId?.name}
					</strong>
				</p>
				<p className="label">Termination Penalty Amount</p>
			</div>
			<div>
				<p>{data.savingsForm}</p>
				<p className="label">Savings Form</p>
			</div>
			<div>
				<p>{data.interestForm}</p>
				<p className="label">Interest Form</p>
			</div>
			<div>
				<p>{moment(data.startDate).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Starts</p>
			</div>
			<div>
				<p>{moment(data.endDate).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Ends</p>
			</div>
			<div>
				<p>{data.requestTerminationInitialDurationDays} days</p>
				<p className="label">Initial Duration</p>
			</div>
			<div>
				<p>{data.durationDays} days</p>
				<p className="label">Duration</p>
			</div>

			{data?.requestTerminationApprovalDate && (
				<div>
					<p>
						{moment(data.requestTerminationApprovalDate).format(
							'MMM DD, YY / hh:mm:ss a'
						)}
					</p>
					<p className="label">Request Approval Termination Date</p>
				</div>
			)}
			<div>
				<p>{moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Created At</p>
			</div>
			<div>
				<p>{moment(data.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Updated At</p>
			</div>
			{data?.requestTerminationStatus === 'requested' && (
				<div>
					<ApproveTerminationRequest fixedSavingsId={data?._id} data={data} />
				</div>
			)}
			{data?.requestTerminationStatus === 'requested' && (
				<div>
					<CancelTerminationRequest fixedSavingsId={data?._id} />
				</div>
			)}
		</div>
	</Style>
);

export default TerminationrequestsItem;
