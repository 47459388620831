import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const HeaderExtraStyle = styled.div`
	/* display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	} */
	display: flex;

	& .date-search {
		display: flex;
		position: relative;
		align-items: flex-end;
		margin-right: 10px;
	}
`;

const MyDateSelector = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [date, setDate] = useState({
		startDate: `${
			searchParams.get('startDate') || moment('2021/10/01').format('YYYY-MM-DD')
		}`,
		endDate: `${searchParams.get('endDate') || moment().format('YYYY-MM-DD')}`,
	});

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						allowClear={false}
						format="ll"
						onChange={(date1) =>
							date1?.isSameOrBefore(moment(date.endDate)) &&
							setDate({ ...date, startDate: date1.format('YYYY-MM-DD') })
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={moment(date.startDate)}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						allowClear={false}
						format="ll"
						value={moment(date.endDate)}
						onChange={(date1) =>
							date1?.isSameOrAfter(moment(date.startDate)) &&
							setDate({ ...date, endDate: date1.format('YYYY-MM-DD') })
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
					/>
				</div>
				<div>
					<Button
						type="primary"
						onClick={() => {
							const n = Object.fromEntries(searchParams.entries());
							setSearchParams({ ...n, ...date });
						}}
					>
						Search
					</Button>
				</div>
			</div>
		</HeaderExtraStyle>
	);
};

export default MyDateSelector;
