import { message, Switch, Tag } from 'antd';
import React, { FC } from 'react';
import baseuri from '../../methods/baseuri';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';

interface IUserWithdrawalConfig {
	details: any;
}
const UserWithdrawalConfig: FC<IUserWithdrawalConfig> = ({ details }) => {
	const onChange = async () => {
		try {
			// setloading(true);
			const res: any = await baseuri.post(
				`admin/users/toggle-withdrawal-process`,
				{
					userId: details?.profile?._id,
				}
			);
			if (res.status === 200) {
				message.success(res.data.message);
				return res.data.data;
			}
			message.success(res.data.message);
			return res.data.data;
		} catch (e: any) {
			// setloading(false);
			message.error(e.response.data.message);
			return '';
		}
	};
	return (
		<MyCard>
			<h2>
				User withdrawal{' '}
				{details?.profile?.processWithdrawal ? (
					<Tag color="processing">Active</Tag>
				) : (
					<Tag color="error">Deactivated</Tag>
				)}
			</h2>
			<Switch
				checked={details?.profile?.processWithdrawal}
				onChange={onChange}
			/>
		</MyCard>
	);
};

export default UserWithdrawalConfig;
