import { Button, Form, InputNumber, message, Pagination, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { commas } from '../../methods/utils/general-utils';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from './UsersByCountry';
import UsersFilter from './UsersFilter';
import { MyTable, UsersListPage } from './UsersList';

const ByWalletBalance = () => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [currencies, setCurrencies] = useState([] as any);
	const [inputAmount, setInputAmount] = useState('100');
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const [form] = Form.useForm();

	const getQueryParams = () => ({
		currency: `${searchParams.get('currency') || ''}`,
		balance: `${searchParams.get('balance') || '100'}`,
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const getCurrencies = async () => {
		try {
			const res: any = await baseuri.get(`admin/currency`);

			if (res.status === 200) {
				setCurrencies(res.data.data.itemsList);
				const params1 = getQueryParams();
				if (!params1?.currency) {
					setSearchParams({
						...params1,
						currency: res?.data?.data?.itemsList[0]?._id,
					});
				}
			}
		} catch (error: any) {
			message.error(error.response.data.data.message);
		}
	};

	const getResult = async (page = 1) => {
		const params1 = getQueryParams();
		setSearchParams({ ...params1, page: `${page}` });
		setLoading(true);
		// const x = await getUsersByWalletBalance(currency, amount, page);
		try {
			const res: any = await baseuri.get('/admin/users-by-wallet-balance', {
				params: { ...params1, page },
			});

			if (res && res.status === 200) {
				setData(res.data.data);
				setLoading(false);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};
	const onFinish = (/* data: any */) => {
		const n = getQueryParams();
		setSearchParams({ ...n, balance: inputAmount });
		// getResult();
		// setState({ ...state, currency: data.currency, amount: data.amount });
	};
	useEffect(() => {
		getCurrencies();
	}, []);
	useEffect(() => {
		if (searchParams.get('currency') && currencies) {
			getResult();
		}
	}, [location.search]);

	const columns = [
		{
			key: 0,
			title: 'User',
			dataIndex: 'walletId',
			render: (text: any) => (
				<Link to={`/user-profile/${text?.userId?._id}`}>
					<p>@{text?.userId?.username}</p>
					<p>{text?.userId?.email}</p>
				</Link>
			),
		},
		{
			key: 3,
			title: 'Phone',
			dataIndex: 'walletId',
			render: (text: any) => <p>{text?.userId?.phoneNo}</p>,
		},
		{
			key: 1,
			title: 'Balance',
			dataIndex: 'balance',
			render: (balance: any) => commas(balance),
		},
	];
	return (
		<UsersListPage>
			<UsersFilter />
			<div>
				<p>Find users with over a certain amount of money in their wallet.</p>
				<p>
					Perform search using any of the supported currencies on Xend Finance.
				</p>
			</div>

			<br />
			<Form
				form={form}
				onFinish={onFinish}
				layout="inline"
				initialValues={{
					currency: searchParams.get('currency') || 'Binance USD',
					balance: searchParams.get('balance') || '100',
				}}
			>
				<Form.Item name="balance">
					<InputNumber
						value={searchParams.get('balance') || '100'}
						placeholder="Balance"
						onChange={(e) => {
							setInputAmount(String(e));
							// const params1 = getQueryParams();
							// setSearchParams({
							// 	...params1,
							// 	balance: e,
							// });
						}}
					/>
				</Form.Item>
				<Form.Item name="currency">
					<Select
						placeholder={searchParams.get('currency') || 'Binance USD'}
						onChange={(e) => {
							const params1 = getQueryParams();
							setSearchParams({
								...params1,
								currency: e,
							});
						}}
					>
						{currencies?.map((currency: any) => (
							<Select.Option value={currency?._id} key={currency._id}>
								{currency?.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						disabled={loading}
					>
						Search
					</Button>
				</Form.Item>
			</Form>
			<MyTableCard>
				<TableHeaderSection
					tableName="users By Wallet Balance"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount}
					downloadURL="https://apiprostaging.xend.finance/api/admin/users"
				/>
				<MyTable
					locale={{
						emptyText: <MyEmptyTable />,
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</UsersListPage>
	);
};

export default ByWalletBalance;
