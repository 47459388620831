import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import {
	getWithdrawalRequestCount,
	getWithdrawalRequests,
} from '../../methods/redux/reducers/withdrawal-requests';
import remakeQueryString from '../../methods/utils/remake-query-string';
import StatusCount from './StatusCount';
import WithdrawalRequestItem from './WithdrawalRequestItem';

const WithdrawalRequests = () => {
	const dispatch = useAppDispatch();

	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { itemsList, paginator } = useAppSelector(
		(store) => store.withdrawalRequests
	);
	const { user } = useAppSelector((store) => store.userProfile);
	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: '8',
				pageId: '1',
				perPage: String(20),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
			dispatch(getWithdrawalRequestCount({ userId }));
		}

		dispatch(getWithdrawalRequestCount({}));
		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getWithdrawalRequests({ ...queryData, userId }));
			} else {
				dispatch(getWithdrawalRequests(queryData));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	const blockchainSelect = blockchains.map((item: any) => ({
		name: item.abbreviation,
		value: item._id,
	}));

	return (
		<Card>
			<h1>
				Withdrawal Requests{' '}
				{userId && (
					<span>
						for{' '}
						<Link
							title="View Profile"
							to={`/user-profile/${user.profile?._id}`}
						>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>

			<Divider />

			<StatusCount />

			<br />
			<br />

			<SearchFilter
				title="Filter Withdrawal Requests "
				searchFilters={[
					{
						label: 'Status',
						name: 'status',
						htmlElement: 'select',
						defaultSelectedValue: '8',
						selectOptions: [
							{ name: 'Review', value: '8' },
							{ name: 'Pending', value: '0' },
							{ name: 'On-Hold', value: '7' },
							{ name: 'Processing', value: '5' },
							{ name: 'Completed', value: '1' },
							{ name: 'Cancelled', value: '3' },
							{ name: 'Retry', value: '6' },
							{ name: 'Failed', value: '2' },
							{ name: 'Deleted', value: '4' },
						],
					},
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Blockchain Network',
						name: 'blockchainNetworkId',
						htmlElement: 'select',
						selectOptions: blockchainSelect,
					},
					{
						label: 'XendBridge Reference',
						name: 'p2pRef',
						htmlElement: 'input',
					},
					{
						label: 'Destination Address',
						name: 'destinationAddress',
						htmlElement: 'input',
					},
					{
						label: 'Transaction hash',
						name: 'transactionHash',
						htmlElement: 'input',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={paginator.itemCount}
				current={paginator.currentPage}
				pageSize={paginator.perPage}
			/>

			<br />

			{itemsList.map((item: any) => (
				<WithdrawalRequestItem key={item._id} request={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={paginator.itemCount}
				current={paginator.currentPage}
				pageSize={paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default WithdrawalRequests;
