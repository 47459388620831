import { Pagination, Divider, Card } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveExpiringFixedSavings } from '../../methods/redux/reducers/fixed-savings';
import FixedSavingsProfile from './FixedSavingsProfile';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import SearchFilter from '../../component/Filter';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const ExpiringFixedSavingsPage = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { currencies } = useAppSelector((store) => store.currencies);
	const { expiring } = useAppSelector((store) => store.fixedSavings);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(expiring.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(retrieveExpiringFixedSavings({ ...queryData, userId }));
			} else {
				dispatch(retrieveExpiringFixedSavings(queryData));
			}
		}
	}, [searchParams]);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<Card>
			<h1>
				Expiring Fixed Savings
				{userId && (
					<span>
						for{' '}
						<Link to={`/user-profile/${user.profile?._id}`}>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>

			<Divider />

			<SearchFilter
				title="Search Expiring Fixed Savings"
				searchFilters={[
					{
						label: 'Status',
						name: 'status',
						htmlElement: 'select',
						selectOptions: [
							{ name: 'Pending', value: 'pending' },
							{ name: 'Active', value: 'active' },
							{ name: 'Completed', value: 'completed' },
							{ name: 'Terminated', value: 'terminated' },
							{ name: 'Deleted', value: 'deleted' },
						],
					},
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of <strong>{expiring.paginator.itemCount} items</strong> found
			</p>
			<Divider />

			<div>
				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={expiring.paginator.itemCount}
					current={expiring.paginator.currentPage}
					pageSize={expiring.paginator.perPage}
				/>
			</div>

			<br />

			{expiring.itemsList.map((profile: any) => (
				<FixedSavingsProfile key={profile._id} fixedSavingsProfile={profile} />
			))}

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={expiring.paginator.itemCount}
				current={expiring.paginator.currentPage}
				pageSize={expiring.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default ExpiringFixedSavingsPage;
