import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from 'antd';
import {
	getTodaysApy,
	retrieveCustodialWallets,
} from '../methods/redux/reducers/general';
import { defaultImage } from '../methods/utils/general-utils';
import CustodialWallets from './CustodialWallets';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getContractAddress,
	getCurrencies,
} from '../methods/redux/reducers/currency';
import TodaysApy from './TodaysApy';
import {
	getSavingsSum,
	getTodaysStats,
} from '../methods/redux/reducers/dashboard';
import ReportHighlights from './ReportHightlights';
import StatusCount from '../transactions/withdrawal-request/StatusCount';
import { getWithdrawalRequestCount } from '../methods/redux/reducers/withdrawal-requests';
import SwapStatusCount from '../core-modules/swap/SwapCountByStatus';
import { retrieveSwapRequestCount } from '../methods/redux/reducers/swap';
import NativeNumber from '../component/Numeral';
import CustodialUsers from './CustodialUsers';
import LoanStatusCount from '../core-modules/loan/LoanStatusCount';
import UrgentNotifications from './UrgentNotifications';
import { retrieveToken } from '../methods/utils/auth';
import { retrieveLoanStatuses } from '../methods/redux/reducers/loan';

const LandingStyle = styled.div`
	& .two-status {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
	}
	& .todays-stats {
		display: flex;
		flex-wrap: wrap;

		& > * {
			display: inline-block;
			min-width: 200px;
			max-width: 300px;
			padding: 10px 10px 10px 0;

			& .stat-label {
				font-size: 10px;
				color: #333;
				letter-spacing: 0.6px;
				font-weight: 400;
				margin: 0;
				padding: 0;
				line-height: 1.5;
				text-transform: uppercase;
			}

			& .stat-value {
				font-weight: 600;
				font-size: 21px;
				margin: 0;
				padding: 0;
				line-height: 1;
				margin-bottom: 5px;
			}

			& .amount {
				background-color: #f2f2f2;
				display: inline-flex;
				align-items: center;
				margin: 0 2px;
				padding: 0 3px;
				font-size: 12px;

				& img {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin-right: 4px;
				}
			}
		}
	}
`;

const LandingPage = () => {
	const dispatch = useAppDispatch();

	const { custodialWallets } = useAppSelector((store) => store.general);

	const { today } = useAppSelector((store) => store.dashboard);

	const [state, setState] = useState({
		fixed: 0,
		flexible: 0,
		stake: 0,
	});

	useEffect(() => {
		dispatch(retrieveCustodialWallets());
		dispatch(getBlockchainNetworks());
		dispatch(getContractAddress({}));
		dispatch(getCurrencies({}));
		dispatch(getTodaysApy());
		dispatch(getTodaysStats());
		dispatch(getSavingsSum({}));
		dispatch(getWithdrawalRequestCount({}));
		dispatch(retrieveSwapRequestCount({}));
		dispatch(retrieveLoanStatuses({}));
	}, []);

	useEffect(() => {
		const fixedSum = today.fixed.today
			.map(
				(currencyData: any) =>
					Number(currencyData.totalAmount) *
					Number(currencyData.currencyId?.usdValue || 0)
			)
			.reduce((a, prev) => a + prev, 0);

		const flexiSum = today.flexible.today
			.map(
				(currencyData: any) =>
					Number(currencyData.totalAmount) *
					Number(currencyData.currencyId?.usdValue || 0)
			)
			.reduce((a, prev) => a + prev, 0);

		const stakeSum = today.stakes.today
			.map(
				(currencyData: any) =>
					Number(currencyData.totalAmount) *
					Number(currencyData.currencyId?.usdValue || 0)
			)
			.reduce((a, prev) => a + prev, 0);

		setState({
			...state,
			fixed: fixedSum,
			flexible: flexiSum,
			stake: stakeSum,
		});
	}, [today]);

	return (
		<LandingStyle>
			<Card>
				<h1>
					Welcome
					{retrieveToken()?.user?.fullName && (
						<span>, {retrieveToken()?.user?.fullName}</span>
					)}
				</h1>
				<UrgentNotifications />
			</Card>

			<br />

			<Card>
				<h2>Withdrawal Request Status</h2>
				<StatusCount />
				<br />
				<Link to="/transactions/withdraw-requests">Withdrawal Requests</Link>
			</Card>

			<br />

			<div className="two-status">
				<Card>
					<h2>Swap Request Status</h2>
					<SwapStatusCount />
					<br />
					<Link to="/core-modules/swap">Swap Requests</Link>
				</Card>
				<Card>
					<h2>Loan Request Status</h2>
					<LoanStatusCount />
					<br />
					<Link to="/core-modules/loan-requests">Loan Requests</Link>
				</Card>
			</div>

			<br />

			<Card>
				<h2>Today&apos;s Stats</h2>
				<div className="todays-stats">
					<div>
						<p className="stat-label">User Registration</p>
						<p className="stat-value" style={{ display: 'flex' }}>
							<span
								className="material-icons"
								style={{ fontSize: 16, marginRight: 5 }}
							>
								group_add
							</span>{' '}
							<NativeNumber value={today.users.today} decimalPlaces={4} />
						</p>
						<div style={{ width: '80%' }}>
							<div className="amount">
								<span
									className="material-icons"
									style={{ fontSize: 16, marginRight: 2 }}
								>
									public
								</span>
								<p>
									<NativeNumber value={today.users.total} decimalPlaces={4} />
									<br /> verified users
								</p>
							</div>
						</div>
					</div>
					<div>
						<p className="stat-label">Fixed Savings</p>
						<p className="stat-value">
							$<NativeNumber value={state.fixed} decimalPlaces={4} />
						</p>
						<div style={{ width: '90%' }}>
							{today.fixed.today.map((item: any) => (
								<div className="amount" key={item.currencyId?._id}>
									<img
										alt=""
										src={item.currencyId.iconUrl || defaultImage}
										width={20}
									/>
									<p>
										<NativeNumber value={item.totalAmount} decimalPlaces={4} />{' '}
										{item.currencyId.symbol}
									</p>
								</div>
							))}
						</div>
					</div>
					<div>
						<p className="stat-label">Flexible Savings</p>
						<p className="stat-value">
							$<NativeNumber value={state.flexible} decimalPlaces={4} />
						</p>
						<div style={{ width: '90%' }}>
							{today.flexible.today.map((item: any) => (
								<div className="amount" key={item.currencyId?._id}>
									<img
										alt=""
										src={item.currencyId.iconUrl || defaultImage}
										width={20}
									/>
									<p>
										<NativeNumber value={item.totalAmount} decimalPlaces={4} />{' '}
										{item.currencyId?.symbol}
									</p>
								</div>
							))}
						</div>
					</div>
					<div>
						<p className="stat-label">Staking</p>
						<p className="stat-value">
							$<NativeNumber value={state.stake} decimalPlaces={4} />
						</p>
						<div style={{ width: '90%' }}>
							{today.stakes.today.map((item: any) => (
								<div className="amount" key={item.currencyId?._id}>
									<img
										alt=""
										src={item.currencyId.iconUrl || defaultImage}
										width={20}
									/>
									<p>
										<NativeNumber value={item.totalAmount} decimalPlaces={4} />{' '}
										{item.currencyId?.symbol}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</Card>

			<br />

			<Card>
				<TodaysApy />
			</Card>

			<br />

			<Card>
				<ReportHighlights />
			</Card>

			<br />

			<Card>
				<h2>Custodial Wallets</h2>
				{custodialWallets.itemsList.map((wallet: any) => (
					<CustodialWallets key={wallet._id} wallet={wallet} />
				))}
				<br />
				<Link
					style={{ textTransform: 'uppercase' }}
					to="/settings/general#custodial-wallets"
				>
					Manage
				</Link>
			</Card>
			<br />
			<CustodialUsers />
		</LandingStyle>
	);
};

export default LandingPage;
