import { Card } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { migratedFeesBatches } from '../../../methods/redux/reducers/reports';

const FeeMigrationBatchPage = () => {
	const dispatch = useAppDispatch();

	const { migratedBatches } = useAppSelector((store) => store.reports);

	console.info(migratedBatches);

	useEffect(() => {
		dispatch(migratedFeesBatches({}));
	}, []);

	return (
		<Card>
			<h1>Fee Migration Batches</h1>
			<p>
				See the report for all the fees charged on transactions and migrated in
				different fee collection batches.
			</p>
		</Card>
	);
};

export default FeeMigrationBatchPage;
