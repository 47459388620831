import styled from 'styled-components';
import moment from 'moment';
import NativeNumber from '../../component/Numeral';

const ItemStyle = styled.div`
	padding: 14px 2px;
	border-bottom: 1px solid #ddd;

	& > * {
		min-width: 100px;
		margin-right: 20px;
		margin-bottom: 10px;
		display: inline-block;
	}

	& .label {
		color: #aaa;
		font-size: 12px;
	}

	& .title {
		text-transform: capitalize;
	}

	& .user {
		display: flex;
		align-items: center;

		& img {
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 7px;
		}
	}

	& .amount {
		display: flex;
		align-items: center;
		font-weight: 600;

		& > * {
			margin-right: 3px;
		}

		& img {
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
	}

	& .discount {
		font-size: 12px;
		display: inline-block;
		background-color: #ddd;
		color: #bbb;
		border-radius: 5px;
		padding: 2px 5px;
	}

	& .discount.applied {
		background-color: #57a773;
		color: white;
	}
`;

interface IFixedSavingsItem {
	item: any;
}

const FixedSavingsProfileAssociatedSavingsItems = ({
	item,
}: IFixedSavingsItem) => (
	<ItemStyle key={item._id}>
		<div>
			<div className="amount">
				<p title={item.depositAmount}>
					<NativeNumber value={item.depositAmount} decimalPlaces={4} />{' '}
					{item.currencyId?.symbol}
				</p>
			</div>
			<p className="label">Deposit Amount</p>
		</div>
		<div>
			<p>
				<NativeNumber value={item.fixedSavingsFees?.fee} decimalPlaces={4} />{' '}
				{item.currencyId?.symbol} fee
			</p>
			<p className="label">Fee Collected</p>
		</div>
		<div>
			<a
				target="_blank"
				href={`https://${item.blockchainTransactionNetworkId?.explorerUrl}/tx/${item.blockchainTransactionHash}`}
				rel="noreferrer"
			>
				Hash
			</a>
			<p className="label">Transaction Hash</p>
		</div>
		<div>
			<p>
				{item.deviceType} | {item.locationIPCountryCode}
			</p>
			<p className="label">Device Used</p>
		</div>
		<div>
			<p>{item.itemForm}</p>
			<p className="label">Item Form</p>
		</div>
		<div>
			<p>{moment(item.startDate).format('MMM DD, YY / hh:mm:ss a')}</p>
			<p className="label">Start Date</p>
		</div>
		<div>
			<p>{moment(item.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
			<p className="label">Date Created</p>
		</div>
		<div>
			<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
			<p className="label">Updated At</p>
		</div>
		<div>
			<a
				target="_blank"
				href={`https://google.com/${item.locationLatitude}/${item.locationLongitude}`}
				rel="noreferrer"
			>
				Pin
			</a>
			<p className="label">Location</p>
		</div>
	</ItemStyle>
);

export default FixedSavingsProfileAssociatedSavingsItems;
