import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../../baseuri';
import {
	retrieveGameActions,
	retrieveGameActionsForAGame,
	retrieveSingleGameAction,
} from './actions/game-actions';
import {
	retrieveGameBadge,
	retrieveSingleGameBadge,
} from './actions/game-badge';
import { retrieveGameTasksForGame } from './actions/games-tasks';

export const createGame = async (data: any) => {
	try {
		await baseuri.post('/admin/game', data);
		message.success('Game created!');
	} catch (e: any) {
		console.error(e);
		message.error(`Game creation failed! ${e.toString()}`);
	}
};

export const retrieveSingleGame = createAsyncThunk(
	'games/retrieveSingleGame',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/single', { params });
		return res.data.data;
	}
);

export const updateGame = createAsyncThunk(
	'game/editGame',
	async (params: any, { dispatch }) => {
		try {
			await baseuri.put('/admin/game', params.data);
			message.success('Game updated!');
			dispatch(retrieveSingleGame({ gameId: params.gameId }));
		} catch (e: any) {
			console.error(e);
			message.error(`Game creation failed! ${e.toString()}`);
		}
	}
);

export const activateGame = createAsyncThunk(
	'game/activateGame',
	async (data: any, { dispatch }) => {
		console.info('came here');
		try {
			await baseuri.post('/admin/game/activate', data);
			message.success('Game Activated!');
			dispatch(retrieveSingleGame({ gameId: data.gameId }));
		} catch (e: any) {
			console.error(e);
			message.error(`Game activation failed! ${e.toString()}`);
		}
	}
);

export const disburseGameReward = createAsyncThunk(
	'game/disburseGameReward',
	async (data: any, { dispatch }) => {
		console.info('came here', data);
		try {
			await baseuri.post('/admin/game/distribute-reward', data);
			message.success('Game Reward Disbursed!');
			// eslint-disable-next-line no-use-before-define
			dispatch(retrieveGeneralGameUsersPoints({}));
		} catch (e: any) {
			console.error(e);
			message.error(`Game reward disburse failed! ${e.toString()}`);
		}
	}
);

export const addGameFile = async ({ data, callback }: any) => {
	try {
		await baseuri.post('/admin/game/gamefiles', data);
		message.success('Game Files Added!');
		if (callback) {
			callback();
		}
	} catch (e: any) {
		console.error(e);
		message.error(`Game files upload failed! ${e.toString()}`);
	}
};

export const retrieveGames = createAsyncThunk(
	'games/retrieveGames',
	async (params: any) => {
		const res = await baseuri.get('/admin/game', { params });
		return res.data.data;
	}
);

export const retrieveGameSubscribers = createAsyncThunk(
	'games/retrieveGameSubscribers',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/game-subscribers', { params });
		return res.data.data;
	}
);

export const retrieveGameSubscriberPoints = createAsyncThunk(
	'games/retrieveGameSubscriberPoints',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/game-reward-points', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveGeneralGameUsersPoints = createAsyncThunk(
	'games/retrieveGeneralGameUsersPoints',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/users-game-points', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveGameWinners = createAsyncThunk(
	'games/retrieveGameWinners',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/game-winners', { params });
		return res.data.data;
	}
);

export const retrieveGameRewardUsers = createAsyncThunk(
	'games/retrieveGameRewardUsers',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/game-reward-users', { params });
		return res.data.data;
	}
);

export const retrieveGameActionActivities = createAsyncThunk(
	'games/retrieveGameActionActivities',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/game-action-activities', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveUserGameActionActivities = createAsyncThunk(
	'games/retrieveUserGameActionActivities',
	async (params: any) => {
		const res = await baseuri.get('/admin/game/user-game-action-activities', {
			params,
		});
		return res.data.data;
	}
);

export const deleteGame = createAsyncThunk(
	'games/deleteGame',
	async (params: any, { dispatch }) => {
		await baseuri.delete('/admin/game', { data: params });
		message.success('Game deleted successfully');
		dispatch(retrieveSingleGame(params));
	}
);

export const deleteGameFile = createAsyncThunk(
	'games/deleteGameFile',
	async (params: any, { dispatch }) => {
		await baseuri.delete('/admin/game/gamefile', { data: params });
		dispatch(retrieveSingleGame({ gameId: params.gameId }));
	}
);

export const getGameStatusCount = createAsyncThunk(
	'games/getGameStatusCount',
	async () => {
		const res = await baseuri.get('/admin/game/game-count');
		return res.data.data;
	}
);

export const createGameAction = async (data: any) => {
	try {
		await baseuri.post('/admin/game-action', data);
		message.success('Game action created!');
	} catch (e: any) {
		console.error(e);
		message.error(
			`Game Action creation failed! ${e.response.data.error_details.toString()}`
		);
	}
};

export const updateGameAction = createAsyncThunk(
	'game/editGame',
	async (params: any, { dispatch }) => {
		try {
			await baseuri.put('/admin/game-action', params.data);
			message.success('Game action updated!');
			dispatch(retrieveSingleGameAction({ actionId: params.actionId }));
		} catch (e: any) {
			console.error(e);
			message.error(`Game  action edit failed! ${e.toString()}`);
		}
	}
);

const gamesRx = createSlice({
	name: 'games',
	initialState: {
		singleGame: {
			_id: '',
			adminId: {
				username: null,
				_id: '',
				email: '',
				fullName: '',
			},
			allowRedeemReward: false,
			autoJoin: false,
			createdAt: '',
			currencyId: {
				_id: '',
				symbol: '',
			},
			description: '',
			endDate: '',
			gameColor: 'black',
			gameFatherId: { _id: '', email: '' },
			lastUpdatedBy: { _id: '', email: '' },
			gameForm: 'GeneralParticipationRewards',
			imageFiles: [],
			maxTokenSpend: '0',
			orderNumber: 0,
			rewardEarnForm: 'Singular',
			rewardPoint: 0,
			rewardSeasonEndDate: '',
			rewardSeasonStartDate: '',
			rewardTokenValue: 0,
			startDate: '',
			status: 'pending',
			title: 'First time',
			totalRewardUsers: 0,
			ugcGameType: 'DEFAULT',
			updatedAt: '',
			videoFiles: [],
			gameLogic: '',
		},
		games: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		gameTasks: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		subscribers: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		subscribersPoints: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		gameActionActivities: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		userGameActionActivities: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		gameActions: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 4,
				currentPage: 1,
			},
		},
		generalGamePoints: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 4,
				currentPage: 1,
			},
		},
		singleGameActionPoint: {
			itemsList: [],
			paginator: {},
		},
		singleGameAction: {
			_id: '',
			gameKey: 'default',
			action: 'it does default',
			createdAt: '',
			point: 0,
			initiator: '',
			usdVolume: 0,
			defaultPointVolume: 0,
			defaultActionCount: 0,
			defaultCountPoint: 0,
			associatedCurrencyId: '',
		},
		gameStatusCount: {
			activeGames: 0,
			completedGames: 0,
			deletedGames: 0,
			pendingGames: 0,
			playingGames: 0,
		},
		singleGameBadge: {
			_id: '',
			key: '',
			description: '',
			createdAt: '',
			requirements: 0,
			perk: 0,
			identifier: '',
		},
		gameBadge: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 4,
				currentPage: 1,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveGames.fulfilled, (store, action) => {
				const state = store;
				state.games = action.payload;
			})
			.addCase(getGameStatusCount.fulfilled, (store, action) => {
				const state = store;
				state.gameStatusCount = action.payload;
			})
			.addCase(retrieveSingleGame.fulfilled, (store, action) => {
				const state = store;
				state.singleGame = action.payload;
			})
			.addCase(retrieveGameTasksForGame.fulfilled, (store, action) => {
				const state = store;
				state.gameTasks = action.payload;
			})
			.addCase(retrieveGameSubscribers.fulfilled, (store, action) => {
				const state = store;
				state.subscribers = action.payload;
			})
			.addCase(retrieveGameSubscriberPoints.fulfilled, (store, action) => {
				const state = store;
				state.subscribersPoints = action.payload;
			})
			.addCase(retrieveGameActions.fulfilled, (store, action) => {
				const state = store;
				state.gameActions = action.payload;
			})
			.addCase(retrieveGameActionsForAGame.fulfilled, (store, action) => {
				const state = store;
				state.singleGameActionPoint = action.payload;
			})
			.addCase(retrieveSingleGameAction.fulfilled, (store, action) => {
				const state = store;
				state.singleGameAction = action.payload;
			})
			.addCase(retrieveGeneralGameUsersPoints.fulfilled, (store, action) => {
				const state = store;
				state.generalGamePoints = action.payload;
			})
			.addCase(retrieveGameActionActivities.fulfilled, (store, action) => {
				const state = store;
				state.gameActionActivities = action.payload;
			})
			.addCase(retrieveUserGameActionActivities.fulfilled, (store, action) => {
				const state = store;
				state.userGameActionActivities = action.payload;
			})
			.addCase(retrieveGameBadge.fulfilled, (store, action) => {
				const state = store;
				state.gameBadge = action.payload;
			})
			.addCase(retrieveSingleGameBadge.fulfilled, (store, action) => {
				const state = store;
				state.singleGameBadge = action.payload;
			}),
});

export default gamesRx.reducer;
