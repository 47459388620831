import styled from 'styled-components';
import { Link } from 'react-router-dom';
import EditStakingProfile from './EditProfile';
import NativeNumber from '../../component/Numeral';

interface IStakingProfile {
	profile: any;
}

interface IStyle {
	status: any;
}
const StakingProfileStyle = styled.section<IStyle>`
	border: 1px solid #f2f2f2;
	margin-bottom: 20px;

	& .banner-land {
		position: relative;
		height: 30px;
		background-color: ${(p) =>
			p.status === 'active' ? 'var(--green-color)' : ' #c4c4c4'};
		opacity: 0.5;

		& button {
			opacity: 0;
		}
	}

	& .main-details {
		padding: 12px 15px;
		font-size: 18px;

		& .apr {
			font-size: 21px;
		}
	}

	& .other-details {
		display: flex;
		flex-wrap: wrap;

		& > * {
			border: 1px solid #ccc;
			border-left-color: transparent;
			border-bottom-color: transparent;
			border-top-color: transparent;
			padding: 5px 15px;
			font-size: 15px;

			& .label {
				font-size: 11px;
				font-weight: 600;
			}
		}
	}

	& .actions {
		background-color: #f2f2f2;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& a {
			display: flex;
			align-items: center;
			color: black;
			margin-left: 12px;

			& .material-icons {
				font-size: 15px;
			}
		}

		& .delete {
			border: none;
			background-color: transparent;
			color: gray;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			transition: all 300ms ease;

			&:hover {
				background-color: #ddd;
				color: red;
			}

			& span {
				font-size: 16px;
			}
		}
	}
`;

const StakingProfile = ({ profile }: IStakingProfile) => (
	<StakingProfileStyle status={profile.status}>
		<div className="banner-land">
			<button type="button">edit</button>
		</div>
		<div className="main-details">
			<p>{profile.name}</p>
			<strong>
				<p className="apr">{profile.apr}% APR</p>
			</strong>
		</div>
		<div className="other-details">
			<div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img
						alt="staking currency"
						src={profile.currencyId?.iconUrl}
						width={20}
						height={20}
						style={{ marginRight: 10 }}
					/>
					<p>{profile.currencyId?.symbol}</p>
				</div>
				<p className="label">Staking Currency</p>
			</div>
			<div>
				<p>{profile.periodInDays} days</p>
				<p className="label">Lock Period</p>
			</div>
			<div>
				<p>
					<NativeNumber value={profile.minimumStakeDaysBeforeUnstake} /> days
				</p>
				<p className="label">Minimum Days Before Unstake</p>
			</div>
			<div>
				<Link
					style={{ display: 'flex' }}
					title="View Reward User Profile"
					to={`/user-profile/${profile.rewardUserId?._id}`}
				>
					<span className="material-icons">account_circle</span>
					<p>
						@{profile.rewardUserId?.username || profile.rewardUserId?.email}
					</p>
				</Link>
				<p className="label">Reward User</p>
			</div>
			<div>
				<p>{profile.stakingForm}</p>
				<p className="label">Staking Form</p>
			</div>
			<div>
				<p>
					<NativeNumber value={profile.minimumStakeAmount} />
				</p>
				<p className="label">Minimun Stake Amount</p>
			</div>
			<div>
				<p>
					<NativeNumber value={profile.maximumTotalStakeAmount} />{' '}
					{profile.currencyId?.symbol}
				</p>
				<p className="label">Maximum Total Stake Amount</p>
			</div>
			<div>
				<p>
					<NativeNumber value={profile.totalAmountStaked} />{' '}
					{profile.currencyId?.symbol}
				</p>
				<p className="label">Total Amount Staked So Far</p>
			</div>
			<div>
				<p>{profile.withdrawalPenaltyApr}%</p>
				<p className="label">Withdrawal Penalty APR</p>
			</div>
			<div>
				<p>{profile.status}</p>
				<p className="label">Status</p>
			</div>
		</div>
		<div className="actions">
			<div>
				<a
					href={`https://${profile.blockchainId?.explorerUrl}/address/${profile.contractAddress}`}
					target="_blank"
					rel="noreferrer"
					style={{ display: 'flex' }}
				>
					<img
						width={20}
						height={20}
						alt="blockchain"
						src={profile.blockchainId?.iconUrl}
					/>
					<span style={{ margin: '0 5px 0 3px' }}>Staking Contract</span>
					<span className="material-icons">link</span>
				</a>
			</div>
			<div>
				<a href={profile.externalLink} target="_blank" rel="noreferrer">
					External Link
				</a>
			</div>
			<div>
				<EditStakingProfile profile={profile} />
			</div>
		</div>
	</StakingProfileStyle>
);

export default StakingProfile;
