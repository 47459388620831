import { Card, Pagination, Divider } from 'antd';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getPendingProfitBuild } from '../../methods/redux/reducers/flexible-savings';
import FlexiSavingsProfileItem from './ProfileItem';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const PendingProfitBuild = () => {
	const dispatch = useAppDispatch();

	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { pendingProfitBuild } = useAppSelector(
		(store) => store.flexibleSavings
	);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());

		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'active',
				pageId: '1',
				perPage: String(pendingProfitBuild.paginator.perPage),
			});
		}
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getPendingProfitBuild({ ...queryData, userId }));
			} else {
				dispatch(getPendingProfitBuild({ ...queryData }));
			}
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	return (
		<Card>
			<h1>
				Flexible Savings Pending Profit Build{' '}
				{userId && (
					<span>
						for{' '}
						<Link to={`/user-profile/${user.profile?._id}`}>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={pendingProfitBuild.paginator.itemCount}
				current={pendingProfitBuild.paginator.currentPage}
				pageSize={pendingProfitBuild.paginator.perPage}
			/>

			<br />

			<p>
				Total of <strong>{pendingProfitBuild.paginator.itemCount} items</strong>{' '}
				found
			</p>

			<Divider />

			{pendingProfitBuild.itemsList.map((item: any) => (
				<FlexiSavingsProfileItem profile={item} key={item._id} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={pendingProfitBuild.paginator.itemCount}
				current={pendingProfitBuild.paginator.currentPage}
				pageSize={pendingProfitBuild.paginator.perPage}
				showSizeChanger
			/>
		</Card>
	);
};

export default PendingProfitBuild;
