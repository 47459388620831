import { Divider, Button, Modal } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { triggerFixedPlanPayout } from '../../methods/redux/reducers/single-fixed-savings';

interface IAdminTriggerPayoutFixedPlan {
	profile: any;
}

const AdminTriggerPayoutFixedPlan = ({
	profile,
}: IAdminTriggerPayoutFixedPlan) => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const { _id, endDate, payout } = profile;

	const proceedWithPayout = () => {
		dispatch(triggerFixedPlanPayout(_id));
	};

	if (moment().isAfter(moment(endDate)) && !payout) {
		return (
			<>
				<Divider />

				<Button onClick={() => setVisible(true)} type="primary">
					Trigger Payout
				</Button>

				<Modal
					title="Trigger Fixed Plan Payout"
					visible={visible}
					onCancel={() => setVisible(false)}
					okText="Proceed"
					onOk={proceedWithPayout}
				>
					<p>
						As an administrator, you can trigger the payout of a fixed plan that
						has reached maturity. Proceed to click the &apos;Payout&apos; button
						if you are sure you know what you&apos;re about to do.
					</p>
				</Modal>
			</>
		);
	}
	return null;
};

export default AdminTriggerPayoutFixedPlan;
