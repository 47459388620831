import { Button, Modal, Input, Tag } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { sendBulkEmail } from '../../methods/redux/reducers/multiple-emails';

const AccessSection = styled.section`
	display: flex;
	flex-direction: column;
	> span {
		width: 25%;
		/* min-width: 150px; */
	}
	/* &.accessInput {
		width: 30px;
	} */
`;
interface ITerminateNewsLetter {
	id: string;
}
const SendBulkEmail: FC<ITerminateNewsLetter> = ({ id }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [senderPin, setSenderPin] = useState('');

	const dispatch = useAppDispatch();

	const handleSend = () => {
		try {
			setloading(true);
			dispatch(sendBulkEmail({ id, senderPin }));
		} finally {
			setloading(false);
		}
	};

	return (
		<div>
			<Tag
				style={{
					cursor: 'pointer',
				}}
				color="blue"
				onClick={() => setVisible(true)}
			>
				Send
			</Tag>
			<Modal
				width="50%"
				// footer={null}
				visible={visible}
				footer={[
					<Button loading={loading} type="primary" onClick={handleSend}>
						Send
					</Button>,
				]}
				onCancel={() => setVisible(false)}
			>
				<strong>
					Are you sure you want to Approve the sending of this NewsLetter
				</strong>
				<br />
				<br />
				<AccessSection>
					<p> Sender Pin : </p>
					<Input
						className="accessInput"
						placeholder="input access code"
						onChange={(e: any) => setSenderPin(e.target.value)}
					/>
				</AccessSection>
			</Modal>
		</div>
	);
};

export default SendBulkEmail;
