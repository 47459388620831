import { Button, Modal } from 'antd';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { activateGame } from '../../../methods/redux/reducers/games';

interface IActivateGame {
	game: any;
}

const ActivateGame = ({ game }: IActivateGame) => {
	const dispatch = useAppDispatch();

	const submit = () => {
		Modal.confirm({
			title: 'Activate Game',
			content: <p>You are about to activate [{game.title}] game</p>,
			onOk: () => {
				dispatch(activateGame({ gameId: game._id, status: 'active' }));
			},
		});
	};

	if (game.status === 'pending') {
		return (
			<Button type="link" onClick={submit}>
				Activate
			</Button>
		);
	}

	return null;
};

export default ActivateGame;
