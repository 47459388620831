import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { defaultImage } from '../../methods/utils/general-utils';

interface ICryptoBalanceItem {
	item: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;

	& .label {
		font-size: 13px;
		color: #808080;
		font-style: italic;
	}

	& .balance {
		display: flex;

		& img {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			margin-right: 10px;
		}
	}
`;

const CryptoBalanceItem = ({ item }: ICryptoBalanceItem) => (
	<Style>
		<div className="balance">
			<img alt="icon" src={item.currencyId?.iconUrl || defaultImage} />
			<p>
				<strong>
					<NativeNumber value={item.balance} /> {item.currencyId?.symbol}
				</strong>
			</p>
		</div>
		<div>
			<Link to={`/user-profile/${item.userId?._id}`}>
				@{item.userId?.username}
			</Link>
			<p className="label">Customer</p>
		</div>
		<div>
			<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
			<p className="label">Last Updated</p>
		</div>
	</Style>
);

export default CryptoBalanceItem;
