import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IReferralRecordItem {
	record: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& > * {
		margin-right: 20px;
		display: inline-block;
		padding: 10px;

		& .bonus-amount {
			font-weight: bold;
			&.claimed {
				color: var(--green-color);
			}
		}

		& .label {
			font-style: italic;
			color: #aaa;
		}
	}
`;

const ReferralRecordItem = ({ record }: IReferralRecordItem) => (
	<Style>
		<div>
			<div>
				<p className="label">New User</p>
				<Link
					title="View Profile"
					to={`/user-profile/${record.newUserId?._id}`}
				>
					@{record.newUserId?.username}
				</Link>
			</div>
			<br />
			<div>
				<p
					className={`bonus-amount ${
						record.newUserClaimedReward ? 'claimed' : ''
					}`}
				>
					{record.newUserRewardAmount} {record.currencyId?.symbol}{' '}
					{record.newUserClaimedReward && <span>(Claimed)</span>}
				</p>
				<p className="label">New User Bonus</p>
			</div>
		</div>
		<div>
			<div>
				<p className="label">Referrer</p>
				<Link
					title="View Profile"
					to={`/user-profile/${record.oldUserId?._id}`}
				>
					@{record.oldUserId?.username}
				</Link>
			</div>
			<br />
			<div>
				<p
					className={`bonus-amount ${
						record.oldUserClaimedReward ? 'claimed' : ''
					}`}
				>
					{record.oldUserRewardAmount} {record.currencyId?.symbol}{' '}
					{record.oldUserClaimedReward && <span>(Claimed)</span>}
				</p>
				<p className="label">Referrer Bonus</p>
			</div>
		</div>
		<div>
			<p>{moment(record.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
			<p className="label">Created At</p>
		</div>
	</Style>
);

export default ReferralRecordItem;
