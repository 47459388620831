import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { FC } from 'react';
import emailBaseUrl from '../../methods/emailBaseUrl';

interface IDeleteNews {
	id: string;
}
const DeleteCron: FC<IDeleteNews> = ({ id }) => {
	const removeCron = async () => {
		try {
			const res = await emailBaseUrl.delete(`v1/app-cron/${id}`);
			if (res.status === 200) {
				message.success('Cron removed Successfully');
			}
		} catch (e) {
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Remove this Cron Job?',
			onOk() {
				removeCron();
			},
		});
	};
	return (
		<Button icon={<DeleteOutlined />} danger type="primary" onClick={confirm}>
			Delete
		</Button>
	);
};

export default DeleteCron;
