import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import { getInCreditTransactions } from '../../methods/redux/reducers/transactions';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import Aggregate from './Aggregate';
import InCreditItem from './InCreditItem';
import DepositFilters from './DepositFilters';
import NativeNumber from '../../component/Numeral';
import remakeQueryString from '../../methods/utils/remake-query-string';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const InCreditTransactions = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const { user } = useAppSelector((store) => store.userProfile);
	const { inCredit } = useAppSelector((store) => store.transactions);
	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(inCredit.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getInCreditTransactions({ ...queryData, userId }));
			} else {
				dispatch(getInCreditTransactions(queryData));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	const blockchainSelect = blockchains.map((item: any) => ({
		name: item.abbreviation,
		value: item._id,
	}));

	return (
		<Card>
			<h1>
				InCredit Transactions{' '}
				{userId && (
					<span>
						{' '}
						for{' '}
						<Link
							title="View Profile"
							to={`/user-profile/${user.profile?._id}`}
						>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>
			<p>All the deposit of funds from an external wallet address.</p>

			<Aggregate data={inCredit.result} />

			{!userId && <DepositFilters />}
			<br />

			<SearchFilter
				title="Search InCredit Transaction Records"
				searchFilters={[
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Blockchain Network',
						name: 'blockchainId',
						htmlElement: 'select',
						selectOptions: blockchainSelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'Status',
						name: 'status',
						defaultSelectedValue: 'active',
						htmlElement: 'select',
						selectOptions: [
							{ name: 'Pending', value: 'pending' },
							{ name: 'Active', value: 'active' },
							{ name: 'Deleted', value: 'deleted' },
						],
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={inCredit.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={inCredit.paginator.itemCount}
				current={inCredit.paginator.currentPage}
				pageSize={inCredit.paginator.perPage}
			/>
			<br />

			{inCredit.itemsList.map((item: any) => (
				<InCreditItem key={item?._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={inCredit.paginator.itemCount}
				current={inCredit.paginator.currentPage}
				pageSize={inCredit.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default InCreditTransactions;
