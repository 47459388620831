import { Button, Card, Divider, Popconfirm } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	deleteThirdPartyLiquidityProviders,
	getLiquidityProviders,
	getThirdPartyLiquidityProviders,
} from '../../methods/redux/reducers/liquidity-providers';
import AddLiquidityProvider from './AddLiquidityProvider';
import LiquidityProviderItem from './LiquidityItem';
import AddThirdPartyLiquidityProvider from './AddThirdPartyLiquidityProvider';

const LiquidityProviders = () => {
	const dispatch = useAppDispatch();

	const { xendbridge, thirpartyLp } = useAppSelector(
		(store) => store.liquidityProviders
	);

	useEffect(() => {
		dispatch(getLiquidityProviders({}));
		dispatch(getThirdPartyLiquidityProviders({}));
	}, []);

	return (
		<div>
			<Card>
				<h1>Manage Liquidity Providers</h1>
				<p>Manage ThirdParty Liquidity Providers</p>
				<Divider />
				<div>
					{thirpartyLp.itemsList.length > 0 && (
						<>
							{thirpartyLp.itemsList.map((lp: any) => (
								<div
									key={lp._id}
									style={{
										display: 'inline-block',
										marginRight: 20,
										padding: '10px 20px',
										border: '1px solid #cecece',
									}}
								>
									<a
										title={lp.website}
										href={lp.website}
										target="_blank"
										rel="noreferrer"
									>
										{lp.name}
									</a>
									<div>
										<Popconfirm
											title="Are you sure?"
											onConfirm={() =>
												dispatch(
													deleteThirdPartyLiquidityProviders({
														providerId: lp._id,
													})
												)
											}
										>
											<Button type="text" danger>
												Delete
											</Button>
										</Popconfirm>
									</div>
								</div>
							))}
							<Divider />
						</>
					)}
				</div>
				<div>
					<AddThirdPartyLiquidityProvider />
				</div>
			</Card>

			<br />

			<Card>
				<h1>Liquidity Providers Agents</h1>
				<p>
					Whitelist / de-list addresses from XendBridge liquidity providers for
					P2P transaction.
				</p>

				<Divider />

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<AddLiquidityProvider />
				</div>
				{xendbridge.itemsList.map((item: any) => (
					<LiquidityProviderItem key={item._id} liquidityProvider={item} />
				))}
			</Card>
		</div>
	);
};

export default LiquidityProviders;
