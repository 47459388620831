import { Button, Input, message, Modal, Popconfirm, Select } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import baseuri from '../../../methods/baseuri';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1em;
`;

const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface IQuickEmail {
	userId: any;
}
const Item = styled.div`
	display: flex;
	flex-direction: column;
`;

const QuickEmail: FC<IQuickEmail> = ({ userId }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		message: '',
		title: '',
		type: 'email',
	} as any);

	const typeEnum = [
		{ name: 'Email', value: 'email' },
		{ name: 'Notifications', value: 'notification' },
	];

	const handleCreate = async () => {
		const formData = new FormData();
		formData.append('csv_file', data?.csv_file);

		try {
			setloading(true);
			const res: any = await baseuri.post(`admin/users/email-user`, {
				...data,
				userId,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(res.data.message);
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<Item>
			<Button icon={<MailOutlined />} onClick={() => setVisible(true)}>
				Send Alert
			</Button>

			<Link
				to={`/alerts/direct-emails?userId=${userId}&perPage=10&pageId=1&sort=desc`}
			>
				View Customer emails
			</Link>

			<MyModal
				title="Send Email to this user"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Send
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={data?.title}
							onChange={(e: any) => setData({ ...data, title: e.target.value })}
						/>
					</p>
					<p>
						<p>Select Type: </p>
						<Select
							value={data?.type}
							style={{ width: '150px' }}
							onChange={(value) =>
								setData({
									...data,
									type: value,
								})
							}
						>
							{typeEnum?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem.value}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<p>Message</p>
						<textarea
							placeholder="input message"
							value={data?.message}
							onChange={(e: any) =>
								setData({ ...data, message: e.target.value })
							}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</Item>
	);
};

export default QuickEmail;
