import { Divider, Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

const ExtrasStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const Alerts = () => {
	const items = [
		{
			title: 'Email Newsletter',
			desc: 'Manage Xend Finance Newsletters. Create, edit and view email newsletters.',
			link: `/alerts/email-newsletter`,
			icon: 'newspaper',
		},
		{
			title: 'Multiple Alert/Email',
			desc: 'Here, admin sends email to multiple users by uploading the csv file containing the list of users to send email to',
			link: `/alerts/multiple-emails?perPage=1&pageId=1`,
			icon: 'dynamic_feed',
		},
		{
			title: 'Automated Emails',
			desc: 'This contains all automated emails',
			link: `/alerts/auto-emails?perPage=10&pageId=1&sort=desc`,
			icon: 'schedule_send',
		},
		{
			title: 'Single Direct Alert/Email',
			desc: 'This page has all the mails sent to individual users on the app',
			link: `/alerts/direct-emails?perPage=10&pageId=1&sort=desc`,
			icon: 'message',
		},
		{
			title: 'In-App Alerts',
			desc: 'Manage in-app alerts',
			link: `/alerts/in-app-alerts?perPage=10&pageId=1&sort=desc`,
			icon: 'circle_notifications',
		},
		{
			title: 'In-App News',
			desc: 'Manage in-app news',
			link: `/alerts/in-app-news?perPage=10&pageId=1&sort=desc`,
			icon: 'circle_notifications',
		},
		{
			title: 'Advert Banners',
			desc: 'Manage in-app notifications, newsletter configurations and history.',
			link: `/alerts/advert-banners?perPage=10&pageId=1&sort=desc`,
			icon: 'ad_units',
		},
	];

	return (
		<section>
			<PageHeader>Alerts & Newsletter</PageHeader>

			<Input.Search placeholder="Search" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<ExtrasStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</ExtrasStyle>
				))}
		</section>
	);
};

export default Alerts;
