import { Button, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useState } from 'react';
import baseuri from '../../methods/baseuri';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateWithdrawalStatus } from '../../methods/redux/reducers/withdrawal-requests';

interface IProcessInAppRequest {
	request: any;
}
const ProcessInAppRequest = ({ request }: IProcessInAppRequest) => {
	const { _id, inAppTransaction } = request;
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const completeRequest = async () => {
		try {
			await baseuri.post('/admin/wallet/process-in-app-transaction', {
				requestId: _id,
			});
			message.success('Action complete!');
			dispatch(updateWithdrawalStatus({ requestId: _id, status: 1 }));
		} catch (e: any) {
			message.error('Action failed!');
		}
	};

	if (inAppTransaction) {
		return (
			<>
				<Button type="primary" onClick={() => setVisible(true)}>
					Process InApp Transactions
				</Button>

				<Modal
					visible={visible}
					onOk={() => completeRequest()}
					onCancel={() => setVisible(false)}
					title="Process InApp Transactions"
				>
					<p>
						This is a transfer of{' '}
						<strong>
							{request.amount} {request.currencyId?.symbol}
						</strong>{' '}
						to a customer on Xend Finance app. Click OK to process the
						transaction
					</p>
				</Modal>
			</>
		);
	}
	return null;
};

export default ProcessInAppRequest;
