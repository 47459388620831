import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
	defaultImage,
	defaultImageOne,
} from '../../methods/utils/general-utils';

interface ISwapItemStyle {
	statusColor: string;
}
const SwapItemStyle = styled(Link)<ISwapItemStyle>`
	display: block;
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 10px;
	background-color: #f8f8f8;
	color: #333;
	text-decoration: none;

	&:hover {
		color: #333;
	}

	& .info {
		display: flex;

		& .status {
			display: flex;
			font-size: 12px;

			& > div:first-child {
				display: flex;
				align-items: center;
			}

			& .material-icons {
				font-size: 10px;
				color: ${(p) => p.statusColor};
				margin-right: 5px;
			}
		}

		& p {
			margin: 0;
			padding: 0;
		}
	}

	& .swap {
		display: flex;
		align-items: center;
		margin-top: 10px;

		& img {
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 5px;
		}

		& .base {
			display: flex;
			align-items: center;
		}

		& p {
			font-weight: bold;
			font-size: 18px;
		}
	}

	& .details {
		margin-top: 15px;
		border-top: 1px dashed #ccc;
		padding-top: 10px;
		font-style: italic;

		& .user {
			& img {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				margin-right: 5px;
			}
		}

		& > * {
			display: inline-block;
			min-width: 100px;
			margin-right: 15px;
			margin-bottom: 5px;

			& p {
				font-size: 14px;
				margin: 0;
				padding: 0;

				&.label {
					color: gray;
					font-size: 14px;
				}
			}
		}
	}
`;

interface ISwapItem {
	swapItem: any;
}

const SwapItem = ({ swapItem }: ISwapItem) => {
	const swapStatusColor = (status: any) => {
		switch (status) {
			case 'processing':
				return 'orange';
			case 'completed':
				return 'green';
			default:
				return '#f3f3f3';
		}
	};

	return (
		<SwapItemStyle
			title="Open Swap Request"
			to={`/core-modules/swap/item/${swapItem._id}`}
			statusColor={swapStatusColor(swapItem.swapStatus)}
		>
			<div className="info">
				<div className="status">
					<div>
						<span className="material-icons">circle</span>
					</div>
				</div>
			</div>
			<div className="swap">
				<div className="base">
					<img alt="" src={swapItem.baseCurrency?.iconUrl || defaultImage} />
					<p>
						{swapItem.baseCurrencyAmount} {swapItem.baseCurrency?.symbol}
					</p>
				</div>
				<span className="material-icons">trending_flat</span>
				<div className="base">
					<img
						alt=""
						src={swapItem.quoteCurrency?.iconUrl || defaultImageOne}
					/>
					<p>{swapItem.quoteCurrency?.symbol}</p>
				</div>
			</div>
			<div className="details">
				<div>
					<p>{swapItem.swapStatus}</p>
					<p className="label">Swap Status</p>
				</div>
				<div className="user" title={swapItem.userId?.fullName}>
					<p>@{swapItem.userId?.username}</p>
					<p className="label">Customer</p>
				</div>
				<div>
					<p>{swapItem.swapDeadlineMinutes} mins</p>
					<p className="label">Swap Deadline</p>
				</div>
				<div>
					<p>{moment(swapItem.createdAt).format('lll')}</p>
					<p className="label">Created</p>
				</div>
				<div>
					<p>{swapItem.transactionSpeed}</p>
					<p className="label">Transaction Speed</p>
				</div>
				<div>
					<p>
						@
						{swapItem.processedByUserId?.username ||
							swapItem.processedByUserId?.email}
					</p>
					<p className="label">Processed By</p>
				</div>
			</div>
		</SwapItemStyle>
	);
};

export default SwapItem;
