import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas, CSVstringToArray } from '../../methods/utils/general-utils';
import Distribute from './Distribute';
import Start from './Start';

interface IColumnItems {
	data: any;
}

const Style = styled(Link)`
	all: unset;
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
	cursor: pointer;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const BulkColumn = ({ data }: IColumnItems) => (
	<Style to={`/transactions/bulk-savings/${data?._id}`}>
		<div>
			<p>{data?.title}</p>
			<p className="label">Bulk Savings Title</p>
			{/* <p>
				<Link title="View Profile" to={`/user-profile/${data?.funder}`}>
					View Funder
				</Link>
			</p> */}
		</div>
		<div>
			<p>{data?.status || '---'}</p>
			<p className="label">Status</p>
		</div>
		<div>
			<p>{data?.durationDays || 0} Days</p>
			<p className="label">Duration</p>
		</div>
		<div>
			<p>
				{data?.csv_text
					? commas(CSVstringToArray(data?.csv_text, ',').length, 2)
					: '--'}
			</p>
			<p className="label">Participants</p>
		</div>
		<div>
			<p>{commas(data?.totalTransactionFee)}</p>
			<p className="label">Transaction Fee</p>
		</div>
		<div>
			<p>
				{data.createdAt
					? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Date created</p>
		</div>
		{data?.status === 'pending' && (
			<div>
				<Start bulkSavingsId={data?._id} />
			</div>
		)}
		{data?.status === 'processing' && (
			<div>
				<Distribute bulkSavingsId={data?._id} />
			</div>
		)}
	</Style>
);
export default BulkColumn;
