import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import GameActionDetails from './GameActionDetails';
import {
	retrieveGameActions,
	retrieveSingleGameAction,
} from '../../../methods/redux/reducers/games/actions/game-actions';

const Style = styled(Card)<{ statusColor: string }>`
	& .status {
		font-weight: 600;
	}
	& .details > * {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 20px;

		& .label {
			font-style: italic;
			color: #aaa;
		}
	}

	& .title-desc {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}

		& .label {
			font-style: italic;
			color: #aaa;
		}
	}

	& .actions > * {
		display: inline-block;
		margin-right: 20px;
	}

	& .status {
		text-transform: uppercase;
		text-align: center;
		padding: 2px;
		font-size: 12px;
		letter-spacing: 5px;
		color: white;
		background-color: ${(p) => p.statusColor};
	}
`;

const SingleGameActionItem = () => {
	const dispatch = useAppDispatch();

	const { actionId } = useParams();

	const { singleGameAction } = useAppSelector((store) => store.games);
	useEffect(() => {
		dispatch(retrieveGameActions({}));
		dispatch(retrieveSingleGameAction({ actionId }));
	}, [actionId]);

	const pickColor = (status: string) => {
		switch (status) {
			case 'pending':
				return 'orange';
			case 'deleted':
				return 'red';
			case 'active':
				return 'green';
			case 'running':
				return 'blue';
			default:
				return '#ccc';
		}
	};

	return (
		<Style statusColor={pickColor('orange')}>
			<h1>Manage Game Action [{singleGameAction.gameKey}]</h1>
			<Divider />
			<GameActionDetails actionId={actionId} />
			<Divider />
		</Style>
	);
};

export default SingleGameActionItem;
