import {
	Select,
	Card,
	Space,
	Upload,
	Input,
	Button,
	Modal,
	Divider,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	updateABlockchainNetwork,
} from '../../methods/redux/reducers/currency';
import { editBlockchainNetworkLogo } from '../../methods/blockchain-networks';
// import { SectionHeader } from '../../theme/theme';
import AddBlockchainNetwork from './AddBlockchainNetwork';
import { toBase64 } from '../../methods/utils/request-helpers';

const Styles = styled.div`
	display: inline-flex;
	& .top {
		display: flex;
		background-color: red;
	}
`;

interface INetworkStyle {
	statusColor: any;
}
const NetworkStyle = styled.button<INetworkStyle>`
	position: relative;
	border: none;
	border: 1px solid #ddd;
	display: inline-flex;
	align-items: center;
	margin-right: 15px;
	margin-bottom: 10px;
	cursor: pointer;
	padding: 5px;
	background-color: transparent;

	& p {
		font-size: 12px;
		text-align: left;

		&.symbol {
			font-size: 16px;
		}
	}

	& .status {
		position: absolute;
		background-color: ${(p) => p.statusColor};
		color: white;
		display: inline-block;
		right: -2.5px;
		top: -2.5px;
		border-radius: 50%;
		height: 10px;
		width: 10px;
		padding: 2.5px;
		font-size: 9px;
		text-transform: capitalize;
	}

	& .withdraw-indication {
		position: absolute;
		background-color: red;
		color: white;
		width: 100%;
		display: flex;
		justifycontent: center;
		align-items: center;
		left: 0;
		bottom: 0;
		padding: 2.5px;
	}
`;

interface INetwork {
	blockchainNetworkId: any;
	name: string;
	abbreviation: string;
	icon: string;
	networkId: any;
	nativeTokenSymbol: any;
	nativeTokenCoinGeckoId: any;
	tokenStandard: any;
	explorerUrl: any;
	status: any;
	switchWalletEnum: any;
	allowWithdrawal: any;
}
const Network = ({
	name,
	explorerUrl,
	abbreviation,
	icon,
	networkId,
	blockchainNetworkId,
	tokenStandard,
	status,
	nativeTokenSymbol,
	nativeTokenCoinGeckoId,
	switchWalletEnum,
	allowWithdrawal,
}: INetwork) => {
	const dispatch = useAppDispatch();
	const { blockchainActivity } = useAppSelector((store) => store.currencies);
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		blockchainNetworkId: '',
		name: '',
		icon: '',
		abbreviation: '',
		nativeTokenSymbol: '',
		nativeTokenCoinGeckoId: '',
		networkId: 0,
		explorerUrl: '',
		tokenStandard: '',
		status: '',
		switchWalletEnum: 1,
		allowWithdrawal: 'true',
		readOnly: true,
	});

	const handleInput = (e: any) => {
		const { value } = e.target;
		setState({ ...state, [e.target.name]: value });
	};

	useEffect(() => {
		setState({
			...state,
			blockchainNetworkId,
			name,
			abbreviation,
			networkId,
			tokenStandard,
			explorerUrl,
			nativeTokenSymbol,
			nativeTokenCoinGeckoId,
			icon,
			status,
			switchWalletEnum,
			allowWithdrawal,
		});
	}, []);

	const handleUpdateNetwork = () => {
		dispatch(updateABlockchainNetwork(state));
	};

	const handleIconUpload = async (e: any) => {
		const { onSuccess, onError, file } = e;
		const base64Img = await toBase64(file);
		const data = {
			blockchainNetworkId: state.blockchainNetworkId,
			icon: base64Img,
		};
		editBlockchainNetworkLogo(data, onSuccess, onError);
	};

	const colorCoding = (s: any) => {
		switch (s) {
			case 'active':
				return 'green';
			case 'partial':
				return 'orange';
			case 'deleted':
				return 'red';
			default:
				return '#ccc';
		}
	};

	return (
		<Styles>
			<NetworkStyle
				statusColor={colorCoding(status)}
				onClick={() => setVisible(true)}
				title="View / Edit"
			>
				<div className="status">{/* <p>{status}</p> */}</div>
				<div style={{ width: 36, height: 24, overflow: 'hidden' }}>
					<img
						width={20}
						style={{ marginRight: 7, borderRadius: '50%' }}
						src={icon}
						alt=""
					/>
				</div>
				<div>
					<p className="symbol">{abbreviation}</p>
					<p>{name}</p>
				</div>
				{allowWithdrawal === false && <div className="withdraw-indication" />}
			</NetworkStyle>

			<Modal
				maskClosable={false}
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Edit Blockchain Network"
			>
				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<p>
							<strong>Network Details</strong>
						</p>
						<Button
							type="text"
							onClick={() => setState({ ...state, readOnly: false })}
						>
							Edit
						</Button>
					</div>
					<Space style={{ width: '100%' }} size={10} direction="vertical">
						<div>
							<p>Network Status</p>
							<Select
								disabled={state.readOnly}
								value={state.status}
								onChange={(value) => setState({ ...state, status: value })}
							>
								<Select.Option value="pending">Pending</Select.Option>
								<Select.Option value="partial">Partial</Select.Option>
								<Select.Option value="active">Active</Select.Option>
								<Select.Option value="deleted">Deleted</Select.Option>
							</Select>
						</div>
						<div>
							<p>Name:</p>
							<Input
								name="name"
								readOnly={state.readOnly}
								value={state.name}
								onChange={handleInput}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<div>
								<p>Network ID:</p>
								<Input readOnly value={state.networkId} />
							</div>
							<div>
								<p>Token Standard</p>
								<Input
									name="tokenStandard"
									onChange={handleInput}
									readOnly={state.readOnly}
									value={state.tokenStandard}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<div>
								<p>Native Token Symbol:</p>
								<Input
									name="nativeTokenSymbol"
									onChange={handleInput}
									readOnly={state.readOnly}
									value={state.nativeTokenSymbol}
								/>
							</div>
							<div>
								<p>Abbreviation:</p>
								<Input
									name="abbreviation"
									onChange={handleInput}
									readOnly={state.readOnly}
									value={state.abbreviation}
								/>
							</div>
						</div>
						<div>
							<p>Explorer:</p>
							<Input
								name="explorerUrl"
								onChange={handleInput}
								addonBefore="https://"
								readOnly={state.readOnly}
								value={state.explorerUrl}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<div>
								<p>Native Token CoinGecko Id:</p>
								<Input
									name="nativeTokenCoinGeckoId"
									onChange={handleInput}
									readOnly={state.readOnly}
									value={state.nativeTokenCoinGeckoId}
								/>
							</div>
							<div>
								<p>SwitchWallet Enum Id:</p>
								<Input
									name="switchWalletEnum"
									onChange={handleInput}
									readOnly={state.readOnly}
									value={state.switchWalletEnum}
								/>
							</div>
						</div>
						<div>
							<p>Allow Withdrawal</p>
							<Select
								disabled={state.readOnly}
								defaultValue={String(state.allowWithdrawal)}
								onChange={(value) =>
									setState({ ...state, allowWithdrawal: value })
								}
							>
								<Select.Option value="true">True</Select.Option>
								<Select.Option value="false">False</Select.Option>
							</Select>
						</div>
						{!state.readOnly && (
							<Button
								loading={blockchainActivity}
								type="primary"
								onClick={handleUpdateNetwork}
							>
								Update Details
							</Button>
						)}
					</Space>
				</div>
				<Divider />
				<div>
					<p>
						<strong>Update Network Logo</strong>
					</p>
					<p>Choose a logo and it&apos;ll be updated immediately</p>
					<img
						src={icon}
						alt=""
						className="logo"
						style={{
							width: 50,
							height: 50,
						}}
					/>
					<div>
						<ImgCrop>
							<Upload customRequest={handleIconUpload}>
								<Button>Choose & Upload</Button>
							</Upload>
						</ImgCrop>
					</div>
				</div>
			</Modal>
		</Styles>
	);
};

const BlockchainNetworks = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { blockchains } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		dispatch(getBlockchainNetworks());
	}, []);

	return (
		<Card>
			<h1>Blockchain Networks</h1>
			<Divider />
			<div>
				{blockchains.map((network: any) => (
					<Network
						key={network._id}
						blockchainNetworkId={network._id}
						explorerUrl={network.explorerUrl}
						tokenStandard={network.tokenStandard}
						nativeTokenSymbol={network.nativeTokenSymbol}
						nativeTokenCoinGeckoId={network.nativeTokenCoinGeckoId}
						networkId={network.networkId}
						name={network.name}
						icon={network.iconUrl}
						status={network.status}
						switchWalletEnum={network.switchWalletEnum}
						abbreviation={network.abbreviation}
						allowWithdrawal={network.allowWithdrawal}
					/>
				))}
			</div>
			<AddBlockchainNetwork />
			<Divider />
			<Button onClick={() => navigate('./fiat')}>Manage Fiat Banks</Button>
		</Card>
	);
};

export default BlockchainNetworks;
