import { EditOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
	Space,
} from 'antd';
import moment from 'moment';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import { countryCode } from '../../methods/utils/request-helpers';

const { TextArea } = Input;
interface IEditGreeting {
	greetingDetails: any;
	setRefresh: Function;
}
const EditSection = styled.div`
	display: inline-flex;
	margin-left: 10px;

	& .EditIcon {
		font-size: 20px;
		color: #4c4cc7;
		cursor: pointer;
	}
`;
const EditGreeting: FC<IEditGreeting> = ({ greetingDetails, setRefresh }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [greeting, setGreeting] = useState({
		title: greetingDetails?.title,
		description: greetingDetails?.description,
		timeFrame: greetingDetails?.timeFrame,
		expiry_time: greetingDetails?.expiry_time,
		countryCode: greetingDetails?.countryCode,
	});

	const timeFrameEnum = [
		{
			name: 'Morning',
			value: 'morning',
		},
		{
			name: 'Afternoon',
			value: 'afternoon',
		},
		{
			name: 'Evening',
			value: 'evening',
		},
	];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(`admin/in-app/alert`, {
				...greeting,
				id: greetingDetails?._id,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Greeting Updated Successfully');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.toString());
		}
	};

	return (
		<EditSection>
			<EditOutlined className="EditIcon" onClick={() => setVisible(true)} />

			<Modal
				title="Update Greeting Message"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<Space direction="vertical" size="large">
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={greeting?.title}
							onChange={(e: any) =>
								setGreeting({ ...greeting, title: e.target.value })
							}
						/>
					</p>
					<p>
						{' '}
						<p>Description: </p>
						<TextArea
							showCount
							// maxLength={100}
							value={greeting.description}
							onChange={(e: any) =>
								setGreeting({ ...greeting, description: e.target.value })
							}
						/>
					</p>
					<p>
						<p>Time Frame: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={greeting.timeFrame}
							onChange={(e) => {
								setGreeting({ ...greeting, timeFrame: e });
							}}
						>
							{timeFrameEnum?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<p> Expiry time: </p>
						<DatePicker
							format="YYYY-MM-DD HH:mm:ss"
							disabledDate={(current) =>
								current && current < moment().endOf('day')
							}
							// disabledTime={disabledDateTime}
							showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
							value={greeting?.expiry_time && moment(greeting.expiry_time)}
							onChange={(date) =>
								setGreeting({
									...greeting,
									expiry_time: date,
								})
							}
						/>
					</p>
					<p>
						Select Country:{' '}
						<Select
							value={greeting.countryCode}
							style={{ width: '150px' }}
							onChange={(value) =>
								setGreeting({
									...greeting,
									countryCode: value,
								})
							}
						>
							{countryCode?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem.code}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
				</Space>
			</Modal>
		</EditSection>
	);
};

export default EditGreeting;
