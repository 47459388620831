import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import emailBaseUrl from '../../emailBaseUrl';

export const retrieveMailingLists = createAsyncThunk(
	'alertRx/retrieveMailingList',
	async () => {
		const res = await emailBaseUrl.get('/v1/newsletters/maillist');
		return res.data.data;
	}
);

export const retrieveTestSubscribers = createAsyncThunk(
	'alertRx/retrieveTestSubscribers',
	async () => {
		const res = await emailBaseUrl.get('/v1/subscribers/testers');
		return res.data.data;
	}
);

const alertRx = createSlice({
	name: 'alertRx',
	initialState: {
		mailingList: [] as any,
		testSubscribers: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveMailingLists.fulfilled, (store, action) => {
				const state = store;
				state.mailingList = Object.keys(action.payload).map((item) => ({
					name: item,
					value: action.payload[item],
				}));
			})
			.addCase(retrieveTestSubscribers.fulfilled, (store, action) => {
				const state = store;
				state.testSubscribers = action.payload;
			}),
});

export default alertRx.reducer;
