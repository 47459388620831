import { Divider, Card, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getReferrals } from '../../methods/redux/reducers/referrals';
import {
	getUserProfileById,
	getUserReferralOverview,
} from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';
import ReferralRecordItem from './RecordItem';

const ReferralReportOverviewStyle = styled.div`
	& > * {
		display: inline-block;
		padding: 10px;

		& .red {
			color: var(--red-color);
		}

		& .label {
			color: #aaaaaa;
		}
	}
`;

const ReferralReport = () => {
	const dispatch = useAppDispatch();

	const { referrals } = useAppSelector((store) => store.referrals);

	const [searchParams, setSearchParams] = useSearchParams();
	const { user, referralOverview } = useAppSelector(
		(store) => store.userProfile
	);

	const { userId } = useParams();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(referrals.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
			dispatch(getUserReferralOverview({ ...queryData, userId }));
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getReferrals({ ...queryData, oldUserId: userId }));
				dispatch(getUserReferralOverview({ ...queryData, userId }));
			} else {
				dispatch(getReferrals(queryData));
			}
		}
	}, [searchParams]);

	return (
		<div>
			<Card>
				<h1>
					Referral Report{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>

				<Divider />

				<SearchFilter
					title="Find Referral Records"
					searchFilters={[
						{
							label: 'Start Date',
							name: 'startDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'End Date',
							name: 'endDate',
							htmlElement: 'datepicker',
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				{userId && (
					<>
						<ReferralReportOverviewStyle>
							<div>
								<p>
									<strong>{referralOverview.numOfUniqueDeviceIds}</strong>
								</p>
								<p className="label">Unique Referrals</p>
							</div>
							<div>
								<p
									className={
										Number(referralOverview.totalReferrals) -
											Number(referralOverview.numOfUniqueDeviceIds) >
										0
											? 'red'
											: ''
									}
								>
									<strong>
										{Number(referralOverview.totalReferrals) -
											Number(referralOverview.numOfUniqueDeviceIds)}
									</strong>
								</p>
								<p className="label">Fake Referrals</p>
							</div>
							<div>
								<p>
									<strong>{referralOverview.totalReferrals}</strong>
								</p>
								<p className="label">Total Referrals</p>
							</div>
						</ReferralReportOverviewStyle>
						<Divider />
					</>
				)}

				{!userId && (
					<>
						<p>
							Total of <strong>{referrals.paginator.itemCount} items</strong>{' '}
							found
						</p>

						<Divider />
					</>
				)}

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={referrals.paginator.itemCount}
					current={referrals.paginator.currentPage}
					pageSize={referrals.paginator.perPage}
				/>

				<br />

				{referrals.itemsList.map((item: any) => (
					<ReferralRecordItem key={item._id} record={item} />
				))}

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={referrals.paginator.itemCount}
					current={referrals.paginator.currentPage}
					pageSize={referrals.paginator.perPage}
					showSizeChanger
				/>
			</Card>
		</div>
	);
};

export default ReferralReport;
