import { useState } from 'react';
import { Button, Modal } from 'antd';
import { cancelTerminationRequest } from '../../methods/redux/reducers/fixed-savings';

interface ICancelTerminationRequest {
	fixedSavingsId: string;
}

const CancelTerminationRequest = ({
	fixedSavingsId,
}: ICancelTerminationRequest) => {
	const [loading, setLoading] = useState(false);

	const proceedWithCancel = async () => {
		setLoading(true);
		await cancelTerminationRequest({
			fixedSavingsId,
		});
		setLoading(false);
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to cancel this Liquidation Request?',
			onOk() {
				proceedWithCancel();
			},
		});
	};

	return (
		<Button onClick={confirm} loading={loading} disabled={loading} danger>
			Cancel Termination
		</Button>
	);
};

export default CancelTerminationRequest;
