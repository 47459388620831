import { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	message,
} from 'antd';
import styled, { css } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { retrieveFiatSupportedBanks } from '../../../methods/redux/reducers/currency';
import AddBank from './AddBank';
import baseuri from '../../../methods/baseuri';
import { countryCode as countries } from '../../../methods/utils/request-helpers';

const BankStyle = styled.button<{ status: string }>`
	position: relative;
	border: none;
	background-color: transparent;
	display: inline-block;
	padding: 20px;
	border: 1px solid #ccc;
	margin-right: 20px;
	cursor: pointer;

	& .status {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		color: white;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 3px;
		background-color: gray;
		${(p) =>
			p.status === 'active' &&
			css`
				background-color: green;
			`}
		${(p) =>
			p.status === 'deleted' &&
			css`
				background-color: red;
			`}
	}
`;
const Bank = ({ bank }: { bank: any }) => {
	const [visible, setVisible] = useState(false);
	const { currencies } = useAppSelector((store) => store.currencies);

	const [state, setState] = useState({
		status: '',
		name: '',
		symbol: '',
		branchCode: '',
		localCurrencyId: '',
		address: '',
		countryCode: '',
		apiTokenKeyInENV: '',
	});

	const deleteBank = async () => {
		try {
			await baseuri.delete(`/admin/fiat-banks/${bank._id}`);
			message.success('Bank deleted!');
			setVisible(false);
		} catch (error) {
			console.error(error);
		}
	};

	const editBank = async () => {
		try {
			await baseuri.put(`/admin/fiat-banks/${bank._id}`, state);
			message.success('Bank Updated!');
			setVisible(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setState({ ...state, ...bank, localCurrencyId: bank.localCurrencyId?._id });
	}, [bank]);

	return (
		<>
			<BankStyle
				title="Click to View / Edit"
				onClick={() => setVisible(true)}
				status={bank.status}
			>
				<p className="status">{bank.status}</p>
				<p>
					{bank.name} ({bank.symbol})
				</p>
				<p>{bank.branchCode}</p>
			</BankStyle>

			<Modal
				title="Edit Bank"
				footer={null}
				open={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Status</p>
					<Select
						value={state.status}
						onChange={(status) => setState({ ...state, status })}
						style={{ width: '100%' }}
						size="large"
					>
						<Select.Option value="active">Active</Select.Option>
						<Select.Option value="inactive">Inactive</Select.Option>
						<Select.Option value="deleted">Deleted</Select.Option>
					</Select>
				</div>
				<br />
				<div>
					<p>Name</p>
					<Input
						size="large"
						value={state.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Symbol</p>
					<Input
						size="large"
						value={state.symbol}
						onChange={(e) => setState({ ...state, symbol: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Branch Code</p>
					<Input
						size="large"
						value={state.branchCode}
						onChange={(e) => setState({ ...state, branchCode: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Local Currency</p>
					<Select
						value={state.localCurrencyId}
						onChange={(localCurrencyId) =>
							setState({ ...state, localCurrencyId })
						}
						style={{ width: '100%' }}
						size="large"
					>
						<Select.Option>--</Select.Option>
						{currencies.map((currency: any) => (
							<Select.Option key={currency._id} value={currency._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Address</p>
					<Input
						size="large"
						value={state.address}
						onChange={(e) => setState({ ...state, address: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Country</p>
					<Select
						value={state.countryCode}
						onChange={(countryCode) => setState({ ...state, countryCode })}
						style={{ width: '100%' }}
						size="large"
					>
						<Select.Option>--</Select.Option>
						{countries?.map((optionItem: any) => (
							<Select.Option key={optionItem.name} value={optionItem.code}>
								{optionItem.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Api Token</p>
					<Input
						size="large"
						value={state.apiTokenKeyInENV}
						onChange={(e) =>
							setState({ ...state, apiTokenKeyInENV: e.target.value })
						}
					/>
				</div>

				<br />

				<Popconfirm title="Are you sure?" onConfirm={editBank}>
					<Button size="large" type="primary" block>
						Update
					</Button>
				</Popconfirm>

				<Divider />
				<Popconfirm title="Are you sure?" onConfirm={deleteBank}>
					<Button size="large" type="primary" danger>
						Delete Bank
					</Button>
				</Popconfirm>
			</Modal>
		</>
	);
};

const Style = styled(Card)``;

const SupportedBanks = () => {
	const dispatch = useAppDispatch();

	const { fiatBanks } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		dispatch(retrieveFiatSupportedBanks({}));
	}, []);

	return (
		<Style>
			<h1>Supported Banks</h1>
			<AddBank />

			<Divider />
			{fiatBanks.itemsList.map((bank: any) => (
				<Bank key={bank._id} bank={bank} />
			))}
		</Style>
	);
};

export default SupportedBanks;
