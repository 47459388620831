import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { chooseEnvironment } from '../../methods/baseuri';

interface IDownloadUserReport {
	queryData: any;
	title: string;
	fetchFunction: Function;
}

const Style = styled.div`
	display: inline-block;
	padding: 20px;
	border: 1px solid #ccc;
	max-width: 400px;
	margin-right: 20px;
	position: relative;
	margin-bottom: 20px;

	& .title {
		font-size: 16px;
		font-weight: 600;
	}

	& .actions {
		margin-top: 10px;
		text-align: right;
	}
`;

const DownloadUserReport = ({
	queryData,
	title,
	fetchFunction,
}: IDownloadUserReport) => {
	const [loading, setLoading] = useState(false);

	const getData = async () => {
		setLoading(true);
		const data = await fetchFunction(queryData);
		const csv = data?.csv3Result;

		if (!csv) {
			message.info('There are no records to download');
		} else {
			const filename = title.toLowerCase().replace(/\s/g, '-');

			const downloadLink = `${
				chooseEnvironment().base
			}/static/front/downloads/${csv}`;

			const blob = await fetch(downloadLink).then((r) => r.blob());

			const a = document.createElement('a');
			a.download = `${filename}-${Date.now()}`;

			a.href = URL.createObjectURL(blob);
			a.click();
		}

		setLoading(false);
	};

	return (
		<Style>
			<p className="title">{title}</p>
			<p>Retrieve Users filtered by the filters above</p>
			<div className="actions">
				<Button icon={<DownloadOutlined />} onClick={getData} loading={loading}>
					Download
				</Button>
			</div>
		</Style>
	);
};

export default DownloadUserReport;
