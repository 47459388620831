import { Input } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Style = styled.div`
	display: none;

	@media (min-width: 768px) {
		display: block;
	}
`;

const GlobalSearchBar = () => {
	const navigate = useNavigate();

	const [text, setText] = useState('');

	const search = () => {
		navigate(`/search?q=${text}`);
	};

	return (
		<Style>
			<Input.Search
				onSearch={search}
				onChange={(e) => setText(e.target.value)}
				placeholder="Global search"
			/>
		</Style>
	);
};

export default GlobalSearchBar;
