import { Button, Divider, Popconfirm, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useState } from 'react';
import { ItemSection } from '.';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { handleSwitch } from '../../../methods/redux/reducers/fundsRobots';

interface IOutgoingWithdrawInterval {
	cronDetails: any[];
}

const OutgoingWithdrawInterval: FC<IOutgoingWithdrawInterval> = ({
	cronDetails,
}) => {
	const [visible, setVisible] = useState(false);
	const [selectedTime, setSelectedTime] = useState(
		cronDetails?.find((a) => a?.name === 'outGoingWithdrawalRecurring')?.value
	);

	const { fundsInterval } = useAppSelector((store) => store.fundsRobot);

	const dispatch = useAppDispatch();

	const showModal = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	const handleRecurrentUpdate = (id: any) => {
		dispatch(handleSwitch({ id, value: selectedTime }));
		setVisible(false);
	};
	return (
		<ItemSection>
			{cronDetails
				?.filter((a) => a?.name === 'outGoingWithdrawalRecurring')
				?.map((data: any) => (
					<ItemSection key={data._id}>
						<h4>CRON Interval for Outgoing Withdrawal</h4>{' '}
						<span>
							{data?.value}{' '}
							<Button type="primary" onClick={showModal}>
								EDIT
							</Button>
						</span>
						<Modal
							visible={visible}
							width={300}
							footer={[
								<Popconfirm
									title="Are you sure?"
									onConfirm={() => handleRecurrentUpdate(data._id)}
								>
									<Button disabled={!selectedTime} type="primary">
										Update
									</Button>
								</Popconfirm>,
							]}
							onCancel={onClose}
						>
							<Divider />

							<p>Select Recurrent time interval</p>
							<Select
								size="large"
								defaultValue={data?.value}
								style={{ width: '100%', marginTop: 5 }}
								placeholder="Please Select"
								onChange={(e: any) => setSelectedTime(e)}
							>
								{fundsInterval?.map((time: any) => (
									<Select.Option value={time} key={time}>
										{time}
									</Select.Option>
								))}
							</Select>
						</Modal>
					</ItemSection>
				))}
		</ItemSection>
	);
};

export default OutgoingWithdrawInterval;
