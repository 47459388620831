import { Button, message, Modal } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
`;
interface ICancel2fa {
	// eslint-disable-next-line react/require-default-props
	details?: any | null;
	// eslint-disable-next-line react/require-default-props
	id?: string | null;
	requestId: string;
}
const Cancel2fa: FC<ICancel2fa> = ({ details, id, requestId }) => {
	const [loading, setLoading] = useState(false);
	const reset2fa = async () => {
		try {
			setLoading(true);
			const res = await baseuri.put(`admin/users/deactivate-two-factor`, {
				userId: details?.profile?._id || details?.userId?._id || id,
				requestId,
				status: 'declined',
			});
			if (res.status === 200) {
				setLoading(false);
				message.success(res.data.message);
			} else {
				message.info(res.data.message);
				setLoading(false);
			}
		} catch (e: any) {
			setLoading(false);
			message.error(e.response.data.message);
		}
	};
	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Decline this user 2fa request?',
			onOk() {
				reset2fa();
			},
		});
	};
	return (
		<DeleteSection>
			<Button
				loading={loading}
				type="primary"
				onClick={confirm}
				disabled={!!details?.profile?.enabledTwoFactor}
				danger
			>
				Decline request
			</Button>
		</DeleteSection>
	);
};

export default Cancel2fa;
