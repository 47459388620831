import { Select, Input, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { completeBlockchainSwap } from '../../methods/redux/reducers/swap';

interface IBlockchainProcessRequest {
	request: any;
}
const BlockchainProcessRequest = ({ request }: IBlockchainProcessRequest) => {
	const dispatch = useAppDispatch();

	const { blockchains } = useAppSelector((store) => store.currencies);

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		resultQuoteCurrencyAmountLower: 0,
		transactionHash: '',
		gasFeeInNativeToken: 0,
		gasFeeInUSD: 0,
		transactionBlockchainId: '',
	});

	useEffect(() => {
		setState({
			...state,
			resultQuoteCurrencyAmountLower: request.resultQuoteCurrencyAmountLower,
		});
	}, [request]);

	const submitManual = () => {
		if (
			state.resultQuoteCurrencyAmountLower >=
			request.resultQuoteCurrencyAmountLower
		) {
			dispatch(
				completeBlockchainSwap({ ...state, processingSwapId: request._id })
			);
		}
	};

	return (
		<div>
			{request.swapStatus === 'processing' && (
				<Button size="middle" type="primary" onClick={() => setVisible(true)}>
					Blockchain Swap
				</Button>
			)}

			<Modal
				visible={visible}
				okText="Complete Swap"
				onOk={submitManual}
				width={400}
				onCancel={() => setVisible(false)}
				title={`Complete Swap ${request.baseCurrencyAmount} ${request.baseCurrency?.symbol}`}
			>
				<p>
					If the swap was done on the blockchain, you can provide the following
					details so that the customer&apos;s account can be credited properly.
				</p>
				<br />
				<div>
					<p>
						Customer should receive between{' '}
						<strong>
							{request.resultQuoteCurrencyAmountLower}{' '}
							{request.quoteCurrency?.symbol}
						</strong>{' '}
						and{' '}
						<strong>
							{request.resultQuoteCurrencyAmountUpper}{' '}
							{request.quoteCurrency?.symbol}
						</strong>
					</p>
				</div>

				<br />

				<div>
					<p>Transaction Hash</p>
					<Input
						value={state.transactionHash}
						onChange={(e) =>
							setState({ ...state, transactionHash: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<p>Blockchain</p>
					<Select
						style={{ width: '100%' }}
						value={state.transactionBlockchainId}
						onChange={(id) =>
							setState({ ...state, transactionBlockchainId: id })
						}
					>
						{blockchains.map((item: any) => (
							<Select.Option value={item._id} key={item._id}>
								{item.abbreviation}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>
						Amount Customer will receive (min{' '}
						{request.resultQuoteCurrencyAmountLower}{' '}
						{request.quoteCurrency?.symbol})
					</p>
					<Input
						type="number"
						addonAfter={request.quoteCurrency?.symbol}
						value={state.resultQuoteCurrencyAmountLower}
						onChange={(e) =>
							setState({
								...state,
								resultQuoteCurrencyAmountLower: Number(e.target.value),
							})
						}
					/>
				</div>
				<br />
				<div>
					<p>Gas Fee in Native Token</p>
					<Input
						type="number"
						value={state.gasFeeInNativeToken}
						onChange={(e) =>
							setState({
								...state,
								gasFeeInNativeToken: Number(e.target.value),
							})
						}
					/>
				</div>
				<br />
				<div>
					<p>Gas Fee in USD</p>
					<Input
						type="number"
						value={state.gasFeeInUSD}
						onChange={(e) =>
							setState({ ...state, gasFeeInUSD: Number(e.target.value) })
						}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default BlockchainProcessRequest;
