import { useEffect, useState } from 'react';
import { Input, Button, Popconfirm } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { updateGameAction } from '../../../methods/redux/reducers/games';

const GameActionDetails = ({ actionId }: { actionId: any }) => {
	const dispatch = useAppDispatch();
	const { singleGameAction } = useAppSelector((store) => store.games);
	// const { currencies } = useAppSelector((store) => store.currencies);
	const [state, setState] = useState({
		gameKey: '',
		action: '',
		createdAt: '',
		point: 0,
		initiator: '',
		usdVolume: 0,
		defaultPointVolume: 0,
		defaultActionCount: 0,
		defaultCountPoint: 0,
		associatedCurrencyId: '',
		readOnly: true,
	});

	const update = () => {
		dispatch(
			updateGameAction({
				data: {
					...state,
					actionId,
				},
				actionId,
			})
		);
		setState({ ...state, readOnly: true });
	};

	useEffect(() => {
		const {
			gameKey,
			action,
			point,
			initiator,
			usdVolume,
			defaultPointVolume,
			defaultActionCount,
			defaultCountPoint,
			associatedCurrencyId,
		} = singleGameAction;
		setState({
			...state,
			gameKey,
			action,
			point,
			initiator,
			usdVolume,
			defaultPointVolume,
			defaultActionCount,
			defaultCountPoint,
			associatedCurrencyId,
		});
	}, [singleGameAction]);

	return (
		<>
			<h2>Game Action Details</h2>
			<div className="title-desc">
				<div>
					<p className="label">Default Game Key</p>
					<Input.TextArea
						disabled={state.readOnly}
						onChange={(e) => setState({ ...state, gameKey: e.target.value })}
						value={state.gameKey}
					/>
				</div>
				<div>
					<p className="label">Default Action</p>
					<Input.TextArea
						onChange={(e) => setState({ ...state, action: e.target.value })}
						value={state.action}
					/>
				</div>
				<div>
					<p className="label">Default Point</p>
					<Input
						onChange={(e) =>
							setState({ ...state, point: Number(e.target.value) })
						}
						value={state.point}
					/>
				</div>

				<div>
					<p className="label">Default Initiator</p>
					<Input
						disabled={state.readOnly}
						onChange={(e) => setState({ ...state, initiator: e.target.value })}
						value={state.initiator}
					/>
				</div>

				<div>
					<p className="label">Default Usd Volume</p>
					<Input
						onChange={(e) =>
							setState({ ...state, usdVolume: Number(e.target.value) })
						}
						value={state.usdVolume}
					/>
				</div>

				<div>
					<p className="label">Default Point Volume</p>
					<Input
						onChange={(e) =>
							setState({ ...state, defaultPointVolume: Number(e.target.value) })
						}
						value={state.defaultPointVolume}
					/>
				</div>

				<div>
					<p className="label">Default Action Count</p>
					<Input
						onChange={(e) =>
							setState({ ...state, defaultActionCount: Number(e.target.value) })
						}
						value={state.defaultActionCount}
					/>
				</div>

				<div>
					<p className="label">Default Count Point</p>
					<Input
						onChange={(e) =>
							setState({ ...state, defaultCountPoint: Number(e.target.value) })
						}
						value={state.defaultCountPoint}
					/>
				</div>

				<div>
					<p className="label">Default Associated Currency Id</p>
					<Input
						onChange={(e) =>
							setState({ ...state, associatedCurrencyId: e.target.value })
						}
						value={state.associatedCurrencyId}
					/>
				</div>
			</div>
			<br />

			<div>
				<Popconfirm
					onConfirm={() => update()}
					title="Are you sure you want to modify this game action ?"
				>
					<Button type="primary">Edit Game Action</Button>
				</Popconfirm>
			</div>
		</>
	);
};

export default GameActionDetails;
