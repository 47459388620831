import { Card, Button, Modal, Input, Popconfirm } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const UpdateGameFatherId = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const [gameFatherId, setGameFatherId] = useState('');

	const updateSettings = () => {
		dispatch(updateInternalSettings({ gameFatherId }));
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Update Game Father</Button>

			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				title="Update Game Father"
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={updateSettings}>
						<Button type="primary">Update</Button>
					</Popconfirm>,
				]}
			>
				<p>Game Father User Id</p>
				<Input
					placeholder="Copy and paste the user id"
					value={gameFatherId}
					onChange={(e) => setGameFatherId(e.target.value)}
				/>
			</Modal>
		</>
	);
};

const GameSettings = () => {
	const { gameFatherId } = useAppSelector((store) => store.internalSettings);

	return (
		<Card>
			<h2>Set Game Father Id</h2>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					{gameFatherId._id ? (
						<Link to={`/user-profile/${gameFatherId._id}`}>
							{gameFatherId?.email}
						</Link>
					) : (
						'--'
					)}
					<p>Game Father</p>
				</div>
				<div>
					<UpdateGameFatherId />
				</div>
			</div>
		</Card>
	);
};

export default GameSettings;
