import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tag, Image } from 'antd';
import moment from 'moment';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getSingleBulkEmail } from '../../methods/redux/reducers/multiple-emails';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';
import { ImageSection } from '../../users/users-kyc/ImageComponent';

const Style = styled.div`
	/* background-color: #f8f8f8; */
	padding: 20px;
	/* margin-bottom: 15px; */
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;

	& > * {
		display: inline-block;
		/* margin-right: 15px; */

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			/* color: #aaa; */
			/* font-style: italic; */
			font-weight: bold;
		}
	}
`;
const Main = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 1em;

	& > * {
		display: flex;
		flex-direction: column;
		line-height: 1;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			/* color: #aaa; */
			/* font-style: italic; */
			font-weight: bold;
		}
	}
`;
const SingleMultipleEmail = () => {
	const { id } = useParams<string>();
	const dispatch = useAppDispatch();
	const { single } = useAppSelector((store) => store.multipleEmails);

	useEffect(() => {
		dispatch(getSingleBulkEmail(id || '123'));
	}, []);

	return (
		<div>
			<MyCard>
				<Style>
					<Tag color="green">Total Sent ({single?.totalSent || 0})</Tag>
					<Tag color="red">Total Failed ({single?.totalFailed || 0})</Tag>
				</Style>
				<Style>
					<div>
						<p className="value">{single?.creatorEmail || '---'}</p>
						<p className="label">Sender</p>
					</div>
					<div>
						<p className="value">{single?.emails?.length || 0}</p>
						<p className="label">No of Recipient</p>
					</div>
					<div>
						<p className="value">
							{single.createdAt
								? moment(single?.createdAt).format('MMM DD, YY / hh:mm:ss a')
								: '--'}
						</p>
						<p className="label">Date created</p>
					</div>
					<div>
						<p className="value">
							{single.deliveryDate
								? moment(single?.deliveryDate).format('MMM DD, YY / hh:mm:ss a')
								: '--'}
						</p>
						<p className="label">Delivery Date</p>
					</div>
				</Style>
			</MyCard>
			<br />
			<MyCard>
				<Main>
					<div>
						<span className="label">Status</span>
						<span className="value">{single?.deliveryStatus || '--'}</span>
					</div>
					<div>
						<span className="label">Subject</span>
						<strong>{single?.subject}</strong>
					</div>
					<div>
						<span className="label">Preview message</span>
						<strong>{single?.previewMessage}</strong>
					</div>
					<div>
						<span className="label">Banner Image</span>
						<ImageSection>
							{single?.bannerUrl ? (
								<Image
									width="100%"
									height="100%"
									src={single?.bannerUrl}
									alt="Unable to load Image"
								/>
							) : (
								'NO IMAGE'
							)}
						</ImageSection>
					</div>
					<div>
						<span className="label">Content</span>
						<span>{parse(single?.content || '')}</span>
					</div>
				</Main>
			</MyCard>
			<br />
			<MyCard>Actions</MyCard>
		</div>
	);
};

export default SingleMultipleEmail;
