import { Line } from '@ant-design/plots';
import { DatePicker, Spin } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';

const TitleSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
`;
const RequestsStatistics = () => {
	const [data, setData] = useState({
		loading: false,
		itemsList: [],
		sum: 0,
	});
	const [selectedDate, setSelectedDate] = useState(
		moment().format('YYYY-MM-D')
	);
	const getData = async () => {
		setData({ ...data, loading: true });
		try {
			const res: any = await baseuri.get(
				'admin/maintenance/request-counter/date',
				{ params: { dateFormat: selectedDate } }
			);
			if (res.status === 200) {
				let sum = 0;

				for (let i = 0; i < res.data.data.length; i++) {
					sum += Number(res.data.data[i].requestCount);
				}
				setData({ ...data, loading: false, itemsList: res.data.data, sum });
			}
		} catch (error) {
			setData({ ...data, loading: false });
		}
	};

	useEffect(() => {
		getData();
	}, [selectedDate]);

	const config = {
		data: data?.itemsList,
		// padding: 'auto',
		xField: 'timePeriod',
		yField: 'requestCount',
		xAxis: {
			// type: 'timeCat',
			tickCount: 5,
		},
		yAxis: {
			tickMethod: 'r-pretty',
		},
		// smooth: true,
	};
	return (
		<MyCard>
			<Spin spinning={data.loading} tip="Loading...">
				<TitleSection>
					<strong>Request Statistics By date</strong>
					<DatePicker
						size="small"
						allowClear={false}
						format="ll"
						value={moment(selectedDate)}
						onChange={(date1) =>
							date1?.isSameOrBefore(moment()) &&
							setSelectedDate(date1.format('YYYY-MM-D'))
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
					/>
				</TitleSection>
				<br />
				Total Requests: <strong>{data.sum}</strong>
				{/* eslint-disable react/jsx-props-no-spreading */}
				<Line {...config} />
			</Spin>
		</MyCard>
	);
};

export default RequestsStatistics;
