import { Button, Card } from 'antd';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FilterSection = styled.div``;

const MyButton = styled(Button)`
	color: #383838;
	margin-right: 20px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const DepositFilters = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const selectedFilter = location.pathname.split(/[/,?,&]/)[3];

	const filterEnum = [
		{ name: 'First Time Funders', value: 'first-funders' },
		{ name: 'First 30 days  Funders', value: 'thirtyDaysOfReg-funders' },
	];
	return (
		<FilterSection>
			<Card>
				<p>
					<strong>Special Filters</strong>
				</p>

				<div>
					{filterEnum.map((n) => (
						<MyButton
							className={selectedFilter === n.value ? 'selected' : ''}
							key={n.value}
							onClick={() =>
								navigate(
									`/transactions/deposits/${
										n.value
									}?startDate=2021-10-01&endDate=${moment().format(
										'YYYY-MM-DD'
									)}&perPage=10&pageId=1&sort=desc`
								)
							}
						>
							{n.name}
						</MyButton>
					))}
				</div>
			</Card>
		</FilterSection>
	);
};

export default DepositFilters;
