import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface IUpdateKYC {
	details: any;
}

const UpdateKYC: FC<IUpdateKYC> = ({ details }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [levelDetails, setInputDetails] = useState({
		levelName: details?.levelName,
		monthlyWithdrawal: Number(details?.monthlyWithdrawal),
		dailyWithdrawal: Number(details?.dailyWithdrawal),
		id: details?._id,
	});

	useEffect(() => {
		setInputDetails({
			levelName: details?.levelName,
			monthlyWithdrawal: Number(details?.monthlyWithdrawal),
			dailyWithdrawal: Number(details?.dailyWithdrawal),
			id: details?._id,
		});
	}, [details]);

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(`admin/kyclevel/kyc-level`, {
				...levelDetails,
				id: details?._id,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('level updated Successfully');
			} else {
				setloading(false);
				message.error(res?.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error('something went wrong');
		}
	};

	return (
		<>
			<Button
				shape="round"
				size="small"
				onClick={() => setVisible(true)}
				style={{ width: '100px' }}
			>
				details
			</Button>
			<Modal
				title={`Update ${details?.levelName}`}
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Level Name :{' '}
					<Input
						placeholder="input level name"
						value={levelDetails?.levelName}
						onChange={(e: any) =>
							setInputDetails({ ...levelDetails, levelName: e.target.value })
						}
					/>
				</p>
				<p>
					Maximum Daily Withdrawal (in dollars) :{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.dailyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								dailyWithdrawal: Number(e.target.value),
							})
						}
					/>
				</p>
				<p>
					Maximum Monthly Withdrawal (in dollars):{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.monthlyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								monthlyWithdrawal: Number(e.target.value),
							})
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default UpdateKYC;
