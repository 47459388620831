import {
	Select,
	Button,
	Input,
	Modal,
	Popconfirm,
	Tag,
	message,
	Checkbox,
} from 'antd';
import { useState } from 'react';
import { chooseEnvironment } from '../../methods/baseuri';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { createACronjob } from '../../methods/redux/reducers/cronjobs';

const CreateCronJob = () => {
	const dispatch = useAppDispatch();
	const cronsBaseUrl = chooseEnvironment().base;

	const { systemTime, subsystemTime } = useAppSelector(
		(store) => store.cronjob
	);
	const [visible, setVisible] = useState(false);
	const [allowSubInterval, setAllowSubInterval] = useState(false);
	const [data, setData] = useState({
		title: '',
		url: '',
		interval: '',
		subInterval: '',
	});

	const handleCreate = () => {
		const firstSlashInUrl = data.url[0];
		if (firstSlashInUrl === '/') {
			const url = data.url.substring(1);
			dispatch(createACronjob({ ...data, url: cronsBaseUrl + url }));
		} else {
			message.info(
				'The url is not in the correct format. Add a slash (/) just as it is in the documentation'
			);
		}
	};

	const options = systemTime.map((option: any) => ({
		value: option,
		label: option.replace(/_/g, ' '),
	}));

	const subOptions = subsystemTime.map((option: any) => ({
		value: option,
		label: option.replace(/_/g, ' '),
	}));

	return (
		<>
			<Button type="primary" onClick={() => setVisible(true)}>
				Add New Cronjob
			</Button>

			<Modal
				title="Add New Cronjob"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button type="primary">create</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Title</p>
					<Input.TextArea
						placeholder="Cronjob Title"
						value={data.title}
						onChange={(e: any) => setData({ ...data, title: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Backend URL to Update</p>
					<p>
						Type in the path name just the way it is in the api documentation
					</p>
					<Tag>{cronsBaseUrl}</Tag>
					<Input
						value={data.url}
						placeholder="/cronjob/savings/stable-currency/apy/set-today-apr"
						onChange={(e: any) => setData({ ...data, url: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Time Interval for Cronjob to Run</p>
					<Select
						onChange={(e: any) => {
							setData({
								...data,
								interval: e,
							});
						}}
						defaultValue={data.interval}
						style={{ width: '100%' }}
					>
						{options.map((systemTimeOptions: any) => (
							<Select.Option
								key={systemTimeOptions.value}
								value={systemTimeOptions.value}
							>
								{systemTimeOptions.label}
							</Select.Option>
						))}
					</Select>
				</div>

				<br />

				<Checkbox
					checked={allowSubInterval}
					onChange={(e) => setAllowSubInterval(e.target.checked)}
				>
					Add Sub-interval
				</Checkbox>

				{allowSubInterval && (
					<>
						<br />

						<div>
							<p>Sub Interval</p>
							<Select
								onChange={(e: any) => {
									setData({
										...data,
										subInterval: e,
									});
								}}
								defaultValue={data.interval}
								style={{ width: '100%' }}
							>
								{subOptions.map((systemTimeOptions: any) => (
									<Select.Option
										key={systemTimeOptions.value}
										value={systemTimeOptions.value}
									>
										{systemTimeOptions.label}
									</Select.Option>
								))}
							</Select>
						</div>
					</>
				)}
			</Modal>
		</>
	);
};

export default CreateCronJob;
