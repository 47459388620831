import { AuditOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;

interface ISendStatement {
	userId: string;
	userEmail: string;
}

const DateSelector = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
`;
const SendStatement = ({ userId, userEmail }: ISendStatement) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);

	const [receiverEmail, setReceivingEmail] = useState('');

	const [date, setDate] = useState({
		startDate: '2022-10-01',
		endDate: moment().format('YYYY-MM-DD'),
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(
				`admin/users/account/user-transaction-statement`,
				{
					userId,
					receiverEmail: receiverEmail.length > 0 && receiverEmail,
					...date,
				}
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(res.data.message);
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<div>
			<Button
				// style={{ background: '#F46036', color: 'white' }}
				onClick={() => setVisible(true)}
				icon={<AuditOutlined />}
			>
				Send Account Statement
			</Button>

			<Modal
				title="Send Account Statement"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button size="large" loading={loading} type="primary">
							Send Statement
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Send a comprehensive account overview of this customer&apos;s Xend
					Finance savings and transactions
				</p>

				<br />

				<DateSelector>
					<div>
						<p>From</p>
						<DatePicker
							size="large"
							style={{ width: '100%' }}
							allowClear={false}
							format="ll"
							onChange={(date1) =>
								date1?.isSameOrBefore(moment(date.endDate)) &&
								setDate({ ...date, startDate: date1.format('YYYY-MM-DD') })
							}
							disabledDate={(current) =>
								current && current < moment(date.startDate).endOf('day')
							}
							value={moment(date.startDate)}
						/>
					</div>
					<div>
						<p>To</p>
						<DatePicker
							size="large"
							allowClear={false}
							style={{ width: '100%' }}
							format="ll"
							value={moment(date.endDate)}
							onChange={(date1) =>
								date1?.isSameOrAfter(moment(date.startDate)) &&
								setDate({ ...date, endDate: date1.format('YYYY-MM-DD') })
							}
							disabledDate={(current) =>
								current && current > moment().endOf('day')
							}
						/>
					</div>
				</DateSelector>

				<Divider />

				<p>
					<strong>Receiving Email Addresses</strong>
				</p>

				<div>
					<p>Primary Email</p>
					<Input size="large" readOnly value={userEmail} disabled />
				</div>

				<br />

				<div>
					<p>Additional Email</p>
					<Input
						size="large"
						value={receiverEmail}
						onChange={(e) => setReceivingEmail(e.target.value)}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default SendStatement;
