import { Button, Card } from 'antd';

const ProfileSettings = () => (
	<Card>
		<p>Settings</p>

		<div>
			<Button>Update Password</Button>
		</div>
	</Card>
);

export default ProfileSettings;
