import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IColumnItems {
	data: any;
}

const Style = styled(Link)`
	all: unset;
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
	cursor: pointer;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const EmailColumn = ({ data }: IColumnItems) => (
	<Style to="">
		<div>
			<p>{data?.title || '--'}</p>
			<p className="label">Title</p>
		</div>
		<div>
			<p>{data?.type || '---'}</p>
			<p className="label">Type</p>
		</div>
		<div>
			<p>{data?.message || '---'}</p>
			<p className="label">Message</p>
		</div>
		<div>
			<p>{data?.emailSender?.email || '---'}</p>
			<p className="label">Sender</p>
		</div>
		<div>
			<p>
				{data?.emailSender?.email ||
					data?.userId?.username ||
					data?.userId?.fullName}
			</p>
			<p className="label">Recipient</p>
		</div>
		<div>
			<p>
				{data.createdAt
					? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Date created</p>
		</div>
		{/* {data?.status === 'pending' && (
			<div>
				<Start bulkSavingsId={data?._id} />
			</div>
		)}
		{data?.status === 'processing' && (
			<div>
				<Distribute bulkSavingsId={data?._id} />
			</div>
		)} */}
	</Style>
);
export default EmailColumn;
