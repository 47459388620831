import { useEffect } from 'react';
import SettingsSection from './SettingsSection';
import HeaderSection from './HeaderSection';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveFundsRobotConfig,
	retrieveFundsRobotIntervals,
} from '../../methods/redux/reducers/fundsRobots';

const FundsRobot = () => {
	// const [refresh, setRefresh] = useState('');
	const { config } = useAppSelector((store) => store.fundsRobot);
	// const [timer, setTimer] = useState(0);
	// const [startTimer, setStartTimer] = useState(
	// 	config?.find((a: any) => a?.name === 'isCronActive')?.value === 'true'
	// );

	// const handleSwitch = (value: any, id: string) => {
	// 	baseuri
	// 		.patch(`/admin/robot/fund-robot-config`, {
	// 			id,
	// 			value: `${value}`,
	// 		})
	// 		.then((res: any) => {
	// 			message.info(res.data.message);
	// 		})
	// 		.catch((err: any) => {
	// 			message.error(err.response.data.message);
	// 		});
	// };

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(retrieveFundsRobotConfig());
		dispatch(retrieveFundsRobotIntervals());
	}, []);

	return (
		<div>
			{/* <CreateFundRobot /> */}
			<HeaderSection
				cronDetails={config}
				// timer={timer}
				// startTimer={startTimer}
				// setTimer={setTimer}
				// setStartTimer={setStartTimer}
			/>

			<SettingsSection cronDetails={config} />
		</div>
	);
};

export default FundsRobot;
