import { Divider, Pagination, Card } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveApyHistory } from '../../methods/redux/reducers/reports';
import ApyHistoryItem from './HistoryItem';
import SearchFilter from '../../component/Filter';
import remakeQueryString from '../../methods/utils/remake-query-string';
import NativeNumber from '../../component/Numeral';

const Style = styled.div``;

const ApyHistory = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { apyHistory } = useAppSelector((store) => store.reports);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(apyHistory.paginator.perPage),
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveApyHistory(queryData));
		}
	}, [searchParams]);

	return (
		<Style>
			<Card>
				<h1>Savings Apy History</h1>

				<Divider />

				<SearchFilter
					title="Search Apy History"
					searchFilters={[
						{
							label: 'Start Date',
							// name: 'startDate',
							name: 'fromDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'End Date',
							// name: 'endDate',
							name: 'toDate',
							htmlElement: 'datepicker',
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<p>
					Total of <strong>{apyHistory.paginator.itemCount} items</strong> found
				</p>

				{apyHistory?.aggregation && (
					<>
						<Divider />
						<p>
							This is the sum of the total earnings for fixed savings and
							flexible savings for the selected date range.
						</p>
						{apyHistory?.aggregation?.map((details: any) => (
							<div key={details}>
								<p style={{ display: 'inline-block', marginRight: 20 }}>
									Flexible Savings:{' '}
									<NativeNumber
										value={details?.flexibleSavings}
										decimalPlaces={4}
									/>{' '}
									USD
								</p>
								<p style={{ display: 'inline-block', marginRight: 20 }}>
									Fixed Savings:{' '}
									<NativeNumber
										value={details?.fixedSavings}
										decimalPlaces={4}
									/>{' '}
									USD
								</p>
								<p style={{ fontWeight: 'bold' }}>
									Total:{' '}
									<NativeNumber
										value={
											Number(details?.flexibleSavings || 0) +
											Number(details?.fixedSavings || 0)
										}
										decimalPlaces={4}
									/>{' '}
									USD
								</p>
							</div>
						))}
					</>
				)}

				<Divider />

				<div>
					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={apyHistory.paginator.itemCount}
						current={apyHistory.paginator.currentPage}
						pageSize={apyHistory.paginator.perPage}
					/>
				</div>

				<br />

				{apyHistory.itemsList.map((item: any) => (
					<ApyHistoryItem key={item._id} data={item} />
				))}

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={apyHistory.paginator.itemCount}
					current={apyHistory.paginator.currentPage}
					pageSize={apyHistory.paginator.perPage}
					showSizeChanger
				/>
			</Card>
		</Style>
	);
};

export default ApyHistory;
