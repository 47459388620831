import { createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../../../baseuri';

export const retrieveGameActions = createAsyncThunk(
	'game/retrieveGameActions',
	async (params: any) => {
		const res = await baseuri.get('/admin/game-action', { params });
		return res.data.data;
	}
);

export const retrieveSingleGameAction = createAsyncThunk(
	'game/retrieveSingleGameActions',
	async (params: any) => {
		const res = await baseuri.get('/admin/game-action/single', { params });

		return res.data.data;
	}
);

export const createGameActions = createAsyncThunk(
	'game/createGameActions',
	async (data: any) => {
		await baseuri.post('/admin/game-action', data);
	}
);

export const retrieveGameActionsForAGame = createAsyncThunk(
	'game/retrieveGameActionsForAGame',
	async (params: any) => {
		const res = await baseuri.get('/admin/game-action/action-point', {
			params,
		});
		return res.data.data;
	}
);

export const assignGameActions = createAsyncThunk(
	'game/createGameActions',
	async (data: any, { dispatch }) => {
		await baseuri.post('/admin/game-action/assign-game', data);
		dispatch(retrieveGameActionsForAGame({ gameId: data.gameId }));
	}
);

export const removeGameActionFromGame = createAsyncThunk(
	'game/removeGameActionFromGame',
	async (params: any, { dispatch }) => {
		const { gameActionPointId, gameId } = params;
		await baseuri.delete('/admin/game-action/action-point', {
			params: { gameActionPointId },
		});
		dispatch(retrieveGameActionsForAGame({ gameId }));
	}
);
