import { Card, Divider } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveUsersOldPlans } from '../../methods/redux/reducers/maintenance';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import TriggerXendRewardWithdrawalTillProMigration from './TriggerXendRewardWithdraw';

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& > * {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 10px;
	}

	& .label {
		font-size: 13px;
		color: #808080;
		font-style: italic;
	}
`;

const UserOldPlans = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const { oldPlans } = useAppSelector((store) => store.maintenance);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		if (userId) {
			dispatch(retrieveUsersOldPlans({ userId, proMigration: true }));
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	const calculateRewardTillProMigrate = (
		rewardData: any,
		proMigrationDate: any
	) => {
		if (!rewardData) {
			return 0;
		}
		const { startTime, lockedPeriod, rewardInUSD } = rewardData;

		const durationInDays =
			moment.unix(lockedPeriod).diff(moment.unix(startTime), 'days') + 1;

		const durationInDaysTillProMigration =
			moment.unix(lockedPeriod).diff(moment(proMigrationDate), 'days') + 1;

		return (
			((durationInDays - durationInDaysTillProMigration) *
				Number(rewardInUSD)) /
			durationInDays
		);
	};

	return (
		<Card>
			<h1>
				Customer Old Fixed Plans{' '}
				{userId && user && (
					<span>
						(
						<Link to={`/user-profile/${user.profile._id}`}>
							{user.profile.username}
						</Link>
						)
					</span>
				)}
			</h1>

			<Divider />

			{oldPlans.fixed.map((fixedPlan: any) => (
				<Style key={fixedPlan._id}>
					<div>
						<Link
							to={`/core-modules/fixed-savings/single/${fixedPlan.proMigrationFixedPlanId?.fixedSavingsId}`}
						>
							{fixedPlan.planName}
						</Link>
						<p className="label">Plan Name</p>
					</div>
					<div>
						<p>
							{fixedPlan.proMigrationAmount ? (
								<span>{fixedPlan.proMigrationAmount} BUSD</span>
							) : (
								'--'
							)}
						</p>
						<p className="label">Amount at Migration</p>
					</div>
					<div>
						<p>{fixedPlan.migrateStatus}</p>
						<p className="label">Migration Status</p>
					</div>
					<div>
						<p>
							{fixedPlan.proMigratoinDate
								? moment(fixedPlan.proMigratoinDate).format(
										'MMM DD, YY / hh:mm:ss a'
								  )
								: '--'}
						</p>
						<p className="label">Migration Date</p>
					</div>
					<div>
						<p>{fixedPlan.rewardData?.rewardInUSD} USD</p>
						<p className="label">Total Reward</p>
					</div>
					{fixedPlan.rewardData && (
						<div>
							<p>
								{calculateRewardTillProMigrate(
									fixedPlan.rewardData,
									fixedPlan.proMigratoinDate
								)}{' '}
								USD
							</p>
							<p className="label">Reward Due by Pro Migration Date</p>
						</div>
					)}
					{fixedPlan.rewardData &&
						Number(fixedPlan.rewardData?.rewardPaid) === 0 && (
							<div>
								<TriggerXendRewardWithdrawalTillProMigration
									amountInUSD={calculateRewardTillProMigrate(
										fixedPlan.rewardData,
										fixedPlan.proMigratoinDate
									)}
									planId={fixedPlan._id}
									username={user.profile?.username}
									recall={() =>
										dispatch(
											retrieveUsersOldPlans({ userId, proMigration: true })
										)
									}
								/>
							</div>
						)}
				</Style>
			))}
		</Card>
	);
};

export default UserOldPlans;
