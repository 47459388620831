import { Button, Modal, Divider, Select, message } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { addNewTeamMember } from '../../methods/redux/reducers/roles';
import RemoveTeamMember from './RemoveTeamMember';

interface IUpdateAdminTeamMember {
	member: any;
}

const UpdateAdminTeamMember = ({ member }: IUpdateAdminTeamMember) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const { roles } = useAppSelector((store) => store.roles);

	const [selectedRole, setSelectedRole] = useState('');

	const updateAdminRoleId = () => {
		if (selectedRole.length === 0) {
			message.error('You have not selected any role.');
		} else if (selectedRole === member?.role?._id) {
			message.error(
				'You have selected the same role as the Admins current role.'
			);
		} else {
			dispatch(
				addNewTeamMember({ email: member?.email, roleId: selectedRole })
			);
			setVisible(false);
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Update</Button>

			<Modal
				visible={visible}
				width={320}
				onCancel={() => setVisible(false)}
				footer={null}
				title={`Update Admin Member (${member?.fullName})`}
			>
				<div>
					<Select
						defaultValue={member?.role?._id}
						size="large"
						style={{ width: '100%' }}
						onChange={(e) => setSelectedRole(e)}
					>
						{roles.map((single: any) => (
							<Select.Option value={single._id}>{single.title}</Select.Option>
						))}
					</Select>
				</div>

				<br />

				<Button block onClick={() => updateAdminRoleId()} type="primary">
					Update
				</Button>

				<Divider />

				<p>Remove {member?.fullName} from being an Admin</p>
				<br />
				<RemoveTeamMember userId={member._id} close={() => setVisible(false)} />
			</Modal>
		</>
	);
};

export default UpdateAdminTeamMember;
