import { Button, message, Modal } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
`;
interface IEmailBounceDelete {
	details: any;
}
const EmailBounceDelete: FC<IEmailBounceDelete> = ({ details }) => {
	const remove = async () => {
		try {
			// setloading(true);
			const res = await baseuri.post(`admin/users/delete-bounced-user`, {
				email: details?.profile?.email || details?.email,
			});
			if (res.status === 200) {
				// setloading(false);
				message.success('email bounce deletion Successfully');
			}
		} catch (e) {
			// setloading(false);
			message.error('Could not complete operation');
		}
	};
	const confirm = () => {
		Modal.confirm({
			title:
				'Are you sure you want to delete this user email as a bounce email?',
			onOk() {
				remove();
			},
		});
	};
	return (
		<DeleteSection>
			<Button
				danger
				type="primary"
				onClick={confirm}
				disabled={!details?.profile?.emailBounce}
			>
				Delete
			</Button>
		</DeleteSection>
	);
};

export default EmailBounceDelete;
