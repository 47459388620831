import { Button, Card, Divider, Popconfirm } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getCurrencies } from '../../../methods/redux/reducers/currency';
import {
	deleteGame,
	retrieveGameSubscribers,
	retrieveSingleGame,
} from '../../../methods/redux/reducers/games';
import ActivateGame from './ActivateGame';
// import GameTasks from '../tasks/GameTasks';
import AddGameFile from './AddGameFile';
import GameDetails from './GameDetails';
import GameImageFiles from './GameFile';
import { retrieveGameActions } from '../../../methods/redux/reducers/games/actions/game-actions';
import GameActions from './GameActions';
import RewardDetails from './RewardDetails';
import { getGlobalEnumsData } from '../../../methods/redux/reducers/general';

const Style = styled(Card)<{ statusColor: string }>`
	& .status {
		font-weight: 600;
	}
	& .details > * {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 20px;

		& .label {
			font-style: italic;
			color: #aaa;
		}
	}

	& .title-desc {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}

		& .label {
			font-style: italic;
			color: #aaa;
		}
	}

	& .actions > * {
		display: inline-block;
		margin-right: 20px;
	}

	& .status {
		text-transform: uppercase;
		text-align: center;
		padding: 2px;
		font-size: 12px;
		letter-spacing: 5px;
		color: white;
		background-color: ${(p) => p.statusColor};
	}
`;

const SingleGame = () => {
	const dispatch = useAppDispatch();

	const { gameId } = useParams();
	const { singleGame, subscribers } = useAppSelector((store) => store.games);
	useEffect(() => {
		dispatch(retrieveSingleGame({ gameId }));
		dispatch(retrieveGameSubscribers({ gameId, perPage: 1 }));
		dispatch(getCurrencies({}));
		dispatch(retrieveGameActions({}));
		dispatch(getGlobalEnumsData());
	}, [gameId]);

	const pickColor = (status: string) => {
		switch (status) {
			case 'pending':
				return 'orange';
			case 'deleted':
				return 'red';
			case 'active':
				return 'green';
			case 'running':
				return 'blue';
			default:
				return '#ccc';
		}
	};

	return (
		<Style statusColor={pickColor(singleGame.status)}>
			<p className="status">{singleGame.status}</p>
			<h1>Manage Game [{singleGame.title}]</h1>
			{/* <Switch
				disabled={singleGame.gameLogic === 'Static'}
				checked={singleGame.autoJoin}
				onChange={(val) =>
					dispatch(
						updateGame({
							data: { autoJoin: String(val) },
							gameId: singleGame._id,
						})
					)
				}
			/> */}
			{/* <p>
				Auto Join{' '}
				<Tooltip title="Users will be automatically added to this game if turned on">
					<InfoCircleOutlined style={{ color: 'orange', cursor: 'help' }} />
				</Tooltip>
			</p> */}
			<Divider />
			<div className="actions">
				<Link to="./subscribers">
					{subscribers.paginator.itemCount} Participants
				</Link>
				<Link to="./game-action-activities">Activities</Link>
				<Link to="./rewards">Reward</Link>
				<ActivateGame game={singleGame} />
			</div>
			<Divider />
			<GameActions gameId={gameId} />
			<Divider />
			<GameDetails gameId={gameId} />
			<Divider />

			<RewardDetails gameId={gameId} />

			<Divider />
			<div>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h2>Image Files</h2>
						<AddGameFile fileType="image" gameId={singleGame._id} />
					</div>
					<div>
						{singleGame.imageFiles ? (
							singleGame.imageFiles.map((photo: any) => (
								<GameImageFiles
									fileType="image"
									gameFile={photo}
									key={photo._id}
									gameId={singleGame._id}
								/>
							))
						) : (
							<p>No image files available</p>
						)}
					</div>
				</div>

				<Divider />

				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h2>Video Files</h2>
						<AddGameFile gameId={singleGame._id} fileType="video" />
					</div>
					<div>
						{singleGame.videoFiles.map((vid: any) => (
							<GameImageFiles
								gameId={singleGame._id}
								fileType="video"
								gameFile={vid}
								key={vid._id}
							/>
						))}
					</div>
				</div>
			</div>
			{/* <Divider />

			<GameTasks gameId={singleGame._id} /> */}
			<Divider />
			<Popconfirm
				onConfirm={() => dispatch(deleteGame({ gameId }))}
				title="Are you sure you want to delete this game?"
			>
				<Button danger type="primary">
					Delete Game
				</Button>
			</Popconfirm>
		</Style>
	);
};

export default SingleGame;
