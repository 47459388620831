import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const createRole = createAsyncThunk(
	'roles/createRole',
	async (data: any) => {
		const res = await baseuri.post('/admin/roles', data);
		message.success('Created successfully');
		return res.data.data;
	}
);

export const updateAdminRole = createAsyncThunk(
	'roles/updateAdminRole',
	async (data: any) => {
		const res = await baseuri.put('/admin/roles', data);
		message.success('Updated successfully!');
		return res.data.data;
	}
);

export const createPermission = createAsyncThunk(
	'roles/createPermission',
	async (data: any) => {
		const res = await baseuri.post('/admin/permissions', data);
		if (res.status === 200) {
			message.success(res.data.message);
		} else {
			message.info(res?.data?.error_details);
		}
		return res.data.data;
	}
);
export const updatePermission = createAsyncThunk(
	'roles/updatePermission',
	async (data: any) => {
		const res = await baseuri.put('/admin/permissions', data);
		if (res.status === 200) {
			message.success('Updated successfully');
		} else {
			message.info(res?.data?.error_details);
		}
		return res.data.data;
	}
);
export const removePermission = createAsyncThunk(
	'roles/removePermission',
	async (data: any) => {
		const res = await baseuri.delete('/admin/permissions', {
			params: { id: data?.id },
		});
		message.success('Deleted successfully');
		return res.data.data;
	}
);

export const additionalPermission = createAsyncThunk(
	'roles/additionalPermission',
	async (data: any) => {
		const res: any = await baseuri.post('/admin/assign/permissions', data);
		if (res.status === 200) {
			message.success('Added successfully successfully');
		} else {
			message.info(res?.data?.error_details);
		}

		return res.data.data;
	}
);

export const removeAdditionalPermission = createAsyncThunk(
	'roles/removeAdditionalPermission',
	async (data: any) => {
		await baseuri.post('/admin/assign/permissions/remove', data);
		message.success('Removed successfully successfully');
	}
);

export const deleteRole = createAsyncThunk(
	'roles/deleteRole',
	async (roleId: any) => {
		const res = await baseuri.delete('/admin/roles', {
			params: { id: roleId },
		});
		return res.data.data;
	}
);

export const retrieveAdminTeam = createAsyncThunk(
	'roles/retrieveAdminTeam',
	async (params: any) => {
		const res = await baseuri.get('/admin/admin-team', { params });
		return res.data.data;
	}
);

export const retrieveRoles = createAsyncThunk(
	'roles/retrieveRoles',
	async (params: any) => {
		const res = await baseuri.get('/admin/roles', { params });
		return res.data.data;
	}
);

export const addNewTeamMember = createAsyncThunk(
	'roles/addNewTeamMember',
	async (data: any) => {
		// const res = await baseuri.post('/admin/assign/permissions', data);
		const res = await baseuri.post('/admin/admin-team', data);
		message.success('Added Successfully!');
		return res.data.data;
	}
);

export const removeTeamMember = createAsyncThunk(
	'roles/removeTeamMember',
	async (userId: any) => {
		const res = await baseuri.delete('/admin/admin-team', {
			params: { userId },
		});
		return res.data.data;
	}
);

export const retrieveAdminPermissions = createAsyncThunk(
	'roles/retrieveAdminPermissions',
	async () => {
		const res = await baseuri.get('/admin/admin-team/permissions');
		return res.data.data;
	}
);
export const getAllPermissions = createAsyncThunk(
	'roles/getAllPermissions',
	async () => {
		const res = await baseuri.get('/admin/permissions');
		return res.data.data;
	}
);

const rolesRx = createSlice({
	name: 'roles',
	initialState: {
		adminUsers: {
			itemsList: [],
			itemCount: 0,
			perPage: 0,
			currentPage: 1,
		},
		roles: [],
		permissions: [],
		allPermissions: {
			itemsList: [],
			paginator: {} as any,
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveAdminTeam.fulfilled, (store, action) => {
				const state = store;
				const { itemsList, paginator } = action.payload;
				state.adminUsers = { ...state.adminUsers, ...paginator, itemsList };
				return state;
			})
			.addCase(retrieveRoles.fulfilled, (store, action) => {
				const state = store;
				state.roles = action.payload;
			})
			.addCase(retrieveAdminPermissions.fulfilled, (store, action) => {
				const state = store;
				const permissions: any = Object.keys(action.payload.permissions);
				state.permissions = permissions;
			})
			.addCase(getAllPermissions.fulfilled, (store, action) => {
				const state = store;
				state.allPermissions = { ...state.allPermissions, ...action?.payload };
			}),
});

export default rolesRx.reducer;
