import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveCronjobIntervals,
	retrieveCronjobs,
} from '../../methods/redux/reducers/cronjobs';
import CreateCronJob from './CreateCronJob';
// import DeleteCron from './DeleteCron';
import SingleCronjob from './SingleCronjob';

const AllCronJobs = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { jobs } = useAppSelector((store) => store.cronjob);

	useEffect(() => {
		dispatch(retrieveCronjobIntervals());

		setSearchParams({
			pageId: '1',
			perPage: String(jobs.paginator.perPage),
		});
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());

		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveCronjobs(queryData));
		}
	}, [searchParams]);

	return (
		<div>
			<Card>
				<h2>Backend Cron Jobs</h2>
				<p>
					Manage cronjobs running different operations for the application
					backend.
				</p>
			</Card>
			<br />

			<Card>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h2>Total of {jobs.paginator?.itemCount} Cron Jobs</h2>
					<CreateCronJob />
				</div>

				{jobs.itemsList.map((item: any) => (
					<SingleCronjob key={item._id} job={item} />
				))}

				<Divider />

				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					pageSize={jobs?.paginator?.perPage || 0}
					current={jobs?.paginator?.currentPage || 0}
					total={jobs?.paginator?.itemCount || 0}
					size="small"
				/>
			</Card>
		</div>
	);
};

export default AllCronJobs;
