import { Button, Input, Modal, Popconfirm, Select } from 'antd';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateCronjob } from '../../methods/redux/reducers/cronjobs';

interface IEditCron {
	item: any;
}
const EditCron: FC<IEditCron> = ({ item }) => {
	const dispatch = useAppDispatch();

	const { systemTime, subsystemTime } = useAppSelector(
		(store) => store.cronjob
	);

	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({
		title: item.title,
		url: item.url,
		interval: item.interval,
		subInterval: item.subInterval,
	});

	const handleCreate = () => {
		dispatch(updateCronjob({ jobId: item._id, data }));
		setVisible(false);
	};

	const options = systemTime.map((option: any) => ({
		value: option,
		label: option.replace(/_/g, ' '),
	}));

	const subIntervalOptions = subsystemTime.map((option: any) => ({
		value: option,
		label: option.replace(/_/g, ' '),
	}));

	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Edit
			</Button>

			<Modal
				title="Edit Cronjob"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button type="primary">Update</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Title</p>
					<Input.TextArea
						placeholder="input Title"
						value={data?.title}
						onChange={(e: any) => setData({ ...data, title: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>URL</p>
					<Input.TextArea
						value={data?.url}
						onChange={(e: any) => setData({ ...data, url: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Interval</p>
					<Select
						onChange={(e: any) => {
							setData({
								...data,
								interval: e,
							});
						}}
						defaultValue={data.interval}
						style={{ width: '100%' }}
					>
						{options.map((systemTimeOptions: any) => (
							<Select.Option
								key={systemTimeOptions.value}
								value={systemTimeOptions.value}
							>
								{systemTimeOptions.label}
							</Select.Option>
						))}
					</Select>
				</div>

				<br />

				<div>
					<p>Sub Interval</p>
					<Select
						onChange={(e: any) => {
							setData({
								...data,
								subInterval: e,
							});
						}}
						defaultValue={data.subInterval}
						style={{ width: '100%' }}
					>
						{subIntervalOptions.map((systemTimeOptions: any) => (
							<Select.Option
								key={systemTimeOptions.value}
								value={systemTimeOptions.value}
							>
								{systemTimeOptions.label}
							</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</>
	);
};

export default EditCron;
