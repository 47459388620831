import { useState } from 'react';
import { Input, Modal, Space, Select, message, Tag, DatePicker } from 'antd';
import SelectItem from 'react-select';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Country, countryCode } from '../../methods/utils/request-helpers';
import baseuri from '../../methods/baseuri';

interface IManualUserKYC {
	user: any;
}

const ManualKYCLevel1 = ({ user }: IManualUserKYC) => {
	const { id } = useParams();
	const [loading, setloading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({
		userId: id,
		firstName: user?.profile?.firstName,
		lastName: user?.profile?.lastName,
		middleName: user?.profile?.middleName,
		dateOfBirth: user?.profile?.dateOfBirth,
		residentAddress: user?.profile?.residentAddress,
		countryCode: user?.profile?.countryCode || 'ng',
		phoneNumber: user?.profile?.phoneNo,
		gender: user?.profile?.gender,
	});

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
	};

	const handleCurrencyUpdate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(
				'admin/custodial-kyc/user/kyc/profile',
				data
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('kyc updated');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	// const handleIconUpload = async (e: any) => {
	// 	const { onSuccess, onError, file } = e;
	// 	// const data = new FormData();
	// 	const icon = await toBase64(file);
	// 	const data = {
	// 		currencyId: currencyData.currencyId,
	// 		icon,
	// 	};
	// 	editCurrencyLogo(data, onSuccess, onError);
	// };

	const options = countryCode.map((option) => ({
		value: option.code,
		label: option.name,
	}));

	return (
		<div>
			<Tag
				style={{ cursor: 'pointer' }}
				color="blue"
				onClick={() => setVisible(true)}
			>
				Upload KYC Level 1
			</Tag>
			<Modal
				okText="Update"
				onOk={handleCurrencyUpdate}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Manually Update User KYC"
				confirmLoading={loading}
			>
				<Space direction="vertical" size={10} style={{ width: '100%' }}>
					<div>
						<p>First Name</p>
						<Input
							name="firstName"
							value={data.firstName}
							placeholder="first name"
							onChange={handleInput}
						/>
					</div>
					<div>
						<p>Last Name</p>
						<Input
							name="lastName"
							value={data.lastName}
							placeholder="last name"
							onChange={handleInput}
						/>
					</div>
					<div>
						<p>Middle Name</p>
						<Input
							name="middleName"
							value={data.middleName}
							placeholder="Middle name"
							onChange={handleInput}
						/>
					</div>
					<div>
						<p>Date of Birth</p>
						<DatePicker
							allowClear={false}
							format="DD/MM/YYYY"
							onChange={(date1: any) =>
								setData({ ...data, dateOfBirth: date1.format('DD/MM/YYYY') })
							}
							disabledDate={(current) =>
								current && current > moment().endOf('day')
							}
						/>
					</div>
					<div>
						<p>Residential Address</p>
						<Input
							name="residentAddress"
							value={data?.residentAddress}
							placeholder="Address"
							onChange={handleInput}
						/>
					</div>
					<div>
						<p>Phone Number</p>
						<Input
							name="phoneNumber"
							value={data?.phoneNumber}
							placeholder="Phone Number"
							onChange={handleInput}
						/>
					</div>
					<div>
						<p>Country</p>
						<SelectItem
							options={options}
							value={Country(data?.countryCode || 'NG')}
							placeholder={Country(data.countryCode || 'NG')}
							defaultValue={{ label: 'Nigeria', value: 'NG' }}
							onChange={(e: any) => {
								setData({ ...data, countryCode: e.value.toLocaleLowerCase() });
							}}
						/>
					</div>
					<div>
						<p>Gender</p>
						<Select
							value={data.gender}
							placeholder="Select Gender"
							style={{ width: '100%' }}
							onChange={(e) => {
								setData({ ...data, gender: e });
							}}
						>
							<Select.Option name="gender" key={1} value="male">
								Male
							</Select.Option>
							<Select.Option key={2} value="female">
								Female
							</Select.Option>
						</Select>
					</div>
				</Space>
			</Modal>
		</div>
	);
};

export default ManualKYCLevel1;
