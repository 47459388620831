// import { useEffect, useState } from 'react';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { retrieveToken } from '../methods/utils/auth';
import { refreshToken } from '../methods/auth';

const AppConnectionToServer = () => {
	const checkTokenIntervalInMinutes = 0.2;
	const checkTokenIntervalInseconds = checkTokenIntervalInMinutes * 60 * 1000;

	// const [visible, setVisible] = useState(false);

	useEffect(() => {
		const autologinIntervalId = setInterval(() => {
			const token = retrieveToken();
			const tokenInfo: any = jwtDecode(token.token);

			if (tokenInfo.exp) {
				const jwtExpiryTime = moment.unix(tokenInfo.exp);
				const minsInbetween = jwtExpiryTime.diff(moment(), 'minutes');
				if (minsInbetween < 5) {
					refreshToken();
				}
			}
		}, checkTokenIntervalInseconds);
		return () => clearInterval(autologinIntervalId);
	}, []);

	return null;
};

export default AppConnectionToServer;
