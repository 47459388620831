import styled from 'styled-components';
import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateSupportedCurrency } from '../../methods/redux/reducers/currency';

const Style = styled.div`
	padding: 10px;
	border: 1px solid #eee;
	border-radius: 3px;
	margin-right: 10px;

	& > div {
		display: flex;
		font-weight: 600;
		align-items: center;
		padding-bottom: 5px;
		margin-bottom: 10px;

		& img {
			width: 21px;
			height: 21px;
			margin-right: 10px;
		}
	}
`;

interface ISavingsCurrency {
	currency: any;
}
const SavingsCurrency = ({ currency }: ISavingsCurrency) => {
	const dispatch = useAppDispatch();
	const [allowSavings, setAllowSavings] = useState(false);

	useEffect(() => {
		setAllowSavings(currency.allowSavings);
	}, [currency]);

	const toggleAllowSavings = (event: any) => {
		setAllowSavings(event);
		dispatch(
			updateSupportedCurrency({
				currencyId: currency._id,
				allowSavings: String(event),
			})
		);
	};

	return (
		<Style>
			<div>
				<img alt="" src={currency.iconUrl} />
				<p>{currency.symbol}</p>
			</div>
			<Switch
				size="default"
				checked={allowSavings}
				onChange={toggleAllowSavings}
			/>
		</Style>
	);
};

export default SavingsCurrency;
