import { Card, Divider } from 'antd';
import styled from 'styled-components';
import { useGetMailingListQuery } from '../../../api-services/newsletter-api';
import MailingLists from './MailingLists';
import MailingListsAdd from './MailingListsAdd';

const Style = styled(Card)``;

const NewsletterMailingListPage = () => {
	const { data: mailingLists } = useGetMailingListQuery({});

	return (
		<Style>
			<h1>Newsletter Mailing Lists</h1>
			<Divider />
			<div>
				<MailingListsAdd />
			</div>

			<br />
			<div>
				{mailingLists?.data?.map((list: any) => (
					<MailingLists mailingList={list} key={list._id} />
				))}
			</div>
		</Style>
	);
};

export default NewsletterMailingListPage;
