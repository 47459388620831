import { Modal } from 'antd';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateSupportedCurrency } from '../../methods/redux/reducers/currency';

const PillStyle = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	margin-right: 12px;
	padding: 3px 7px;
	font-size: 16px;
	border: 1px solid transparent;
	border-radius: 16px;
	background-color: #efefef;
	transition: all 400ms ease;

	& img {
		width: 24px;
		height: 24px;
		margin-right: 5px;
	}

	& button {
		position: absolute;
		opacity: 0;
		border: none;
		border-radius: 50%;
		height: 20px;
		width: 20px;
		right: -4px;
		top: -5px;
		background-color: red;
		color: white;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		font-size: 12px;
		transition: all 300ms ease;
	}

	&:hover {
		border-color: #eee;

		& button {
			opacity: 1;
		}
	}
`;

interface ILoanCurrencyPill {
	currency: any;
}

const LoanCurrencyPill = ({ currency }: ILoanCurrencyPill) => {
	const dispatch = useAppDispatch();
	const stopLoanCurrency = () => {
		dispatch(
			updateSupportedCurrency({ currencyId: currency._id, allowLoans: 'false' })
		);
	};

	return (
		<PillStyle key={currency._id}>
			<button
				type="button"
				onClick={() => {
					Modal.confirm({
						title: 'Disabling Loan',
						content:
							'You about to disable this currency from being Loan. Are you sure of your action?',
						onOk: () => stopLoanCurrency(),
					});
				}}
				title="Disable Staking"
			>
				<p>x</p>
			</button>
			<img src={currency.iconUrl} alt="staking currency" />
			<p>{currency.symbol}</p>
		</PillStyle>
	);
};

export default LoanCurrencyPill;
