import { Card } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import CurrencyItem from './CurrencyItem';
import { retrieveInternalSettings } from '../../methods/redux/reducers/internal-settings';
import GeneralSwapSettings from './GeneralSwapSettings';
import { retrieveSwapProtocols } from '../../methods/redux/reducers/swap';
import CreateSwapProtocol from './CreateSwapProtocol';
import SwapProtocolItem from './SwapProtocolItem';

const ArrangeCurrencies = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	margin-top: 10px;
`;

const CurrencySwapSettings = () => {
	const dispatch = useAppDispatch();

	const { currencies } = useAppSelector((store) => store.currencies);
	const { swapProtocols } = useAppSelector((store) => store.swap);

	useEffect(() => {
		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
		dispatch(retrieveInternalSettings());
		dispatch(retrieveSwapProtocols({}));
	}, []);

	return (
		<div>
			<Card>
				<h2>Allow Swapping Currencies</h2>
				<p>
					Supported currencies and toggle to enable or disable individual
					currency for swapping.
				</p>
				<ArrangeCurrencies>
					{currencies.map((currency: any) => (
						<CurrencyItem key={currency._id} currency={currency} />
					))}
				</ArrangeCurrencies>
			</Card>

			<br />

			<GeneralSwapSettings />

			<br />

			<Card>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h2>Swap Protocols</h2>
					<CreateSwapProtocol />
				</div>

				<br />

				{swapProtocols.itemsList.map((protocol: any) => (
					<SwapProtocolItem key={protocol._id} protocol={protocol} />
				))}
			</Card>
		</div>
	);
};

export default CurrencySwapSettings;
