import { Button, Modal } from 'antd';
import { useState } from 'react';
import { updateBlacklistedUser } from '../../methods/redux/reducers/compliance';

const UpdateBlacklistedItem = ({
	recordId,
	blacklisted,
}: {
	recordId: any;
	blacklisted: any;
}) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const update = async () => {
		setLoading(true);
		await updateBlacklistedUser({
			blacklistedRecordId: recordId,
			blacklisted: String(!blacklisted),
		});
		setLoading(false);
		setVisible(false);
	};

	// const performDelete = async () => {
	// 	setLoading(true);
	// 	await deleteBlacklistedUser({ recordId });
	// 	setLoading(false);
	// };

	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Update
			</Button>

			<Modal
				footer={null}
				open={visible}
				onCancel={() => setVisible(false)}
				title="Update Blacklist Record"
			>
				<Button
					loading={loading}
					disabled={loading}
					type="primary"
					size="large"
					block
					onClick={update}
				>
					Set As {blacklisted ? '[No Longer Blacklisted]' : 'Blacklisted'}
				</Button>

				{/** <Popconfirm onConfirm={performDelete} title="Are you sure?">
					<Button
						size="small"
						type="primary"
						danger
						loading={loading}
						disabled={loading}
					>
						Delete Record
					</Button>
				</Popconfirm> */}
			</Modal>
		</>
	);
};

export default UpdateBlacklistedItem;
