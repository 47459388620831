import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { shortAddress } from '../../methods/utils/general-utils';
import RemoveDoubleFUnding from './RemoveDoubleFUnding';

interface ISingleDoubleFundingRecord {
	item: any;
}

const Style = styled.div`
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 10px;
	background-color: #f8f8f8;
	color: #333;
	text-decoration: none;

	& .details {
		margin-top: 15px;
		border-top: 1px dashed #ccc;
		padding-top: 10px;

		& > * {
			display: inline-block;
			min-width: 100px;
			margin-right: 15px;
			margin-bottom: 5px;

			& p {
				font-size: 14px;
				margin: 0;
				padding: 0;

				&.label {
					color: gray;
					font-size: 14px;
					font-style: italic;
				}
			}
		}
	}
`;

const SingleDoubleFundingRecord = ({ item }: ISingleDoubleFundingRecord) => (
	<Style>
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<p>
				<strong>
					<NativeNumber value={item.amountReceived} decimalPlaces={2} />{' '}
					{item.currencyId?.symbol}
				</strong>
			</p>
			<div>
				<RemoveDoubleFUnding id={item._id} />
			</div>
		</div>
		<div className="details">
			<div>
				{item.userId?._id ? (
					<Link to={`/user-profile/${item.userId?._id}`} target="_blank">
						{item.userId?.username ? (
							<span>@{item.userId.username}</span>
						) : (
							item.userEmail
						)}
					</Link>
				) : (
					item.userEmail
				)}
				<p className="label">Customer</p>
			</div>
			<div>
				{item.userId?._id ? (
					<Link
						to={`/transactions/in-credit/${item.userId?._id}`}
						target="_blank"
					>
						InCredit Transactions
					</Link>
				) : (
					'--'
				)}
				<p className="label">Customers Transactions</p>
			</div>
			<div>
				<p>
					<NativeNumber value={item.totalAmountDeposited} decimalPlaces={2} />{' '}
					{item.currencyId?.symbol}
				</p>
				<p className="label">Total Amount Deposited</p>
			</div>
			<div>
				<a
					href={`https://${
						item.blockchainNetworkId
							? item.blockchainNetworkId.explorerUrl
							: 'bscscan.com'
					}/tx/${item.transactionHash}`}
					target="_blank"
					rel="noreferrer"
				>
					Hash
				</a>
				<p className="label">Transaction Hash</p>
			</div>
			<div>
				<a
					target="_blank"
					rel="noreferrer"
					href={`https://${
						item.blockchainNetworkId
							? item.blockchainNetworkId.explorerUrl
							: 'bscscan.com'
					}/address/${item.depositAddress}#tokentxns`}
				>
					{shortAddress(item.depositAddress)}
				</a>
				<p className="label">Origin Address</p>
			</div>
			<div>
				<a
					target="_blank"
					rel="noreferrer"
					href={`https://${
						item.blockchainNetworkId
							? item.blockchainNetworkId.explorerUrl
							: 'bscscan.com'
					}/address/${item.receivingAddress}#tokentxns`}
				>
					{shortAddress(item.receivingAddress)}
				</a>
				<p className="label">Receiving Address</p>
			</div>
			<div>
				<p>
					{item.durationSinceInitialFunding ? (
						<span>{item.durationSinceInitialFunding} hrs</span>
					) : (
						'---'
					)}
				</p>
				<p className="label">Duration Since Initial Funding</p>
			</div>
			<div>
				<p>{moment(item.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Created</p>
			</div>
			<div>
				<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Updated</p>
			</div>
			<div>
				{item.associatedFundingRequestId ? (
					<Link to={`/funding/${item.associatedFundingRequestId}`}>View</Link>
				) : (
					'--'
				)}
				<p className="label">Associated Funding Request</p>
			</div>
		</div>
	</Style>
);

export default SingleDoubleFundingRecord;
