import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import OtherDetailsSection from './OtherDetailsSection';
import UserInfoSection from './ProfileInfo';
import UserReferralSection from './user-refferals/UserReferralSection';
import TransactionOverview from './TransactionOverview';
import SavingsInfo from './SavingsInfo';
import { useAppDispatch } from '../../methods/redux/hooks';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import { getBlockchainNetworks } from '../../methods/redux/reducers/currency';

const ProfilePageStyle = styled.section`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 1rem;
	position: relative;

	& .upperSection {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 1rem;
		width: 100%;

		@media (min-width: 768px) {
			display: flex;
			flex-direction: row;
		}
	}
`;

const UserProfile = () => {
	const dispatch = useAppDispatch();

	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		dispatch(getUserProfileById(id));
		dispatch(getBlockchainNetworks());
	}, [id]);

	return (
		<ProfilePageStyle>
			<div className="upperSection">
				<UserInfoSection />
				<OtherDetailsSection />
			</div>

			<SavingsInfo />

			<UserReferralSection />
			<TransactionOverview />
		</ProfilePageStyle>
	);
};

export default UserProfile;
