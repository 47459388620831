import { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const Style = styled.div`
	margin-top: 10px;
	display: flex;
	align-items: center;
`;

const EditApySource = () => {
	const dispatch = useAppDispatch();
	const { apySource } = useAppSelector((store) => store.internalSettings);

	const [visible, setVisible] = useState(false);
	const [newApySource, setNewApySource] = useState('');

	const submit = () => {
		dispatch(updateInternalSettings({ apySource: newApySource }));
	};

	return (
		<Style>
			<div>
				<p>
					<strong>{apySource}</strong>
				</p>
			</div>
			<div>
				<Button type="text" onClick={() => setVisible(true)}>
					Edit
				</Button>
				<Modal
					onOk={submit}
					title="Change APY source"
					visible={visible}
					onCancel={() => setVisible(false)}
				>
					<p>Get the source name from the values in apy cronjob.</p>
					<p>Source name</p>
					<Input
						value={newApySource}
						onChange={(e) => setNewApySource(e.target.value)}
					/>
				</Modal>
			</div>
		</Style>
	);
};

export default EditApySource;
