import { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Select, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { updateGame } from '../../../methods/redux/reducers/games';
import { gameFormTypes } from '../../../methods/utils/general-utils';

const GameDetails = ({ gameId }: { gameId: any }) => {
	const dispatch = useAppDispatch();
	const { singleGame } = useAppSelector((store) => store.games);
	const { globalEnums } = useAppSelector((store) => store.general);
	// const { currencies } = useAppSelector((store) => store.currencies);

	const [state, setState] = useState({
		autoJoin: false,
		currencyId: '',
		gameColor: '',
		maxTokenSpend: '0',
		gameLogic: '',
		gameForm: '',
		description: '',
		title: '',
		ugcGameType: '',
		gameFatherId: '',
		readOnly: true,
		dirty: false,
	});

	const update = () => {
		dispatch(
			updateGame({
				data: {
					...state,
					autoJoin: String(state.autoJoin),
					gameId,
				},
				gameId,
			})
		);
		setState({ ...state, readOnly: true });
	};

	useEffect(() => {
		const {
			currencyId,
			gameColor,
			maxTokenSpend,
			gameForm,
			description,
			title,
			ugcGameType,
			gameFatherId,
		} = singleGame;
		setState({
			...state,
			gameFatherId: gameFatherId?._id,
			gameColor,
			maxTokenSpend,
			gameForm,
			description,
			title,
			ugcGameType,
			currencyId: currencyId?._id,
		});
	}, [singleGame]);

	return (
		<>
			<h2>Game Details</h2>
			<div className="title-desc">
				<div>
					<p className="label">Title</p>
					<Input.TextArea
						disabled={state.readOnly}
						onChange={(e) => setState({ ...state, title: e.target.value })}
						value={state.title}
					/>
				</div>
				<div>
					<p className="label">Description</p>
					<Input.TextArea
						disabled={state.readOnly}
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
						value={state.description}
					/>
				</div>
			</div>
			<br />
			<div className="details">
				<div>
					<Input
						disabled={state.readOnly}
						onChange={(e) =>
							setState({ ...state, maxTokenSpend: e.target.value })
						}
						value={state.maxTokenSpend}
					/>
					<p className="label">Max Token Spend</p>
				</div>
				{/** <div>
					<Select
						style={{ width: 200 }}
						onChange={(currencyId) => setState({ ...state, currencyId })}
						disabled={state.readOnly}
						value={state.currencyId}
					>
						<Select.Option>--</Select.Option>
						{currencies.map((currency: any) => (
							<Select.Option key={currency?._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
					<p className="label">Currency</p>
				</div> */}
				{/* <div>
					<Select
						value={state.gameLogic}
						onChange={(gameLogic) => setState({ ...state, gameLogic })}
						disabled={state.readOnly}
						style={{ minWidth: 148, width: '100%' }}
					>
						<Select.Option value="Static">Static</Select.Option>
						<Select.Option value="Dynamic">Dynamic</Select.Option>
					</Select>
					<p className="label">Game Logic</p>
				</div> */}
				<div>
					<Select
						value={state.gameForm}
						onChange={(gameForm) => setState({ ...state, gameForm })}
						disabled={state.readOnly}
						style={{ minWidth: 148, width: '100%' }}
					>
						<Select.Option>--</Select.Option>
						{gameFormTypes(globalEnums.GAMES_ENUM.form).map((item) => (
							<Select.Option value={item.value}>{item.name}</Select.Option>
						))}
					</Select>
					<p className="label">Game Form</p>
				</div>
				<div>
					<Select
						disabled={state.readOnly}
						onChange={(gameColor) => setState({ ...state, gameColor })}
						value={state.gameColor}
						style={{ width: '100%' }}
					>
						<Select.Option value="black">Black</Select.Option>
					</Select>
					<p className="label">Game Color</p>
				</div>
				<div>
					{state.readOnly && (
						<div>
							{singleGame.gameFatherId?._id ? (
								<Link to={`/user-profile/${singleGame.gameFatherId?._id}`}>
									{singleGame.gameFatherId?.email}
								</Link>
							) : (
								'--'
							)}
						</div>
					)}
					{!state.readOnly && (
						<Input
							value={state.gameFatherId}
							onChange={(e) =>
								setState({ ...state, gameFatherId: e.target.value })
							}
						/>
					)}

					<p className="label">Game Father</p>
				</div>
				<div>
					{singleGame.lastUpdatedBy?._id ? (
						<Link to={`/user-profile/${singleGame.lastUpdatedBy?._id}`}>
							{singleGame.lastUpdatedBy?.email}
						</Link>
					) : (
						'--'
					)}
					<p className="label">Last Updated By</p>
				</div>
				<div>
					<p>{state.ugcGameType}</p>
					<p className="label">UGC Game Types</p>
				</div>
				<div>
					<p>
						{moment(singleGame.createdAt).format('MMM DD, YY / hh:mm:ss a')}
					</p>
					<p className="label">Created</p>
				</div>
			</div>
			<div>
				{state.readOnly ? (
					<Button onClick={() => setState({ ...state, readOnly: false })}>
						Edit
					</Button>
				) : (
					<Button onClick={update}>Update</Button>
				)}
			</div>
		</>
	);
};

export default GameDetails;
