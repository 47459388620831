import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const getReferrals = createAsyncThunk(
	'referrals/getReferrals',
	async (params: any) => {
		const res = await baseuri.get('/admin/referrals', {
			params: { ...params, sort: 'desc' },
		});
		return res.data.data;
	}
);

export const getReferralRewardSettings = createAsyncThunk(
	'referrals/getReferralRewardSettings',
	async (params: any) => {
		const res = await baseuri.get('/admin/referrals/reward-settings', {
			params,
		});
		return res.data.data;
	}
);

export const createReferralRewardSettings = createAsyncThunk(
	'referrals/createReferralRewardSettings',
	async (data: any, thunkApi) => {
		const res = await baseuri.post('/admin/referrals/reward-settings', data);
		message.success('Created Successfully!');
		thunkApi.dispatch(getReferralRewardSettings({}));
		return res.data.data;
	}
);

export const editReferralRewardSettings = createAsyncThunk(
	'referrals/editReferralRewardSettings',
	async (data: any, thunkApi) => {
		const res = await baseuri.put('/admin/referrals/reward-settings', data);
		message.success('Edited Successfully!');
		thunkApi.dispatch(getReferralRewardSettings({}));
		return res.data.data;
	}
);

export const deleteReferralRewardSettings = createAsyncThunk(
	'referrals/deleteReferralRewardSettings',
	async (params: any, thunkApi) => {
		await baseuri.delete('/admin/referrals/reward-settings', { params });
		thunkApi.dispatch(getReferralRewardSettings({}));
		message.success('Deleted Successfully!');
	}
);

export const getTopReferrals = createAsyncThunk(
	'referrals/getTopReferrals',
	async (params: any) => {
		const res = await baseuri.get('/data/monthly/top/referalls', {
			params,
		});
		return res.data.data;
	}
);

const referralRx = createSlice({
	name: 'referrals',
	initialState: {
		referrals: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		referralSettings: [],
		topReferrals: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
			record: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getReferrals.fulfilled, (store, action) => {
				const state = store;
				state.referrals = action.payload;
			})
			.addCase(getReferralRewardSettings.fulfilled, (store, action) => {
				const state = store;
				state.referralSettings = action.payload;
			})
			.addCase(getTopReferrals.fulfilled, (store, action) => {
				const state = store;
				state.topReferrals = action.payload;
			}),
});

export default referralRx.reducer;
