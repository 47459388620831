import { useState } from 'react';
import { Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { addNewTeamMember } from '../../methods/redux/reducers/roles';

const AddAdminTeamMember = () => {
	const dispatch = useAppDispatch();

	const { roles } = useAppSelector((store) => store.roles);

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		email: '',
		roleId: '',
	});

	const submit = () => {
		dispatch(addNewTeamMember(state));
		setVisible(false);
	};

	return (
		<div>
			<button type="button" onClick={() => setVisible(true)}>
				Add Member
			</button>

			<Modal
				okText="Add Member"
				onOk={() => submit()}
				width={320}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Add Admin Team Member"
			>
				<p>
					You can assign an admin role to an existing user, and the user will be
					able to login to the admin dashboard and perform actions the role you
					assign allows.
				</p>
				<br />
				<div>
					<p>Email</p>
					<Input
						value={state.email}
						onChange={(e) => setState({ ...state, email: e.target.value })}
						type="email"
					/>
				</div>
				<div>
					<p>Role</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, roleId: e })}
					>
						{roles.map((item: any) => (
							<Select.Option key={item._id} value={item._id}>
								{item.title}
							</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</div>
	);
};

export default AddAdminTeamMember;
