import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const userGlobalSearch = createAsyncThunk(
	'global-search/userGlobalSearch',
	async (searchText: string) => {
		const res = await baseuri.get('/admin/user-global-search', {
			params: { search_text: searchText },
		});
		return res.data.data;
	}
);

export const generalGlobalSearch = createAsyncThunk(
	'global-search/generalGlobalSearch',
	async (searchText: any) => {
		const res = await baseuri.get('/admin/global/search', {
			params: { q: searchText },
		});
		return res.data.data;
	}
);

const globalSearch = createSlice({
	name: 'global-search',
	initialState: {
		userSearch: [],
		generalSearch: {
			users: [],
			statements: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(userGlobalSearch.fulfilled, (store, action) => {
				const state = store;
				state.userSearch = action.payload;
			})
			.addCase(generalGlobalSearch.fulfilled, (store, action) => {
				const state = store;
				state.generalSearch = { ...state.generalSearch, ...action.payload };
			}),
});

export default globalSearch.reducer;
