import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, InputNumber } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

interface IUpdateTrustScore {
	id: any;
	mainScore: any;
}

const ScoreSection = styled.div`
	display: flex;
	gap: 2em;
`;
const TrustScoreUpdate: FC<IUpdateTrustScore> = ({ id, mainScore }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [score, setScore] = useState(mainScore);
	const updateTrustScore = async () => {
		setloading(true);
		try {
			const res: any = await baseuri.post(`admin/users/set-trustscore`, {
				userId: id,
				score,
				form: 'inc',
			});

			if (res.status === 200) {
				message.success('trust score updated successfully');
				setloading(false);
				setVisible(false);
			} else {
				message.info(res.data.error_details);
				setloading(false);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.error_details);
		}
	};
	return (
		<>
			<Button size="small" onClick={() => setVisible(true)} loading={loading}>
				Update Trust Score
			</Button>
			<Modal
				width="350px"
				// className="modalPage"
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={updateTrustScore}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<p>Update Trust Score</p>
				<br />
				<br />
				<ScoreSection>
					<strong>Input Score(In %):</strong>
					<InputNumber
						min={1}
						max={100}
						value={score}
						onChange={(e) => setScore(e)}
					/>
				</ScoreSection>
			</Modal>
		</>
	);
};

export default TrustScoreUpdate;
