import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../../methods/utils/general-utils';
import { hideEmail } from '../../../methods/utils/request-helpers';

interface IColumnItems {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const SingleBulkColumn = ({ data }: IColumnItems) => (
	<Style>
		<div>
			<p>
				<Link title="View Profile" to={`/user-profile/${data?.user?._id}`}>
					<p> @{data?.user?.username}</p>
					<p> {hideEmail(data?.user?.email)}</p>
				</Link>
			</p>
			<p className="label">User</p>
		</div>
		<div>
			<p>{`${commas(data?.amount)} ${data?.currencyId?.symbol}`}</p>
			<p className="label">Actual Amount</p>
		</div>
		{/* <div>
			<p>{`${commas(data?.transactionAmount)} ${data?.currencyId?.symbol}`}</p>
			<p className="label">Transaction Amount</p>
			<p>{`${commas(data?.associatedTransactionFee)} ${
				data?.currencyId?.symbol
			}`}</p>
			<p className="label">Transaction Fee</p>
		</div> */}

		<div>
			<p>{data?.status}</p>
			<p className="label">Status</p>
		</div>
		<div>
			<p>
				{data.createdAt
					? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Date created</p>
		</div>
	</Style>
);

export default SingleBulkColumn;
