import {
	Button,
	Card,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	Tag,
	// message,
} from 'antd';
import styled from 'styled-components';
import { EmailEditor } from 'react-email-editor';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	retrieveMailingLists,
	retrieveTestSubscribers,
} from '../../../methods/redux/reducers/alerts';
import { countryCode as countries } from '../../../methods/utils/request-helpers';
import SendTestNewsLetter from '../SendTestNewsLetter';
import { getUserProfileById } from '../../../methods/redux/reducers/user-profile';
import { retrieveToken } from '../../../methods/utils/auth';

const Style = styled(Card)``;

const NewsletterBuilder = () => {
	const dispatch = useAppDispatch();
	const emailEditorRef: any = useRef(null);

	const [visible, setVisible] = useState(false);

	const { mailingList } = useAppSelector((store) => store.alerts);
	const { user } = useAppSelector((store) => store.userProfile);

	const [newsletter, setNewsLetter] = useState({
		subject: '',
		maillist: '',
		countryCode: '',
		locationType: '',
		content: '',
		access_code: '3345constable',
		deliveryType: 'instant',
		creatorEmail: '',
	});

	const sendMessage = async (data: any) => {
		console.info({ data });
		// const res = await baseuri.post('/newsletters/html-template/draft', data);
		// if (res.status === 200) {
		// 	message.success('Successfully Submitted!');
		// } else {
		// 	message.error('Error Submitting.');
		// }
	};

	const submit = () => {
		sendMessage(newsletter);
	};

	const getHtmlContent = () => {
		if (emailEditorRef.current) {
			emailEditorRef.current.editor.exportHtml((data: any) => {
				const { design, html } = data;
				console.info(html);
				console.info(design);
				setNewsLetter({
					...newsletter,
					content: html,
					creatorEmail: user.profile.email,
				});
			});
		}
	};

	useEffect(() => {
		dispatch(retrieveMailingLists());
		dispatch(retrieveTestSubscribers());
		dispatch(getUserProfileById(retrieveToken().user?._id));
	}, []);

	return (
		<Style>
			<h1>Newsletter Builder</h1>
			<p>Use this email builder to prepare and send newsletters.</p>
			<Link to="./mailing-lists">Mailing Lists</Link>
			<br />

			<Tag color="orange">
				For images: Upload the image to be included in the email to this website
				(
				<a href="https://im.ge/upload" target="_blank" rel="noreferrer">
					https://im.ge/upload
				</a>
				) then use the link here.
			</Tag>
			<Divider />
			<div style={{ overflowX: 'auto' }}>
				<EmailEditor ref={emailEditorRef} />
			</div>
			<Popconfirm
				title="Are you done preparing the email?"
				onConfirm={() => {
					setVisible(true);
					getHtmlContent();
				}}
				okText="Yes. Proceed"
			>
				<Button size="large" type="primary">
					Proceed to Email Options
				</Button>
			</Popconfirm>

			<Modal
				title="Submit Email"
				open={visible}
				onOk={submit}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Access Code</p>
					<Input
						size="large"
						value={newsletter.access_code}
						onChange={(e) =>
							setNewsLetter({ ...newsletter, access_code: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<p>Email Subject</p>
					<Input
						placeholder="Email Title"
						size="large"
						value={newsletter.subject}
						onChange={(e) =>
							setNewsLetter({ ...newsletter, subject: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<p>Mailing List</p>
					<Select
						size="large"
						value={newsletter.maillist}
						style={{ width: '100%' }}
						onChange={(e) => setNewsLetter({ ...newsletter, maillist: e })}
					>
						<Select.Option value="">--</Select.Option>
						{mailingList.map((item: any) => (
							<Select.Option key={item.value} value={item.value}>
								{item.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Select Country</p>
					<p style={{ fontStyle: 'italic' }}>
						Users in the selected country will receive the email
					</p>
					<Select
						value={newsletter.countryCode}
						style={{ width: '100%' }}
						size="large"
						onChange={(value) =>
							setNewsLetter({
								...newsletter,
								countryCode: value,
							})
						}
					>
						<Select.Option value="">All Countries</Select.Option>

						{countries?.map((optionItem: any) => (
							<Select.Option key={optionItem.name} value={optionItem.code}>
								{optionItem.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				{newsletter.countryCode.length > 0 && (
					<div>
						<p>Country Type</p>
						<Select
							value={newsletter.locationType}
							size="large"
							style={{ width: '100%' }}
							onChange={(locationType) =>
								setNewsLetter({ ...newsletter, locationType })
							}
						>
							<Select.Option value="">--</Select.Option>
							<Select.Option value="all">All Country Type</Select.Option>
							<Select.Option value="zone">Timezone</Select.Option>
							<Select.Option value="ip">IP Location</Select.Option>
						</Select>
					</div>
				)}

				<Divider />

				<SendTestNewsLetter newsletter={newsletter} typeOfEmail="html" />
			</Modal>
		</Style>
	);
};

export default NewsletterBuilder;
