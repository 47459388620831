/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, message, Modal, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import emailBaseUrl from '../../methods/emailBaseUrl';

interface ISendTestAutoEmail {
	data: any;
}
const SendTestAutoEmail: FC<ISendTestAutoEmail> = ({ data }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [emails, setEmails] = useState([] as any);

	const handleTest = async () => {
		try {
			setloading(true);
			const res: any = await emailBaseUrl.post(
				`/v1/automatedemails/send-test`,
				{
					...data,
					emails,
				}
			);

			if (res.status === 200) {
				setloading(false);
				setVisible(false);
				message.success('Test Auto Emails sent');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};
	return (
		<div>
			<Button
				type="primary"
				shape="round"
				size="small"
				onClick={() => setVisible(true)}
				loading={loading}
			>
				SEND TEST
			</Button>
			<Modal
				title={null}
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleTest}>
						<Button loading={loading} type="primary">
							Send Test
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>Input Test Emails</p>
				<ReactMultiEmail
					emails={emails}
					onChange={(_emails: string[]) => {
						setEmails(_emails);
					}}
					validateEmail={
						(email) => isEmail(email) // return boolean
					}
					// eslint-disable-next-line react/no-unstable-nested-components
					getLabel={(email: string, index: number, removeEmail: Function) => (
						<div data-tag key={index}>
							{email}
							<span data-tag-handle onClick={() => removeEmail(index)}>
								×
							</span>
						</div>
					)}
				/>
			</Modal>
		</div>
	);
};

export default SendTestAutoEmail;
