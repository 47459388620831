import { Divider, Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

const SavingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const SavingsPage = () => {
	const items = [
		{
			title: 'Fixed Savings',
			desc: 'Different settings for the smooth running of the app.',
			link: '/core-modules/fixed-savings',
			icon: 'savings',
		},
		{
			title: 'Fixed Savings (Bulk Create)',
			desc: 'Create fixed savings plan in bulk for multiple users',
			link: '/transactions/bulk-savings?sort=desc',
			icon: 'group_work',
		},
		// {
		// 	title: 'Flexible Savings Topup Items',
		// 	desc: 'View individual flexible savings top up items and also get realtime data as top ups happen on the app.',
		// 	link: '/core-modules/flexible-savings',
		// 	icon: 'money',
		// },
		{
			title: 'Flexible Savings',
			desc: 'View and find all the flexible savings profiles on the app.',
			link: '/core-modules/flexible-savings',
			icon: 'folder_shared',
		},
		{
			title: 'Swap',
			desc: 'Manage currency swapping. Handle manual or blockchain swapping from the admin dashboard.',
			link: '/core-modules/swap',
			icon: 'swap_horizontal_circle',
		},
		{
			title: 'Staking',
			desc: 'Manage token stakings from the Xend Finance mobile application.',
			link: '/core-modules/staking',
			icon: 'token',
		},
		{
			title: 'Loan',
			desc: `Loan page shows details of Loan records`,
			link: '/core-modules/loan-requests',
			icon: 'real_estate_agent',
		},
	];

	return (
		<section>
			<PageHeader>Core Modules</PageHeader>

			<Input.Search placeholder="Search Settings" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SavingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SavingsItemStyle>
				))}
		</section>
	);
};

export default SavingsPage;
