import { Button, message, Space } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	DownloadOutlined,
	ArrowDownOutlined,
	ArrowUpOutlined,
} from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import baseuri from '../methods/baseuri';
import NativeNumber from './Numeral';

const CardTableHeaderSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 2em;
`;

const SortSection = styled.button`
	all: unset;
	display: flex;
	position: relative;
	align-items: center;

	cursor: pointer;

	& .desc {
		color: red;
	}
	& .asc {
		color: green;
	}
`;
const DownloadButton = styled(Button)`
	color: red;
	background-color: #f5d5d5;
	border-radius: 5px;
	&:hover {
		color: red;
		background-color: #efe7e7;
	}
	&:focus {
		color: red;
		background-color: #f5d5d5;
	}
`;

interface ITableHeaderSection {
	tableName: string;
	currentPage: any;
	totalRecords: any;
	// eslint-disable-next-line react/require-default-props
	downloadURL?: string | null;
}
const TableHeaderSection: FC<ITableHeaderSection> = ({
	tableName,
	currentPage,
	totalRecords,
	downloadURL,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState([] as any);

	const csvLinkRef = useRef<
		CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
	>(null);

	const getQueryParams = () => Object.fromEntries(searchParams.entries());
	const toggleSort = () => {
		const params1 = getQueryParams();
		if (searchParams.get('sort') === 'desc') {
			setSearchParams({
				...params1,
				sort: 'asc',
			});
		} else {
			setSearchParams({
				...params1,
				sort: 'desc',
			});
		}
	};
	const headers = [
		{ label: 'First Name', key: 'fullName' },
		{ label: 'Email', key: 'email' },
		{ label: 'Count', key: 'referralCount' },
	];

	const handleDownload = () => {
		setLoading(true);
		const params1 = getQueryParams();
		setResults([]);
		baseuri
			.get(downloadURL || '', {
				params: {
					...params1,
					perPage: totalRecords,
					pageId: '1',
				},
			})
			.then((res: any) => {
				setResults(res.data.data.itemsList);
				setLoading(false);
			})
			.catch(() => {
				message.error('could not download CSV');
				setLoading(true);
			});
	};

	useEffect(() => {
		if (results.length > 0) {
			csvLinkRef?.current?.link.click();
		}
	}, [results]);

	return (
		<CardTableHeaderSection>
			<Space size="middle">
				<span className="tableTitle">
					Title: <strong>{tableName || 'N/A'}</strong>
				</span>
				<p className="recordSection">
					<strong>
						<NativeNumber value={totalRecords} /> Users
					</strong>
				</p>
				<span>Page {currentPage}</span>
			</Space>
			<Space size="middle">
				<SortSection title="Click to toggle" onClick={() => toggleSort()}>
					Sort:{' '}
					{searchParams.get('sort') === 'desc' ? (
						<span className="desc">
							<ArrowDownOutlined /> Desc
						</span>
					) : (
						<span className="asc">
							<ArrowUpOutlined /> Asc
						</span>
					)}
				</SortSection>
				{downloadURL && (
					<DownloadButton
						danger
						loading={loading}
						icon={<DownloadOutlined />}
						onClick={() => handleDownload()}
					>
						{loading ? 'Loading csv...' : 'Download CSV'}
					</DownloadButton>
				)}
				<CSVLink
					data={results}
					headers={headers}
					className="download"
					filename="data.csv"
					ref={csvLinkRef}
				/>
			</Space>
		</CardTableHeaderSection>
	);
};

export default TableHeaderSection;
