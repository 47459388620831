import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import { getStakingReport } from '../../methods/redux/reducers/staking';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';
import ColumnStaking from './ColumnStaking';
import StakingAggregate from './StakingAggregate';
import StakingFilter from './StakingFilter';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const StakingReport = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const { user } = useAppSelector((store) => store.userProfile);
	const { stakingReport } = useAppSelector((store) => store.staking);
	const { currencies } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(stakingReport.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getStakingReport({ ...queryData, userId }));
			} else {
				dispatch(getStakingReport(queryData));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<Card>
			<h1>
				Staking Report{' '}
				{userId && (
					<span>
						{' '}
						for{' '}
						<Link
							title="View Profile"
							to={`/user-profile/${user.profile?._id}`}
						>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>
			{/* <p>All the deposit of funds from an external wallet address.</p> */}

			<StakingFilter />
			<br />
			<StakingAggregate tokens={stakingReport.record} />

			<br />

			<SearchFilter
				title="Search Staking filters"
				searchFilters={[
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of <strong>{stakingReport.paginator.itemCount} items</strong>{' '}
				found
			</p>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={stakingReport.paginator.itemCount}
				current={stakingReport.paginator.currentPage}
				pageSize={stakingReport.paginator.perPage}
			/>
			<br />

			{stakingReport.itemsList.map((item: any) => (
				<ColumnStaking key={item?._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={stakingReport.paginator.itemCount}
				current={stakingReport.paginator.currentPage}
				pageSize={stakingReport.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default StakingReport;
