import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveFundingRequests } from '../../methods/redux/reducers/compliance';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import remakeQueryString from '../../methods/utils/remake-query-string';
import SingleFundingRequest from './SingleRecord';

const FundingRequests = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);
	const { fundingRequests } = useAppSelector((store) => store.compliance);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
				sort: 'desc',
			});
		}

		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveFundingRequests(queryData));
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	const blockchainSelect = blockchains.map((item: any) => ({
		name: item.abbreviation,
		value: item._id,
	}));

	return (
		<Card>
			<h1>Funding Requests</h1>
			<p>
				This is the receiving model of all inflow of funds before the customer
				is credit on Xend Finance.
			</p>

			<Divider />

			<SearchFilter
				title="Search Funding Requests"
				searchFilters={[
					{
						label: 'Funding Status',
						name: 'fundingStatus',
						htmlElement: 'select',
						defaultSelectedValue: '1',
						selectOptions: [
							{ value: '0', name: 'Pending' },
							{ name: 'Success', value: '1' },
							{ name: 'Failed', value: '2' },
						],
					},
					{
						label: 'Receiving Address',
						name: 'receivingAddress',
						htmlElement: 'input',
					},
					{
						label: 'Deposit Address',
						name: 'depositAddress',
						htmlElement: 'input',
					},
					{
						label: 'Customer Email',
						name: 'userEmail',
						htmlElement: 'input',
					},
					{
						label: 'Transaction Hash',
						name: 'transactionHash',
						htmlElement: 'input',
					},
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Blockchain Network',
						name: 'blockchainNetworkId',
						htmlElement: 'select',
						selectOptions: blockchainSelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<Divider />

			<p>
				Total:{' '}
				<strong>
					<NativeNumber value={fundingRequests.paginator.itemCount} />
				</strong>{' '}
				items
			</p>
			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={fundingRequests?.paginator?.currentPage || 0}
				total={fundingRequests?.paginator?.itemCount || 0}
				pageSize={fundingRequests?.paginator?.perPage || 0}
			/>

			<br />

			{fundingRequests?.itemsList?.map((item: any) => (
				<SingleFundingRequest item={item} key={item._id} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={fundingRequests?.paginator?.currentPage || 0}
				pageSize={fundingRequests?.paginator?.perPage || 0}
				total={fundingRequests?.paginator?.itemCount || 0}
			/>
		</Card>
	);
};

export default FundingRequests;
