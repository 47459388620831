import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const getLiquidityProviders = createAsyncThunk(
	'liquidity-providers/getLiquidityProviders',
	async (params: any) => {
		const res = await baseuri.get('/admin/lp/liquidity-providers', { params });
		return res.data.data;
	}
);

export const getThirdPartyLiquidityProviders = createAsyncThunk(
	'liquidity-providers/getThirdPartyLiquidityProviders',
	async (params: any) => {
		const res = await baseuri.get('/admin/lp/thirdparty-liquidity-provider', {
			params,
		});
		return res.data.data;
	}
);

export const addThirdPartyLiquidityProvider = createAsyncThunk(
	'liquidity-providers/addThirdPartyLiquidityProvider',
	async (data: any, { dispatch }) => {
		const res = await baseuri.post(
			'/admin/lp/thirdparty-liquidity-provider',
			data
		);
		message.success('LP added!');
		dispatch(getThirdPartyLiquidityProviders({}));
		return res.data.data;
	}
);

export const deleteThirdPartyLiquidityProviders = createAsyncThunk(
	'liquidity-providers/deleteThirdPartyLiquidityProviders',
	async (params: any, { dispatch }) => {
		const res = await baseuri.delete(
			'/admin/lp/thirdparty-liquidity-provider',
			{
				params,
			}
		);
		dispatch(getThirdPartyLiquidityProviders({}));
		return res.data.data;
	}
);

export const addLiquidityProvider = createAsyncThunk(
	'liquidity-providers/addLiquidityProvider',
	async (data: any) => {
		const res = await baseuri.post('/admin/lp/add-liquidity-provider', data);
		return res.data.data;
	}
);

export const toggleLiquidityProvider = createAsyncThunk(
	'liquidity-providers/toggleLiquidityProvider',
	async (recordId: any) => {
		const res = await baseuri.put(
			'/admin/lp/toggle-liquidity-provider-status',
			{ recordId }
		);
		return res.data.data;
	}
);

export const updateLiquidityProvider = createAsyncThunk(
	'liquidity-providers/updateLiquidityProvider',
	async (data: any) => {
		const res = await baseuri.put('/admin/lp/edit-liquidity-provider', data);
		return res.data.data;
	}
);

const liquidityProviderRx = createSlice({
	name: 'liquidity-providers',
	initialState: {
		xendbridge: {
			itemsList: [],
		},
		thirpartyLp: {
			itemsList: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getLiquidityProviders.fulfilled, (store, action) => {
				const state = store;
				state.xendbridge = { ...state.xendbridge, ...action.payload };
				return state;
			})
			.addCase(getThirdPartyLiquidityProviders.fulfilled, (store, action) => {
				const state = store;
				state.thirpartyLp = action.payload;
			}),
});

export default liquidityProviderRx.reducer;
