import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Style = styled.div`
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 10px;
	background-color: #f8f8f8;
	color: #333;
	text-decoration: none;

	& .details {
		margin-top: 15px;
		border-top: 1px dashed #ccc;
		padding-top: 10px;

		& > * {
			display: inline-block;
			min-width: 100px;
			margin-right: 15px;
			margin-bottom: 5px;

			& p {
				font-size: 14px;
				margin: 0;
				padding: 0;

				&.label {
					color: gray;
					font-size: 14px;
					font-style: italic;
				}
			}
		}
	}
`;

interface IAccountBalanceItem {
	currency: any;
}

const AccountBalanceItem = ({ currency }: IAccountBalanceItem) => (
	<Style>
		<div>
			<p style={{ color: 'var(--red-color)' }}>
				<strong>
					{currency.balance} {currency.currencyId?.symbol}
				</strong>
			</p>
		</div>
		<div className="details">
			<div>
				<Link to={`/user-profile/${currency.userId?._id}`}>
					{currency.userId?.username ? (
						<span>@{currency.userId?.username}</span>
					) : (
						currency.userId?.email
					)}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>
					{currency.balance} {currency.currencyId?.symbol}
				</p>
				<p className="label">Balance</p>
			</div>
			<div>
				<p>{moment(currency.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Last Updated</p>
			</div>
		</div>
	</Style>
);

export default AccountBalanceItem;
