import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getFixedTerminationRequests } from '../../methods/redux/reducers/fixed-savings';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';
import TerminationrequestItem from './TerminationrequestItem';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const TerminationRequests = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const { user } = useAppSelector((store) => store.userProfile);
	const { terminationRequests } = useAppSelector((store) => store.fixedSavings);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
				status: 'requested',
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getFixedTerminationRequests({ ...queryData, userId }));
			} else {
				dispatch(getFixedTerminationRequests(queryData));
			}
		}
	}, [searchParams]);

	return (
		<Card>
			<h1>
				Liquidation Requests{' '}
				{userId && (
					<span>
						{' '}
						for{' '}
						<Link
							title="View Profile"
							to={`/user-profile/${user.profile?._id}`}
						>
							for @{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>
			<p>Fixed Savings Liquidataion requests</p>

			<Divider />

			<SearchFilter
				title="Search Fixed Savings Termination Requests"
				searchFilters={[
					{
						label: 'Status',
						name: 'status',
						defaultSelectedValue: 'requested',
						htmlElement: 'select',
						selectOptions: [
							{ name: 'Approved', value: 'approved' },
							{ name: 'Requested', value: 'requested' },
							{ name: 'Cancelled', value: 'canceled' },
							{ name: 'None', value: 'none' },
						],
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={terminationRequests.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={terminationRequests.paginator.itemCount}
				current={terminationRequests.paginator.currentPage}
				pageSize={terminationRequests.paginator.perPage}
			/>
			<br />

			{terminationRequests.itemsList.map((item: any) => (
				<TerminationrequestItem key={item?._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={terminationRequests.paginator.itemCount}
				current={terminationRequests.paginator.currentPage}
				pageSize={terminationRequests.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default TerminationRequests;
