import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../../../baseuri';

export const createGameTask = async (data: any, recall: any) => {
	try {
		await baseuri.post('/admin/gameTask', data);
		message.success('Game task created!');
		if (recall) {
			recall();
		}
	} catch (e: any) {
		message.error('Failed to create task!');
	}
};

export const retrieveGameTasksForGame = createAsyncThunk(
	'game/retrieveGameTaskForGame',
	async (params: any) => {
		const res = await baseuri.get('/admin/gameTask', { params });
		return res.data.data;
	}
);

export const deleteGameTask = createAsyncThunk(
	'game/deleteGameTask',
	async (params: any, { dispatch }) => {
		await baseuri.delete('/admin/gameTask', { params });
		dispatch(retrieveGameTasksForGame(params));
	}
);
