import moment from 'moment';
import styled from 'styled-components';
import { defaultImage } from '../../../methods/utils/general-utils';

interface IGameUserActionActivitiesItem {
	userActivitiesAction: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;

const GameUserActionActivitiesItem = ({
	userActivitiesAction,
}: IGameUserActionActivitiesItem) => (
	<Style>
		<div className="details">
			<div>
				<p className="label">FullName</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userActivitiesAction.userId.fullName}
				</p>
			</div>
			<div>
				<p className="label">User Name</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userActivitiesAction.userId.username}
				</p>
			</div>
			<div>
				<p className="label">Game Title</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userActivitiesAction.gameId.title}
				</p>
			</div>
			<div>
				<p className="label">Game Status</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userActivitiesAction.gameId.status}
				</p>
			</div>
			<div>
				<p className="label">Amount</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userActivitiesAction.savingsAmount}
				</p>
			</div>
			<div>
				<p className="label">Game Action</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userActivitiesAction.gameActionPointId?.gameKey}
				</p>
			</div>
			<div>
				<p className="label">Currency</p>
				<p style={{ display: 'flex', alignItems: 'center' }}>
					<img
						style={{ marginRight: '10px' }}
						alt=""
						src={userActivitiesAction.currencyId?.iconUrl || defaultImage}
						width={20}
					/>
					<p style={{ textTransform: 'capitalize' }}>
						{userActivitiesAction.currencyId?.symbol}
					</p>
				</p>
			</div>
			<div>
				<p className="label">Game End Date </p>
				<p style={{ textTransform: 'capitalize' }}>
					{' '}
					{userActivitiesAction.gameId.endDate
						? moment(userActivitiesAction.gameId.endDate).format('MMM DD, YY')
						: '--'}
				</p>
			</div>
			<div>
				<p className="label">User Created Action Date</p>
				<p style={{ textTransform: 'capitalize' }}>
					{' '}
					{userActivitiesAction.createdAt
						? moment(userActivitiesAction.createdAt).format('MMM DD, YY')
						: '--'}
				</p>
			</div>
		</div>
	</Style>
);

export default GameUserActionActivitiesItem;
