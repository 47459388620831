import { useEffect, useState } from 'react';
import { Modal, Button, Input, Checkbox, Divider } from 'antd';
import { setAppApy } from '../../methods/redux/reducers/general';
import { useAppSelector, useAppDispatch } from '../../methods/redux/hooks';
import { commas } from '../../methods/utils/general-utils';

interface ISetApy {
	apyAverage: any;
}

const SetApy = ({ apyAverage }: ISetApy) => {
	const dispatch = useAppDispatch();

	const { todaysApy } = useAppSelector((store) => store.general);

	const [visible, setVisible] = useState(false);
	const [apy, setApy] = useState(0);
	const [checkboxReadOnly, setCheckboxReadOnly] = useState(false);

	const submit = () => {
		dispatch(setAppApy({ apy }));
		setVisible(false);
	};

	useEffect(() => {
		setApy(Number(todaysApy.apy) * 100);
	}, [todaysApy]);

	const checkboxChange = (e: any) => {
		if (e.target?.checked) {
			setCheckboxReadOnly(true);
			setApy(apyAverage);
		} else {
			setCheckboxReadOnly(false);
		}
	};

	return (
		<div>
			<Button type="primary" onClick={() => setVisible(true)}>
				Manually Set Apy
			</Button>

			<Modal
				title="Set Daily Apy"
				onCancel={() => setVisible(false)}
				visible={visible}
				onOk={submit}
				width={320}
				okText="Update APY"
			>
				<p>Apy</p>
				<Input
					value={apy}
					readOnly={checkboxReadOnly}
					disabled={checkboxReadOnly}
					type="number"
					onChange={(e) => setApy(Number(e.target.value))}
					addonAfter="%"
				/>

				<Divider />

				<p>
					The current average APY is now <strong>{commas(apyAverage)}%</strong>.
					You can set the general APY for Xend Finance with this average by
					clicking the checkbox below.
				</p>

				<br />

				<Checkbox onChange={checkboxChange}>
					Use Average APY of <strong>{commas(apyAverage)}%</strong>
				</Checkbox>
			</Modal>
		</div>
	);
};

export default SetApy;
