import { Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import TableHeaderSection from '../../component/TableHeaderSection';
import emailBaseUrl from '../../methods/emailBaseUrl';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import EmailColumn from './EmailColumn';
import CreateMultipleEmails from './CreateMultipleEmails';

const MultipleEmail = () => {
	const [refresh, setRefresh] = useState('');
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
		};
	};

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		// if (!location.search) return;
		try {
			const res: any = await emailBaseUrl.get('v1/newsletters/bulk-emails', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, [location.search, refresh]);

	return (
		<div>
			<br />
			<CreateMultipleEmails setRefresh={setRefresh} />
			<MyTableCard>
				<TableHeaderSection
					tableName="All Multiple Emails Sent"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
				/>

				{data.itemsList?.length > 0 ? (
					data?.itemsList?.map((item: any) => (
						<EmailColumn key={item?._id} data={item} />
					))
				) : (
					<MyEmptyTable />
				)}
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</div>
	);
};

export default MultipleEmail;
