import { Button, Input, Modal, Popconfirm, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	getCurrencies,
	retrieveFiatSupportedBanks,
} from '../../../methods/redux/reducers/currency';
import { countryCode as countries } from '../../../methods/utils/request-helpers';
import baseuri from '../../../methods/baseuri';

const AddBank = () => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const { currencies } = useAppSelector((store) => store.currencies);

	const [state, setState] = useState({
		name: '',
		symbol: '',
		branchCode: '',
		localCurrencyId: '',
		address: '',
		countryCode: '',
		apiTokenKeyInENV: '',
	});

	const submitAddBank = async () => {
		const res = await baseuri.post('/admin/fiat-banks', state);
		if (res.status === 200) {
			dispatch(retrieveFiatSupportedBanks({}));
			message.success('Added Successfully!');
			setVisible(false);
		}
	};

	useEffect(() => {
		dispatch(getCurrencies({}));
	}, []);

	return (
		<>
			<Button onClick={() => setVisible(true)}>Add Bank</Button>

			<Modal
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={submitAddBank}>
						<Button size="large" type="primary">
							Add Bank
						</Button>
					</Popconfirm>,
				]}
				title="Add Bank"
				open={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Name</p>
					<Input
						size="large"
						value={state.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Symbol</p>
					<Input
						size="large"
						value={state.symbol}
						onChange={(e) => setState({ ...state, symbol: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Branch Code</p>
					<Input
						size="large"
						value={state.branchCode}
						onChange={(e) => setState({ ...state, branchCode: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Local Currency</p>
					<Select
						value={state.localCurrencyId}
						onChange={(localCurrencyId) =>
							setState({ ...state, localCurrencyId })
						}
						style={{ width: '100%' }}
						size="large"
					>
						<Select.Option>--</Select.Option>
						{currencies.map((currency: any) => (
							<Select.Option key={currency._id} value={currency._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Address</p>
					<Input
						size="large"
						value={state.address}
						onChange={(e) => setState({ ...state, address: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Country</p>
					<Select
						value={state.countryCode}
						onChange={(countryCode) => setState({ ...state, countryCode })}
						style={{ width: '100%' }}
						size="large"
					>
						<Select.Option>--</Select.Option>
						{countries?.map((optionItem: any) => (
							<Select.Option key={optionItem.name} value={optionItem.code}>
								{optionItem.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Api Token</p>
					<Input
						size="large"
						value={state.apiTokenKeyInENV}
						onChange={(e) =>
							setState({ ...state, apiTokenKeyInENV: e.target.value })
						}
					/>
				</div>
			</Modal>
		</>
	);
};

export default AddBank;
