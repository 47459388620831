import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

interface IMigrationLogItem {
	item: any;
}

const Style = styled.div`
	margin-bottom: 10px;
	background-color: #f8f8f8;
	padding: 20px;

	& > * {
		display: inline-block;
		padding: 5px 20px 5px 1px;
		min-width: 100px;

		& .label {
			font-style: italic;
			font-size: 10px;
		}
	}
`;

const MigrationLogItem = ({ item }: IMigrationLogItem) => (
	<Style>
		<div>
			<Link
				to={`/core-modules/flexible-savings/single/${item.flexibleSavingsId?._id}`}
			>
				{item.flexibleSavingsId?.title}
			</Link>
			<p className="label">Savings</p>
		</div>
		<div>
			<Link to={`/user-profile/${item.userId?._id}`}>
				@{item.userId?.username}
			</Link>
			<p className="label">Customer</p>
		</div>
		<div>
			<p>
				{commas(item.flexibleSavingsItemId?.amount)}{' '}
				{item.flexibleSavingsId?.symbol}
			</p>
			<p className="label">Topup Amount</p>
		</div>
		<div>
			<p>
				{commas(item.savingsCurrentBalance)} {item.flexibleSavingsId?.symbol}
			</p>
			<p className="label">Current Balance</p>
		</div>
		<div>
			<p>
				{commas(item.savingsPreviousBalance)} {item.flexibleSavingsId?.symbol}
			</p>
			<p className="label">Previous Balance</p>
		</div>
		<div>
			<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
			<p className="label">Updated</p>
		</div>
		<div>
			<p>@{item.lastUpdatedByUserId?.username}</p>
			<p className="label">Last Updated By</p>
		</div>
	</Style>
);

export default MigrationLogItem;
