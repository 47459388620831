import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { shortAddress } from '../../methods/utils/general-utils';

interface IOutDebitItem {
	transaction: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 5px 10px;
	margin-bottom: 10px;

	& .amount {
		display: flex;
		font-weight: bold;
		font-size: 16px;
		& img {
			margin-right: 7px;
			width: 20px;
			height: 20px;
		}
	}

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 12px;
		padding: 15px 0;

		& > * {
			display: inline-block;
			min-width: 200px;
			margin-bottom: 7px;
			padding: 5px;
		}

		& .label {
			font-size: 13px;
			color: #808080;
			font-style: italic;
		}
	}
`;

const OutDebitItem = ({ transaction }: IOutDebitItem) => (
	<Style>
		<div>
			<div className="amount">
				<img alt="" src={transaction.currencyId?.iconUrl} />
				<p>
					<NativeNumber value={transaction.transactionAmount} />{' '}
					{transaction.currencyId?.symbol}
				</p>
			</div>
		</div>
		<div className="details">
			<div>
				<Link
					title="View User Profile"
					to={`/user-profile/${transaction.userId?._id}`}
				>
					@{transaction.userId?.username}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>{transaction.blockchainNetworkId?.abbreviation}</p>
				<p className="label">Blockchain Network</p>
			</div>
			<div>
				<a
					title="View Destination"
					target="_blank"
					href={`https://${transaction.blockchainNetworkId?.explorerUrl}/address/${transaction.destinationAddress}`}
					rel="noreferrer"
				>
					{shortAddress(transaction.destinationAddress)}
				</a>
				<p className="label">Destination Address</p>
			</div>
			<div>
				<a
					title="View Transaction Hash"
					target="_blank"
					href={`https://${transaction.blockchainNetworkId?.explorerUrl}/tx/${transaction.blockchainTransactionHash}`}
					rel="noreferrer"
				>
					Hash
				</a>
				<p className="label">Transaction Hash</p>
			</div>
			<div>
				<p>{transaction.description}</p>
				<p className="label">Description</p>
			</div>
			<div>
				<p>{moment(transaction.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Created</p>
			</div>
			<div>
				<p>{moment(transaction.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Updated</p>
			</div>
		</div>
	</Style>
);

export default OutDebitItem;
