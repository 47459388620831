import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import emailBaseUrl from '../../emailBaseUrl';

export const retrieveWalletReport = createAsyncThunk(
	'robot-report/retrieveWalletReport',
	async (params: any) => {
		const res = await emailBaseUrl.get('/v1/wallet-reports', { params });
		return res.data.data;
	}
);

const walletReportx = createSlice({
	name: 'robot-report',
	initialState: {
		walletReport: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder.addCase(retrieveWalletReport.fulfilled, (store, action) => {
			const state = store;
			state.walletReport = action.payload;
		}),
});
export default walletReportx.reducer;
