import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import emailBaseUrl from '../../emailBaseUrl';

export const getMultipleEmails = createAsyncThunk(
	'multiple-emails/getMultipleEmails',
	async (params: any) => {
		try {
			const res: any = await emailBaseUrl.get('v1/newsletters/bulk-emails', {
				params,
			});
			if (res.status === 200) {
				message.success(res.data.message);
				return res.data.data;
			}
			message.info(res.data.message);
			return res.data.data;
		} catch (error: any) {
			message.error(error.response.data.message);
			return {
				itemsList: [],
				paginator: {},
			} as any;
		}
	}
);

export const draftBulkEmail = createAsyncThunk(
	'multiple-emails/draftBulkEmail',
	async (formData: any) => {
		try {
			const res: any = await emailBaseUrl.post(
				`v1/newsletters/bulk-emails`,
				formData
			);
			if (res.status === 200) {
				message.success(res.data.message);
				return res.data.data;
			}
			message.info(res.data.message);
			return res.data.data;
		} catch (error: any) {
			message.error(error.response.data.message);
			return '';
		}
	}
);

export const sendBulkEmail = createAsyncThunk(
	'multiple-emails/sendBulkEmail',
	async (params: any) => {
		const { id, senderPin } = params;
		try {
			const res: any = await emailBaseUrl.post(`/v1/bulk-emails/${id}/send`, {
				sender_pin: senderPin,
			});
			if (res.status === 200) {
				message.success(res.data.message);
				return res.data.data;
			}
			message.info(res.data.message);
			return res.data.data;
		} catch (error: any) {
			message.error(error.response.data.message);
			return '';
		}
	}
);

export const getSingleBulkEmail = createAsyncThunk(
	'multiple/getSingleBulkEmail',
	async (id: string) => {
		const res = await emailBaseUrl.get(`v1/newsletters/bulk-emails/${id}`);
		return res.data.data;
	}
);

const multipleEmailsRx = createSlice({
	name: 'multiple-emails',
	initialState: {
		all: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		} as any,
		single: {} as any,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getMultipleEmails.fulfilled, (store, action) => {
				const state = store;
				state.all = action.payload;
			})
			.addCase(getSingleBulkEmail.fulfilled, (store, action) => {
				const state = store;
				state.single = action.payload;
			}),
});

export default multipleEmailsRx.reducer;
