import { Button, Divider, Input, Modal, Select, Switch } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { createFeeSettings } from '../../methods/redux/reducers/fee-settings';

const AddFeeSettings = () => {
	const dispatch = useAppDispatch();

	const { currencies } = useAppSelector((store) => store.currencies);
	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		typeOfFee: '',
		strategy: '',
		fee: 0,
		capFeeAmount: 0,
		currencyId: '',
		applyXendDiscount: false,
		xendDiscountPercentage: 0,
		xendThreshold: 0,
		active: false,
	});

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const submit = () => {
		dispatch(createFeeSettings(state));
	};

	return (
		<div>
			<Button onClick={() => setVisible(true)}>Add New Fee Setting</Button>

			<Modal
				onOk={submit}
				okText="Create Fee"
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Add New Fee Settings"
			>
				<div>
					<p>Fee Category</p>
					<Select
						onChange={(typeOfFee) => setState({ ...state, typeOfFee })}
						style={{ width: '100%' }}
					>
						<Select.Option value="out">External Withdrawal</Select.Option>
						<Select.Option value="deposit">Deposit to Savings</Select.Option>
						<Select.Option value="withdraw">
							Withdrawal from Savings
						</Select.Option>
						<Select.Option value="swap">Swap</Select.Option>
						<Select.Option value="p2pOut">XendBridge Withdrawal</Select.Option>
					</Select>
				</div>

				<div>
					<p>Transaction Currency</p>
					<Select
						onChange={(currencyId) => setState({ ...state, currencyId })}
						style={{ width: '100%' }}
					>
						{currencies.map((currency: any) => (
							<Select.Option key={currency._id} value={currency._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						columnGap: 20,
					}}
				>
					<div>
						<p>Fee</p>
						<Input value={state.fee} name="fee" onChange={handleInputChange} />
					</div>
					<div>
						<p>Strategy</p>
						<Select
							onChange={(strategy) => setState({ ...state, strategy })}
							style={{ width: '100%' }}
						>
							<Select.Option value="percent">Percentage</Select.Option>
							<Select.Option value="flat">Flat Fee</Select.Option>
						</Select>
					</div>
				</div>
				<div>
					<p>Cap Fee Amount</p>
					<Input
						value={state.capFeeAmount}
						name="capFeeAmount"
						onChange={handleInputChange}
					/>
				</div>

				<Divider />

				<div>
					<p>XEND Discounts</p>
					<p>
						Discounts are given to users performing transactions and have over
						the discount threshold amount set
					</p>
				</div>

				<br />

				<div>
					<p>Apply Discount</p>
					<Switch
						checked={state.applyXendDiscount}
						onChange={(applyXendDiscount) =>
							setState({ ...state, applyXendDiscount })
						}
					/>
				</div>
				<div>
					<p>Discount Percentage</p>
					<Input
						value={state.xendDiscountPercentage}
						name="xendDiscountPercentage"
						onChange={handleInputChange}
						addonAfter="%"
					/>
				</div>
				<div>
					<p>Discount Threshold Amount</p>
					<Input
						value={state.xendThreshold}
						name="xendThreshold"
						onChange={handleInputChange}
						addonAfter="XEND"
					/>
				</div>
			</Modal>
		</div>
	);
};

export default AddFeeSettings;
