import { Modal, Button, Input, Popconfirm } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	addCustodialWallet,
	removeCustodialWallet,
} from '../../methods/redux/reducers/general';

const Style = styled.div`
	& .title {
		display: flex;
		justify-content: space-between;
	}

	& .single {
		padding: 10px 0;
		border-bottom: 1px solid #ddd;
	}

	& .action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 7px;
		font-size: 11.5px;
	}
`;

const ManageCustodialWallets = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);
	const { custodialWallets } = useAppSelector((store) => store.general);
	const [state, setState] = useState({
		address: '',
		description: '',
	});

	const submitNewWallet = () => {
		dispatch(addCustodialWallet(state));
	};

	const removeWallet = (addressId: any) => {
		dispatch(removeCustodialWallet(addressId));
	};

	return (
		<Style>
			<div className="title">
				<h2>Manage Custodial Wallets</h2>
				<Button type="primary" onClick={() => setVisible(true)}>
					Add Wallet
				</Button>
			</div>

			<div>
				{custodialWallets.itemsList.map((item: any) => (
					<div key={item._id} className="single">
						<p>
							<strong>{item.address}</strong>
						</p>
						<p>{item.description}</p>
						<div className="action">
							<p>{moment(item.updatedAt).format('lll')}</p>
							<Popconfirm
								title="Are you sure?"
								onConfirm={() => removeWallet(item._id)}
							>
								<Button size="small" danger>
									Delete
								</Button>
							</Popconfirm>
						</div>
					</div>
				))}
			</div>

			<Modal
				okText="Add Wallet"
				title="Add Custodial Wallet"
				onOk={submitNewWallet}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<p>Custodial Wallet Address</p>
				<Input
					value={state.address}
					onChange={(e) => setState({ ...state, address: e.target.value })}
				/>
				<p>Description</p>
				<Input.TextArea
					onChange={(e) => setState({ ...state, description: e.target.value })}
					value={state.description}
				/>
			</Modal>
		</Style>
	);
};

export default ManageCustodialWallets;
