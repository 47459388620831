import { Button, message, Modal, Input } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';

const AccessSection = styled.section`
	display: flex;
	flex-direction: row;
	> span {
		width: 25%;
		/* min-width: 150px; */
	}
	/* &.accessInput {
		width: 30px;
	} */
`;
interface ITerminateNewsLetter {
	newsletter: any;
	setRefresh: Function;
}
const TerminateNewsLetter: FC<ITerminateNewsLetter> = ({
	newsletter,
	setRefresh,
}) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [accessCode, setAccessCode] = useState('3345constable');

	const terminateNewsletter = async () => {
		try {
			setloading(true);
			const res: any = await emailBaseUrl.post(
				`/v1/newsletters/terminate/${newsletter.id}`,
				{
					access_code: accessCode,
				}
			);
			if (res.status === 200) {
				setloading(false);
				message.success(res?.data?.data?.message);
				setVisible(false);
				setRefresh(Math.random());
			}
		} catch (e) {
			setloading(false);
			message.error('Could not complete operation');
		}
	};

	return (
		<div>
			<Button
				danger
				block
				shape="round"
				size="small"
				loading={loading}
				onClick={() => setVisible(true)}
				ghost
			>
				Terminate
			</Button>
			<Modal
				width="50%"
				// footer={null}
				visible={visible}
				footer={[
					<Button
						loading={loading}
						type="primary"
						onClick={() => terminateNewsletter()}
					>
						Terminate
					</Button>,
				]}
				onCancel={() => setVisible(false)}
			>
				<strong>
					{`Are you sure you want to Terminate this NewsLetter ${
						newsletter?.subject ? `with Subject "${newsletter?.subject}"` : ''
					}`}
				</strong>
				<br />
				<br />
				<AccessSection>
					<span> Access Code : </span>
					<Input
						className="accessInput"
						value={accessCode}
						placeholder="input access code"
						onChange={(e: any) => setAccessCode(e.target.value)}
					/>
				</AccessSection>
			</Modal>
		</div>
	);
};

export default TerminateNewsLetter;
