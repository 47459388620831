import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { defaultImageOne } from '../../methods/utils/general-utils';
import NativeNumber from '../../component/Numeral';

interface ITransactionItem {
	transaction: any;
}

interface IStyle {
	color: string;
}
const Style = styled.div<IStyle>`
	padding: 15px 10px;
	background-color: #fbfbfb;
	margin-bottom: 7px;

	& .transactionType {
		color: ${(p) => p.color};
		opacity: 0.7;
	}

	& .amount {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 16px;
		color: ${(p) => p.color};

		& img {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			margin-right: 7px;
		}
	}

	& .details {
		border-top: 1px dashed #ddd;
		margin-top: 8px;
		padding: 10px 0;

		& > * {
			display: inline-block;
			padding: 5px;
			border-right: 1px solid #eee;
			font-size: 13.5px;

			& .label {
				color: #aaa;
				font-size: 12px;
			}
		}
	}
`;

const TransactionItem = ({ transaction }: ITransactionItem) => {
	const txnColor = () => {
		const { transactionType } = transaction;
		switch (transactionType) {
			case 'debit':
				return '#d52a4f';
			case 'credit':
				return '#1a846d';
			default:
				return 'gray';
		}
	};
	return (
		<Style color={txnColor()}>
			<div>
				<div>
					<span className="material-icons transactionType">
						{transaction.transactionType === 'debit' && 'outbound'}
						{transaction.transactionType === 'credit' && 'arrow_circle_down'}
					</span>
				</div>
				<div>
					<div className="amount">
						<img
							alt=""
							src={transaction.currencyId?.iconUrl || defaultImageOne}
							width={18}
						/>
						<p>
							<NativeNumber
								value={
									transaction.transactionAmount || transaction.actualAmount
								}
							/>{' '}
							{transaction.currencyId?.symbol}
						</p>
					</div>
					<Link
						title="User Profile"
						to={`/user-profile/${transaction.userId?._id}`}
					>
						@{transaction.userId?.username}
					</Link>
				</div>
			</div>
			<div className="details">
				<div>
					<p>{transaction.status}</p>
					<p className="label">Status</p>
				</div>
				<div>
					<p>{transaction.transactionForm}</p>
					<p className="label">Transaction Form</p>
				</div>
				{transaction.blockchainTransactionNetworkId && (
					<div>
						<p>{transaction.blockchainTransactionNetworkId?.abbreviation}</p>
						<p className="label">Blockchain Network</p>
					</div>
				)}
				{transaction.blockchainTransactionHash && (
					<div>
						<p>
							<a
								href={`https://${transaction.blockchainTransactionNetworkId?.explorerUrl}/tx/${transaction.blockchainTransactionHash}`}
								target="_blank"
								rel="noreferrer"
							>
								Hash
							</a>
						</p>
						<p className="label">Transaction Hash</p>
					</div>
				)}
				<div>
					<p>
						<NativeNumber value={transaction.actualAmount} decimalPlaces={4} />{' '}
						{transaction.currencyId?.symbol}
					</p>
					<p className="label">Actual Amount</p>
				</div>
				<div>
					<p>{transaction.associatedTransactionFee}</p>
					<p className="label">Transaction Fee</p>
				</div>
				<div>
					<p>{transaction.description}</p>
					<p className="label">Description</p>
				</div>
				<div>
					<p>
						<NativeNumber value={transaction.oldWalletBalance} />{' '}
						{transaction.currencyId?.symbol}
					</p>
					<p className="label">Old Wallet Balance</p>
				</div>
				<div>
					<p>
						<NativeNumber value={transaction.newWalletBalance} />{' '}
						{transaction.currencyId?.symbol}
					</p>
					<p className="label">New Wallet Balance</p>
				</div>
				<div>
					<p>{transaction.usdActualAmountValue} USD</p>
					<p className="label">Actual Amount USD Value</p>
				</div>
				<div>
					<p>
						{moment(transaction.createdAt).format('DD MMM, YY / hh:mm:ss a')}
					</p>
					<p className="label">Created</p>
				</div>
				<div>
					<p>
						{moment(transaction.updatedAt).format('DD MMM, YY / hh:mm:ss a')}
					</p>
					<p className="label">Last Updated</p>
				</div>
			</div>
		</Style>
	);
};

export default TransactionItem;
