import { Route, Routes } from 'react-router-dom';
import Alerts from './alerts';
import AutoEmails from './alerts/automated-emails/AutoEmails';
import EmailNewsletter from './alerts/email-newsletter';
import InAppNews from './alerts/in-app-news/InAppNews';
import InAPPNotifications from './alerts/in-app-alert/InAPPNotifications';
import AuthLogin from './auth/Login';
import LandingPage from './landing';
import Layout from './layouts';
import Authenticated from './layouts/Authenticate';
import AllMerchant from './users/merchants/AllMerchant';
import UsersProfile from './profiles/users-profile';
import Reports from './reports';
import AccountantReport from './reports/accounts-reports';
import ReferralReport from './reports/referral-report/ReferralReport';
import Robots from './robots';
import EmailRobot from './robots/email-robot/EmailRobot';
import FundsRobot from './robots/funds-robot/FundsRobot';
import SettingsPage from './settings';
import CurrencySettings from './settings/currency/Currency';
import StakingSettings from './settings/staking/Staking';
import Transactions from './transactions';
import Users from './users';
import UsersKYC from './users/users-kyc/UsersKYC';
import ByUserType from './users/users-list/by-usertype/ByUserType';
import ByWalletBalance from './users/users-list/ByWalletBalance';
import UsersByCountry from './users/users-list/UsersByCountry';
import UsersList from './users/users-list/UsersList';
import AllCronJobs from './robots/cron-jobs';
import MerchantProfile from './profiles/merchant-profile/MerchantProfile';
import MyProfile from './profiles/admin-profile';
import UserLastLogin from './profiles/users-profile/UserLastLogin';
import ReferralFilter from './profiles/users-profile/user-refferals/ReferralFilter';
import UnverifiedUsers from './users/users-list/UnverifiedUsers';
import ByLastLogin from './users/users-list/ByLastLogin';
import AdvertBanners from './alerts/advert-banners/AdvertBanners';
import SavingsSettings from './settings/savings';
import TransactionFees from './settings/transaction-fees/TransactionFees';
import CurrencySwapSettings from './settings/swap/SwapSettings';
import FixedSavingsReport from './reports/fixed-savings-report/PaidFixedInterest';
import SwapReport from './reports/swap/SwapReport';
import UserSettings from './user-settings/Index';
import UserSavingsSettings from './user-settings/user-transaction-settings/UserTransactionSettings';
import MadWallet from './thirdparty/mad-wallet';
import UserGlobalSearch from './users/global-user-search/UserGlobalSearch';
import GeneralGlobalSearch from './general-search';
import FlexibleSavings from './core-modules/flexible-savings';
import FixedSavings from './core-modules/fixed-savings';
import SingleFixedSavings from './core-modules/fixed-savings/SingleFixedSavings';
import OldTransactions from './reports/old-transactions/OldTransactions';
import WithdrawQueue from './transactions/withdrawal/withdrawal-queque/WithdrawQueue';
// import WithdrawalRequests from './wallet-transactions/withdrawal/withdrawal-queque/withdrawal-requests/WithdrawalRequests';
import Wallets from './wallets';
import InCredit from './transactions/in-credit-transactions';
import Swap from './core-modules/swap';
import Staking from './core-modules/staking';
import VerifiedUsers from './users/users-list/VerifiedUsers';
import TransactionStatement from './reports/transaction-statement/TransactionStatement';
import AllWalletBalances from './wallets/all-wallet-balances/AllWalletBalances';
import CoreModules from './core-modules';
import Roles from './settings/roles';
import Overview from './overview';
import StakingReport from './reports/staking-report/StakingReport';
import Thirdparty from './thirdparty';
import LiquidityProviders from './thirdparty/LiquidityProviders';
import GeneralSettings from './settings/general/GeneralSettings';
import MaintenanceReport from './maintenance/request-maintenance/MaintenanceReport';
import Maintenance from './maintenance';
import ExtrasPage from './extras';
import CompliancePage from './compliance';
import TwoFactorSetup from './auth/TwoFactorSetup';
import TransactionStatments from './transactions/transaction-statments';
import WithdrawalRequests from './transactions/withdrawal-request';
import EmailBounceMaintenance from './maintenance/emailBounce-maintenance/EmailBounceMaintenance';
import Reset2faRequests from './maintenance/2fa-requests';
import UserGeneralSettings from './user-settings/user-general-settings/UserGeneralSettings';
import AccountDeletionList from './maintenance/account-deletion/AccountDeletionList';
import FirstFunders from './transactions/deposits/FirstFunders';
import ThirtyDaysFunders from './transactions/deposits/ThirtyDaysFunders';
import BlockchainTransactions from './transactions/blockchain-transactions';
import ManageKYC from './users/users-kyc/ManageKYC';
import KYCSuspect from './users/users-kyc/KYCSuspect';
import SingleFlexibleSavingsPage from './core-modules/flexible-savings/SingleFlexibleSavingsPage';
import FlexibleSavingsProfilesPage from './core-modules/flexible-savings/FlexibleSavingsProfilesPage';
import DeletedDoubleFundings from './compliance/double-funding/DeletedDoubleFundings';
import TrustScoreUsers from './compliance/trust-score/TrustScoreUsers';
import BlackListedUsers from './compliance/blacklisted-users/BlackListedUsers';
import SpamDomains from './compliance/spam-domains/SpamDomains';
import TransactionPerUser from './reports/transaction-per-user-new/TransactionPerUser';
import DailyEarnings from './core-modules/daily-earnings/DailyEarnings';
import MigrationLogs from './core-modules/migration-logs/MigrationLogs';
import GhostUsers from './users/users-list/GhostUsers';
import PaidOutStaking from './reports/staking-report/PaidOutStaking';
import UnpaidFixedInterests from './reports/fixed-savings-report/UnpaidFixedInterests';
import FirstSavers from './reports/fixed-savings-report/FirstSavers.';
import PaidOutFlexibleInterests from './reports/flexible-savings-report/PaidOutFlexibleInterests';
import FlexibleFirstSavers from './reports/flexible-savings-report/FlexibleFirstSavers';
import TerminationRequests from './core-modules/fixed-savings/TerminationRequests';
import TopupFixedSavingsItemsPage from './core-modules/fixed-savings/TopupFixedSavingsItemsPage';
import FixedSavingsPendingPayoutPage from './core-modules/fixed-savings/PendingPayoutPage';
import ExpiringFixedSavingsPage from './core-modules/fixed-savings/ExpiringFixedSavings';
import PendingProfitBuild from './core-modules/flexible-savings/PendingProfitBuildPage';
import InternalSettings from './settings/internal-settings/InternalSettingsPage';
import ApyHistory from './reports/apy-history/ApyHistory';
import SingleSwapItemPage from './core-modules/swap/SingleSwapItemPage';
import FixedLockedSavings from './compliance/locked-savings/FixedLockedSavings';
import FlexibleLockedSavings from './compliance/locked-savings/FlexibleLockedSavings';
import BulkSavings from './transactions/builk-savings/BulkSavings';
import DirectEmail from './alerts/single-alert/DirectEmail';
import SingleBulkSavings from './transactions/builk-savings/single-bulk/SingleBulkSavings';
import ForcedNotification from './alerts/single-alert/ForcedNotification';
import UserOldPlans from './maintenance/old-plans';
import XendBridgeSellRequest from './thirdparty/XendBridgeSellRequest';
import RobotUsers from './users/users-list/RobotUsers';
import FixedSavingsPendingProfitBuildPage from './core-modules/fixed-savings/PendingProfitBuild';
import OutDebitTransaction from './transactions/out-debit-transactions';
import ReferralSettings from './settings/referrals';
import ServiceStatus from './maintenance/service-status/ServiceStatus';
import MultipleEmail from './alerts/multiple-alerts/MultipleEmail';
import ManualSwitchFunding from './maintenance/manual-switch-funding/ManualSwitchFunding';
import Single2faRequest from './maintenance/2fa-requests/Single2faRequest';
import TransactionPerUserOld from './reports/transaction-per-user-old/TransactionPerUserOld';
import UserInfoSettings from './user-settings/user-info-setting/UserInfoSettings';
import Loan from './core-modules/loan';
import RolesAndPermissions from './settings/roles/RolesAndPermissions';
import UnmigratedFeesPage from './reports/fee-collection/unmigrated';
import SingleNewsletter from './alerts/email-newsletter/SingleNewsletter';
import SingleMultipleEmail from './alerts/multiple-alerts/SingleMultipleEmail';
import FeeMigrationBatchPage from './reports/fee-collection/migration-batch';
import TransactionOverview from './reports/transaction-overview/TransactionOverview';
import OldGasFees from './reports/fee-expenses/OldGasFees';
import OldTransactionFees from './reports/fee-expenses/OldTransactionFees';
import MonthlySavers from './extras/monthly-savers.tsx/MonthlySavers';
import TGIFSavers from './extras/monthly-savers.tsx/TGIFSavers';
import DailySavers from './extras/monthly-savers.tsx/DailySavers';
import WeeklySavers from './extras/monthly-savers.tsx/WeeklySavers';
import TopReferrals from './extras/monthly-savers.tsx/TopReferrals';
import Games from './extras/game';
import ManageFeeCollection from './reports/fee-collection';
import LoanSettings from './settings/loan-settings/LoanSettings';
import SingleLoanItemPage from './core-modules/loan/SingleLoanItemPage';
import ExternalWithdrawalFees from './reports/fee-collection/blockchain-fees/ExternalWithdrawals';
import AccountantExternalWithdrawals from './reports/accounts-reports/external';
import SavingsDepositAccountReport from './reports/accounts-reports/savings-deposit';
import SavingsWithdrawalAccountReport from './reports/accounts-reports/savings-withdrawal';
import DoubleFunding from './compliance/double-funding';
import FundingRequests from './compliance/funding-requests';
import NegativeBalances from './compliance/negative-balances';
import UsersQueryReports from './users/reports';
import DueLoanRepayments from './core-modules/loan/DueLoanRepayments';
import SingleGame from './extras/game/SingleGame';
import NegativeFlexibleBalances from './compliance/negative-flexible-balances';
import GameSubscribers from './extras/game/Subscribers';
import GameActionActivities from './extras/game/SingleGame/GameActionActivities';
import GameSettings from './extras/game/GameSettings';
import GamePointDistribution from './extras/game/gamePointDistribution';
import GameRewards from './extras/game/SingleGame/GameRewards';
import EarlyTerminationOfFixedSavings from './reports/accounts-reports/early-termination';
import SwapAccountantReport from './reports/accounts-reports/swap';
import LoanAccountantReport from './reports/accounts-reports/loan';
import StakingAccountantReport from './reports/accounts-reports/staking';
import AccountSnapshot from './overview/account-snapshot';
import NewsletterBuilder from './alerts/email-newsletter/builder/NewsletterBuilder';
import ManageFiatBanks from './settings/currency/fiat/Manage';
import SingleGameActionItem from './extras/game/gameAction';
import GameBadgeItem from './extras/game/gameBadge/gameBadgeItem';
import SingleGameBadgeItem from './extras/game/gameBadge';
import GameUserActionActivities from './extras/game/SingleGame/GameUserActionActivities';
import NewsletterMailingListPage from './alerts/email-newsletter/builder/NewsletterMailingListPage';

const App = () => (
	<Routes>
		<Route element={<Layout />}>
			<Route index element={<LandingPage />} />
			<Route path="/overview" element={<Overview />} />
			<Route path="/overview/account-snapshot" element={<AccountSnapshot />} />
			<Route path="/users" element={<Users />} />
			<Route path="/users/all-users" element={<UsersList />} />
			<Route path="/users/verified-users" element={<VerifiedUsers />} />
			<Route path="/users/unverified-users" element={<UnverifiedUsers />} />
			<Route path="/users/users-country" element={<UsersByCountry />} />
			<Route path="/users/users-type" element={<ByUserType />} />
			<Route path="/users/ghost-users" element={<GhostUsers />} />
			<Route path="/users/robot-users" element={<RobotUsers />} />
			<Route path="/users/last-login" element={<ByLastLogin />} />
			<Route path="/users/balance-search" element={<ByWalletBalance />} />
			<Route path="/users/all-users/:search" element={<UsersList />} />
			<Route path="/users/kyc" element={<UsersKYC />} />
			<Route path="/users/merchants" element={<AllMerchant />} />
			<Route path="/users/reports" element={<UsersQueryReports />} />
			<Route path="/manage-kyc/:id" element={<ManageKYC />} />
			<Route path="/kyc-suspects" element={<KYCSuspect />} />
			<Route path="/users/global-search" element={<UserGlobalSearch />} />
			<Route path="/alerts" element={<Alerts />} />
			<Route path="/alerts/email-newsletter" element={<EmailNewsletter />} />
			<Route
				path="/alerts/email-newsletter/:id"
				element={<SingleNewsletter />}
			/>
			<Route
				path="/alerts/email-newsletter-builder"
				element={<NewsletterBuilder />}
			/>
			<Route
				path="/alerts/email-newsletter-builder/mailing-lists"
				element={<NewsletterMailingListPage />}
			/>
			<Route path="/alerts/auto-emails" element={<AutoEmails />} />
			<Route path="/alerts/direct-emails" element={<DirectEmail />} />
			<Route path="/alerts/multiple-emails" element={<MultipleEmail />} />
			<Route
				path="/alerts/multiple-emails/:id"
				element={<SingleMultipleEmail />}
			/>
			<Route
				path="/alerts/direct-notifications"
				element={<ForcedNotification />}
			/>
			<Route path="/alerts/advert-banners" element={<AdvertBanners />} />
			<Route path="/alerts/in-app-alerts" element={<InAPPNotifications />} />
			<Route path="/alerts/in-app-news" element={<InAppNews />} />
			<Route path="/wallets" element={<Wallets />} />
			<Route path="/wallets/crypto-balances" element={<AllWalletBalances />} />
			<Route path="/transactions" element={<Transactions />} />
			<Route
				path="/transactions/blockchain"
				element={<BlockchainTransactions />}
			/>
			<Route
				path="/transactions/statements"
				element={<TransactionStatments />}
			/>
			<Route path="/transactions/out-debit" element={<OutDebitTransaction />} />
			<Route
				path="/transactions/out-debit/:userId"
				element={<OutDebitTransaction />}
			/>
			<Route
				path="/transactions/statements/:userId"
				element={<TransactionStatments />}
			/>
			<Route path="/transactions/in-credit" element={<InCredit />} />
			<Route path="/transactions/in-credit/:userId" element={<InCredit />} />
			<Route
				path="/transactions/deposits/first-funders"
				element={<FirstFunders />}
			/>
			<Route
				path="/transactions/deposits/thirtyDaysOfReg-funders"
				element={<ThirtyDaysFunders />}
			/>
			<Route path="/transactions/withdraw-queues" element={<WithdrawQueue />} />
			<Route
				path="/transactions/withdraw-requests"
				element={<WithdrawalRequests />}
			/>
			<Route
				path="/transactions/withdraw-requests/:userId"
				element={<WithdrawalRequests />}
			/>
			<Route path="/transactions/bulk-savings" element={<BulkSavings />} />
			<Route
				path="/transactions/bulk-savings/:id"
				element={<SingleBulkSavings />}
			/>
			<Route path="/core-modules" element={<CoreModules />} />
			<Route
				path="/core-modules/flexible-savings/daily-earnings"
				element={<DailyEarnings />}
			/>
			<Route
				path="/core-modules/flexible-savings/migration-logs"
				element={<MigrationLogs />}
			/>
			<Route
				path="/core-modules/flexible-savings/pending-profit-build"
				element={<PendingProfitBuild />}
			/>
			<Route
				path="/core-modules/flexible-savings/pending-profit-build/:userId"
				element={<PendingProfitBuild />}
			/>
			<Route
				path="/core-modules/flexible-savings/top-ups"
				element={<FlexibleSavings />}
			/>
			<Route
				path="/core-modules/flexible-savings/top-ups/:userId"
				element={<FlexibleSavings />}
			/>
			<Route
				path="/core-modules/flexible-savings"
				element={<FlexibleSavingsProfilesPage />}
			/>
			<Route
				path="/core-modules/flexible-savings/:userId"
				element={<FlexibleSavingsProfilesPage />}
			/>
			<Route
				path="/core-modules/flexible-savings/single/:flexibleSavingsId"
				element={<SingleFlexibleSavingsPage />}
			/>
			<Route path="/core-modules/fixed-savings" element={<FixedSavings />} />
			<Route
				path="/core-modules/fixed-savings/:userId"
				element={<FixedSavings />}
			/>
			<Route
				path="/core-modules/fixed-savings/top-ups"
				element={<TopupFixedSavingsItemsPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/top-ups/:userId"
				element={<TopupFixedSavingsItemsPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/termination"
				element={<TerminationRequests />}
			/>
			<Route
				path="/core-modules/fixed-savings/termination/:userId"
				element={<TerminationRequests />}
			/>
			<Route
				path="/core-modules/fixed-savings/pending-payouts"
				element={<FixedSavingsPendingPayoutPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/pending-payouts/:userId"
				element={<FixedSavingsPendingPayoutPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/pending-profit-build"
				element={<FixedSavingsPendingProfitBuildPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/pending-profit-build/:userId"
				element={<FixedSavingsPendingProfitBuildPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/expiring"
				element={<ExpiringFixedSavingsPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/expiring/:userId"
				element={<ExpiringFixedSavingsPage />}
			/>
			<Route
				path="/core-modules/fixed-savings/single/:fixedSavingsId"
				element={<SingleFixedSavings />}
			/>
			<Route path="/core-modules/swap" element={<Swap />} />
			<Route path="/core-modules/swap/:userId" element={<Swap />} />
			<Route
				path="/core-modules/loan-requests-due"
				element={<DueLoanRepayments />}
			/>
			<Route path="/core-modules/loan-requests" element={<Loan />} />
			<Route path="/core-modules/loan-requests/:userId" element={<Loan />} />
			<Route
				path="/core-modules/loan/item/:loanItemId"
				element={<SingleLoanItemPage />}
			/>
			<Route
				path="/core-modules/swap/item/:swapItemId"
				element={<SingleSwapItemPage />}
			/>
			<Route path="/core-modules/staking" element={<Staking />} />
			<Route path="/core-modules/staking/:userId" element={<Staking />} />
			<Route path="/thirdparty" element={<Thirdparty />} />
			<Route
				path="/thirdparty/xendbridge-sell-request"
				element={<XendBridgeSellRequest />}
			/>
			<Route
				path="/thirdparty/liquidity-providers"
				element={<LiquidityProviders />}
			/>
			<Route path="/thirdparty/mad-wallet" element={<MadWallet />} />
			<Route path="/robots" element={<Robots />} />
			<Route path="/robots/cronjobs" element={<AllCronJobs />} />
			<Route path="/robots/email-robot" element={<EmailRobot />} />
			<Route path="/robots/funds-robot" element={<FundsRobot />} />
			<Route path="/reports" element={<Reports />} />
			<Route path="/reports/apy-history" element={<ApyHistory />} />
			<Route path="/reports/referral" element={<ReferralReport />} />
			<Route path="/reports/referral/:userId" element={<ReferralReport />} />
			<Route path="/reports/accountant" element={<AccountantReport />} />
			<Route
				path="/reports/accountant/withdrawal-via-crypto"
				element={<AccountantExternalWithdrawals />}
			/>
			<Route
				path="/reports/accountant/savings-deposit"
				element={<SavingsDepositAccountReport />}
			/>
			<Route
				path="/reports/accountant/savings-withdrawal"
				element={<SavingsWithdrawalAccountReport />}
			/>
			<Route
				path="/reports/accountant/early-termination"
				element={<EarlyTerminationOfFixedSavings />}
			/>
			<Route
				path="/reports/accountant/swap"
				element={<SwapAccountantReport />}
			/>
			<Route
				path="/reports/accountant/loan"
				element={<LoanAccountantReport />}
			/>
			<Route
				path="/reports/accountant/staking"
				element={<StakingAccountantReport />}
			/>
			<Route
				path="/report/fixed-savings/paidout"
				element={<FixedSavingsReport />}
			/>
			<Route
				path="/report/fixed-savings/unpaid"
				element={<UnpaidFixedInterests />}
			/>
			<Route
				path="/report/fixed-savings/first-savers"
				element={<FirstSavers />}
			/>
			<Route
				path="/report/flexible-savings/paidout"
				element={<PaidOutFlexibleInterests />}
			/>
			<Route
				path="/reports/fee-collection/old-transaction-fees"
				element={<OldTransactionFees />}
			/>
			<Route
				path="/report/flexible-savings/first-savers"
				element={<FlexibleFirstSavers />}
			/>
			<Route path="/reports/swap" element={<SwapReport />} />
			<Route path="/reports/maintenance" element={<MaintenanceReport />} />
			<Route path="/reports/fee-collection" element={<ManageFeeCollection />} />
			<Route
				path="/reports/fee-collection/old-gas-fees"
				element={<OldGasFees />}
			/>
			<Route
				path="/reports/fee-collection/unmigrated"
				element={<UnmigratedFeesPage />}
			/>
			<Route
				path="/reports/fee-collection/manage"
				element={<ManageFeeCollection />}
			/>
			<Route
				path="/reports/fee-collection/batch"
				element={<FeeMigrationBatchPage />}
			/>
			<Route
				path="/reports/fee-collection/external-withdrawal-gas-fees"
				element={<ExternalWithdrawalFees />}
			/>
			<Route path="/reports/all-staking" element={<StakingReport />} />
			<Route path="/reports/staking-paidout" element={<PaidOutStaking />} />
			<Route path="/reports/old-transactions" element={<OldTransactions />} />
			<Route
				path="/reports/transaction-statement"
				element={<TransactionStatement />}
			/>
			<Route
				path="/reports/transaction-overview"
				element={<TransactionOverview />}
			/>
			<Route
				path="/reports/transactions-per-user-new"
				element={<TransactionPerUser />}
			/>
			<Route
				path="/reports/transactions-per-user-old"
				element={<TransactionPerUserOld />}
			/>
			<Route path="/settings" element={<SettingsPage />} />
			<Route
				path="/settings/internal-processes"
				element={<InternalSettings />}
			/>
			<Route path="/settings/currency" element={<CurrencySettings />} />
			<Route path="/settings/currency/fiat" element={<ManageFiatBanks />} />
			<Route path="/settings/staking" element={<StakingSettings />} />
			<Route path="/settings/loan" element={<LoanSettings />} />
			<Route path="/settings/savings" element={<SavingsSettings />} />
			<Route path="/settings/swap" element={<CurrencySwapSettings />} />
			<Route path="/settings/roles" element={<Roles />} />
			<Route
				path="/settings/roles/roles-permissions"
				element={<RolesAndPermissions />}
			/>
			<Route path="/settings/general" element={<GeneralSettings />} />
			<Route path="/settings/transaction-fees" element={<TransactionFees />} />
			<Route path="/settings/referral" element={<ReferralSettings />} />
			<Route path="/user-settings/:id" element={<UserSettings />} />
			<Route
				path="/user-settings/:id/savings"
				element={<UserSavingsSettings />}
			/>
			<Route path="/user-settings/:id/info" element={<UserInfoSettings />} />
			<Route
				path="/user-settings/:id/general"
				element={<UserGeneralSettings />}
			/>
			<Route path="/user-profile/:id" element={<UsersProfile />} />
			<Route path="/merchant-profile/:id" element={<MerchantProfile />} />
			<Route path="/my-profile" element={<MyProfile />} />
			<Route path="/user/last-login/:id" element={<UserLastLogin />} />
			<Route path="/user/referral" element={<ReferralFilter />} />
			<Route path="/search" element={<GeneralGlobalSearch />} />
			<Route path="/extras" element={<ExtrasPage />} />
			<Route path="/extras/games" element={<Games />} />
			<Route path="/extras/games/single/:gameId" element={<SingleGame />} />
			<Route
				path="/extras/games/single/:gameId/subscribers"
				element={<GameSubscribers />}
			/>
			<Route
				path="/extras/games/single/:gameId/game-action-activities"
				element={<GameActionActivities />}
			/>
			<Route
				path="/extras/games/single/:gameId/rewards"
				element={<GameRewards />}
			/>
			<Route path="/extras/games/settings" element={<GameSettings />} />
			<Route
				path="/extras/games/point-distribution/user-game-action-activities/:userId"
				element={<GameUserActionActivities />}
			/>
			<Route
				path="/extras/games/point-distribution"
				element={<GamePointDistribution />}
			/>
			<Route
				path="/extras/games/settings/game-ranks"
				element={<GameBadgeItem />}
			/>
			<Route
				path="/extras/games/settings/game-ranks/single/:gameRankId"
				element={<SingleGameBadgeItem />}
			/>
			<Route
				path="/extras/games/settings/game-action/single/:actionId"
				element={<SingleGameActionItem />}
			/>

			<Route path="/extras/monthly-savers" element={<MonthlySavers />} />
			<Route path="/extras/daily-savers" element={<DailySavers />} />
			<Route path="/extras/weekly-savers" element={<WeeklySavers />} />
			<Route path="/extras/tgif" element={<TGIFSavers />} />
			<Route path="/extras/top-referrals" element={<TopReferrals />} />
			<Route path="/compliance" element={<CompliancePage />} />

			<Route path="/compliance/double-funding" element={<DoubleFunding />} />

			<Route
				path="/compliance/negative-balances"
				element={<NegativeBalances />}
			/>

			<Route
				path="/compliance/negative-flexible-balances"
				element={<NegativeFlexibleBalances />}
			/>

			<Route
				path="/compliance/funding-requests"
				element={<FundingRequests />}
			/>

			<Route
				path="/compliance/double-funding/deleted"
				element={<DeletedDoubleFundings />}
			/>
			<Route path="/compliance/low-trust-score" element={<TrustScoreUsers />} />
			<Route
				path="/compliance/locked-savings/fixed"
				element={<FixedLockedSavings />}
			/>
			<Route
				path="/compliance/locked-savings/flexible"
				element={<FlexibleLockedSavings />}
			/>
			<Route path="/compliance/spam-domains" element={<SpamDomains />} />
			<Route
				path="/compliance/blacklisted-users"
				element={<BlackListedUsers />}
			/>

			<Route path="/maintenance" element={<Maintenance />} />
			<Route
				path="/maintenance/email-bounce"
				element={<EmailBounceMaintenance />}
			/>
			<Route
				path="/maintenance/2fa-reset-request"
				element={<Reset2faRequests />}
			/>
			<Route
				path="/maintenance/single-2fa/:id"
				element={<Single2faRequest />}
			/>
			<Route
				path="/maintenance/account-deletion"
				element={<AccountDeletionList />}
			/>
			<Route path="/maintenance/service-status" element={<ServiceStatus />} />
			<Route
				path="/maintenance/manual-switch-funding"
				element={<ManualSwitchFunding />}
			/>
			<Route path="/maintenance/old-plans/:userId" element={<UserOldPlans />} />
		</Route>

		<Route element={<Authenticated />}>
			<Route path="/login" element={<AuthLogin />} />
			<Route path="/two-factor-setup" element={<TwoFactorSetup />} />
		</Route>
	</Routes>
);

export default App;
