import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';
import UnlockLoanFixedSavings from './UnlockLoanFixedSavings';

interface IFixedSavingsItem {
	item: any;
	// onChange: Function;
}

const Style = styled.div`
	margin-bottom: 10px;
	padding: 12px;
	background-color: #f8f8f8;

	& .amount {
		font-size: 18px;
	}

	& .title > * {
		display: inline-block;
	}

	& .details {
		margin-top: 10px;
		border-top: 1px dashed #ccc;
		font-size: 14px;
		font-style: italic;

		& .label {
			color: #aaa;
			font-size: 12px;
			font-style: italic;
		}

		& > * {
			display: inline-block;
			padding: 10px;
		}
	}
`;

const LockedFixedItem = ({ item }: IFixedSavingsItem) => (
	<Style>
		{/* <div>
			<p className="amount">
				<Checkbox
					onChange={(e: any) => onChange({ e, id: item?._id, details: item })}
				/>{' '}
				{'  '}
				{commas(item.cacheCapital + item.cacheProfit)} {item.currencyId?.symbol}
			</p>
		</div> */}
		<div className="details">
			<div>
				<Link
					title="View Customer Profile"
					to={`/user-profile/${
						item?.userId?._id || item?.user?._id || item?.userId
					}`}
				>
					@
					{item?.userId?.username ||
						item?.user?.username ||
						item?.user?.fullName}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>
					<Link
						to={`/core-modules/fixed-savings/single/${item?.fixedSavingsId?._id}`}
						title="View Savings Profile"
					>
						{item?.fixedSavingsId?.title}
					</Link>
				</p>
				<p>Title</p>
			</div>
			<div>
				<p>{item?.fixedSavingsId?.status}</p>
				<p className="label">Status</p>
			</div>
			<div>
				<p>
					{commas(item?.fixedSavingsId?.cacheCapital)}{' '}
					{item.fixedSavingsCurrencyId?.symbol}
				</p>
				<p className="label">Capital</p>
			</div>
			<div>
				<p>
					{commas(item?.fixedSavingsId.cacheProfit)}{' '}
					{item.fixedSavingsCurrencyId?.symbol}
				</p>
				<p className="label">Profit</p>
			</div>
			<div>
				<p>{item?.fixedSavingsId.durationDays} Days</p>
				<p className="label">Duration</p>
			</div>
			<div>
				<p>
					{moment(item?.fixedSavingsId.startDate).format(
						'MMM DD, YY / hh:mm:ss a'
					)}
				</p>
				<p className="label">Starts</p>
			</div>
			<div>
				<p>
					{moment(item?.fixedSavingsId.endDate).format(
						'MMM DD, YY / hh:mm:ss a'
					)}
				</p>
				<p className="label">Ends</p>
			</div>
			<div>
				<p>{item?.fixedSavingsId?.durationDays} days</p>
				<p className="label">Duration</p>
			</div>
			<div>
				<p>{moment(item.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Created At</p>
			</div>
			<div>
				<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Updated At</p>
			</div>
			{item?.fixedSavingsId?.status !== 'completed' && (
				<div>
					<UnlockLoanFixedSavings fixedSavings={item?.fixedSavingsId?._id} />
				</div>
			)}
		</div>
	</Style>
);
export default LockedFixedItem;
