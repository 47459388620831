import { useEffect } from 'react';
import { Card, Divider, Pagination } from 'antd';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AccountantsDateSelector from '../DateSelector';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getAccountantsSavingsReport } from '../../../methods/redux/reducers/accountant-report';
import NativeNumber from '../../../component/Numeral';
import TransactionItem from '../../../transactions/transaction-statments/TransactionItem';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import DownloadCSV from '../DownloadCSV';

const Style = styled(Card)`
	& .aggregate {
		border: 1px solid #ddd;
		padding: 20px;

		& > * {
			display: inline-block;
			margin-right: 20px;
			font-size: 18px;

			& .label {
				font-style: italic;
				color: #888;
				font-size: 13px;
			}
		}
	}
`;

const SavingsWithdrawalAccountReport = () => {
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	const { savingsReport } = useAppSelector((store) => store.accountantsReport);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(
				getAccountantsSavingsReport({
					...queryData,
					transactionType: 'credit',
				})
			);
		}
	}, [searchParams]);

	return (
		<Style>
			<h1>Savings Withdrawals</h1>
			<p>
				Accountant report for fixed and flexible savings and how much collected
				as transaction fees in withdrawals from the savings.
			</p>

			<Divider />

			<AccountantsDateSelector onGetReport={updateQueryString} />

			{savingsReport.withdrawal.aggregate.length > 0 && (
				<>
					<br />
					{savingsReport.withdrawal.aggregate.map((data: any) => (
						<div className="aggregate" key={1}>
							<div>
								<p style={{ color: 'var(--green-color)' }}>
									<strong>
										<NativeNumber
											value={data.totalFeesCollected}
											decimalPlaces={4}
										/>{' '}
										USD
									</strong>
								</p>
								<p className="label">Total Fees Collected</p>
							</div>
							<div>
								<p>
									<strong>
										<NativeNumber
											value={data.totalTransactionAmount}
											decimalPlaces={4}
										/>{' '}
										USD
									</strong>
								</p>
								<p className="label">Total Transaction Amount</p>
							</div>
							<div>
								<p>
									<strong>
										<NativeNumber value={data.count} /> Transactions
									</strong>
								</p>
								<p className="label">Transactions Count</p>
							</div>
						</div>
					))}
				</>
			)}

			{savingsReport.withdrawal.record.itemsList.length > 0 && (
				<>
					<Divider />

					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={savingsReport.withdrawal.record.paginator.itemCount}
						current={savingsReport.withdrawal.record.paginator.currentPage}
						pageSize={savingsReport.withdrawal.record.paginator.perPage}
					/>

					<br />

					<div>
						<DownloadCSV
							title="savings-withdrawal"
							csv={savingsReport.withdrawal.record?.reportCSVFile}
						/>
					</div>

					<br />

					{savingsReport.withdrawal.record.itemsList.map((item: any) => (
						<TransactionItem key={item._id} transaction={item} />
					))}

					<Divider />
					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={savingsReport.withdrawal.record.paginator.itemCount}
						current={savingsReport.withdrawal.record.paginator.currentPage}
						pageSize={savingsReport.withdrawal.record.paginator.perPage}
					/>
				</>
			)}
		</Style>
	);
};

export default SavingsWithdrawalAccountReport;
