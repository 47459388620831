import moment from 'moment';
import styled from 'styled-components';

interface ISingleFeeItem {
	data: any;
}

const Style = styled.div`
	margin-bottom: 10px;
	background-color: #f8f8f8;
	padding: 20px;

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 7px;
		padding-top: 10px;

		& > * {
			display: inline-block;
			margin-right: 20px;
		}

		& .label {
			font-style: italic;
			font-size: 12px;
			color: #aaa;
		}
	}
`;

const SingleFeeItem = ({ data }: ISingleFeeItem) => (
	<Style>
		<div>
			<p>
				<strong>
					{data.associatedTransactionFee} {data.currencyId?.symbol}
				</strong>
			</p>
		</div>
		<div className="details">
			<div>
				<p>@{data.userId?.username}</p>
				<p className="label">Customer</p>
			</div>
			<div>
				<p
					style={{
						color:
							data.transactionType === 'credit'
								? 'var(--green-color)'
								: 'var(--red-color)',
					}}
				>
					{data.actualAmount} {data.currencyId?.symbol}
				</p>
				<p className="label">Actual Amount</p>
			</div>
			<div>
				<p>{data.transactionType}</p>
				<p className="label">Transaction Type</p>
			</div>
			<div>
				<p>{data.transactionForm}</p>
				<p className="label">Transaction Form</p>
			</div>
			<div>
				<p>{moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Created</p>
			</div>
		</div>
	</Style>
);

export default SingleFeeItem;
