import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { trustScoreColor } from '../../methods/utils/general-utils';

const MainContainer = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100px;
	margin: 2rem 0px 5px 0px;
`;

const HalfCircle = styled.div`
	display: flex;
	position: absolute;
	width: 300px;
	height: 150px;
	position: relative;
	text-align: center;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	border-radius: 150px 150px 0 0;
	overflow: hidden;
	color: ${(props) => props.color};
	display: flex;
	align-items: flex-end;
	justify-content: center;
	box-sizing: border-box;
	&:before,
	&:after {
		content: '';
		width: 300px;
		height: 150px;
		border: 50px solid ${(props) => props.color};
		border-top: none;
		position: absolute;
		transform-origin: 50% 0% 0;
		border-radius: 0 0 300px 300px;
		box-sizing: border-box;
		left: 0;
		top: 100%;
	}
	&:before {
		border-color: rgba(0, 0, 0, 0.15);
		transform: rotate(180deg);
	}
	&:after {
		z-index: 3;
		animation: 1s fillGraphAnimation ease-in;
		transform: rotate(calc(1deg * (${(props: any) => props?.slot || 0} * 1.8)));
	}
	&:hover {
		&:after {
			opacity: 0.8;
		}
	}
`;

const ContentSection = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	height: 100%;

	& > div {
		font-size: 18px;
		font-weight: bold;
		color: Black;
	}

	& > span {
		font-size: 14px;
		/* color: green; */
	}
`;

const UpdateLink = styled(Link)`
	font-size: 12px;
`;

interface ITrustScoreSection {
	trustScore: any;
	id: any;
}
const TrustScoreSection: FC<ITrustScoreSection> = ({ trustScore, id }) => (
	<MainContainer>
		<HalfCircle color={trustScoreColor(trustScore)} slot={trustScore}>
			<ContentSection>
				<div>TrustScore</div>
				<span>{trustScore}/100</span>
				<UpdateLink to={`/user-settings/${id}/general`}>update</UpdateLink>
			</ContentSection>
		</HalfCircle>
		{/* <ColorCircle />
			<ContentSection>
				<div>TrustScore</div>
				<span>80/100</span>
			</ContentSection> */}
	</MainContainer>
);

export default TrustScoreSection;
