import { useState } from 'react';
import { Button, Modal } from 'antd';
import baseuri from '../../methods/baseuri';

interface ITriggerReward {
	amountInUSD: any;
	username: any;
	planId: any;
	recall: any;
}

const TriggerXendRewardWithdrawalTillProMigration = ({
	amountInUSD,
	username,
	planId,
	recall,
}: ITriggerReward) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const trigger = async () => {
		setLoading(true);
		try {
			await baseuri.post('/admin/maintenance/pay-old-plans-reward', { planId });
			setVisible(false);
			setLoading(false);
			recall();
		} catch (e: any) {
			setLoading(false);
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Trigger Bonus Withdrawal</Button>

			<Modal
				footer={null}
				open={visible}
				width={320}
				onCancel={() => setVisible(false)}
				title="Trigger Xend Bonus Withdrawal"
				confirmLoading={loading}
			>
				<p>
					Proceeding with this action will trigger the deposit of {amountInUSD}{' '}
					USD worth of XEND to @{username}
				</p>
				<br />
				<div>
					<Button
						loading={loading}
						type="primary"
						block
						onClick={() => trigger()}
					>
						Proceed
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default TriggerXendRewardWithdrawalTillProMigration;
