import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import GlobalStyles from './theme/theme';
import App from './App';
import appState from './methods/redux/store';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import './index.css';

appState().then((store) => {
	const root = ReactDOM.createRoot(
		document.getElementById('root') as HTMLElement
	);
	root.render(
		<Provider store={store}>
			<BrowserRouter>
				<GlobalStyles />
				<App />
			</BrowserRouter>
		</Provider>
	);
});
