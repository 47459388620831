import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const getAccountantsSavingsReport = createAsyncThunk(
	'accountant-report/getSavingsReport',
	async (params: any) => {
		const res = await baseuri.get('/admin/accountant/savings-report', {
			params,
		});
		return {
			data: res.data.data,
			transactionType: params.transactionType || 'debit',
		};
	}
);

export const getEarlyTerminationReport = createAsyncThunk(
	'accountant/getEarlyTerminationReport',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/accountant/terminate-fixedsavings-report',
			{ params }
		);
		return res.data.data;
	}
);

export const getSwapReport = createAsyncThunk(
	'accountant/getSwapReport',
	async (params: any) => {
		const res = await baseuri.get('/admin/accountant/swap-report', { params });
		return res.data.data;
	}
);

export const getStakingReport = createAsyncThunk(
	'accountant/getStakingReport',
	async (params: any) => {
		const res = await baseuri.get('/admin/accountant/staking-report', {
			params,
		});
		return res.data.data;
	}
);
export const getLoanReport = createAsyncThunk(
	'accountant/getLoanReport',
	async (params: any) => {
		const res = await baseuri.get('/admin/accountant/loan-report', { params });
		return res.data.data;
	}
);

const accountantReport = createSlice({
	name: 'accountant-report',
	initialState: {
		staking: {
			aggregate: [],
			record: {
				reportCSVFile: '',
				itemsList: [],
				paginator: {
					itemCount: 0,
					currentPage: 1,
					perPage: 10,
				},
			},
		},
		loan: {
			aggregate: [],
			record: {
				reportCSVFile: '',
				itemsList: [],
				paginator: {
					itemCount: 0,
					currentPage: 1,
					perPage: 10,
				},
			},
		},
		swap: {
			aggregate: [],
			record: {
				reportCSVFile: '',
				itemsList: [],
				paginator: {
					itemCount: 0,
					currentPage: 1,
					perPage: 10,
				},
			},
		},
		earlyTermination: {
			aggregate: [],
			record: {
				reportCSVFile: '',
				itemsList: [],
				paginator: {
					itemCount: 0,
					currentPage: 1,
					perPage: 10,
				},
			},
		},
		savingsReport: {
			deposit: {
				aggregate: [],
				record: {
					reportCSVFile: '',
					itemsList: [],
					paginator: {
						itemCount: 0,
						currentPage: 1,
						perPage: 10,
					},
				},
			},
			withdrawal: {
				aggregate: [],
				record: {
					reportCSVFile: '',
					itemsList: [],
					paginator: {
						itemCount: 0,
						currentPage: 1,
						perPage: 10,
					},
				},
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getAccountantsSavingsReport.fulfilled, (state, action) => {
				const store = state;
				if (action.payload.transactionType === 'credit') {
					store.savingsReport.withdrawal = action.payload.data;
				} else {
					store.savingsReport.deposit = action.payload.data;
				}
			})
			.addCase(getEarlyTerminationReport.fulfilled, (state, action) => {
				const store = state;
				store.earlyTermination = action.payload;
			})
			.addCase(getSwapReport.fulfilled, (state, action) => {
				const store = state;
				store.swap = action.payload;
			})
			.addCase(getLoanReport.fulfilled, (state, action) => {
				const store = state;
				store.loan = action.payload;
			})
			.addCase(getStakingReport.fulfilled, (state, action) => {
				const store = state;
				store.staking = action.payload;
			}),
});

export default accountantReport.reducer;
