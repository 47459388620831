import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import { retrieveTopSavers } from '../../methods/redux/reducers/fixed-savings';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';
import ColumnFlexible from './ColumnFlexible';
import FlexibleAggregate from './FlexibleAggregate';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const DailySavers = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	// const { user } = useAppSelector((store) => store.userProfile);
	const { topSavers } = useAppSelector((store) => store.fixedSavings);
	const { currencies } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(topSavers.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(retrieveTopSavers({ ...queryData, form: 'dailySavers' }));
			} else {
				dispatch(retrieveTopSavers({ ...queryData, form: 'dailySavers' }));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<Card>
			<h1>Daily Top Savers</h1>
			<FlexibleAggregate tokens={topSavers.record} />

			<br />

			<SearchFilter
				title="Search Daily Top Savers"
				searchFilters={[
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of <strong>{topSavers.paginator.itemCount} items</strong> found
			</p>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={topSavers.paginator.itemCount}
				current={topSavers.paginator.currentPage}
				pageSize={topSavers.paginator.perPage}
			/>
			<br />

			{topSavers.itemsList.map((item: any) => (
				<ColumnFlexible key={item?._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={topSavers.paginator.itemCount}
				current={topSavers.paginator.currentPage}
				pageSize={topSavers.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default DailySavers;
