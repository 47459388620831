import { Divider } from 'antd';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

const ItemSection = styled.div`
	display: inline-block;
	border: 1px solid #d5d3d3;
	padding: 20px;
	margin-top: 20px;
	margin-right: 20px;
	max-width: 500px;

	& .amount {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 600;
	}

	& img {
		margin-right: 10px;
		border-radius: 50%;
		width: 20px;
		height: 20px;
	}

	& .details {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px dashed #ddd;

		& > * {
			display: inline-block;
			margin-right: 15px;
		}

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}
	}
`;

interface IAggregate {
	data: any[];
}

const OldGasFeesAggregate = ({ data }: IAggregate) => {
	if (data.length > 0) {
		return (
			<div>
				<p>
					<strong>Total Gas Fees Spent on the selected Date Ranges</strong>
				</p>

				{data.map((record: any) => (
					<ItemSection key={record._id}>
						<strong>Total Gas cost on {record?._id?.action || '--'} Txs</strong>
						<div className="amount">
							<p>{record.gasCostUSD ? commas(record.gasCostUSD) : '--'} USD</p>
						</div>
						<div className="details">
							<div>
								<p>{record.count} transactions</p>
								<div className="label">Transaction Count</div>
							</div>
							<div>
								<p>{record.amount ? commas(record.amount) : '0'}</p>
								<div className="label">Total Gas Spent</div>
							</div>
						</div>
					</ItemSection>
				))}

				<Divider />
			</div>
		);
	}
	return null;
};

export default OldGasFeesAggregate;
