import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const NewDeviceLoginWithdrawal = () => {
	const dispatch = useAppDispatch();

	const settings = useAppSelector((store) => store.internalSettings);

	const [state, setState] = useState({
		newDeviceLoginWithdrawalSuspensionHour: '0',
	});

	const updateSettings = () => {
		dispatch(updateInternalSettings({ ...state }));
	};

	useEffect(() => {
		setState(settings);
	}, [settings]);

	return (
		<div>
			<p>New Device Login Withdrawal Time</p>
			<div>
				<Input
					addonAfter="Hours"
					value={state.newDeviceLoginWithdrawalSuspensionHour}
					onChange={(e) =>
						setState({
							newDeviceLoginWithdrawalSuspensionHour: e.target.value,
						})
					}
				/>
			</div>
			<br />
			<div>
				<Button onClick={updateSettings}>Update</Button>
			</div>
		</div>
	);
};

export default NewDeviceLoginWithdrawal;
