import { Tag, Popconfirm, Button } from 'antd';
import Input from 'antd/lib/input/Input';
import Modal from 'antd/lib/modal/Modal';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	getAllPermissions,
	removePermission,
} from '../../../methods/redux/reducers/roles';

interface IMainItems {
	permission: any;
}
const MainItems: FC<IMainItems> = ({ permission }) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		id: '',
		name: '',
	});

	useEffect(() => {
		setState({
			id: permission?._id,
			name: permission?.name,
		});
	}, [permission]);

	const remove = () => {
		dispatch(removePermission(state));
		setVisible(false);
	};

	return (
		<>
			<Tag style={{ display: 'inline-block' }} onClick={() => setVisible(true)}>
				{permission?.name}
			</Tag>

			<Modal
				title="Modify Permissions"
				width={400}
				visible={visible}
				onCancel={() => setVisible(false)}
				footer={[
					<Popconfirm title="Confirm Delete?" onConfirm={remove}>
						<Button type="primary" danger>
							Delete
						</Button>
					</Popconfirm>,
				]}
			>
				<div>
					<p>Title</p>
					<Input
						readOnly
						value={permission.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>
				</div>
				<br />
			</Modal>
		</>
	);
};

const PermissionItems = () => {
	const { allPermissions } = useAppSelector((store) => store.roles);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getAllPermissions());
	}, []);

	return (
		<div>
			{allPermissions?.itemsList?.map((permission: any) => (
				<MainItems permission={permission} key={permission._id} />
			))}
		</div>
	);
};

export default PermissionItems;
