import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const retrieveLoanRequest = createAsyncThunk(
	'loan/retrieveLoanRequest',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('admin/loans/stable-currency', { params });
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const retrieveLoanRequestThatAreDue = createAsyncThunk(
	'loan/retrieveLoanRequestThatAreDue',
	async (params: any) => {
		const res = await baseuri.get(
			'admin/loans/stable-currency/loan-repayment-due',
			{ params }
		);
		return res.data.data;
	}
);

export const retrieveLockableSavings = createAsyncThunk(
	'loan/retrieveLockableSavings',
	async (params: any) => {
		// const loadingId = randomgen();
		// dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get(
			'admin/loans/stable-currency/lockable-fixed-savings-accounts',
			{ params }
		);
		// dispatch(removeFromLoadingQueue(loadingId));
		return {
			...res.data.data.lockableFixedSavings,
			...res.data.data,
		};
	}
);
export const retrieveLockedSavings = createAsyncThunk(
	'loan/retrieveLockedSavings',
	async (params: any) => {
		// const loadingId = randomgen();
		// dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get(
			'admin/loans/loan-collateral/locked-fixed-savings-collateral',
			{ params }
		);
		// dispatch(removeFromLoadingQueue(loadingId));
		return {
			...res.data.data.lockableFixedSavings,
			...res.data.data,
		};
	}
);

export const retrieveSingleLoanRequest = createAsyncThunk(
	'loan/retrieveSingleLoanRequest',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('admin/loans/stable-currency/single', {
			params,
		});
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const validateLoanData = createAsyncThunk(
	'loan/validateLoanData',
	async (data: any) => {
		const res = await baseuri.post(
			'/admin/loans/stable-currency/validate',
			data
		);
		return res.data.data;
	}
);

export const distributeLoan = createAsyncThunk(
	'loan/distributeLoan',
	async (params: any, { dispatch }) => {
		try {
			const res: any = await baseuri.post(
				`/admin/loans/stable-currency/disburse-loan`,
				params
			);

			dispatch(retrieveSingleLoanRequest({ loanId: params?.loanId }));

			message.success(res?.data?.message);
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	}
);
export const unlockFixedSavingLoan = createAsyncThunk(
	'loan/unlockFixedSavingLoan',
	async (params: any) => {
		try {
			const res: any = await baseuri.post(
				`/admin/loans/loan-collateral/remove-fixed-savings-to-collateral`,
				params
			);

			if (res.status === 200) {
				message.success(res?.data?.message);
			} else {
				message.info(res?.data?.message);
			}
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	}
);
export const startLoan = createAsyncThunk(
	'loan/startLoan',
	async (params: any, { dispatch }) => {
		try {
			const res: any = await baseuri.post(
				`/admin/loans/stable-currency/start-loan-process`,
				params
			);

			dispatch(retrieveSingleLoanRequest({ loanId: params?.loanId }));

			message.success(res?.data?.message);
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	}
);
export const rejectLoan = createAsyncThunk(
	'loan/rejectLoan',
	async (params: any) => {
		try {
			const res: any = await baseuri.post(
				`/admin/loans/stable-currency/reject-loan-process`,
				params
			);

			if (res.status === 200) {
				message.success(res?.data?.message);
			} else {
				message.info(res?.data?.message);
			}
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	}
);
export const lockFixedForLoan = createAsyncThunk(
	'loan/lockFixedForLoan',
	async (params: any) => {
		try {
			const res: any = await baseuri.post(
				`/admin/loans/loan-collateral/add-fixed-savings-to-collateral`,
				params
			);

			if (res.status === 200) {
				message.success(res?.data?.message);
			} else {
				message.info(res?.data?.message);
			}
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	}
);

export const retrieveLoanStatuses = createAsyncThunk(
	'loan/retrieveLoanStatuses',
	async (params: any) => {
		const res = await baseuri.get('/admin/loans/stable-currency/status-count', {
			params,
		});
		return res.data.data;
	}
);

export const adminLiquidateLoan = createAsyncThunk(
	'loan/adminLiquidateLoan',
	async (data: any, { dispatch }) => {
		await baseuri.post(
			'/admin/loans/stable-currency/admin-liquidate-user-loan',
			data
		);
		dispatch(retrieveSingleLoanRequest({ loanId: data.loanId }));
	}
);

export const adminLiquidateDefaultedLoan = createAsyncThunk(
	'loan/adminLiquidateLoan',
	async (data: any, { dispatch }) => {
		await baseuri.post('/admin/loans/stable-currency/liquidate-loan', data);
		dispatch(retrieveSingleLoanRequest({ loanId: data.loanId }));
	}
);

const loanRx = createSlice({
	name: 'loan',
	initialState: {
		loanStatuses: {
			completedLoan: 0,
			pendingLoan: 0,
			processingLoan: 0,
			rejectedLoan: 0,
			runningLoan: 0,
		},
		duePayments: {
			itemsList: [],
			paginator: {
				perPage: 20,
				currentPage: 1,
				itemCount: 0,
			},
		},
		loanRequests: {
			itemsList: [],
			paginator: {
				perPage: 20,
				currentPage: 1,
				itemCount: 0,
			},
		} as any,
		singleLoanRequest: {
			transactionStatements: [],
			loanData: {
				_id: '',
				title: '',
				loanAmount: 0,
				interest: 0,
				payBackPeriodInDays: 0,
				paybackExtensionDay: 0,
				totalLoanPaid: 0,
				totalLoanRemaining: 0,
				loanApprovalDate: '',
				expectedPaybackDate: '',
				LoanPaybackDate: null,
				liquidateLoan: false,
				durationDays: 0,
				userId: {
					_id: '',
					email: '',
					username: '',
					fullName: '',
				},
				currencyId: {
					iconUrl: null,
					_id: '624204eb5f655ee95e0d3a98',
					name: 'Binance USD',
					symbol: 'BUSD',
					icon_url:
						'https://ipfs.io/ipfs/QmW75qcxH9HqTVdRALLt3ALuxBCcbpM2PBX7Pc8EuSAeAh',
					allowLoans: true,
				},
				fixedSavingsId: {
					cacheRewardValue: 0,
					createdByAutoSave: false,
					associatedLoanId: null,
					_id: '',
					userId: '',
					title: '',
					durationDays: 0,
					startDate: '',
					endDate: '',
					cacheProfit: 0,
					cacheRewardTokenValue: 0,
					cacheCapital: 0,
					cacheDayOfYear: [],
					cacheProfitLastDate: null,
					requestTerminationApprovalDate: null,
					lastUpdatedByUserId: '',
					payoutInitiatorUserId: null,
					currencyId: '',
					savingsForm: '',
					payoutStatus: '',
					payout: true,
					requestTermination: false,
					requestTerminationDate: null,
					requestTerminationInitialDurationDays: 0,
					requestTerminationStatus: '',
					status: '',
					createdAt: '',
					updatedAt: '',
					__v: 0,
					regDate: '',
					locked: false,
					lockedAdmin: '',
					lockedAmount: 200,
					lockedComment: '',
					lockedDate: '',
					unLockedAdmin: '',
					unLockedDate: '',
				},
				lastUpdatedByUserId: '',
				loanAdminId: null,
				status: '',
				durationInterest: 0,
				interestAmount: 0,
				repayableAmount: 0,
				createdAt: '0',
				updatedAt: '0',
				__v: 0,
			},
		},
		lockableFixedSavings: {
			content: [],
			cacheCapital: '',
			count: 0,
			paginator: {} as any,
		},
		lockedFixedSavings: {
			itemsList: [],
			paginator: {} as any,
		},
		validateInfo: {
			currencyLoanable: false,
			durationValid: false,
			expectedPaybackDate: null,
			fixedSavingsValid: false,
			loanAmount: '0',
			loanInterest: {
				interest: 0,
				durationInterest: 0,
				interestAmount: 0,
				repayableAmount: 0,
			},
			durationInterest: 0,
			interest: 0,
			interestAmount: 0,
			repayableAmount: 0,
			maxLoanAmount: 0,
			maxLoanAmountUsd: 0,
			minLoanAmountUsd: 0,
			profileLoanable: false,
			systemAllowLoan: false,
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveLoanRequest.fulfilled, (store, action) => {
				const state = store;
				state.loanRequests = { ...state.loanRequests, ...action.payload };
			})
			.addCase(retrieveSingleLoanRequest.fulfilled, (store, action) => {
				const state = store;
				state.singleLoanRequest = action.payload;
			})
			.addCase(retrieveLockableSavings.fulfilled, (store, action) => {
				const state = store;
				state.lockableFixedSavings = action.payload;
			})
			.addCase(retrieveLockedSavings.fulfilled, (store, action) => {
				const state = store;
				state.lockedFixedSavings = action.payload;
			})
			.addCase(retrieveLoanStatuses.fulfilled, (store, action) => {
				const state = store;
				state.loanStatuses = action.payload;
			})
			.addCase(validateLoanData.fulfilled, (store, action) => {
				const state = store;
				state.validateInfo = action.payload;
			})
			.addCase(retrieveLoanRequestThatAreDue.fulfilled, (store, action) => {
				const state = store;
				state.duePayments = action.payload;
			}),
});

export default loanRx.reducer;
