import { Button, Input, Modal } from 'antd';
import { useState } from 'react';
import { addBlacklistedUser } from '../../methods/redux/reducers/compliance';

const AddToBlacklistedUser = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		userId: '',
		deviceId: '',
	});

	const submit = async () => {
		setLoading(true);
		await addBlacklistedUser(state);
		setLoading(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Add Blacklisted</Button>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Add To Blacklist"
				footer={null}
			>
				<div>
					<p>User Id</p>
					<Input
						value={state.userId}
						onChange={(e) => setState({ ...state, userId: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Device Id</p>
					<Input
						value={state.deviceId}
						onChange={(e) => setState({ ...state, deviceId: e.target.value })}
					/>
				</div>
				<br />
				<Button
					loading={loading}
					disabled={loading}
					onClick={submit}
					block
					type="primary"
					size="large"
				>
					Add Blacklisted
				</Button>
			</Modal>
		</>
	);
};

export default AddToBlacklistedUser;
