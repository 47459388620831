import styled from 'styled-components';
import { Switch } from 'antd';
import { FC } from 'react';
// import RecurrentDrawer from './RecurrentDrawer';
import OutgoingWithdrawInterval from './OutgoingWithdrawInterval';
// import Timer from './Timer';
import Notifications from './Notifications';
// import MaxOutgoingXend from './MaxOutgoingXend';
import MaxOutgoingBUSD from './MaxOutgoingBUSD';
// import PlanWithdrawInterval from './PlanWithdrawInterval';
import MaxGasLimit from './MaxGasLimit';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { handleSwitch } from '../../../methods/redux/reducers/fundsRobots';

const Container = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
`;
const HeaderTop = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 50px;
	padding: 0px 20px;

	.headerTopTitle {
		display: flex;
		flex-direction: column;
	}
	.headerTopTitleLeft {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
	}
	.activeSection {
		display: flex;
		flex-direction: row;
	}
	.titleSectionDown {
		display: flex;
		position: relative;
		align-items: center;
	}
	.blink_me {
		display: inline-flex;
		background-color: green;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		animation: blinker 1s linear infinite;
		margin-left: 10px;
		padding: 0;
	}

	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
`;
const HeaderDown = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-between;
	width: 100%;
`;
export const ItemSection = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	background-color: whitesmoke;
	width: 100%;
	height: 40px;
	align-items: center;
	justify-content: space-between;
	margin: 2px;
	padding: 2px 10px;
`;

interface IHeaderSection {
	cronDetails: any;
	// timer: number;
	// startTimer: boolean;
	// setTimer: Function;
	// setStartTimer: Function;
}

const HeaderSection: FC<IHeaderSection> = ({
	cronDetails,
	// setTimer,
	// startTimer,
	// timer,
	// setStartTimer,
}) => {
	const dispatch = useAppDispatch();

	return (
		<Container>
			<HeaderTop>
				{cronDetails[1] &&
					cronDetails
						?.filter((a: any) => a?.name === 'isCronActive')
						?.map((data: any) => (
							<div className="headerTopTitle" key={data?._id}>
								<strong>Activate Robot</strong>
								<div className="titleSectionDown">
									<Switch
										defaultChecked={data?.value === 'true'}
										unCheckedChildren="Inactive"
										checkedChildren="Active"
										onClick={(checked: boolean) => {
											dispatch(handleSwitch({ id: data?._id, value: checked }));
											// setStartTimer(checked);
											// setTimer(0);
										}}
									/>
									{data?.value === 'true' ? <div className="blink_me" /> : ''}
								</div>
							</div>
						))}
				<div className="headerTopTitleLeft">
					{' '}
					<Notifications />
					{/* <Timer timer={timer} startTimer={startTimer} setTimer={setTimer} /> */}
				</div>
			</HeaderTop>
			<HeaderDown>
				{/* <MaxDeposit cronDetails={cronDetails} handleSwitch={handleSwitch} /> */}
				{/* <MaxSavingsWithdrawal
					cronDetails={cronDetails}
					handleSwitch={handleSwitch}
				/> */}
				<MaxOutgoingBUSD cronDetails={cronDetails} />
				{/* <MaxOutgoingXend
					cronDetails={cronDetails}
					handleSwitch={handleSwitch}
				/> */}
				<MaxGasLimit cronDetails={cronDetails} />
				{/* <RecurrentDrawer
					cronDetails={cronDetails}
					handleSwitch={handleSwitch}
				/> */}
				{/* <PlanWithdrawInterval
					cronDetails={cronDetails}
					handleSwitch={handleSwitch}
				/> */}
				<OutgoingWithdrawInterval cronDetails={cronDetails} />
			</HeaderDown>
		</Container>
	);
};

export default HeaderSection;
