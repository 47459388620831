import { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { defaultImageOne } from '../../methods/utils/general-utils';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const Style = styled.div`
	& img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-right: 7px;
	}
`;

const FeeCollectorUser = () => {
	const dispatch = useAppDispatch();

	const { globalFeeCollectorUserId } = useAppSelector(
		(store) => store.internalSettings
	);

	const [visible, setVisible] = useState(false);
	const [taxCollectorId, setTaxCollector] = useState('');

	const updateTaxCollector = () => {
		dispatch(
			updateInternalSettings({ globalFeeCollectorUserId: taxCollectorId })
		);
	};

	return (
		<Style>
			{globalFeeCollectorUserId.email && (
				<div>
					<img alt="" src={defaultImageOne} />
					<Link to={`/user-profile/${globalFeeCollectorUserId._id}`}>
						<strong>
							@
							{globalFeeCollectorUserId?.username ||
								globalFeeCollectorUserId?.email}
						</strong>
					</Link>
				</div>
			)}
			<br />
			<div>
				<Button onClick={() => setVisible(true)} size="small">
					Update Tax Collector
				</Button>

				<Modal
					open={visible}
					okText="Submit Update"
					onOk={() => updateTaxCollector()}
					title="Update Tax Collector"
					onCancel={() => setVisible(false)}
				>
					<p>Enter ObjectId of tax collector user</p>
					<Input
						value={taxCollectorId}
						onChange={(e) => setTaxCollector(e.target.value)}
					/>
				</Modal>
			</div>
		</Style>
	);
};

export default FeeCollectorUser;
