import { Button, Input, Modal, Space } from 'antd';
import { useState } from 'react';
import login from '../methods/auth';

interface INewDeviceOtp {
	email: string;
	password: string;
	show: boolean;
	open2FAModal: Function;
	turnOff: () => void;
}
const NewDeviceOtp = ({
	email,
	password,
	show,
	turnOff,
	open2FAModal,
}: INewDeviceOtp) => {
	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState('');

	const handleNewDeviceAuth = async () => {
		const data = { email, password, otp };
		await login({ data, setLoading, open2FAModal });
		turnOff();
	};

	return (
		<Modal
			footer={null}
			width={320}
			visible={show}
			onCancel={() => turnOff()}
			title="New Device Authentication"
		>
			<p>
				We noticed you are signing in for the first time with the device.
				(Device name).
			</p>
			<p>
				An OTP has been sent to your email, or you can use your Authenticator
				app code.
			</p>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleNewDeviceAuth();
				}}
			>
				<Space direction="vertical" style={{ width: '100%' }} size={15}>
					<Input
						value={otp}
						autoFocus
						onChange={(e) => setOtp(e.target.value)}
						placeholder="Input OTP or Authenticator Code"
					/>
					<Button
						block
						htmlType="submit"
						type="primary"
						loading={loading}
						role="button"
					>
						Submit
					</Button>
				</Space>
			</form>
		</Modal>
	);
};

export default NewDeviceOtp;
