import { Card, Divider, Pagination } from 'antd';
// import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveLoanRequestThatAreDue } from '../../methods/redux/reducers/loan';
import remakeQueryString from '../../methods/utils/remake-query-string';
import LoanItem from './LoanItem';

const DueLoanRepayments = () => {
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	const { duePayments } = useAppSelector((store) => store.loan);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveLoanRequestThatAreDue(queryData));
		}

		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
				sort: 'desc',
			});
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	return (
		<Card>
			<h1>Due Loan Payments</h1>
			<p>Manage loan requests that are due or about to be due.</p>

			<Divider />

			<p>
				Total:{' '}
				<strong>
					<NativeNumber value={duePayments.paginator.itemCount} />
				</strong>{' '}
				items
			</p>

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={duePayments?.paginator?.currentPage || 0}
				total={duePayments?.paginator?.itemCount || 0}
				pageSize={duePayments?.paginator?.perPage || 0}
			/>

			<br />

			{duePayments.itemsList.map((record: any) => (
				<LoanItem loanItem={record} key={record._id} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={duePayments?.paginator?.currentPage || 0}
				total={duePayments?.paginator?.itemCount || 0}
				pageSize={duePayments?.paginator?.perPage || 0}
			/>
		</Card>
	);
};

export default DueLoanRepayments;
