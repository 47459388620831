import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const getWithdrawalRequests = createAsyncThunk(
	'withdrawal-requests/getWithdrawalRequests',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/wallet/withdrawal-requests', {
			params: { ...params, sort: 'desc' },
		});
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const getWithdrawalRequestCount = createAsyncThunk(
	'withdrawal-requests/getWithdrawalRequestCount',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/wallet/withdrawal-requests-count', {
			params,
		});
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

const withdrawaRequestRx = createSlice({
	name: 'withdrawal-requests',
	initialState: {
		itemsList: [],
		paginator: {
			itemCount: 0,
			currentPage: 1,
			perPage: 10,
		},
		withdrawalRequestCount: {
			cancelled: 0,
			deleted: 0,
			success: 0,
			review: 0,
			retry: 0,
			failed: 0,
			pending: 0,
			processing: 0,
			hold: 0,
		},
	},
	reducers: {
		updateWithdrawalStatus: (state, action) => {
			const store = state;
			const { requestId, status } = action.payload;
			const updatedList: any = store.itemsList.map((request: any) => {
				if (request._id === requestId) {
					return { ...request, status };
				}
				return request;
			});
			store.itemsList = updatedList;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(getWithdrawalRequests.fulfilled, (store, action) => {
				let state = store;
				state = { ...state, ...action.payload };
				return state;
			})
			.addCase(getWithdrawalRequestCount.fulfilled, (store, action) => {
				const state = store;
				state.withdrawalRequestCount = action.payload;
			}),
});

export const { updateWithdrawalStatus } = withdrawaRequestRx.actions;

export default withdrawaRequestRx.reducer;
