import { Card, Divider } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Style = styled(Card)`
	& .topics {
		& > a {
			color: #333;
			display: inline-block;
			border: 1px soild #000;
			padding: 20px;

			&:hover {
				background: #f8f8f8;
			}

			& > .title {
				font-size: 21px;
				font-weight: 600;
				display: block;
			}
		}
	}
`;

const AccountantReport = () => (
	<Style>
		<h1>Accountants Report</h1>
		<Divider />

		<div className="topics">
			<Link to="/reports/accountant/savings-deposit">
				<span className="title">Savings Deposit</span>
				<span>View reports of savings deposits</span>
			</Link>
			<Link to="/reports/accountant/savings-withdrawal">
				<span className="title">Savings Withdrawals</span>
				<span>View reports of savings withdrawals</span>
			</Link>
			<Link to="/reports/accountant/early-termination">
				<span className="title">Early Termination of Fixed Savings</span>
				<span>View reports of fixed savings termination</span>
			</Link>
			<Link to="/reports/accountant/withdrawal-via-crypto">
				<span className="title">External Withdrawals</span>
				<span>View reports for withdrawals to the blockchain</span>
			</Link>
			<Link to="/reports/accountant/swap">
				<span className="title">Swap</span>
				<span>View reports for swap transactions</span>
			</Link>
			<Link to="/reports/accountant/staking">
				<span className="title">Staking</span>
				<span>View reports for token staking</span>
			</Link>
			<Link to="/reports/accountant/loan">
				<span className="title">Loan</span>
				<span>View reports for loan</span>
			</Link>
		</div>
	</Style>
);

export default AccountantReport;
