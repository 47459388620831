import { Empty, Image, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import emailBaseUrl from '../../methods/emailBaseUrl';
import { ImageSection } from '../../users/users-kyc/ImageComponent';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';
import EditAutoEmail from './EditAutoEmail';
import SendTestAutoEmail from './SendTestAutoEmail';

const MainPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
const LastSection = styled.div`
	display: flex;
	gap: 1em;
	min-width: 150px;
`;
const AutoEmails = () => {
	const [refresh, setRefresh] = useState('');
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([] as any);

	const getData = async () => {
		setLoading(true);
		let res: any;
		try {
			res = await emailBaseUrl.get(`/v1/automatedemails`);
			if (res.status === 200) {
				setData(res?.data?.data);
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error?.response?.data?.data?.message);
		}
	};
	useEffect(() => {
		getData();
	}, [refresh]);

	// 	content: '<p style="text-align: left;"><span style="font-weight: 400;">Talk to us.&nbsp;</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">How has been your experience saving on the Xend Finance app? Has it been good, bad, or somewhere in the middle?</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">We are constantly looking for ways to give you a seamless experience as you use the Xend Finance app.&nbsp;</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">So, if you have questions, complaints, or suggestions, reply to this email and we will be glad to attend to them all.</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">And if your experience has been splendid, please share it with us. Your success stories mean the world to us. Were you able to help you meet your savings goals?</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">It helps us know what we are doing right and how we can serve you better.&nbsp;</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">If you have not started saving yet, let us know why so we know how we can help you get started on achieving your savings goal.&nbsp;</span></p>\n    <p style="text-align: left;"><span style="font-weight: 400;">We are waiting to hear from you. </span></p>';
	// 	createdAt: '2022-06-27T16:29:10.690Z';
	// 	id: '62b9dad62b53a778f311d7f2';
	// 	updatedAt: '2022-06-27T16:29:10.690Z';
	// 	_id: '62b9dad62b53a778f311d7f2';
	const columns = [
		{
			title: 'Subject',
			key: 'subject',
			render: (text: any) => <strong>{text?.subject}</strong>,
		},
		{
			title: 'Preview Message',
			key: 'previewMessage',
			render: (text: any) => <strong>{text?.previewMessage}</strong>,
		},
		{
			title: 'Banner',
			key: 'bannerUrl',
			render: (text: any) => (
				<ImageSection>
					{text?.bannerUrl ? (
						<Image
							width="100%"
							height="100%"
							src={text?.bannerUrl}
							alt="Unable to load Image"
						/>
					) : (
						'NO IMAGE'
					)}
				</ImageSection>
			),
		},
		{
			title: 'Status',
			key: 'status',
			render: (text: any) => <strong>{text?.status}</strong>,
		},
		{
			title: 'Total Sent',
			key: 'totalSent',
			render: (text: any) => <strong>{text?.totalSent}</strong>,
		},
		{
			title: 'Limit',
			key: 'limit',
			render: (text: any) => <strong>{text?.limit}</strong>,
		},
		{
			title: 'Last Update',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Last Updated By',
			key: 'creatorEmail',
			render: (text: any) => <strong>{text?.creatorEmail}</strong>,
		},

		{
			title: '',
			key: '345334',
			render: (text: any) => (
				<LastSection style={{ width: '100px' }}>
					<SendTestAutoEmail data={text} />
					<EditAutoEmail autoEmail={text} setRefresh={setRefresh} />{' '}
				</LastSection>
			),
		},
	];
	return (
		<MainPage>
			<MyTableCard>
				<TableHeaderSection
					tableName="Automated Emails"
					currentPage={1}
					totalRecords={data?.maxLength || 0}
				/>

				<MyTable
					locale={{
						emptyText: (
							<Empty
								image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
								description="No data to display"
							/>
						),
					}}
					loading={loading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>
				<br />
			</MyTableCard>
		</MainPage>
	);
};

export default AutoEmails;
