import { Divider, Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

const SettingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const SettingsPage = () => {
	const items = [
		{
			title: 'All Internal Process Settings',
			desc: 'View & edit all internal process settings here. Dont make any edits if you do not know what it is about.',
			link: '/settings/internal-processes',
			icon: 'rectangle',
		},
		{
			title: 'General',
			desc: 'Different settings for the smooth running of the app. Set stable currency apy, manage custodial wallets etc.',
			link: '/settings/general',
			icon: 'rectangle',
		},
		{
			title: 'Supported Currency',
			desc: 'Currency settings including supported blockchain networks and contract addresses of the supported cryptocurrency tokens.',
			link: '/settings/currency',
			icon: 'paid',
		},
		{
			title: 'Savings',
			desc: 'Currency settings including supported blockchain networks and contract addresses of the supported cryptocurrency tokens.',
			link: '/settings/savings',
			icon: 'savings',
		},
		{
			title: 'Token Staking',
			desc: 'All settings for token staking facilitation',
			link: '/settings/staking',
			icon: 'token',
		},
		{
			title: 'Transaction Fees',
			desc: 'All settings for transaction fees on the system',
			link: '/settings/transaction-fees',
			icon: 'point_of_sale',
		},
		{
			title: 'Referral Reward',
			desc: 'Set referral user, amounts to reward users when they claim registration and referral bonus and other related settings.',
			link: '/settings/referral',
			icon: 'stars',
		},
		{
			title: 'Currency Swap',
			desc: 'Settings for swapping between two currencies on the platform, from swap request to completion of the process.',
			link: '/settings/swap',
			icon: 'swap_horizontal_circle',
		},
		{
			title: 'Admin Roles',
			desc: 'Admin roles management.',
			link: '/settings/roles',
			icon: 'badge',
		},
		{
			title: 'Loan',
			desc: 'Manage loan requests from users.',
			link: '/settings/loan',
			icon: 'engineering',
		},
	];

	return (
		<section>
			<PageHeader>Settings</PageHeader>

			<Input.Search placeholder="Search Settings" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SettingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SettingsItemStyle>
				))}
		</section>
	);
};

export default SettingsPage;
