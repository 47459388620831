import { Divider, Pagination, Card } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getFlexibleSavings } from '../../methods/redux/reducers/flexible-savings';
import SearchFilter from '../../component/Filter';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import FlexibleSavingsItem from './FlexibleSavingsItem';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const FlexibleSavings = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { currencies } = useAppSelector((store) => store.currencies);
	const { user } = useAppSelector((store) => store.userProfile);
	const { flexible, paginator } = useAppSelector(
		(store) => store.flexibleSavings
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getFlexibleSavings({ ...queryData, userId }));
			} else {
				dispatch(getFlexibleSavings(queryData));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => {
		if (item.allowSavings) {
			return {
				name: `${item.symbol} *`,
				value: item._id,
			};
		}
		return {
			name: item.symbol,
			value: item._id,
		};
	});

	return (
		<div>
			<Card>
				<h1>
					Flexible Savings Topup Items{' '}
					{userId && (
						<span>
							for{' '}
							<Link
								title="View Profile"
								to={`/user-profile/${user.profile?._id}`}
							>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>

				<Divider />

				<SearchFilter
					title="Find Flexible Savings Topup"
					searchFilters={[
						{
							label: 'Migrate Status',
							name: 'migrateStatus',
							htmlElement: 'select',
							selectOptions: [
								{ name: 'Pending', value: 'pending' },
								{ name: 'Migrated', value: 'migrated' },
								{ name: 'Paused', value: 'paused' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<p>
					Total of <strong>{paginator.itemCount} items</strong> found
				</p>

				<Divider />

				<div>
					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={paginator.itemCount}
						current={paginator.currentPage}
						pageSize={paginator.perPage}
					/>
				</div>

				<br />

				{flexible.map((item: any) => (
					<FlexibleSavingsItem key={item._id} item={item} />
				))}

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={paginator.itemCount}
					current={paginator.currentPage}
					pageSize={paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>
		</div>
	);
};

export default FlexibleSavings;
