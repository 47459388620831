import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { deleteGameFile } from '../../../methods/redux/reducers/games';

interface IGameFiles {
	gameFile: any;
	gameId: any;
	fileType: 'image' | 'video';
}

const Style = styled.div`
	display: inline-block;
	margin-right: 20px;
`;

const GameFiles = ({ gameFile, fileType, gameId }: IGameFiles) => {
	const dispatch = useAppDispatch();

	const removeImageFile = () => {
		dispatch(deleteGameFile({ fileId: gameFile._id, gameId }));
	};
	return (
		<Style>
			<div>
				{fileType === 'image' && (
					<img alt="" src={gameFile.imageUrl} width={200} />
				)}

				{fileType === 'video' && (
					<YouTube videoId={gameFile.videoId} opts={{ width: '200' }} />
				)}
			</div>
			<Popconfirm
				title="Are you sure you want to delete this?"
				onConfirm={removeImageFile}
			>
				<Button>
					<DeleteOutlined />
				</Button>
			</Popconfirm>
		</Style>
	);
};

export default GameFiles;
