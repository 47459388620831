import { Button, Card } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';

const FilterSection = styled.div``;
const CustomCard = styled(Card)`
	padding: 10px;
	border-radius: 10px;
`;
const MyButton = styled(Button)`
	color: #383838;
	border-radius: 10px;
	margin: 5px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const StakingFilter = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(getCurrencies({}));
	}, []);
	const navigate = useNavigate();

	const location = useLocation();
	const selectedFilter = location.pathname.split(/[/,?,&]/)[2];

	const filterEnum = [
		{ name: 'All Staking', value: 'all-staking' },
		{ name: 'PaidOut Staking', value: 'staking-paidout' },
	];
	return (
		<FilterSection>
			<CustomCard>
				<strong>Filters: </strong>
				{filterEnum.map((n) => (
					<MyButton
						className={selectedFilter === n.value ? 'selected' : ''}
						key={n.value}
						onClick={() => navigate(`/reports/${n.value}`)}
					>
						{n.name}
					</MyButton>
				))}
			</CustomCard>
		</FilterSection>
	);
};

export default StakingFilter;
