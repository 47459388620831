import styled from 'styled-components';
import Numeral from '../../../component/Numeral';
import { useAppSelector } from '../../../methods/redux/hooks';
import { defaultImageOne } from '../../../methods/utils/general-utils';

const Style = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	column-gap: 20px;
	row-gap: 20px;
	margin-top: 20px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(3, 1fr);
	}

	& img {
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}

	& > * {
		padding: 20px;
		border: 1px solid #ddd;

		& .count {
			font-weight: 600;
			font-size: 18px;
		}
	}
`;

const UnmigratedCount = () => {
	const { unmigratedFees } = useAppSelector((store) => store.reports);

	return (
		<Style>
			{unmigratedFees.aggregation.map((item: any) => (
				<div key={item._id}>
					<img alt="" src={item.currencyId?.iconUrl || defaultImageOne} />
					<p className="count">
						<Numeral value={item.count} /> items /{' '}
						<Numeral value={item.totalAmount || 0} /> {item.currencyId?.symbol}
					</p>
					<p>{item.currencyId?.symbol} Unmigrated Fees</p>
				</div>
			))}
		</Style>
	);
};

export default UnmigratedCount;
