import { useState } from 'react';
import { Modal, Space, message, Tag, Upload, Button } from 'antd';
import { useParams } from 'react-router-dom';
import baseuri from '../../methods/baseuri';
import { ImageMain } from './ManualKYCID';

const ManualKYCSelfie = () => {
	const { id } = useParams();
	const [loading, setloading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({
		userId: id,
		selfieUrl: '',
	});

	const handleCurrencyUpdate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(
				'admin/custodial-kyc/user/kyc/selfie',
				{
					...data,
					selfieUrl: data?.selfieUrl?.replace('data:image/jpeg;base64,', ''),
				}
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('kyc updated');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	function getBase64(img: any, callback: Function) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}
	const handleImageUpload = async (info: any) => {
		getBase64(info.file.originFileObj, (imageUrl: any) =>
			setData({
				...data,
				selfieUrl: imageUrl,
			})
		);
	};
	const beforeUpload = (file: any) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
			setData({
				...data,
				selfieUrl: '',
			});
		}
		return isJpgOrPng && isLt2M;
	};

	return (
		<div>
			<Tag
				style={{ cursor: 'pointer' }}
				color="blue"
				onClick={() => setVisible(true)}
			>
				Upload Selfie Image
			</Tag>
			<Modal
				okText="Update"
				onOk={handleCurrencyUpdate}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Manually Update User KYC Selfie"
				confirmLoading={loading}
			>
				<Space direction="vertical" size={10} style={{ width: '100%' }}>
					<div>
						Upload Selfie Image :
						<Upload
							name="image"
							listType="picture-card"
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleImageUpload}
						>
							{data?.selfieUrl ? (
								<ImageMain
									src={data?.selfieUrl}
									alt="avatar"
									className="actualImage"
									style={{ width: '100%' }}
								/>
							) : (
								<Button>Upload</Button>
							)}
						</Upload>
					</div>
				</Space>
			</Modal>
		</div>
	);
};

export default ManualKYCSelfie;
