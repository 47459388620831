import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import UpdateKYC from './UpdateKYC';

const LevelSection = styled.div`
	display: inline-flex;
	flex-direction: column;
	border: 2px solid black;
	border-radius: 10px;
	min-width: 340px;
	padding: 5px;
	margin: 10px;
	gap: 1em;
	/* background-color: #ffffff; */

	&:hover {
		background-color: #fffafa;
	}
	&.selected {
		background-color: #0681fb;
		color: white;
	}
	& .levelName {
		font-size: 20px;
		margin-bottom: 20px;
		cursor: pointer;
	}

	& .statusSection {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
	}

	& .statusItems {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		align-items: center;
		padding: 5px;
	}

	& .statusMiddle {
		border-left: 1px solid #d1cfcf;
		border-right: 1px solid #d1cfcf;
	}
	& .statusMiddle2 {
		border-right: 1px solid #d1cfcf;
	}
`;
const LevelButton = styled.button`
	all: unset;
	cursor: pointer;
`;

interface ILevelDetails {
	levelList: any;
	data: any;
	setSelectedLevel: Function;
	selectedLevel: any;
}
const LevelDetails: FC<ILevelDetails> = ({
	levelList,
	data,
	selectedLevel,
	setSelectedLevel,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const kycLevel = searchParams.get('kycLevel');

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			kycLevel: `${searchParams.get('kycLevel') || ''}`,
			kycStatus: `${searchParams.get('kycStatus') || 'submitted'}`,
			pageSize: '50',
			page: `${searchParams.get('page') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};

	useEffect(() => {
		setSelectedLevel(levelList.find((i: any) => i._id === kycLevel));
	}, [kycLevel, levelList]);
	return (
		<div>
			<LevelSection
				className={
					selectedLevel?.levelName === levelList[1]?.levelName ? 'selected' : ''
				}
			>
				<LevelButton
					onClick={() => {
						setSelectedLevel(levelList[1]);
						const n = getQueryParams();
						setSearchParams({ ...n, kycLevel: levelList[1]?._id || '' });
						// setParams({ ...params, kycLevel: levelList[1]?._id });
					}}
				>
					<div className="levelName">
						{levelList[1]?.levelName || 'Level 2'}
					</div>
					<div className="statusSection">
						<span className="statusItems">
							<strong>{data?.kycCount?.levelTwosubmittedKyc || '0'}</strong>
							<p>Submitted</p>
						</span>
						<span className="statusItems statusMiddle">
							<strong>{data?.kycCount?.levelTwoapprovedKyc || '0'}</strong>
							<p>Accepted</p>
						</span>
						<span className="statusItems statusMiddle2">
							<strong>{data?.kycCount?.levelTworejectedKyc || '0'}</strong>
							<p>Rejected</p>
						</span>
						<span className="statusItems">
							<strong>{data?.kycCount?.levelTwoescalatedKyc || '0'}</strong>
							<p>Escalated</p>
						</span>
					</div>
				</LevelButton>
				<UpdateKYC details={levelList[1]} />
			</LevelSection>
			<LevelSection
				className={
					selectedLevel?.levelName === levelList[2]?.levelName ? 'selected' : ''
				}
			>
				<LevelButton
					onClick={() => {
						setSelectedLevel(levelList[2]);
						const n = getQueryParams();
						setSearchParams({ ...n, kycLevel: levelList[2]?._id || '' });
						// setParams({ ...params, kycLevel: levelList[2]?._id });
					}}
				>
					<div className="levelName">
						{levelList[2]?.levelName || 'Level 3'}
					</div>
					<div className="statusSection">
						<span className="statusItems">
							<strong>{data?.kycCount?.levelThreeSubmittedKyc || '0'}</strong>
							<p>Submitted</p>
						</span>
						<span className="statusItems statusMiddle">
							<strong>{data?.kycCount?.levelThreeApprovedKyc || '0'}</strong>
							<p>Accepted</p>
						</span>
						<span className="statusItems statusMiddle2">
							<strong>{data?.kycCount?.levelThreeRejectedKyc || '0'}</strong>
							<p>Rejected</p>
						</span>
						<span className="statusItems">
							<strong>{data?.kycCount?.levelThreeEscalatedKyc || '0'}</strong>
							<p>Escalated</p>
						</span>
					</div>
				</LevelButton>
				<UpdateKYC details={levelList[2]} />
			</LevelSection>
		</div>
	);
};

export default LevelDetails;
