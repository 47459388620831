import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Space } from 'antd';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';
import ManualKYCLevel1 from './ManualKYCLevel1';
import ManualKYCSelfie from './ManualKYCSelfie';
import ManualKYCID from './ManualKYCID';

const MainSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
`;
const TitleSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
`;
const UserInfoSettings = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const { user } = useAppSelector((store) => store?.userProfile);
	useEffect(() => {
		dispatch(getUserProfileById(id));
	}, []);
	return (
		<MainSection>
			<strong>
				Info Settings for{' '}
				{user?.profile?.fullName ||
					user?.profile?.username ||
					user?.profile?.email}
			</strong>
			<MyCard>
				<TitleSection>
					<h2>Manually Update User KYC</h2>
					<Space direction="horizontal">
						<ManualKYCLevel1 user={user} />
						<ManualKYCSelfie />
						<ManualKYCID />
					</Space>
				</TitleSection>
				<p>
					Here, admin upload user kyc details collected from the google form{' '}
				</p>
			</MyCard>
		</MainSection>
	);
};

export default UserInfoSettings;
