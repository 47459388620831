import { message, Modal, Tag } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
	cursor: pointer;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IDistribute {
	bulkSavingsId: string;
}
const Distribute: FC<IDistribute> = ({ bulkSavingsId }) => {
	const distribute = async () => {
		try {
			const res: any = await baseuri.post(
				`admin/bulk-savings/stable-currency/distribute`,
				{
					bulkSavingsId,
				}
			);

			if (res.status === 200) {
				message.success(res?.data?.message);
			} else {
				message.info(res?.data?.message);
			}
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Distribute this Bulk Savings',
			onOk() {
				distribute();
			},
		});
	};
	return (
		<DeleteSection>
			<Tag color="blue" onClick={confirm}>
				Manually Distribute
			</Tag>
		</DeleteSection>
	);
};

export default Distribute;
