import { Avatar, Badge, Card } from 'antd';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { badgeColor, defaultImageOne } from '../../methods/utils/general-utils';
import TrustScoreSection from './TrustScoreSection';
import { useAppSelector } from '../../methods/redux/hooks';
import ViewPaymentMethods from './PaymentMethods';
import NextOfKin from './NextOfKin';
import BlacklistedUserMarker from './BlacklistedMarker';

const InfoStyles = styled(Card)`
	display: flex;
	flex: 1;
	position: relative;
	width: 100%;

	.avatarSection {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 3rem;
	}
	.infoList {
		display: flex;
		flex-direction: column;
		width: clamp(100px, 75vw, 350px) !important;
		margin-top: 20px;
		gap: 1em;
	}
	.hideMore {
		display: none;
	}
	.settings-icon {
		display: flex;
		position: absolute;
		z-index: 1;
		cursor: pointer;
	}

	.profile-badges {
		display: flex;
		justify-content: center;

		& > * {
			position: relative;
			display: inline-block;
			padding: 5px;
			font-size: 12px;
			border: 1px solid #ddd;
			margin-right: 5px;
			font-weight: 600;

			& .icon {
				position: absolute;
				font-size: 12px;
				font-weight: bold;
				color: white;
				top: -5px;
				right: -5px;
				background: var(--green-color);
				height: 14px;
				width: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
			}
		}
	}
`;

const MoreSection = styled.div`
	display: flex;
	position: relative;
	align-items: flex-end;
	justify-content: flex-end;
	width: clamp(100px, 75vw, 350px) !important;

	@media (min-width: 768px) {
		display: none;
	}
`;

export const MoreButton = styled.button`
	all: unset;
	cursor: pointer;
	color: blue;
`;

const Ghost = styled.div`
	color: red;
	font-size: bold;
`;

const ProfileInfo = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const [show, setShow] = useState(true);

	const { user } = useAppSelector((store) => store.userProfile);

	const details = user;

	return (
		<InfoStyles>
			<Link
				className="settings-icon"
				title="GoTo User Settings"
				to={`/user-settings/${id}`}
			>
				<i className="material-icons">settings</i>
			</Link>
			<div className="avatarSection">
				{details?.profile?.ghostUserMerchantId ? (
					<Avatar src={defaultImageOne} size={60} />
				) : (
					<Badge
						dot
						color={badgeColor(
							details?.profile?.hasCompletedProfile,
							details?.profile?.deactivated
						)}
					>
						{details?.profile?.profileUrl ? (
							<Avatar
								src={details?.profile?.profileUrl || defaultImageOne}
								size={60}
							/>
						) : (
							<Avatar icon={<UserOutlined />} size={60} />
						)}
					</Badge>
				)}
				{details?.profile?.ghostUserMerchantId ? (
					<Ghost> This is a GHOST User</Ghost>
				) : (
					<>
						<strong>{details?.profile?.fullName || '--'}</strong>
						<strong>{details?.profile?.email || '--'}</strong>
						<p>
							Last Login:{' '}
							{details?.loading ? (
								'loading...'
							) : (
								<span>
									{details?.profile?.lastLogin
										? moment(details?.profile?.lastLogin).format('lll')
										: '--'}
								</span>
							)}
						</p>
						{details?.profile?.lastLogin && (
							<MoreButton
								onClick={() =>
									navigate(
										`/user/last-login/${id}?startDate=2021-10-01&endDate=2022-06-23&perPage=10&pageId=1&sort=desc`
									)
								}
							>
								View login history
							</MoreButton>
						)}
					</>
				)}
			</div>
			<div className="profile-badges">
				{details?.profile?.appleId && (
					<div>
						<span>Apple Login</span>
						<span className="material-symbols-outlined icon">check_small</span>
					</div>
				)}
				{details?.profile?.googleId && (
					<div>
						<span>Google Login</span>
						<span className="material-symbols-outlined icon">check_small</span>
					</div>
				)}
				{details?.profile?.enabledTwoFactor && (
					<div>
						<span>2FA Active</span>
						<span className="material-symbols-outlined icon">check_small</span>
					</div>
				)}
			</div>

			<MoreSection>
				{window.innerWidth < 800 && (
					<p>
						{show ? (
							<MoreButton onClick={() => setShow(false)}>
								<i className="material-icons">expand_less</i>
							</MoreButton>
						) : (
							<MoreButton onClick={() => setShow(true)}>
								<i className="material-icons">expand_more</i>
							</MoreButton>
						)}
					</p>
				)}
			</MoreSection>

			<BlacklistedUserMarker userId={id} />

			<div className={`infoList ${show ? '' : 'hideMore'}`}>
				<div className="infoItem">
					<p>Full Name:</p>{' '}
					<strong>
						{details?.loading
							? 'loading...'
							: details?.profile?.fullName || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>Username:</p>
					<strong>
						{details?.loading
							? 'loading...'
							: details?.profile?.username || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>Email:</p>
					<strong>
						{details?.loading ? 'loading...' : details?.profile?.email || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>Phone Number:</p>
					<strong>
						{details?.loading
							? 'loading...'
							: details?.profile?.phoneNo || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>Country:</p>
					<strong>
						{details?.loading
							? 'loading...'
							: details?.profile?.countryCode || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>Registration Date:</p>
					<strong>
						{details?.loading
							? 'loading...'
							: moment(details?.profile?.createdAt).format('lll') || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>Last Update:</p>
					<strong>
						{details?.loading
							? 'loading...'
							: moment(details?.profile?.updatedAt).format('lll') || '--'}
					</strong>
				</div>
				<div className="infoItem">
					<p>KYC level 2 Status:</p>
					<strong>
						<span>
							{details?.profile?.kycLevelTwoStatus === 'submitted' ||
							details?.profile?.kycLevelTwoStatus === 'approved' ||
							details?.profile?.kycLevelTwoStatus === 'rejected' ? (
								<Link to={`/manage-kyc/${details?.profile?._id}`}>
									{' '}
									KYC Data(
									{details?.profile?.kycLevelTwoStatus.toUpperCase()})
								</Link>
							) : (
								<span>{details?.profile?.kycLevelTwoStatus.toUpperCase()}</span>
							)}
						</span>
					</strong>
				</div>
				<div className="infoItem">
					KYC level 3 Status:{' '}
					<strong>
						{details?.loading
							? 'loading...'
							: details?.profile?.kycLevelThreeStatus.toUpperCase() || '--'}
					</strong>
				</div>
				<div className="infoItem">
					Admin Last Comment:{' '}
					<p>
						{details?.loading
							? 'loading...'
							: details?.profile?.adminLastComment || '--'}
					</p>
				</div>
				<div>
					<ViewPaymentMethods bankDetails={user.paymentMethods} />
					<NextOfKin details={user.nextOfKin} />
				</div>
				<TrustScoreSection
					trustScore={details?.profile?.trustScore || 0}
					id={details?.profile?._id}
				/>
			</div>
		</InfoStyles>
	);
};

export default ProfileInfo;
