import { Card, Divider, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import { gateWays } from '../../methods/utils/general-utils';
import { Country } from '../../methods/utils/request-helpers';
import Cancel2fa from '../../user-settings/user-general-settings/Cancel2fa';
import Reset2fa from '../../user-settings/user-general-settings/Reset2fa';
import ImageComponent from '../../users/users-kyc/ImageComponent';

const MyCard = styled(Card)`
	border-radius: 10px;
`;

const ImageSection = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
`;

const Single2faRequest = () => {
	const { id } = useParams<{ id: string }>();
	const [refresh] = useState(0);
	const [data, setData] = useState({} as any);
	const [selectedGateway, setSelectedGateway] = useState(
		'https://cf-ipfs.com/ipfs/'
	);
	console.info(selectedGateway);

	const getSingle2fa = async () => {
		setData({ loading: true });
		try {
			const res: any = await baseuri.get(
				'admin/users/2fa/single/reset-request',
				{
					params: {
						requestId: id,
					},
				}
			);

			if (res.status === 200) {
				setData({
					...res.data.data,
					loading: false,
				});
			} else {
				setData({ loading: false });
			}
		} catch (error) {
			setData({ loading: false });
		}
	};

	useEffect(() => {
		getSingle2fa();
	}, [refresh, id]);

	return (
		<div>
			<div>
				Select gateway :{' '}
				<Select
					// mode="multiple"
					placeholder="Select GateWay"
					// value={newsletter?.maillist}
					onChange={(e: any) => setSelectedGateway(e)}
					defaultValue="https://cf-ipfs.com/ipfs/"
					// style={{ width: '100%' }}
				>
					{gateWays?.map((gateway: any) => (
						<Select.Option key={gateway.name} value={gateway.value}>
							{gateway.name}
						</Select.Option>
					))}
				</Select>
			</div>
			<br />
			<MyCard>
				<strong>KYC DETAILS</strong>
				<br />
				<br />
				<ImageSection>
					{data?.kycId?.idcardIpfsHash && (
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={data?.kycId?.idcardEncryptKey}
								url={`${data?.kycId?.idcardUrl}`}
								createdAt={data?.kycId?.createdAt}
							/>
						</p>
					)}
					{data?.kycId?.idcardIpfsHash && (
						<p>
							<strong>Photo Image</strong>
							<ImageComponent
								secret={data?.kycId?.selfieEncryptKey}
								url={`${data?.kycId?.selfieUrl}`}
								createdAt={data?.kycId?.createdAt}
							/>
						</p>
					)}
				</ImageSection>
				<Link title="View Profile" to={`/user-profile/${data?.userId?._id}`}>
					View Profile
				</Link>
				<div>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${data?.kycId?.lastName}  ${data?.kycId?.firstName}  ${data?.kycId?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{data?.kycId?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{data?.kycId?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Device Id: <strong>{data?.kycId?.deviceId || 'N/A'}</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>
							{Country(data?.kycId?.countryCode.toUpperCase()) || 'N/A'}
						</strong>
					</p>
				</div>

				<Divider />
				<strong>Reset Details Submitted</strong>
				<br />
				<br />
				<ImageSection>
					{data?.kycIpfsHash && (
						<p>
							<strong>Image Uploaded</strong>
							<ImageComponent
								secret={data?.kycEncryptKey}
								url={`${data?.kycUrl}`}
								createdAt={data?.createdAt}
							/>
						</p>
					)}
				</ImageSection>
				<div>
					<p>
						FULL NAME: <strong>{`${data?.fullName}` || 'N/A'}</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{data?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{data?.kycId?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(data?.country?.toUpperCase()) || 'N/A'}</strong>
					</p>
					<p>
						Device COUNTRY:{' '}
						<strong>
							{Country(
								data?.requestDeviceDetails?.deviceCountry?.toUpperCase()
							) || 'N/A'}
						</strong>
					</p>
					<p>
						IP COUNTRY:{' '}
						<strong>
							{Country(
								data?.requestDeviceDetails?.cfIpCountry?.toUpperCase()
							) || 'N/A'}
						</strong>
					</p>
					<p>
						IP ADDRESS:{' '}
						<strong>
							{Country(data?.requestDeviceDetails?.clientIP?.toUpperCase()) ||
								'N/A'}
						</strong>
					</p>
					<p>
						Serial Id:{' '}
						<strong>{data?.requestDeviceDetails?.serial || 'N/A'}</strong>
					</p>
				</div>
				{data.status === 'pending' && (
					<>
						<Divider />
						<Reset2fa id={data?.userId?._id} requestId={data?._id} />
						<Cancel2fa id={data?.userId?._id} requestId={data?._id} />
					</>
				)}
			</MyCard>
		</div>
	);
};

export default Single2faRequest;
