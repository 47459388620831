import cryptojs from 'crypto-js';
import moment from 'moment';
import defaultProfileImage from '../../assets/default.png';
import defaultProfileImageOne from '../../assets/defaultone.png';

// eslint-disable-next-line import/prefer-default-export
export const commas = (x: any, decimal?: number) => {
	if (x && decimal) {
		return parseFloat(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			String(x).match(/^-?\d+(?:\.\d{0,2})?/)[0]
		).toLocaleString('en', {
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		});
	}
	if (x) {
		return parseFloat(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			String(x).match(/^-?\d+(?:\.\d{0,4})?/)[0]
		).toLocaleString('en', {
			maximumFractionDigits: 4,
			minimumFractionDigits: 4,
		});
	}
	return '0.00';
};
export const badgeColor = (profileComplete: boolean, deactivated: boolean) => {
	if (profileComplete && !deactivated) {
		return '#87d068';
	}
	return 'red';
};

export const supportCurrencyEnumInfo = (value: number) => {
	switch (value) {
		case 1:
			return { currency: 'busd' };

		case 2:
			return { currency: 'xend' };

		default:
			return { network: null, currency: null };
	}
};
export const shortAmount = (amount: any) => {
	if (!amount) return '0';
	if (amount) {
		return String(amount.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
	}
	return amount;
};

export const shortAddress = (address: string) =>
	`${address.slice(0, 5)}...${address.slice(-5)}`;

export const defaultImage = defaultProfileImage;
export const defaultImageOne = defaultProfileImageOne;
// export const defaultImage =
// 	'https://occ-0-4616-2219.1.nflxso.net/dnm/api/v6/K6hjPJd6cR6FpVELC5Pd6ovHRSk/AAAABXuurmkiCsw7ucJzV1WgJMQz09Wo9RnX5NreritRygVutW0vVPZuUeENaLN_1hcjJrReDGOEVHPEZk9JWXqFK6sCyEPCgCR1j14z.png?r=eea';

export const erc20 = [
	{
		inputs: [
			{
				internalType: 'address',
				name: 'owner',
				type: 'address',
			},
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
];

export const trustScoreColor = (value: any) => {
	const val = Number(value);
	switch (true) {
		case val >= 0 && val <= 20:
			return 'red';
		case val >= 21 && val <= 40:
			return 'orange';
		case val >= 41 && val <= 60:
			return '#fdd017';
		case val >= 61 && val <= 80:
			return '#121341';
		case val >= 81 && val <= 100:
			return 'green';
		default:
			return 'None';
	}
};
export const gateWays = [
	{ name: 'https://cf-ipfs.com/ipfs/', value: 'https://cf-ipfs.com/ipfs/' },
	{ name: 'https://ipfs.io/ipfs/', value: 'https://ipfs.io/ipfs/' },
	{
		name: 'https://cloudflare-ipfs.com/ipfs/',
		value: 'https://cloudflare-ipfs.com/ipfs/',
	},
	{
		name: 'https://gateway.ipfs.io/ipfs/',
		value: 'https://gateway.ipfs.io/ipfs/',
	},
	{
		name: 'https://gateway.pinata.cloud/ipfs/',
		value: 'https://gateway.pinata.cloud/ipfs/',
	},
];
export const setColor = (status: string) => {
	switch (status) {
		case 'done':
			return 'green';
		case 'pending':
			return 'orange';
		case 'failed':
			return 'red';
		case 'processing':
			return 'blue';
		default:
			return 'gray';
	}
};
export const truncateAddress = (argsAddress: string, argsLen?: number) => {
	let address = argsAddress;
	let len = argsLen;
	if (address?.length > 15) {
		len = len || 4;
		address = `${address?.slice(0, len)}...${address?.slice(-len)}`;
	}

	return address;
};

export const referralStatus = (value: any) => {
	switch (Number(value)) {
		case 0:
			return {
				text: 'To be claimed',
				enumValue: Number(value),
				color: 'orange',
			};
		case 1:
			return { text: 'Unverified', enumValue: Number(value), color: 'gray' };
		case 2:
			return { text: 'Completed', enumValue: Number(value), color: 'green' };
		default:
			return { text: '', enumValue: Number(value), color: 'gray' };
	}
};

export const CSVstringToArray = (str: string, delimiter = ',') => {
	const rows = str.replaceAll('\r', '').split(/[\n']/);
	const data: any = [];

	rows.map((row) => {
		const values = row.split(delimiter);
		data.push({ email: values[0], amount: values[1] });
		return row;
	});
	return data;
};

export const isBoolean = (value: any) => {
	if (value === 'true' || value === 'false') {
		return true;
	}
	return false;
};
export function secondsToHms(d: number) {
	// d = Number(d);
	const h = Math.floor(d / 3600);
	const m = Math.floor((d % 3600) / 60);
	const s = Math.floor((d % 3600) % 60);

	const hDisplay = h > 9 ? h : `0${h}`;
	const mDisplay = m > 9 ? m : `0${m}`;
	const sDisplay = s > 9 ? s : `0${s}`;
	return `${hDisplay}:${mDisplay}:${sDisplay}`;
}
export function timeAgo(d: any) {
	const diff = +moment().diff(moment(d), 'seconds');
	if (diff < 60) {
		const v = Math.round(diff);
		return `${v} second${v === 1 ? '' : 's'} ago`;
	}
	if (diff < 60 * 60) {
		const v = Math.round(diff / 60);
		return `${v} minute${v === 1 ? '' : 's'} ago`;
	}
	if (diff < 60 * 60 * 24) {
		const v = Math.round(diff / (60 * 60));
		return `${v} hour${v === 1 ? '' : 's'} ago`;
	}
	if (diff < 60 * 60 * 24 * 30.436875) {
		const v = Math.round(diff / (60 * 60 * 24));
		return `${v} day${v === 1 ? '' : 's'} ago`;
	}
	if (diff < 60 * 60 * 24 * 30.436875 * 12) {
		const v = Math.round(diff / (60 * 60 * 24 * 30.436875));
		return `${v} month${v === 1 ? '' : 's'} ago`;
	}
	const v = Math.round(diff / (60 * 60 * 24 * 30.436875 * 12));
	return `${v} year${v === 1 ? '' : 's'} ago`;
}

export const randomgen = () => {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghipqrstuvwxyz0123456789';
	let gen = '';

	const value =
		Math.floor(Math.random() * (Math.floor(12) - Math.ceil(3))) + Math.ceil(3);

	for (let i = 0; i < value; i++) {
		gen += chars.charAt(Math.floor(Math.random() * chars.length));
	}

	return gen;
};

export function truncString(
	str: string,
	n: number,
	useWordBoundary?: boolean | null
) {
	const tooLong = str.length > n;
	const useWordBoundary1 = useWordBoundary || true;

	// Edge case where someone enters a ridiculously long string.
	let str1 = tooLong ? str.substr(0, n - 1) : str;

	const singular = str1.search(/\s/) === -1;
	if (!singular) {
		str1 =
			useWordBoundary1 && tooLong
				? str1.substr(0, str1.lastIndexOf(' '))
				: str1;
	}

	return tooLong ? `${str1} ...` : str1;
}

export const gameRewardTypes = (data: any) => {
	const listOfKeyValuePair: any[] = [];

	if (!data) {
		return listOfKeyValuePair;
	}
	const keys = Object.keys(data);
	const values = Object.values(data);

	if (keys.length < 1) {
		return listOfKeyValuePair;
	}
	for (let i = 0; i < keys.length; i += 1) {
		listOfKeyValuePair.push({
			name: String(keys[i])
				.replace('_', ' ')
				.replace(/^\w/, (c) => c.toUpperCase()),
			value: values[i],
		});
	}

	return listOfKeyValuePair;
};

export const gameFormTypes = (data: any) => {
	const listOfKeyValuePair: any[] = [];

	if (!data) {
		return listOfKeyValuePair;
	}
	const keys = Object.keys(data);
	const values = Object.values(data);

	if (keys.length < 1) {
		return listOfKeyValuePair;
	}
	for (let i = 0; i < keys.length; i += 1) {
		listOfKeyValuePair.push({
			name: String(keys[i])
				.replace('_', ' ')
				.replace(/^\w/, (c) => c.toUpperCase()),
			value: values[i],
		});
	}

	return listOfKeyValuePair;
};

export const transactionStatementTypes = (data: any) => {
	const listOfKeyValuePair: any[] = [];

	if (!data) {
		return listOfKeyValuePair;
	}
	const keys = Object.keys(data);
	const values = Object.values(data);

	if (keys.length < 1) {
		return listOfKeyValuePair;
	}
	for (let i = 0; i < keys.length; i += 1) {
		listOfKeyValuePair.push({
			name: String(keys[i])
				.replace('_', ' ')
				.replace(/^\w/, (c) => c.toUpperCase()),
			value: values[i],
		});
	}

	return listOfKeyValuePair;
};

export const decryptString = (str: string, key: string) =>
	cryptojs.AES.decrypt(str, key).toString(cryptojs.enc.Utf8);
