import { Button, Card } from 'antd';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { deleteContractAddress } from '../../methods/redux/reducers/currency';

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 10px;

	& .address {
		font-style: italic;
		word-wrap: break-word;
	}

	& .details > * {
		display: inline-block;
		margin-right: 20px;
	}
`;

interface IContractAddressItem {
	contract: any;
}

const ContractAddressItem = ({ contract }: IContractAddressItem) => {
	const dispatch = useAppDispatch();

	const deleteAddress = () => {
		dispatch(deleteContractAddress({ contractAddressId: contract._id }));
	};

	return (
		<Style>
			<p className="address">
				<a
					target="_blank"
					href={`https://${contract.blockchainNetworkId?.explorerUrl}/address/${contract.contractAddress}`}
					rel="noreferrer"
				>
					{contract.contractAddress}
				</a>
			</p>
			<div className="details">
				<div>
					<p>
						<strong>{contract.currencyId?.symbol}</strong>
					</p>
				</div>
				<div>
					<p>
						<strong>{contract.blockchainNetworkId?.abbreviation}</strong>
					</p>
				</div>
				<div>
					<Button type="text" onClick={deleteAddress} size="small" danger>
						DELETE
					</Button>
				</div>
			</div>
		</Style>
	);
};

const ContractAddresses = () => {
	const { contracts } = useAppSelector((store) => store.currencies);

	return (
		<Card>
			<h2>Contract Addresses</h2>

			{contracts.map((contract: any) => (
				<ContractAddressItem contract={contract} key={contract._id} />
			))}
		</Card>
	);
};

export default ContractAddresses;
