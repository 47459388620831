import {
	Avatar,
	Card,
	Divider,
	message,
	Select,
	Table,
	Tag,
	Tooltip,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import {
	Country,
	genderEnum,
	statusColor,
} from '../../methods/utils/request-helpers';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../users-list/UsersByCountry';
import { MyTable } from '../users-list/UsersList';
import ImageComponent from './ImageComponent';
import NextKyc from './NextKyc';
import RejectKYC from './RejectKYC';
import { FakeRef, SearchSection, TableProfile } from './UsersKYC';

const MyCard = styled(Card)`
	border-radius: 10px;
`;
const Level1Section = styled.div`
	min-width: 250px;

	& .sus {
		color: red;
	}
`;

const MainColumn = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 0.5em;
`;

const Levels = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	gap: 1em;
	min-width: 200px;
`;

const SusLink = styled.div`
	display: flex;
	gap: 2em;
	min-width: 220px;
	& .sus {
		color: red;
		cursor: pointer;
	}
`;
const ManageKYC = () => {
	const { id } = useParams<{ id: string }>();
	const [refresh, setRefresh] = useState(0);
	const [data, setData] = useState({} as any);
	const [kycHistory, setKycHistory] = useState({} as any);
	const [selectedGateway, setSelectedGateway] = useState(
		'https://cf-ipfs.com/ipfs/'
	);
	console.info(selectedGateway);

	const gateWays = [
		{ name: 'https://cf-ipfs.com/ipfs/', value: 'https://cf-ipfs.com/ipfs/' },
		{ name: 'https://ipfs.io/ipfs/', value: 'https://ipfs.io/ipfs/' },
		{
			name: 'https://cloudflare-ipfs.com/ipfs/',
			value: 'https://cloudflare-ipfs.com/ipfs/',
		},
		{
			name: 'https://gateway.ipfs.io/ipfs/',
			value: 'https://gateway.ipfs.io/ipfs/',
		},
		{
			name: 'https://gateway.pinata.cloud/ipfs/',
			value: 'https://gateway.pinata.cloud/ipfs/',
		},
	];

	const approve = async (userId: string, url: string) => {
		try {
			const res: any = await baseuri.post(url, {
				id: userId,
				kycStatus: 'approved',
			});

			if (res.status === 200) {
				message.success(`Approved successfully `);
				const k = data.itemsList.filter(
					(n: any) => n?.getUserKyc?.userId?._id !== userId
				);
				setData({
					...data,
					itemsList: k,
				});
				setRefresh(Math.random());
			} else {
				message.error(res?.message);
			}
		} catch (e: any) {
			message.error('something went wrong');
		}
	};

	const getKycHistory = async () => {
		setKycHistory({ loading: true });
		try {
			const res: any = await baseuri.get('admin/kyc/rejected-kyc-records', {
				params: {
					userId: id,
				},
			});

			if (res.status === 200) {
				setKycHistory({ ...kycHistory, ...res.data.data, loading: false });
			} else {
				setKycHistory({ loading: false });
			}
		} catch (error) {
			setKycHistory({ loading: false });
		}
	};
	const getSingleKYC = async () => {
		setData({ loading: true });
		try {
			const res: any = await baseuri.get('admin/kyc/single-kyc-details', {
				params: {
					id,
				},
			});

			if (res.status === 200) {
				setData({
					...data,
					itemsList: [res.data.data],
					loading: false,
					...res.data.data,
				});
			} else {
				setData({ loading: false });
			}
		} catch (error) {
			setData({ loading: false });
		}
	};

	useEffect(() => {
		getKycHistory();
		getSingleKYC();
	}, [refresh, id]);

	const columns = [
		{
			title: 'User',
			key: '_id',
			width: 150,
			render: (text: any) => (
				// eslint-disable-next-line no-underscore-dangle
				<TableProfile>
					<div className="avatarSection">
						{text?.getUserKyc?.profileUrl ? (
							<Avatar src={text?.getUserKyc?.userId?.profileUrl} />
						) : (
							<Avatar className="avatar">
								{' '}
								{text?.getUserKyc?.userId?.username?.charAt(0)}
							</Avatar>
						)}
						<Link
							title="View Profile"
							to={`/user-profile/${text?.getUserKyc.userId._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<p>
								<strong>@{text?.getUserKyc?.userId?.username}</strong>
							</p>
							<p>{text?.getUserKyc?.userId?.email}</p>
						</Link>
					</div>
					<br />
					<div>
						<p>
							FULL NAME:{' '}
							<span>{text?.getUserKyc?.userId?.fullName || '---'}</span>
						</p>
						<p>
							PHONE NO:{' '}
							<span>{text?.getUserKyc?.userId?.phoneNo || '---'}</span>
						</p>
						<br />
						<p>
							Referred by:{' '}
							<strong>{text?.getUserKyc?.userId?.refereeName || '---'}</strong>
						</p>
					</div>
				</TableProfile>
			),
		},
		{
			title: 'Level 1',
			key: '9024352',
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.getUserKyc?.lastName}  ${text?.getUserKyc?.firstName}  ${text?.getUserKyc?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER:{' '}
						<strong>+{text?.getUserKyc?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH:{' '}
						<strong>{text?.getUserKyc?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(
									moment(text?.getUserKyc?.dateOfBirth, 'DD/MM/YYYY'),
									'years'
								)
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.getUserKyc?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(
									moment(text?.getUserKyc?.dateOfBirth, 'DD/MM/YYYY'),
									'years'
								)
							)}
						</strong>
					</p>
					<p>
						Gender:{' '}
						<strong>{genderEnum(text?.getUserKyc?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${
								text?.getUserKyc?.residentAddress.length < 15 ? 'sus' : ''
							}`}
						>
							{' '}
							{text?.getUserKyc?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>
							{Country(text?.getUserKyc?.countryCode.toUpperCase()) || 'N/A'}
						</strong>
					</p>
					<p>
						Device Id/Serial No.:{' '}
						<strong>
							{text?.getUserKyc?.deviceId ||
								text?.getUserKyc?.userId?.deviceSerialNumber}
						</strong>
					</p>
					<p>
						Date Create:{' '}
						<strong>
							{moment(text?.getUserKyc?.createdAt).format('lll') || 'N/A'}
						</strong>
					</p>
					<p>
						Date Updated:{' '}
						<strong>
							{moment(text?.getUserKyc?.updatedAt).format('lll') || 'N/A'}
						</strong>
					</p>
					<br />
					<br />
					<p className={data?.blacklistRecord?.blacklisted ? 'sus' : ''}>
						BlackListed User/Device:{' '}
						<strong>{data?.blacklistRecord?.blacklisted ? 'Yes' : 'No'}</strong>
					</p>
					{text?.getUserKyc?.deviceId && (
						<SusLink
							className={Number(data?.kycUsedDeviceId) > 1 ? ' sus' : ''}
						>
							<>
								KYCs with same device:{' '}
								<strong>{data?.kycUsedDeviceId || 0}</strong>
							</>
							{Number(data?.kycUsedDeviceId) > 1 && (
								<Tag
									style={{
										cursor: 'pointer',
									}}
									color="processing"
								>
									<Link
										to={`/kyc-suspects?deviceId=${
											text?.getUserKyc?.deviceId ||
											text?.getUserKyc?.userId?.deviceSerialNumber
										}`}
									>
										{' '}
										View
									</Link>
								</Tag>
							)}
						</SusLink>
					)}
					<SusLink
						className={Number(data?.duplicatephoneNumber) > 1 ? ' sus' : ''}
					>
						<>
							KYCs with same Phone Number:{' '}
							<strong>{data?.duplicatephoneNumber || 0}</strong>
						</>
						{Number(data?.duplicatephoneNumber) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="warning"
							>
								<Link
									to={`/kyc-suspects?phoneNumber=${text?.getUserKyc?.phoneNumber}`}
								>
									{' '}
									View
								</Link>
							</Tag>
						)}
					</SusLink>
					<SusLink
						className={Number(data?.duplicateIdcardNumber) > 1 ? ' sus' : ''}
					>
						<>
							KYCs with same ID Number:{' '}
							<strong>{data?.duplicateIdcardNumber || 0}</strong>
						</>
						{Number(data?.duplicateIdcardNumber) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="error"
							>
								<Link
									to={`/kyc-suspects?idcardNumber=${text?.getUserKyc?.idcardNumber}`}
								>
									{' '}
									View
								</Link>
							</Tag>
						)}
					</SusLink>
					<SusLink
						className={Number(data?.userRegisteredWithDevice) > 1 ? ' sus' : ''}
					>
						<>
							Users with same device:{' '}
							<strong>{data?.userRegisteredWithDevice || 0}</strong>
						</>
						{Number(data?.userRegisteredWithDevice) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="error"
							>
								<Link
									to={`/kyc-suspects?deviceId=${
										text?.getUserKyc?.deviceId ||
										text?.getUserKyc?.userId?.deviceSerialNumber ||
										'N/A'
									}`}
								>
									{' '}
									View
								</Link>
							</Tag>
						)}
					</SusLink>
				</Level1Section>
			),
		},
		{
			title: 'Level 2',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Level 2 Status:{' '}
						<strong
							style={{
								color: `${statusColor(
									text?.getUserKyc?.userId?.kycLevelTwoStatus
								)}`,
							}}
						>
							{' '}
							{text?.getUserKyc?.userId?.kycLevelTwoStatus || 'N/A'}
						</strong>
					</p>
					<p>
						Last Updated by:{' '}
						<strong>
							{text?.getUserKyc?.userId?.KycLevelTwoApprovedBy?.email || 'N/A'}
						</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								createdAt={text?.getUserKyc?.createdAt}
								secret={text?.getUserKyc?.idcardEncryptKey}
								url={`${text?.getUserKyc?.idcardUrl}`}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								createdAt={text?.getUserKyc?.createdAt}
								secret={text?.getUserKyc?.selfieEncryptKey}
								url={`${text?.getUserKyc.selfieUrl}`}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.getUserKyc?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER:{' '}
						<strong> {text?.getUserKyc?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					<Levels>
						{text?.getUserKyc?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.getUserKyc?.userId?.kycLevelTwoStatus === 'rejected' ? (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(
										text?.getUserKyc?.userId?._id,
										'admin/kyc/level-two-kyc'
									)
								}
							>
								Approve
							</Tag>
						) : (
							''
						)}
						{text?.getUserKyc?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.getUserKyc?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/level-two-kyc"
								userId={text?.getUserKyc?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels>
					<br />
					<br />
					{text?.getUserKyc?.custodialAdminId && (
						<strong>Updated by Admin</strong>
					)}
				</MainColumn>
			),
		},
	];
	const columns2 = [
		{
			title: 'Level 1',
			key: 9024352,
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.lastName}  ${text?.firstName}  ${text?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{text?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{text?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							)}
						</strong>
					</p>
					<p>
						Gender: <strong>{genderEnum(text?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${text?.residentAddress.length < 15 ? 'sus' : ''}`}
						>
							{text?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(text?.countryCode.toUpperCase()) || 'N/A'}</strong>
					</p>
				</Level1Section>
			),
		},
		{
			title: 'Level 2',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Rejected by: <strong>{text?.rejectedBy?.email || 'N/A'}</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={text.idcardEncryptKey}
								url={`${text.idcardUrl}`}
								createdAt={text?.createdAt}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								secret={text.selfieEncryptKey}
								url={`${text.selfieUrl}`}
								createdAt={text?.createdAt}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER: <strong> {text?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					{/* <Levels>
						{text?.userId?.kycLevelTwoStatus === 'submitted' && (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(text?.userId?._id, 'admin/kyc/update-levelTwo-kyc')
								}
							>
								Approve
							</Tag>
						)}
						{text?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/update-levelTwo-kyc"
								userId={text?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels> */}
				</MainColumn>
			),
		},
		{
			title: 'Rejection Message',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Rejection Time:
						<strong>{moment(text?.rejectionTime).format('lll')}</strong>
					</p>
					<p>
						<strong>Rejection Reason</strong>
						<div>{text?.levelTwoRejectionReason}</div>
					</p>
					<p>
						<strong>Rejection Message</strong>
						<div>{text?.levelTwoRejectionMessage}</div>
					</p>
				</MainColumn>
			),
		},
	];

	return (
		<div>
			<SearchSection>
				<div>
					Select gateway :{' '}
					<Select
						// mode="multiple"
						placeholder="Select GateWay"
						// value={newsletter?.maillist}
						onChange={(e: any) => setSelectedGateway(e)}
						defaultValue="https://cf-ipfs.com/ipfs/"
						// style={{ width: '100%' }}
					>
						{gateWays?.map((gateway: any) => (
							<Select.Option key={gateway.name} value={gateway.value}>
								{gateway.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<NextKyc text="Next KYC" />
			</SearchSection>
			<br />
			<MyCard>
				<Table
					loading={data?.loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
					showHeader={false}
				/>
			</MyCard>
			<Divider />

			<MyTableCard>
				<TableHeaderSection
					tableName="Rejected KYC History"
					currentPage={kycHistory?.paginator?.currentPage || 0}
					totalRecords={kycHistory?.paginator?.itemCount || 0}
				/>
				<MyTable
					locale={{
						emptyText: <MyEmptyTable />,
					}}
					loading={kycHistory?.loading || false}
					columns={columns2}
					dataSource={kycHistory.itemsList}
					pagination={false}
				/>
			</MyTableCard>
		</div>
	);
};

export default ManageKYC;
