import moment from 'moment';
import styled from 'styled-components';

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 10px;

	& .details {
		padding: 12px 0;
		margin-top: 12px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		border-top: 1px dashed #ddd;

		& .label {
			font-style: italic;
			font-size: 10px;
		}
	}
`;

interface IBlockchainTransactionItem {
	transaction: any;
	actionTypes: any[];
}
const BlockchainTransactionItem = ({
	transaction,
	actionTypes,
}: IBlockchainTransactionItem) => {
	const actionName = (actionType: any) => {
		const filtered = actionTypes.filter((item) => item.value === actionType);
		if (filtered.length > 0) {
			return filtered[0].name;
		}
		return actionType;
	};

	return (
		<Style>
			<div>
				<p>
					<strong>{actionName(transaction.actionType)}</strong>
				</p>
				<a
					target="_blank"
					href={`https://${transaction.blockchainNetworkId?.explorerUrl}/tx/${transaction.transactionHash}`}
					rel="noreferrer"
				>
					Transaction Hash
				</a>
			</div>
			<div className="details">
				<p>{transaction.status}</p>
				<div>
					<p>{transaction.currencyId?.symbol}</p>
					<p className="label">Currency</p>
				</div>
				<div>
					<p>{transaction.blockchainNetworkId?.abbreviation}</p>
					<p className="label">Blockchain</p>
				</div>
				<div>
					<p>{transaction.adminId?.email}</p>
					<p className="label">Added By</p>
				</div>
				<div>
					<p>
						{moment(transaction.createdAt).format('MMM DD, YY / hh:mm:ss a')}
					</p>
					<p className="label">Created At</p>
				</div>
			</div>
		</Style>
	);
};

export default BlockchainTransactionItem;
