import { EditOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useEffect, useState } from 'react';
import SelectItem from 'react-select';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;
interface IEditEmailCron {
	item: any;
}
const EditEmailCron: FC<IEditEmailCron> = ({ item }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [typeList, setTypeList] = useState([] as any);
	const [data, setData] = useState({
		title: item.title,
		type: item.type,
	});

	const getType = async () => {
		try {
			const res: any = await emailBaseUrl.get(`v1/maintenance/types`);

			if (res.status === 200) {
				setTypeList(res.data.data);
			}
		} catch (e: any) {
			message.error(e.response.data.data.message);
		}
	};

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await emailBaseUrl.patch(`v1/maintenance`, data);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Email process updated Successfully');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	useEffect(() => {
		getType();
	}, []);

	const options = Object.entries(typeList).map(([key, value1]) => ({
		value: value1,
		label: key.replace(/_/g, ' '),
	}));

	return (
		<div>
			<EditOutlined className="EditIcon" onClick={() => setVisible(true)} />
			<MyModal
				title="update In an Email Process"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={data?.title}
							onChange={(e: any) => setData({ ...data, title: e.target.value })}
						/>
					</p>
					<p>
						Type :{' '}
						<SelectItem
							options={options}
							placeholder="Select interval"
							onChange={(e: any) => {
								setData({
									...data,
									type: e.value,
								});
							}}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default EditEmailCron;
