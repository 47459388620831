import { Button, Card } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FilterSection = styled.div``;
const CustomCard = styled(Card)`
	padding: 10px;
	border-radius: 10px;
`;
const MyButton = styled(Button)`
	color: #383838;
	border-radius: 10px;
	margin: 5px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const Filters = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const selectedFilter = location.pathname.split(/[/,?,&]/)[3];

	const filterEnum = [
		{ name: 'Locked Fixed Savings', value: 'fixed' },
		{ name: 'Locked Flexible Savings', value: 'flexible' },
	];
	return (
		<FilterSection>
			<CustomCard>
				{filterEnum.map((n) => (
					<MyButton
						className={selectedFilter === n.value ? 'selected' : ''}
						key={n.value}
						onClick={() =>
							navigate(
								`/compliance/locked-savings/${n.value}?perPage=10&pageId=1&sort=desc`
							)
						}
					>
						{n.name}
					</MyButton>
				))}
			</CustomCard>
		</FilterSection>
	);
};

export default Filters;
