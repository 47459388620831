import { Pagination, Card, Divider } from 'antd';
import { useEffect } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getTransactions } from '../../methods/redux/reducers/transactions';
import SearchFilter from '../../component/Filter';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import TransactionItem from './TransactionItem';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import NativeNumber from '../../component/Numeral';
import remakeQueryString from '../../methods/utils/remake-query-string';
import { transactionStatementTypes } from '../../methods/utils/general-utils';
import { getGlobalEnumsData } from '../../methods/redux/reducers/general';

const TransactionStatments = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { globalEnums } = useAppSelector((store) => store.general);
	const { currencies } = useAppSelector((store) => store.currencies);
	const { user } = useAppSelector((store) => store.userProfile);
	const { itemsList, paginator } = useAppSelector(
		(store) => store.transactions
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'active',
				pageId: '1',
				perPage: String(paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getGlobalEnumsData());
		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getTransactions({ ...queryData, userId }));
			} else {
				dispatch(getTransactions(queryData));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<div>
			<Card>
				<h1>
					Transaction Statements{' '}
					{userId && (
						<span>
							{' '}
							for{' '}
							<Link
								title="View Profile"
								to={`/user-profile/${user.profile?._id}`}
							>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>
				<p>Find all wallet credit and debit records on Xend Finance.</p>

				<Divider />

				<SearchFilter
					title="Search Transaction Statements"
					searchFilters={[
						{
							label: 'Status',
							name: 'status',
							defaultSelectedValue: 'active',
							htmlElement: 'select',
							selectOptions: [
								{ name: 'Pending', value: 'pending' },
								{ name: 'Active', value: 'active' },
							],
						},
						{
							label: 'Type',
							name: 'transactionType',
							htmlElement: 'select',
							selectOptions: [
								{ name: 'Credit', value: 'credit' },
								{ name: 'Debit', value: 'debit' },
								{ name: 'Unknown', value: 'unknown' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
						{
							label: 'Transaction Form',
							name: 'transactionForm',
							htmlElement: 'select',
							selectOptions: transactionStatementTypes(
								globalEnums.TRANSACTION_STATEMENT_FORM
							),
						},
						{
							label: 'Start Date',
							name: 'startDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'End Date',
							name: 'endDate',
							htmlElement: 'datepicker',
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<p>
					Total of{' '}
					<strong>
						<NativeNumber value={paginator.itemCount} /> items
					</strong>{' '}
					found
				</p>

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={paginator.itemCount}
					current={paginator.currentPage}
					pageSize={paginator.perPage}
				/>

				<br />

				{itemsList.map((item: any) => (
					<TransactionItem key={item._id} transaction={item} />
				))}

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={paginator.itemCount}
					current={paginator.currentPage}
					pageSize={paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>
		</div>
	);
};

export default TransactionStatments;
