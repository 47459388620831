import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { rejectLoan } from '../../methods/redux/reducers/loan';

const ActionSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
	cursor: pointer;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IRejectLoan {
	id: string;
}
const RejectLoan = ({ id }: IRejectLoan) => {
	const dispatch = useAppDispatch();

	const handle = async () => {
		dispatch(
			rejectLoan({
				loanId: id,
				status: 'rejected',
			})
		);
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to reject this Loan?',
			onOk() {
				handle();
			},
		});
	};
	return (
		<ActionSection>
			<Button danger type="primary" onClick={confirm}>
				Reject Loan
			</Button>
		</ActionSection>
	);
};

export default RejectLoan;
