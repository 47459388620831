import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const retrieveUnreadNotifications = createAsyncThunk(
	'notifications/retrieveUnreadNofications',
	async (params: any) => {
		const res = await baseuri.get('/admin/notifications', {
			params: { ...params, read: 'unread' },
		});

		return res.data.data;
	}
);

export const retrieveReadNotifications = createAsyncThunk(
	'notifications/retrieveReadNofications',
	async (params: any) => {
		const res = await baseuri.get('/admin/notifications', {
			params: { ...params, read: 'read' },
		});

		return res.data.data;
	}
);

export const markNotificationAsRead = createAsyncThunk(
	'notifications/markNotificationAsRead',
	async (data: any) => {
		const { adminNotificationId, userId } = data;
		await baseuri.put('/admin/notifications/mark-as-read', {
			adminNotificationId,
		});

		return { adminNotificationId, userId };
	}
);

export const deleteNotificationAsAdmin = createAsyncThunk(
	'notifications/deleteNotificationAsAdmin',
	async (adminNotificationId: any) => {
		await baseuri.delete('/admin/notifications', {
			params: { adminNotificationId },
		});
	}
);

const notificationRx = createSlice({
	name: 'notifications',
	initialState: {
		unread: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 5,
			},
		},
		read: {
			itemsList: [],
			paginator: {
				itemCount: 0,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveUnreadNotifications.fulfilled, (store, action) => {
				const state = store;
				state.unread = action.payload;
			})
			.addCase(retrieveReadNotifications.fulfilled, (store, action) => {
				const state = store;
				state.read = action.payload;
			})
			.addCase(markNotificationAsRead.fulfilled, (store) => {
				const state = store;
				state.unread.paginator.itemCount -= 1;
			}),
});

export default notificationRx.reducer;
