import { Button, Modal } from 'antd';
import emailBaseUrl from '../../methods/emailBaseUrl';

interface IManuallyTriggerCronjob {
	jobId: any;
}
const ManuallyTriggerCronjob = ({ jobId }: IManuallyTriggerCronjob) => {
	const work = async () => {
		try {
			const res = await emailBaseUrl.post('/v1/app-cron/trigger-job', {
				id: jobId,
			});
			console.info(res.data);
		} catch (e: any) {
			console.info('failed!');
		}
	};
	const trigger = () => {
		Modal.confirm({
			title: 'Confirm',
			content: 'Trigger cronjob manually',
			onOk: work,
		});
	};

	return (
		<Button size="small" onClick={trigger}>
			Trigger Job
		</Button>
	);
};

export default ManuallyTriggerCronjob;
