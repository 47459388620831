import { Divider } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import {
	getFlexibleSavings,
	getPendingProfitBuild,
} from '../methods/redux/reducers/flexible-savings';
import { commas } from '../methods/utils/general-utils';

const Style = styled.div`
	& .notice {
		font-weight: bold;
		color: var(--red-color);
	}

	& .highlights {
		& > * {
			display: inline-block;
			margin-right: 20px;
		}
		& .title {
			font-style: italic;
			font-size: 10px;
			color: #333;
			letter-spacing: 0.6px;
			line-height: 1.5;
			text-transform: uppercase;
		}

		& .dt {
			& > * {
				display: inline-block;
				padding: 10px 10px 10px 1px;

				& .label {
					font-size: 10px;
					color: #333;
					letter-spacing: 0.6px;
					font-weight: 400;
					margin: 0;
					padding: 0;
					line-height: 1.5;
					text-transform: uppercase;
				}
			}
		}
	}
`;

const ReportHighlights = () => {
	const dispatch = useAppDispatch();

	const { savingsSum } = useAppSelector((store) => store.dashboard);
	const { paginator, pendingProfitBuild } = useAppSelector(
		(store) => store.flexibleSavings
	);

	useEffect(() => {
		dispatch(getFlexibleSavings({ migrateStatus: 'pending' }));
		dispatch(getPendingProfitBuild({}));
	}, []);

	return (
		<Style>
			<h2>Report Highlights</h2>

			<div className="highlights">
				<div>
					<p className="title">Flexible Savings</p>
					<div className="dt">
						<div>
							<p className="label">Total Available Balance</p>
							<p>
								<strong>
									{commas(savingsSum.flexibleSavings.totalBalanceUsdValue)} USD
								</strong>
							</p>
						</div>
						<div>
							<p className="label">Total Withdrawn</p>
							<p>
								<strong>
									{commas(savingsSum.flexibleSavings.totalWithdrawn)} USD
								</strong>
							</p>
						</div>
					</div>
				</div>
				<div>
					<p className="title">Fixed Savings</p>
					<div className="dt">
						<div>
							<p className="label">Total Available Balance</p>
							<p>
								<strong>
									{commas(savingsSum.fixedSavings.totalCapital)} USD
								</strong>
							</p>
						</div>
						{/* <div> */}
						{/* 	<p className="label">Total Withdrawn</p> */}
						{/* 	<p> */}
						{/* 		<strong> */}
						{/* 			{commas(savingsSum.fixedSavings.)} USD */}
						{/* 		</strong> */}
						{/* 	</p> */}
						{/* </div> */}
					</div>
				</div>
			</div>

			<Link to="/reports" style={{ textTransform: 'uppercase' }}>
				See Reports
			</Link>

			<Divider />

			<div>
				<div>
					<Link
						to="/core-modules/flexible-savings/top-ups?migrateStatus=pending"
						className={`${Number(paginator.itemCount) > 0 ? 'notice' : ''}`}
					>
						We have {paginator.itemCount} unmigrated flexible topup items
					</Link>
				</div>
				<div>
					<Link
						to="/core-modules/flexible-savings/pending-profit-build"
						className={`${
							Number(pendingProfitBuild.paginator.itemCount) > 0 ? 'notice' : ''
						}`}
					>
						We have {pendingProfitBuild.paginator.itemCount} pending profit
						build
					</Link>
				</div>
			</div>
		</Style>
	);
};

export default ReportHighlights;
