import { Tag } from 'antd';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { useAppSelector } from '../../methods/redux/hooks';

const StatusCountStyle = styled.div`
	& > * {
		padding: 7px 15px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
`;

const GameStatusCount = () => {
	const { gameStatusCount } = useAppSelector((store) => store.games);

	return (
		<StatusCountStyle>
			<Tag color="orange">
				<p>Pending</p>
				<p>
					<NativeNumber value={gameStatusCount.pendingGames} />
				</p>
			</Tag>
			<Tag color="darkgreen">
				<p>Active</p>
				<p>
					<NativeNumber value={gameStatusCount.activeGames} />
				</p>
			</Tag>
			<Tag color="blue">
				<p>Playing</p>
				<p>
					<NativeNumber value={gameStatusCount.playingGames} />
				</p>
			</Tag>
			<Tag color="green">
				<p>Completed</p>
				<p>
					<NativeNumber value={gameStatusCount.completedGames} />
				</p>
			</Tag>
			<Tag color="red">
				<p>Deleted</p>
				<p>
					<NativeNumber value={gameStatusCount.deletedGames} />
				</p>
			</Tag>
		</StatusCountStyle>
	);
};

export default GameStatusCount;
