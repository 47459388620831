import {
	Divider,
	Card,
	Pagination,
	Input,
	Button,
	Select,
	Popconfirm,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import remakeQueryString from '../../methods/utils/remake-query-string';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveGameActions } from '../../methods/redux/reducers/games/actions/game-actions';
import { retrieveGameBadge } from '../../methods/redux/reducers/games/actions/game-badge';
import GameActionItem from './gameAction/GameActionItem';
import GameSetting from '../../settings/general/Game';
import {
	retrieveInternalSettings,
	updateInternalSettings,
} from '../../methods/redux/reducers/internal-settings';
import { getCurrencies } from '../../methods/redux/reducers/currency';

const Style = styled(Card)``;

const GameSettings = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const settings = useAppSelector((store) => store.internalSettings);

	const [state, setState] = useState({
		_id: '',
		gameRewardCurrencyId: { iconUrl: '', symbol: '' },
		gamePointsEquivalentToOneDollar: 0,
	});

	useEffect(() => {
		setState({
			...state,
			...settings,
		});
	}, [settings]);

	const updateSettings = () => {
		const { gameRewardCurrencyId, gamePointsEquivalentToOneDollar } = state;

		dispatch(
			updateInternalSettings({
				settingsId: state._id,
				gameRewardCurrencyId,
				gamePointsEquivalentToOneDollar,
			})
		);
	};

	const { gameActions } = useAppSelector((store) => store.games);
	const { currencies } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '4',
			});
		}
		dispatch(retrieveInternalSettings());
		dispatch(getCurrencies({}));
		dispatch(retrieveGameBadge({}));
	}, []);
	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
		icon: item.iconUrl,
	}));
	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveGameActions({ ...queryData }));
		}
	}, [searchParams]);

	return (
		<Style>
			<h1>Game Settings</h1>
			<GameSetting />
			<br />
			<h2>Set Game Reward Distribution </h2>
			<div>
				<p>
					Game Reward Default Currency:{' '}
					<strong>[ {state.gameRewardCurrencyId.symbol} ]</strong>
				</p>
				<Select
					onChange={(gameRewardCurrencyId) =>
						setState({ ...state, gameRewardCurrencyId })
					}
					style={{ width: '50%' }}
					size="large"
				>
					<Select.Option value="">
						<strong>{state.gameRewardCurrencyId.symbol}</strong>
					</Select.Option>
					{currencySelect.map((option: any) => (
						<Select.Option value={option.value} key={option.value}>
							{option.symbol && <img src={option.symbol} alt={option.name} />}{' '}
							{option.name}
						</Select.Option>
					))}
				</Select>
			</div>

			<div>
				<p style={{ marginTop: '20px' }}>
					Game Points Equivalent To One Dollar
				</p>

				<Input
					style={{ width: '50%' }}
					onChange={(e) =>
						setState({
							...state,
							gamePointsEquivalentToOneDollar: Number(e.target.value),
						})
					}
					value={state.gamePointsEquivalentToOneDollar}
				/>
			</div>
			<Popconfirm title="Are you sure?" onConfirm={updateSettings}>
				<Button type="primary">Update</Button>
			</Popconfirm>
			<Divider />
			<br />
			<h2>Game Action</h2>
			<p>
				This shows a complete list of all the game actions a user can perform in
				the game..
			</p>
			<Button style={{ marginTop: '10px' }}>Create Game Action</Button>

			<Divider />
			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={gameActions.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={gameActions.paginator.itemCount}
				current={gameActions.paginator.currentPage}
				pageSize={gameActions.paginator.perPage}
			/>
			<br />

			{gameActions?.itemsList?.map((gameAction: any) => (
				<GameActionItem gameAction={gameAction} key={gameAction._id} />
			))}
			<br />
			<Divider />
			<br />
			<br />
			<h2>Game Ranks</h2>
			<p>
				This shows a complete list of all the game ranks a user can attain
				during game progress.
			</p>

			<Divider />
			<div style={{ marginTop: '10px', marginBottom: '10px' }}>
				<Link to="./game-ranks">
					<p>CLick to View / Edit Game Ranks</p>
				</Link>
			</div>
		</Style>
	);
};

export default GameSettings;
