/* eslint-disable react/destructuring-assignment */
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

const Style = styled.div`
	display: grid;
	grid-template-columns: 1fr;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	& .item {
		border: 1px solid #d5d3d3;
		padding: 20px;
		margin-right: 10px;
		margin-bottom: 10px;

		& .amount {
			font-size: 18px;
		}

		& .details {
			& > * {
				display: inline-block;
				margin-right: 20px;

				& img {
					width: 24px;
					height: 24px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}

			& .label {
				font-size: 13px;
				color: #808080;
				font-style: italic;
			}
		}
	}
`;

const FixedSavingsTab = (data: any) => (
	<Style>
		<div className="item">
			<div className="details">
				<div>
					<strong>
						<p>Available Fixed Savings Balance</p>
					</strong>
					<br />

					<p className="amount">
						<strong> {commas(data.data)} USD </strong>
					</p>
				</div>
			</div>
		</div>
	</Style>
);

export default FixedSavingsTab;
