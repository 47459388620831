import { Input, Select, Button, Modal } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { createReferralRewardSettings } from '../../methods/redux/reducers/referrals';

const CreateRewardSetting = () => {
	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store) => store.currencies);

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		referralRewardCurrencyId: '',
		referrerRewardAmount: '',
		referredRewardAmount: '',
		referralUserId: '',
		rewardUser: '',
	});

	const submit = () => {
		dispatch(createReferralRewardSettings(state));
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Create Setting</Button>

			<Modal
				onOk={submit}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Create Settings"
			>
				<p>New User Reward Amount</p>
				<Input
					value={state.referredRewardAmount}
					onChange={(e) =>
						setState({ ...state, referredRewardAmount: e.target.value })
					}
				/>

				<br />
				<br />

				<p>Old User Reward Amount</p>
				<Input
					value={state.referrerRewardAmount}
					onChange={(e) =>
						setState({ ...state, referrerRewardAmount: e.target.value })
					}
				/>

				<br />
				<br />

				<p>Reward Currency</p>
				<Select
					defaultValue={state.referralRewardCurrencyId}
					onChange={(referralRewardCurrencyId) =>
						setState({ ...state, referralRewardCurrencyId })
					}
					style={{ width: '100%' }}
				>
					{currencies.map((currency: any) => (
						<Select.Option key={currency._id} value={currency._id}>
							{currency.symbol}
						</Select.Option>
					))}
				</Select>

				<br />
				<br />

				<p>User to Pay Referral Rewards</p>
				<Input
					value={state.referralUserId}
					placeholder="Paste the ObjectId of referral user"
					onChange={(e) =>
						setState({ ...state, referralUserId: e.target.value })
					}
				/>
			</Modal>
		</>
	);
};

export default CreateRewardSetting;
