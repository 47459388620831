import { Input, Modal, Space } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { createBlockchainNetwork } from '../../methods/blockchain-networks';

const AddBlockchainNetworkStyle = styled.section`
	& > button {
		border: none;
		background: transparent;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 16px;
		padding: 12px 12px 12px 5px;
		border-radius: 25px;
		transition: background 400ms ease;

		&:hover {
			background: #e2e2e2;
		}
	}
`;

const AddBlockchainNetwork = () => {
	const [visible, setVisible] = useState(false);
	const [newData, setNewData] = useState<any>({});
	const [loading, setLoading] = useState(false);

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setNewData({ ...newData, [name]: value });
	};

	const handleSubmit = async () => {
		createBlockchainNetwork(newData, setLoading, () => setVisible(false));
	};

	return (
		<AddBlockchainNetworkStyle>
			<button type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Add Network</span>
			</button>

			<Modal
				okText="Create Network"
				confirmLoading={loading}
				onOk={() => handleSubmit()}
				title="Add Blockchain Network"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<Space direction="vertical" size={15} style={{ width: '100%' }}>
						<Input
							name="name"
							onChange={handleInput}
							required
							size="large"
							placeholder="Network Name"
						/>

						<Input
							name="abbreviation"
							onChange={handleInput}
							size="large"
							placeholder="Abbreviation"
						/>

						<Input
							name="explorerUrl"
							size="large"
							onChange={handleInput}
							required
							addonBefore="https://"
							placeholder="Explorer url"
						/>

						<Input
							name="tokenStandard"
							size="large"
							onChange={handleInput}
							placeholder="Token Standard (eg BEP20, ERC20)"
						/>

						<Input
							onChange={handleInput}
							name="networkId"
							size="large"
							placeholder="Network ID"
						/>

						<Input
							onChange={handleInput}
							name="nativeTokenCoinGeckoId"
							size="large"
							placeholder="Eg. binancecoin"
						/>
					</Space>
				</form>
			</Modal>
		</AddBlockchainNetworkStyle>
	);
};

export default AddBlockchainNetwork;
