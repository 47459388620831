import { Button, message } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import baseuri from '../../methods/baseuri';

interface INextKyc {
	text: string;
}
const NextKyc: FC<INextKyc> = ({ text }) => {
	const [loading, setloading] = useState(false);
	const navigate = useNavigate();

	const handleNext = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.get('/admin/kyc/random-kyc-records');

			if (res.status === 200) {
				setloading(false);
				navigate(`/manage-kyc/${res?.data?.data}`);
			} else {
				setloading(false);
				message.info('No Unattended KYC');
				// history.push(`/users-kyc`);
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
		}
	};

	return (
		<Button onClick={() => handleNext()} loading={loading} type="primary">
			{text}
		</Button>
	);
};

export default NextKyc;
