import styled from 'styled-components';
import { Outlet, Navigate } from 'react-router-dom';
import Sider from './Sider';
import AppHeader from './Header';
import Content from './Content';
import { isLoggedIn } from '../methods/utils/auth';
import AppConnectionToServer from '../component/AppConnectionToServer';
import LoadingCover from '../component/LoadingCover';
// import RealtimeSocket from './Socket';

const LayoutStyle = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: none;
	background-color: #f1f1f1;
	display: grid;
	grid-template-rows: 60px 1fr;
	grid-template-columns: 0.2fr 1fr;
`;

const Layout = () => {
	if (!isLoggedIn().response) {
		return <Navigate to="/login" replace />;
	}

	return (
		<>
			{/* <RealtimeSocket /> */}
			<LoadingCover />
			<AppConnectionToServer />
			<LayoutStyle>
				<Sider />
				<AppHeader />
				<Content>
					<Outlet />
				</Content>
			</LayoutStyle>
		</>
	);
};

export default Layout;
