import { message } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import { chooseEnvironment } from './baseuri';
import { getEmailToken } from './utils/auth';
import { getDeviceDetails, getLocation } from './utils/request-helpers';

const emailBaseUrl = Axios.create({
	baseURL: chooseEnvironment().robot,
	headers: {
		...getLocation(),
		...getDeviceDetails(),
		authentication: `Bearer ${localStorage.getItem('emailToken')}`,
		authorization: String(chooseEnvironment().auth),
		timestamp: moment().valueOf(),
	},
});

/**
 * Intercept the axios requests and add the necessary header properties
 * that the backend expects
 */

emailBaseUrl.interceptors.request.use((request) => {
	const timestamp = moment().valueOf().toString();
	if (request.headers) {
		// request.headers.apisignature = getApiSignature(request, timestamp);
		request.headers.timestamp = timestamp;
		request.headers.authentication = `Basic ${localStorage.getItem(
			'emailToken'
		)}`;
		return request;
	}
	throw new Error('no headers object');
});

/**
 * Handle lack of authentication
 * */
emailBaseUrl.interceptors.response.use(
	(res) => res,
	async (error) => {
		const { status } = error.response;
		const token = localStorage.getItem('emailToken');

		if (!token || status === 401) {
			const originalRequest = error.config;
			originalRequest._retry = true;
			await getEmailToken();
			return emailBaseUrl(originalRequest);
		}

		message.warn('You dont have the permission to access this page');

		throw error;
	}
);

export default emailBaseUrl;
