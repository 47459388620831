import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { distributeLoan } from '../../methods/redux/reducers/loan';

const ActionSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
	cursor: pointer;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IDisburseLoan {
	id: string;
}
const DisburseLoan = ({ id }: IDisburseLoan) => {
	const dispatch = useAppDispatch();
	const handle = async () => {
		dispatch(
			distributeLoan({
				loanId: id,
				status: 'running',
			})
		);
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to disburse this Loan',
			onOk() {
				handle();
			},
		});
	};
	return (
		<ActionSection>
			<Button type="primary" onClick={confirm}>
				Disburse Loan
			</Button>
		</ActionSection>
	);
};

export default DisburseLoan;
