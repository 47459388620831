import { configureStore } from '@reduxjs/toolkit';
import entry from './reducers/entry';
import currencies from './reducers/currency';
import general from './reducers/general';
import staking from './reducers/staking';
import internalSettings from './reducers/internal-settings';
import feeSettings from './reducers/fee-settings';
import blockchainTransactions from './reducers/blockchain-transactions';
import swap from './reducers/swap';
import loan from './reducers/loan';
import globalSearch from './reducers/global-search';
import flexibleSavings from './reducers/flexible-savings';
import fixedSavings from './reducers/fixed-savings';
import fixedSavingsSingle from './reducers/single-fixed-savings';
import roles from './reducers/roles';
import liquidityProviders from './reducers/liquidity-providers';
import dashboard from './reducers/dashboard';
import connection from './reducers/web3-connection';
import transactions from './reducers/transactions';
import withdrawalRequests from './reducers/withdrawal-requests';
import cronjob from './reducers/cronjobs';
import fundsRobot from './reducers/fundsRobots';
import users from './reducers/users';
import notifications from './reducers/notification';
import referrals from './reducers/referrals';
import reports from './reducers/reports';
import userProfile from './reducers/user-profile';
import maintenance from './reducers/maintenance';
import cryptoBalances from './reducers/crypto-balances';
import multipleEmails from './reducers/multiple-emails';
import accountantsReport from './reducers/accountant-report';
import games from './reducers/games';
import compliance from './reducers/compliance';
import robotReport from './reducers/robot-report';
import alerts from './reducers/alerts';
import api from '../../api-services/api';

const store = configureStore({
	reducer: {
		alerts,
		compliance,
		users,
		fundsRobot,
		entry,
		cronjob,
		currencies,
		general,
		staking,
		feeSettings,
		internalSettings,
		blockchainTransactions,
		swap,
		loan,
		globalSearch,
		flexibleSavings,
		fixedSavings,
		fixedSavingsSingle,
		roles,
		liquidityProviders,
		dashboard,
		connection,
		transactions,
		withdrawalRequests,
		notifications,
		referrals,
		userProfile,
		reports,
		maintenance,
		cryptoBalances,
		multipleEmails,
		accountantsReport,
		games,
		robotReport,
		[api.reducerPath]: api.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware),
});
async function reduxStore() {
	return store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default reduxStore;
