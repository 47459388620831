import { useEffect, useState } from 'react';
import {
	Switch,
	Button,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
} from 'antd';
import baseuri from '../../methods/baseuri';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { editStakingProfile } from '../../methods/redux/reducers/staking';

interface IEditStakingProfile {
	profile: any;
}

const EditStakingProfile = ({ profile }: IEditStakingProfile) => {
	const dispatch = useAppDispatch();
	const { loading } = useAppSelector((store) => store.staking);
	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);
	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);
	const [email, setEmail] = useState('');

	const [state, setState] = useState({
		apr: 0,
		blockchainId: '',
		contractAddress: '',
		externalLink: '',
		createdAt: '',
		currencyId: '',
		details: '',
		maximumTotalStakeAmount: 0,
		minimumStakeAmount: 0,
		name: '',
		periodInDays: 0,
		minimumStakeDaysBeforeUnstake: 0,
		rewardUserId: '',
		stakingForm: '',
		status: '',
		updatedAt: '',
		withdrawalPenaltyApr: 0,
		rewardUserEmail: '',
	});

	useEffect(() => {
		setState({
			...state,
			...profile,
			rewardUserEmail: profile.rewardUserId?.email,
			currencyId: profile.currencyId?._id,
			blockchainId: profile.blockchainId?._id,
		});
	}, [profile]);

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const getRewardUserDetails = async () => {
		if (!email) {
			message.warning('no email has been provided');
			return;
		}

		const res = await baseuri.get('/admin/users/get-user', {
			params: { email },
		});

		const rewardUserId = res.data.data?._id;

		if (rewardUserId) {
			setState({ ...state, rewardUserId, rewardUserEmail: email });
			setVisible1(false);
			message.success('Found!');
		}
	};

	const submit = () => {
		if (!state.rewardUserId) {
			message.warning('Confirm the reward user');
		}
		dispatch(editStakingProfile({ ...state, stakingProfileId: profile._id }));
	};

	return (
		<div>
			<Modal
				footer={null}
				title="Create Staking Profile"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Switch Active Status</p>
					<Switch
						checked={state.status === 'active'}
						onChange={(e) => {
							setState({
								...state,
								status: e ? 'active' : 'inactive',
							});
						}}
					/>
				</div>
				<br />
				<div>
					<p>Profile Name</p>
					<Input name="name" value={state.name} onChange={handleInput} />
				</div>
				<br />
				<div>
					<p>Percentage APR</p>
					<Input
						name="apr"
						value={state.apr}
						onChange={handleInput}
						placeholder="Eg: 30"
						addonAfter="%"
					/>
				</div>
				<br />
				<div>
					<p>Withdrawal Penalty APR</p>
					<Input
						value={state.withdrawalPenaltyApr}
						name="withdrawalPenaltyApr"
						onChange={handleInput}
						addonAfter="%"
					/>
				</div>
				<br />
				<div>
					<p>Lock Period</p>
					<Input
						value={state.periodInDays}
						name="periodInDays"
						onChange={handleInput}
						placeholder="Eg: 60"
						addonAfter="days"
					/>
				</div>
				<br />
				<div>
					<p>Staking Currency</p>
					<Select
						defaultValue={state.currencyId}
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, currencyId: e })}
					>
						{currencies.map((currency: any) => (
							<Select.Option key={currency._id} value={currency._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Staking Form</p>
					<Select
						defaultValue={state.stakingForm}
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, stakingForm: e })}
					>
						<Select.Option value="blockchain">Blockchain</Select.Option>
						<Select.Option value="local">Local</Select.Option>
					</Select>
				</div>
				<br />
				<div>
					<p>Mininum Stake Amount</p>
					<Input
						name="minimumStakeAmount"
						value={state.minimumStakeAmount}
						onChange={handleInput}
					/>
				</div>
				<br />
				<div>
					<p>Maximum Total Amount</p>
					<Input
						name="maximumTotalStakeAmount"
						value={state.maximumTotalStakeAmount}
						onChange={handleInput}
					/>
				</div>
				<br />
				<div>
					<p>Minimum Days Before Unstake</p>
					<Input
						name="minimumStakeDaysBeforeUnstake"
						value={state.minimumStakeDaysBeforeUnstake}
						onChange={handleInput}
						addonAfter="days"
					/>
				</div>
				<br />
				<div>
					<p>Reward User</p>
					<Input
						type="email"
						value={state.rewardUserEmail}
						readOnly
						name="rewardUserEmail"
					/>
					<div
						style={{
							marginTop: 5,
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Modal
							visible={visible1}
							footer={null}
							width={320}
							onCancel={() => setVisible1(false)}
							title="Find User"
						>
							<div>
								<Input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<br />
							<div>
								<Button
									block
									type="primary"
									onClick={() => getRewardUserDetails()}
								>
									Find User
								</Button>
							</div>
						</Modal>
						<button type="button" onClick={() => setVisible1(true)}>
							Change User
						</button>
					</div>
				</div>
				<br />
				<div>
					<p>Blockchain Network</p>
					<Select
						defaultValue={state.blockchainId}
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, blockchainId: e })}
					>
						{blockchains.map((network: any) => (
							<Select.Option key={network._id} value={network._id}>
								{network.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Staking Contract Address</p>
					<Input
						name="contractAddress"
						value={state.contractAddress}
						onChange={handleInput}
					/>
				</div>
				<br />
				<div>
					<p>Profile Details</p>
					<Input.TextArea
						value={state.details}
						name="details"
						onChange={handleInput}
					/>
				</div>

				<br />
				<div>
					<p>External Link</p>
					<Input.TextArea
						value={state.externalLink}
						name="externalLink"
						onChange={handleInput}
					/>
				</div>

				<br />

				<div>
					<Popconfirm title="Are you sure?" onConfirm={submit}>
						<Button type="primary">Submit</Button>
					</Popconfirm>
				</div>
			</Modal>

			<Button type="default" loading={loading} onClick={() => setVisible(true)}>
				Edit Profile
			</Button>
		</div>
	);
};

export default EditStakingProfile;
