import { Button, Card } from 'antd';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const FilterSection = styled.div``;
const CustomCard = styled(Card)`
	padding: 10px;
	border-radius: 10px;
`;
const MyButton = styled(Button)`
	color: #383838;
	border-radius: 10px;
	margin: 5px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const QueueFilters = () => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const selectedFilter = searchParams.get('status') || 0;

	const filterEnum = [
		{ name: 'Pending', value: 'pending', code: 0 },
		{ name: 'Processing', value: 'processing', code: 5 },
		{ name: 'Completed', value: 'done', code: 1 },
		{ name: 'Cancelled ', value: 'cancelled', code: 3 },
	];
	return (
		<FilterSection>
			<CustomCard>
				<strong>Filters: </strong>
				{filterEnum.map((n) => (
					<MyButton
						className={selectedFilter === n.value ? 'selected' : ''}
						key={n.value}
						onClick={() =>
							navigate(
								`/withdrawals/queues?status=${
									n.value
								}&startDate=2021-10-01&endDate=${moment().format(
									'YYYY-MM-DD'
								)}&perPage=10&pageId=1&sort=desc`
							)
						}
					>
						{n.name}
					</MyButton>
				))}
			</CustomCard>
		</FilterSection>
	);
};

export default QueueFilters;
