import {
	Button,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
	Tag,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';
import { countryCode } from '../../methods/utils/request-helpers';
import EditorComponent from './EditorComponent';
// import PreviewNewsLetter from './PreviewNewsLetter';
import SendTestNewsLetter from './SendTestNewsLetter';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
interface IEditNewsLetter {
	newsLetter: any;
	setRefresh: Function;
}
const EditNewsletter: FC<IEditNewsLetter> = ({ newsLetter, setRefresh }) => {
	const [allMailList, setAllMailList] = useState({} as any);
	const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [newsletter, setNewsLetter] = useState({
		subject: newsLetter.subject,
		bannerUrl: newsLetter.bannerUrl,
		previewMessage: newsLetter.previewMessage,
		maillist: newsLetter.maillist,
		access_code: 'password',
		deliveryType: newsLetter.deliveryType,
		instantProcessing: newsLetter.instantProcessing,
		creatorEmail: user?.email,
		content: newsLetter.content,
		countryCode: newsLetter.countryCode,
		locationType: newsLetter.locationType,
	});

	useEffect(() => {
		emailBaseUrl.get(`v1/newsletters/maillist`).then((res: any) => {
			setAllMailList(res?.data?.data);
		});
	}, []);

	const instantProcessing = [
		{
			name: 'YES',
			value: true,
		},
		{
			name: 'No',
			value: false,
		},
	];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await emailBaseUrl.post('/v1/newsletters', newsletter);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('NewsLetter sent');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};
	const handleEditorOnchange = (newText: any) => {
		setNewsLetter({ ...newsletter, content: newText });
	};

	return (
		<div>
			<Button
				type="primary"
				// shape="round"
				size="small"
				ghost
				onClick={() => setVisible(true)}
			>
				Edit
			</Button>
			<Modal
				title="Modify Newsletter"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Save
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Access Code :{' '}
						<Input
							placeholder="input access code"
							value={newsLetter?.access_code}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, access_code: e.target.value })
							}
						/>
					</p>
					<p>
						Subject :{' '}
						<Input
							placeholder="input Title"
							value={newsLetter?.subject}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, subject: e.target.value })
							}
						/>
					</p>
					<p>
						Select Country:{' '}
						<Select
							value={newsletter.countryCode}
							style={{ width: '150px' }}
							onChange={(value) =>
								setNewsLetter({
									...newsletter,
									countryCode: value,
								})
							}
						>
							{countryCode?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem.code}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						Preview Message :{' '}
						<Input
							placeholder="input preview message"
							value={newsLetter?.previewMessage}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, previewMessage: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={newsLetter?.bannerUrl}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, bannerUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://im.ge/upload"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://im.ge/upload
						</a>{' '}
						to Upload/generate banner url, Then select Direct Link to see the
						generated link
					</p>
					<p>
						<p>Delivery Type: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={newsLetter.instantProcessing}
							onChange={(e) => {
								setNewsLetter({ ...newsLetter, instantProcessing: e });
							}}
						>
							{instantProcessing?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<div>
						<p>Select MailList</p>
						<div>
							<Select
								size="large"
								placeholder="Select user type"
								value={newsletter?.maillist}
								onChange={(e: any) =>
									setNewsLetter({ ...newsletter, maillist: e })
								}
								style={{ width: '100%' }}
							>
								<Select.Option value="">--</Select.Option>
								{Object.entries(allMailList)?.map(([key, value1]) => (
									<Select.Option key={`${key}`} value={`${value1}`}>
										{key}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>

					{newsletter.countryCode.length > 0 && (
						<div>
							<p>Country Type</p>
							<Select
								value={newsletter.locationType}
								style={{ width: '100%' }}
								onChange={(locationType) =>
									setNewsLetter({ ...newsletter, locationType })
								}
							>
								<Select.Option value="">--</Select.Option>
								<Select.Option value="all">All Country Type</Select.Option>
								<Select.Option value="zone">Timezone</Select.Option>
								<Select.Option value="ip">IP Location</Select.Option>
							</Select>
						</div>
					)}

					<Divider />
					<p>
						<strong>Hi {`${'{{Name}}'}`}</strong>
					</p>
					<EditorComponent
						editorValue={newsletter?.content}
						editorOnchange={handleEditorOnchange}
					/>

					{/* <PreviewNewsLetter /> */}
					<SendTestNewsLetter newsletter={newsletter} typeOfEmail="text" />

					<div>
						<p>
							<strong>List Of Test Emails</strong>
						</p>
						<div>
							<Tag>cabafor@gmail.com</Tag>
							<Tag> mercyemmanuelotomowo@gmail.com</Tag>
							<Tag>ugochukwuaronu@gmail.com</Tag> <Tag>emekaece@gmail.com</Tag>
							<Tag>ifihear@gmail.com</Tag>
							<Tag>olowogbayigbenga@gmail.com</Tag>
							<Tag>ekwealorobinna112@gmail.com</Tag>
						</div>
					</div>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default EditNewsletter;
