import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const getTodaysStats = createAsyncThunk(
	'dashboard/getTodaysStats',
	async (x, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/dashboard/todays-stats');
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const getSavingsSum = createAsyncThunk(
	'dashboard/getSavingsSum',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/users/stable-currency/savings/sum/apy',
			{
				params,
			}
		);
		return res.data.data;
	}
);

const dashboardRx = createSlice({
	name: 'dashboard',
	initialState: {
		today: {
			users: { today: 0, total: 0 },
			flexible: { today: [] },
			fixed: { today: [] },
			stakes: { today: [] },
		},
		savingsSum: {
			apy: {
				currentXendApy: 0,
				combinedProtocolApy: 0,
			},
			totalDailyEarnings: {
				flexibleSavingsDailyEarning: 0,
				fixedSavingsDailyEarning: 0,
			},
			flexibleSavings: {
				totalBalanceUsdValue: 0,
				totalWithdrawn: 0,
			},
			fixedSavings: {
				totalCapital: 0,
				totalProfit: 0,
				income: 0,
				totalRewardTokenValue: 0,
				totalRewardTokenValueUsd: 0,
				rewardTokenSymbol: '',
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getTodaysStats.fulfilled, (store, action) => {
				const state = store;
				state.today = action.payload;
			})
			.addCase(getSavingsSum.fulfilled, (store, action) => {
				const state = store;
				state.savingsSum = action.payload;
			}),
});

export default dashboardRx.reducer;
