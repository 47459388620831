import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import { getOutDebitTransactions } from '../../methods/redux/reducers/transactions';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';
import OutDebitItem from './OutDebitItem';
import OutDebitSummary from './Summary';

const OutDebitTransaction = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const { outDebit } = useAppSelector((store) => store.transactions);
	const { user } = useAppSelector((store) => store.userProfile);
	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(outDebit.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getOutDebitTransactions({ ...queryData, userId }));
			} else {
				dispatch(getOutDebitTransactions(queryData));
			}
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	const blockchainSelect = blockchains.map((item: any) => ({
		name: item.abbreviation,
		value: item._id,
	}));

	return (
		<Card>
			<h1>
				Out Debit Transactions{' '}
				{userId && (
					<span>
						{' '}
						for{' '}
						<Link
							title="View Profile"
							to={`/user-profile/${user.profile?._id}`}
						>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>

			{!userId && <OutDebitSummary data={outDebit.result} />}

			<SearchFilter
				title="Search Out Debit Records"
				searchFilters={[
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Blockchain Network',
						name: 'blockchainNetworkId',
						htmlElement: 'select',
						selectOptions: blockchainSelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'Status',
						name: 'status',
						defaultSelectedValue: 'active',
						htmlElement: 'select',
						selectOptions: [
							{ name: 'Pending', value: 'pending' },
							{ name: 'Active', value: 'active' },
							{ name: 'Deleted', value: 'deleted' },
						],
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={outDebit.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={outDebit.paginator.itemCount}
				current={outDebit.paginator.currentPage}
				pageSize={outDebit.paginator.perPage}
			/>

			<br />

			{outDebit.itemsList.map((item: any) => (
				<OutDebitItem key={item._id} transaction={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={outDebit.paginator.itemCount}
				current={outDebit.paginator.currentPage}
				pageSize={outDebit.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default OutDebitTransaction;
