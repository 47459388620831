import { Card, Divider } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getAdminWalletBalance } from '../../methods/redux/reducers/general';
import { retrieveSingleSwapRequest } from '../../methods/redux/reducers/swap';
import { retrieveToken } from '../../methods/utils/auth';
import TransactionItem from '../../transactions/transaction-statments/TransactionItem';
import BlockchainProcessRequest from './BlockchainProcessRequest';
import ProcessSwapRequest from './ProcessSwapRequest';
import UnAssignSwapRequest from './UnAssignSwapRequest';

interface IStyle {
	statusColor: any;
}
const SingleSwapItemPageStyle = styled.div<IStyle>`
	& .indication {
		height: 5px;
		background-color: ${(p) => p.statusColor};
		margin-bottom: 20px;
	}

	& .details {
		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-bottom: 15px;
		}
	}

	& .label {
		color: #aaa;
		font-style: italic;
		font-size: 14px;
	}

	& .actions {
		margin-top: 10px;
		& > * {
			display: inline-block;
			margin-right: 20px;
		}
	}
`;

const SingleSwapItemPage = () => {
	const dispatch = useAppDispatch();

	const { swapItemId } = useParams();

	const { singleSwapRequest } = useAppSelector((store) => store.swap);

	const [state, setState] = useState({
		_id: '',
	});

	useEffect(() => {
		const auth = retrieveToken();
		setState({ ...state, ...auth.user });
	}, []);

	useEffect(() => {
		if (swapItemId) {
			dispatch(retrieveSingleSwapRequest({ swapTransactionId: swapItemId }));
		}
	}, [swapItemId]);

	useEffect(() => {
		if (
			state._id &&
			state._id.length > 0 &&
			singleSwapRequest.swapStatus === 'processing'
		) {
			dispatch(getAdminWalletBalance(state._id));
		}
	}, [state._id, singleSwapRequest.swapStatus]);

	const swapStatusColor = (status: any) => {
		switch (status) {
			case 'processing':
				return 'orange';
			case 'completed':
				return 'green';
			case 'hold':
				return 'maroon';
			default:
				return '#f3f3f3';
		}
	};

	return (
		<SingleSwapItemPageStyle
			statusColor={swapStatusColor(singleSwapRequest.swapStatus)}
		>
			<Card>
				<div className="indication" />
				<h1>
					{singleSwapRequest.baseCurrency.symbol} Swap for{' '}
					<Link to={`/user-profile/${singleSwapRequest.userId?._id}`}>
						@{singleSwapRequest.userId?.username}
					</Link>
				</h1>
				<p>{singleSwapRequest.swapStatus}</p>

				<Divider />

				<div className="details">
					{singleSwapRequest.swapStatus === 'hold' && (
						<div>
							<p>{singleSwapRequest.swapHoldReason}</p>
							<p className="label">Swap Hold Reason</p>
						</div>
					)}
					<div>
						<div style={{ display: 'flex' }}>
							<img
								width={20}
								height={20}
								style={{ marginRight: 5 }}
								src={singleSwapRequest.baseCurrency?.iconUrl}
								alt="swap fee token"
							/>
							<p>
								{singleSwapRequest.baseCurrencyAmount}{' '}
								{singleSwapRequest.baseCurrency.symbol}
							</p>
						</div>
						<p className="label">Base Currency</p>
					</div>
					<div>
						<div style={{ display: 'flex' }}>
							<img
								width={20}
								height={20}
								style={{ marginRight: 5 }}
								src={singleSwapRequest.baseCurrency?.iconUrl}
								alt="swap fee token"
							/>
							<p>
								{singleSwapRequest.baseConvertRequestAmount}{' '}
								{singleSwapRequest.baseCurrency.symbol}
							</p>
						</div>
						<p className="label">Base Convert Amount</p>
					</div>
					<div>
						<p>{singleSwapRequest.quoteCurrency.symbol}</p>
						<p className="label">Quote Currency</p>
					</div>
					<div>
						<p>
							{singleSwapRequest.resultQuoteCurrencyAmountLower}{' '}
							{singleSwapRequest.quoteCurrency.symbol}
						</p>
						<p className="label">Quote Lower Amount</p>
					</div>
					<div>
						<p>
							{singleSwapRequest.resultQuoteCurrencyAmountUpper}{' '}
							{singleSwapRequest.quoteCurrency.symbol}
						</p>
						<p className="label">Quote Amount (Upper)</p>
					</div>
					<div>
						<p>{singleSwapRequest.slippage}</p>
						<p className="label">Slippage</p>
					</div>
					<div>
						<p>{singleSwapRequest.transactionSpeed}</p>
						<p className="label">Transaction Speed</p>
					</div>
					{singleSwapRequest.swapProtocol && (
						<div>
							<p>{singleSwapRequest.swapProtocol?.name}</p>
							<p className="label">Swap Protocol</p>
						</div>
					)}
					{singleSwapRequest.swapFeeTokenId && (
						<div>
							<div style={{ display: 'flex' }}>
								<img
									width={20}
									height={20}
									style={{ marginRight: 5 }}
									src={singleSwapRequest.swapFeeTokenId?.iconUrl}
									alt="swap fee token"
								/>
								<p>{singleSwapRequest.swapFeeTokenId?.symbol}</p>
							</div>
							<p className="label">Swap Fee Token</p>
						</div>
					)}
					<div>
						{singleSwapRequest.processedByUserId ? (
							<Link
								to={`/user-profile/${singleSwapRequest.processedByUserId?._id}`}
							>
								@
								{singleSwapRequest.processedByUserId?.username ||
									singleSwapRequest.processedByUserId?.email}
							</Link>
						) : (
							'--'
						)}
						<p className="label">Processed By</p>
					</div>
					<div>
						<p>
							{moment(singleSwapRequest.createdAt).format(
								'MMM DD, YY / hh:mm:ss a'
							)}
						</p>
						<p className="label">Created At</p>
					</div>
				</div>

				{singleSwapRequest.swapStatus !== 'completed' && (
					<>
						<br />
						<Divider />

						<p>
							<strong>Actions</strong>
						</p>
						<br />
						<p style={{ color: 'var(--red-color)' }}>
							Note that the transaction fee for Blockchain Swap should not be
							more than{' '}
							<strong>
								{singleSwapRequest.swapData.transactionFeeInUSD} USD
							</strong>
						</p>

						<br />

						<div className="actions">
							<div>
								<ProcessSwapRequest request={singleSwapRequest} />
							</div>
							<div>
								<BlockchainProcessRequest request={singleSwapRequest} />
							</div>
							<div>
								<UnAssignSwapRequest
									request={singleSwapRequest}
									adminId={state._id}
								/>
							</div>
						</div>

						<br />
					</>
				)}
				<Divider />

				<p>
					<strong>Swap Data</strong>
				</p>
				<br />

				<div className="details">
					<div>
						<p>
							{singleSwapRequest.swapData.baseCurrencyBalance}{' '}
							{singleSwapRequest.baseCurrency.symbol}
						</p>
						<p className="label">Base Currency Balance</p>
					</div>
					<div>
						<p>
							{singleSwapRequest.swapData.transactionFeeInBaseCurrencyAmount}{' '}
							{singleSwapRequest.baseCurrency.symbol}
						</p>
						<p className="label">Transaction Fee (Base)</p>
					</div>
					<div>
						<p>
							{singleSwapRequest.swapData.transactionFeeInToken}{' '}
							{singleSwapRequest.swapData.feeTokenCurrencySymbol}
						</p>
						<p className="label">Transaction Fee in Token</p>
					</div>
					<div>
						<p>{singleSwapRequest.swapData.transactionFeeInUSD} USD</p>
						<p className="label">Transaction Fee in USD</p>
					</div>
					<div>
						<p>{singleSwapRequest.swapData.feeCurrencyBalance}</p>
						<p className="label">Fee Currency Balance</p>
					</div>
					<div>
						<p>{singleSwapRequest.swapUseTokenAsFee ? 'True' : 'False'}</p>
						<p className="label">Swap Token As Fee</p>
					</div>
					<div>
						<p>{singleSwapRequest.userSwapUseTokenAsFee ? 'True' : 'False'}</p>
						<p className="label">User Swap Token As Fee</p>
					</div>
					<div>
						<p>{singleSwapRequest.swapData.routeText}</p>
						<p className="label">Route</p>
					</div>
					<div>
						<p>{singleSwapRequest.swapData.details}</p>
						<p className="label">Details</p>
					</div>
				</div>

				<Divider />

				<div>
					<p>
						<strong>Swap Fee Details</strong>
					</p>

					<div className="details">
						<div>
							<p>{singleSwapRequest.feeDiscountRule}</p>
							<p className="label">Fee Discount Rule</p>
						</div>
						<div>
							<p>{singleSwapRequest.feeDiscountPercentage}%</p>
							<p className="label">Fee Discount Percentage</p>
						</div>
						<div>
							<p>{singleSwapRequest.gasFeeInNativeToken}</p>
							<p className="label">Gas Fee</p>
						</div>
						<div>
							<p>{singleSwapRequest.gasFeeInUSD} USD</p>
							<p className="label">Gas Fee</p>
						</div>
						<div>
							<p>
								{singleSwapRequest.liquidityProviderFeeInBaseCurrency}{' '}
								{singleSwapRequest.baseCurrency?.symbol}
							</p>
							<p className="label">Liquidity Provider Fee</p>
						</div>
						<div>
							<p>
								{Number(singleSwapRequest.swapData.transactionFeeInUSD) -
									Number(singleSwapRequest.gasFeeInUSD)}{' '}
								USD
							</p>
							<p className="label">Fee Difference</p>
						</div>
					</div>
				</div>

				<Divider />

				<p>
					<strong>Associated Transaction Statements</strong>
				</p>
				{singleSwapRequest.associatedSwapTransactions.map(
					(transaction: any) => (
						<TransactionItem key={transaction._id} transaction={transaction} />
					)
				)}
			</Card>
		</SingleSwapItemPageStyle>
	);
};

export default SingleSwapItemPage;
