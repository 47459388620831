import api from './api';
import { ResponseStructure } from './req-res-types';

const newsletterService = api.injectEndpoints({
	endpoints: (build) => ({
		getMailingList: build.query<ResponseStructure, {}>({
			query: () => ({
				url: '/admin/newsletter/mailing-lists',
			}),
		}),
		createMailingList: build.mutation<
			unknown,
			{ email: any; description: any }
		>({
			query: (body) => ({
				url: '/admin/newsletter/mailing-lists',
				method: 'POST',
				body,
			}),
		}),
		addSingleSubscriber: build.mutation<
			unknown,
			{ fullName: any; email: any; listIds: any[] }
		>({
			query: (body) => ({
				url: '/admin/newsletter/subscribers',
				method: 'POST',
				body,
			}),
		}),
		getNewsletterCampaigns: build.query({
			query: () => ({
				url: '/admin/newsletter/campaigns',
			}),
		}),
		createNewsletterCampaigns: build.mutation({
			query: () => ({
				url: '/admin/newsletter/campaigns',
				method: 'POST',
			}),
		}),
		sendNewsletterCampaigns: build.mutation<unknown, { campaignId: any }>({
			query: ({ campaignId }) => ({
				url: `/admin/newsletter/campaigns/${campaignId}/send`,
				method: 'POST',
			}),
		}),
	}),
});

export const {
	useGetMailingListQuery,
	useCreateMailingListMutation,
	useAddSingleSubscriberMutation,
	useCreateNewsletterCampaignsMutation,
	useSendNewsletterCampaignsMutation,
} = newsletterService;

export default newsletterService;
