import { Tag } from 'antd';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { useAppSelector } from '../../methods/redux/hooks';

const StatusCountStyle = styled.div`
	& > * {
		padding: 7px 15px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
`;

const SwapStatusCount = () => {
	const { swapCount } = useAppSelector((store: any) => store.swap);

	return (
		<StatusCountStyle>
			<Tag color="orange">
				<p>Pending</p>
				<p>
					<NativeNumber value={swapCount.pending} />
				</p>
			</Tag>
			<Tag color="maroon">
				<p>Hold</p>
				<p>
					<NativeNumber value={swapCount.hold || 0} />
				</p>
			</Tag>
			<Tag color="blue">
				<p>Processing</p>
				<p>
					<NativeNumber value={swapCount.processing} />
				</p>
			</Tag>
			<Tag color="green">
				<p>Completed</p>
				<p>
					<NativeNumber value={swapCount.completed} />
				</p>
			</Tag>
			<Tag color="gray">
				<p>Cancelled</p>
				<p>
					<NativeNumber value={swapCount.cancelled} />
				</p>
			</Tag>
		</StatusCountStyle>
	);
};

export default SwapStatusCount;
