import { Button, Input, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';
import { SavingsSettingsStyle } from './SavingsSettings';

const GeneralSavingsSettings = () => {
	const dispatch = useAppDispatch();

	const settings = useAppSelector((store) => store.internalSettings);

	const [general, setGeneralSettings] = useState({
		minStableCurrencySavingAmounts: 0,
		maxStableCurrencySavingAmounts: 0,
		updateButtonDisabled: true,
	});

	useEffect(() => {
		setGeneralSettings({ ...general, ...settings, updateButtonDisabled: true });
	}, [settings]);

	const updateSettings = () => {
		const { minStableCurrencySavingAmounts, maxStableCurrencySavingAmounts } =
			general;
		dispatch(
			updateInternalSettings({
				settingsId: settings._id,
				minStableCurrencySavingAmounts,
				maxStableCurrencySavingAmounts,
			})
		);
	};

	return (
		<SavingsSettingsStyle>
			<p>
				<strong>General Savings</strong>
			</p>
			<div className="lined-settings">
				<div>
					<Tooltip title="The minimum amount of token allowable for flexible or fixed savings">
						<p className="label">
							<span>Minimum Amount</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						addonBefore="$"
						onChange={(e) =>
							setGeneralSettings({
								...general,
								updateButtonDisabled: false,
								minStableCurrencySavingAmounts: Number(e.target.value),
							})
						}
						value={general.minStableCurrencySavingAmounts}
					/>
				</div>
				<div>
					<p className="label">Maximum Amount</p>
					<Input
						addonBefore="$"
						onChange={(e) =>
							setGeneralSettings({
								...general,
								updateButtonDisabled: false,
								maxStableCurrencySavingAmounts: Number(e.target.value),
							})
						}
						value={general.maxStableCurrencySavingAmounts}
					/>
				</div>
			</div>
			<div>
				<Button
					type="primary"
					onClick={updateSettings}
					disabled={general.updateButtonDisabled}
				>
					Update General Savings Settings
				</Button>
			</div>
		</SavingsSettingsStyle>
	);
};

export default GeneralSavingsSettings;
