import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LockSavings from '../../compliance/locked-savings/LockSavings';

interface IFlexiSavingsProfileItem {
	profile: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .details {
		margin-top: 5px;
		border-top: 1px dashed #ccc;
		text-transform: capitalize;
		& > * {
			display: inline-block;
			padding: 5px;

			& .label {
				color: #aaa;
				font-size: 12px;
			}
		}
	}
`;

const FlexiSavingsProfileItem = ({ profile }: IFlexiSavingsProfileItem) => (
	<Style>
		<div>
			<p>
				<Link
					to={`/core-modules/flexible-savings/single/${profile._id}`}
					title="View Single Savings Profile"
				>
					{profile.title} ({profile.currencyId?.symbol})
				</Link>
			</p>
		</div>
		<div className="details">
			<div>
				<Link
					to={`/user-profile/${profile.userId?._id}`}
					title="View User Profile"
				>
					@{profile.userId?.username}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>
					{profile.availableBalance} {profile.currencyId?.symbol}
				</p>
				<p className="label">Available Balance</p>
			</div>
			<div>
				<p>{profile.status}</p>
				<p className="label">Status</p>
			</div>
			<div>
				<p>
					{profile.dailyProfitMakerLastBuildDate
						? moment(profile.dailyProfitMakerLastBuildDate).format(
								'MMM DD, YY / hh:mm:ss a'
						  )
						: '--'}
				</p>
				<p className="label">Daily Profit Maker Last Build Date</p>
			</div>
			<div>
				<p>{moment(profile.startDate).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Start Date</p>
			</div>
			<div>
				<p>
					{profile.totalWithdrawn} {profile.currencyId?.symbol}
				</p>
				<p className="label">Total Withdrawn</p>
			</div>
			<div>
				<p>@{profile.lastUpdatedByUserId?.username}</p>
				<p className="label">Last Updated By</p>
			</div>
			<div>
				<LockSavings
					id={profile?._id}
					text="flexible"
					url="admin/compliance/lock-flexible-savings"
				/>
			</div>
		</div>
	</Style>
);

export default FlexiSavingsProfileItem;
