import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

interface IColumnItems {
	data: any;
}

const Style = styled(Link)`
	all: unset;
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
	cursor: pointer;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const ColumnItem = ({ data }: IColumnItems) => (
	<Style to="">
		<div>
			<p>
				{data?.userId?.email ||
					data?.userId?.username ||
					data?.userId?.fullName ||
					'--'}
			</p>
			<p className="label">user/Receiver</p>
		</div>
		<div>
			<p>
				{commas(data?.amount) || '---'} {data?.currencyId?.symbol}
			</p>
			<p className="label">Type</p>
		</div>
		<div>
			<p>{data?.comment || '---'}</p>
			<p className="label">Comment</p>
		</div>
		<div>
			<p>{data?.blockchainNetworkId?.name || '---'}</p>
			<p className="label">Network</p>
		</div>
		<div>
			<p>{data?.transactionHash || '---'}</p>
			<p className="label">Transaction Hash</p>
		</div>
		<div>
			<p>{data?.transactionStatementId || '---'}</p>
			<p className="label">Transaction statement id</p>
		</div>
		<div>
			<p>{data?.inCreditStatementId || '---'}</p>
			<p className="label">InCredit statement id</p>
		</div>
		<div>
			<p>
				{data?.processedByAdminId?.email || data?.processedByAdminId?.fullName}
			</p>
			<p className="label">Sender</p>
		</div>
		<div>
			<p>
				{data.createdAt
					? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Date created</p>
		</div>
	</Style>
);
export default ColumnItem;
