function remakeQueryString(
	query: any,
	currentQueryInUrlBar: any,
	setSearchParams: Function,
	action?: string
) {
	const currentQuery = Object.fromEntries(currentQueryInUrlBar.entries());

	let mainQuery: any = {};

	if (action && action === 'remove') {
		for (let i = 0; i < Object.keys(query).length; i += 1) {
			mainQuery[Object.keys(query)[i]] = query[Object.keys(query)[i]];
		}
		setSearchParams(mainQuery);
	} else {
		mainQuery = currentQuery;

		for (let i = 0; i < Object.keys(query).length; i += 1) {
			mainQuery[Object.keys(query)[i]] = query[Object.keys(query)[i]];
		}
		setSearchParams(mainQuery);
	}
}

export default remakeQueryString;
