import { Button, Modal, Tag } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import UpdateRole from './UpdateRole';

interface IRoleItemStyle {
	active: boolean;
}

const RoleItemStyle = styled.div<IRoleItemStyle>`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& > * {
		min-width: 100px;
	}

	& .label {
		font-size: 12px;
		color: #aaa;
	}

	& .title {
		display: flex;
		align-items: center;

		& .material-icons {
			font-size: 11px;
			margin-right: 10px;
			color: ${(p) => (p.active ? 'green' : 'red')};
		}

		& p {
			margin: 0;
			padding: 0;

			&.name {
				text-transform: capitalize;
				font-weight: 600;
			}

			&.slug {
				text-transform: uppercase;
				font-size: 10px;
			}
		}
	}
`;

interface IRoleItem {
	role: any;
}
const RoleItem = ({ role }: IRoleItem) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<RoleItemStyle active={role.isActive}>
				<div className="title">
					<div>
						<span className="material-icons">circle</span>
					</div>
					<div>
						<p className="name">{role.title}</p>
						<p className="slug">{role.slug}</p>
					</div>
				</div>
				<div>
					<p>{role.description}</p>
					<p className="label">Description</p>
				</div>
				<div>
					<Button type="link" onClick={() => setVisible(true)}>
						{role.permissions?.length || 0} Permissions
					</Button>
				</div>
				<div>
					<p>{moment(role.updatedAt).format('lll')}</p>
					<p className="label">Last Updated</p>
				</div>
				<UpdateRole data={role} />
			</RoleItemStyle>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title={`${role.title} Permissions`}
				footer={null}
			>
				{role?.permissions?.map((permission: any) => (
					<Tag key={permission} style={{ display: 'inline-block' }}>
						{permission}
					</Tag>
				))}
			</Modal>
		</>
	);
};

export default RoleItem;
