import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const getStakingProfiles = createAsyncThunk(
	'staking/getStakingProfiles',
	async (status: string) => {
		const res = await baseuri.get('/admin/staking/staking-profile', {
			params: { status },
		});
		const profiles = res.data.data?.itemsList;
		if (profiles) {
			return profiles;
		}
		return [];
	}
);

export const createStakingProfile = createAsyncThunk(
	'staking/createStakingProfile',
	async (data: any) => {
		const res = await baseuri.post('/admin/staking/staking-profile', data);
		return res.data.data;
	}
);

export const editStakingProfile = createAsyncThunk(
	'staking/editStakingProfileInactive',
	async (data: any) => {
		const res = await baseuri.put('/admin/staking/staking-profile', data);
		return res.data.data;
	}
);

export const retrieveUserStakings = createAsyncThunk(
	'staking/retrieveUserStakings',
	async (params: any) => {
		const res = await baseuri.get('/admin/staking/user-stakings', { params });
		return res.data.data;
	}
);
export const getStakingReport = createAsyncThunk(
	'staking/getStakingReport',
	async (params: any) => {
		const res = await baseuri.get('/report/staking/total', { params });
		return res.data.data;
	}
);
export const getPaidOutStakingReport = createAsyncThunk(
	'staking/getPaidOutStakingReport',
	async (params: any) => {
		const res = await baseuri.get('/report/staking/paidout', { params });
		return res.data.data;
	}
);

export const retrieveUserStakingsCount = createAsyncThunk(
	'staking/retrieveUserStakingsCount',
	async (params: any) => {
		const res = await baseuri.get('/admin/staking/user-stakings-count', {
			params,
		});
		return res.data.data;
	}
);

export const retrievePendingStakings = createAsyncThunk(
	'staking/retrievePendingStakings',
	async () => {
		const res = await baseuri.get('/admin/staking/pending-stakings');
		return res.data.data;
	}
);

export const retrieveTotalAggeragtedStakings = createAsyncThunk(
	'staking/retrieveTotalAggeragtedStakings',
	async () => {
		const res = await baseuri.get('/report/activeStakings');
		return res.data.data;
	}
);

const stakingRx = createSlice({
	name: 'staking',
	initialState: {
		profiles: [],
		loading: false,
		pendingStaking: [],
		stakings: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		stakingsCount: {
			pending: 0,
			staked: 0,
			completed: 0,
			deleted: 0,
		},
		stakingReport: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
			record: [],
		},
		paidOutStakingReport: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
			result: [],
		},
		stakingSum: {
			details: {
				itemsList: [],
				paginator: { currentPage: 1, itemCount: 0, perPage: 20 },
			},
			result: [],
		},
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getStakingProfiles.fulfilled, (store, action) => {
				const state = store;
				state.profiles = action.payload;
			})
			.addCase(getStakingReport.fulfilled, (store, action) => {
				const state = store;
				state.stakingReport = action.payload;
			})
			.addCase(getPaidOutStakingReport.fulfilled, (store, action) => {
				const state = store;
				state.paidOutStakingReport = action.payload;
			})
			.addCase(createStakingProfile.pending, (store) => {
				const state = store;
				state.loading = true;
			})
			.addCase(createStakingProfile.rejected, (store) => {
				const state = store;
				state.loading = false;
			})
			.addCase(createStakingProfile.fulfilled, (store) => {
				const state = store;
				state.loading = false;
			})
			.addCase(retrieveUserStakings.fulfilled, (store, action) => {
				let state = store;
				state = { ...state, stakings: { ...action.payload } };
				return state;
			})
			.addCase(retrievePendingStakings.fulfilled, (store, action) => {
				const state = store;
				state.pendingStaking = action.payload;
			})
			.addCase(retrieveUserStakingsCount.fulfilled, (store, action) => {
				const state = store;
				state.stakingsCount = action.payload;
			})
			.addCase(editStakingProfile.fulfilled, (store, action) => {
				let state = store;
				const updatedProfiles: any = state.profiles.map((item: any) => {
					if (item._id === action.payload?._id) {
						return { ...item, ...action.payload };
					}
					return item;
				});
				state = { ...state, profiles: updatedProfiles };
				return state;
			})
			.addCase(retrieveTotalAggeragtedStakings.fulfilled, (store, action) => {
				const state = store;
				state.stakingSum = action.payload;
			});
	},
});

export default stakingRx.reducer;
