import { Card } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getUserReferralOverview } from '../../../methods/redux/reducers/user-profile';

export const DetailsSection = styled.div`
	font-weight: 600;

	& > * {
		padding: 5px 20px 5px 1px;
		display: inline-block;
	}

	& .label {
		color: #c5c5c5;
		font-weight: 400;
	}
`;

const UserReferralSection = () => {
	const dispatch = useAppDispatch();

	const { user, referralOverview } = useAppSelector(
		(store) => store.userProfile
	);

	useEffect(() => {
		if (user.profile?._id) {
			dispatch(getUserReferralOverview({ userId: user.profile?._id }));
		}
	}, [user]);

	return (
		<Card>
			<p>
				<strong>Referral Overview</strong>
			</p>
			<p>
				@{user.profile?.username} was referred by{' '}
				{user.profile?.refereeName ? (
					<Link
						title="Search Referee"
						to={`/search?q=${user.profile?.refereeName}`}
					>
						@{user.profile?.refereeName}
					</Link>
				) : (
					'--'
				)}
			</p>
			<br />
			<DetailsSection>
				<div className="infoItem">
					<p>
						<strong>{referralOverview.totalReferrals}</strong>
					</p>
					<p className="label">Total referrals</p>
				</div>
				<div className="infoItem">
					<p>
						<strong>{referralOverview.unverified}</strong>
					</p>
					<p className="label">Unverified</p>
				</div>
				<div className="infoItem">
					<p>
						<strong>{referralOverview.numOfUniqueDeviceIds}</strong>
					</p>
					<p className="label">Unique Referrals</p>
				</div>
			</DetailsSection>

			<div>
				<Link to={`/reports/referral/${user.profile?._id}`}>
					View Referral Report
				</Link>
			</div>
		</Card>
	);
};

export default UserReferralSection;
