import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	CloseCircleOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { Card, message, Pagination, Space, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MyDateSelector from '../../../component/MyDateSelector';
import TableHeaderSection from '../../../component/TableHeaderSection';
import baseuri from '../../../methods/baseuri';
import { statusTag } from '../../../methods/utils/ReactUtils';
import { MyEmptyTable } from '../../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../../users/users-list/UsersByCountry';
import { MyTable } from '../../../users/users-list/UsersList';
import QueueFilters from './QueueFilters';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const WithdrawQueue = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const columns = [
		// {
		// 	key: 98,
		// 	title: '',
		// 	render: (x: any) => <Selection data={x} selectChange={rowSelectChange} />,
		// },
		{
			key: 0,
			title: 'Name',
			render: (x: any) => (
				<>
					<p>
						<Link to={`/user-profile/${x.userId?._id}`} title="View Profile">
							@{x.userId?.username}
						</Link>
					</p>
					<p>
						<Link to={`/user-profile/${x?.userId?._id}`} title="View Profile">
							{x?.userId?.email}
						</Link>
					</p>
				</>
			),
		},
		// {
		// 	key: 1,
		// 	title: "Email",
		// 	render: (x: any) => x.walletId?.userId?.email
		// },
		{
			key: 2,
			title: 'Status',
			dataIndex: 'status',
			render: (x: any) => <span>{statusTag(x)}</span>,
		},
		{
			key: 3,
			title: 'Amount',
			dataIndex: 'amount',
			render: (amnt: any) => `${amnt} BUSD`,
		},
		{
			key: 99,
			title: 'Processed By',
			dataIndex: 'processList',
			render: (text: any) => <p>{text?.creator?.email}</p>,
		},
		// {
		// 	key: 4,
		// 	title: 'Date Updated',
		// 	dataIndex: 'updatedAt',
		// 	render: (x: any) => (
		// 		<DateSection>
		// 			{' '}
		// 			{moment(x).format('lll')} <strong>{format(x)}</strong>
		// 		</DateSection>
		// 	),
		// },
		{
			key: 49,
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('lll'),
		},
		// {
		// 	key: 9,
		// 	title: '',
		// 	render: (x: any) => <UpdateQueueItem item={x} updateItem={setToDone} />,
		// },
		// {
		// 	key: 10,
		// 	title: '',
		// 	render: (x: any) => <CancelWithdrawRequest data={x} />,
		// },
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			startDate: `${
				searchParams.get('startDate') ||
				moment('2021/10/01').format('YYYY-MM-DD')
			}`,
			endDate: `${
				searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			}`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};
	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get('admin/wallet/withdraw-queue-filter', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({
					...data,
					paginator: res.data.data,
					itemsList: res.data.data.records,
				});
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSearchByDate();
	}, [location.search]);
	return (
		<UsersListPage>
			<QueueFilters />
			<MainSection>
				<MyDateSelector />
				<br />
				<Space>
					<Tag icon={<ClockCircleOutlined spin />} color="default">
						Pending (0)
					</Tag>
					<Tag icon={<SyncOutlined spin />} color="processing">
						Processing (0)
					</Tag>
					<Tag icon={<CheckCircleOutlined />} color="success">
						Completed (0)
					</Tag>
					<Tag icon={<CloseCircleOutlined />} color="error">
						Cancelled (0)
					</Tag>
				</Space>
				<MyTableCard>
					<TableHeaderSection
						tableName="Withdrawal Queue"
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
						downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
					/>

					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.itemsList}
						pagination={false}
					/>

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default WithdrawQueue;
