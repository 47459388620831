import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const getFixedSavings = createAsyncThunk(
	'fixed-savings/getFixedSavings',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/savings/stable-currency/fixed', {
			params,
		});
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const retrieveFixedSavingsItems = createAsyncThunk(
	'fixed-savings/retrieveFixedSavingsItems',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/items',
			{
				params,
			}
		);
		return res.data.data;
	}
);

export const retrieveFixedSavingsActiveTerminationRequests = createAsyncThunk(
	'fixed-savings/retrieveFixedSavingsActiveTerminationRequests',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/active-termination-requests',
			{
				params,
			}
		);
		return res.data.data;
	}
);
export const retrieveTopSavers = createAsyncThunk(
	'fixed-savings/retrieveTopSavers',
	async (params: any) => {
		const res = await baseuri.get('/data/topsaver', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveFixedSavingsPendingPayouts = createAsyncThunk(
	'fixed-savings/retrieveFixedSavingsPendingPayouts',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/pending-payout',
			{
				params,
			}
		);
		return res.data.data;
	}
);

export const retrieveFixedSavingsPendingProfitBuild = createAsyncThunk(
	'fixed-savings/retrieveFixedSavingsPendingProfitBuild',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/pending-profit-build',
			{
				params,
			}
		);
		return res.data.data;
	}
);

export const retrieveExpiringFixedSavings = createAsyncThunk(
	'fixed-savings/retrieveExpiringFixedSavings',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/expiring',
			{
				params,
			}
		);
		return res.data.data;
	}
);
export const getPaidOutFixedInterest = createAsyncThunk(
	'fixed-savings/getPaidOutFixedInterest',
	async (params: any) => {
		const res = await baseuri.get('/report/savings/fixed/interest/paidout', {
			params,
		});
		return res.data.data;
	}
);
export const getUnpaidFixedInterest = createAsyncThunk(
	'fixed-savings/getUnpaidFixedInterest',
	async (params: any) => {
		const res = await baseuri.get('/report/savings/fixed/interest/notpaid', {
			params,
		});
		return res.data.data;
	}
);
export const getFixedTerminationRequests = createAsyncThunk(
	'fixed-savings/getFixedTerminationRequests',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/active-termination-requests',
			{
				params,
			}
		);
		return res.data.data;
	}
);

export const retrieveExpiringFixedSavingsByDate = createAsyncThunk(
	'fixed-savings/retrieveExpiringFixedSavingsByDate',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/expiring-date',
			{
				params,
			}
		);
		return res.data.data;
	}
);

export const approveTerminationRequest = async (data: any) => {
	try {
		const res: any = await baseuri.post(
			'/admin/savings/stable-currency/fixed/approve-termination-requests',
			data
		);

		message.success(res?.data?.message);
		return true;
	} catch (e: any) {
		message.error(
			`Approve Termination Request Failed. ${e?.response?.data?.error_details.toString()}`
		);
		return false;
	}
};

export const cancelTerminationRequest = async (data: any) => {
	try {
		const res: any = await baseuri.post(
			'/admin/savings/stable-currency/fixed/cancel-termination-requests',
			data
		);
		message.success(res?.data?.message);
		return true;
	} catch (e: any) {
		message.error(
			`Cancel Termination Request Failed. ${e?.response?.data?.error_details.toString()}`
		);
		return false;
	}
};

const fixedSavingsRx = createSlice({
	name: 'fixed-savings',
	initialState: {
		fixedItems: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		},
		fixedSavingsProfile: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		},
		terminationRequests: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		},
		pendingPayout: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		},
		expiring: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		},
		pendingProfitBuild: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
		},
		paidOut: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
			result: [],
		},
		unpaid: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
			result: [],
		},
		// terminationRequest: {
		// 	itemsList: [],
		// 	paginator: {
		// 		perPage: 20,
		// 		itemCount: 0,
		// 		currentPage: 1,
		// 	},
		// },
		topSavers: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
			record: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getFixedSavings.fulfilled, (store, action) => {
				const state = store;
				state.fixedSavingsProfile = action.payload;
			})
			.addCase(retrieveFixedSavingsItems.fulfilled, (store, action) => {
				const state = store;
				state.fixedItems = action.payload;
			})
			.addCase(
				retrieveFixedSavingsPendingPayouts.fulfilled,
				(store, action) => {
					const state = store;
					state.pendingPayout = action.payload;
				}
			)
			.addCase(getFixedTerminationRequests.fulfilled, (store, action) => {
				const state = store;
				state.terminationRequests = action.payload;
			})
			.addCase(retrieveExpiringFixedSavings.fulfilled, (store, action) => {
				const state = store;
				state.expiring = action.payload;
			})
			.addCase(
				retrieveExpiringFixedSavingsByDate.fulfilled,
				(store, action) => {
					const state = store;
					state.expiring = action.payload;
				}
			)
			.addCase(getPaidOutFixedInterest.fulfilled, (store, action) => {
				const state = store;
				state.paidOut = action.payload;
			})
			.addCase(getUnpaidFixedInterest.fulfilled, (store, action) => {
				const state = store;
				state.unpaid = action.payload;
			})
			.addCase(
				retrieveFixedSavingsPendingProfitBuild.fulfilled,
				(store, action) => {
					const state = store;
					state.pendingProfitBuild = action.payload;
				}
			)
			.addCase(retrieveTopSavers.fulfilled, (store, action) => {
				const state = store;
				state.topSavers = action.payload;
			}),
});

export default fixedSavingsRx.reducer;
