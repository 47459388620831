import { Card, message, Radio } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MyDateSelector from '../../component/MyDateSelector';
import baseuri from '../../methods/baseuri';
import { commas } from '../../methods/utils/general-utils';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';
import MainHeaderSection from './MainHeaderSection';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const TopSection = styled.div`
	display: flex;
	gap: 2em;
	position: relative;
	align-items: flex-end;
	justify-content: space-between;
`;

const TransactionPerUser = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	} as any);

	const columns = [
		{
			title: 'User',
			dataIndex: 'username',
			width: 150,
			render: (text: any) => <p>{text}</p>,
		},
		{
			title: 'No. of Txs',
			dataIndex: 'transactionCount',
			width: 100,
			render: (text: any) => <strong>{text}</strong>,
		},
		{
			title: 'Total Amount',
			dataIndex: 'totalTransactionAmount',
			width: 100,
			render: (text: any) => <strong>{commas(text)}</strong>,
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			startDate: `${
				searchParams.get('startDate') ||
				moment('2021/10/01').format('YYYY-MM-DD')
			}`,
			endDate: `${
				searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			}`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};
	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get('report/transaction/peruser', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData(res.data.data);
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSearchByDate();
	}, [location.search]);
	return (
		<UsersListPage>
			<MainSection>
				<TopSection>
					<MyDateSelector />

					<Radio.Group
						onChange={({ target: { value } }) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								transactionForm: value,
							});
						}}
						value={searchParams.get('transactionForm') || 'fixed_savings'}
					>
						{/* <Radio value="">ALL</Radio> */}
						<Radio value="flexible_savings">FLEXIBLE</Radio>
						<Radio value="fixed_savings">FIXED</Radio>
					</Radio.Group>
					<Link
						to={`/reports/transactions-per-user-old?transactionState=8&startDate=${moment()
							.subtract(2, 'day')
							.format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}
					>
						View Old Txs per User(Deprecating)
					</Link>
				</TopSection>

				<MyTableCard>
					<MainHeaderSection
						tableName="Transactions Per User"
						data={data.Query}
						totalAmount={data?.totalTransactionAmount || 0}
						// downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
					/>

					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.Query}
						pagination={false}
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default TransactionPerUser;
