import styled from 'styled-components';
import { Pagination, Card, Divider } from 'antd';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getFlexibleSavings,
	getFlexibleSavingsProfiles,
	getPendingProfitBuild,
} from '../../methods/redux/reducers/flexible-savings';
import FlexiSavingsProfileItem from './ProfileItem';
import SearchFilter from '../../component/Filter';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import { getSavingsSum } from '../../methods/redux/reducers/dashboard';
import { commas } from '../../methods/utils/general-utils';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const FlexibleSavingsProfilesPageStyle = styled.div`
	& .notice {
		font-weight: bold;
		color: red;
	}

	& .report {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;

		& .available-balance,
		& .withdrawn-balance,
		& .estimated-payout {
			font-weight: 600;
			font-size: 18px;
		}

		& .estimated-payout {
			color: #1a846d;
		}
		& .withdrawn-balance {
			color: #d52a4f;
		}
	}

	& .link-to {
		display: inline-block;
		margin-right: 20px;
		text-decoration: underline;
	}
`;

const FlexibleSavingsProfilesPage = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();

	const { profiles, paginator, pendingProfitBuild } = useAppSelector(
		(store) => store.flexibleSavings
	);
	const { currencies } = useAppSelector((store) => store.currencies);
	const { savingsSum } = useAppSelector((store) => store.dashboard);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'active',
				pageId: '1',
				perPage: String(profiles.paginator.perPage),
			});
		}

		if (!userId) {
			dispatch(getSavingsSum({}));
			dispatch(getPendingProfitBuild({}));
		}
		dispatch(getCurrencies({}));

		if (userId) {
			dispatch(getSavingsSum({ userId }));
			dispatch(getUserProfileById(userId));
			dispatch(getPendingProfitBuild({ userId }));
		}
		dispatch(getFlexibleSavings({ migrateStatus: 'pending' }));
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getFlexibleSavingsProfiles({ ...queryData, userId }));
			} else {
				dispatch(getFlexibleSavingsProfiles({ ...queryData }));
			}
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
			dispatch(getSavingsSum({ userId }));
		}
	}, [userId]);

	const currencySelect = currencies.map((item: any) => {
		if (item.allowSavings) {
			return {
				name: `${item.symbol} *`,
				value: item._id,
			};
		}
		return {
			name: item.symbol,
			value: item._id,
		};
	});

	return (
		<FlexibleSavingsProfilesPageStyle>
			<Card>
				<h1>
					Flexible Savings{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile?._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>

				<div className="report">
					<Card>
						<p>Estimated Payout Today</p>
						<p className="estimated-payout">
							{commas(
								savingsSum.totalDailyEarnings.flexibleSavingsDailyEarning
							)}{' '}
							USD
						</p>
					</Card>
					<Card>
						<p>Total Available Balance</p>
						<p className="available-balance">
							{commas(savingsSum.flexibleSavings.totalBalanceUsdValue)} USD
						</p>
					</Card>
					<Card>
						<p>Total Withdrawn</p>
						<p className="withdrawn-balance">
							{commas(savingsSum.flexibleSavings.totalWithdrawn)} USD
						</p>
					</Card>
				</div>

				<br />

				<Link
					title="Flexible Topup Savings Items"
					className="link-to"
					to={`/core-modules/flexible-savings/top-ups${
						userId ? `/${userId}` : ''
					}`}
				>
					Goto Top up Items
				</Link>
				<Link
					title="View Daily Earnings Page"
					className="link-to"
					to="/core-modules/flexible-savings/daily-earnings"
				>
					Daily Earnings
				</Link>
				<Link
					title="Go To Report Page"
					className="link-to"
					to="/report/flexible-savings"
				>
					View Report
				</Link>
				<Link
					title="Migration Logs"
					className="link-to"
					to="/core-modules/flexible-savings/migration-logs"
				>
					Migration Logs
				</Link>

				<br />
				<br />

				<div>
					<div>
						<Link
							to="/core-modules/flexible-savings/top-ups?migrateStatus=pending"
							className={`${Number(paginator.itemCount) > 0 ? 'notice' : ''}`}
						>
							We have {paginator.itemCount} unmigrated flexible topup items
						</Link>
					</div>
					<div>
						<Link
							to={`/core-modules/flexible-savings/pending-profit-build${
								userId ? `/${userId}` : ''
							}`}
							className={`${
								Number(pendingProfitBuild.paginator.itemCount) > 0
									? 'notice'
									: ''
							}`}
						>
							We have {pendingProfitBuild.paginator.itemCount} pending profit
							build
						</Link>
					</div>
				</div>

				<Divider />

				<SearchFilter
					title="Search Flexible Savings Profiles"
					searchFilters={[
						{
							label: 'Status',
							htmlElement: 'select',
							defaultSelectedValue: 'active',
							name: 'status',
							selectOptions: [
								{ name: 'Active', value: 'active' },
								{ name: 'Terminated', value: 'terminated' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={profiles.paginator.itemCount}
					current={profiles.paginator.currentPage}
					pageSize={profiles.paginator.perPage}
				/>

				<br />

				<p>
					Total of <strong>{profiles.paginator.itemCount} items</strong> found
				</p>
				<Divider />

				{profiles.itemsList.map((item: any) => (
					<FlexiSavingsProfileItem profile={item} />
				))}

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={profiles.paginator.itemCount}
					current={profiles.paginator.currentPage}
					pageSize={profiles.paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>
		</FlexibleSavingsProfilesPageStyle>
	);
};

export default FlexibleSavingsProfilesPage;
