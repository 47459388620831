import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
	Chart as Chartjs,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal } from 'antd';
import { getFlexibleSavingsDailyEarnings } from '../../methods/redux/reducers/flexible-savings';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { commas } from '../../methods/utils/general-utils';

Chartjs.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const Style = styled.div`
	& .section-head {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}
`;

const EarnItemStyle = styled.div`
	background-color: #f8f8f8;
	padding: 15px 5px;
	margin-bottom: 10px;

	& > * {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 10px;

		& .label {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const DailyEarnings = () => {
	const dispatch = useAppDispatch();
	const params = useParams();

	const [visible, setVisible] = useState(false);

	const { savingsDailyEarns } = useAppSelector(
		(store) => store.flexibleSavings
	);

	useEffect(() => {
		dispatch(
			getFlexibleSavingsDailyEarnings({
				flexibleSavingsId: params?.flexibleSavingsId,
			})
		);
	}, [params]);

	const uniqueDates = savingsDailyEarns.itemsList
		.map((item: any) => moment(item.earnDate).format('YYYY-MM-DD'))
		.map((d) => new Date(d))
		.sort((d1: any, d2: any) => d1 - d2);
	// .filter((value, index, self) => self.indexOf(value) === index)

	const dataForEachDay = () =>
		savingsDailyEarns.itemsList
			.map((item: any) => Number(item.interest))
			.reverse();

	// const interestEachDay = [];

	// for (let i = 0; i < uniqueDates.length; i += 1) {
	// 	const day = uniqueDates[i];

	// 	const interestsThisDay = savingsDailyEarns.itemsList.filter(
	// 		(item: any) =>
	// 			moment(item.earnDate).format('YYYY-MM-DD') ===
	// 			moment(day).format('YYYY-MM-DD')
	// 	);

	// 	const count = interestsThisDay.length;

	// 	const totalInterest = interestsThisDay.reduce(
	// 		(prev, current: any) => prev + Number(current.interest || 0),
	// 		0
	// 	);

	// 	interestEachDay.push(totalInterest / count);
	// }
	// return interestEachDay;

	return (
		<Style>
			<div className="section-head">
				<p>
					<strong>Average Daily Earnings</strong>
				</p>
				<Button onClick={() => setVisible(true)}>View Earning Table</Button>
			</div>
			<Line
				options={{
					responsive: true,
					aspectRatio: 2 / 0.5,
				}}
				data={{
					labels: uniqueDates.map((item) => moment(item).format('MMM DD, YY')),
					datasets: [
						{
							label: 'Daily Earnings',
							data: dataForEachDay(),
							tension: 0.3,
							borderColor: '#1a846d',
							fill: false,
						},
					],
				}}
			/>

			<Modal
				title="Daily Earnings Table"
				visible={visible}
				footer={null}
				onCancel={() => setVisible(false)}
			>
				{savingsDailyEarns.itemsList.map((item: any) => (
					<EarnItemStyle>
						<div>
							<p>{moment(item.earnDate).format('MMM DD, YY')}</p>
							<p className="label">Earn Date</p>
						</div>
						<div>
							<p style={{ color: 'var(--green-color)' }}>
								<strong>
									{commas(item.interest)} {item.currencyId?.symbol}
								</strong>
							</p>
							<p className="label">Interest</p>
						</div>
						<div>
							<p>
								{commas(item.dayBalance)} {item.currencyId?.symbol}
							</p>
							<p className="label">Day Balance</p>
						</div>
						<div>
							<p>{commas(Number(item.apy) * 100)}%</p>
							<p className="label">Day Apy</p>
						</div>
					</EarnItemStyle>
				))}
			</Modal>
		</Style>
	);
};

export default DailyEarnings;
