import { Card, Input } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../methods/redux/hooks';
import { userGlobalSearch } from '../../methods/redux/reducers/global-search';

const UserSearchResult = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 1px solid #ddd;
	padding: 10px 0;

	& > * {
		min-width: 100px;
	}
`;

const UserGlobalSearch = () => {
	const dispatch = useAppDispatch();

	const { userSearch } = useAppSelector((store) => store.globalSearch);

	const [search, setSearch] = useState('');

	const performSearch = () => {
		dispatch(userGlobalSearch(search));
	};

	return (
		<div>
			<Card>
				<h2>User Glboal Search</h2>
				<Input.Search
					onSearch={performSearch}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
			</Card>

			<br />

			<Card>
				<p>
					<strong>Search Result ({userSearch.length} items)</strong>
				</p>
				{userSearch.map((item: any) => (
					<UserSearchResult key={item._id}>
						<div>
							<p>{item.fullName}</p>
						</div>
						<p>{item.email}</p>
						<p>{item.username}</p>
						<div>
							<p>{moment(item.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
							<p>Date of Registration</p>
						</div>
						<div>
							<Link to={`/user-profile/${item._id}`}>Profile</Link>
						</div>
					</UserSearchResult>
				))}
			</Card>
		</div>
	);
};

export default UserGlobalSearch;
