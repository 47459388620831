import { Card, Divider } from 'antd';
import styled from 'styled-components';
import FixedSavingsSettings from './FixedSavingsSettings';
import FlexibleSavingsSettings from './FlexibleSavingsSettings';
import GeneralSavingsSettings from './GeneralSettings';

export const SavingsSettingsStyle = styled.section`
	& .lined-settings {
		margin-top: 10px;
		margin-bottom: 10px;
		border-top: 1px solid #eee;
		padding-top: 10px;

		& > * {
			display: inline-block;
			margin-right: 10px;

			& .label {
				font-size: 14px;
				display: flex;
				align-items: center;

				& .tooltip {
					font-size: 14px;
					color: gray;
					margin-left: 5px;
				}
			}
		}
	}
`;

const SavingsSettings = () => (
	<Card>
		<h2>Savings Settings</h2>
		<Divider />
		<GeneralSavingsSettings />

		<Divider />

		<FlexibleSavingsSettings />

		<Divider />

		<FixedSavingsSettings />
	</Card>
);

export default SavingsSettings;
