import React from 'react';
import RequestsStatistics from './RequestsStatistics';
import RequestTotals from './RequestTotals';

const MaintenanceReport = () => (
	<div>
		<RequestTotals />
		<br />
		<RequestsStatistics />
	</div>
);

export default MaintenanceReport;
