import { useEffect, useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { editSwapProtocol } from '../../methods/redux/reducers/swap';

interface IEditSwapProtocol {
	protocol: any;
}

const EditSwapProtocol = ({ protocol }: IEditSwapProtocol) => {
	const dispatch = useAppDispatch();

	const { blockchains } = useAppSelector((store) => store.currencies);
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({ blockchainNetwork: '', name: '' });

	useEffect(() => {
		setState({
			...state,
			name: protocol.name,
			blockchainNetwork: protocol.blockchainNetwork?._id,
		});
	}, [protocol, blockchains]);

	const editProtocol = () => {
		dispatch(
			editSwapProtocol({
				swapProtocolId: protocol._id,
				swapProtocolName: state.name,
				swapProtocolBlockchainNetworkId: state.blockchainNetwork,
			})
		);
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)} size="small">
				Edit
			</Button>

			<Modal
				okText="Update"
				onOk={editProtocol}
				visible={visible}
				title="Edit Swap Protocol"
				onCancel={() => setVisible(false)}
			>
				<p>Protocol Name</p>
				<Input
					value={state.name}
					onChange={(e) => setState({ ...state, name: e.target.value })}
				/>

				<br />
				<br />

				<p>Blockchain Network</p>
				<Select
					style={{ width: '100%' }}
					defaultValue={state.blockchainNetwork}
					onChange={(e) => setState({ ...state, blockchainNetwork: e })}
				>
					{blockchains.map((chain: any) => (
						<Select.Option key={chain._id} value={chain._id}>
							{chain.abbreviation}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</>
	);
};

export default EditSwapProtocol;
