import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { defaultImageOne } from '../../methods/utils/general-utils';

interface IStakingItem {
	stakingItem: any;
}
const Style = styled.div`
	border-bottom: 1px solid #eee;
	padding: 15px 0;

	& > * {
		display: inline-block;
		margin-right: 20px;
	}

	& .label {
		color: #aaa;
		font-size: 12px;
	}
`;

const StakingItem = ({ stakingItem }: IStakingItem) => (
	<Style>
		<div>
			<p style={{ textTransform: 'capitalize' }}>{stakingItem.status}</p>
			<p className="label">Status</p>
		</div>
		<div>
			<Link
				title="View Profile"
				to={`/user-profile/${stakingItem.userId?._id}`}
			>
				@{stakingItem.userId?.username}
			</Link>
			<p className="label">Customer</p>
		</div>
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img
					alt=""
					style={{ marginRight: 5 }}
					width={18}
					height={18}
					src={stakingItem.currencyId?.iconUrl || defaultImageOne}
				/>
				<p>
					<NativeNumber value={stakingItem.amount} />{' '}
					{stakingItem.currencyId?.symbol}
				</p>
			</div>
			<p className="label">Amount</p>
		</div>
		<div>
			<p>
				{stakingItem.stakingProfileId?.name} ({stakingItem.apr}%)
			</p>
			<p className="label">Staking Profile</p>
		</div>
		<div>
			<p>{stakingItem.periodInDays} days</p>
			<p className="label">Period in days</p>
		</div>
		<div>
			<p>{moment(stakingItem.createdAt).format('lll')}</p>
			<p className="label">Created</p>
		</div>
		{stakingItem.status === 'completed' && (
			<div>
				<p>
					<NativeNumber value={stakingItem.interestPaid} />{' '}
					{stakingItem.currencyId?.symbol}
				</p>
				<p className="label">Interest Paid</p>
			</div>
		)}
		{stakingItem.status === 'completed' && (
			<div>
				<p>
					<NativeNumber value={stakingItem.completedPayoutAmount} />{' '}
					{stakingItem.currencyId?.symbol}
				</p>
				<p className="label">Completed Payout Amount</p>
			</div>
		)}
		{stakingItem.status === 'completed' && (
			<div>
				<p>
					{stakingItem.unstakeDate
						? moment(stakingItem.unstakeDate).format('lll')
						: '--'}
				</p>
				<p className="label">Unstake Date</p>
			</div>
		)}
		<div>
			<p>{moment(stakingItem.updatedAt).format('lll')}</p>
			<p className="label">Last Updated</p>
		</div>
	</Style>
);

export default StakingItem;
