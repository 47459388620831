import styled, { css } from 'styled-components';
// import logoWhite from '../logo-white.png';
import logoBlack from '../logo-black.png';

interface IUseSection {
	section: string;
}
const LogoSymbolStyle = styled.div<IUseSection>`
	display: block;
	& img {
		width: 60px;
	}

	${(props) => {
		switch (props.section) {
			case 'header':
				return css`
					display: block;
					@media (min-width: 768px) {
						display: none;
					}
				`;
			default:
				return css`
					display: block;
				`;
		}
	}}
`;

const LogoSymbol = ({ section }: IUseSection) => (
	<LogoSymbolStyle section={section}>
		{/* <img src={logoWhite} alt="" /> */}
		<img src={logoBlack} alt="" />
	</LogoSymbolStyle>
);

export default LogoSymbol;
