import { Card, message, Pagination, Space, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../../component/TableHeaderSection';
import baseuri from '../../../methods/baseuri';
import { MyEmptyTable } from '../../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../UsersByCountry';
import UsersFilter from '../UsersFilter';
import { MyTable, UsersListPage } from '../UsersList';
import AllUserTypes from './AllUserTypes';
import CreateNewAgent from './CreateNewAgent';
import CreateUserType from './CreateUserType';
import RemoveUser from './RemoveUser';

export const MyCard = styled(Card)`
	border-radius: 10px;
`;
const ByUserType = () => {
	const [userTypeList, setUserTypeList] = useState([] as any);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => ({
		userType: `${searchParams.get('userType') || ''}`,
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const getUserTypes = async () => {
		try {
			const res: any = await baseuri.get(`admin/user-types/user-types`);

			if (res.status === 200) {
				setUserTypeList(res.data.data);
				const params1 = getQueryParams();
				if (!params1?.userType) {
					setSearchParams({ ...params1, userType: res?.data?.data[0]?._id });
				}
			}
		} catch (error: any) {
			message.error(error.response.data.data.message);
		}
	};

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		// if (!location.search) return;
		try {
			const res: any = await baseuri.get('admin/users/usertype', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
				setLoading(false);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};
	useEffect(() => {
		getUserTypes();
	}, []);
	useEffect(() => {
		if (searchParams.get('userType') && userTypeList) {
			getData();
		}
	}, [location.search]);

	const columns = [
		{
			title: 'Username',
			key: '_id',
			render: (text: any) => <strong>{text?.userId?.username}</strong>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		// {
		// 	title: '',
		// 	key: '_id',
		// 	render: (text: any) => <KYCData userDetails={text?.userId} />,
		// },
		// {
		// 	title: '',
		// 	key: '_id',
		// 	render: (text: any) => (
		// 		<RemoveAsAgent email={text.email} usertype={query?.userType} />
		// 	),
		// },
		{
			title: '',
			key: '_id',
			render: (text: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${text?.userId?._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
		{
			title: '',
			key: 'email',
			render: (text: any) => (
				<RemoveUser
					email={text?.email}
					usertype={`${searchParams.get('userType')}`}
				/>
			),
		},
	];
	return (
		<UsersListPage>
			<UsersFilter />
			<MyCard>
				<Space>
					<CreateUserType />
					<AllUserTypes userTypeList={userTypeList} />
					<CreateNewAgent userTypeList={userTypeList} />
				</Space>
			</MyCard>
			<Tabs
				activeKey={`${searchParams.get('userType')}`}
				onChange={(key) => {
					const params1 = getQueryParams();
					setSearchParams({ ...params1, userType: key });
				}}
			>
				{userTypeList?.map((userType: any) => (
					<Tabs.TabPane tab={userType?.name} key={userType?._id}>
						<MyTableCard>
							<TableHeaderSection
								tableName="Users by UserType"
								currentPage={data?.paginator?.currentPage || 0}
								totalRecords={data?.paginator?.itemCount || 0}
								downloadURL={`${process.env.REACT_APP_BASEURI}admin/sortuser-by-usertype`}
							/>

							<MyTable
								locale={{
									emptyText: <MyEmptyTable />,
								}}
								loading={loading}
								columns={columns}
								dataSource={data.itemsList}
								pagination={false}
							/>
							<br />
							<Pagination
								onChange={(page, pageSize) => {
									const params1 = Object.fromEntries(searchParams.entries());
									setSearchParams({
										...params1,
										pageId: `${page}`,
										perPage: `${pageSize}`,
									});
								}}
								disabled={loading}
								pageSize={data?.paginator?.perPage || 0}
								current={data?.paginator?.currentPage || 0}
								total={data?.paginator?.itemCount || 0}
								showSizeChanger
								size="small"
								showQuickJumper
							/>
						</MyTableCard>
					</Tabs.TabPane>
				))}
			</Tabs>{' '}
		</UsersListPage>
	);
};

export default ByUserType;
