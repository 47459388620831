import { Badge } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import { retrieveUnreadNotifications } from '../methods/redux/reducers/notification';

const Notifications = () => {
	const dispatch = useAppDispatch();

	const { unread } = useAppSelector((store) => store.notifications);

	useEffect(() => {
		dispatch(retrieveUnreadNotifications({}));
	}, []);

	return (
		<Link to="/my-profile" style={{ paddingRight: 20 }}>
			<Badge count={unread?.paginator?.itemCount}>
				<span className="material-icons">notifications</span>
			</Badge>
		</Link>
	);
};

export default Notifications;
