import { useEffect } from 'react';
import { Card, Table } from 'antd';
import { retrieveSwapRequest } from '../../methods/redux/reducers/swap';
import { useAppDispatch } from '../../methods/redux/hooks';

const SwapReport = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(retrieveSwapRequest({}));
	}, []);

	return (
		<div>
			<Card>
				<h2>Swap</h2>
			</Card>
			<br />
			<Card>
				<h2>Pending Swap Requests</h2>
				<Table />
			</Card>
		</div>
	);
};

export default SwapReport;
