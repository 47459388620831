import { Card, Divider, Pagination } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveBlacklistedUsers } from '../../methods/redux/reducers/compliance';
import remakeQueryString from '../../methods/utils/remake-query-string';
import AddToBlacklistedUser from './AddToBlacklistedUser';
import BlacklistedItem from './BlacklistedItem';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const BlackListedUsers = () => {
	const dispatch = useAppDispatch();

	const { blacklistedUsers } = useAppSelector((store) => store.compliance);

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveBlacklistedUsers(queryData));
		}

		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
				sort: 'desc',
				startDate: '2022-10-01',
				endDate: moment().format('YYYY-MM-DD'),
				blacklisted: 'true',
			});
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	return (
		<Card>
			<h1>Blacklisted Users & Devices</h1>
			<p>Manage blacklisted users and devices on Xend Finance</p>
			<br />
			<AddToBlacklistedUser />

			<Divider />

			<SearchFilter
				title="Search Blacklisted Users Record"
				searchFilters={[
					{
						label: 'Blacklisted',
						name: 'blacklisted',
						htmlElement: 'select',
						defaultSelectedValue: 'true',
						selectOptions: [
							{ value: 'true', name: 'Blacklisted' },
							{ value: 'false', name: 'No Longer Blacklisted' },
						],
					},
					{
						label: 'Email',
						name: 'email',
						htmlElement: 'input',
					},
					{
						label: 'Device Id',
						name: 'deviceId',
						htmlElement: 'input',
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<Divider />

			<p>
				Total:{' '}
				<strong>
					<NativeNumber value={blacklistedUsers.paginator.itemCount} />
				</strong>{' '}
				items
			</p>

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={blacklistedUsers?.paginator?.currentPage || 0}
				total={blacklistedUsers?.paginator?.itemCount || 0}
				pageSize={blacklistedUsers?.paginator?.perPage || 0}
			/>

			<br />

			{blacklistedUsers.itemsList.map((record: any) => (
				<BlacklistedItem record={record} key={record._id} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={blacklistedUsers?.paginator?.currentPage || 0}
				total={blacklistedUsers?.paginator?.itemCount || 0}
				pageSize={blacklistedUsers?.paginator?.perPage || 0}
			/>
		</Card>
	);
};

export default BlackListedUsers;
