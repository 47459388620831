import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const getFlexibleSavings = createAsyncThunk(
	'flexible-savings/getFlexibleSavings',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/flexible/items',
			{
				params,
			}
		);
		return res.data.data;
	}
);
export const getPaidFlexibleInterest = createAsyncThunk(
	'flexible-savings/getPaidFlexibleInterest',
	async (params: any) => {
		const res = await baseuri.get('/report/savings/flexible/interest/paidout', {
			params,
		});
		return res.data.data;
	}
);

export const getFlexibleFirstTimeSavers = createAsyncThunk(
	'flexible-savings/getFlexibleFirstTimeSavers',
	async (params: any) => {
		const res = await baseuri.get('/report/new-user/flexible/savings', {
			params,
		});
		return res.data.data;
	}
);

export const getSingleFlexibleSavingsProfile = createAsyncThunk(
	'flexible-savings/getSingleFlexibleSavingsProfile',
	async (flexibleSavingsId: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/flexible/single',
			{
				params: { flexibleSavingsId },
			}
		);
		return res.data.data;
	}
);

export const getFlexibleSavingsProfiles = createAsyncThunk(
	'flexible-savings/getFlexibleSavingsProfiles',
	async (params: any) => {
		const res = await baseuri.get('/admin/savings/stable-currency/flexible', {
			params: { ...params },
		});
		return res.data.data;
	}
);

export const getFlexibleSavingsDailyEarnings = createAsyncThunk(
	'flexible-savings/getFlexibleSavingsDailyEarnings',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/flexible/daily-earns',
			{
				params: { ...params },
			}
		);
		return res.data.data;
	}
);

export const getFlexibleMigrationLogs = createAsyncThunk(
	'flexible-savings/getFlexibleMigrationLogs',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/flexible/migration-logs',
			{
				params: { ...params },
			}
		);
		return res.data.data;
	}
);

export const getPendingProfitBuild = createAsyncThunk(
	'flexible-savings/getPendingProfitBuild',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/savings/stable-currency/flexible/pending-profit-build',
			{
				params: { ...params },
			}
		);
		return res.data.data;
	}
);

const flexibleSavingsRx = createSlice({
	name: 'flexible-savings',
	initialState: {
		savingsDailyEarns: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		profiles: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		flexible: [],
		paginator: {
			itemCount: 0,
			currentPage: 1,
			perPage: 20,
		},
		singleFlexibleSavingsProfile: {
			ledgerBalance: 0,
			latestSavingsItems: [],
			transactionStatements: [],
			savingsProfile: {
				userId: { username: '', _id: '' },
				currencyId: { symbol: '' },
				title: '',
				savingsForm: '',
				startDate: '',
				updatedAt: '',
				dailyProfitMakerLastBuildDate: '',
				lastUpdatedByUserId: { username: '' },
				endDate: '',
				status: '',
				availableBalance: 0,
				totalWithdrawn: 0,
			},
		},
		migrationLogs: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		pendingProfitBuild: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		paidOut: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
			record: [],
		},
		firstTimeSavers: {
			itemsList: [],
			paginator: {
				perPage: 20,
				itemCount: 0,
				currentPage: 1,
			},
			flexibleSavings: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getFlexibleSavings.fulfilled, (store, action) => {
				const state = store;
				state.flexible = action.payload?.itemsList;
				state.paginator = action.payload?.paginator;
			})
			.addCase(getPaidFlexibleInterest.fulfilled, (store, action) => {
				const state = store;
				state.paidOut = action.payload;
			})
			.addCase(getFlexibleFirstTimeSavers.fulfilled, (store, action) => {
				const state = store;
				state.firstTimeSavers = action.payload;
			})
			.addCase(getSingleFlexibleSavingsProfile.fulfilled, (store, action) => {
				const state = store;
				state.singleFlexibleSavingsProfile = action.payload;
			})
			.addCase(getFlexibleSavingsProfiles.fulfilled, (store, action) => {
				const state = store;
				state.profiles = action.payload;
			})
			.addCase(getFlexibleSavingsDailyEarnings.fulfilled, (store, action) => {
				const state = store;
				state.savingsDailyEarns = action.payload;
			})
			.addCase(getFlexibleMigrationLogs.fulfilled, (store, action) => {
				const state = store;
				state.migrationLogs = action.payload;
			})
			.addCase(getPendingProfitBuild.fulfilled, (store, action) => {
				const state = store;
				state.pendingProfitBuild = action.payload;
			}),
});

export default flexibleSavingsRx.reducer;
