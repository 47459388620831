import { Card, Divider } from 'antd';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../component/Numeral';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import { getUserWalletBalanceNonRedux } from '../methods/redux/reducers/user-profile';
import {
	getCustodialUsers,
	getUserTypes,
} from '../methods/redux/reducers/users';
import { defaultImageOne } from '../methods/utils/general-utils';

interface ICustodialUsersAccountBalance {
	userDetails: any;
	userId: any;
}

const Style = styled.div`
	& .money {
		display: inline-flex;
		align-items: center;
		margin-right: 10px;
		font-weight: 600;

		& > * {
			margin-right: 3px;
		}

		& img {
			margin-right: 3px;
			width: 20px;
			height: 20px;
		}
	}
	& .address {
		word-break: break-all;
	}
`;

const CustodialUsersAccountBalance = ({
	userDetails,
	userId,
}: ICustodialUsersAccountBalance) => {
	const [cryptoBalances, setCryptoBalances] = useState<Array<any>>([]);
	const [walletAddress, setWalletAddress] = useState('');

	const retrieveBalance = async () => {
		const walletDetails = await getUserWalletBalanceNonRedux(userId);
		setWalletAddress(walletDetails.walletAddress);
		const balances = walletDetails.cryptoBalances?.filter((d: any) => d.userId);
		setCryptoBalances(balances);
	};

	useEffect(() => {
		retrieveBalance();
	}, [userId]);

	return (
		<Style>
			<Link to={`/user-profile/${userDetails.userId?._id}`}>
				{userDetails.userId?.username ? (
					<span>@{userDetails.userId?.username}</span>
				) : (
					<span>{userDetails.userId?.email}</span>
				)}
			</Link>
			<div>
				{cryptoBalances.map((bal: any) => (
					<div className="money" key={bal._id}>
						<img alt="" src={bal.currencyId?.iconUrl || defaultImageOne} />
						<NativeNumber value={bal.balance} decimalPlaces={4} />{' '}
						<span>{bal.currencyId?.symbol}</span>
					</div>
				))}
			</div>
			<p className="address">{walletAddress}</p>
			<Divider />
		</Style>
	);
};

const CustodialUsers = () => {
	const dispatch = useAppDispatch();

	const { userTypes, custodialFunctionUsers } = useAppSelector(
		(store) => store.users
	);

	const [custodialUserTypesId, setCustodialUserTypesId] = useState('');

	useEffect(() => {
		dispatch(getUserTypes());
	}, []);

	useEffect(() => {
		const custodialUser: any = userTypes.filter(
			(x: any) => String(x.name).toLowerCase() === 'custodialusers'
		);
		if (custodialUser.length > 0) {
			setCustodialUserTypesId(custodialUser[0]?._id);
		}
	}, [userTypes]);

	useEffect(() => {
		if (custodialUserTypesId) {
			dispatch(
				getCustodialUsers({ userType: custodialUserTypesId, perPage: 50 })
			);
		}
	}, [custodialUserTypesId]);

	return (
		<Card>
			<h2>Custodial Users</h2>

			{custodialFunctionUsers.itemsList.map((user: any) => (
				<CustodialUsersAccountBalance
					key={user._id}
					userId={user.userId?._id}
					userDetails={user}
				/>
			))}
		</Card>
	);
};

export default CustodialUsers;
