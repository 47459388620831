import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const MainSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 1em;
	margin: 10px 0px;
`;
const ItemsSection = styled.div`
	display: inline-flex;
	flex-direction: column;
	height: 100px;
	min-width: 300px;
	background: white;
	border-radius: 10px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
	padding: 10px;
	font-size: 16px;

	& .upperSection {
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-weight: bold;
		margin-bottom: 10px;
	}
	& .lowerSection {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		color: green;
		font-size: 26px;
		font-weight: bold;
		margin: 5px;
	}
	& .red {
		color: red;
	}
	& .yellow {
		color: yellow;
	}
`;
const RequestTotals = () => {
	const [data, setData] = useState({
		currentHourRequests: '0',
	});
	const [todayTotalRequests, setTodayTotalRequests] = useState(0);
	const [lastHourRequests, setLastHourRequests] = useState('0');
	const getCurrentHourRequest = async () => {
		try {
			const res: any = await baseuri.get('admin/maintenance/request-counter');
			if (res.status === 200) {
				setData({ ...data, currentHourRequests: res.data.data.public });
			}
		} catch (error) {
			console.info(error);
		}
	};
	const getTodayTotalRequests = async () => {
		try {
			const res: any = await baseuri.get(
				'admin/maintenance/request-counter/day'
			);

			if (res.status === 200) {
				let sum = 0;

				for (let i = 0; i < res.data.data.length; i++) {
					sum += Number(res.data.data[i].requestCount);
				}
				setTodayTotalRequests(sum);
			}
		} catch (error) {
			console.info(error);
		}
	};
	const getLastHourRequests = async () => {
		try {
			const res: any = await baseuri.get(
				'admin/maintenance/request-counter/hour',
				{
					params: {
						hourFormat: `${moment()
							.subtract(1, 'hours')
							.format('YYYY-MM-D-H')}`,
					},
				}
			);

			if (res.status === 200) {
				setLastHourRequests(res.data.data.public);
			}
		} catch (error) {
			console.info(error);
		}
	};
	useEffect(() => {
		getCurrentHourRequest();
		getTodayTotalRequests();
		getLastHourRequests();
	}, []);

	return (
		<MainSection>
			<ItemsSection>
				<div className="upperSection">
					<span>Current Hour Requests</span>
				</div>
				<p>
					Current Hour: <strong>{moment().hour()}</strong>
				</p>
				<div className="lowerSection">{data.currentHourRequests}</div>
			</ItemsSection>
			<ItemsSection>
				<div className="upperSection">
					<span>Last Hour Requests</span>
				</div>
				<p>
					Last Hour: <strong>{moment().subtract(1, 'hours').hour()}</strong>
				</p>
				<div className="lowerSection">{lastHourRequests}</div>
			</ItemsSection>
			<ItemsSection>
				<div className="upperSection">
					<span>Today Total Requests</span>
				</div>
				<p>
					Today Date: <strong>{moment().format('YYYY/MM/DD')}</strong>
				</p>
				<div className="lowerSection">{todayTotalRequests}</div>
			</ItemsSection>
		</MainSection>
	);
};

export default RequestTotals;
