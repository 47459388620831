import {
	Card,
	Pagination,
	Space,
	Table,
	Tooltip,
	message,
	Divider,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	Link,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { truncateAddress } from '../../methods/utils/general-utils';
import { hideEmail } from '../../methods/utils/request-helpers';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import HeaderExtraSection from './HeaderExtraSection';
import { MyTableCard } from './UsersByCountry';
import UsersFilter from './UsersFilter';

export const UsersListPage = styled(Card)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		gap: 1em;
	}
`;

export const MyTable = styled(Table)`
	min-width: 250px;
	overflow-x: auto;

	& .ant-table-thead > tr > th {
		padding: 16px 25px;
		font-weight: 700;
		background-color: transparent;
		font-size: 12px;
		color: red;
		min-width: 140px;
		text-overflow: wrap;
	}

	& .ant-table-tbody > tr > td {
		padding: 16px 25px;
		min-width: 140px;
		text-overflow: wrap;
	}
`;
const UsersList = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'User ID',
			dataIndex: '_id',
			key: '_id',
			render: (text: any) => (
				<Tooltip title="Click to copy ID" color="green">
					<span
						role="none"
						onClick={() => {
							navigator.clipboard.writeText(text);
							message.success('ID copied');
						}}
					>
						{truncateAddress(text, 6)}{' '}
					</span>
				</Tooltip>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text: any) => <h3>{hideEmail(text)}</h3>,
		},
		{
			title: 'Phone No.',
			dataIndex: 'phoneNo',
			key: 'phoneNo',
			render: (text: any) => <h3>{text}</h3>,
		},
		{
			title: 'No. of Refs',
			dataIndex: 'referralCount',
			key: 'referralCount',
		},
		{
			title: 'SignUp Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).subtract(1, 'hour').format('lll'),
			key: 'createdAt',
		},

		{
			title: '',
			// key: '_id',
			render: (user: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${user._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const params: any = useParams();
	const location = useLocation();
	const { search } = params;

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			startDate: `${
				searchParams.get('startDate') ||
				moment('2021/10/01').format('YYYY-MM-DD')
			}`,
			endDate: `${
				searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			}`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};
	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		if (!location.search) return;
		try {
			const res: any = await baseuri.get('admin/users/search/by-date', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const globalSearch = async () => {
		if (!search) return;

		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/users/search-user', {
				params: { search },
			});
			if (res.status === 200) {
				setData({ ...data, itemsList: res.data.data, paginator: {} });
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (search) {
			globalSearch();
		}
	}, [search]);

	useEffect(() => {
		if (!search) {
			setLoading(true);
			handleSearchByDate();
		}
	}, [location.search]);

	return (
		<UsersListPage>
			<h1>Xend Finance Users</h1>
			<p>Manage/search for users on Xend Finance</p>
			<Divider />

			<UsersFilter />
			<br />

			<HeaderExtraSection />

			<MyTableCard>
				<TableHeaderSection
					tableName="All Users"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
					downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
				/>
				<MyTable
					locale={{
						emptyText: <MyEmptyTable />,
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>

				<br />

				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
				/>
			</MyTableCard>
		</UsersListPage>
	);
};

export default UsersList;
