import { Modal, Input, Select, Space } from 'antd';
import { useState } from 'react';
import { createACurrency } from '../../methods/currency';

const AddCurrency = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newData, setNewData] = useState({
		name: '',
		symbol: '',
		decimalPrecision: '',
		minimumWithdrawalAmount: '',
		coinGeckoId: '',
		switchWalletId: '',
		currencyForm: 'crypto',
	});

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setNewData({ ...newData, [name]: value });
	};

	const handleSubmit = () => {
		createACurrency(newData, setLoading);
	};

	return (
		<div>
			<button type="button" onClick={() => setVisible(true)}>
				+ Add Currency
			</button>

			<Modal
				confirmLoading={loading}
				okText="+ Add"
				onOk={() => handleSubmit()}
				title="Add Currency"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<Space direction="vertical" size={10} style={{ width: '100%' }}>
					<div>
						<p>Type of Currency</p>
						<Select
							size="large"
							style={{ width: '100%' }}
							onChange={(e) => setNewData({ ...newData, currencyForm: e })}
							defaultValue={newData.currencyForm}
						>
							<Select.Option value="crypto">Crypto</Select.Option>
							<Select.Option value="fiat">Fiat</Select.Option>
						</Select>
					</div>
					<div>
						<p>Name</p>
						<Input
							name="name"
							value={newData.name}
							onChange={handleInput}
							size="large"
							placeholder="Name"
						/>
					</div>
					<div>
						<p>Symbol</p>
						<Input
							name="symbol"
							value={newData.symbol}
							onChange={handleInput}
							size="large"
							placeholder="Symbol"
						/>
					</div>
					<div>
						<p>Decimal Precision</p>
						<p>
							Decimal precision used in the app (not the crypto currency
							decimals from the token smart contract)
						</p>
						<Input
							name="decimalPrecision"
							value={newData.decimalPrecision}
							onChange={handleInput}
							size="large"
							placeholder="Decimal Precision"
						/>
					</div>
					<div>
						<p>Minimum Withdrawal Amount</p>
						<Input
							name="minimumWithdrawalAmount"
							value={newData.minimumWithdrawalAmount}
							onChange={handleInput}
							size="large"
							placeholder="Minimum Withdrawal Amount"
						/>
					</div>
					{newData.currencyForm === 'crypto' && (
						<div>
							<p>Switch Wallet Id</p>
							<Input
								name="switchWalletId"
								value={newData.switchWalletId}
								onChange={handleInput}
								size="large"
								placeholder="Switch Wallet Id"
							/>
						</div>
					)}
					{newData.currencyForm === 'crypto' && (
						<div>
							<p>CoinGecko Id</p>
							<Input
								name="coinGeckoId"
								value={newData.coinGeckoId}
								onChange={handleInput}
								size="large"
								placeholder="Coin Gecko Id"
							/>
						</div>
					)}
				</Space>
			</Modal>
		</div>
	);
};

export default AddCurrency;
