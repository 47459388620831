import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveDoubleFundings } from '../../methods/redux/reducers/compliance';
import remakeQueryString from '../../methods/utils/remake-query-string';
import SingleDoubleFundingRecord from './SingleRecord';

const DoubleFunding = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { doubleFunding } = useAppSelector((store) => store.compliance);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveDoubleFundings(queryData));
		}

		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
				sort: 'desc',
			});
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	return (
		<Card>
			<h1>Double Funding</h1>
			<p>
				Manage double funding on Xend Finace that are yet to be
				&quot;deleted&quot;.
			</p>

			<Divider />

			<SearchFilter
				title="Search Double Funding Record"
				searchFilters={[
					{
						label: 'Status',
						name: 'status',
						htmlElement: 'select',
						defaultSelectedValue: 'pending',
						selectOptions: [{ value: 'pending', name: 'Pending' }],
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<Divider />

			<p>
				Total: <strong>{doubleFunding.paginator.itemCount}</strong> items
			</p>
			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={doubleFunding?.paginator?.currentPage || 0}
				total={doubleFunding?.paginator?.itemCount || 0}
				pageSize={doubleFunding?.paginator?.perPage || 0}
			/>

			<br />

			{doubleFunding?.itemsList?.map((item: any) => (
				<SingleDoubleFundingRecord item={item} key={item._id} />
			))}
			<Divider />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={doubleFunding?.paginator?.currentPage || 0}
				pageSize={doubleFunding?.paginator?.perPage || 0}
				total={doubleFunding?.paginator?.itemCount || 0}
			/>
		</Card>
	);
};

export default DoubleFunding;
