import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveLockedSavings } from '../../methods/redux/reducers/loan';
import LockedFixedItem from './LockedFixedItem';

interface ILockedFixedLoan {
	loanId: string;
	userId: string;
}
const LockedFixedLoan = ({ loanId, userId }: ILockedFixedLoan) => {
	const dispatch = useAppDispatch();

	const { lockedFixedSavings } = useAppSelector((store) => store.loan);
	useEffect(() => {
		if (loanId && userId) {
			dispatch(
				retrieveLockedSavings({
					loanId,
					userId,
				})
			);
		}
	}, [loanId, userId]);

	return (
		<div>
			{lockedFixedSavings?.itemsList &&
				lockedFixedSavings?.itemsList?.map((profile: any) => (
					<LockedFixedItem
						key={profile._id}
						item={profile}
						// onChange={onChange}
					/>
				))}
		</div>
	);
};

export default LockedFixedLoan;
