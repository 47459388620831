import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import links from './links';
import { ProfilePillMobile } from '../component/ProfilePill';

const SidebarMobileStyle = styled.section`
	display: block;

	& .menu-button {
		background-color: #cacaca;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& .cover {
	}

	@media (min-width: 768px) {
		display: none;
	}
`;

interface IMobileSlidingSidebar {
	visible: boolean;
}
const MobileSlidingSidebar = styled.section<IMobileSlidingSidebar>`
	position: fixed;
	z-index: 6;
	top: 0;
	left: -1000px;
	width: 100%;
	height: 100%;
	background-color: white;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
	transition: all 400ms ease;

	${(p) =>
		p.visible &&
		css`
			left: 0;
		`}

	& .top {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #eee;
	}

	& .content {
		overflow-y: auto;
		overflow-x: none;
		padding: 10px;

		& a {
			text-decoration: none;
			color: inherit;
			padding: 10px;
			display: flex;
			align-items: flex-end;

			&:hover {
				background-color: #d6ebff;
			}

			&.active {
				background-color: #1890ff;
				color: white;
			}

			& > i {
				margin-right: 7px;
				font-size: 21px;
			}
		}
	}

	& .footer {
		padding: 10px;
		border-top: 1px solid #eee;
	}

	@media (min-width: 768px) {
		display: none;
	}
`;

const SidebarMobile = () => {
	const [visible, setVisible] = useState(false);

	const { pathname } = useLocation();

	const [activeBase, setActiveBase] = useState('');

	useEffect(() => {
		const baseRoute = pathname.split('/')[1];
		setActiveBase(baseRoute);
		setVisible(false);
	}, [pathname]);

	const choseActiveLink = (base: string) => {
		if (base === activeBase) {
			return 'active';
		}
		return '';
	};

	return (
		<>
			<SidebarMobileStyle>
				<button
					type="button"
					className="menu-button"
					onClick={() => setVisible(true)}
				>
					<i className="material-icons">menu</i>
				</button>
			</SidebarMobileStyle>

			<MobileSlidingSidebar visible={visible}>
				<div className="top">
					<p>Xend Finance</p>
					<div>
						<button type="button" onClick={() => setVisible(false)}>
							<span className="material-icons">cancel</span>
						</button>
					</div>
				</div>
				<div className="content">
					<Link className={`${choseActiveLink('')}`} to="/">
						<i className="material-icons">dashboard</i>
						<p>Dashboard</p>
					</Link>
					{links.map((item) => (
						<Link
							to={`/${item.name}`}
							key={item.id}
							className={`${choseActiveLink(item.name)}`}
						>
							<i className="material-icons">{item.icon}</i>
							<p style={{ textTransform: 'capitalize' }}>
								{item.name
									.toLowerCase()
									.split(' ')
									.map(
										(word) => word.charAt(0).toUpperCase() + word.substring(1)
									)
									.join(' ')
									.replace('-', ' ')}
							</p>
						</Link>
					))}
				</div>
				<div className="footer">
					<ProfilePillMobile />
				</div>
			</MobileSlidingSidebar>
		</>
	);
};

export default SidebarMobile;
