import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const getUserProfileById = createAsyncThunk(
	'userProfile/getUserProfileById',
	async (userId: any) => {
		const res = await baseuri.get('/admin/users/profile', {
			params: { userId },
		});

		return res.data.data;
	}
);

export const getUserWalletBalance = createAsyncThunk(
	'userProfile/getUserWalletBalance',
	async (userId: any) => {
		const res = await baseuri.get('/admin/wallets/user-wallet-balance', {
			params: { userId },
		});

		return res.data.data;
	}
);

export const getUserWalletBalanceNonRedux = async (userId: any) => {
	try {
		const res = await baseuri.get('/admin/wallets/user-wallet-balance', {
			params: { userId },
		});

		return res.data.data;
	} catch (e) {
		return null;
	}
};

export const getUserReferralOverview = createAsyncThunk(
	'userProfile/getUserReferralOverview',
	async (params: any) => {
		const res = await baseuri.get('/admin/users/referral-info', {
			params,
		});
		return res.data.data;
	}
);

const userProfileRx = createSlice({
	name: 'userProfile',
	initialState: {
		user: {
			nextOfKin: {},
			paymentMethods: [],
			lastDeviceLogin: [
				{
					userDevice: {
						type: '',
						build: '',
						description: '',
						serial: '',
						deviceCountry: '',
						cfIpCountry: '',
						clientIP: '',
					},
					locationCoordinates: { lat: 0, long: 0 },
				},
			],
			profile: {
				_id: '',
				username: '',
				refereeName: null,
				hasCompletedProfile: false,
				deactivated: false,
				profileUrl: '',
				fullName: '',
				email: '',
				lastLogin: '',
				phoneNo: '',
				countryCode: '',
				createdAt: '',
				updatedAt: '',
				googleId: null,
				appleId: null,
				twoFaResetTime: null,
				isRobot: false,
				ghostUserMerchantId: '',
				enabledTwoFactor: false,
				kycLevelTwoStatus: '',
				kycLevelThreeStatus: '',
				adminLastComment: '',
				trustScore: 0,
				registrationCoordinates: {
					long: '',
					lat: '',
				},
				registrationDeviceDetails: {
					cfIpCountry: '',
					serial: '',
					deviceCountry: '',
					xRealIp: '',
					description: '',
					build: '',
					type: '',
				},
			},
			loading: false,
			userActivities: {
				lastLoginLocation: {
					deviceCountry: '',
					cfIpCountry: '',
					xRealIp: '',
					deviceType: '',
					locationCoordinates: {
						lat: '',
						long: '',
					},
					userDevice: {
						description: '',
						serial: '',
						build: '',
					},
				},
			},
			transactionsCount: {
				transactions: 0,
				inappTransfers: 0,
				deposits: 0,
				withdrawals: 0,
				swap: 0,
				staking: 0,
			},
		},
		walletBalance: {
			walletAddress: '',
			cryptoBalances: [],
			fiatBalances: [],
		},
		referralOverview: {
			totalReferrals: 0,
			unverified: 0,
			numOfUniqueDeviceIds: 0,
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getUserProfileById.fulfilled, (store, action) => {
				const state = store;
				state.user = { ...state.user, ...action.payload };
			})
			.addCase(getUserWalletBalance.fulfilled, (store, action) => {
				const state = store;
				state.walletBalance = action.payload;
			})
			.addCase(getUserReferralOverview.fulfilled, (store, action) => {
				const state = store;
				state.referralOverview = action.payload;
			}),
});

export default userProfileRx.reducer;
