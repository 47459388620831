import { Button, Modal } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { adminLiquidateLoan } from '../../methods/redux/reducers/loan';

interface ILiquidateLoan {
	loanItem: any;
}

const LiquidateLoan = ({ loanItem }: ILiquidateLoan) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const validateLiquidation = () => {
		const hasReachedMaturity = moment().isSameOrAfter(
			loanItem.expectedPaybackDate
		);

		return hasReachedMaturity && !loanItem.repaymentCompleted;
	};

	const handleAdminLiquidate = () => {
		if (validateLiquidation()) {
			dispatch(adminLiquidateLoan({ loanId: loanItem._id }));
		}
	};

	return (
		<>
			<Button disabled={validateLiquidation()} onClick={() => setVisible(true)}>
				Self Liquidate Loan
			</Button>

			<Modal
				open={visible}
				title="Admin Self Liquidate Loan"
				onCancel={() => setVisible(false)}
				okText="Understand? Proceed"
				onOk={() => handleAdminLiquidate()}
			>
				<p>
					Ensure that the customer [
					<strong>@{loanItem.userId?.username}</strong>] has requested this
					action from the Customer Care before proceeding. Proceed only if this
					is true.
				</p>
			</Modal>
		</>
	);
};

export default LiquidateLoan;
