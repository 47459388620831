import { Divider, Card, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../../component/Numeral';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { retrieveGameActions } from '../../../methods/redux/reducers/games/actions/game-actions';
import GameRewardDistributionItem from './gameRewardDisctributionItem';
import { retrieveGeneralGameUsersPoints } from '../../../methods/redux/reducers/games';

const Style = styled(Card)``;

const GamePointDistribution = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { generalGamePoints } = useAppSelector((store) => store.games);
	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '4',
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveGameActions({ ...queryData }));
			dispatch(retrieveGeneralGameUsersPoints({ ...queryData }));
		}
	}, [searchParams]);
	return (
		<Style>
			<h1>Game Reward Distribution Table</h1>
			<Divider />
			<br />
			<p>
				This shows a complete details of all users total and distributed points
				gotten from all participated games..
			</p>
			<Divider />
			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={generalGamePoints.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={generalGamePoints.paginator.itemCount}
				current={generalGamePoints.paginator.currentPage}
				pageSize={generalGamePoints.paginator.perPage}
			/>
			<br />

			{generalGamePoints?.itemsList?.map((gamePoints: any) => (
				<GameRewardDistributionItem
					gamePoints={gamePoints}
					key={gamePoints._id}
				/>
			))}
			<br />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={generalGamePoints.paginator.itemCount}
				current={generalGamePoints.paginator.currentPage}
				pageSize={generalGamePoints.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
			<Divider />
		</Style>
	);
};

export default GamePointDistribution;
