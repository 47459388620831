import { DeleteOutlined } from '@ant-design/icons';
import { message, Modal, Tag } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IDeleteDomain {
	id: string;
	setRefresh: Function;
}
const DeleteDomain: FC<IDeleteDomain> = ({ id, setRefresh }) => {
	const removeBanner = async () => {
		try {
			const res: any = await baseuri.delete(`admin/spam-temporary-domains`, {
				params: {
					id,
				},
			});
			if (res.status === 200) {
				message.success('Domain removed As Spam Successfully');
				setRefresh(Math.random());
			} else {
				message.info(res?.data?.message);
			}
		} catch (e) {
			message.error('error occurred');
		}
	};
	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Remove this Email as Spam?',
			onOk() {
				removeBanner();
			},
		});
	};
	return (
		<DeleteSection>
			<Tag icon={<DeleteOutlined />} color="error" onClick={confirm}>
				Delete
			</Tag>
		</DeleteSection>
	);
};

export default DeleteDomain;
