import { Card, message, Pagination, Tag, InputNumber, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const ScoreSection = styled.div`
	display: flex;
	gap: 2em;
`;
const TrustScoreUsers = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const [trustScore, setTrustScore] = useState(20);

	const columns = [
		{
			key: 'fullName',
			title: 'Full Name',
			render: (text: any) => text.fullName,
		},
		{
			key: 'username',
			title: 'Username',
			render: (text: any) => text.username,
		},
		{
			key: 'email',
			title: 'Email',
			render: (text: any) => text.email,
		},
		{
			key: 'phoneNo',
			title: 'Phone Number',
			render: (text: any) => text.phoneNo,
		},
		{
			key: 'trustScore',
			title: 'TrustScore',
			render: (x: any) => <Tag color="red">{x?.trustScore} %</Tag>,
		},
		{
			key: 'referralCount',
			title: 'Referral Count',
			render: (x: any) => x?.referralCount,
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const handleSearchByDate = async () => {
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get(
				'admin/compliance/trustscore/below-average-score',
				{
					params: {
						trustScore,
					},
				}
			);

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSearchByDate();
	}, [location.search]);
	return (
		<UsersListPage>
			<MainSection>
				<ScoreSection>
					<strong>Input Score(In %):</strong>
					<InputNumber
						min={1}
						max={100}
						defaultValue={trustScore}
						onChange={(e) => setTrustScore(e ? Number(e) : 0)}
						addonAfter={
							<Button
								type="primary"
								size="small"
								onClick={() => handleSearchByDate()}
							>
								Search
							</Button>
						}
					/>
				</ScoreSection>
				<MyTableCard>
					<TableHeaderSection
						tableName="Trust Score"
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
					/>
					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.itemsList}
						pagination={false}
					/>

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default TrustScoreUsers;
