import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import { getReferralRewardSettings } from '../../methods/redux/reducers/referrals';
import { defaultImage } from '../../methods/utils/general-utils';
import CreateRewardSetting from './CreateRewardSettings';
import EditReferralRewardSettings from './EditRewardSettingItem';

const Style = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .label {
		color: #aaa;
		font-style: italic;
	}
`;

interface ISettingItem {
	setting: any;
}
const SettingItem = ({ setting }: ISettingItem) => (
	<Style>
		<div>
			<p>{setting.referralRewardStatus ? 'True' : 'False'}</p>
			<p className="label">Active Status</p>
		</div>
		<div>
			<p>
				{setting.referredRewardAmount}{' '}
				{setting.referralRewardCurrencyId?.symbol}
			</p>
			<p className="label">New User Reward Amount</p>
		</div>
		<div>
			<p>
				{setting.referrerRewardAmount}{' '}
				{setting.referralRewardCurrencyId?.symbol}
			</p>
			<p className="label">Old User Reward Amount</p>
		</div>
		<div>
			<div style={{ display: 'flex' }}>
				<img
					src={setting.referralRewardCurrencyId?.iconUrl || defaultImage}
					alt=""
					style={{ marginRight: 7, width: 20, height: 20 }}
				/>
				<p>
					{setting.referralRewardCurrencyId
						? setting.referralRewardCurrencyId?.symbol
						: '--'}
				</p>
			</div>
			<p className="label">Reward Currency</p>
		</div>
		<div>
			<p>{setting.referralRewardMethod}</p>
			<p className="label">Reward Currency</p>
		</div>
		<div>
			<p>
				{setting.referralUserId
					? setting.referralUserId?.username || setting.referralUserId?.email
					: '--'}
			</p>
			<p className="label">Reward Paying User</p>
		</div>
		<div>
			<EditReferralRewardSettings item={setting} />
		</div>
	</Style>
);

const ReferralSettings = () => {
	const dispatch = useAppDispatch();

	const { referralSettings } = useAppSelector((store) => store.referrals);

	useEffect(() => {
		dispatch(getReferralRewardSettings({}));
		dispatch(getCurrencies({}));
	}, []);

	return (
		<Card>
			<h1>Referral Reward Settings</h1>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<p>There should be only one reward settings.</p>
				<CreateRewardSetting />
			</div>
			<Divider />

			{referralSettings.map((item: any) => (
				<SettingItem key={item._id} setting={item} />
			))}
		</Card>
	);
};

export default ReferralSettings;
