import { Card, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import {
	getUsersWithFixedSavings,
	getUsersWithFlexibleSavings,
	getUsersWithNoFixedPlanButHaveMoney,
	getUsersWithNoFlexiblePlanButHaveMoney,
	getUsersWithNoWalletBalance,
} from '../../methods/redux/reducers/reports';
import remakeQueryString from '../../methods/utils/remake-query-string';
import DownloadUserReport from './DownloadUserReport';

const Style = styled(Card)``;

const UsersQueryReports = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { currencies } = useAppSelector((store) => store.currencies);

	const [querySearch, setQuery] = useState<any>();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({});
		}

		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			setQuery(queryData);
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<Style>
			<h1>User Query Reports</h1>
			<p>
				Retrieve & download user details that matches various query parameters
				you choose and set.
			</p>
			<Divider />

			<SearchFilter
				filterOkText="Set Filter Before Download"
				title="Choose Query Parameters, Set Filter, then Download"
				searchFilters={[
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Balance',
						name: 'balance',
						htmlElement: 'input',
					},
					{
						label: 'Default Limit',
						name: 'defaultLimit',
						htmlElement: 'input',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<Divider />

			<div>
				<DownloadUserReport
					title="No Flexible Savings [But Has Money]"
					queryData={querySearch}
					fetchFunction={getUsersWithNoFlexiblePlanButHaveMoney}
				/>

				<DownloadUserReport
					title="No Fixed Savings [But Has Money]"
					queryData={querySearch}
					fetchFunction={getUsersWithNoFixedPlanButHaveMoney}
				/>

				<DownloadUserReport
					title="Zero Wallet Balance"
					queryData={querySearch}
					fetchFunction={getUsersWithNoWalletBalance}
				/>

				<DownloadUserReport
					title="Users With Fixed Savings"
					queryData={querySearch}
					fetchFunction={getUsersWithFixedSavings}
				/>

				<DownloadUserReport
					title="Users With Flexible Savings"
					queryData={querySearch}
					fetchFunction={getUsersWithFlexibleSavings}
				/>
			</div>
		</Style>
	);
};

export default UsersQueryReports;
