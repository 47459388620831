import { DeleteOutlined } from '@ant-design/icons';
import { message, Modal, Button } from 'antd';
import { FC } from 'react';
import baseuri from '../../../methods/baseuri';

interface IRemoveUser {
	email: string;
	usertype: string;
}
const RemoveUser: FC<IRemoveUser> = ({ usertype, email }) => {
	const removeCron = async () => {
		try {
			const res = await baseuri.put(`admin/remove-usertype`, {
				email,
				usertype,
			});
			if (res.status === 200) {
				message.success('removed Successfully');
			} else {
				message.info(res.data.message);
			}
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Remove this user?',
			onOk() {
				removeCron();
			},
		});
	};
	return (
		<Button icon={<DeleteOutlined />} color="red" onClick={confirm}>
			Remove
		</Button>
	);
};

export default RemoveUser;
