import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';
import { getUserWalletBalance } from './user-profile';

export const retrieveDoubleFundings = createAsyncThunk(
	'compliance/retrieveDoubleFundings',
	async (params: any) => {
		const res = await baseuri.get('/admin/compliance/double-fundings', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveFundingRequests = createAsyncThunk(
	'compliance/retrieveFundingRequests',
	async (params: any) => {
		const res = await baseuri.get('/admin/compliance/funding-requests', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveNegativeAccountBalances = createAsyncThunk(
	'compliance/retrieveNegativeAccountBalances',
	async (params: any) => {
		const res = await baseuri.get('/admin/compliance/negative-balances', {
			params,
		});
		return res.data.data;
	}
);

export const retrieveNegativeFlexibleSavingsBalances = createAsyncThunk(
	'compliance/retrieveNegativeFlexibleSavingsBalances',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/compliance/negative-flexible-savings-balances',
			{
				params,
			}
		);
		return res.data.data;
	}
);

export const retrieveBlacklistedUsers = createAsyncThunk(
	'compliance/retrieveBlacklistedUsers',
	async (params: any) => {
		const res = await baseuri.get('/admin/blacklisted-device', {
			params,
		});
		return res.data.data;
	}
);

export const addBlacklistedUser = async (data: any) => {
	try {
		await baseuri.post('/admin/blacklisted-device', data);

		message.success('Added Successfully!');

		return null;
	} catch (e: any) {
		message.error(`Add failed! ${e.toString()}`);
		return null;
	}
};

export const updateBlacklistedUser = async (data: any) => {
	try {
		await baseuri.put('/admin/blacklisted-device', data);

		message.success('Updated Successfully!');

		return null;
	} catch (e: any) {
		message.error(`Update failed! ${e.toString()}`);
		return null;
	}
};

export const deleteBlacklistedUser = async (params: any) => {
	try {
		await baseuri.delete('/admin/blacklisted-device', { params });

		message.success('Deleted Successfully!');

		return null;
	} catch (e: any) {
		message.error(`Deleting failed! ${e.toString()}`);
		return null;
	}
};

export const resetUserBalance = createAsyncThunk(
	'compliance/resetUserBalance',
	async (data: any, { dispatch }) => {
		await baseuri.put('/admin/users/reset-user-balance', data);
		message.success('Reset Complete!');
		dispatch(getUserWalletBalance(data.userId));
	}
);

const complianceRx = createSlice({
	name: 'compliance',
	initialState: {
		fundingRequests: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		negativeAccounts: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		negativeFlexibleAccounts: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		blacklistedUsers: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		doubleFunding: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveDoubleFundings.fulfilled, (store, action) => {
				const state = store;
				state.doubleFunding = action.payload;
			})
			.addCase(retrieveFundingRequests.fulfilled, (store, action) => {
				const state = store;
				state.fundingRequests = action.payload;
			})
			.addCase(retrieveNegativeAccountBalances.fulfilled, (store, action) => {
				const state = store;
				state.negativeAccounts = action.payload;
			})
			.addCase(
				retrieveNegativeFlexibleSavingsBalances.fulfilled,
				(store, action) => {
					const state = store;
					state.negativeFlexibleAccounts = action.payload;
				}
			)
			.addCase(retrieveBlacklistedUsers.fulfilled, (store, action) => {
				const state = store;
				state.blacklistedUsers = action.payload;
			}),
});

export default complianceRx.reducer;
