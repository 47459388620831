import { Divider, Input } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import { getCurrencies } from '../methods/redux/reducers/currency';

const SettingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const Wallets = () => {
	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store: any) => store.currencies);

	useEffect(() => {
		if (currencies.length < 1) {
			dispatch(getCurrencies({}));
		}
	}, []);

	const items = [
		{
			title: 'Crypto Balances',
			desc: 'This gives information about all crypto balances of all the users in Xend Finance',
			link: '/wallets/crypto-balances',
			icon: 'monetization_on',
		},
	];

	return (
		<section>
			<h1>Wallets</h1>

			<Input.Search placeholder="Search" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SettingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SettingsItemStyle>
				))}
		</section>
	);
};

export default Wallets;
