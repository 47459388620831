import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../../../baseuri';

export const retrieveGameBadge = createAsyncThunk(
	'game/retrieveGameBadge',
	async (params: any) => {
		const res = await baseuri.get('/admin/game-rank', { params });
		return res.data.data;
	}
);

export const retrieveSingleGameBadge = createAsyncThunk(
	'game/retrieveSingleGameBadge',
	async (params: any) => {
		const res = await baseuri.get('/admin/game-rank/single', { params });

		return res.data.data;
	}
);

export const createGameActions = createAsyncThunk(
	'game/createGameActions',
	async (data: any) => {
		await baseuri.post('/admin/game-action', data);
	}
);

export const editGameRank = createAsyncThunk(
	'game/editGameRank',
	async (data: any, { dispatch }) => {
		await baseuri.put('/admin/game-rank/', data);
		dispatch(retrieveSingleGameBadge({ gameRankId: data.gameRankId }));
		message.success('Update Success!');
	}
);
