import {
	Avatar,
	Card,
	message,
	Pagination,
	Select,
	Tabs,
	Tag,
	Tooltip,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	Link,
	useLocation,
	// createSearchParams,
	// useNavigate,
	useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { gateWays } from '../../methods/utils/general-utils';
import {
	Country,
	genderEnum,
	statusColor,
} from '../../methods/utils/request-helpers';
import { MyTableCard } from '../users-list/UsersByCountry';
import { MyTable } from '../users-list/UsersList';
import AddKYCLevel from './AddKYCLevel';
import ImageComponent from './ImageComponent';
import LevelDetails from './LevelDetails';
import RejectKYC from './RejectKYC';

const KYCPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;

export const MainTableSection = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: auto;

	& .ant-table-thead > tr > th {
		/* color: #8c8c8c; */
		padding: 16px 25px;
		font-weight: 700;
		background-color: transparent;
		font-size: 12px;
		color: red;
	}

	& .ant-table-tbody > tr > td {
		padding: 16px 25px;
		/* color: red; */
	}
`;
export const TableProfile = styled.div`
	cursor: pointer;
	transition: all 600ms ease;
	text-decoration: none;

	& .avatar {
		background-color: hsl(235.89, 57.48%, 55%);
	}

	&:hover {
		& .avatar {
			background-color: hsl(235.89, 57.48%, 40%);
		}
	}
`;

export const MainColumn = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 0.5em;
`;
export const Level1Section = styled.div`
	min-width: 250px;
`;
export const Levels = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	gap: 1em;
	min-width: 200px;
`;
export const FakeRef = styled.span`
	color: red;
	font-weight: 20px;
`;
export const SearchSection = styled.div`
	display: flex;
	justify-content: space-between;
`;
const kycStatus = [
	{ name: 'Submitted', value: 'submitted' },
	{ name: 'Approved', value: 'approved' },
	{ name: 'Rejected', value: 'rejected' },
	{ name: 'Escalated', value: 'escalated' },
];
const UsersKYC = () => {
	const [refresh, setRefresh] = useState(0);
	const [loading, setLoading] = useState(false);
	const [selectedLevel, setSelectedLevel] = useState({} as any);
	const [levelList, setLevelList] = useState([] as any);

	const [searchParams, setSearchParams] = useSearchParams();

	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
		kycCount: {} as any,
	} as any);

	const [selectedGateway, setSelectedGateway] = useState(
		'https://cf-ipfs.com/ipfs/'
	);
	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			kycLevel: `${searchParams.get('kycLevel') || ''}`,
			kycStatus: `${searchParams.get('kycStatus') || 'submitted'}`,
			pageSize: '50',
			page: `${searchParams.get('page') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};

	const location = useLocation();

	const getKyc = async () => {
		const params = getQueryParams();

		if (!params?.kycLevel) return;
		setLoading(true);
		setData({ itemsList: [], paginator: {} as any, kycCount: {} as any });
		try {
			const res: any = await baseuri.get('admin/kyc/get-kyc-by-level', {
				params: {
					...params,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				setLoading(false);
				message.success('data pulled successfully');
			}
			await setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const getLevelList = async () => {
		const params1 = getQueryParams();

		if (params1?.kycLevel) return;

		const res: any = await baseuri.get(`data/kyclevel`);

		if (res.status === 200) {
			setLevelList(res?.data?.data?.itemsList);
			setSearchParams({
				...params1,
				kycLevel: res?.data?.data?.itemsList[1]?._id,
			});
		}
	};

	useEffect(() => {
		getLevelList();
	}, [location.search]);

	useEffect(() => {
		getKyc();
	}, [location.search, refresh, selectedGateway]);

	const columns = [
		{
			title: 'User',
			key: '_id',
			width: 150,
			render: (text: any) => (
				// eslint-disable-next-line no-underscore-dangle
				<TableProfile>
					<div className="avatarSection">
						{text.profileUrl ? (
							<Avatar src={text?.userId?.profileUrl} />
						) : (
							<Avatar className="avatar">
								{' '}
								{text?.userId?.username?.charAt(0)}
							</Avatar>
						)}
						<Link
							title="View Profile"
							to={`/user-profile/${text.userId._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<p>
								<strong>@{text?.userId?.username}</strong>
							</p>
							<p>{text?.userId?.email}</p>
						</Link>
					</div>
					<br />
					<div>
						<p>
							FULL NAME: <span>{text?.userId?.fullName || '---'}</span>
						</p>
						<p>
							PHONE NO: <span>{text?.userId?.phoneNo || '---'}</span>
						</p>
						<br />
						<p>
							Referred by: <strong>{text?.userId?.refereeName || '---'}</strong>
						</p>
					</div>
				</TableProfile>
			),
		},
		{
			title: <span>{levelList[0]?.levelName || 'Level 1'}</span>,
			key: 9024352,
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.lastName}  ${text?.firstName}  ${text?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{text?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{text?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							)}
						</strong>
					</p>
					<p>
						Gender: <strong>{genderEnum(text?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${text?.residentAddress.length < 15 ? 'sus' : ''}`}
						>
							{' '}
							{text?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(text?.countryCode.toUpperCase()) || 'N/A'}</strong>
					</p>
					<br />
					<br />
					{text?.custodialAdminId && <strong>Updated by Admin</strong>}
				</Level1Section>
			),
		},
		{
			title: <span>{levelList[1]?.levelName || 'Level 2'}</span>,
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Level 2 Status:{' '}
						<strong
							style={{
								color: `${statusColor(text?.userId?.kycLevelTwoStatus)}`,
							}}
						>
							{' '}
							{text?.userId?.kycLevelTwoStatus || 'N/A'}
						</strong>
					</p>
					<p>
						Last Updated by:{' '}
						<strong>
							{text?.userId?.KycLevelTwoApprovedBy?.email || 'N/A'}
						</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={text.idcardEncryptKey}
								url={`${text.idcardUrl}`}
								createdAt={text?.createdAt}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								secret={text.selfieEncryptKey}
								url={`${text.selfieUrl}`}
								createdAt={text?.createdAt}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER: <strong> {text?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					<Levels>
						<Tag
							style={{ cursor: 'pointer' }}
							color="#1e05c2"
							// onClick={() => setVisible(true)}
						>
							<Link to={`/manage-kyc/${text?.userId?._id}`}>
								{`Manage ${text?.userId?.username}'s KYC`}
							</Link>
						</Tag>
						{/* {text?.userId?.kycLevelTwoStatus === 'submitted' && (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(text?.userId?._id, 'admin/kyc/update-levelTwo-kyc')
								}
							>
								Approve
							</Tag>
						)} */}
						{text?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/level-two-kyc"
								userId={text?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels>
				</MainColumn>
			),
		},
		// {
		// 	title: <span>{levelList[2]?.levelName || 'Level 3'}</span>,
		// 	key: 2451,
		// 	render: (text: any) => (
		// 		<MainColumn>
		// 			<p>
		// 				Level 3 Status:{' '}
		// 				<strong
		// 					style={{
		// 						color: `${statusColor(text?.userId?.kycLevelThreeStatus)}`,
		// 					}}
		// 				>
		// 					{' '}
		// 					{text?.userId?.kycLevelThreeStatus || 'N/A'}
		// 				</strong>
		// 			</p>
		// 			<p>
		// 				Last Updated by:{' '}
		// 				<strong>
		// 					{text?.userId?.KycLevelThreeApprovedBy?.email || 'N/A'}
		// 				</strong>
		// 			</p>
		// 			<Levels>
		// 				<p>
		// 					<strong>Bank Statement</strong>
		// 					<ImageComponent
		// 						secret={text.bankStatmentEncryptKey}
		// 						url={text.bankStatementUrl}
		// 					/>
		// 				</p>
		// 				<p>
		// 					<strong>Utility Bill</strong>
		// 					<ImageComponent
		// 						secret={text.utilityEncryptKey}
		// 						url={text.utilityIpfsHash}
		// 					/>
		// 				</p>
		// 			</Levels>
		// 			<Divider />
		// 			{text?.userId?.kycLevelThreeStatus === 'submitted' && (
		// 				<Levels>
		// 					<Tag
		// 						style={{ cursor: 'pointer' }}
		// 						color="blue"
		// 						onClick={() =>
		// 							approve(text?.userId?._id, 'admin/kyc/update-levelThree-kyc')
		// 						}
		// 					>
		// 						Approve
		// 					</Tag>
		// 					<RejectKYC
		// 						text="Reject"
		// 						url="admin/kyc/update-levelThree-kyc"
		// 						userId={text?.userId}
		// 						data={data}
		// 						setData={setData}
		// 						setRefresh={setRefresh}
		// 					/>
		// 				</Levels>
		// 			)}
		// 		</MainColumn>
		// 	),
		// },
		// {
		// 	title: 'Action',
		// 	key: 'createdAt',
		// 	render: (text: any) => (
		// 		<MainColumn>
		// 			<Tag
		// 				color="blue"
		// 				onClick={() =>
		// 					approve(text?.userId?._id, 'admin/kyc/update-both-level-kyc')
		// 				}
		// 			>
		// 				Approve All
		// 			</Tag>
		// 			<RejectKYC
		// 				text="Reject All"
		// 				url="admin/kyc/update-both-level-kyc"
		// 				userId={text?.userId}
		// 				data={data}
		// 				setData={setData}
		// 				setRefresh={setRefresh}
		// 			/>
		// 		</MainColumn>
		// 	),
		// },
	];

	return (
		<KYCPage>
			<Card className="myCard">
				<LevelDetails
					levelList={levelList}
					data={data}
					selectedLevel={selectedLevel}
					setSelectedLevel={setSelectedLevel}
				/>
				<AddKYCLevel />
			</Card>
			<SearchSection>
				<div>
					Select gateway :{' '}
					<Select
						// mode="multiple"
						placeholder="Select GateWay"
						// value={newsletter?.maillist}
						onChange={(e: any) => setSelectedGateway(e)}
						defaultValue="https://cf-ipfs.com/ipfs/"
						// style={{ width: '100%' }}
					>
						{gateWays?.map((gateway: any) => (
							<Select.Option key={gateway.name} value={gateway.value}>
								{gateway.name}
							</Select.Option>
						))}
					</Select>
				</div>
				{/* <div>
					<Input.Search
						type="email"
						placeholder="Search with email only"
						enterButton
						onSearch={(e) => history.push(`/users-kyc?email=${e}`)}
					/>
				</div> */}
			</SearchSection>
			<Card className="myCard">
				<MainTableSection>
					<Tabs
						activeKey={`${searchParams.get('kycStatus')}`}
						onChange={(e) => {
							const n = getQueryParams();
							setSearchParams({ ...n, kycStatus: e });
						}}
					>
						{kycStatus.map((status: any) => (
							<Tabs.TabPane tab={status?.name} key={status?.value}>
								<MyTableCard>
									<TableHeaderSection
										tableName={selectedLevel?.levelName?.toUpperCase() || 'N/A'}
										currentPage={data?.paginator?.currentPage || 0}
										totalRecords={data?.paginator?.itemCount || 0}
									/>

									<MyTable
										loading={loading}
										columns={columns}
										dataSource={data.itemsList}
										pagination={false}
									/>
									<br />
									<Pagination
										onChange={(page, pageSize) => {
											const params1 = Object.fromEntries(
												searchParams.entries()
											);
											setSearchParams({
												...params1,
												pageId: `${page}`,
												perPage: `${pageSize}`,
											});
										}}
										disabled={loading}
										pageSize={data?.paginator?.perPage || 0}
										current={data?.paginator?.currentPage || 0}
										total={data?.paginator?.itemCount || 0}
										showSizeChanger
										size="small"
										showQuickJumper
									/>
								</MyTableCard>
							</Tabs.TabPane>
						))}
					</Tabs>
				</MainTableSection>
			</Card>
		</KYCPage>
	);
};

export default UsersKYC;
