import Axios from 'axios';
import moment from 'moment';
import { refreshToken } from './auth';
import { retrieveToken } from './utils/auth';
import {
	getApiSignature,
	getDeviceDetails,
	getLocation,
	sanitizeRequestData,
} from './utils/request-helpers';

const apiBaseuri = [
	process.env.REACT_APP_BASEURI,
	process.env.REACT_APP_BASEURI_PROD,
];

const authorization = [
	process.env.REACT_APP_AUTHORIZATION,
	process.env.REACT_APP_AUTHORIZATION_PROD,
];

const robotUri = [
	process.env.REACT_APP_NEWSLETTER_Robot,
	process.env.REACT_APP_NEWSLETTER_ROBOT_PROD,
];

const environment = [
	process.env.REACT_APP_BLOCKCHAIN_ENV,
	process.env.REACT_APP_BLOCKCHAIN_ENV_PROD,
];

export const chooseEnvironment = () => {
	const { hostname } = window.location;

	if (
		hostname === 'lab-admin.xend.africa' ||
		hostname === 'lab-admin.xend.finance'
	) {
		return {
			base: apiBaseuri[1],
			name: 'Live',
			auth: authorization[1],
			url: 'https://lab-admin.xend.africa/',
			robot: robotUri[1],
			env: environment[1],
		};
	}
	return {
		base: apiBaseuri[0],
		name: 'Staging',
		auth: authorization[0],
		url: 'https://pro-dashboard.xend.africa/',
		robot: robotUri[0],
		env: environment[0],
	};
};

const baseuri = Axios.create({
	baseURL: `${chooseEnvironment().base}/api`,
	headers: {
		...getLocation(),
		...getDeviceDetails(),
		authorization: String(chooseEnvironment().auth),
		authentication: `Basic ${retrieveToken().token}`,
	},
});

/**
 * Intercept the axios requests and add the necessary header properties
 * that the backend expects
 */

baseuri.interceptors.request.use((request) => {
	let req = request;
	const timestamp = moment().valueOf().toString();

	req = sanitizeRequestData(request).request;

	if (req.headers) {
		req.headers.apisignature = getApiSignature(req, timestamp);
		req.headers.timestamp = timestamp;
		req.headers.authentication = `Basic ${retrieveToken().token}`;
		return req;
	}
	throw new Error('no headers object');
});

/**
 * Handle lack of authentication
 * */
baseuri.interceptors.response.use(
	(res) => res,
	async (error) => {
		// const { status, data } = error.response;
		const { data } = error.response;

		const { action } = data;

		if (action === 'auto_login') {
			const originalRequest = error.config;
			originalRequest._retry = true;
			await refreshToken();
			return baseuri(originalRequest);
		}

		// message.error(data.message);

		if (action === 'please_login') {
			// removeToken();
			// setTimeout(() => {
			// 	window.location.reload();
			// }, 300);
		}

		if (action !== 'signature_failure') {
			// removeToken();
			// message.warn('Youre disconnected');
			// setTimeout(() => {
			// 	window.location.reload();
			// }, 1000);
		}

		throw error;
	}
);

export default baseuri;
