import { Button, Modal } from 'antd';
import styled from 'styled-components';
import baseuri from '../methods/baseuri';

interface IMakeRobotUser {
	userId: string;
	isRobot: boolean;
}

const Style = styled.div``;

const ToggleRobotUser = ({ userId, isRobot }: IMakeRobotUser) => {
	const submitUpdate = async () => {
		await baseuri.put('/admin/users/update-user', {
			userId,
			isRobot: !isRobot,
		});
	};

	const confirm = () => {
		Modal.confirm({
			title: `Are you sure you want to ${
				isRobot ? 'Remove' : 'Set'
			} this user as Robot User?`,
			onOk() {
				submitUpdate();
			},
		});
	};

	return (
		<Style>
			<Button onClick={confirm}>Toggle</Button>
		</Style>
	);
};

export default ToggleRobotUser;
