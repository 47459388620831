import { Button, Modal, Popconfirm } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { removeGameActionFromGame } from '../../../methods/redux/reducers/games/actions/game-actions';
import { useAppDispatch } from '../../../methods/redux/hooks';

const Style = styled.div`
	display: inline-block;
	padding: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
	position: relative;
	background-color: #f8f8f8;

	& .other-details {
		margin-top: 5px;
		font-size: 12px;
		color: #aaa;

		& > * {
			padding: 5px;
			border: 1px solid #aaa;
			display: inline-block;
			margin-right: 5px;
		}
	}

	& .show-on-hover {
		z-index: -1;
		position: absolute;
		padding: 15px;
		height: 100%;
		background-color: #007bff50;
		width: 100%;
		top: 0;
		left: 0;
		transition: all 400ms ease;

		&.show {
			z-index: 1;
		}
	}
`;

const GameActionItem = ({ item, gameId }: { item: any; gameId: any }) => {
	const dispatch = useAppDispatch();

	const divRef = useRef<HTMLDivElement>(null);
	const showRef = useRef<HTMLDivElement>(null);

	const [visible, setVisible] = useState(false);

	const show = () => {
		if (showRef.current) {
			showRef.current.classList.add('show');
		}
	};

	const hide = () => {
		if (showRef.current) {
			showRef.current.classList.remove('show');
		}
	};

	useEffect(() => {
		if (divRef.current) {
			divRef.current.addEventListener('mouseover', show);
			divRef.current.addEventListener('mouseleave', hide);
		}

		return () => {
			if (divRef.current) {
				divRef.current.removeEventListener('mouseover', show);
				divRef.current.removeEventListener('mouseleave', hide);
			}
		};
	}, []);

	return (
		<Style ref={divRef}>
			<div className="show-on-hover" ref={showRef}>
				<Button
					onClick={() => setVisible(true)}
					block
					type="primary"
					size="small"
				>
					Edit
				</Button>
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => {
						dispatch(
							removeGameActionFromGame({ gameActionPointId: item._id, gameId })
						);
					}}
				>
					<Button block size="small" danger>
						Remove
					</Button>
				</Popconfirm>
			</div>
			<p>{item.action}</p>
			<div className="other-details">
				{item.preferredTypeOfAction === 'default' && (
					<>
						<p>Pts: {item.point}</p>
						<p>
							Currency: {item.associatedCurrencyId?.symbol || 'All Currency'}
						</p>
					</>
				)}
				{item.preferredTypeOfAction === 'count' && (
					<>
						<p>Action Count: {item.defaultActionCount}</p>
						<p>Pts: {item.defaultCountPoint}</p>
						<p>Currency: {item.associatedCurrencyId?.symbol}</p>
					</>
				)}
				{item.preferredTypeOfAction === 'volume' && (
					<>
						<p>Volume: {item.usdVolume}</p>
						<p>Point: {item.defaultPointVolume}</p>
						<p>Currency: {item.associatedCurrencyId?.symbol || 'USD'}</p>
					</>
				)}
			</div>

			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				title="Edit Action Points"
			>
				<p>Edit Actions Points for this game.</p>
			</Modal>
		</Style>
	);
};

export default GameActionItem;
