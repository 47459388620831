import styled from 'styled-components';
import { Card, Tag } from 'antd';
import { useEffect } from 'react';
import { Country } from '../../methods/utils/request-helpers';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getUserWalletBalance } from '../../methods/redux/reducers/user-profile';
import AdminWalletBalances from '../admin-profile/WalletBalances';
import UserQuickActions from './quick-actions/UserQuickActions';

const OtherSectionStyle = styled.div`
	display: flex;
	flex: 2;
	flex-direction: column;
	gap: 1rem;
	height: auto;
	width: 100%;
`;

const DeviceCard = styled(Card)`
	display: flex;
	flex-direction: column;

	& .title {
		display: flex;
		gap: 1em;
	}
`;
const DetailsMain = styled.div`
	display: flex;
	flex: 1;

	& .items {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 1em;
	}
`;

const OtherDetailsSection = () => {
	const dispatch = useAppDispatch();
	const { user, walletBalance } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		if (user.profile?._id) {
			dispatch(getUserWalletBalance(user.profile?._id));
		}
	}, [user.profile]);

	const details = user;

	return (
		<OtherSectionStyle>
			<Card>
				<AdminWalletBalances
					balances={walletBalance.cryptoBalances.concat(
						walletBalance.fiatBalances
					)}
					walletAddress={walletBalance.walletAddress}
				/>
			</Card>
			<DeviceCard>
				<div className="title">
					<strong>Registration Device Details</strong>
					<i className="material-icons" style={{ fontSize: 16 }}>
						phone_iphone
					</i>
					<span>{details?.profile?.registrationDeviceDetails?.type}</span>
					{details?.profile?.registrationCoordinates && (
						<a
							target="_blank"
							href={`https://maps.google.com/maps?f=q&q=${details?.profile?.registrationCoordinates?.lat},${details?.profile?.registrationCoordinates?.long}`}
							rel="noreferrer"
						>
							View Location
						</a>
					)}
				</div>
				<DetailsMain>
					<div className="items">
						<div>
							<p>Build</p>
							<strong>
								{details?.profile?.registrationDeviceDetails?.build || '--'}
							</strong>
						</div>
						<div>
							<p>Description</p>
							<strong>
								{details?.profile?.registrationDeviceDetails?.description ||
									'--'}
							</strong>
						</div>
					</div>
					<div className="items">
						<div>
							<p>Serial No</p>
							<strong>
								{details?.profile?.registrationDeviceDetails?.serial || '--'}
							</strong>
						</div>
						<div>
							<p>country</p>
							<strong>
								{Country(
									details?.profile?.registrationDeviceDetails?.cfIpCountry
								) || '--'}
							</strong>
						</div>
					</div>
				</DetailsMain>
			</DeviceCard>
			<DeviceCard>
				<div className="title">
					<strong>Last Login Device Details</strong>
					<i className="material-icons" style={{ fontSize: 16 }}>
						phone_iphone
					</i>
					<span>{details?.lastDeviceLogin[0]?.userDevice?.type || '--'}</span>
					{details?.lastDeviceLogin[0]?.locationCoordinates && (
						<a
							target="_blank"
							href={`https://maps.google.com/maps?f=q&q=${details?.lastDeviceLogin[0]?.locationCoordinates?.lat},${details?.lastDeviceLogin[0]?.locationCoordinates?.long}`}
							rel="noreferrer"
						>
							View Location
						</a>
					)}
				</div>
				<DetailsMain>
					<div className="items">
						<div>
							<p>Build</p>
							<strong>
								{details?.lastDeviceLogin[0]?.userDevice?.build || '--'}
							</strong>
						</div>
						<div>
							<p>Description</p>
							<strong>
								{details?.lastDeviceLogin[0]?.userDevice?.description || '--'}
							</strong>
						</div>
						<div>
							<p>Serial No</p>
							<strong>
								{details?.lastDeviceLogin[0]?.userDevice?.serial || '--'}
							</strong>
						</div>
					</div>
					<div className="items">
						<div>
							<p>Device country</p>
							<strong>
								{Country(
									details?.lastDeviceLogin[0]?.userDevice?.deviceCountry
								) || '--'}
							</strong>
						</div>
						<div>
							<p>IP country</p>
							<strong>
								{Country(
									details?.lastDeviceLogin[0]?.userDevice?.cfIpCountry
								) || '--'}
							</strong>
						</div>
						<div>
							<p>IP Address</p>
							<strong>
								<Tag>
									{details?.lastDeviceLogin[0]?.userDevice?.clientIP || '--'}
								</Tag>
							</strong>
						</div>
					</div>
				</DetailsMain>
			</DeviceCard>
			<UserQuickActions details={details} />
		</OtherSectionStyle>
	);
};
export default OtherDetailsSection;
