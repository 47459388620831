const links = [
	{ id: 11, icon: 'insights', name: 'overview' },
	{ id: 1, icon: 'hub', name: 'core-modules' },
	{ id: 0, icon: 'group', name: 'users' },
	{ id: 19, icon: 'paid', name: 'transactions' },
	{ id: 13, icon: 'account_balance_wallet', name: 'wallets' },
	// { id: 12, icon: 'north_east', name: 'withdrawals' },
	{ id: 3, icon: 'campaign', name: 'alerts' },
	// { id: 5, icon: 'swap_horizontal_circle', name: 'swap' },
	// { id: 6, icon: 'token', name: 'staking' },
	{ id: 7, icon: 'menu_book', name: 'reports' },
	{ id: 21, icon: 'assured_workload', name: 'compliance' },
	{ id: 8, icon: 'smart_toy', name: 'robots' },
	{ id: 14, icon: 'engineering', name: 'maintenance' },
	{ id: 20, icon: 'casino', name: 'extras' },
	{ id: 17, icon: 'smart_toy', name: 'thirdparty' },
	{ id: 9, icon: 'settings', name: 'settings' },
];

export default links;
