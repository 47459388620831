import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

interface IColumnItem {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;

	& .amount {
		display: flex;
		font-weight: bold;
		font-size: 16px;
		& img {
			margin-right: 7px;
			width: 20px;
			height: 20px;
		}
	}

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 12px;
		padding: 15px 0;

		& > * {
			display: inline-block;
			min-width: 200px;
			margin-bottom: 7px;
			padding: 5px;
		}

		& .label {
			font-size: 13px;
			color: #808080;
			font-style: italic;
		}
	}
`;

const FirstSaversColumn = ({ data }: IColumnItem) => (
	<Style>
		<div>
			<Link
				to={`/core-modules/flexible-savings/single/${data._id}`}
				className="title"
			>
				<div className="amount">
					<img alt="" src={data.currencyId?.iconUrl} />
					<p>
						{commas(data.availableBalance)}{' '}
						{data?.currencyId?.symbol || data?.currencyId?.name}
					</p>
				</div>
			</Link>
		</div>
		<div className="details">
			<div>
				<p>
					<Link title="View Profile" to={`/user-profile/${data.userId?._id}`}>
						<p>@{data.userId?.username}</p>
					</Link>
				</p>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>
					{data.groupDepositAmount
						? commas(data.groupDepositAmount)
						: commas(
								Number(data.groupDepositAmount) *
									Number(data.currencyId?.usdValue || 0)
						  )}
				</p>
				<p className="label">Deposit Amount</p>
			</div>
			<div>
				<Link
					to={`/core-modules/flexible-savings/single/${data._id}`}
					className="title"
				>
					<p>{data.title}</p>
					<p className="label">Title</p>
				</Link>
			</div>
			<div>
				<p>{data.groupCycleDurationDays || '--'} days</p>
				<p className="label">Duration</p>
			</div>
			<div>
				<p>{data.status || '--'}</p>
				<p className="label">Status</p>
			</div>
			<div>
				<p>{data.savingsForm || '--'}</p>
				<p className="label">Savings Form</p>
			</div>
			<div>
				<p>
					{data.startDate
						? moment(data.startDate).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Start Date</p>
			</div>
		</div>
	</Style>
);

export default FirstSaversColumn;
