import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

interface IEarningItem {
	item: any;
}

const Style = styled.div`
	padding: 20px;
	margin-bottom: 10px;
	background-color: #f8f8f8;

	& .details {
		margin-top: 5px;
		border-top: 1px dashed #ccc;
		& > * {
			display: inline-block;
			padding: 5px 15px 5px 1px;

			& .label {
				font-style: italic;
				font-size: 10px;
			}
		}
	}
`;

const EarningItem = ({ item }: IEarningItem) => (
	<Style>
		<Link
			target="_blank"
			to={`/core-modules/flexible-savings/single/${item.flexibleSavingsId?._id}`}
		>
			{item.flexibleSavingsId?.title} ({item.currencyId?.symbol})
		</Link>
		<div className="details">
			<div>
				<Link to={`/user-profile/${item.userId?._id}`}>
					@{item.userId?.username}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p style={{ color: 'green' }}>{item.interest}</p>
				<p className="label">Interest</p>
			</div>
			<div>
				<p>{moment(item.earnDate).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Earn Date</p>
			</div>
			<div>
				<p>
					{commas(item.dayBalance)} {item.currencyId?.symbol}
				</p>
				<p className="label">Day Balance</p>
			</div>
			<div>
				<p>{commas(item.apy)}</p>
				<p className="label">APY</p>
			</div>
			<div>
				<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Updated At</p>
			</div>
		</div>
	</Style>
);

export default EarningItem;
