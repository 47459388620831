import { useEffect } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import SavingsAllowableCurrencies from './AllowableCurrencies';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import SavingsSettings from './SavingsSettings';

const SavingsCurrencySettings = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getCurrencies({ currencyForm: 'crypto' }));
	}, []);

	return (
		<div>
			<SavingsAllowableCurrencies />
			<br />
			<SavingsSettings />
		</div>
	);
};

export default SavingsCurrencySettings;
