import { Button, Card } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';

const FilterSection = styled.div``;
const CustomCard = styled(Card)`
	padding: 10px;
	border-radius: 10px;
`;
const MyButton = styled(Button)`
	color: #383838;
	border-radius: 10px;
	margin: 5px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const OldFeeFilter = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(getCurrencies({}));
	}, []);
	const navigate = useNavigate();

	const location = useLocation();
	const selectedFilter = location.pathname.split(/[/,?,&]/)[3];

	const filterEnum = [
		{ name: 'Old Gas Fee', value: 'gas-fees' },
		{ name: 'Old Transaction Fee', value: 'transaction-fees' },
	];
	return (
		<FilterSection>
			<CustomCard>
				<strong>Filters: </strong>
				{filterEnum.map((n) => (
					<MyButton
						className={selectedFilter.includes(`${n.value}`) ? 'selected' : ''}
						key={n.value}
						onClick={() =>
							navigate(
								`/reports/fee-collection/old-${n.value}?pageId=1&perPage=20`
							)
						}
					>
						{n.name}
					</MyButton>
				))}
			</CustomCard>
		</FilterSection>
	);
};

export default OldFeeFilter;
