import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { shortAddress } from '../../methods/utils/general-utils';

interface ISingleFundingRequest {
	item: any;
}

const Style = styled.div`
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 10px;
	background-color: #f8f8f8;
	color: #333;
	text-decoration: none;

	& .details {
		margin-top: 15px;
		border-top: 1px dashed #ccc;
		padding-top: 10px;

		& > * {
			display: inline-block;
			min-width: 100px;
			margin-right: 15px;
			margin-bottom: 5px;

			& p {
				font-size: 14px;
				margin: 0;
				padding: 0;

				&.label {
					color: gray;
					font-size: 14px;
					font-style: italic;
				}
			}
		}
	}
`;

const SingleFundingRequest = ({ item }: ISingleFundingRequest) => {
	const fundingStatusInterpreter = (status: any) => {
		if (String(status) === '0') {
			return 'Customer Funding Pending';
		}
		if (String(status) === '1') {
			return 'Customer Funding Complete';
		}
		if (String(status) === '2') {
			return 'Failed';
		}
		return status;
	};

	return (
		<Style>
			<div>
				<p
					style={{
						color:
							String(item.fundingStatus) === '1'
								? 'var(--green-color)'
								: '#000',
					}}
				>
					<strong>
						<NativeNumber value={item.amountReceived} decimalPlaces={2} />{' '}
						{item.currencyId?.symbol}
					</strong>
				</p>
				<p
					style={{
						color:
							String(item.fundingStatus) === '1'
								? 'var(--green-color)'
								: '#000',
					}}
				>
					{fundingStatusInterpreter(item.fundingStatus)}
				</p>
			</div>
			<div className="details">
				<div>
					{item.userId?._id ? (
						<Link to={`/user-profile/${item.userId?._id}`} target="_blank">
							{item.userId?.username ? (
								<span>@{item.userId.username}</span>
							) : (
								item.userEmail
							)}
						</Link>
					) : (
						item.userEmail
					)}
					<p className="label">Customer</p>
				</div>
				<div>
					<a
						href={`https://${
							item.blockchainNetworkId
								? item.blockchainNetworkId.explorerUrl
								: 'bscscan.com'
						}/tx/${item.transactionHash}`}
						target="_blank"
						rel="noreferrer"
					>
						Hash
					</a>
					<p className="label">Transaction Hash</p>
				</div>
				<div>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://${
							item.blockchainNetworkId
								? item.blockchainNetworkId.explorerUrl
								: 'bscscan.com'
						}/address/${item.depositAddress}#tokentxns`}
					>
						{shortAddress(item.depositAddress)}
					</a>
					<p className="label">Origin Address</p>
				</div>
				<div>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://${
							item.blockchainNetworkId
								? item.blockchainNetworkId.explorerUrl
								: 'bscscan.com'
						}/address/${item.receivingAddress}#tokentxns`}
					>
						{shortAddress(item.receivingAddress)}
					</a>
					<p className="label">Receiving Address</p>
				</div>
				<div>
					<p>{moment(item.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
					<p className="label">Created</p>
				</div>
				<div>
					<p>{moment(item.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
					<p className="label">Updated</p>
				</div>
			</div>
		</Style>
	);
};

export default SingleFundingRequest;
