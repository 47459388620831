import { useEffect, useState } from 'react';
import { Input, Select, Modal, Button } from 'antd';
import { useAppDispatch } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import { submitBlockchainTransactionRecord } from '../../methods/redux/reducers/blockchain-transactions';

interface IPerformBlockchainSavings {
	actionType: any[];
	blockchains: any[];
	currencies: any[];
}

const PerformBlockchainSavings = ({
	actionType,
	blockchains,
	currencies,
}: IPerformBlockchainSavings) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		transactionHash: '',
		blockchainNetworkId: '',
		currencyId: '',
		actionType: '',
	});

	useEffect(() => {
		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
	}, []);

	const recordTransaction = () => {
		dispatch(submitBlockchainTransactionRecord(state));
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>
				Record Blockchain Transaction
			</Button>

			<Modal
				visible={visible}
				footer={[
					<Button type="primary" onClick={recordTransaction}>
						Record Transaction
					</Button>,
				]}
				onCancel={() => setVisible(false)}
				title="Record Blockchain Transaction"
			>
				<div>
					<p>Type of Transaction</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, actionType: e })}
					>
						{actionType.map((item: any) => (
							<Select.Option key={item.value} value={item.value}>
								{item.name}
							</Select.Option>
						))}
					</Select>
				</div>

				<br />

				<div>
					<p>Transaction Hash</p>
					<Input.TextArea
						onChange={(e) =>
							setState({ ...state, transactionHash: e.target.value })
						}
						value={state.transactionHash}
					/>
				</div>

				<br />

				<div>
					<p>Blockchain Network</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, blockchainNetworkId: e })}
					>
						{blockchains.map((item: any) => (
							<Select.Option value={item.value} key={item.value}>
								{item.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<p>Currency</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, currencyId: e })}
					>
						{currencies.map((item: any) => (
							<Select.Option key={item.value} value={item.value}>
								{item.name}
							</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</>
	);
};

export default PerformBlockchainSavings;
