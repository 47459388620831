// import { Line } from '@ant-design/plots';
import { Line } from 'react-chartjs-2';
import {
	Chart as Chartjs,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Divider } from 'antd';
import { useAppSelector } from '../methods/redux/hooks';
import { commas } from '../methods/utils/general-utils';
// import ViewApyByDate from './ViewApyByDate';

Chartjs.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const Styles = styled.div`
	& .top {
		display: flex;
		justify-content: space-between;
	}
	& .info {
		display: grid;
		grid-template-columns: 1fr;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 2fr;
			align-items: center;
		}
	}
	& .apy {
		color: green;
		font-size: 16px;
	}
	& .last-updated {
		font-size: 11.5px;
	}

	& .estimated-payout {
		& > * {
			display: inline-block;
			padding: 5px 20px 5px 1px;

			& .label {
				font-size: 10px;
				font-style: italic;
			}
		}
	}
`;

const TodaysApy = () => {
	const { todaysApy, todayApyArray } = useAppSelector((store) => store.general);
	const { savingsSum } = useAppSelector((store) => store.dashboard);

	const hourLabel = todayApyArray.map(
		(hourData: any) => `${String(hourData.hour)}:00h`
	);

	const hourlApyValue = todayApyArray.map((hourData: any) =>
		String(Number(hourData.apy) * 100)
	);

	const apyAverage =
		hourlApyValue.reduce((prev, current: any) => prev + Number(current), 0) /
		hourlApyValue.length;

	return (
		<Styles>
			<div className="top">
				<h2>Today&apos;s APY</h2>
				<Link to="/reports/apy-history">View APY History</Link>
			</div>
			<div className="info">
				<div>
					<p className="apy">
						<strong>{commas(Number(todaysApy?.apy) * 100)}%</strong>
					</p>
					<p className="last-updated">
						{todaysApy.updatedAt ? (
							<span>
								Updated: {moment(todaysApy.updatedAt).format('lll')} (
								{moment(todaysApy.updatedAt).fromNow()})
							</span>
						) : (
							'Not yet updated today'
						)}
					</p>
					<br />
					<p>
						APY average for today is <strong>{commas(apyAverage)}%</strong>,
						updated by{' '}
						{todaysApy.lastUpdatedByUserId?.username ||
							todaysApy.lastUpdatedByUserId?.email}
					</p>
					<Link
						style={{ textTransform: 'uppercase' }}
						to="/settings/general#daily-apy"
					>
						Settings
					</Link>
					<Divider />
					<div>
						<p>Current XEND Apy: {savingsSum.apy.currentXendApy}%</p>
						<p>
							Total Apy:{' '}
							<strong>{commas(savingsSum.apy.combinedProtocolApy)}%</strong>
						</p>
					</div>
				</div>
				<div>
					{/* , aspectRatio: 2 / 0.5 */}
					<Line
						options={{ responsive: true }}
						data={{
							labels: hourLabel,
							datasets: [
								{
									label: 'Hourly xAuto Apy',
									data: hourlApyValue,
									tension: 0.5,
									borderColor: '#1890ff',
									fill: false,
								},
							],
						}}
					/>
				</div>
			</div>

			<Divider />

			<p>
				<strong>Estimated Savings Payout For Today</strong>
			</p>
			<div className="estimated-payout">
				<div>
					<p style={{ color: 'var(--red-color)' }}>
						<strong>
							$
							{commas(
								Number(
									savingsSum.totalDailyEarnings.flexibleSavingsDailyEarning
								) +
									Number(savingsSum.totalDailyEarnings.fixedSavingsDailyEarning)
							)}
						</strong>
					</p>
					<p className="label">Total Payout</p>
				</div>
				<div>
					<p>
						${commas(savingsSum.totalDailyEarnings.flexibleSavingsDailyEarning)}
					</p>
					<p className="label">Flexible Savings</p>
				</div>
				<div>
					<p>
						${commas(savingsSum.totalDailyEarnings.fixedSavingsDailyEarning)}
					</p>
					<p className="label">Fixed Savings</p>
				</div>
				<div>
					<p>
						{commas(savingsSum.fixedSavings.totalRewardTokenValue)}{' '}
						{savingsSum.fixedSavings.rewardTokenSymbol} ($
						{commas(savingsSum.fixedSavings.totalRewardTokenValueUsd)})
					</p>
					<p className="label">Xend Reward</p>
				</div>
				<div>
					<p>
						<strong>
							$
							{commas(
								Number(
									savingsSum.totalDailyEarnings.flexibleSavingsDailyEarning
								) +
									Number(
										savingsSum.totalDailyEarnings.fixedSavingsDailyEarning
									) +
									Number(savingsSum.fixedSavings.totalRewardTokenValueUsd)
							)}
						</strong>
					</p>
					<p className="label">Grand Total Amount</p>
				</div>
			</div>
		</Styles>
	);
};

export default TodaysApy;
