import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import {
	login,
	updateAddress,
} from '../methods/redux/reducers/web3-connection';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import metamaskLogo from '../metamask.svg';
import {
	isLoggedIn,
	isWalletConnectionAllowed,
	updateAllowWalletConnection,
} from '../methods/utils/auth';

const WalletConnectionStyle = styled.button`
	font-size: 16px;

	& .connected {
		display: flex;

		& img {
			width: 16px;
			margin-right: 7px;
		}
	}
`;

const WalletConnection = () => {
	const dispatch = useAppDispatch();
	const { connectedAddress } = useAppSelector((store) => store.connection);

	const [visible, setVisible] = useState(false);

	const connect = () => {
		if (connectedAddress.length < 1) {
			updateAllowWalletConnection('true');
			dispatch(login(56));
		}
	};

	const shortAddress = (address: string) =>
		`${address.slice(0, 3)}...${address.slice(-4)}`;

	useEffect(() => {
		if (isLoggedIn().response && isWalletConnectionAllowed()) {
			dispatch(login(56));
		}
	}, []);

	return (
		<>
			{connectedAddress.length > 0 ? (
				<WalletConnectionStyle onClick={() => setVisible(true)}>
					<div className="connected" title="Disconnect">
						<img alt="" src={metamaskLogo} />
						<div>{shortAddress(connectedAddress)}</div>
					</div>
				</WalletConnectionStyle>
			) : (
				<button type="button" onClick={() => connect()}>
					Connect
				</button>
			)}
			<Modal
				footer={[
					<Button
						danger
						type="primary"
						onClick={() => {
							dispatch(updateAddress(''));
							updateAllowWalletConnection('false');
							setVisible(false);
						}}
					>
						Disconnect
					</Button>,
				]}
				title="Connected Address"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<p>{connectedAddress}</p>
			</Modal>
		</>
	);
};

export default WalletConnection;
