import { Card, Divider } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import SetApy from './SetApy';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { commas } from '../../methods/utils/general-utils';
import ManageCustodialWallets from './ManageCustodialWallets';
import EditApySource from './EditApySource';
import GenerateNewAddresses from './GenerateNewAddresses';
import { getTodaysApy } from '../../methods/redux/reducers/general';
import { retrieveInternalSettings } from '../../methods/redux/reducers/internal-settings';

const GeneralSettings = () => {
	const dispatch = useAppDispatch();
	const { todaysApy, todayApyArray } = useAppSelector((store) => store.general);

	const hourlApyValue = todayApyArray.map((hourData: any) =>
		String(Number(hourData.apy) * 100)
	);

	const apyAverage =
		hourlApyValue.reduce((prev, current: any) => prev + Number(current), 0) /
		hourlApyValue.length;

	useEffect(() => {
		dispatch(getTodaysApy());
		dispatch(retrieveInternalSettings());
	}, []);

	return (
		<div>
			<Card id="custodial-wallets">
				<ManageCustodialWallets />
			</Card>

			<br />

			<Card id="daily-apy">
				<h2>Daily APY</h2>
				<p>
					The daily savings apy is updated once daily by a cronjob, but an admin
					can still edit / update this values during the course of the day as
					necessary.
				</p>
				<br />
				<p>
					<span>
						<strong>{commas(Number(todaysApy.apy) * 100)}%</strong>
					</span>{' '}
					|{' '}
					<span>
						{todaysApy.updatedAt ? (
							<span>
								Last updated{' '}
								<strong>{moment(todaysApy.updatedAt).format('lll')}</strong> by{' '}
								<strong title={todaysApy.lastUpdatedByUserId?.email}>
									{todaysApy.lastUpdatedByUserId?.username ||
										todaysApy.lastUpdatedByUserId?.email}
								</strong>
							</span>
						) : (
							'Not yet updated today'
						)}{' '}
					</span>
				</p>

				<p>
					APY average for today is <strong>{commas(apyAverage)}%</strong>
				</p>

				<br />

				<SetApy apyAverage={apyAverage} />

				<Divider />

				<p>
					<strong>APY Sources</strong>
				</p>
				<p>
					Update which source the apy cronjob will attempt to use to set the
					daily apys for the stable currency savings on the mobile app. Retrieve
					the source text from the apy cronjob app.
				</p>
				<EditApySource />
			</Card>

			<br />

			<GenerateNewAddresses />

			<br />
		</div>
	);
};

export default GeneralSettings;
