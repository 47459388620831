import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const updateInternalSettings = createAsyncThunk(
	'internal-settings/updateInternalSettings',
	async (data: any) => {
		const res = await baseuri.put('/admin/internal-settings', data);
		return res.data.data;
	}
);

export const retrieveInternalSettings = createAsyncThunk(
	'internal-settings/retrieveInternalSettings',
	async () => {
		const res = await baseuri.get('/admin/internal-settings');
		return res.data.data;
	}
);

//
const settingsRx = createSlice({
	name: 'internal-settings',
	initialState: {
		_id: '',
		activeNetworkChainId: null,
		activeProtocol: null,
		xendSavingsRewardTokenAPR: 50,
		fixedSavingsRewardCryptoTokenId: null, // WARNING: must be crypto
		fixedSavingsRewardBonusPayerUserId: null,
		payFixedSavingsRewardBonus: false,
		minStableCurrencySavingAmounts: 100, // general
		maxStableCurrencySavingAmounts: 100, // general
		minCurrencyStakingAmounts: 100, // staking
		maxCurrencyStakingAmounts: 100, // staking
		allowStableCurrencyFixedSavings: false,
		allowStableCurrencyFlexibleSavings: false,
		allowStableCurrencyEsusuSavings: false,
		allowJoinEsusuMultiple: false,
		allowPublicViewEsusuSavings: false,
		maxAllowedStableCurrencyFixedSavingsCount: 5,
		maxAllowedStableCurrencyFlexibleSavingsCount: 5,
		maxAllowedStableCurrencySavingsTopUpCount: 5,
		fixedSavingsTopUpLifespan: 20,
		allowCurrencyStaking: false,
		allowFlexibleAutoSave: false,
		allowFixedAutoSave: false,
		generateNewAddresses: null,
		allowSwapping: false,
		swapUseTokenAsFee: false,
		swapFeeTokenId: { symbol: '', _id: '', iconUrl: '' },
		swapDefaultSlippage: 0,
		swapTransactionDeadlineMinutes: 10,
		swapTransactionSpeed: 'standard', // standard, fast, instant
		swapDefaultProtocol: {
			_id: '',
			name: '',
			blockchainNetwork: { abbreviation: '' },
		},
		swapUseXendAsFee: false,
		fixedSavingsTerminationPenaltyPercentage: 40,
		globalFeeCollectorUserId: {
			_id: '',
			username: '',
			email: '',
		},
		gameFatherId: {
			_id: '',
			username: '',
			email: '',
		},
		apySource: '',
		newDeviceLoginWithdrawalSuspensionHour: '0',
		maxFixedSavingsDurationDays: 0,
		minFixedSavingsDurationDays: 0,

		loanMasterUserId: null,
		maxDurationDays: 0,
		minDurationDays: 0,
		minLoanAmountUsd: 0,
		maxLoanAmountUsd: 0,
		allowLoans: false,
		allowGame: false,
		xendPrice: 0,
		xendAPR: 0,
		gamePointsEquivalentToOneDollar: 0,
		gameRewardCurrencyId: {
			_id: '',
			iconUrl: '',
			symbol: '',
		},
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(updateInternalSettings.fulfilled, (store, action) => {
				const state = { ...store, ...action.payload };
				message.success('Update Success!');
				return state;
			})
			.addCase(retrieveInternalSettings.fulfilled, (store, action) => {
				const state = { ...store, ...action.payload };
				return state;
			});
	},
});

export default settingsRx.reducer;
