import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import UpdateBlacklistedItem from './UpdateBlacklistedItem';

interface IBlacklistedItem {
	record: any;
}

const Style = styled.div`
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 10px;
	background-color: #f8f8f8;
	color: #333;
	text-decoration: none;

	& .details {
		margin-top: 15px;
		border-top: 1px dashed #ccc;
		padding-top: 10px;

		& > * {
			display: inline-block;
			min-width: 100px;
			margin-right: 15px;
			margin-bottom: 5px;

			& p {
				font-size: 14px;
				margin: 0;
				padding: 0;

				&.label {
					color: gray;
					font-size: 14px;
					font-style: italic;
				}
			}
		}
	}
`;

const BlacklistedItem = ({ record }: IBlacklistedItem) => (
	<Style>
		<div>
			<Link to={`/user-profile/${record.userId?._id}`}>
				{record.userId?.email}
			</Link>
			<p>{record.blacklisted ? 'Blacklisted' : 'No Longer Blacklisted'}</p>
		</div>
		<div className="details">
			<div>
				<Link to={`/user-profile/${record.userId?._id}`}>
					{record.userId?.username ? (
						<span>@{record.userId?.username}</span>
					) : (
						record.userId?.email
					)}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>{record.deviceId}</p>
				<p className="label">Device Id</p>
			</div>
			<div>
				<p>{moment(record.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Date Added to List</p>
			</div>
			<div>
				<p>{moment(record.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Last Updated</p>
			</div>
			<div>
				<UpdateBlacklistedItem
					blacklisted={record.blacklisted}
					recordId={record._id}
				/>
			</div>
		</div>
	</Style>
);

export default BlacklistedItem;
