import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	retrieveGameSubscriberPoints,
	retrieveSingleGame,
} from '../../../methods/redux/reducers/games';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import GameRewardPointItem from '../tasks/GameRewardPointItem';

const Style = styled(Card)``;

const GameRewards = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { gameId } = useParams();

	const { subscribersPoints } = useAppSelector((store) => store.games);
	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};
	const totalPoints = subscribersPoints.itemsList
		.map((a: any) => a.point)
		.reduce((index, cc) => index + cc, 0);
	useEffect(() => {
		dispatch(retrieveGameSubscriberPoints({ gameId }));
		dispatch(retrieveSingleGame({ gameId }));
	}, [gameId]);

	console.info('adsd');
	return (
		<Style>
			<h1>Game Reward Points </h1>
			<Divider />
			<h2>Total Game Points: {totalPoints}</h2>
			<br />
			<Divider />
			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={subscribersPoints.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>
			<br />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={subscribersPoints.paginator.itemCount}
				current={subscribersPoints.paginator.currentPage}
				pageSize={subscribersPoints.paginator.perPage}
			/>

			<br />
			{subscribersPoints.itemsList.map((userPoints: any) => (
				<GameRewardPointItem userPoints={userPoints} key={userPoints._id} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={subscribersPoints.paginator.itemCount}
				current={subscribersPoints.paginator.currentPage}
				pageSize={subscribersPoints.paginator.perPage}
			/>
		</Style>
	);
};

export default GameRewards;
