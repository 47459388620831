import { Divider, Input } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

export const SettingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const Reports = () => {
	const items = [
		{
			title: 'Accounting Report',
			desc: 'Reports tailored for Accounting with details of transactions and profits.',
			link: '/reports/accountant',
			icon: 'track_changes',
		},
		{
			title: 'APY History (Savings)',
			desc: 'Historical data for savings apy on previous days',
			link: '/reports/apy-history',
			icon: 'history',
		},
		{
			title: 'Referral Report',
			desc: 'Details of referrals on Xend Finance.',
			link: '/reports/referral',
			icon: 'supervisor_account',
		},
		{
			title: 'Fee Collection Report',
			desc: 'Manage fees collected since Xend Finance since PRO version migration.',
			link: `/reports/fee-collection`,
			icon: 'attach_money',
		},
		// {
		// 	title: 'Requests Report',
		// 	desc: 'This gives information about the total requests, successful requests bad requests and error requests',
		// 	link: `/reports/maintenance`,
		// 	icon: 'request_quote',
		// },
		// {
		// 	title: 'Swap Report',
		// 	desc: 'Report / admin functions on swapping of different token on the blockchain.',
		// 	link: '/reports/swap',
		// 	icon: 'swap_horizontal_circle',
		// },
		{
			title: 'Staking Report',
			desc: 'Report / admin functions on staking of different token on the blockchain.',
			link: `/reports/all-staking`,
			icon: 'token',
		},
		// {
		// 	title: 'Fees Expenses Report',
		// 	desc: 'Here the summary of the fees spent on gas and transaction fees',
		// 	link: `/report/fee-collection/old-gas-fees`,
		// 	icon: 'credit_card',
		// },
		{
			title: 'Transactions Per User',
			desc: 'Here the admin will view the sorted transactions agregate of users',
			link: `/reports/transactions-per-user-new?transactionForm=fixed_savings&startDate=${moment()
				.subtract(2, 'day')
				.format('YYYY-MM-DD')}&endDate=${moment().format(
				'YYYY-MM-DD'
			)}&perPage=10&pageId=1&sort=desc`,
			icon: 'face_5',
		},
		// {
		// 	title: 'Old Transactions Per User',
		// 	desc: 'Here the admin will view the old transaction per user',
		// link: `/reports/transactions-per-user-old?transactionState=8&startDate=${moment()
		// 	.subtract(2, 'day')
		// 	.format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`,
		// 	icon: 'face_5',
		// },
		{
			title: 'PaidOut Fixed Savings Report',
			desc: 'Report on stable currency savings, bulk blockchain savings, etc.',
			link: `/report/fixed-savings/paidout`,
			icon: 'savings',
		},
		{
			title: 'PaidOut Flexible Savings Report',
			desc: 'Report on stable currency savings, bulk blockchain savings, etc.',
			link: `/report/flexible-savings/paidout`,
			icon: 'savings',
		},
		{
			title: 'All Transactions Report',
			desc: 'Report on transactions volume and count for all types of transactions on Xend Finance.',
			link: `/reports/transaction-statement`,
			icon: 'receipt_long',
		},
	];

	return (
		<section>
			<PageHeader>Reports</PageHeader>

			<Input.Search placeholder="Search" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SettingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SettingsItemStyle>
				))}
		</section>
	);
};

export default Reports;
