import { useState } from 'react';
import { Input, Button, Modal } from 'antd';
import { useAppDispatch } from '../../methods/redux/hooks';
import { addThirdPartyLiquidityProvider } from '../../methods/redux/reducers/liquidity-providers';

const AddThirdPartyLiquidityProvider = () => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		name: '',
		website: '',
	});

	const submit = () => {
		dispatch(addThirdPartyLiquidityProvider(state));
		setVisible(false);
	};

	return (
		<div>
			<Button onClick={() => setVisible(true)}>Add Liquidity Provider</Button>

			<Modal
				open={visible}
				onOk={submit}
				onCancel={() => setVisible(false)}
				title="Add ThirdParty Liquidity Provider"
			>
				<div>
					<p>Liquidity Provider&apos;s Name</p>
					<Input
						size="large"
						value={state.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Website</p>
					<Input
						size="large"
						value={state.website}
						onChange={(e) => setState({ ...state, website: e.target.value })}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default AddThirdPartyLiquidityProvider;
