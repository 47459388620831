import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';
import { hideEmail } from '../../methods/utils/request-helpers';

interface IColumnItems {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const ColumnFixed = ({ data }: IColumnItems) => (
	<Style>
		<div>
			<p>
				<Link title="View Profile" to={`/user-profile/${data?.userId?._id}`}>
					<p> @{data?.userId?.username}</p>
					<p> {hideEmail(data?.userId?.fullName)}</p>
				</Link>
			</p>
			<p className="label">User</p>
		</div>
		<div>
			<Link
				to={`/core-modules/fixed-savings/single/${data._id}`}
				className="title"
			>
				<p>{data?.title}</p>
				<p className="label">Title</p>
				<p>{data?.durationDays || 0} Days</p>
				<p className="label">Duration</p>
			</Link>
		</div>
		<div>
			<p>{commas(data?.cacheCapital)}</p>
			<p className="label">Capital</p>
			<p>{data?.status}</p>
			<p className="label">Status</p>
		</div>
		<div>
			<p>
				{data.startDate
					? moment(data.startDate).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Start Date</p>
			<p>
				{data.endDate
					? moment(data.endDate).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">End Date</p>
		</div>
		<div>
			<p>{commas(data?.cacheProfit)}</p>
			<p className="label">Profit</p>
			<p>{`${commas(data?.cacheRewardTokenValue)}`}</p>
			<p className="label">Token Value</p>
		</div>
		<div>
			<p>{data?.payoutStatus || '--'}</p>
			<p className="label">Payout Status</p>
			<p>{data?.payoutInitiatorUserId || '--'}</p>
			<p className="label">Payout Initiator</p>
		</div>
		<div>
			<p>{data?.requestTerminationStatus || '--'}</p>
			<p className="label">Termination Status</p>
			<p>
				{data.requestTerminationDate
					? moment(data.requestTerminationDate).format(
							'MMM DD, YY / hh:mm:ss a'
					  )
					: '--'}
			</p>
			<p className="label">Payout Initiator</p>
		</div>

		<div>
			<p>
				{data.createdAt
					? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Date created</p>
		</div>
	</Style>
);

export default ColumnFixed;
