import { useState } from 'react';
import { Button, Modal } from 'antd';
import { approveTerminationRequest } from '../../methods/redux/reducers/fixed-savings';
import { commas } from '../../methods/utils/general-utils';

interface IApproveTerminationRequest {
	fixedSavingsId: string;
	data: any;
}

const ApproveTerminationRequest = ({
	fixedSavingsId,
	data,
}: IApproveTerminationRequest) => {
	const [loading, setLoading] = useState(false);

	const proceedWithTermination = async () => {
		setLoading(true);
		await approveTerminationRequest({
			fixedSavingsId,
		});
		setLoading(false);
	};

	const confirm = () => {
		Modal.confirm({
			title: (
				<span>
					{' '}
					Are you sure you want to Liquidate this Fixed Saving?{' '}
					<p>
						The customer will loose{' '}
						{Number(data?.RequestTerminationPenaltyPercentage) * 100}% of the
						profile as liquidation charge
					</p>
					<br />
					<p>
						Liquidation/Termination Charge:{' '}
						<strong>
							{commas(
								((Number(data.cacheCapital) + Number(data.cacheProfit)) *
									Number(data?.RequestTerminationPenaltyPercentage)) /
									100
							)}{' '}
							{data?.currencyId?.symbol || data?.currencyId?.name}
						</strong>
					</p>
					<p>
						Amount to receive:{' '}
						<strong>
							{commas(
								Number(data.cacheCapital) +
									Number(data.cacheProfit) -
									(Number(data.cacheCapital) + Number(data.cacheProfit)) *
										Number(data?.RequestTerminationPenaltyPercentage)
							)}{' '}
							{data?.currencyId?.symbol || data?.currencyId?.name}
						</strong>
					</p>
				</span>
			),
			onOk() {
				proceedWithTermination();
			},
		});
	};

	return (
		<Button loading={loading} disabled={loading} onClick={confirm}>
			Approve Termination Request
		</Button>
	);
};

export default ApproveTerminationRequest;
