import { Outlet, Navigate } from 'react-router-dom';
import { isLoggedIn } from '../methods/utils/auth';

const Authenticated = () => {
	if (isLoggedIn().response) {
		return <Navigate to="/" replace />;
	}
	return <Outlet />;
};

export default Authenticated;
