import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const submitBlockchainTransactionRecord = createAsyncThunk(
	'blockchain-transactions/submitBlockchainTransactionRecord',
	async (data: any) => {
		const res = await baseuri.post('/admin/blockchain-transactions', data);
		message.success('Record submitted');
		return res.data.data;
	}
);

export const getBlockchainTransactionRecords = createAsyncThunk(
	'blockchain-transactions/getBlockchainTransactionRecords',
	async (params: any) => {
		const res = await baseuri.get('/admin/blockchain-transactions', { params });
		return res.data.data;
	}
);

const blockchainTransactionRx = createSlice({
	name: 'blockchain-transactions',
	initialState: {
		transactions: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		loading: false,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getBlockchainTransactionRecords.fulfilled, (store, action) => {
				const state = store;
				state.transactions = { ...action.payload };
				state.loading = false;
			})
			.addCase(submitBlockchainTransactionRecord.fulfilled, (store) => {
				const state = store;
				state.loading = false;
			});
	},
});

export default blockchainTransactionRx.reducer;
