import { Button, message, Modal, Popconfirm, Select, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface IRejectKYC {
	userId: any;
	url: string;
	text: string;
	data: any;
	setData: Function;
	setRefresh: Function;
}
const RejectKYC: FC<IRejectKYC> = ({
	userId,
	url,
	text,
	data,
	setData,
	setRefresh,
}) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [updateDetails, setUpdateDetails] = useState({
		id: userId?._id,
		kycStatus: 'rejected',
		RejectionReason: 'your face is not clear in the selfie/ID card',
		RejectionMessage: '',
	});

	const updateEnum = [
		{
			name: 'Your face is not clear in the selfie/ID card',
			value: 'FACE_NOT_CLEAR',
		},
		{
			name: 'Image not found',
			value: 'NO_IMAGE',
		},
		{
			name: 'There was not enough light',
			value: 'NO_LIGHT',
		},
		{
			name: 'Your request is rejected because you are too young or you entered a wrong date of birth',
			value: 'AGE_ISSUE',
		},
		{
			name: 'Your uploaded your ID to selfie section instead of selfie and viz',
			value: 'WRONG_PLACEMENT_OF_SELFIE',
		},
		{
			name: 'You uploaded same item in both selfie and ID',
			value: 'NO_SELFIE',
		},
		{
			name: 'The name on the ID card is not the same as your name',
			value: 'NAME_MISMATCH',
		},
		{
			name: 'The Content of the ID card is practically different from your profile data',
			value: 'DATA_MISMATCH',
		},
		{
			name: 'The Gender identified in photo is not same as yours',
			value: 'GENDER_MISMATCH',
		},
		{
			name: 'The ID card type used is not among the supported ID card list',
			value: 'UNSUPPORTED_ID_TYPE',
		},
	];

	const handleUpdate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(url, updateDetails);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(
					`KYC ${updateDetails?.kycStatus || 'updated'} successfully `
				);
				setUpdateDetails({
					id: userId?._id,
					kycStatus: 'rejected',
					RejectionReason: 'your face is not clear in the selfie/ID card',
					RejectionMessage: '',
				});
				const k = data.itemsList.filter(
					(n: any) => n?.getUserKyc?.userId?._id !== userId?._id
				);
				setData({
					...data,
					itemsList: k,
				});
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.error(res?.data?.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<>
			<Tag
				style={{ cursor: 'pointer' }}
				color="#f57070"
				onClick={() => setVisible(true)}
			>
				{text}
			</Tag>
			<Modal
				title={[
					<p>
						Update <strong> {`${userId?.username}'s`} </strong> KYC Status
					</p>,
				]}
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleUpdate}>
						<Button loading={loading} type="primary" danger>
							Reject
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					<p>Rejection Reason : </p>
					<Select
						style={{ width: 200 }}
						defaultValue="FACE_NOT_CLEAR"
						value={updateDetails?.RejectionReason}
						onChange={(e) => {
							setUpdateDetails({
								...updateDetails,
								RejectionReason: e,
							});
						}}
					>
						{updateEnum?.map((n: any) => (
							<Select.Option key={n.value} value={n.name}>
								{n.value}
							</Select.Option>
						))}
					</Select>
				</p>
				<p>
					Message: <strong>{updateDetails?.RejectionReason}</strong>
				</p>

				<p>
					Additional Message :{' '}
					<TextArea
						value={updateDetails?.RejectionMessage}
						onChange={(e: any) =>
							setUpdateDetails({
								...updateDetails,
								RejectionMessage: e.target.value,
							})
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default RejectKYC;
