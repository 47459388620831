import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import cryptojs from 'crypto-js';
import { chooseEnvironment } from '../methods/baseuri';
import {
	getDeviceDetails,
	getLocation,
	trimObj,
} from '../methods/utils/request-helpers';
import { retrieveToken } from '../methods/utils/auth';

const base = fetchBaseQuery({
	baseUrl: `${chooseEnvironment().base}/api`,
});

const customBaseQuery = (args: any, api: any, extraOptions: any) => {
	let { params, body } = args;

	params = trimObj(params);
	body = trimObj(body);

	const timestamp = moment().valueOf().toString();

	// const { token, accessSecret } = retrieveToken();
	let jwt = null;
	let accessSecret = null;
	const token = retrieveToken();
	if (token) {
		jwt = token.token;
		accessSecret = token.accessSecret;
	}

	const payload = new URLSearchParams({
		...params,
		...body,
		timestamp,
		'api-key': jwt,
		'api-secret': accessSecret,
	}).toString();

	const signature = cryptojs
		.HmacSHA256(payload, accessSecret)
		.toString(cryptojs.enc.Hex);

	const headers = {
		...extraOptions?.headers,
		...getLocation(),
		...getDeviceDetails(),
		timestamp,
		authentication: `Basic ${jwt}`,
		authorization: String(chooseEnvironment().auth),
		apisignature: signature,
	};

	return base({ ...args, headers }, api, extraOptions);
};

const api = createApi({
	reducerPath: 'xend-api',
	baseQuery: customBaseQuery,
	endpoints: () => ({}),
});

export default api;
