import { useEffect } from 'react';
import SupportedBanks from './SupportedBanks';
import { retrieveFiatSupportedBanks } from '../../../methods/redux/reducers/currency';
import { useAppDispatch } from '../../../methods/redux/hooks';
import CustodialAccounts from './CustodialAccounts';

const ManageFiatBanks = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(retrieveFiatSupportedBanks({}));
	}, []);

	return (
		<>
			<CustodialAccounts />

			<br />

			<SupportedBanks />
		</>
	);
};

export default ManageFiatBanks;
