import { Button, Divider, Modal } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateSupportedCurrency } from '../../methods/redux/reducers/currency';

const ItemStyle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div:first-child {
		display: flex;
		align-items: center;

		& img {
			margin-right: 10px;
		}
	}
`;

const AddLoanCurrency = () => {
	const dispatch = useAppDispatch();
	const { currencies, loading } = useAppSelector((store) => store.currencies);
	const [visible, setVisible] = useState(false);
	const [availableCurrencies, setAvailableCurrencies] = useState<Array<any>>(
		[]
	);

	const makeStakable = (currencyId: string) => {
		dispatch(updateSupportedCurrency({ currencyId, allowLoans: true }));
	};

	useEffect(() => {
		const filter = currencies.filter((currency: any) => !currency.allowLoans);
		setAvailableCurrencies(filter);
	}, [currencies]);

	return (
		<>
			<Modal
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Add Loan Currency"
			>
				{availableCurrencies.map((currency: any) => (
					<div key={currency._id}>
						<ItemStyle>
							<div>
								<img
									alt={currency.name}
									src={currency.iconUrl}
									width={30}
									height={30}
								/>
								<p>{currency.symbol}</p>
							</div>
							<div>
								<Button
									type="primary"
									loading={loading}
									onClick={() => makeStakable(currency._id)}
								>
									Enable Loan
								</Button>
							</div>
						</ItemStyle>
						<Divider />
					</div>
				))}
			</Modal>

			{availableCurrencies.length > 0 && (
				<button type="button" onClick={() => setVisible(true)}>
					Add
				</button>
			)}
		</>
	);
};

export default AddLoanCurrency;
