import { CommentOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm } from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { getUserProfileById } from '../../../methods/redux/reducers/user-profile';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1em;
`;

const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface IQuickEmail {
	userId: any;
	details: any;
}

const UpdateAdminComment: FC<IQuickEmail> = ({ userId, details }) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);

	const [adminLastComment, setLastComment] = useState('');

	useEffect(() => {
		if (details) {
			setLastComment(details.profile?.adminLastComment);
		}
	}, [details]);

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(`admin/users/edit-admin-comment`, {
				adminLastComment,
				userId,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(res.data.message);
				dispatch(getUserProfileById(userId));
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<div>
			<Button onClick={() => setVisible(true)} icon={<CommentOutlined />}>
				Update Admin Comment
			</Button>

			<MyModal
				title="Update Admin Last Comment"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						<p>Comment</p>
						<textarea
							style={{ width: '100%' }}
							value={adminLastComment}
							onChange={(e: any) => setLastComment(e.target.value)}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default UpdateAdminComment;
