import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import OldFeeFilter from './OldFeeFilter';
import { getOldTransactionFee } from '../../methods/redux/reducers/reports';
import OldTransactionFeeAggregate from './OldTransactionFeeAggregate';
import ColumnOldTransactionFee from './ColumnOldTransactionFee';
import remakeQueryString from '../../methods/utils/remake-query-string';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const OldTransactionFees = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const { user } = useAppSelector((store) => store.userProfile);
	const { OldTransactionFeeReport } = useAppSelector((store) => store.reports);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(OldTransactionFeeReport.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getOldTransactionFee({ ...queryData, userId }));
			} else {
				dispatch(getOldTransactionFee(queryData));
			}
		}
	}, [searchParams]);

	return (
		<Card>
			<h1>
				Old Transaction Fees Expenses Report{' '}
				{userId && (
					<span>
						{' '}
						for{' '}
						<Link
							title="View Profile"
							to={`/user-profile/${user.profile?._id}`}
						>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>
			{/* <p>All the deposit of funds from an external wallet address.</p> */}

			<OldFeeFilter />
			<br />
			<OldTransactionFeeAggregate
				data={OldTransactionFeeReport.transactionFeeRecords}
			/>

			<br />

			<SearchFilter
				title="Search Old Gas Fees Filters "
				searchFilters={[
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					{OldTransactionFeeReport.paginator.itemCount || '0'} items
				</strong>{' '}
				found
			</p>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={OldTransactionFeeReport.paginator.itemCount}
				current={OldTransactionFeeReport.paginator.currentPage}
				pageSize={OldTransactionFeeReport.paginator.perPage}
			/>
			<br />

			{OldTransactionFeeReport.itemsList.map((item: any) => (
				<ColumnOldTransactionFee key={item?._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={OldTransactionFeeReport.paginator.itemCount}
				current={OldTransactionFeeReport.paginator.currentPage}
				pageSize={OldTransactionFeeReport.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default OldTransactionFees;
