import moment from 'moment';
import styled from 'styled-components';
import { Divider, Card } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getSingleFlexibleSavingsProfile } from '../../methods/redux/reducers/flexible-savings';
import FlexibleSavingsItem from './FlexibleSavingsItem';
import TransactionItem from '../../transactions/transaction-statments/TransactionItem';
import DailyEarnings from './DailyEarnings';

const SavingsDetailsStyle = styled.div`
	& .title {
		text-transform: capitalize;
	}

	& .balances > * {
		display: inline-block;
		margin-right: 20px;

		& .amount {
			font-weight: bold;
			font-size: 18px;

			&.green {
				color: #1a846d;
			}
			&.blue {
				color: blue;
			}
			&.red {
				color: #d52a4f;
			}
			&.gray {
				color: #333;
			}
		}
	}

	& .profile-details {
		& > div {
			display: inline-block;
			padding: 5px;
			margin-right: 5px;

			& .label {
				font-size: 12px;
				color: gray;
			}
		}
	}
`;

const SingleFlexibleSavingsPage = () => {
	const params = useParams();
	const dispatch = useAppDispatch();

	const { singleFlexibleSavingsProfile } = useAppSelector(
		(store) => store.flexibleSavings
	);

	useEffect(() => {
		dispatch(getSingleFlexibleSavingsProfile(params.flexibleSavingsId));
	}, []);

	const {
		transactionStatements,
		savingsProfile,
		latestSavingsItems,
		ledgerBalance,
	} = singleFlexibleSavingsProfile;

	return (
		<SavingsDetailsStyle>
			<Card>
				<Link
					title="View User Profile"
					to={`/user-profile/${savingsProfile?.userId?._id}`}
				>
					@{savingsProfile?.userId?.username}
				</Link>
				<h2 className="title">
					{savingsProfile.title} ({savingsProfile.currencyId?.symbol}
					{' / '}
					{savingsProfile.savingsForm})
				</h2>
				<div className="profile-details">
					<div>
						<p>
							{moment(savingsProfile.startDate).format(
								'MMM DD, YY / hh:mm:ss a'
							)}
						</p>
						<p className="label">Start Date</p>
					</div>
					<div>
						<p>--</p>
						<p className="label">End Date</p>
					</div>
					<div>
						<p>{savingsProfile.status}</p>
						<p className="label">Status</p>
					</div>

					<div>
						{savingsProfile.dailyProfitMakerLastBuildDate ? (
							<p>
								{moment(savingsProfile.dailyProfitMakerLastBuildDate).format(
									'MMM DD, YY / hh:mm:ss a'
								)}{' '}
								(
								{moment(savingsProfile.dailyProfitMakerLastBuildDate).fromNow()}
								)
							</p>
						) : (
							<p>--</p>
						)}
						<p className="label">Daily Profit Maker Last Build Date</p>
					</div>
					<div>
						<p>@{savingsProfile.lastUpdatedByUserId?.username}</p>
						<p className="label">Last Updated By</p>
					</div>
					<div>
						<p>
							{moment(savingsProfile.updatedAt).format(
								'MMM DD, YY / hh:mm:ss a'
							)}{' '}
						</p>
						<p className="label">Updated At</p>
					</div>
				</div>

				<Divider />

				<div className="balances">
					<div>
						<p>Available Balance:</p>
						<p className="amount green">
							{savingsProfile.availableBalance}{' '}
							{savingsProfile.currencyId?.symbol}
						</p>
					</div>
					<div>
						<p>Ledger Balance:</p>
						<p className="amount blue">
							{ledgerBalance} {savingsProfile.currencyId?.symbol}
						</p>
					</div>
					<div>
						<p>Unmigrated Balance:</p>
						<p className="amount gray">
							{Number(ledgerBalance) -
								Number(savingsProfile.availableBalance || 0)}{' '}
							{savingsProfile.currencyId?.symbol}
						</p>
					</div>
					<div>
						<p>Total Withdrawn</p>
						<p className="amount red">
							{savingsProfile.totalWithdrawn}{' '}
							{savingsProfile.currencyId?.symbol}
						</p>
					</div>
				</div>

				<Divider />

				<DailyEarnings />

				<Divider />

				<p>
					<strong>Savings Topup Items</strong>
				</p>
				{latestSavingsItems.map((item: any) => (
					<FlexibleSavingsItem item={item} />
				))}

				<Divider />

				<p>
					<strong>Associated Transaction Statements</strong>
					{transactionStatements.map((item: any) => (
						<TransactionItem transaction={item} />
					))}
				</p>
			</Card>
		</SavingsDetailsStyle>
	);
};

export default SingleFlexibleSavingsPage;
