import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../../component/Filter';
import NativeNumber from '../../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getBlockchainNetworks } from '../../../methods/redux/reducers/currency';
import { getExternalWithdrawalGasFeeReport } from '../../../methods/redux/reducers/reports';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import ExternalWithdrawalGasFeeItem from './ExternalWithdrawalGasFeeItem';

const Style = styled(Card)`
	& .aggregates {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 20px;
		row-gap: 20px;
		margin-top: 20px;

		@media (min-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
		}

		& .total {
			font-size: 18px;
		}

		& > * {
			padding: 20px;
			border: 1px solid #ddd;

			& .count {
				font-weight: 600;
				font-size: 18px;
			}
		}
	}
`;

const ExternalWithdrawalFees = () => {
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	const { externalWithdrawals } = useAppSelector(
		(store) => store.reports.blockchainGasFees
	);
	const { blockchains } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		dispatch(getExternalWithdrawalGasFeeReport({}));
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(20),
			});
		}

		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(getExternalWithdrawalGasFeeReport({ ...queryData }));
		}
	}, [searchParams]);

	const blockchainSelect = blockchains.map((item: any) => ({
		name: item.abbreviation,
		value: item._id,
	}));

	return (
		<Style>
			<h1>External Withdrawal Gas Fees</h1>
			<p>
				Get report on blockchain gas fees paid for funds transfer to external
				wallet addresses.
			</p>
			<Divider />
			<div className="aggregates">
				{externalWithdrawals.aggregate?.map((detail: any) => (
					<div key={detail.blockchainNetwork?._id}>
						<p>Total Amount ({detail.blockchainNetwork?.name})</p>
						<p className="total">
							<strong>
								<NativeNumber
									value={detail.totalGasFeeInUsd}
									decimalPlaces={4}
								/>{' '}
								USD
							</strong>
						</p>
						<Divider />
						<p>
							<strong>
								<NativeNumber
									value={detail.totalGasFeeInNativeToken}
									decimalPlaces={8}
								/>{' '}
								{detail.blockchainNetwork?.nativeTokenSymbol}
							</strong>
						</p>
						<p>
							Amount in native token (
							{detail.blockchainNetwork?.nativeTokenSymbol})
						</p>
					</div>
				))}
			</div>

			<Divider />

			<SearchFilter
				title="Filter Record of External Withdrawal Gas Fees"
				searchFilters={[
					{
						label: 'Blockchain Network',
						name: 'blockchainNetworkId',
						htmlElement: 'select',
						selectOptions: blockchainSelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber
						value={externalWithdrawals.record.paginator.itemCount}
					/>{' '}
					items
				</strong>{' '}
				found
			</p>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={externalWithdrawals.record.paginator.itemCount}
				current={externalWithdrawals.record.paginator.currentPage}
				pageSize={externalWithdrawals.record.paginator.perPage}
			/>

			<br />

			{externalWithdrawals.record.itemsList.map((item: any) => (
				<ExternalWithdrawalGasFeeItem key={item._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={externalWithdrawals.record.paginator.itemCount}
				current={externalWithdrawals.record.paginator.currentPage}
				pageSize={externalWithdrawals.record.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Style>
	);
};

export default ExternalWithdrawalFees;
