import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	CloseCircleOutlined,
	PauseCircleOutlined,
	RedoOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';

export const statusTag = (value: string) => {
	switch (value) {
		case 'pending':
			return (
				<Tag icon={<ClockCircleOutlined />} color="default">
					Pending
				</Tag>
			);
		case 'processing':
			return (
				<Tag icon={<SyncOutlined spin />} color="processing">
					Processing
				</Tag>
			);
		case 'active':
			return (
				<Tag icon={<SyncOutlined spin />} color="processing">
					Active
				</Tag>
			);
		case 'retry':
			return (
				<Tag icon={<RedoOutlined />} color="warning">
					Retry
				</Tag>
			);
		case 'paused':
			return (
				<Tag icon={<PauseCircleOutlined />} color="warning">
					Paused
				</Tag>
			);
		case 'completed':
			return (
				<Tag icon={<CheckCircleOutlined />} color="success">
					Completed
				</Tag>
			);
		case 'cancelled':
			return (
				<Tag icon={<CloseCircleOutlined />} color="error">
					Cancelled
				</Tag>
			);
		default:
			return (
				<Tag icon={<ClockCircleOutlined spin />} color="default">
					{value}
				</Tag>
			);
	}
};
const ReactUtils = () => <div>ReactUtils</div>;

export default ReactUtils;
