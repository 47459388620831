// import moment from 'moment';
import { useEffect, useState } from 'react';
import { Select, Button, Popconfirm } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { updateGame } from '../../../methods/redux/reducers/games';
import { gameRewardTypes } from '../../../methods/utils/general-utils';

const RewardDetails = ({ gameId }: { gameId: any }) => {
	const dispatch = useAppDispatch();

	const { singleGame } = useAppSelector((store) => store.games);
	const { globalEnums } = useAppSelector((store) => store.general);

	const [state, setState] = useState({
		allowRedeemReward: false,
		rewardSeasonEndDate: '',
		rewardSeasonStartDate: '',
		rewardEarnForm: '',
		rewardTokenValue: 0,
		readOnly: true,
	});

	const update = () => {
		dispatch(
			updateGame({
				data: {
					...state,
					allowRedeemReward: String(state.allowRedeemReward),
					gameId,
				},
				gameId,
			})
		);
	};

	useEffect(() => {
		const {
			allowRedeemReward,
			rewardTokenValue,
			rewardSeasonEndDate,
			rewardSeasonStartDate,
			rewardEarnForm,
		} = singleGame;
		setState({
			...state,
			allowRedeemReward,
			rewardTokenValue,
			rewardSeasonStartDate,
			rewardSeasonEndDate,
			rewardEarnForm,
		});
	}, [singleGame]);

	return (
		<>
			<h2>Reward Details</h2>
			<div className="details">
				{/* <div>
					<Input
						disabled={state.readOnly}
						value={state.rewardTokenValue}
						onChange={(e) =>
							setState({ ...state, rewardTokenValue: Number(e.target.value) })
						}
					/>
					<p className="label">Reward Token Value</p>
				</div> */}
				<div>
					<Select
						disabled={state.readOnly}
						onChange={(rewardEarnForm) =>
							setState({ ...state, rewardEarnForm })
						}
						value={state.rewardEarnForm}
						style={{ width: '100%' }}
					>
						<Select.Option>--</Select.Option>
						{gameRewardTypes(globalEnums.GAMES_ENUM.reward).map((item) => (
							<Select.Option value={item.value}>{item.name}</Select.Option>
						))}
					</Select>
					<p className="label">Reward Earn Form</p>
				</div>
				{/* <div>
					<Switch
						onChange={(e) => setState({ ...state, allowRedeemReward: e })}
						disabled={state.readOnly}
						checked={state.allowRedeemReward}
					/>
					<p className="label">Allow Redeem Reward</p>
				</div>
				<div>
					<DatePicker
						placeholder="Start Date"
						format="MMM DD, YYYY"
						disabled={state.readOnly}
						value={
							String(state.rewardSeasonStartDate).length > 5
								? moment(state.rewardSeasonStartDate)
								: undefined
						}
						onChange={(e) =>
							setState({
								...state,
								rewardSeasonStartDate: String(e?.format('YYYY-MM-DD')),
							})
						}
					/>
					<p className="label">Reward Season Starts</p>
				</div>
				<div>
					<DatePicker
						disabled={state.readOnly}
						placeholder="End Date"
						format="MMM DD, YYYY"
						value={
							String(state.rewardSeasonEndDate).length > 5
								? moment(state.rewardSeasonEndDate)
								: undefined
						}
						onChange={(e) =>
							setState({
								...state,
								rewardSeasonEndDate: String(e?.format('YYYY-MM-DD')),
							})
						}
					/>
					<p className="label">Reward Season Ends</p>
				</div> */}
			</div>

			<div>
				{state.readOnly ? (
					<Button onClick={() => setState({ ...state, readOnly: false })}>
						Edit
					</Button>
				) : (
					<Popconfirm onConfirm={update} title="Are you sure?">
						<Button>Update</Button>
					</Popconfirm>
				)}
			</div>
		</>
	);
};

export default RewardDetails;
