import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InjectedConnector } from '@web3-react/injected-connector';
import { chooseEnvironment } from '../../baseuri';

const addNetworkToMetamask = async (params: any) => {
	try {
		await window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [params],
		});
	} catch (e) {
		console.error(e);
	}
};

const switchOrAddNetworkToMetamask = async (chainId: number) => {
	const frontendEnvironment = chooseEnvironment().env;

	let chainIdHex = `0x${chainId.toString(16)}`;
	if (frontendEnvironment === 'testnet') {
		chainIdHex = `0x${Number(97).toString(16)}`;
	}

	try {
		await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: chainIdHex }],
		});
	} catch (e: any) {
		if (e.code === 4902) {
			let params = {};

			if (frontendEnvironment === 'testnet') {
				if (chainId === 56) {
					params = {
						chainId: `0x${Number(97).toString(16)}`,
						chainName: 'BSC Testnet',
						nativeCurrency: {
							name: 'BNB',
							symbol: 'bnb',
							decimals: 18,
						},
						rpcUrls: [],
						blockExplorerUrls: ['https://testnet.bscscan.com/'],
					};
				}
			}

			if (frontendEnvironment === 'mainnet') {
				if (chainId === 56) {
					params = {
						chainId: chainIdHex,
						chainName: 'BSC Mainnet',
						nativeCurrency: {
							name: 'BNB',
							symbol: 'bnb',
							decimals: 18,
						},
						rpcUrls: [],
						blockExplorerUrls: ['https://bscscan.com/'],
					};
				}
			}

			await addNetworkToMetamask(params);
		}
	}
};

export const login = createAsyncThunk(
	'connection/login',
	async (chainId: any) => {
		const connector = new InjectedConnector({
			supportedChainIds: [1, 97, 56, 137, 8001],
		});
		await switchOrAddNetworkToMetamask(chainId);

		const connection = await connector.activate();

		connection.provider.on('accountChanged', (addresses: any) => {
			const [firstAddress] = addresses;
			if (firstAddress) {
				return firstAddress;
			}
			return null;
		});

		return connection.account as string;
	}
);

const connectionRx = createSlice({
	name: 'connection',
	initialState: {
		connectedAddress: '',
	},
	reducers: {
		updateAddress: (store, action) => {
			const state = store;
			state.connectedAddress = action.payload;
			return state;
		},
	},
	extraReducers: (builder) =>
		builder.addCase(login.fulfilled, (store, action) => {
			const state = store;
			state.connectedAddress = action.payload;
		}),
});

export const { updateAddress } = connectionRx.actions;

export default connectionRx.reducer;
