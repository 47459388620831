import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { message, Tooltip } from 'antd';
import { truncateAddress } from '../../methods/utils/general-utils';

interface ILoanMasterProfile {
	profile: any;
}

interface IStyle {
	status: any;
}
const LoanMasterProfileStyle = styled.section<IStyle>`
	border: 1px solid #f2f2f2;
	margin-bottom: 20px;

	& .banner-land {
		position: relative;
		height: 30px;
		background-color: ${(p) =>
			p.status === 'active' ? 'var(--green-color)' : ' #c4c4c4'};
		opacity: 0.5;

		& button {
			opacity: 0;
		}
	}

	& .main-details {
		padding: 12px 15px;
		font-size: 18px;

		& .apr {
			font-size: 21px;
		}
	}

	& .other-details {
		display: flex;
		flex-wrap: wrap;

		& > * {
			border: 1px solid #ccc;
			border-left-color: transparent;
			border-bottom-color: transparent;
			border-top-color: transparent;
			padding: 5px 15px;
			font-size: 15px;

			& .label {
				font-size: 11px;
				font-weight: 600;
			}
		}
	}

	& .actions {
		background-color: #f2f2f2;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& a {
			display: flex;
			align-items: center;
			color: black;
			margin-left: 12px;

			& .material-icons {
				font-size: 15px;
			}
		}

		& .delete {
			border: none;
			background-color: transparent;
			color: gray;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			transition: all 300ms ease;

			&:hover {
				background-color: #ddd;
				color: red;
			}

			& span {
				font-size: 16px;
			}
		}
	}
`;

const LoanMasterProfile = ({ profile }: ILoanMasterProfile) => (
	<LoanMasterProfileStyle status={profile.status}>
		{/* <div className="main-details">
			<p>{profile.name}</p>
			<strong>
				<p className="apr">{profile.apr}% APR</p>
			</strong>
		</div> */}
		<div className="other-details">
			<div>
				<Link
					style={{ display: 'flex' }}
					title="View Reward User Profile"
					to={`/user-profile/${profile?._id}`}
				>
					<span className="material-icons">account_circle</span>
					<p>@{profile?.username || profile?.email}</p>
				</Link>
				<p className="label">Loan Master User</p>
			</div>
			<div>
				<p>
					<Tooltip title="Click to copy ID" color="green">
						<span
							role="none"
							onClick={() => {
								navigator.clipboard.writeText(profile?._id);
								message.success('ID copied');
							}}
						>
							{truncateAddress(profile?._id, 6)}{' '}
						</span>
					</Tooltip>
				</p>
				<p className="label">Loan Master Id</p>
			</div>
			<div>
				<p>{profile.phoneNo}</p>
				<p className="label">Phone Number</p>
			</div>
			<div>
				<p>{profile.adminLastComment}</p>
				<p className="label">Admin Last Comment</p>
			</div>
		</div>
	</LoanMasterProfileStyle>
);

export default LoanMasterProfile;
