import { Card, Divider, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import {
	retrieveInternalSettings,
	updateInternalSettings,
} from '../../methods/redux/reducers/internal-settings';
import { getStakingProfiles } from '../../methods/redux/reducers/staking';
import AddLoanCurrency from './AddLoanCurrency';
import LoanCurrencyPill from './LoanCurrencyPill';
import OtherLoanSettings from './OtherLoanSettings';
import LoanMasterProfile from './LoanMasterProfile';

const LoanSettings = () => {
	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store) => store.currencies);

	const { allowLoans, loanMasterUserId } = useAppSelector(
		(store) => store.internalSettings
	);
	// const settings = useAppSelector((store) => store.internalSettings);
	// const { profiles } = useAppSelector((store) => store.staking);

	const [loanableCurrencies, setLoanableCurrencies] = useState<Array<any>>([]);

	const switchLoanAbility = () => {
		dispatch(
			updateInternalSettings({
				allowLoans: String(!allowLoans),
			})
		);
	};

	useEffect(() => {
		dispatch(retrieveInternalSettings());
		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
		dispatch(getStakingProfiles(''));
	}, []);

	useEffect(() => {
		const filter = currencies.filter((currency: any) => currency.allowLoans);
		setLoanableCurrencies(filter);
	}, [currencies]);

	return (
		<div>
			<Card>
				<h2>Loan Settings</h2>

				<p>
					Allow Loan on the application. This settings can be turned on or off
					which will affect if the Loan module will work.
				</p>
				<Switch checked={allowLoans} onClick={switchLoanAbility} />
			</Card>
			<Card>
				<h2>Loan currencies</h2>
				<p>
					These are the tokens / currencies that can be loaned on the Xend
					Finance platform.
				</p>
				<section
					style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
				>
					{loanableCurrencies.map((currency: any) => (
						<LoanCurrencyPill currency={currency} key={currency._id} />
					))}
					<AddLoanCurrency />
				</section>
			</Card>
			<Card>
				<OtherLoanSettings />
			</Card>
			<Divider />
			<Card>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 10,
					}}
				>
					<h2>Loan Master Profiles</h2>
				</div>
				{loanMasterUserId && <LoanMasterProfile profile={loanMasterUserId} />}
			</Card>
		</div>
	);
};

export default LoanSettings;
