import { useEffect, useState } from 'react';
import { Input, Button, Popconfirm } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { editGameRank } from '../../../methods/redux/reducers/games/actions/game-badge';

const GameBadgeDetails = ({ gameRankId }: { gameRankId: any }) => {
	const dispatch = useAppDispatch();
	const { singleGameBadge } = useAppSelector((store) => store.games);

	const [state, setState] = useState({
		key: '',
		description: '',
		createdAt: '',
		requirements: 0,
		identifier: '',
		perk: 0,
		readOnly: true,
	});
	// const update = () => {
	// 	dispatch(
	// 		editGameRank({
	// 			data: {
	// 				...state,
	// 				gameRankId,
	// 			},
	// 			gameRankId,
	// 		})
	// 	);
	// 	setState({ ...state, readOnly: true });
	// };

	const update = () => {
		dispatch(
			editGameRank({
				...state,
				gameRankId,
			})
		);
		setState({ ...state, readOnly: true });
	};

	useEffect(() => {
		const { key, description, requirements, identifier, perk } =
			singleGameBadge;
		setState({
			...state,
			key,
			description,
			requirements,
			identifier,
			perk,
		});
	}, [singleGameBadge]);

	return (
		<>
			<h2>Game Rank Details</h2>
			<div className="title-desc">
				<div>
					<p className="label">Game Rank Key</p>
					<Input.TextArea
						disabled={state.readOnly}
						onChange={(e) => setState({ ...state, key: e.target.value })}
						value={state.key}
					/>
				</div>
				<div>
					<p className="label">Game Rank Description</p>
					<Input.TextArea
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
						value={state.description}
					/>
				</div>
				<div>
					<p className="label">Game Rank Requirements</p>
					<Input
						onChange={(e) =>
							setState({ ...state, requirements: Number(e.target.value) })
						}
						value={state.requirements}
					/>
				</div>

				<div>
					<p className="label">Game Rank Identifier</p>
					<Input
						disabled={state.readOnly}
						onChange={(e) => setState({ ...state, identifier: e.target.value })}
						value={state.identifier}
					/>
				</div>

				<div>
					<p className="label">Game Rank Perk</p>
					<Input
						onChange={(e) =>
							setState({ ...state, perk: Number(e.target.value) })
						}
						value={state.perk}
					/>
				</div>
			</div>
			<br />

			<div>
				<Popconfirm
					onConfirm={() => update()}
					title="Are you sure you want to modify this game rank ?"
				>
					<Button type="primary">Edit Game Rank</Button>
				</Popconfirm>
			</div>
		</>
	);
};

export default GameBadgeDetails;
