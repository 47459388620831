import { Card, Pagination, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MyDateSelector from '../../component/MyDateSelector';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import ColumnFixed from './ColumnFixed';
import FixedAggregate from './FixedAggregate';
import FixedSavingsFilter from './FixedSavingsFilter';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;
export const MyTable = styled(Table)`
	min-width: 250px;
	overflow-x: auto;

	& .ant-table-thead > tr > th {
		/* color: #8c8c8c; */
		padding: 16px 25px;
		font-weight: 700;
		background-color: transparent;
		font-size: 12px;
		color: red;
		min-width: 140px;
		/* max-width: 300px; */
		text-overflow: wrap;
	}

	& .ant-table-tbody > tr > td {
		padding: 16px 25px;
		min-width: 140px;
		/* max-width: 300px; */
		text-overflow: wrap;
		/* color: red; */
	}
`;
const FirstSavers = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		fixedSavings: [],
		paginator: {} as any,
	});

	const [searchParams, setSearchParams] = useSearchParams();
	const params: any = useParams();
	const location = useLocation();
	const { search } = params;

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			startDate: `${
				searchParams.get('startDate') ||
				moment('2021/10/01').format('YYYY-MM-DD')
			}`,
			endDate: `${
				searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			}`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};
	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			fixedSavings: [],
			paginator: {} as any,
		});
		if (!location.search) return;
		try {
			const res: any = await baseuri.get(`report/new-user/fixed/savings`, {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res?.data?.data });
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!search) {
			setLoading(true);
			handleSearchByDate();
		}
	}, [location.search]);

	return (
		<UsersListPage>
			<h1>Users Fixing their Savings For the First Time</h1>
			<FixedSavingsFilter />
			{data?.fixedSavings?.length > 0 ? (
				<FixedAggregate tokens={data?.fixedSavings} />
			) : (
				''
			)}

			<MainSection>
				<MyDateSelector />

				<MyTableCard>
					<TableHeaderSection
						tableName="First Time Savers"
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
						// downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
					/>
					{data.itemsList?.length > 0 ? (
						data?.itemsList?.map((item: any) => (
							<ColumnFixed key={item?._id} data={item} />
						))
					) : (
						<MyEmptyTable />
					)}

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default FirstSavers;
