import { Button, Checkbox, Modal, Popconfirm } from 'antd';
import { NodeExpandOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch } from '../../methods/redux/hooks';
import { resetUserBalance } from '../../methods/redux/reducers/compliance';
import { defaultImage } from '../../methods/utils/general-utils';

const Style = styled(Checkbox)`
	display: flex;
	width: 100%;
	border-top: 1px solid #d5d5d5;
	padding: 10px 0;

	& > span:not(:first-child) {
		display: block;
		width: 100%;

		& > div {
			display: flex !important;

			& img {
				width: 32px;
				height: 32px;
				margin-right: 10px;
			}
		}
	}
`;

const EachWallet = ({
	wallet,
	selectedBalances,
	toggle,
}: {
	wallet: any;
	selectedBalances: any[];
	toggle: Function;
}) => {
	const isChecked = () => {
		if (Number(wallet.balance) === 0) {
			return true;
		}
		const isFound = selectedBalances.find((x) => x === wallet.currencyId?._id);
		return !!isFound;
	};

	const handleChange = () => {
		toggle(wallet.currencyId?._id);
	};

	return (
		<Style
			disabled={Number(wallet.balance) === 0}
			onChange={handleChange}
			checked={isChecked()}
		>
			<div>
				<div>
					<img alt="" src={wallet.currencyId?.iconUrl || defaultImage} />
				</div>
				<div>
					<p className="balance">
						{wallet.balance} {wallet.currencyId?.symbol}
					</p>
					<p className="usdValue">
						$
						<NativeNumber
							value={
								Number(wallet.balance) *
								Number(wallet.currencyId?.usdValue || 0)
							}
						/>
					</p>
				</div>
			</div>
		</Style>
	);
};

const ResetAllBalances = ({ balances }: { balances: any[] }) => {
	const dispatch = useAppDispatch();

	const { id: userId } = useParams();

	const [visible, setVisible] = useState(false);
	const [selectedWallets, setSelectedWallets] = useState<Array<string>>([]);

	const toggleBalance = (currencyId: string) => {
		const item = selectedWallets.find((x) => x === currencyId);
		if (item) {
			const newList = selectedWallets.filter((x) => x !== currencyId);
			setSelectedWallets(newList);
		} else {
			setSelectedWallets([...selectedWallets, currencyId]);
		}
	};

	const handleResets = async () => {
		await Promise.all(
			selectedWallets.map((currencyId) => {
				dispatch(resetUserBalance({ currencyId, userId }));
				return null;
			})
		);
		setVisible(false);
	};

	useEffect(() => {
		const idList = balances.filter((x: any) => {
			if (Number(x.balance) === 0) {
				return false;
			}
			if (x?.currencyId?._id) {
				return true;
			}
			return false;
		});

		setSelectedWallets(idList.map((x) => x.currencyId?._id));
	}, [balances]);

	return (
		<>
			<Button onClick={() => setVisible(true)} type="link">
				<NodeExpandOutlined />
			</Button>

			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				footer={[
					<Popconfirm
						disabled={selectedWallets.length === 0}
						onConfirm={handleResets}
						title="Are you sure?"
					>
						<Button type="primary" disabled={selectedWallets.length === 0}>
							Reset Selected
						</Button>
					</Popconfirm>,
				]}
				title="Reset Balances"
			>
				<p>Choose which balances to reset</p>
				<br />
				{balances.map((wallet: any) => (
					<EachWallet
						key={wallet.currencyId?._id}
						wallet={wallet}
						toggle={toggleBalance}
						selectedBalances={selectedWallets}
					/>
				))}
			</Modal>
		</>
	);
};

export default ResetAllBalances;
