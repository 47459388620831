import { Card, message, Pagination, Space } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MyDateSelector from '../../component/MyDateSelector';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { Country } from '../../methods/utils/request-helpers';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const UserLastLogin = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {},
	} as any);

	const columns = [
		{
			title: 'Device Type',
			key: '23',
			render: (text: any) => <h3>{text?.userDevice?.type}</h3>,
		},
		{
			title: 'Device S/N',
			key: '532',
			render: (text: any) => <h3>{text?.userDevice?.serial}</h3>,
		},
		{
			title: 'Device Country',
			key: '3425',
			render: (text: any) => (
				<h3>{Country(text?.userDevice?.deviceCountry?.toLocaleUpperCase())}</h3>
			),
		},
		{
			title: 'IP Country',
			dataIndex: 'cfIpCountry',
			key: 'referrcfIpCountryalCount',
			render: (text: any) => <h3>{Country(text?.toLocaleUpperCase())}</h3>,
		},
		{
			title: 'IP',
			dataIndex: 'xRealIp',
			key: 'xRealIp',
		},
		{
			title: '',
			// key: '_id',
			render: (text: any) => (
				<Space size="middle">
					<a
						target="_blank"
						href={`https://maps.google.com/maps?f=q&q=${text.locationCoordinates?.lat},${text.locationCoordinates?.long}`}
						rel="noreferrer"
					>
						View Location
					</a>
				</Space>
			),
		},
		{
			title: 'login Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
			key: 'createdAt',
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const { id } = useParams();
	const location = useLocation();

	const getQueryParams = () => ({
		startDate: `${
			searchParams.get('startDate') || moment('2021/10/01').format('YYYY-MM-DD')
		}`,
		endDate: `${searchParams.get('endDate') || moment().format('YYYY-MM-DD')}`,
		// userId: `${searchParams.get('userId') || id}`,
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		if (!id) return;
		try {
			const res: any = await baseuri.get('admin/users/loginhistory', {
				params: {
					...params1,
					userId: id,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSearchByDate();
	}, [location.search]);
	return (
		<UsersListPage>
			<MainSection>
				<MyDateSelector />
				<MyTableCard>
					<TableHeaderSection
						tableName={`Login History for ${data?.itemsList[0]?.userEmail}`}
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
						// downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
					/>
					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.itemsList}
						pagination={false}
					/>

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default UserLastLogin;
