import { message } from 'antd';
import baseuri from './baseuri';

export const createACurrency = async (data: any, setLoading: Function) => {
	setLoading(true);
	try {
		await baseuri.post('/admin/currency', data);
		message.success('New currency created');
	} catch (e) {
		message.error('Failed to create currency');
	}
	setLoading(false);
};

export const editCurrencyLogo = async (
	data: any,
	onSuccess: Function,
	onError: Function
) => {
	try {
		await baseuri.put('/admin/currency/icon', data);

		onSuccess('OK');
	} catch (e) {
		onError(e);
	}
};
