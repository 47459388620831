import { Input, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	setSwapToProcessing,
	completeManualSwap,
} from '../../methods/redux/reducers/swap';

interface IProcessSwapRequest {
	request: any;
}
const ProcessSwapRequest = ({ request }: IProcessSwapRequest) => {
	const dispatch = useAppDispatch();

	const { cryptoBalances } = useAppSelector((store) => store.general);

	const [visible, setVisible] = useState(false);
	const [adminQuoteCurrencyBalance, setBalance] = useState(0);

	const [state, setState] = useState({
		resultQuoteCurrencyAmountLower: 0,
	});

	useEffect(() => {
		setState({
			...state,
			resultQuoteCurrencyAmountLower: request.resultQuoteCurrencyAmountLower,
		});
	}, [request]);

	useEffect(() => {
		const balance: any = cryptoBalances.filter(
			(currency: any) => currency.currencyId?._id === request.quoteCurrency?._id
		);
		if (balance.length > 0) {
			setBalance(balance[0].balance);
		}
	}, [cryptoBalances]);

	const submitManual = () => {
		if (
			state.resultQuoteCurrencyAmountLower >=
				request.resultQuoteCurrencyAmountLower ||
			state.resultQuoteCurrencyAmountLower <
				request.resultQuoteCurrencyAmountLower
		) {
			dispatch(completeManualSwap({ ...state, processingSwapId: request._id }));
		}
		setVisible(false);
	};

	return (
		<div>
			{request.swapStatus === 'pending' && (
				<Button
					type="primary"
					size="middle"
					onClick={() => {
						dispatch(setSwapToProcessing(request._id));
						console.info('refresh');
					}}
				>
					Assign
				</Button>
			)}

			{request.swapStatus === 'processing' && (
				<Button size="middle" type="primary" onClick={() => setVisible(true)}>
					Manual Swap
				</Button>
			)}

			<Modal
				visible={visible}
				okText="Complete Swap"
				onOk={submitManual}
				width={400}
				onCancel={() => setVisible(false)}
				title={`Complete Swap ${request.baseCurrencyAmount} ${request.baseCurrency?.symbol}`}
			>
				<p>
					Note: You will be acting as the liquidity provider to this swap trade.
				</p>
				<p>
					Customer is selling: {request.baseConvertRequestAmount}{' '}
					{request.baseCurrency?.symbol}
				</p>
				<p>
					Your balance: {adminQuoteCurrencyBalance}{' '}
					{request.quoteCurrency?.symbol}
				</p>
				<br />
				<div>
					<p>
						Customer should receive between{' '}
						<strong>
							{request.resultQuoteCurrencyAmountLower}{' '}
							{request.quoteCurrency?.symbol}
						</strong>{' '}
						and{' '}
						<strong>
							{request.resultQuoteCurrencyAmountUpper}{' '}
							{request.quoteCurrency?.symbol}
						</strong>
					</p>
				</div>
				<br />
				<div>
					<p>Amount Customer will receive</p>
					<Input
						type="number"
						addonAfter={request.quoteCurrency?.symbol}
						min={state.resultQuoteCurrencyAmountLower}
						value={state.resultQuoteCurrencyAmountLower}
						onChange={(e) =>
							setState({
								...state,
								resultQuoteCurrencyAmountLower: Number(e.target.value),
							})
						}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default ProcessSwapRequest;
