import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getCurrencies } from '../../../methods/redux/reducers/currency';
import { getGlobalEnumsData } from '../../../methods/redux/reducers/general';
import { unmigratedFeeReport } from '../../../methods/redux/reducers/reports';
import { transactionStatementTypes } from '../../../methods/utils/general-utils';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import SingleFeeItem from '../SingleFeeItem';
import UnmigratedCount from './Count';

const Style = styled(Card)``;

const UnmigratedFeesPage = () => {
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	const { currencies } = useAppSelector((store) => store.currencies);
	const { unmigratedFees } = useAppSelector((store) => store.reports);
	const { globalEnums } = useAppSelector((store) => store.general);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(20),
			});
		}

		dispatch(getGlobalEnumsData());
		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(unmigratedFeeReport({ ...queryData }));
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<Style>
			<h1>Unmigrated Fees Report</h1>
			<p>
				View report of unmigrated fees in the platform for different currencies
				and transaction forms within a date range
			</p>

			<UnmigratedCount />

			<Divider />

			<SearchFilter
				title="Filter Report Items "
				searchFilters={[
					{
						label: 'Type of Transaction',
						name: 'transactionForm',
						htmlElement: 'select',
						selectOptions: transactionStatementTypes(
							globalEnums?.TRANSACTION_STATEMENT_FORM
						),
					},
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={unmigratedFees.record.paginator.itemCount}
				current={unmigratedFees.record.paginator.currentPage}
				pageSize={unmigratedFees.record.paginator.perPage}
			/>

			<br />

			{unmigratedFees.record.itemsList.map((item: any) => (
				<SingleFeeItem key={item._id} data={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={unmigratedFees.record.paginator.itemCount}
				current={unmigratedFees.record.paginator.currentPage}
				pageSize={unmigratedFees.record.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Style>
	);
};

export default UnmigratedFeesPage;
