import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { disburseGameReward } from '../../../methods/redux/reducers/games';
import { useAppDispatch } from '../../../methods/redux/hooks';

interface IGamePointsDistributionItem {
	gamePoints: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;

const GameRewardDistributionItem = ({
	gamePoints,
}: IGamePointsDistributionItem) => {
	const dispatch = useAppDispatch();

	const update = (userId: any) => {
		dispatch(disburseGameReward({ userId }));
	};
	return (
		<Style>
			<div className="details">
				<div>
					<p className="label">FullName</p>
					<Link to={`/user-profile/${gamePoints.userId._id}`}>
						<p style={{ textTransform: 'capitalize' }}>
							{gamePoints.userId?.fullName}
						</p>
					</Link>
				</div>
				<div>
					<p className="label">Username</p>
					<p style={{ textTransform: 'capitalize' }}>
						{gamePoints.userId?.username}
					</p>
				</div>

				<div>
					<p className="label">Total Available Points</p>
					<p style={{ textTransform: 'capitalize' }}>{gamePoints.totalPoint}</p>
				</div>

				<div>
					<p className="label">Total Distributed Points</p>
					<p style={{ textTransform: 'capitalize' }}>
						{gamePoints.distributePoints}
					</p>
				</div>

				<div>
					<p className="label">Total Game Points</p>
					<p style={{ textTransform: 'capitalize' }}>
						{gamePoints.gameTotalPoints}
					</p>
				</div>
				<div>
					<p className="label">User Game Actions</p>
					<p>
						<Link to={`./user-game-action-activities/${gamePoints.userId._id}`}>
							<Button type="primary">View Game Actions</Button>
						</Link>
					</p>
				</div>
				<div>
					<p className="label">Pay User Reward</p>
					<p>
						{Number(gamePoints.totalPoint) >= 1 ? (
							<div>
								<Popconfirm
									onConfirm={() => update(gamePoints.userId._id)}
									title="Are you sure you want to distribute this reward.?"
								>
									<Button type="primary">Pay Reward</Button>
								</Popconfirm>
							</div>
						) : (
							<Button disabled type="default">
								Pay Reward
							</Button>
						)}
					</p>
				</div>
			</div>
		</Style>
	);
};

export default GameRewardDistributionItem;
