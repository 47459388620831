import { Divider, Input } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

const ExtrasStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const CompliancePage = () => {
	const items = [
		{
			title: 'BlackListed Users',
			desc: 'Admin will be able to see all the blacklisted users',
			link: `/compliance/blacklisted-users?startDate=2021-10-01&endDate=${moment().format(
				'YYYY-MM-DD'
			)}&perPage=10&pageId=1&sort=desc`,
			icon: 'supervised_user_circle',
		},
		{
			title: 'Double Fundings',
			desc: 'Here, the admin will be able to see all users that funded their accounts twice mistakenly.',
			link: '/compliance/double-funding',
			icon: 'keyboard_double_arrow_down',
		},
		{
			title: 'Funding Requests',
			desc: 'Manage funding requests on Xend Finance. This is the receiving record for all inflow of funds.',
			link: '/compliance/funding-requests',
			icon: 'filter_alt',
		},
		{
			title: 'Locked Savings',
			desc: 'Here, the admin will be able to manage all the locked savings on the xend finance platform',
			link: `/compliance/locked-savings/fixed?perPage=10&pageId=1&sort=desc`,
			icon: 'sync_lock',
		},
		{
			title: 'Users with low trust score',
			desc: 'Here, the admin will view all the users with trust score below 30%',
			link: `/compliance/low-trust-score?perPage=10&pageId=1&sort=desc`,
			icon: 'speed',
		},
		{
			title: 'Spam/Temporal Email Domains',
			desc: 'Here, the admin will view all the span/temporal email domains',
			link: `/compliance/spam-domains?perPage=10&pageId=1&sort=desc`,
			icon: 'warning',
		},
		{
			title: 'Negative Balances',
			desc: 'Track accounts with issues of having negative balances. Follow up to know and resolve.',
			link: `/compliance/negative-balances`,
			icon: 'exposure_neg_2',
		},
	];

	return (
		<section>
			<PageHeader>Compliance</PageHeader>

			<Input.Search placeholder="Search Settings" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<ExtrasStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</ExtrasStyle>
				))}
		</section>
	);
};

export default CompliancePage;
