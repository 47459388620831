import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';
// import { retrieveToken } from '../../utils/auth';

// const user1 = retrieveToken();

export const getUserTypes = createAsyncThunk('users/getUserTypes', async () => {
	const res = await baseuri.get('/admin/user-types/user-types');
	return res.data.data;
});

export const getCustodialUsers = createAsyncThunk(
	'users/getCustodialUsers',
	async (params: any) => {
		const res = await baseuri.get('/admin/users/usertype', { params });
		return res.data.data;
	}
);

const userRx = createSlice({
	name: 'users',
	initialState: {
		userTypes: [],
		custodialFunctionUsers: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 50,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getUserTypes.fulfilled, (store, action) => {
				const state = store;
				state.userTypes = action.payload;
			})
			.addCase(getCustodialUsers.fulfilled, (store, action) => {
				const state = store;
				state.custodialFunctionUsers = action.payload;
			}),
});

export default userRx.reducer;
