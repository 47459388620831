import { FC } from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';
import { commas, defaultImageOne } from '../../methods/utils/general-utils';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';

const ItemSection = styled.div`
	display: inline-flex;
	position: relative;
	justify-content: center;
	align-items: center;
	border: 1px solid #d5d3d3;
	border-radius: 10px;
	padding: 12px;
	margin: 10px;
	gap: 0.5em;

	& > div,
	& > div > span {
		display: flex;
		flex-direction: column;
		height: auto;
	}
	& .tokenTotal,
	.tokenSymbol {
		font-size: 16px;
		font-weight: bold;
	}

	& .usdValue {
		font-size: 10px;
		font-weight: bold;
	}
	& .name {
		font-size: 10px;
		font-weight: 200;
		font-style: italic;
		color: #bbb;
	}
`;

interface IAggregate {
	tokens: any;
}

const StakingAggregate: FC<IAggregate> = ({ tokens }) => (
	<MyCard>
		{tokens?.map((currency: any) => (
			<ItemSection key={currency._id}>
				<div>
					<Avatar
						src={currency?.currency?.iconUrl || defaultImageOne}
						size={60}
						alt=""
					/>
				</div>
				<div>
					<div className="tokenTotal">
						{commas(
							currency?.total ||
								currency?.totalCurrencyValue ||
								currency?.stakingAmountPaid
						)}
					</div>
					<div className="tokenSymbol">
						{currency?.currency?.symbol
							? currency?.currency?.symbol
							: 'UNKNOWN'}
					</div>
				</div>
				<div>
					<div>
						<div className="usdValue">
							{commas(
								currency.TotalUsdValue ||
									currency.TotalStakingAmountPaidUsdValue
							)}{' '}
							USD
						</div>
						<div className="name">USD Equivalent</div>
					</div>
					<div>
						<div className="usdValue">
							$
							{currency?.currency?.usdValue
								? commas(currency?.currency?.usdValue)
								: '0'}
						</div>
						<div className="name">Rate Per Token</div>
					</div>
					<div>
						<div className="usdValue">{currency?.count || '0'} txs</div>
						<div className="name">Count</div>
					</div>
					{currency?.totalStakedAmount && (
						<div>
							<div className="usdValue">
								{commas(
									currency.totalStakedAmount ||
										currency.TotalStakingAmountPaidUsdValue
								)}{' '}
								Xend
							</div>
							<div className="name">Total Staked Amount</div>
						</div>
					)}
				</div>
			</ItemSection>
		))}
	</MyCard>
);

export default StakingAggregate;
