import {
	Button,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Space,
} from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';
import EditorComponent from '../email-newsletter/EditorComponent';
import SendTestAutoEmail from './SendTestAutoEmail';
// import PreviewNewsLetter from './PreviewNewsLetter';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
interface IEditAutoEmail {
	autoEmail: any;
	setRefresh: Function;
}
const EditAutoEmail: FC<IEditAutoEmail> = ({ autoEmail, setRefresh }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		subject: autoEmail.subject,
		content: autoEmail.content,
		previewMessage: autoEmail.previewMessage,
		bannerUrl: autoEmail.bannerUrl,
	});

	const handleUpdate = async () => {
		try {
			setloading(true);
			const res: any = await emailBaseUrl.post(
				`/v1/automatedemails/${autoEmail?._id}`,
				data
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('AutoEmail Updated');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};
	const handleEditorOnchange = (newText: any) => {
		setData({ ...data, content: newText });
	};

	return (
		<div>
			<Button
				type="primary"
				// shape="round"
				size="small"
				ghost
				onClick={() => setVisible(true)}
			>
				Edit
			</Button>
			<Modal
				title="Update Auto Email"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleUpdate}>
						<Button loading={loading} type="primary">
							Save
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					{/* <p>
						Access Code :{' '}
						<Input
							placeholder="input access code"
							value={newsLetter?.access_code}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, access_code: e.target.value })
							}
						/>
					</p> */}
					<p>
						Subject :{' '}
						<Input
							placeholder="input sunject"
							value={data?.subject}
							onChange={(e: any) =>
								setData({ ...data, subject: e.target.value })
							}
						/>
					</p>
					<p>
						Preview Message :{' '}
						<Input
							placeholder="input preview message"
							value={data?.previewMessage}
							onChange={(e: any) =>
								setData({ ...data, previewMessage: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={data?.bannerUrl}
							onChange={(e: any) =>
								setData({ ...data, bannerUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://im.ge/upload"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://im.ge/upload
						</a>{' '}
						to Upload/generate banner url, Then select Direct Link to see the
						generated link
					</p>
					<Divider />
					<strong>Hi {`${'{{Name}}'}`}</strong>
					<p>
						<EditorComponent
							editorValue={data?.content}
							editorOnchange={handleEditorOnchange}
						/>
					</p>
					<Space direction="horizontal">
						{/* <PreviewNewsLetter /> */}
						<SendTestAutoEmail data={data} />
					</Space>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default EditAutoEmail;
