import { Card, Divider, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';
import {
	retrieveInternalSettings,
	updateInternalSettings,
} from '../../methods/redux/reducers/internal-settings';
import { getStakingProfiles } from '../../methods/redux/reducers/staking';
import AddStakingCurrency from './AddStakingCurrency';
import CreateStakingProfile from './CreateStakingProfile';
import StakingCurrencyPill from './StakingCurrencyPill';
import StakingProfile from './StakingProfile';

const Staking = () => {
	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store) => store.currencies);

	const { allowCurrencyStaking } = useAppSelector(
		(store) => store.internalSettings
	);
	const { profiles } = useAppSelector((store) => store.staking);

	const [stakableCurrencies, setStakableCurrencies] = useState<Array<any>>([]);

	const switchStakingAbility = () => {
		dispatch(
			updateInternalSettings({
				allowCurrencyStaking: !allowCurrencyStaking,
			})
		);
	};

	useEffect(() => {
		dispatch(retrieveInternalSettings());
		dispatch(getCurrencies({}));
		dispatch(getBlockchainNetworks());
		dispatch(getStakingProfiles(''));
	}, []);

	useEffect(() => {
		const filter = currencies.filter((currency: any) => currency.allowStaking);
		setStakableCurrencies(filter);
	}, [currencies]);

	return (
		<div>
			<Card>
				<h2>Staking Settings</h2>

				<p>
					Allow staking on the application. This settings can be turned on or
					off which will affect if the staking module will work.
				</p>
				<Switch checked={allowCurrencyStaking} onClick={switchStakingAbility} />
			</Card>
			<Card>
				<h2>Staking currencies</h2>
				<p>
					These are the tokens / currencies that can be staked on the Xend
					Finance platform.
				</p>
				<section
					style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
				>
					{stakableCurrencies.map((currency: any) => (
						<StakingCurrencyPill currency={currency} key={currency._id} />
					))}
					<AddStakingCurrency />
				</section>
			</Card>
			<Divider />
			<Card>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 10,
					}}
				>
					<h2>Staking Profiles</h2>
					<CreateStakingProfile />
				</div>
				{profiles.map((profile: any) => (
					<StakingProfile key={profile._id} profile={profile} />
				))}
			</Card>
		</div>
	);
};

export default Staking;
