import { Button, Input, message, Modal, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface ICreateAdvertBanner {
	setRefresh: Function;
}
const CreateAdvertBanner: FC<ICreateAdvertBanner> = ({ setRefresh }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		title: '',
		imageUrl: '',
		description: '',
		youtubeLink: '',
		externalLink: '',
		internalLink: '',
		orderNumber: 0,
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(`admin/in-app/advert-banner`, {
				...data,
				imageUrl: data.imageUrl.replace(/data:.*base64,/, ''),
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(res.data.message);
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Add New Advert Banner</span>
			</MyButton>
			<MyModal
				title="Add Advert Banner"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							create
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={data?.title}
							onChange={(e: any) => setData({ ...data, title: e.target.value })}
						/>
					</p>
					<p>
						Description :{' '}
						<TextArea
							placeholder="input description"
							value={data?.description}
							onChange={(e: any) =>
								setData({ ...data, description: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={data?.imageUrl}
							onChange={(e: any) =>
								setData({ ...data, imageUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://im.ge/upload"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://im.ge/upload
						</a>{' '}
						to Upload/generate banner url, Then select Direct Link to see the
						generated link
					</p>
					<p>
						YouTube Video ID :{' '}
						<Input
							type="url"
							placeholder="input description"
							value={data?.youtubeLink}
							onChange={(e: any) =>
								setData({ ...data, youtubeLink: e.target.value })
							}
						/>
						(go to{'  '}
						<a
							href="https://www.youtube.com/watch?v=liJVSwOiiwg"
							target="_blank"
							rel="noreferrer noopener"
						>
							{' '}
							https://www.youtube.com/watch?v=liJVSwOiiwg{' '}
						</a>
						to learn how to get a youtube ID )
					</p>
					<p>
						External Link :{' '}
						<Input
							type="url"
							placeholder="input description"
							value={data?.externalLink}
							onChange={(e: any) =>
								setData({ ...data, externalLink: e.target.value })
							}
						/>
					</p>
					<p>
						Internal URL :{' '}
						<Input
							type="url"
							placeholder="input description"
							value={data?.internalLink}
							onChange={(e: any) =>
								setData({ ...data, internalLink: e.target.value })
							}
						/>
					</p>
					<p>
						Order Number:{' '}
						<Input
							placeholder="input order number"
							type="number"
							value={data?.orderNumber}
							onChange={(e: any) =>
								setData({ ...data, orderNumber: Number(e.target.value) })
							}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default CreateAdvertBanner;
