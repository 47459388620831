import { Empty, message, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { MyTable } from '../../users/users-list/UsersList';
import CreateEmailList from './CreateEmailList';
import noData from '../../assets/icons/noData.jpg';
import EditEmailCron from './EditEmailCron';
import { LastSection } from '../../alerts/in-app-alert/InAPPNotifications';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import TableHeaderSection from '../../component/TableHeaderSection';
import emailBaseUrl from '../../methods/emailBaseUrl';

export const MyEmptyTable = () => (
	<Empty
		// image="../../assets/icons/noData.jpg"
		image={<img className="" src={noData} alt="" />}
		imageStyle={{
			height: 200,
		}}
		description="No data to display"
	/>
);
const EmailRobot = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => ({
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
	});

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		// if (!location.search) return;
		try {
			const res: any = await emailBaseUrl.get(`v1/maintenance`, {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!location.search) {
			const params1 = getQueryParams();
			setSearchParams({ ...params1 });
		} else {
			getData();
		}
	}, [location.search]);

	const columns = [
		{
			title: 'Title',
			key: 'subject',
			render: (text: any) => <strong>{text?.title}</strong>,
		},
		{
			title: 'Type',
			key: 'type',
			render: (text: any) => <strong>{text?.type}</strong>,
		},
		{
			title: 'Status',
			key: 'status',
			render: (text: any) => <strong>{text?.status}</strong>,
		},
		{
			title: 'Progress',
			key: 'progress',
			render: (text: any) => <strong>{text?.progress || 0}%</strong>,
		},
		{
			title: 'Total Records',
			key: 'totalRecords',
			render: (text: any) => <strong>{text?.totalRecords}</strong>,
		},
		{
			title: 'Page',
			key: 'page',
			render: (text: any) => <strong>{text?.page}</strong>,
		},
		{
			title: 'Limit',
			key: 'limit',
			render: (text: any) => <strong>{text?.limit}</strong>,
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Last Run TIme',
			dataIndex: 'lastRunAt',
			key: 'lastRunAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: '',
			key: 'subject',
			render: (text: any) => (
				<LastSection>
					<EditEmailCron item={text} />
				</LastSection>
			),
		},
	];
	return (
		<div>
			<CreateEmailList />
			<MyTableCard>
				<TableHeaderSection
					tableName="Email Robot"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
				/>

				<MyTable
					locale={{
						emptyText: <MyEmptyTable />,
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</div>
	);
};

export default EmailRobot;
