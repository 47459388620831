import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IGameActiionItem {
	gameAction: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;

const GameActionItem = ({ gameAction }: IGameActiionItem) => (
	<Style>
		<div>
			<Link to={`./game-action/single/${gameAction._id}`} className="title">
				{gameAction.gameKey}
			</Link>
		</div>
		<div className="details">
			<div>
				<p style={{ textTransform: 'capitalize' }}>{gameAction.action}</p>
				<p className="label">Default Action</p>
			</div>
			<div>
				<p style={{ textTransform: 'capitalize' }}>{gameAction.initiator}</p>
				<p className="label">Default Initiator</p>
			</div>
			<div>
				<p style={{ textTransform: 'capitalize' }}>
					{gameAction.defaultActionCount}
				</p>
				<p className="label">Default Action Count</p>
			</div>

			<div>
				<p style={{ textTransform: 'capitalize' }}>
					{gameAction.defaultCountPoint}
				</p>
				<p className="label">Default Count Point</p>
			</div>

			<div>
				<p style={{ textTransform: 'capitalize' }}>
					{gameAction.defaultPointVolume}
				</p>
				<p className="label">Default Point Volume </p>
			</div>

			<div>
				<p style={{ textTransform: 'capitalize' }}>{gameAction.usdVolume}</p>
				<p className="label">Default USD Volume </p>
			</div>

			<div>
				<p style={{ textTransform: 'capitalize' }}>{gameAction.point}</p>
				<p className="label">Default Point </p>
			</div>
			<div>
				<p>
					{gameAction.associatedCurrencyId
						? gameAction.associatedCurrencyId
						: '--'}
				</p>
				<p className="label">Default Associate Currency</p>
			</div>
		</div>
	</Style>
);

export default GameActionItem;
