import { Button, Card, Modal, Pagination, Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { retrieveToken } from '../../methods/utils/auth';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	deleteNotificationAsAdmin,
	markNotificationAsRead,
	retrieveReadNotifications,
	retrieveUnreadNotifications,
} from '../../methods/redux/reducers/notification';

const MessageStyle = styled.div`
	cursor: pointer;
	padding: 10px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .sender {
		font-style: italic;
	}

	& .topic {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& .time {
			font-size: 12px;
		}

		& > div:first-child {
			display: flex;
			align-items: center;

			& p {
				font-size: 17px;
			}
		}
	}
`;

interface IMessage {
	message: any;
}

export const Message = ({ message }: IMessage) => {
	const auth = retrieveToken();
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const isRead = () => {
		if (message.read === 'read') {
			return true;
		}
		return false;
	};

	const makeAsRead = (messageId: any) => {
		if (!isRead()) {
			dispatch(
				markNotificationAsRead({
					adminNotificationId: messageId,
					userId: auth?.user?._id,
				})
			);
		}
	};

	const deleteNotify = () => {
		dispatch(deleteNotificationAsAdmin(message._id));
		setVisible(false);
	};

	return (
		<>
			<MessageStyle
				title="Click to mark as read"
				onClick={() => {
					setVisible(true);
					makeAsRead(message._id);
				}}
			>
				<p className="sender"> From {message.adminNotificationId?.sender}</p>
				<div className="topic">
					<div>
						<p>
							<strong>{message.adminNotificationId?.topic}</strong>
						</p>
						{!isRead() && (
							<div
								style={{
									width: 7,
									height: 7,
									background: 'green',
									borderRadius: '50%',
									marginLeft: 10,
								}}
							/>
						)}
					</div>
					<p className="time">
						{moment(message.adminNotificationId?.createdAt).format(
							'MMM DD, YY / hh:mm:ss a'
						)}
					</p>
				</div>
				<p>{message.adminNotificationId?.message}</p>
			</MessageStyle>

			<Modal
				footer={null}
				visible={visible}
				title={`Message from ${message.adminNotificationId?.sender}`}
				onCancel={() => setVisible(false)}
			>
				<p>
					<strong>{message.adminNotificationId?.topic}</strong>
				</p>
				<p>{message.adminNotificationId?.message}</p>
				<br />
				<br />
				<Button danger size="small" onClick={() => deleteNotify()}>
					Delete
				</Button>
			</Modal>
		</>
	);
};

const AdminNotifications = () => {
	const dispatch = useAppDispatch();
	const { unread, read } = useAppSelector((store) => store.notifications);

	console.info({ unread, read });

	const fetchUnread = (pageId: any) => {
		dispatch(
			retrieveUnreadNotifications({ pageId, perPage: unread.paginator.perPage })
		);
	};

	useEffect(() => {
		fetchUnread(1);
		dispatch(retrieveReadNotifications({}));
	}, []);

	return (
		<Card>
			<p>Messages</p>
			<Tabs defaultActiveKey="0">
				<Tabs.TabPane tab="Unread" key="0">
					{unread.itemsList.map((message: any) => (
						<Message key={message._id} message={message} />
					))}
					<br />
					<br />
					<Pagination
						onChange={(pageId) => fetchUnread(pageId)}
						current={unread.paginator.currentPage}
						total={unread.paginator.itemCount}
						pageSize={unread.paginator.perPage}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Read" key="1">
					{read.itemsList.map((message: any) => (
						<Message key={message._id} message={message} />
					))}
					<br />
					<br />
					<Pagination
						onChange={(pageId) => fetchUnread(pageId)}
						current={unread.paginator.currentPage}
						total={unread.paginator.itemCount}
						pageSize={unread.paginator.perPage}
					/>
				</Tabs.TabPane>
			</Tabs>
		</Card>
	);
};

export default AdminNotifications;
