import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	retrieveUserGameActionActivities,
	retrieveSingleGame,
} from '../../../methods/redux/reducers/games';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import GameUserActionActivitiesItem from '../tasks/GameUserActionActivitiesItem';

const Style = styled(Card)``;

const GameUserActionActivities = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { userId } = useParams();

	const { userGameActionActivities } = useAppSelector((store) => store.games);
	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '4',
			});
		}
	}, []);
	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};
	useEffect(() => {
		dispatch(retrieveUserGameActionActivities({ userId }));
		dispatch(retrieveSingleGame({ userId }));
	}, [userId]);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveUserGameActionActivities({ userId, ...queryData }));
			dispatch(retrieveSingleGame({ userId, ...queryData }));
		}
	}, [searchParams]);

	console.info('adsd');
	return (
		<Style>
			<h1>User Game Action Activities </h1>
			<p>This table shows a complete list of all user game action activities</p>
			<Divider />
			<br />
			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={userGameActionActivities.paginator.itemCount} />{' '}
					items
				</strong>{' '}
				found
			</p>
			<br />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={userGameActionActivities.paginator.itemCount}
				current={userGameActionActivities.paginator.currentPage}
				pageSize={userGameActionActivities.paginator.perPage}
			/>

			<br />
			{userGameActionActivities.itemsList.map((userActivitiesAction: any) => (
				<GameUserActionActivitiesItem
					userActivitiesAction={userActivitiesAction}
					key={userActivitiesAction._id}
				/>
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={userGameActionActivities.paginator.itemCount}
				current={userGameActionActivities.paginator.currentPage}
				pageSize={userGameActionActivities.paginator.perPage}
			/>
		</Style>
	);
};

export default GameUserActionActivities;
