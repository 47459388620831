import { useState } from 'react';
import { Input, Button, Modal } from 'antd';
import { useAppDispatch } from '../../methods/redux/hooks';
import { addLiquidityProvider } from '../../methods/redux/reducers/liquidity-providers';

const AddLiquidityProvider = () => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		name: '',
		address: '',
	});

	const submit = () => {
		dispatch(addLiquidityProvider(state));
	};

	return (
		<div>
			<Button onClick={() => setVisible(true)}>Add LP</Button>

			<Modal
				open={visible}
				onOk={submit}
				onCancel={() => setVisible(false)}
				title="Add Liquidity Provider Agent"
			>
				<div>
					<p>Liquidity Provider&apos;s Name</p>
					<Input
						size="large"
						value={state.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Wallet Address</p>
					<Input
						size="large"
						value={state.address}
						onChange={(e) => setState({ ...state, address: e.target.value })}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default AddLiquidityProvider;
