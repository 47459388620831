import { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { additionalPermission } from '../../methods/redux/reducers/roles';

interface IAdditionalPermissions {
	userId: string;
}

const AdditionalPermissions = ({ userId }: IAdditionalPermissions) => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const { permissions } = useAppSelector((store) => store.roles);

	const [name, setName] = useState('');

	const submit = () => {
		dispatch(additionalPermission({ userId, name }));
		setVisible(false);
	};

	return (
		<>
			<Button size="small" type="primary" onClick={() => setVisible(true)}>
				Add Personal Permission
			</Button>

			<Modal
				title="Add Additional Permission"
				okText="Add"
				onOk={submit}
				width={400}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Choose Permission</p>
					<Select
						allowClear
						style={{ width: '100%' }}
						onChange={(e) => setName(e)}
					>
						{permissions.map((item: string) => (
							<Select.Option key={item}>{item}</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</>
	);
};

export default AdditionalPermissions;
