import { useEffect } from 'react';
import { Card, Divider, Pagination } from 'antd';
import { useSearchParams } from 'react-router-dom';
// import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import remakeQueryString from '../../methods/utils/remake-query-string';
import { retrieveNegativeFlexibleSavingsBalances } from '../../methods/redux/reducers/compliance';
import NativeNumber from '../../component/Numeral';
import FlexiSavingsProfileItem from '../../core-modules/flexible-savings/ProfileItem';

const NegativeFlexibleBalances = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { negativeFlexibleAccounts } = useAppSelector(
		(store) => store.compliance
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
			});
		}
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveNegativeFlexibleSavingsBalances(queryData));
		}
	}, [searchParams]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	return (
		<Card>
			<h1>Negative Flexible Savings Balances</h1>
			<p>
				Track flexible savings with negative balances. Follow up and resolve.
			</p>
			<Divider />

			<p>
				Total:{' '}
				<strong>
					<NativeNumber value={negativeFlexibleAccounts.paginator.itemCount} />
				</strong>{' '}
				items
			</p>
			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={negativeFlexibleAccounts?.paginator?.currentPage || 0}
				total={negativeFlexibleAccounts?.paginator?.itemCount || 0}
				pageSize={negativeFlexibleAccounts?.paginator?.perPage || 0}
			/>

			<br />

			{negativeFlexibleAccounts?.itemsList?.map((item: any) => (
				<FlexiSavingsProfileItem profile={item} key={item._id} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				current={negativeFlexibleAccounts?.paginator?.currentPage || 0}
				pageSize={negativeFlexibleAccounts?.paginator?.perPage || 0}
				total={negativeFlexibleAccounts?.paginator?.itemCount || 0}
			/>
		</Card>
	);
};

export default NegativeFlexibleBalances;
