import { useState } from 'react';
import {
	Modal,
	Space,
	message,
	Tag,
	Upload,
	Button,
	DatePicker,
	Select,
	Input,
} from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import baseuri from '../../methods/baseuri';

export const ImageMain = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;
const ManualKYCID = () => {
	const { id } = useParams();
	const [loading, setloading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({
		userId: id,
		idcardUrl: '',
		idcardType: '',
		idcardNumber: '',
		idcardExpirationDate: '',
	});

	const handleCurrencyUpdate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(
				'admin/custodial-kyc/user/kyc/idcard',
				{
					...data,
					idcardUrl: data?.idcardUrl?.replace('data:image/jpeg;base64,', ''),
				}
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('kyc updated');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	function getBase64(img: any, callback: Function) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}
	const handleImageUpload = async (info: any) => {
		getBase64(info.file.originFileObj, (imageUrl: any) =>
			setData({
				...data,
				idcardUrl: imageUrl,
			})
		);
	};

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
	};

	const beforeUpload = (file: any) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
			setData({
				...data,
				idcardUrl: '',
			});
		}
		return isJpgOrPng && isLt2M;
	};

	return (
		<div>
			<Tag
				style={{ cursor: 'pointer' }}
				color="blue"
				onClick={() => setVisible(true)}
			>
				Upload ID Card
			</Tag>
			<Modal
				okText="Update"
				onOk={handleCurrencyUpdate}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Manually Update User KYC Selfie"
				confirmLoading={loading}
			>
				<Space direction="vertical" size={10} style={{ width: '100%' }}>
					<div>
						Upload ID Image :
						<Upload
							name="image"
							listType="picture-card"
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleImageUpload}
						>
							{data?.idcardUrl ? (
								<ImageMain
									src={data?.idcardUrl}
									alt="avatar"
									className="actualImage"
									style={{ width: '100%' }}
								/>
							) : (
								<Button>Upload</Button>
							)}
						</Upload>
					</div>
					<div>
						<p>ID Type</p>
						<Select
							value={data.idcardType}
							placeholder="select Id"
							style={{ width: '100%' }}
							onChange={(e) => {
								if (e === 'nin') {
									setData({
										...data,
										idcardType: e,
										idcardExpirationDate: '2099-01-01',
									});
								} else {
									setData({ ...data, idcardType: e });
								}
							}}
						>
							<Select.Option name="gender" key={1} value="driverslicence">
								DRIVER LICENCE
							</Select.Option>
							<Select.Option key={2} value="voterscard">
								VOTERS CARD
							</Select.Option>
							<Select.Option key={3} value="internationalpassport">
								INTERNATIONALPASSPORT
							</Select.Option>
							<Select.Option key={4} value="nin">
								National ID Number
							</Select.Option>
						</Select>
					</div>
					<div>
						<p>ID Card Number</p>
						<Input
							name="idcardNumber"
							value={data?.idcardNumber}
							placeholder="ID Card Number"
							onChange={handleInput}
							style={{ width: '100%' }}
						/>
					</div>
					<div>
						<p>ID Expiry Date</p>
						<DatePicker
							allowClear={false}
							format="YYYY-MM-DD"
							onChange={(date1: any) =>
								setData({
									...data,
									idcardExpirationDate: date1.format('YYYY-MM-DD'),
								})
							}
							disabledDate={(current) =>
								current && current < moment().endOf('day')
							}
							disabled={data?.idcardType === 'nin'}
						/>
					</div>
				</Space>
			</Modal>
		</div>
	);
};

export default ManualKYCID;
