import { Button, Input, Modal, message } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useCreateMailingListMutation } from '../../../api-services/newsletter-api';

const MailingListsAdd = () => {
	const [createMailingList, { isLoading, isSuccess }] =
		useCreateMailingListMutation();
	const [visible, setVisible] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		onSubmit: (values: any) => {
			createMailingList(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			setVisible(false);
			message.success('Mailing List Created!');
		}
	}, [isSuccess]);

	return (
		<>
			<div style={{ textAlign: 'right' }}>
				<Button size="large" onClick={() => setVisible(true)}>
					Add Mailing List
				</Button>
			</div>

			<Modal
				title="Create Mailing List"
				open={visible}
				onCancel={() => setVisible(false)}
				footer={null}
			>
				<form onSubmit={formik.handleSubmit}>
					<div>
						<p>Name</p>
						<Input size="large" onChange={formik.handleChange} name="name" />
					</div>
					<br />
					<div>
						<p>Description</p>
						<Input.TextArea
							size="large"
							onChange={formik.handleChange}
							name="description"
						/>
					</div>
					<br />
					<div>
						<Button
							loading={isLoading}
							htmlType="submit"
							size="large"
							type="primary"
							block
						>
							Create List
						</Button>
					</div>
				</form>
			</Modal>
		</>
	);
};

export default MailingListsAdd;
