import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveGameSubscribers,
	retrieveSingleGame,
} from '../../methods/redux/reducers/games';
import remakeQueryString from '../../methods/utils/remake-query-string';
import GameSubscribersItem from './tasks/GameSubscribersItem';

const Style = styled(Card)``;

const GameSubscribers = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { gameId } = useParams();

	const { subscribers } = useAppSelector((store) => store.games);
	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		dispatch(retrieveGameSubscribers({ gameId }));
		dispatch(retrieveSingleGame({ gameId }));
	}, [gameId]);

	return (
		<Style>
			<h1>Game Subscribers</h1>
			<Divider />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={subscribers.paginator.itemCount} /> items
				</strong>{' '}
				found
			</p>
			<br />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={subscribers.paginator.itemCount}
				current={subscribers.paginator.currentPage}
				pageSize={subscribers.paginator.perPage}
			/>

			<br />

			{subscribers.itemsList.map((user: any) => (
				<GameSubscribersItem user={user} key={user._id} />
				//  <div key={user._id}>{user._id}</div>
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={subscribers.paginator.itemCount}
				current={subscribers.paginator.currentPage}
				pageSize={subscribers.paginator.perPage}
			/>
		</Style>
	);
};

export default GameSubscribers;
