import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LogoSymbol from './Logo';
import links from './links';

const SidebarStyle = styled.section`
	display: none;
	grid-row: 1/-1;
	background-color: white;
	padding: 12px;
	border-right: 1px solid #dddddd;

	& .cover {
		overflow-y: auto;
		overflow-x: none;

		& a {
			text-decoration: none;
			color: inherit;
			padding: 10px;
			display: flex;
			align-items: flex-end;
			position: relative;

			&:hover {
				background-color: #d6ebff;
			}

			& .badge {
				display: inline-block;
				width: 7px;
				height: 7px;
				background-color: var(--red-color);
				position: absolute;
				border-radius: 50%;
				top: 7px;
				left: 7px;
			}

			&.active {
				background-color: #1890ff;
				color: white;
			}

			& > i {
				margin-right: 7px;
				font-size: 21px;
			}
		}
	}

	& .side-bottom {
		border-top: 1px solid #dddddd;
		padding: 10px;
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 100px 1fr 60px;
	}
`;

const Sidebar = () => {
	const { pathname } = useLocation();

	const [activeBase, setActiveBase] = useState('');

	useEffect(() => {
		const baseRoute = pathname.split('/')[1];
		setActiveBase(baseRoute);
	}, [pathname]);

	const choseActiveLink = (base: string) => {
		if (base === activeBase) {
			return 'active';
		}
		return '';
	};

	return (
		<SidebarStyle>
			<div>
				<LogoSymbol section="sider" />
			</div>
			<div className="cover">
				<Link className={`${choseActiveLink('')}`} to="/">
					<span className="badge" />
					<i className="material-icons">dashboard</i>
					<p>Dashboard</p>
				</Link>

				{links.map((item) => (
					<Link
						to={`/${item.name}`}
						key={item.id}
						className={`${choseActiveLink(item.name)}`}
					>
						<i className="material-icons">{item.icon}</i>
						<p style={{ textTransform: 'capitalize' }}>
							{item.name
								.toLowerCase()
								.split(' ')
								.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
								.join(' ')
								.replace('-', ' ')}
						</p>
					</Link>
				))}
			</div>
			<div className="side-bottom">{/* <p>&copy; xend</p> */}</div>
		</SidebarStyle>
	);
};

export default Sidebar;
