import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { commas } from '../../methods/utils/general-utils';

interface IInCreditItem {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;

	& .amount {
		display: flex;
		font-weight: bold;
		font-size: 16px;
		& img {
			margin-right: 7px;
			width: 20px;
			height: 20px;
		}
	}

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 12px;
		padding: 15px 0;

		& > * {
			display: inline-block;
			min-width: 200px;
			margin-bottom: 7px;
			padding: 5px;
		}

		& .label {
			font-size: 13px;
			color: #808080;
			font-style: italic;
		}
	}
`;

const InCreditItem = ({ data }: IInCreditItem) => (
	<Style>
		<div>
			<div className="amount">
				<img alt="" src={data.currencyId?.iconUrl} />
				<p>
					<NativeNumber value={data.transactionAmount} />{' '}
					{data.currencyId?.symbol}
				</p>
			</div>
		</div>
		<div className="details">
			<div>
				<p>
					<Link title="View Profile" to={`/user-profile/${data.userId?._id}`}>
						<p>@{data.userId?.username}</p>
					</Link>
				</p>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>
					{data.transactionAmountInUSD
						? commas(data.transactionAmountInUSD)
						: commas(
								Number(data.transactionAmount) *
									Number(data.currencyId?.usdValue || 0)
						  )}
				</p>
				<p className="label">Amount in USD</p>
			</div>
			<div>
				<p>{data.blockchainId?.abbreviation}</p>
				<p className="label">Blockchain Network</p>
			</div>
			<div>
				<a
					href={`https://${data.blockchainId?.explorerUrl}/tx/${data.transactionHash}`}
					target="_blank"
					rel="noreferrer"
				>
					Hash
				</a>
				<p className="label">Transaction Hash</p>
			</div>
			<div>
				<p>{data.transactionSource}</p>
				<p className="label">Source</p>
			</div>
			<div>
				<p>{data.title}</p>
				<p className="label">Description</p>
			</div>
			<div>
				<p>{data.blockchainValidation}</p>
				<p className="label">Blockchain Validation</p>
			</div>
			<div>
				<p>{data.fundsRemitted ? 'Remitted' : 'Not Yet Remitted'}</p>
				<p className="label">Funds Remitted to Custodial Wallet</p>
			</div>
			<div>
				<p>{data.status}</p>
				<p className="label">Status</p>
			</div>
			<div>
				<p>
					{data.createdAt
						? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Date Created</p>
			</div>
			<div>
				<p>
					{data.updatedAt
						? moment(data.updatedAt).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Last Updated</p>
			</div>
			<div>
				<p>{data.lastUpdatedByUserForm}</p>
				<p className="label">User Form (Last Updater)</p>
			</div>
		</div>
	</Style>
);

export default InCreditItem;
