import { Empty, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';
// import { LastSection } from '../in-app-alert/InAPPNotifications';
import CreateAdvertBanner from './CreateAdvertBanner';
// import DeleteBanner from './DeleteBanner';
// import EditAdvertBanner from './EditAdvertBanner';

const AdvertBanners = () => {
	const [refresh, setRefresh] = useState('');
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => ({
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		// if (!location.search) return;
		try {
			const res: any = await baseuri.get('data/advert-banner', {
				params: {
					...params1,
					orderByNumber: true,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!location.search) {
			const params1 = getQueryParams();
			setSearchParams({ ...params1 });
		} else {
			getData();
		}
	}, [location.search, refresh]);

	const columns = [
		{
			title: 'Title',
			key: 'title',
			render: (text: any) => <strong>{text?.title}</strong>,
		},
		{
			title: 'Description',
			key: 'description',
			render: (text: any) => <strong>{text?.description}</strong>,
		},
		{
			title: 'Order Number',
			key: 'orderNumber',
			render: (text: any) => <strong>{text?.orderNumber}</strong>,
		},
		{
			title: 'Youtube Link',
			key: 'youtubeLink',
			render: (text: any) => <strong>{text?.youtubeLink}</strong>,
		},
		{
			title: 'External Link',
			key: 'externalLink',
			render: (text: any) => <strong>{text?.externalLink}</strong>,
		},
		{
			title: 'Internal Url',
			key: 'internalLink',
			render: (text: any) => <strong>{text?.internalLink}</strong>,
		},
		{
			title: 'Banner',
			key: 'imageUrl',
			render: (text: any) => text?.imageUrl,
			// <ImageSection>
			// 	{text?.imageUrl ? (
			// 		<Image
			// 			width="100%"
			// 			height="100%"
			// 			src={text?.imageUrl}
			// 			alt="Unable to load Image"
			// 		/>
			// 	) : (
			// 		<span>NO IMAGE</span>
			// 	)}
			// </ImageSection>
		},
		// {
		// 	title: 'Order Number',
		// 	key: 'orderNumber',
		// 	render: (text: any) => <strong>{text?.orderNumber}</strong>,
		// },
		{
			title: 'Last Update',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		// {
		// 	title: 'Created By',
		// 	key: 'creatorEmail',
		// 	render: (text: any) => <strong>{text?.authorId?.email}</strong>,
		// },
		{
			title: '',
			key: 'subject',
			render: (text: any) => text?._id,
			// <LastSection>
			// 	<EditAdvertBanner details={text} setRefresh={setRefresh} />
			// 	<DeleteBanner id={text?._id} setRefresh={setRefresh} />
			// </LastSection>
		},
	];
	return (
		<div>
			<CreateAdvertBanner setRefresh={setRefresh} />
			<MyTableCard>
				<TableHeaderSection
					tableName="Advert Banner"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
				/>

				<MyTable
					locale={{
						emptyText: (
							<Empty
								image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
								// imageStyle={{
								// 	height: 60,
								// }}
								description="No data to display"
							/>
						),
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</div>
	);
};

export default AdvertBanners;
