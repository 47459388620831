import { Upload, Button, Divider, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { useAppDispatch } from '../../methods/redux/hooks';
import {
	updateAdminProfile,
	updateAdminProfileImage,
} from '../../methods/redux/reducers/general';

interface IEditProfile {
	data: any;
}
const EditProfile = ({ data }: IEditProfile) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		email: '',
		fullName: '',
		profileUrl: '',
		phoneNumber: '',
	});

	useEffect(() => {
		setState({ ...state, ...data });
	}, [data]);

	const submitUpdate = () => {
		dispatch(updateAdminProfile({ fullName: state.fullName }));
	};

	const handleIconUpload = (e: any) => {
		const { onSuccess, onError, file } = e;

		const reader = new FileReader();
		reader.addEventListener('load', () => {
			updateAdminProfileImage(
				{ image_file: reader.result, phoneNumber: '' },
				onSuccess,
				onError
			);
		});
		reader.readAsDataURL(file);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Edit Profile</Button>

			<Modal
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Edit Profile"
				width={320}
			>
				<div>
					<p>FullName</p>
					<Input
						value={state.fullName}
						onChange={(e) => setState({ ...state, fullName: e.target.value })}
					/>
				</div>

				<br />

				<Button type="primary" block onClick={() => submitUpdate()}>
					Update
				</Button>

				<Divider />

				<p>Update Profile picture</p>

				<ImgCrop>
					<Upload customRequest={handleIconUpload}>
						<Button>Choose & Upload</Button>
					</Upload>
				</ImgCrop>
			</Modal>
		</>
	);
};

export default EditProfile;
