import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const retrieveApyHistory = createAsyncThunk(
	'reports/retrieveApyHistory',
	async (params: any) => {
		const res = await baseuri.get('/report/apy/history', {
			params: { ...params },
		});
		return res.data.data;
	}
);
export const getOldGasFees = createAsyncThunk(
	'reports/getOldGasFees',
	async (params: any) => {
		const res = await baseuri.get('/report/old/fees/expenses', {
			params: { ...params },
		});
		return res.data.data;
	}
);
export const getOldTransactionFee = createAsyncThunk(
	'reports/getOldTransactionFee',
	async (params: any) => {
		const res = await baseuri.get('/report/old/fees/receipt', {
			params: { ...params },
		});
		return res.data.data;
	}
);

export const unmigratedFeeReportCount = createAsyncThunk(
	'reports/unmigratedFeeReportCount',
	async (params: any) => {
		const res = await baseuri.get('/report', { params });
		return res.data.data;
	}
);

export const unmigratedFeeReport = createAsyncThunk(
	'reports/unmigratedFeeReport',
	async (params: any) => {
		const res = await baseuri.get(
			'/admin/fees/collector/un-migrated-fees-summary',
			{ params }
		);
		return res.data.data;
	}
);

export const migratedFeesBatches = createAsyncThunk(
	'reports/migratedFeesBatches',
	async (params: any) => {
		const res = await baseuri.get('/admin/fees/collector/migration/batch', {
			params,
		});
		return res.data.data;
	}
);

export const getExternalWithdrawalGasFeeReport = createAsyncThunk(
	'reports/getExternalWithdrawalGasFeeReport',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get(
			'/report/blockchain-fees/external-withdrawal',
			{
				params,
			}
		);
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const getUsersWithNoFixedPlanButHaveMoney = async (params: any) => {
	try {
		const res = await baseuri.get('/report/usersWithNoFixedPlanButHaveMoney', {
			params,
		});
		return res.data.data;
	} catch (e) {
		return null;
	}
};

export const getUsersWithNoFlexiblePlanButHaveMoney = async (params: any) => {
	try {
		const res = await baseuri.get(
			'/report/usersWithNoFlexiblePlanButHaveMoney',
			{
				params,
			}
		);
		return res.data.data;
	} catch (e) {
		return null;
	}
};

export const getUsersWithNoWalletBalance = async (params: any) => {
	try {
		const res = await baseuri.get('/report/usersWithNoWalletBalance', {
			params,
		});
		return res.data.data;
	} catch (e) {
		return null;
	}
};

export const getUsersWithFixedSavings = async (params: any) => {
	try {
		const res = await baseuri.get('/report/usersWithFixedSavings', {
			params,
		});
		return res.data.data;
	} catch (e) {
		return null;
	}
};

export const getUsersWithFlexibleSavings = async (params: any) => {
	try {
		const res = await baseuri.get('/report/userWithFlexibleSavings', {
			params,
		});
		return res.data.data;
	} catch (e) {
		return null;
	}
};

const reportRx = createSlice({
	name: 'reports',
	initialState: {
		migratedBatches: {
			aggregation: [],
			record: {
				itemsList: [],
				paginator: {
					itemCount: 0,
					currentPage: 1,
					perPage: 20,
				},
			},
		},
		unmigratedFees: {
			aggregation: [],
			record: {
				itemsList: [],
				paginator: {
					itemCount: 0,
					currentPage: 1,
					perPage: 20,
				},
			},
			depositGasFeesRecords: [],
		},
		apyHistory: {
			aggregation: [],
			itemsList: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		OldTransactionFeeReport: {
			itemsList: [] as any,
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
			transactionFeeRecords: [],
		},
		oldGasFeesReport: {
			itemsList: [] as any,
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
			depositGasFeesRecords: [],
		},
		blockchainGasFees: {
			externalWithdrawals: {
				record: {
					reportCSVFile: '',
					itemsList: [],
					paginator: {
						itemCount: 0,
						perPage: 20,
						currentPage: 1,
					},
				},
				aggregate: [],
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveApyHistory.fulfilled, (store, action) => {
				const state = store;
				state.apyHistory = action.payload;
			})
			.addCase(unmigratedFeeReport.fulfilled, (store, action) => {
				const state = store;
				state.unmigratedFees = action.payload;
			})
			.addCase(unmigratedFeeReportCount.fulfilled, (store, action) => {
				const state = store;
				state.unmigratedFees = action.payload;
			})
			.addCase(migratedFeesBatches.fulfilled, (store, action) => {
				const state = store;
				state.migratedBatches = action.payload;
			})
			.addCase(getOldGasFees.fulfilled, (store, action) => {
				const state = store;
				state.oldGasFeesReport = action.payload;
			})
			.addCase(getOldTransactionFee.fulfilled, (store, action) => {
				const state = store;
				state.OldTransactionFeeReport = action.payload;
			})
			.addCase(getExternalWithdrawalGasFeeReport.fulfilled, (store, action) => {
				const state = store;
				state.blockchainGasFees.externalWithdrawals = action.payload;
			}),
});

export default reportRx.reducer;
