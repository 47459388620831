import { Button, Divider, Input, Modal, Popconfirm, Tag, message } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveTestSubscribers } from '../../methods/redux/reducers/alerts';

interface ISendTestNewsletter {
	newsletter: any;
	typeOfEmail: 'text' | 'html';
}

const Style = styled.button``;

const TestSubscriber = ({ subscriber }: { subscriber: any }) => {
	const dispatch = useAppDispatch();

	const removeSubscriber = async () => {
		const res = await emailBaseUrl.patch(
			`/v1/subscribers/testers/${subscriber.email}`
		);
		if (res.status === 200) {
			dispatch(retrieveTestSubscribers());
		}
	};
	return (
		<Popconfirm
			title="Want to remove this test subscriber?"
			onConfirm={removeSubscriber}
		>
			<Style title="Click to remove">
				<span>{subscriber.email}</span>
			</Style>
		</Popconfirm>
	);
};

const SendTestNewsLetter: FC<ISendTestNewsletter> = ({
	newsletter,
	typeOfEmail,
}) => {
	const dispatch = useAppDispatch();

	const [loading, setloading] = useState(false);
	const [visible, setVisible] = useState(false);

	const { testSubscribers } = useAppSelector((store) => store.alerts);

	const [emailTester, setEmailTester] = useState({ email: '', fullName: '' });

	const handleTest = async () => {
		const endpoint =
			typeOfEmail === 'html'
				? '/v1/newsletters/html-template/draft'
				: '/v1/newsletters/draft';
		try {
			setloading(true);
			const res: any = await emailBaseUrl.post(endpoint, {
				...newsletter,
				maillist: 'listTwo',
			});

			if (res.status === 200) {
				setloading(false);
				message.success('Test NewsLetter sent');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	const handleAddTester = async () => {
		const res = await emailBaseUrl.post(`/v1/subscribers/testers`, emailTester);
		if (res.status === 200) {
			dispatch(retrieveTestSubscribers());
			setVisible(false);
		}
	};

	return (
		<div>
			<div>
				<Tag>Test Subscribers</Tag>
				<br />
				<div>
					{testSubscribers.map((item) => (
						<TestSubscriber subscriber={item} />
					))}
					<Button onClick={() => setVisible(true)} type="text">
						+ Add
					</Button>
				</div>
				<br />
			</div>

			<Divider />

			<Popconfirm
				title="This will send the email to all test subscribers"
				okText="Proceed"
				onConfirm={handleTest}
			>
				<Button type="primary" loading={loading}>
					SEND TEST
				</Button>
			</Popconfirm>

			<Modal
				title="Add Tester Email"
				open={visible}
				onCancel={() => setVisible(false)}
				onOk={handleAddTester}
			>
				<p>Email</p>
				<Input
					value={emailTester.email}
					onChange={(e) =>
						setEmailTester({ ...emailTester, email: e.target.value })
					}
					size="large"
				/>
				<p>Full Name</p>
				<Input
					value={emailTester.fullName}
					onChange={(e) =>
						setEmailTester({ ...emailTester, fullName: e.target.value })
					}
					size="large"
				/>
			</Modal>
		</div>
	);
};

export default SendTestNewsLetter;
