import { message, Pagination, Space } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';

const AllMerchant = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone No.',
			dataIndex: 'phoneNo',
			key: 'phoneNo',
			render: (text: any) => <h3>{text}</h3>,
		},
		{
			title: 'No. of Refs',
			dataIndex: 'referralCount',
			key: 'referralCount',
		},
		{
			title: 'SignUp Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).subtract(1, 'hour').format('lll'),
			key: 'createdAt',
		},

		{
			title: '',
			// key: '_id',
			render: (user: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${user._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
	];
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getQueryParams = () => ({
		startDate: `${
			searchParams.get('start') || moment('2021/10/01').format('YYYY-MM-DD')
		}`,
		endDate: `${searchParams.get('end') || moment().format('YYYY-MM-DD')}`,
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get('admin/merchants', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
				setLoading(false);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, [location.search]);
	return (
		<div>
			<MyTableCard>
				<TableHeaderSection
					tableName="All Merchants"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
					downloadURL={`${process.env.REACT_APP_BASEURI}admin/merchant`}
				/>

				<MyTable
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</div>
	);
};

export default AllMerchant;
