import { Card } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
// import SearchFilter from '../../component/Filter';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
// import {
// 	getBlockchainNetworks,
// 	getCurrencies,
// } from '../../methods/redux/reducers/currency';
import { getTopReferrals } from '../../methods/redux/reducers/referrals';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import ColumnFlexible from './ColumnFlexible';
// import FlexibleAggregate from './FlexibleAggregate';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const TopReferrals = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	// const { user } = useAppSelector((store) => store.userProfile);
	const { topReferrals } = useAppSelector((store) => store.referrals);
	// const { currencies } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		// dispatch(getCurrencies({}));
		// dispatch(getBlockchainNetworks());
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(
					getTopReferrals({
						month: moment().format('YYYY-MM'),
						defaultLimit: 100,
					})
				);
			} else {
				dispatch(
					getTopReferrals({
						month: moment().format('YYYY-MM'),
						defaultLimit: 100,
					})
				);
			}
		}
	}, [searchParams]);

	// const currencySelect = currencies.map((item: any) => ({
	// 	name: item.symbol,
	// 	value: item._id,
	// }));

	return (
		<Card>
			<h1>Top Referrals</h1>
			{/* <FlexibleAggregate tokens={topReferrals.record} /> */}

			<br />

			{/* <SearchFilter
				title="Search Top Referral"
				searchFilters={[
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/> */}

			<br />

			<p>
				Total of <strong>{topReferrals?.record.length} items</strong> found
			</p>

			<br />

			{/* <Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={topReferrals.paginator.itemCount}
				current={topReferrals.paginator.currentPage}
				pageSize={topReferrals.paginator.perPage}
			/> */}
			<br />

			{topReferrals.record.map((item: any) => (
				<ColumnFlexible key={item?._id} data={item} />
			))}

			{/* <Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={topReferrals.paginator.itemCount}
				current={topReferrals.paginator.currentPage}
				pageSize={topReferrals.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/> */}
		</Card>
	);
};

export default TopReferrals;
