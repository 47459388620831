import { Input, Modal, Button, Popconfirm } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import {
	toggleLiquidityProvider,
	updateLiquidityProvider,
} from '../../methods/redux/reducers/liquidity-providers';

interface IStyle {
	isActive: boolean;
}
const Style = styled.div<IStyle>`
	border-bottom: 1px solid #ddd;
	padding: 20px 0;

	& > * {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	& .material-icons {
		font-size: 10px;
		color: ${(p) => (p.isActive ? 'green' : 'gray')};
	}

	& .label {
		font-size: 12px;
		color: #aaa;
	}
`;

interface ILiquidityProviderItem {
	liquidityProvider: any;
}

const LiquidityProviderItem = ({
	liquidityProvider,
}: ILiquidityProviderItem) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		name: '',
		email: '',
		walletAddress: '',
	});

	const toggleLP = () => {
		dispatch(toggleLiquidityProvider(liquidityProvider._id));
	};

	const submit = () => {
		dispatch(updateLiquidityProvider(state));
	};

	useEffect(() => {
		setState({ ...state, ...liquidityProvider });
	}, [liquidityProvider]);

	return (
		<Style isActive={liquidityProvider.isActive}>
			<div>
				<span className="material-icons">circle</span>
			</div>
			<div>
				<p>{liquidityProvider.name}</p>
				<p>{liquidityProvider.email}</p>
			</div>
			<div>
				<p>{liquidityProvider.walletAddress}</p>
			</div>
			<div>
				<p className="label">Last Updated</p>
				<p>{moment(liquidityProvider.updatedAt).format('lll')}</p>
				<p>{liquidityProvider.editedBy?.email}</p>
			</div>
			<div>
				<Button onClick={() => setVisible(true)}>Edit</Button>
				<Modal
					title="Edit Liquidity Provider"
					visible={visible}
					onCancel={() => setVisible(false)}
					onOk={submit}
				>
					<div>
						<p>Name</p>
						<Input
							size="large"
							value={state.name}
							onChange={(e) => setState({ ...state, name: e.target.value })}
						/>
					</div>
					<div>
						<p>Email</p>
						<Input
							size="large"
							type="email"
							value={state.email}
							onChange={(e) => setState({ ...state, email: e.target.value })}
						/>
					</div>
					<div>
						<p>Wallet Address</p>
						<Input
							size="large"
							value={state.walletAddress}
							onChange={(e) =>
								setState({ ...state, walletAddress: e.target.value })
							}
						/>
					</div>
				</Modal>
			</div>
			<div>
				<Popconfirm title="Are you sure?" onConfirm={toggleLP}>
					<Button>{liquidityProvider.isActive ? 'Disable' : 'Activate'}</Button>
				</Popconfirm>
			</div>
		</Style>
	);
};

export default LiquidityProviderItem;
