import { Button, Space } from 'antd';
import { FC, useRef } from 'react';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import { commas } from '../../methods/utils/general-utils';

const CardTableHeaderSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	/* margin: 10px 0px; */
	gap: 2em;
`;

const DownloadButton = styled(Button)`
	color: red;
	background-color: #f5d5d5;
	border-radius: 5px;
	&:hover {
		color: red;
		background-color: #efe7e7;
	}
	&:focus {
		color: red;
		background-color: #f5d5d5;
	}
`;

interface IMainHeaderSection {
	tableName: string;
	totalAmount: any;
	data: any;
}
const MainHeaderSection: FC<IMainHeaderSection> = ({
	tableName,
	totalAmount,
	data,
}) => {
	const [searchParams] = useSearchParams();

	const csvLinkRef = useRef<
		CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
	>(null);

	const headers = [
		{ label: 'Username', key: 'username' },
		{ label: 'RegDate', key: 'regDate' },
		{ label: 'Total Amounts', key: 'totalTransactionAmount' },
		{ label: 'No of txs', key: 'transactionCount' },
	];

	return (
		<CardTableHeaderSection>
			<Space size="middle">
				<span className="tableTitle">
					Title: <strong>{tableName || 'N/A'}</strong>
				</span>
				<span className="pageSection">
					Total Amount: <strong>${commas(totalAmount)}</strong>
				</span>
			</Space>
			<Space size="middle">
				{data && (
					<DownloadButton danger icon={<DownloadOutlined />}>
						<CSVLink
							data={data}
							headers={headers}
							className="download"
							filename={`${tableName}${searchParams.get(
								'startDate'
							)}to${searchParams.get('endDate')}.csv`}
							ref={csvLinkRef}
						>
							Download
						</CSVLink>
					</DownloadButton>
				)}
			</Space>
		</CardTableHeaderSection>
	);
};

export default MainHeaderSection;
