import { Card } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import { getFeeSettings } from '../../methods/redux/reducers/fee-settings';
import AddFeeSettings from './AddFeeSettings';
import SingleFeeSettings from './SingleFeeSettings';

const TransactionFees = () => {
	const dispatch = useAppDispatch();
	const { fees } = useAppSelector((store) => store.feeSettings);

	useEffect(() => {
		dispatch(getFeeSettings());
		dispatch(getCurrencies({}));
	}, []);

	return (
		<div>
			<Card>
				<h2>Transaction fees</h2>
				<p>
					Manage transaction fees on Xend Finance. Add, update and remove
					transaction fee settings for different currencies and for different
					functions where transaction fees are charged.
				</p>

				<br />

				<p>We have a total of {fees.length} fee settings.</p>
				<br />
				<AddFeeSettings />
			</Card>
			<br />
			{fees.map((item: any) => (
				<div key={item._id}>
					<Card>
						<SingleFeeSettings fee={item} />
					</Card>
					<br />
				</div>
			))}
		</div>
	);
};

export default TransactionFees;
