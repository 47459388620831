import styled from 'styled-components';
import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateSupportedCurrency } from '../../methods/redux/reducers/currency';

const Style = styled.div`
	padding: 10px;
	border: 1px solid #eee;
	border-radius: 3px;
	margin-right: 10px;

	& > div {
		display: flex;
		font-weight: 600;
		align-items: center;
		padding-bottom: 5px;
		margin-bottom: 10px;

		& img {
			width: 21px;
			height: 21px;
			margin-right: 10px;
		}
	}
`;

interface ICurrencyItem {
	currency: any;
}
const CurrencyItem = ({ currency }: ICurrencyItem) => {
	const dispatch = useAppDispatch();
	const [allowSwapping, setAllowSwapping] = useState(false);

	useEffect(() => {
		setAllowSwapping(currency.allowSwapping);
	}, [currency]);

	const toggleAllowSwapping = (event: any) => {
		setAllowSwapping(event);
		dispatch(
			updateSupportedCurrency({
				currencyId: currency._id,
				allowSwapping: String(event),
			})
		);
	};

	return (
		<Style>
			<div>
				<img alt="" src={currency.iconUrl} />
				<p>{currency.symbol}</p>
			</div>
			<Switch
				size="default"
				checked={allowSwapping}
				onChange={toggleAllowSwapping}
			/>
		</Style>
	);
};

export default CurrencyItem;
