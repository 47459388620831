import { Button, Input, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAddSingleSubscriberMutation } from '../../../api-services/newsletter-api';

const AddSingleSubscriber = ({ mailingListId }: { mailingListId: any }) => {
	const [visible, setVisible] = useState(false);
	const [email, setEmail] = useState('');
	const [fullName, setName] = useState('');

	const [addSubscriber, { isLoading, isSuccess, isError, error }] =
		useAddSingleSubscriberMutation();

	useEffect(() => {
		if (isSuccess) {
			setVisible(false);
			message.success('Subscriber Added!');
		}
		if (isError) {
			const dd: any = error;
			message.error(dd?.data?.error_details);
		}
	}, [isSuccess, isError]);

	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Add Subscriber
			</Button>

			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				title="Add Subscriber"
				confirmLoading={isLoading}
				onOk={() =>
					addSubscriber({ email, fullName, listIds: [mailingListId] })
				}
			>
				<div>
					<p>Fullname</p>
					<Input
						size="large"
						value={fullName}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<br />
				<div>
					<p>Email</p>
					<Input
						type="email"
						size="large"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
			</Modal>
		</>
	);
};

const Style = styled.div`
	padding: 15px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		text-transform: capitalize;
	}

	& .details {
		display: flex;
		flex-wrap: wrap;
		justify-items: space-between;
		align-items: flex-end;
		border-top: 1px dashed #ccc;
		margin-top: 10px;

		& > * {
			min-width: 100px;
			margin-right: 15px;
			padding: 5px 0;
			font-size: 13px;

			& .label {
				color: #bbb;
				font-size: 12px;
			}
		}
	}
`;

const MailingLists = ({ mailingList }: { mailingList: any }) => (
	<Style>
		<p className="title">
			<strong>{mailingList.name}</strong>
		</p>
		<div className="details">
			<div>
				<p>{mailingList.totalSubscriber}</p>
				<p className="label">Subscribers</p>
			</div>
			<div>
				<AddSingleSubscriber mailingListId={mailingList._id} />
			</div>
		</div>
	</Style>
);

export default MailingLists;
