import { Button, Input, message, Modal } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { two2FALogin } from '../methods/auth';

interface IVerify2FA {
	show: boolean;
}
const Verify2FA: FC<IVerify2FA> = ({ show }) => {
	const navigate = useNavigate();
	const [code, setCode] = useState('');
	const [loading, setLoading] = useState(false);

	const navigateToDashboard = () => {
		navigate('/');
	};

	const enterApp = async () => {
		try {
			setLoading(true);
			await two2FALogin(code, navigateToDashboard);
			setLoading(false);
		} catch (e) {
			message.error('2FA Verification failed.');
			setLoading(false);
		}
	};

	return (
		<Modal
			width={320}
			closable={false}
			visible={show}
			footer={null}
			title="2FA Code"
		>
			<p>
				Get authentication code from your Google Authenticator mobile app and
				login.
			</p>

			<br />

			<form
				onSubmit={(e) => {
					e.preventDefault();
					enterApp();
				}}
			>
				<div>
					<Input
						value={code}
						onChange={(e) => setCode(e.target.value)}
						autoFocus
					/>
				</div>

				<br />

				<Button loading={loading} htmlType="submit" block type="primary">
					Proceed
				</Button>
			</form>
		</Modal>
	);
};

export default Verify2FA;
