import { Avatar, Pagination, Select, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import {
	Country,
	genderEnum,
	statusColor,
} from '../../methods/utils/request-helpers';
import { MyTableCard } from '../users-list/UsersByCountry';
import ImageComponent from './ImageComponent';
import RejectKYC from './RejectKYC';
import { FakeRef, SearchSection, TableProfile } from './UsersKYC';

const Level1Section = styled.div`
	min-width: 250px;

	& .sus {
		color: red;
	}
`;

const MainColumn = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 0.5em;
`;

const Levels = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	gap: 1em;
	min-width: 200px;
`;

const KYCSuspect = () => {
	const { id } = useParams<{ id: string }>();
	const [refresh, setRefresh] = useState(0);
	const [data, setData] = useState({} as any);
	const [selectedGateway, setSelectedGateway] = useState(
		'https://cf-ipfs.com/ipfs/'
	);
	console.info(selectedGateway);

	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);

	const gateWays = [
		{ name: 'https://cf-ipfs.com/ipfs/', value: 'https://cf-ipfs.com/ipfs/' },
		{ name: 'https://ipfs.io/ipfs/', value: 'https://ipfs.io/ipfs/' },
		{
			name: 'https://cloudflare-ipfs.com/ipfs/',
			value: 'https://cloudflare-ipfs.com/ipfs/',
		},
		{
			name: 'https://gateway.ipfs.io/ipfs/',
			value: 'https://gateway.ipfs.io/ipfs/',
		},
		{
			name: 'https://gateway.pinata.cloud/ipfs/',
			value: 'https://gateway.pinata.cloud/ipfs/',
		},
	];

	const getData = async () => {
		const phoneNumber = urlParams.get('phoneNumber');
		const idcardNumber = urlParams.get('idcardNumber');
		const deviceId = urlParams.get('deviceId');
		setData({ loading: true });

		const searchBy: any = {
			deviceId,
			phoneNumber,
			idcardNumber,
		};

		const search: any = {};
		Object.keys(searchBy).forEach((x: any) => {
			const params = searchBy[x];
			if (
				params !== undefined &&
				params !== null &&
				String(params).length > 0
			) {
				search[x] = params;
			}
		});

		try {
			const res: any = await baseuri.get('/admin/kyc/kyc-filter-by-values', {
				params: {
					...search,
				},
			});
			if (res.status === 200) {
				setData({ ...data, ...res.data.data, loading: false });
			} else {
				setData({ loading: false });
			}
		} catch (error) {
			setData({ loading: false });
		}
	};

	useEffect(() => {
		getData();
	}, [id, refresh]);

	const columns = [
		{
			title: 'User',
			key: '_id',
			width: 150,
			render: (text: any) => (
				// eslint-disable-next-line no-underscore-dangle
				<TableProfile>
					<div className="avatarSection">
						{text.profileUrl ? (
							<Avatar src={text?.userId?.profileUrl} />
						) : (
							<Avatar className="avatar">
								{' '}
								{text?.userId?.username?.charAt(0)}
							</Avatar>
						)}
						<Link
							title="View Profile"
							to={`/user-profile/${text.userId._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<p>
								<strong>@{text?.userId?.username}</strong>
							</p>
							<p>{text?.userId?.email}</p>
						</Link>
					</div>
					<br />
					<div>
						<p>
							FULL NAME: <span>{text?.userId?.fullName || '---'}</span>
						</p>
						<p>
							PHONE NO: <span>{text?.userId?.phoneNo || '---'}</span>
						</p>
						<br />
						<p>
							Referred by: <strong>{text?.userId?.refereeName || '---'}</strong>
						</p>
					</div>
				</TableProfile>
			),
		},
		{
			title: 'Level 1',
			key: '9024352',
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.lastName}  ${text?.firstName}  ${text?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{text?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{text?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							)}
						</strong>
					</p>
					<p>
						Gender: <strong>{genderEnum(text?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${text?.residentAddress.length < 15 ? 'sus' : ''}`}
						>
							{' '}
							{text?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(text?.countryCode.toUpperCase()) || 'N/A'}</strong>
					</p>
					<p>
						Device Id: <strong>{text?.deviceId || 'N/A'}</strong>
					</p>
					<p>
						Date Create:{' '}
						<strong>{moment(text?.createdAt).format('lll') || 'N/A'}</strong>
					</p>
					<p>
						Date Updated:{' '}
						<strong>{moment(text?.updatedAt).format('lll') || 'N/A'}</strong>
					</p>
					<br />
					<br />
					{/* <p className={data?.blacklistDetails?.blacklisted ? 'sus' : ''}>
						BlackListed User:{' '}
						<strong>
							{data?.blacklistDetails?.blacklisted ? 'Yes' : 'No'}
						</strong>
					</p>
					<SusLink
						to={Number(data?.kycsRegisteredWithDevice) > 1 ? '/' : ''}
						className={Number(data?.kycsRegisteredWithDevice) > 1 ? ' sus' : ''}
					>
						KYCs with same device:{' '}
						<strong>{data?.kycsRegisteredWithDevice || 0}</strong>
					</SusLink>
					<SusLink
						to={Number(data?.duplicatephoneNumber) > 1 ? '/' : ''}
						className={Number(data?.duplicatephoneNumber) > 1 ? ' sus' : ''}
					>
						KYCs with same Phone Number:{' '}
						<strong>{data?.duplicatephoneNumber || 0}</strong>
					</SusLink>
					<p className={Number(data?.duplicateIdcardNumber) > 1 ? ' sus' : ''}>
						KYCs with same ID Number:{' '}
						<strong>{data?.duplicateIdcardNumber || 0}</strong>
					</p>
					<p
						className={Number(data?.userRegisteredWithDevice) > 1 ? ' sus' : ''}
					>
						Users with same device:{' '}
						<strong>{data?.userRegisteredWithDevice || 0}</strong>
					</p> */}
				</Level1Section>
			),
		},
		{
			title: 'Level 2',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Level 2 Status:{' '}
						<strong
							style={{
								color: `${statusColor(text?.userId?.kycLevelTwoStatus)}`,
							}}
						>
							{' '}
							{text?.userId?.kycLevelTwoStatus || 'N/A'}
						</strong>
					</p>
					<p>
						Last Updated by:{' '}
						<strong>
							{text?.userId?.KycLevelTwoApprovedBy?.email || 'N/A'}
						</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={text.idcardEncryptKey}
								url={`${text.idcardUrl}`}
								createdAt={text?.createdAt}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								secret={text.selfieEncryptKey}
								url={`${text.selfieUrl}`}
								createdAt={text?.createdAt}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER: <strong> {text?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					<Levels>
						{/* {text?.userId?.kycLevelTwoStatus === 'submitted' && (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(text?.userId?._id, 'admin/kyc/update-levelTwo-kyc')
								}
							>
								Approve
							</Tag>
						)} */}
						{text?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/update-levelTwo-kyc"
								userId={text?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels>
				</MainColumn>
			),
		},
	];
	const selectedFilter = location.search.split(/[/,?,&,=]/)[1];

	return (
		<div>
			<SearchSection>
				<div>
					Select gateway :{' '}
					<Select
						// mode="multiple"
						placeholder="Select GateWay"
						// value={newsletter?.maillist}
						onChange={(e: any) => setSelectedGateway(e)}
						defaultValue="https://cf-ipfs.com/ipfs/"
						// style={{ width: '100%' }}
					>
						{gateWays?.map((gateway: any) => (
							<Select.Option key={gateway.name} value={gateway.value}>
								{gateway.name}
							</Select.Option>
						))}
					</Select>
				</div>
			</SearchSection>
			<br />

			<MyTableCard>
				<TableHeaderSection
					tableName={
						data?.itemsList &&
						`All Possible Suspects with same ${
							(selectedFilter === 'phoneNumber' && 'Phone Number') ||
							(selectedFilter === 'idcardNumber' && 'ID Card Number') ||
							(selectedFilter === 'deviceId' && 'Device Id')
						}`
					}
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
				/>

				<Table
					loading={data?.loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />

				<Pagination
					onChange={() => getData()}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					pageSizeOptions={['2', '5', '10', '20']}
				/>
			</MyTableCard>
		</div>
	);
};

export default KYCSuspect;
