import { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { createPermission } from '../../../methods/redux/reducers/roles';

const CreatePermission = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		name: '',
	});

	const submit = () => {
		dispatch(createPermission(state));
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Create Permissions</Button>

			<Modal
				title="Create New Permissions"
				okText="Create"
				onOk={submit}
				width={400}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Title</p>
					<Input
						value={state.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>
				</div>
				<br />
			</Modal>
		</>
	);
};

export default CreatePermission;
