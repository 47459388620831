import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getFlexibleMigrationLogs } from '../../methods/redux/reducers/flexible-savings';
import remakeQueryString from '../../methods/utils/remake-query-string';
import MigrationLogItem from './MigrationLogItem';

const MigrationLogs = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { migrationLogs } = useAppSelector((store) => store.flexibleSavings);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(migrationLogs.paginator.perPage),
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(getFlexibleMigrationLogs(queryData));
		}
	}, [searchParams]);

	return (
		<Card>
			<h1>Flexible Savings Migration Logs</h1>
			<p>View Logs of flexible savings top-up items</p>

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={migrationLogs.paginator.itemCount}
				current={migrationLogs.paginator.currentPage}
				pageSize={migrationLogs.paginator.perPage}
			/>

			<br />

			<p>
				Total of <strong>{migrationLogs.paginator.itemCount} items</strong>{' '}
				found.
			</p>

			<br />

			{migrationLogs.itemsList.map((item: any) => (
				<MigrationLogItem key={item._id} item={item} />
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={migrationLogs.paginator.itemCount}
				current={migrationLogs.paginator.currentPage}
				pageSize={migrationLogs.paginator.perPage}
			/>
		</Card>
	);
};

export default MigrationLogs;
