import { Card, Divider, Pagination } from 'antd';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import SearchFilter from '../../component/Filter';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import LoanItem from './LoanItem';
import {
	retrieveLoanRequest,
	retrieveLoanStatuses,
} from '../../methods/redux/reducers/loan';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import remakeQueryString from '../../methods/utils/remake-query-string';
import LoanStatusCount from './LoanStatusCount';

const Loan = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const { userId } = useParams();

	const { user } = useAppSelector((store) => store.userProfile);
	const { loanRequests } = useAppSelector((store) => store.loan);
	const { currencies } = useAppSelector((store) => store.currencies);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	const currencySelect = currencies.map((item: any) => {
		if (item.allowSavings) {
			return {
				name: `${item.symbol} *`,
				value: item._id,
			};
		}
		return {
			name: item.symbol,
			value: item._id,
		};
	});

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(retrieveLoanRequest({ ...queryData, userId }));
				dispatch(retrieveLoanStatuses({ userId }));
			} else {
				dispatch(retrieveLoanRequest(queryData));
				dispatch(retrieveLoanStatuses({}));
			}
		}

		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'pending',
				pageId: '1',
				perPage: '10',
			});
		}

		if (!currencies) {
			dispatch(getCurrencies({}));
		}
	}, [searchParams]);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	return (
		<div>
			<Card>
				<h1>
					Loan Requests{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile?._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>
				<p>
					This page shows counts of Loan records, Loans and amount summary on
					each token plus sum amount of both unpaid and paid loans, filtered by
					currency, and users.
				</p>

				<br />

				<div style={{ display: 'inline-block', marginRight: 20 }}>
					<Link to="/core-modules/loan-requests-due">Due Payments</Link>
				</div>
				<div style={{ display: 'inline-block' }}>
					<Link to="/settings/loan">Settings</Link>
				</div>
				<Divider />

				<LoanStatusCount />

				<br />

				<SearchFilter
					title="Filter Loan Records"
					searchFilters={[
						{
							label: 'Status',
							name: 'status',
							htmlElement: 'select',
							defaultSelectedValue: 'pending',
							selectOptions: [
								{ value: 'pending', name: 'Pending' },
								{ value: 'processing', name: 'Processing' },
								{ value: 'completed', name: 'Completed' },
								{ value: 'running', name: 'Running' },
								{ value: 'rejected', name: 'Rejected' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
						{
							label: 'Start Date',
							name: 'startDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'End Date',
							name: 'endDate',
							htmlElement: 'datepicker',
						},
					]}
					retrieveQueryString={updateQueryString}
				/>
				<Divider />
				<p>
					Total: <strong>{loanRequests.length}</strong> items
				</p>
				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					current={loanRequests?.paginator?.currentPage || 0}
					total={loanRequests?.paginator?.itemCount || 0}
					pageSize={loanRequests?.paginator?.perPage || 0}
				/>

				<br />

				{loanRequests?.itemsList?.map((item: any) => (
					<LoanItem loanItem={item} key={item._id} />
				))}
				<Divider />
				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					current={loanRequests?.paginator?.currentPage || 0}
					pageSize={loanRequests?.paginator?.perPage || 0}
					total={loanRequests?.paginator?.itemCount || 0}
				/>
			</Card>
		</div>
	);
};

export default Loan;
