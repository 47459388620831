import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { commas, defaultImageOne } from '../../methods/utils/general-utils';

const ItemSection = styled.div`
	display: inline-block;
	border: 1px solid #d5d3d3;
	padding: 20px;
	margin-top: 20px;
	margin-right: 20px;
	max-width: 500px;

	& .amount {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 600;
	}

	& img {
		margin-right: 10px;
		border-radius: 50%;
		width: 20px;
		height: 20px;
	}

	& .details {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px dashed #ddd;

		& > * {
			display: inline-block;
			margin-right: 15px;
		}

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}
	}
`;

interface IAggregate {
	data: any[];
}

const Aggregate = ({ data }: IAggregate) => {
	const [result, setResult] = useState<Array<any>>([]);

	useEffect(() => {
		const d = data.filter((x) => x._id !== null);
		setResult(d);
	}, [data]);

	if (result.length > 0) {
		return (
			<div>
				<Divider />
				<p>
					<strong>Transaction Volume for Filtered Records</strong>
				</p>

				{result.map((record: any) => (
					<ItemSection key={record._id}>
						<div className="amount">
							<img
								src={record.currency?.iconUrl || defaultImageOne}
								width={20}
								alt=""
							/>
							<p>
								<NativeNumber value={record.total} />{' '}
								{record.currency?.symbol || record?.currency?.name}
							</p>
						</div>
						<div className="details">
							<div>
								<p>
									<NativeNumber value={record.count} /> transactions
								</p>
								<div className="label">Transaction Count</div>
							</div>
							<div>
								<p>
									{record.TotalUsdValue ? (
										<NativeNumber value={record.TotalUsdValue} />
									) : (
										'0'
									)}{' '}
									USD
								</p>
								<div className="label">Amount in USD</div>
							</div>
							<div>
								<p>
									{record.currency?.usdValue
										? commas(record.currency?.usdValue)
										: '--'}{' '}
									USD
								</p>
								<div className="label">Currency Price</div>
							</div>
						</div>
					</ItemSection>
				))}

				<Divider />
			</div>
		);
	}
	return null;
};

export default Aggregate;
