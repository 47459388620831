import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CreateManualSwitchFunding from '../../maintenance/manual-switch-funding/CreateManualSwitchFunding';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import AdminTransfer from '../../profiles/users-profile/AdminTransfer';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';

const MainSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
`;
const TitleSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
`;
const UserTransactionSettings = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const { user } = useAppSelector((store) => store.userProfile);
	useEffect(() => {
		dispatch(getUserProfileById(id));
	}, []);
	return (
		<MainSection>
			<strong>
				Transaction settings for{' '}
				{user?.profile?.fullName || user.profile.username || user.profile.email}
			</strong>
			<MyCard>
				<TitleSection>
					<h2>Manually Remit Switch Funding</h2>
					{user?.profile?._id && <CreateManualSwitchFunding details={user} />}
				</TitleSection>
				<p>
					Here, the admin can manually remit funds to user&apos;s wallet when an
					error occurs during the funding process
				</p>
			</MyCard>
			<MyCard>
				<TitleSection>
					<h2>Admin Transfer</h2>
					{user?.profile?._id && <AdminTransfer details={user} />}
				</TitleSection>
				<p>Here, the admin can manually transfer funds to a user wallet</p>
			</MyCard>
		</MainSection>
	);
};

export default UserTransactionSettings;
