import {
	Button,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { countryCode as countries } from '../../methods/utils/request-helpers';
import EditorComponent from './EditorComponent';
import SendTestNewsLetter from './SendTestNewsLetter';
import { retrieveTestSubscribers } from '../../methods/redux/reducers/alerts';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
interface ICreateNewsLetter {
	setRefresh: Function;
}
const CreateNewsLetter: FC<ICreateNewsLetter> = ({ setRefresh }) => {
	const dispatch = useAppDispatch();
	const [allMailList, setAllMailList] = useState({} as any);

	const { user } = useAppSelector((store) => store.userProfile);

	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);

	const [newsletter, setNewsLetter] = useState({
		subject: '',
		bannerUrl: '',
		previewMessage: '',
		maillist: '',
		countryCode: '',
		locationType: '',
		access_code: '3345constable',
		deliveryType: 'instant',
		creatorEmail: '',
		content: '',
	});

	useEffect(() => {
		setNewsLetter({ ...newsletter, creatorEmail: user.profile.email });
	}, [user]);

	useEffect(() => {
		emailBaseUrl.get(`v1/newsletters/maillist`).then((res: any) => {
			setAllMailList(res?.data?.data);
		});
		dispatch(retrieveTestSubscribers());
	}, []);

	const handleCreate = async () => {
		try {
			const { locationType, countryCode } = newsletter;

			if (countryCode.length > 0 && locationType.length === 0) {
				message.info('Select a Country Type, since you selected a country');
				return false;
			}
			setloading(true);

			const res: any = await emailBaseUrl.post(
				'/v1/newsletters/draft',
				newsletter
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('NewsLetter Saved Successfully');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
			return true;
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
			return false;
		}
	};

	const handleEditorOnchange = (newText: any) => {
		setNewsLetter({ ...newsletter, content: newText });
	};

	return (
		<>
			<Button size="large" onClick={() => setVisible(true)}>
				Create NewsLetter
			</Button>

			<Modal
				width="50%"
				title="Create Newsletter"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Save
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<div>
						<p>Access Code</p>
						<div>
							<Input
								placeholder="input access code"
								value={newsletter?.access_code}
								size="large"
								onChange={(e: any) =>
									setNewsLetter({ ...newsletter, access_code: e.target.value })
								}
							/>
						</div>
					</div>

					<div>
						<p>Subject</p>
						<div>
							<Input
								size="large"
								placeholder="Type email title"
								value={newsletter?.subject}
								onChange={(e: any) =>
									setNewsLetter({ ...newsletter, subject: e.target.value })
								}
							/>
						</div>
					</div>

					<div>
						<p>Preview Message</p>
						<div>
							<Input
								size="large"
								placeholder="Type preview message"
								value={newsletter?.previewMessage}
								onChange={(e: any) =>
									setNewsLetter({
										...newsletter,
										previewMessage: e.target.value,
									})
								}
							/>
						</div>
					</div>

					<div>
						<p>Banner URL</p>
						<div>
							<Input
								size="large"
								placeholder="Banner url"
								value={newsletter?.bannerUrl}
								onChange={(e: any) =>
									setNewsLetter({ ...newsletter, bannerUrl: e.target.value })
								}
							/>
						</div>
						<p>
							You can use{' '}
							<a
								href="https://im.ge/upload"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://im.ge/upload
							</a>{' '}
							to Upload/generate banner url, Then select Direct Link to see the
							generated link
						</p>
					</div>

					{/* <p>
						<p>Delivery Type: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={newsletter.instantProcessing}
							onChange={(e) => {
								setNewsLetter({ ...newsletter, instantProcessing: e });
							}}
						>
							{instantProcessing?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p> */}
					<div>
						<p>Select MailList</p>
						<div>
							<Select
								size="large"
								placeholder="Select user type"
								value={newsletter?.maillist}
								onChange={(e: any) =>
									setNewsLetter({ ...newsletter, maillist: e })
								}
								style={{ width: '100%' }}
							>
								<Select.Option value="">--</Select.Option>
								{Object.entries(allMailList)?.map(([key, value1]) => (
									<Select.Option key={`${key}`} value={`${value1}`}>
										{key}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>

					<div>
						<p>Select Country</p>
						<p>Users in the selected country will receive the email</p>
						<Select
							value={newsletter.countryCode}
							style={{ width: '100%' }}
							onChange={(value) =>
								setNewsLetter({
									...newsletter,
									countryCode: value,
								})
							}
						>
							<Select.Option value="">All Countries</Select.Option>

							{countries?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem.code}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</div>
					{newsletter.countryCode.length > 0 && (
						<div>
							<p>Country Type</p>
							<Select
								value={newsletter.locationType}
								style={{ width: '100%' }}
								onChange={(locationType) =>
									setNewsLetter({ ...newsletter, locationType })
								}
							>
								<Select.Option value="">--</Select.Option>
								<Select.Option value="all">All Country Type</Select.Option>
								<Select.Option value="zone">Timezone</Select.Option>
								<Select.Option value="ip">IP Location</Select.Option>
							</Select>
						</div>
					)}

					<Divider />

					<p>
						<strong>Hi {`${'{{Name}}'}`}</strong>
					</p>
					<EditorComponent
						editorValue={newsletter?.content}
						editorOnchange={handleEditorOnchange}
					/>

					{/* <PreviewNewsLetter /> */}
					<SendTestNewsLetter newsletter={newsletter} typeOfEmail="text" />
				</ModalContent>
			</Modal>
		</>
	);
};

export default CreateNewsLetter;
