import {
	Button,
	Input,
	InputNumber,
	message,
	Modal,
	Popconfirm,
	Select,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getBlockchainNetworks,
	getCurrencies,
} from '../../methods/redux/reducers/currency';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface ICreateAdvertBanner {
	// eslint-disable-next-line react/require-default-props
	details?: any | null;
}
const CreateManualSwitchFunding: FC<ICreateAdvertBanner> = ({ details }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		userId: details?.profile?._id || '',
		amount: '0',
		currencyId: '',
		transactionHash: '',
		blockchainNetworkId: '',
		comment: '',
		inCreditStatementId: '',
		transactionStatementId: '',
	} as any);

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(
				`admin/maintenance/manual-switch-funding`,
				data
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(res.data.message);
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store: any) => store.currencies);
	const { blockchains } = useAppSelector((store) => store.currencies);
	useEffect(() => {
		if (currencies.length < 1) {
			dispatch(getCurrencies({}));
		}
		if (blockchains.length < 1) {
			dispatch(getBlockchainNetworks());
		}
	}, []);

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Remit Switch Funding</span>
			</MyButton>
			<MyModal
				title="Manual Switch Funding"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Remit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						UserId :{' '}
						<Input
							placeholder="input Title"
							value={data?.userId}
							defaultValue={details?.profile?._id || ''}
							onChange={(e: any) =>
								setData({ ...data, userId: e.target.value })
							}
						/>
					</p>
					<p>
						<p>Amount :</p>
						<InputNumber
							style={{ width: '100%' }}
							placeholder="input Amount"
							value={Number(data?.amount)}
							onChange={(e: any) => setData({ ...data, amount: `${e}` })}
							stringMode
						/>
					</p>
					<p>
						<p>Comment</p>
						<textarea
							placeholder="input message"
							style={{ width: '100%' }}
							value={data?.comment}
							onChange={(e: any) =>
								setData({ ...data, comment: e.target.value })
							}
						/>
					</p>
					<p>
						<h3>Currencies</h3>
						<Select
							// value={searchParams.get('currencyId') || defaultData.value}
							placeholder="Select Currency"
							style={{ width: '100%' }}
							onChange={(value: string) =>
								setData({ ...data, currencyId: value })
							}
						>
							{currencies?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem._id}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<h3>Blockchain Network</h3>
						<Select
							// value={searchParams.get('currencyId') || defaultData.value}
							placeholder="Select Blockchain Network"
							style={{ width: '100%' }}
							onChange={(value: string) =>
								setData({ ...data, blockchainNetworkId: value })
							}
						>
							{blockchains?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem._id}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						Transaction Hash :{' '}
						<Input
							placeholder="input Transaction Hash"
							value={data?.transactionHash}
							onChange={(e: any) =>
								setData({ ...data, transactionHash: e.target.value })
							}
						/>
					</p>
					<p>
						Incredit Statement ID :{' '}
						<Input
							placeholder="InCredit Statement Id"
							value={data?.inCreditStatementId}
							onChange={(e: any) =>
								setData({ ...data, inCreditStatementId: e.target.value })
							}
						/>
					</p>
					<p>
						Transaction Statements ID :{' '}
						<Input
							placeholder="Transaction Statement Id"
							value={data?.transactionStatementId}
							onChange={(e: any) =>
								setData({ ...data, transactionStatementId: e.target.value })
							}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default CreateManualSwitchFunding;
