import { UploadOutlined } from '@ant-design/icons';
import { Input, Divider, DatePicker, Button, Upload, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import { createGame } from '../../methods/redux/reducers/games';

const CreateGame = () => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	// const { currencies } = useAppSelector((store) => store.currencies);

	const [file, setFile] = useState<any>();
	const [state, setState] = useState({
		title: '',
		description: '',
		startDate: '',
		gameLogic: 'Dynamic',
		gameForm: '',
		endDate: '',
		// currencyId: '',
		// totalRewardUsers: '',
		// maxTokenSpend: '',
		prizes: '',
		// rewardTokenValue: '',
		autoJoin: false,
	} as any);

	useEffect(() => {
		dispatch(getCurrencies({}));
	}, []);

	const onCreateGame = async () => {
		console.info({ state });
		const formData = new FormData();
		Object.keys(state).forEach((x) => {
			formData.append(x, state[x]);
		});
		formData.append('imageUrl', file);
		setLoading(true);
		await createGame(formData);
		setLoading(false);
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Create Game</Button>

			<Modal
				footer={null}
				open={visible}
				onCancel={() => setVisible(false)}
				title="Create Game"
			>
				<div>
					<p>Title of Game</p>
					<Input.TextArea
						value={state.title}
						onChange={(e) => setState({ ...state, title: e.target.value })}
					/>
				</div>

				<br />

				<div>
					<p>Description</p>
					<Input.TextArea
						value={state.description}
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
					/>
				</div>

				<br />

				<div style={{ display: 'flex' }}>
					{/* <div style={{ flex: 1 }}>
						<p>
							Game Form{' '}
							<Tooltip title="Users will be automatically added to this game if turned on">
								<InfoCircleOutlined
									style={{ color: 'orange', cursor: 'help' }}
								/>
							</Tooltip>
						</p>
						<Select
							style={{ width: '100%' }}
							size="large"
							value={state.gameForm}
							onChange={(gameForm) => setState({ ...state, gameForm })}
						>
							<Select.Option>--</Select.Option>
							{gameFormTypes(globalEnums.GAMES_ENUM.form).map((item) => (
								<Select.Option value={item.value}>{item.name}</Select.Option>
							))}
						</Select>
					</div> */}
					{/* <div style={{ flex: 1, marginLeft: 20 }}>
						<p>Game Logic</p>
						<Select
							style={{ width: '100%' }}
							size="large"
							value={state.gameLogic}
							onChange={(gameLogic) => setState({ ...state, gameLogic })}
						>
							<Select.Option value="Static">Static</Select.Option>
							<Select.Option value="Dynamic">Dynamic</Select.Option>
						</Select>
					</div> */}
				</div>

				<br />
				{/* 
				<div>
					<p>
						Auto Join{' '}
						<Tooltip title="Users will be automatically added to this game if turned on">
							<InfoCircleOutlined style={{ color: 'orange', cursor: 'help' }} />
						</Tooltip>
					</p>
					<Switch
						checked={state.autoJoin}
						onChange={(e) => setState({ ...state, autoJoin: e })}
					/>
				</div> */}

				<br />

				<div>
					<p>Choose game banner</p>
					<Upload
						beforeUpload={(f) => {
							setFile(f);
							return false;
						}}
					>
						<Button icon={<UploadOutlined />}>Choose</Button>
					</Upload>
				</div>

				<br />

				{/* <div>
					<p>Reward Amount</p>
					<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
						<div>
							<Input
								size="large"
								value={state.rewardTokenValue}
								onChange={(e) =>
									setState({ ...state, rewardTokenValue: e.target.value })
								}
							/>
						</div>
						<div>
							<Select
								onChange={(currencyId) => setState({ ...state, currencyId })}
								style={{ width: '100%' }}
								defaultValue=""
								size="large"
							>
								<Select.Option value="">--</Select.Option>
								{currencies.map((currency: any) => (
									<Select.Option value={currency._id} key={currency._id}>
										{currency.symbol}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>
				</div>

				<br /> */}

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						gridGap: 20,
					}}
				>
					<div>
						<p>Start Date</p>
						<DatePicker
							style={{ width: '100%' }}
							size="large"
							showTime
							onChange={(e) =>
								setState({
									...state,
									startDate: String(e?.toISOString()),
								})
							}
						/>
					</div>
					<div>
						<p>End Date</p>
						<DatePicker
							style={{ width: '100%' }}
							size="large"
							showTime
							onChange={(e) =>
								setState({ ...state, endDate: String(e?.toISOString()) })
							}
						/>
					</div>
				</div>

				<br />

				{/* <div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						gridGap: 20,
					}}
				>
					<div>
						<p>Total Reward users</p>
						<Input
							size="large"
							value={state.totalRewardUsers}
							onChange={(e) =>
								setState({ ...state, totalRewardUsers: e.target.value })
							}
						/>
					</div>
					<div>
						<p>Maximum Token Spend</p>
						<Input
							size="large"
							value={state.maxTokenSpend}
							onChange={(e) =>
								setState({ ...state, maxTokenSpend: e.target.value })
							}
						/>
					</div>
				</div>

				<br /> */}

				<div>
					<p>Prizes</p>
					<Input.TextArea
						value={state.prizes}
						onChange={(e) => setState({ ...state, prizes: e.target.value })}
					/>
				</div>

				<Divider />

				<Button
					size="large"
					block
					disabled={loading}
					loading={loading}
					type="primary"
					onClick={() => onCreateGame()}
				>
					Submit
				</Button>
			</Modal>
		</>
	);
};

export default CreateGame;
