import { message } from 'antd';
import baseuri from './baseuri';

export const createBlockchainNetwork = async (
	data: any,
	setLoading: Function,
	closeModal: Function
) => {
	try {
		setLoading(true);
		await baseuri.post('/admin/blockchain-network', data);
		closeModal();
		message.success('Blockchain added successfully');
	} catch (e: any) {
		message.error(e.toString());
	}
	setLoading(false);
};

export const editBlockchainNetworkLogo = async (
	data: any,
	onSuccess: Function,
	onError: Function
) => {
	try {
		await baseuri.put('/admin/blockchain-network/icon', data);

		onSuccess('OK');
	} catch (e) {
		onError(e);
	}
};
