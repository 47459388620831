import { Card, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { commas } from '../../methods/utils/general-utils';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';
import Filters from './Filters';
import UnlockSavings from './UnlockSavings';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;

const ColumnItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;

	& > div {
		display: flex;
		flex-direction: column;

		& .value {
			font-size: 12px;
			font-weight: 300;
		}
		& .title {
			font-size: 10px;
			font-weight: 100;
			font-style: italic;
			color: #bbb;
		}
	}
`;
const FlexibleLockedSavings = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const columns = [
		{
			key: '1',
			title: 'User',
			render: (text: any) => (
				<>
					<p>
						<Link
							title="View Profile"
							to={`/user-profile/${text?.userId?._id}`}
						>
							<p> {text?.userId?.fullName}</p>
							<p> @{text?.userId?.username}</p>
							<p> {text?.email}</p>
						</Link>
					</p>
					<br />
					<br />
					<br />
					<UnlockSavings
						details={text}
						url="admin/compliance/lock-flexible-savings"
						text="flexible"
					/>
				</>
			),
		},
		{
			key: '2',
			title: 'Saving Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">{text?.title || '--'}</span>
						<span className="title">Savings Title</span>
					</div>
					<div>
						<span className="value">
							{text?.availableBalance ? commas(text?.availableBalance) : '0'}
						</span>
						<span className="title">Available Balance</span>
					</div>
					<div>
						<span className="value">
							{text?.totalWithdrawn ? commas(text?.totalWithdrawn) : '0'}
						</span>
						<span className="title">Total Withdrawn</span>
					</div>
					<div>
						<span className="value">{text?.status || '--'} Day</span>
						<span className="title">Status</span>
					</div>
					<div>
						<span className="value">
							{moment(text?.endDate) > moment()
								? moment(text?.endDate).diff(moment(), 'days')
								: '0'}{' '}
							Day Remaining
						</span>
					</div>
				</ColumnItem>
			),
		},
		{
			key: '2',
			title: 'Group Savings Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">
							{text?.groupDepositAmount
								? commas(text?.groupDepositAmount)
								: '0'}
						</span>
						<span className="title">Group Deposit Amount</span>
					</div>
					<div>
						<span className="value">
							{text?.groupCycleDurationDays || '0'} Day
						</span>
						<span className="title">Group Cycle Duration</span>
					</div>
					<div>
						<span className="value">{text?.groupMaxMembers || '0'} Day</span>
						<span className="title">Group Max Number</span>
					</div>
				</ColumnItem>
			),
		},
		{
			key: '6',
			title: 'Dates',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">
							{moment(text?.startDate).format('lll')}
						</span>
						<span className="title">Start Date</span>
					</div>
					{text?.esusuStartDate && (
						<div>
							<span className="value">
								{moment(text?.esusuStartDate).format('lll')}
							</span>
							<span className="title">Esusu Start Date</span>
						</div>
					)}
					{text?.cacheProfitLastDate && (
						<div>
							<span className="value">
								{text?.cacheProfitLastDate &&
									moment(text?.cacheProfitLastDate).format('lll')}
							</span>
							<span className="title">last Profit Date</span>
						</div>
					)}
				</ColumnItem>
			),
		},
		{
			key: '62',
			title: 'Lock Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">
							{text?.locked ? 'Locked' : 'Not Locked'}
						</span>
						<span className="title">Lock Status</span>
					</div>
					{text?.locked && (
						<>
							<div>
								<span className="value">{commas(text?.lockedAmount)}</span>
								<span className="title">Locked Amount</span>
							</div>
							<div>
								<span className="value">
									{moment(text?.lockedDate).format('lll')}
								</span>
								<span className="title">Locked Date</span>
							</div>
							<div>
								<span className="value">{text?.lockedAdmin?.email}</span>
								<span className="title">Lock Initiator</span>
							</div>
							{text?.unLockedDate && (
								<>
									<div>
										<span className="value">
											{moment(text?.unLockedDate).format('lll')}
										</span>
										<span className="title">UnLock Date</span>
									</div>
									<div>
										<span className="value">{text?.unLockedAdmin?.email}</span>
										<span className="title">UnLock Initiator</span>
									</div>
								</>
							)}
						</>
					)}
				</ColumnItem>
			),
		},
		{
			key: '49',
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('lll'),
		},
		{
			key: '498325',
			render: (text: any) => (
				<UnlockSavings
					details={text}
					url="admin/compliance/lock-flexible-savings"
					text="flexible"
				/>
			),
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			// startDate: `${
			// 	searchParams.get('startDate') ||
			// 	moment('2021/10/01').format('YYYY-MM-DD')
			// }`,
			// endDate: `${
			// 	searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			// }`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};

	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get(
				'admin/compliance/locked-flexible-plan',
				{
					params: {
						...params1,
					},
				}
			);

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSearchByDate();
	}, [location.search]);

	return (
		<UsersListPage>
			<Filters />
			<MainSection>
				{/* <MyDateSelector /> */}
				<MyTableCard>
					<TableHeaderSection
						tableName="Flexible Locked Savings"
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
						// downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
					/>
					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.itemsList}
						pagination={false}
					/>

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default FlexibleLockedSavings;
