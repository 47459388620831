import { Modal, Button, Divider, Input, message } from 'antd';
import { useState } from 'react';
import baseuri from '../../methods/baseuri';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateWithdrawalStatus } from '../../methods/redux/reducers/withdrawal-requests';

interface IProcessRequest {
	request: any;
}

const ProcessRequest = ({ request }: IProcessRequest) => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);
	const [hash, setHash] = useState('');

	const submitTransactionHash = async () => {
		try {
			await baseuri.post('/admin/wallet/process-withdraw-request', {
				requestId: request._id,
				hash,
				channel: 'human',
			});
			message.success('Action Completed!');
			dispatch(updateWithdrawalStatus({ requestId: request._id, status: 1 }));
		} catch (e: any) {
			message.error('Action Failed!');
		}
	};

	if (!request.inAppTransaction) {
		return (
			<>
				<Button type="primary" onClick={() => setVisible(true)}>
					Process Request
				</Button>

				<Modal
					visible={visible}
					okText="Submit Transaction Hash"
					onOk={submitTransactionHash}
					title="Process Withdrawal Request"
					onCancel={() => setVisible(false)}
				>
					<p>
						<strong>Transfer amount with MetaMask</strong>
					</p>

					<Divider>OR</Divider>

					<p>
						<strong>Provide Transaction Hash</strong>
					</p>
					<p>
						If you have already done this transaction, please provide the
						correct transaction hash here to complete the request. Note that
						doing this will signal to the customer that their withdrawal request
						is now successful and is delivered to the destination address in the
						request.
					</p>
					<br />
					<Input.TextArea
						value={hash}
						onChange={(e) => setHash(e.target.value)}
						placeholder="Paste transaction hash"
					/>
				</Modal>
			</>
		);
	}
	return null;
};

export default ProcessRequest;
