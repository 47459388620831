import { Button, Input, Modal, Popconfirm, message } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface IEditCron {
	item: any;
}
const ReverseFunds: FC<IEditCron> = ({ item }) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({
		title: item.title,
		requestId: item?._id,
	});

	const handleRevers = async () => {
		try {
			const res: any = await baseuri.post(
				`admin/wallet/refund-withdraw-request`,
				data
			);
			if (res.status === 200) {
				message.success(res.data.message);
			} else {
				message.info(res.data.message);
			}
		} catch (e: any) {
			message.error(e.response.data.message);
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Reverse Withdrawal</Button>

			<Modal
				title="Reverse Withdrawal"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleRevers}>
						<Button type="primary">Reverse</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Revert{' '}
					<strong>
						{item.finalAmount} {item.currencyId?.symbol}
					</strong>{' '}
					for @{item?.userId?.username}
				</p>
				<br />
				<div>
					<p>Title</p>
					<Input.TextArea
						placeholder="input Title"
						value={data?.title}
						onChange={(e: any) => setData({ ...data, title: e.target.value })}
					/>
				</div>
			</Modal>
		</>
	);
};

export default ReverseFunds;
