import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../methods/redux/hooks';
import { retrieveInternalSettings } from '../../methods/redux/reducers/internal-settings';
import FeeCollectorUser from './FeeCollectorUser';

const ManageFeeCollection = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(retrieveInternalSettings());
	}, []);

	return (
		<Card>
			<h1>Manage Fee Collection</h1>
			<Divider />
			<FeeCollectorUser />

			<Divider />

			<div>
				<Link to="/reports/fee-collection/unmigrated">
					Unmigrated Transaction Fees
				</Link>
			</div>

			<Divider />

			<div>
				<Link to="/reports/fee-collection/batch">Fee Migration Batches</Link>
			</div>

			<Divider />

			<div>
				<Link to="/reports/fee-collection/old-gas-fees">
					Fee report before PRO version migration
				</Link>
			</div>

			<Divider />

			<div>
				<Link to="/reports/fee-collection/external-withdrawal-gas-fees">
					External Withdrawal Gas Fees
				</Link>
			</div>
		</Card>
	);
};

export default ManageFeeCollection;
