import { ReactNode } from 'react';
import styled from 'styled-components';

interface IContent {
	children: ReactNode;
}

const ConstentStyle = styled.main`
	overflow-y: auto;
	overflow-x: none;
	grid-column: 1 / -1;

	& > div {
		padding: 20px;
		width: 100%;
		margin: 0 auto;
		max-width: 1200px;
	}

	@media (min-width: 768px) {
		grid-column: 2 / -1;
	}
`;

const Content = ({ children }: IContent) => (
	<ConstentStyle>
		<div>{children}</div>
	</ConstentStyle>
);

export default Content;
