import { Select } from 'antd';

interface IFilterComponentSelect {
	item: any;
	addFilter: Function;
	retrieveDefaultValue: Function;
}

const FilterComponentSelect = ({
	retrieveDefaultValue,
	item,
	addFilter,
}: IFilterComponentSelect) => (
	<Select
		style={{ width: '100%' }}
		defaultValue={retrieveDefaultValue(item.name) || ''}
		onChange={(value) => addFilter(item.name, value)}
	>
		<Select.Option value="">--</Select.Option>
		{item.selectOptions?.map((optionItem: any) => (
			<Select.Option key={optionItem.value} value={optionItem.value}>
				{optionItem.name}
			</Select.Option>
		))}
	</Select>
);

export default FilterComponentSelect;
