import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';

const CreateUserType = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [inputDetails, setInputDetails] = useState({
		name: '',
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res = await baseuri.post(
				`/admin/user-types/user-type`,
				inputDetails
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('UserType created Successfully');
			}
		} catch (e: any) {
			setloading(false);
			message.error('something went wrong');
		}
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				ghost
				onClick={() => setVisible(true)}
			>
				Create new UserType
			</Button>
			<Modal
				title="Create New User Type"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button
							loading={loading}
							type="primary"
							disabled={!inputDetails?.name}
						>
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					UserType Name :{' '}
					<Input
						placeholder="input UserType name"
						value={inputDetails?.name}
						onChange={(e: any) =>
							setInputDetails({ ...inputDetails, name: e.target.value })
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default CreateUserType;
