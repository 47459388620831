import {
	Button,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
	Upload,
} from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface ICreateAdvertBanner {
	setRefresh: Function;
}
const SendMultiple: FC<ICreateAdvertBanner> = ({ setRefresh }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		csv_file: {},
		message: '',
		title: '',
		type: 'email',
	} as any);

	const typeEnum = [
		{ name: 'Email', value: 'email' },
		{ name: 'Notifications', value: 'notification' },
	];

	const handleCreate = async () => {
		const formData = new FormData();
		formData.append('csv_file', data?.csv_file);

		try {
			setloading(true);
			const res: any = await baseuri.post(
				`admin/bulk-savings/stable-currency/upload?durationDays=${data?.durationDays}&title=${data?.title}&currencyId=${data?.currencyId}`,
				formData
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success(res.data.message);
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Send Multiple Emails</span>
			</MyButton>
			<MyModal
				title="Create Email TO send TO multiple users"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							create
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={data?.title}
							onChange={(e: any) => setData({ ...data, title: e.target.value })}
						/>
					</p>
					<p>
						<p>Select Type: </p>
						<Select
							value={data?.type}
							style={{ width: '150px' }}
							onChange={(value) =>
								setData({
									...data,
									type: value,
								})
							}
						>
							{typeEnum?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem.value}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<p>Message</p>
						<textarea
							placeholder="input message"
							value={data?.message}
							onChange={(e: any) =>
								setData({ ...data, message: e.target.value })
							}
						/>
					</p>
					<p>
						Upload Excel List (CSV):
						<p>
							<Upload
								accept=".txt, .csv"
								showUploadList
								beforeUpload={(file: any) => {
									setData({
										...data,
										csv_file: file,
									});
									return false;
								}}
							>
								<Button>Click to Upload</Button>
							</Upload>{' '}
							Click{' '}
							<a
								href="https://docs.google.com/spreadsheets/d/17kp9KLg09KCdK_1svsTS7kclHwWkIW584oD3eR-KkTk/edit?usp=sharing"
								target="_blank"
								rel="noopener noreferrer"
							>
								HERE
							</a>
							{'  '}
							to see a sample of the csv to upload.
						</p>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default SendMultiple;
