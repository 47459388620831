import { useState } from 'react';
import { Button, Input, message, Modal, Popconfirm, Select } from 'antd';
import baseuri from '../../methods/baseuri';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { createStakingProfile } from '../../methods/redux/reducers/staking';

const CreateStakingProfile = () => {
	const dispatch = useAppDispatch();
	const { loading } = useAppSelector((store) => store.staking);
	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState<any>({});

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const getRewardUserDetails = async () => {
		const email = state.rewardUserEmail;
		if (!email) {
			message.warning('no email has been provided');
			return;
		}
		const res = await baseuri.get('/admin/users/get-user', {
			params: { email: state.rewardUserEmail },
		});
		const rewardUserId = res.data.data?._id;
		if (rewardUserId) {
			setState({ ...state, rewardUserId });
		}
	};

	const createProfile = () => {
		if (!state.rewardUserId) {
			message.warning('Confirm the reward user');
		}
		dispatch(createStakingProfile(state));
	};

	return (
		<div>
			<Modal
				footer={null}
				title="Create Staking Profile"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Profile Name</p>
					<Input name="name" value={state.name} onChange={handleInput} />
				</div>
				<div>
					<p>Percentage APR</p>
					<Input
						name="apr"
						onChange={handleInput}
						placeholder="Eg: 30"
						addonAfter="%"
					/>
				</div>
				<div>
					<p>Withdrawal Penalty APR</p>
					<Input
						name="withdrawalPenaltyApr"
						placeholder="Eg: 50"
						onChange={handleInput}
						addonAfter="%"
					/>
				</div>
				<div>
					<p>Lock Period</p>
					<Input
						name="periodInDays"
						onChange={handleInput}
						placeholder="Eg: 60"
						addonAfter="days"
					/>
				</div>
				<div>
					<p>Staking Currency</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, currencyId: e })}
					>
						{currencies.map((currency: any) => (
							<Select.Option key={currency._id} value={currency._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>
				<div>
					<p>Staking Form</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, stakingForm: e })}
					>
						<Select.Option value="blockchain">Blockchain</Select.Option>
						<Select.Option value="local">Local</Select.Option>
					</Select>
				</div>
				<div>
					<p>Mininum Stake Amount</p>
					<Input
						name="minimumStakeAmount"
						onChange={handleInput}
						placeholder="Eg: 1000"
					/>
				</div>
				<div>
					<p>Maximum Total Amount</p>
					<Input
						name="maximumTotalStakeAmount"
						value={state.maximumTotalAmount}
						onChange={handleInput}
						placeholder="Eg: 30000"
					/>
				</div>
				<div>
					<p>Reward User</p>
					<Input
						type="email"
						name="rewardUserEmail"
						onChange={handleInput}
						placeholder="Email"
					/>
					<div>
						<button type="button" onClick={getRewardUserDetails}>
							confirm
						</button>
						<div>details</div>
					</div>
				</div>
				<div>
					<p>Blockchain Network</p>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, blockchainId: e })}
					>
						{blockchains.map((network: any) => (
							<Select.Option key={network._id} value={network._id}>
								{network.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<div>
					<p>Staking Contract Address</p>
					<Input name="contractAddress" onChange={handleInput} />
				</div>
				<div>
					<p>Profile Details</p>
					<Input.TextArea name="details" onChange={handleInput} />
				</div>
				<div>
					<Popconfirm title="Are you sure?" onConfirm={createProfile}>
						<Button type="primary">Submit</Button>
					</Popconfirm>
				</div>
			</Modal>

			<Button type="primary" loading={loading} onClick={() => setVisible(true)}>
				Create Profile
			</Button>
		</div>
	);
};

export default CreateStakingProfile;
