import { Button, message, Modal } from 'antd';
import { FC } from 'react';
import baseuri from '../../methods/baseuri';

interface IRemoveDoubleFUnding {
	id: string;
}
const RemoveDoubleFUnding: FC<IRemoveDoubleFUnding> = ({ id }) => {
	const removeCron = async () => {
		try {
			const res = await baseuri.post(`admin/compliance/delete-double-funding`, {
				id,
			});
			if (res.status === 200) {
				message.success('Double Funding removed Successfully');
			} else {
				message.info(res.data.message);
			}
		} catch (e) {
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to remove this double funding record?',
			onOk() {
				removeCron();
			},
		});
	};
	return (
		<Button size="small" danger onClick={confirm}>
			Delete
		</Button>
	);
};

export default RemoveDoubleFUnding;
