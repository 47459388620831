import { Card } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../methods/redux/hooks';
import { generalGlobalSearch } from '../methods/redux/reducers/global-search';
import GeneralSearchUserResult from './UserResult';

const GeneralGlobalSearch = () => {
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams({});

	const { generalSearch } = useAppSelector((store) => store.globalSearch);

	useEffect(() => {
		const search = searchParams.get('q');
		dispatch(generalGlobalSearch(search));
	}, [searchParams]);

	return (
		<div>
			<Card>
				<p>
					<strong>Search Result</strong>
				</p>
				{generalSearch.users.length > 0 && (
					<GeneralSearchUserResult users={generalSearch.users} />
				)}
			</Card>
		</div>
	);
};

export default GeneralGlobalSearch;
