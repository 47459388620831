import { Card, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveAdminPermissions,
	retrieveAdminTeam,
	retrieveRoles,
} from '../../methods/redux/reducers/roles';
import AddAdminTeamMember from './AddTeamMember';
import AdminUserItem from './AdminUserItem';

const Roles = () => {
	const dispatch = useAppDispatch();

	const { adminUsers, roles } = useAppSelector((store) => store.roles);

	useEffect(() => {
		dispatch(retrieveAdminTeam({ pageId: 1 }));
		dispatch(retrieveRoles({ isActive: true }));
		dispatch(retrieveAdminPermissions());
	}, []);

	return (
		<Card>
			<h1>Manage Admin Roles & Team Members</h1>
			<p>
				{roles.length} roles and {adminUsers.itemCount} admin users
			</p>
			<div>
				<Link
					to="/settings/roles/roles-permissions"
					style={{ display: 'inline-block', marginRight: 10 }}
				>
					Roles & Permissions
				</Link>
			</div>

			<br />

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<h2>Admin Team Members ({adminUsers.itemCount})</h2>
				<AddAdminTeamMember />
			</div>

			<br />

			{adminUsers.itemsList.map((item: any) => (
				<AdminUserItem key={item._id} item={item} />
			))}
			<br />

			<Pagination
				onChange={(pageId) => dispatch(retrieveAdminTeam({ pageId }))}
				total={adminUsers.itemCount}
				current={adminUsers.currentPage}
				pageSize={adminUsers.perPage}
			/>
		</Card>
	);
};

export default Roles;
