import { Button } from 'antd';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FilterSection = styled.div``;

const CustomCard = styled.div`
	padding: 10px;
	border-radius: 10px;
`;
const MyButton = styled(Button)`
	color: #383838;
	border-radius: 10px;
	margin: 5px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const UsersFilter = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const selectedFilter = location.pathname.split(/[/,?,&]/)[2];

	const filterEnum = [
		{ name: 'All Users', value: 'all-users' },
		{ name: 'verified Users', value: 'verified-users' },
		{ name: 'Unverified Users', value: 'unverified-users' },
		{ name: 'Ghost Users', value: 'ghost-users' },
		// { name: 'Merchant Users', value: 'merchant-users' },
		{ name: 'Robot Users', value: 'robot-users' },
		{ name: 'Users by Country', value: 'users-country' },
		{ name: 'Users by Type', value: 'users-type' },
		{ name: 'Users by Wallet Balance', value: 'balance-search' },
		{ name: 'Users by Last Login', value: 'last-login' },
	];
	return (
		<FilterSection>
			<CustomCard>
				<strong>Filters: </strong>
				{filterEnum.map((n) => (
					<MyButton
						className={selectedFilter === n.value ? 'selected' : ''}
						key={n.value}
						onClick={() =>
							navigate(
								`/users/${
									n.value
								}?startDate=2021-10-01&endDate=${moment().format(
									'YYYY-MM-DD'
								)}&perPage=10&pageId=1&sort=desc`
							)
						}
					>
						{n.name}
					</MyButton>
				))}
			</CustomCard>
		</FilterSection>
	);
};

export default UsersFilter;
