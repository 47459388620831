import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { retrieveToken } from '../methods/utils/auth';
import { defaultImage } from '../methods/utils/general-utils';

const ProfilePillStyle = styled(Link)`
	display: none;

	@media (min-width: 768px) {
		text-decoration: none;
		color: inherit;
		border: none;
		background: transparent;
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 15px;
		padding: 5px;
		border-radius: 50px;
		transition: background 360ms ease;

		&:hover {
			background: #f2f2f2;
		}

		& img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-right: 10px;
		}

		& span {
			font-size: 15px;
			margin-left: 10px;
			margin-top: 25%;
		}
	}
`;

const ProfilePill = () => (
	<ProfilePillStyle to="/my-profile">
		<img
			src={retrieveToken()?.user?.profileUrl || defaultImage}
			alt="user profile"
		/>
		<p>{retrieveToken()?.user?.fullName}</p>
		<div>
			<span className="material-icons">keyboard_arrow_down</span>
		</div>
	</ProfilePillStyle>
);

const ProfilePillStyleMobile = styled(Link)`
	display: none;

	@media (max-width: 768px) {
		text-decoration: none;
		color: inherit;
		border: none;
		background: transparent;
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 15px;
		padding: 5px;
		border-radius: 50px;
		transition: background 360ms ease;

		&:hover {
			background: #f2f2f2;
		}

		& img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-right: 10px;
		}

		& span {
			font-size: 15px;
			margin-left: 10px;
			margin-top: 25%;
		}
	}
`;

export const ProfilePillMobile = () => (
	<ProfilePillStyleMobile to="/my-profile">
		<img
			src={retrieveToken()?.user?.profileUrl || defaultImage}
			alt="user profile"
		/>
		<p>{retrieveToken()?.user?.fullName}</p>
		<div>
			<span className="material-icons">keyboard_arrow_down</span>
		</div>
	</ProfilePillStyleMobile>
);

export default ProfilePill;
