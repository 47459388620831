import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import CreateRole from './CreateRole';
import RoleItem from './RoleItem';
import CreatePermission from './CreatePermission';
import PermissionItems from './PermissionItems';
import {
	retrieveAdminPermissions,
	retrieveRoles,
} from '../../../methods/redux/reducers/roles';

const RolesAndPermissions = () => {
	const dispatch = useAppDispatch();

	const { roles } = useAppSelector((store) => store.roles);

	useEffect(() => {
		dispatch(retrieveRoles({ isActive: true }));
		dispatch(retrieveAdminPermissions());
	}, []);

	return (
		<Card>
			<h1>Roles & Permissions</h1>

			<Divider />

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<h2>Roles </h2>
				<div>
					<CreateRole />
				</div>
			</div>

			<div>
				{roles.map((item: any) => (
					<RoleItem key={item._id} role={item} />
				))}
			</div>

			<Divider />

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<h2>Permissions</h2>
				<div>
					<CreatePermission />
				</div>
			</div>
			<br />
			<div>
				<PermissionItems />
			</div>
		</Card>
	);
};

export default RolesAndPermissions;
