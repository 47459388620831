import { Button, message } from 'antd';
import { chooseEnvironment } from '../../methods/baseuri';

interface IDownloadCSV {
	title: string;
	csv: string;
}

const DownloadCSV = ({ csv, title }: IDownloadCSV) => {
	const handleDownload = async () => {
		if (csv && csv.length > 3) {
			const filename = title.toLowerCase().replace(/\s/g, '-');

			const downloadLink = `${
				chooseEnvironment().base
			}/static/front/downloads/${csv}`;

			const blob = await fetch(downloadLink).then((r) => r.blob());

			const a = document.createElement('a');
			a.download = `${filename}-${Date.now()}`;

			a.href = URL.createObjectURL(blob);
			a.click();
		} else {
			message.info('There are no records to download');
		}
	};

	return (
		<Button disabled={!csv || csv.length < 2} onClick={handleDownload}>
			Download CSV
		</Button>
	);
};

export default DownloadCSV;
