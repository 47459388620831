import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import emailBaseUrl from '../../emailBaseUrl';

export const retrieveCronjobIntervals = createAsyncThunk(
	'cronjobs/retrieveCronjobIntervals',
	async () => {
		const res = await emailBaseUrl.get('/v1/app-cron/cronjob-time');
		return res.data.data;
	}
);

export const retrieveCronjobs = createAsyncThunk(
	'cronjobs/retrieveCronjobs',
	async (params: any) => {
		const res = await emailBaseUrl.get('/v1/app-cron', { params });
		return res.data.data;
	}
);

export const createACronjob = createAsyncThunk(
	'cronjobs/retrieveCronjobs',
	async (data: any) => {
		await emailBaseUrl.post('/v1/app-cron', data);
		return data;
	}
);

export const updateCronjobStatus = createAsyncThunk(
	'cronjobs/updateCronjobStatus',
	async (params: any) => {
		const { jobId, jobStatus } = params;
		await emailBaseUrl.patch(`/v1/app-cron/${jobId}/status`, {
			status: jobStatus,
		});
		return { jobId, jobStatus };
	}
);

export const updateCronjob = createAsyncThunk(
	'cronjobs/updateCronjob',
	async (info: any) => {
		const { jobId, data } = info;
		await emailBaseUrl.patch(`/v1/app-cron/${jobId}`, data);
		message.success('Cronjob updated');
		return info;
	}
);

const cronjobRx = createSlice({
	name: 'cronjobs',
	initialState: {
		systemTime: [],
		subsystemTime: [],
		jobs: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 10,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveCronjobIntervals.fulfilled, (store, action) => {
				let state = store;
				state = { ...state, ...action.payload };
				return state;
			})
			.addCase(retrieveCronjobs.fulfilled, (store, action) => {
				const state = store;
				state.jobs = action.payload;
			})
			.addCase(updateCronjobStatus.fulfilled, (store, action) => {
				const state = store;
				const { jobId, jobStatus } = action.payload;
				const updatedJobs: any = state.jobs.itemsList.map((item: any) => {
					if (item._id === jobId) {
						return { ...item, status: jobStatus };
					}
					return item;
				});
				state.jobs.itemsList = updatedJobs;
			})
			.addCase(updateCronjob.fulfilled, (store, action) => {
				const state = store;
				const { jobId, data } = action.payload;
				const updatedJobs: any = state.jobs.itemsList.map((item: any) => {
					if (item._id === jobId) {
						return { ...item, ...data };
					}
					return item;
				});
				state.jobs.itemsList = updatedJobs;
			});
	},
});

export default cronjobRx.reducer;
