import { Button, Divider, Input, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useState } from 'react';
import { ItemSection } from '.';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { handleSwitch } from '../../../methods/redux/reducers/fundsRobots';

interface IMaxGasLimit {
	cronDetails: any[];
}

const MaxGasLimit: FC<IMaxGasLimit> = ({ cronDetails }) => {
	const [visible, setVisible] = useState(false);
	const [selectedUpdate, setSelectedUpdate] = useState(
		cronDetails?.find((a) => a?.name === 'maximumGasLimit')?.value
	);

	const dispatch = useAppDispatch();

	const showModal = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	const handleUpdate = (id: any) => {
		dispatch(handleSwitch({ id, value: selectedUpdate }));
		setVisible(false);
	};
	return (
		<ItemSection>
			{cronDetails
				?.filter((a) => a?.name === 'maximumGasLimit')
				?.map((data: any) => (
					<ItemSection key={data._id}>
						<h4>Max Gas Limit that CRON should Use</h4>{' '}
						<span>
							{data?.value || 0}{' '}
							<Button type="primary" onClick={showModal}>
								EDIT
							</Button>
						</span>
						<Modal
							visible={visible}
							width={300}
							footer={[
								<Popconfirm
									title="Are you sure?"
									onConfirm={() => handleUpdate(data._id)}
								>
									<Button type="primary" disabled={!selectedUpdate}>
										Update
									</Button>
								</Popconfirm>,
							]}
							onCancel={onClose}
						>
							<Divider />

							<p>Modify Maximum Gas Limit that CRON should Use </p>

							<Input
								value={selectedUpdate}
								defaultValue={data?.value}
								onChange={(e: any) => setSelectedUpdate(e.target.value)}
							/>
						</Modal>
					</ItemSection>
				))}
		</ItemSection>
	);
};

export default MaxGasLimit;
