import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LockSavings from '../../compliance/locked-savings/LockSavings';
import { commas } from '../../methods/utils/general-utils';

interface IFlexibleSavingsItem {
	item: any;
}

interface IStyle {
	migrateStatus: any;
}

const Style = styled.div<IStyle>`
	margin-bottom: 10px;
	padding: 12px;
	background-color: ${(p) =>
		p.migrateStatus === 'migrated' ? '#ecfefa' : '#f8f8f8'};

	& .amount {
		font-size: 18px;
	}

	& .title > * {
		display: inline-block;
	}

	& .details {
		margin-top: 10px;
		border-top: 1px dashed #ccc;
		font-size: 14px;
		font-style: italic;
		text-transform: capitalize;

		& .label {
			color: #aaa;
			font-size: 12px;
			font-style: italic;
		}

		& > * {
			display: inline-block;
			padding: 10px;
		}
	}
`;

const FlexibleSavingsItem = ({ item }: IFlexibleSavingsItem) => (
	<Style migrateStatus={item.migrateStatus}>
		<div>
			<p className="amount">
				{commas(item.depositAmount)} {item.currencyId?.symbol}
			</p>
			<div className="title">
				<Link
					to={`/core-modules/flexible-savings/single/${item.flexibleSavingsId?._id}`}
					title="View Savings Profile"
				>
					{item.flexibleSavingsId?.title} ({item.currencyId?.symbol})
				</Link>
				<p className="user">
					{' '}
					/{' '}
					<Link
						to={`/user-profile/${item.userId?._id}`}
						title="View User Profile"
					>
						@{item.userId?.username}
					</Link>
				</p>
			</div>
		</div>
		<div className="details">
			<div>
				<p>{item.migrateStatus}</p>
				<p className="label">Migrate Status</p>
			</div>
			<div>
				<p>{item.status}</p>
				<p className="label">Status</p>
			</div>
			{/* <div> */}
			{/* 	<p>{moment(item.startDate).format('MMM DD, YY / hh:mm:ss a')}</p> */}
			{/* 	<p className="label">Starts</p> */}
			{/* </div> */}
			{/* <div> */}
			{/* 	<p>{moment(item.endDate).format('MMM DD, YY / hh:mm:ss a')}</p> */}
			{/* 	<p className="label">Ends</p> */}
			{/* </div> */}
			<div>
				<p>
					{commas(item.flexibleSavingsFees)} {item.currencyId?.symbol}
				</p>
				<p className="label">Fee</p>
			</div>
			<div>
				<p>@{item.lastUpdatedByUserId?.username}</p>
				<p className="label">Last Updated By</p>
			</div>
			<div>
				<a href="https://maps.google.com/" target="_blank" rel="noreferrer">
					View Location
				</a>
				<p className="label">Transaction Location</p>
			</div>
			<div>
				<p>View Transaction</p>
				<p className="label">Transaction</p>
			</div>
			<div>
				<p>{moment(item.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Created</p>
			</div>
		</div>
		<div>
			<LockSavings
				id={item?._id}
				text="flexible"
				url="admin/compliance/lock-flexible-savings"
			/>
		</div>
	</Style>
);

export default FlexibleSavingsItem;
