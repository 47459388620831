import { Button, Modal, Divider } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

interface IViewSavingsAndPayoutReport {
	data: any;
	day: any;
	month: any;
	year: any;
}

const Style = styled.div`
	& .details {
		& > * {
			display: inline-block;
			margin-right: 10px;
			padding: 5px;

			& .label {
				color: #aaa;
				font-style: italic;
			}
		}
	}
`;

const ViewSavingsAndPayoutReport = ({
	data,
	day,
	month,
	year,
}: IViewSavingsAndPayoutReport) => {
	const [visible, setVisible] = useState(false);
	return (
		<>
			<Button onClick={() => setVisible(true)}>Savings & Payout</Button>

			<Modal
				visible={visible}
				footer={null}
				onCancel={() => setVisible(false)}
				title={`Savings & Payout Report ${day}/${month}/${year}`}
			>
				<Style>
					<p>
						<strong>APY</strong>
					</p>
					<div className="details">
						<div>
							<p>{data.apy?.currentApy}%</p>
							<p className="label">Current APY</p>
						</div>
						<div>
							<p>{data.apy?.currentXendApy}%</p>
							<p className="label">Current Xend Apy</p>
						</div>
						<div>
							<p>{data.apy?.combinedProtocolApy}%</p>
							<p className="label">Combined Protocol Apy</p>
						</div>
					</div>

					<Divider />

					<p>
						<strong>Fixed Savings</strong>
					</p>
					<div className="details">
						<div>
							<p>{commas(data.fixedSavings?.income)} USD</p>
							<p className="label">Income</p>
						</div>
						<div>
							<p title={data.fixedSavings?.totalCapital}>
								{commas(data.fixedSavings?.totalCapital)} USD
							</p>
							<p className="label">Total Capital</p>
						</div>
						<div>
							<p title={data.fixedSavings?.totalProfit}>
								{commas(data.fixedSavings?.totalProfit)} USD
							</p>
							<p className="label">Total Profit</p>
						</div>
						<div>
							<p>{data.fixedSavings?.rewardTokenSymbol}</p>
							<p className="label">Reward Token</p>
						</div>
						<div>
							<p>
								{commas(data.fixedSavings?.totalRewardTokenValue)}{' '}
								{data.fixedSavings?.rewardTokenSymbol}
							</p>
							<p className="label">Total Reward Token Value</p>
						</div>
						<div>
							<p>{commas(data.fixedSavings?.totalRewardTokenValueUsd)} USD</p>
							<p className="label">Total Reward Token Value in USD</p>
						</div>
					</div>

					<Divider />

					<p>
						<strong>Flexible Savings</strong>
					</p>
					<div className="details">
						<div>
							<p>{commas(data.flexibleSavings?.totalBalance)} USD</p>
							<p className="label">Total Balance</p>
						</div>
						<div>
							<p title={data.flexibleSavings?.totalBalanceUsdValue}>
								{commas(data.flexibleSavings?.totalCapital)} USD
							</p>
							<p className="label">Total Balance in USD</p>
						</div>
						<div>
							<p title={data.flexibleSavings?.totalWithdrawn}>
								{commas(data.flexibleSavings?.totalProfit)} USD
							</p>
							<p className="label">Total Withdrawn</p>
						</div>
					</div>

					<Divider />

					<p>
						<strong>Total Daily Earnings</strong>
					</p>
					<div className="details">
						<div>
							<p>
								{commas(data.totalDailyEarnings?.fixedSavingsDailyEarning)} USD
							</p>
							<p className="label">Fixed Savings Daily Earning</p>
						</div>
						<div>
							<p title={data.totalDailyEarnings?.flexibleSavingsDailyEarning}>
								{commas(data.totalDailyEarnings?.flexibleSavingsDailyEarning)}{' '}
								USD
							</p>
							<p className="label">Flexible Savings Daily Earning</p>
						</div>
					</div>
				</Style>
			</Modal>
		</>
	);
};

export default ViewSavingsAndPayoutReport;
