import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveTwoFactorResetRequests } from '../../methods/redux/reducers/maintenance';
import remakeQueryString from '../../methods/utils/remake-query-string';
import ColumnItem from './ColumnItem';

const Reset2faRequests = () => {
	const dispatch = useAppDispatch();
	const { twoFactorResetRequests } = useAppSelector(
		(store) => store.maintenance
	);

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(10),
				filter: 'pending',
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveTwoFactorResetRequests(queryData));
		}
	}, [searchParams]);

	return (
		<Card>
			<h1>Manage 2FA Reset Requests</h1>

			<Divider />

			<SearchFilter
				title="Search 2FA Requests"
				searchFilters={[
					{
						label: 'Status',
						name: 'filter',
						htmlElement: 'select',
						defaultSelectedValue: 'pending',
						selectOptions: [
							{ name: 'Pending', value: 'pending' },
							{ name: 'Processed', value: 'processed' },
							{ name: 'Declined', value: 'declined' },
						],
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={twoFactorResetRequests.paginator.itemCount} />
				</strong>{' '}
				Requests Found
			</p>

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				pageSize={twoFactorResetRequests.paginator.perPage}
				current={twoFactorResetRequests.paginator.currentPage}
				total={twoFactorResetRequests.paginator.itemCount}
			/>

			<br />

			{twoFactorResetRequests?.itemsList?.map((item: any) => (
				<ColumnItem key={item?._id} data={item} />
			))}
			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				pageSize={twoFactorResetRequests.paginator.perPage}
				current={twoFactorResetRequests.paginator.currentPage}
				total={twoFactorResetRequests.paginator.itemCount}
			/>
		</Card>
	);
};

export default Reset2faRequests;
