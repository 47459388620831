import { Button } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logout } from '../methods/auth';

const LogoutStyle = styled.section`
	padding-top: 10px;
	padding-bottom: 10px;
`;

const Logout = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const logoutAction = async () => {
		setLoading(true);
		await logout(navigate);
		setLoading(false);
	};

	return (
		<LogoutStyle>
			<Button danger loading={loading} onClick={logoutAction}>
				Logout
			</Button>
		</LogoutStyle>
	);
};

export default Logout;
