import {
	Button,
	DatePicker,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
	Space,
} from 'antd';
import moment from 'moment';
import { FC, useState } from 'react';
import baseuri from '../../methods/baseuri';
import { countryCode } from '../../methods/utils/request-helpers';
import { MyButton } from '../email-newsletter/CreateNewsLetter';

const { TextArea } = Input;

interface ICreateGreeting {
	setRefresh: Function;
}
const CreateGreeting: FC<ICreateGreeting> = ({ setRefresh }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [greeting, setGreeting] = useState({
		title: '',
		description: '',
		timeFrame: '',
		expiry_time: '' as any,
		countryCode: '',
	});

	const timeFrameEnum = [
		{
			name: 'All Day',
			value: '',
		},
		{
			name: 'Morning',
			value: 'morning',
		},
		{
			name: 'Afternoon',
			value: 'afternoon',
		},
		{
			name: 'Evening',
			value: 'evening',
		},
	];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post('admin/in-app/alert', greeting);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Greeting created Successfully');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.toString());
		}
	};

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Create New InApp Notification</span>
			</MyButton>
			<Modal
				title="Create New Greeting"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<Space direction="vertical" size="large">
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={greeting?.title}
							onChange={(e: any) =>
								setGreeting({ ...greeting, title: e.target.value })
							}
						/>
					</p>
					<p>
						{' '}
						<p>Description: </p>
						<TextArea
							showCount
							maxLength={100}
							onChange={(e: any) =>
								setGreeting({ ...greeting, description: e.target.value })
							}
						/>
					</p>
					<p>
						<p>Time Frame: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={greeting.timeFrame}
							onChange={(e) => {
								setGreeting({ ...greeting, timeFrame: e });
							}}
						>
							{timeFrameEnum?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<p> Expiry time: </p>
						<DatePicker
							format="YYYY-MM-DD HH:mm:ss"
							disabledDate={(current) =>
								current && current < moment().endOf('day')
							}
							// disabledTime={disabledDateTime}
							showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
							onChange={(date) =>
								setGreeting({
									...greeting,
									expiry_time: date,
								})
							}
						/>
					</p>
					<p>
						Select Country:{' '}
						<Select
							value={greeting.countryCode}
							style={{ width: '150px' }}
							onChange={(value) =>
								setGreeting({
									...greeting,
									countryCode: value,
								})
							}
						>
							{countryCode?.map((optionItem: any) => (
								<Select.Option key={optionItem.name} value={optionItem.code}>
									{optionItem.name}
								</Select.Option>
							))}
						</Select>
					</p>
				</Space>
			</Modal>
		</div>
	);
};

export default CreateGreeting;
