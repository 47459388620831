import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';

const Style = styled.section`
	& .heading {
		text-transform: uppercase;
		color: #aaa;
		font-size: 14px;
		font-weight: bold;
	}

	& .item {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		border-bottom: 1px solid #ddd;
		padding: 15px 0;

		& > * {
			min-width: 100px;
		}
	}
`;

interface IGeneralSearchUserResult {
	users: Array<any>;
}
const GeneralSearchUserResult = ({ users }: IGeneralSearchUserResult) => (
	<Style>
		<Divider />

		<p className="heading">Users</p>

		<div>
			{users.map((item: any) => (
				<div className="item" key={item._id}>
					<p>{item.fullName}</p>
					<p>{item.email}</p>
					<p>@{item.username}</p>
					<p>{moment(item.createdAt).format('lll')}</p>
					<div>
						<Link to={`/user-profile/${item._id}`}>Profile</Link>
					</div>
				</div>
			))}
		</div>
	</Style>
);

export default GeneralSearchUserResult;
