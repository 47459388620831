import { Button, Input, message, Modal, Popconfirm, Switch } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import EditorComponent from '../email-newsletter/EditorComponent';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface ICreateInAPPNews {
	setRefresh: Function;
}
const CreateInAppNews: FC<ICreateInAPPNews> = ({ setRefresh }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [news, setNews] = useState({
		subject: '',
		imageUrl: '',
		preview: '',
		messageBody: '',
		// access_code: '3345constable',
		featured: false,
		orderNumber: 0,
	});

	// const instantProcessing = [
	// 	{
	// 		name: 'YES',
	// 		value: true,
	// 	},
	// 	{
	// 		name: 'No',
	// 		value: false,
	// 	},
	// ];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(`admin/in-app/news`, {
				...news,
				imageUrl: news.imageUrl.replace(/data:.*base64,/, ''),
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('News Created Successfully');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	const handleEditorOnchange = (newText: any) => {
		setNews({ ...news, messageBody: newText });
	};

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Add New In App News</span>
			</MyButton>
			<MyModal
				title="Add In App News"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							create
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					{/* <p>
						Access Code :{' '}
						<Input
							placeholder="input access code"
							value={newsletter?.access_code}
							onChange={(e: any) =>
								setNewsLetter({ ...newsletter, access_code: e.target.value })
							}
						/>
					</p> */}
					<p>
						Subject :{' '}
						<Input
							placeholder="input Title"
							value={news?.subject}
							onChange={(e: any) =>
								setNews({ ...news, subject: e.target.value })
							}
						/>
					</p>
					<p>
						Preview Message :{' '}
						<Input
							placeholder="input preview message"
							value={news?.preview}
							onChange={(e: any) =>
								setNews({ ...news, preview: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={news?.imageUrl}
							onChange={(e: any) =>
								setNews({ ...news, imageUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://im.ge/upload"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://im.ge/upload
						</a>{' '}
						to Upload/generate banner url, Then select Direct Link to see the
						generated link
					</p>
					<p>
						Featured:{' '}
						<Switch
							size="small"
							defaultChecked={false}
							onChange={(e: any) => setNews({ ...news, featured: e })}
						/>
					</p>
					<p>
						Order Number :{' '}
						<Input
							type="number"
							placeholder="order number"
							value={news?.orderNumber}
							onChange={(e: any) =>
								setNews({ ...news, orderNumber: e.target.value })
							}
						/>
					</p>
					<p>
						{' '}
						<p>Message Body: </p>
						<EditorComponent
							editorValue={news?.messageBody}
							editorOnchange={handleEditorOnchange}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default CreateInAppNews;
