import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const retrieveUsersOldPlans = createAsyncThunk(
	'maintenance/retrieveUsersOldPlans',
	async (params: any) => {
		const res = await baseuri.get('/admin/maintenance/old-plans', { params });
		return res.data.data;
	}
);

export const retrieveTwoFactorResetRequests = createAsyncThunk(
	'maintenance/retrieveTwoFactorResetRequests',
	async (params: any) => {
		const res = await baseuri.get('/admin/users/2fa/reset-request', {
			params: { ...params, sort: 'desc' },
		});
		return res.data.data;
	}
);

export const manual2FaResetByAdmin = async (userId: any) => {
	try {
		await baseuri.post('/admin/maintenance/manual-two-factor-reset', {
			userId,
		});
		message.success('2FA Reset Done!');
		return true;
	} catch (e: any) {
		message.error(`Reset Failed! ${e.toString()}`);
		return false;
	}
};

const maintenanceRx = createSlice({
	name: 'maintenance',
	initialState: {
		twoFactorResetRequests: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 10,
			},
		},
		oldPlans: {
			flexible: [],
			fixed: [],
			xAuto: { price: '', createdAt: '' },
			venus: { price: '', createdAt: '' },
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveUsersOldPlans.fulfilled, (store, action) => {
				const state = store;
				state.oldPlans = action.payload;
			})
			.addCase(retrieveTwoFactorResetRequests.fulfilled, (store, action) => {
				const state = store;
				state.twoFactorResetRequests = action.payload;
			}),
});

export default maintenanceRx.reducer;
