import { Divider, Input } from 'antd';
import { useEffect } from 'react';
// import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import { getCurrencies } from '../methods/redux/reducers/currency';
import { PageHeader } from '../theme/theme';

const SettingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const WalletTransactions = () => {
	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store: any) => store.currencies);

	useEffect(() => {
		if (currencies.length < 1) {
			dispatch(getCurrencies({}));
		}
	}, []);
	const items = [
		{
			title: 'Deposits / Funding (InCredit)',
			desc: 'this gives information about all crypto deposits of all the users in xend finance',
			link: '/transactions/in-credit',
			icon: 'south_east',
		},
		{
			title: 'Withdrawal Requests',
			desc: 'Withdrawal requests on Xend Finance mobile app, with options to filter by withdrawal status and other properties.',
			link: '/transactions/withdraw-requests',
			icon: 'payments',
		},
		{
			title: 'Bulk Savings',
			desc: 'any user or admin can create a fixed/flexible savings on behalf(for) another user',
			link: '/transactions/bulk-savings',
			icon: 'groups',
		},
		{
			title: 'Withdrawal Transactions (OutDebit)',
			desc: 'All the withdrawals that have gone out of the Xend Finance application to an external address.',
			link: '/transactions/out-debit',
			icon: 'drive_file_move_outline',
		},
		{
			title: 'Transaction Statements',
			desc: 'Transaction statement receipts for all transactions that happens on the Xend Finance mobile app.',
			link: `/transactions/statements`,
			icon: 'receipt_long',
		},
		{
			title: 'Blockchain Transaction',
			desc: 'Record blockchain transactions for savings & withdrawals from savings protocols, staking protocols etc.',
			link: `/transactions/blockchain`,
			icon: 'currency_bitcoin',
		},
	];

	return (
		<section>
			<PageHeader>Transactions</PageHeader>

			<Input.Search placeholder="Search" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SettingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SettingsItemStyle>
				))}
		</section>
	);
};

export default WalletTransactions;
