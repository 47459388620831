import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { useAppSelector } from '../../methods/redux/hooks';
import { defaultImage } from '../../methods/utils/general-utils';
import ResetAllBalances from './ResetAllBalances';
import ResetUserNegativeWalletBalance from './ResetNegativeBalance';

const Style = styled.div`
	& .address {
		word-wrap: break-word;
	}

	& .network {
		display: inline-block;
		margin-right: 20px;
		font-style: italic;
	}
`;

const BalanceStyle = styled.div<{ negative: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 0;
	margin-right: 10px;
	border-bottom: 1px solid #eee;

	${(p) =>
		p.negative &&
		css`
			color: red;
			font-weight: 600;
		`}

	& img {
		width: 32px;
		height: 32px;
	}

	& .usdValue {
		font-size: 11px;
	}

	& .balance {
		font-size: 15px;
	}

	& > div:first-child {
		margin-right: 5px;
		display: flex;
		align-items: center;

		& > div:first-child {
			margin-right: 5px;
		}
	}
`;

interface IAdminWalletBalances {
	balances: Array<any>;
	walletAddress: string;
}
const AdminWalletBalances = ({
	balances,
	walletAddress,
}: IAdminWalletBalances) => {
	const { blockchains } = useAppSelector((store) => store.currencies);

	const [totalUSDInWallet, setTotalUSDInWallet] = useState(0);

	useEffect(() => {
		const total = balances
			.map(
				(item: any) =>
					Number(item.balance || 0) * Number(item.currencyId?.usdValue || 0)
			)
			.reduce((prev, current) => prev + current, 0);
		setTotalUSDInWallet(total);
	}, [balances]);

	return (
		<Style>
			<p>Wallet Balances</p>

			<br />

			<p className="address" title="Wallet Address">
				{walletAddress}
			</p>

			{blockchains.map((network: any) => (
				<a
					key={network._id}
					title="Open Explorer"
					className="network"
					href={`https://${network.explorerUrl}/address/${walletAddress}`}
					target="_blank"
					rel="noreferrer"
				>
					{network.abbreviation}
				</a>
			))}

			<Divider />

			{balances.length > 0 && (
				<div>
					<p>Total Wallet Balance:</p>
					<h2>
						<strong>
							$<NativeNumber value={totalUSDInWallet} />
						</strong>
					</h2>
					{balances.map((item: any) => (
						<BalanceStyle
							negative={Number(item.balance) < 0}
							key={item.currencyId?._id}
						>
							<div>
								<div>
									<img alt="" src={item.currencyId?.iconUrl || defaultImage} />
								</div>
								<div>
									<p className="balance">
										{item.balance} {item.currencyId?.symbol}
									</p>
									<p className="usdValue">
										$
										<NativeNumber
											value={
												Number(item.balance) *
												Number(item.currencyId?.usdValue || 0)
											}
										/>
									</p>
								</div>
							</div>
							<div>
								{Number(item.balance) < 0 && (
									<ResetUserNegativeWalletBalance
										currencyId={item.currencyId?._id}
									/>
								)}
							</div>
						</BalanceStyle>
					))}
				</div>
			)}
			<br />
			<ResetAllBalances balances={balances} />
		</Style>
	);
};

export default AdminWalletBalances;
