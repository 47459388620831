import { Button, Modal, Input, Select, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	deleteReferralRewardSettings,
	editReferralRewardSettings,
} from '../../methods/redux/reducers/referrals';

interface IEdit {
	item: any;
}

const EditReferralRewardSettings = ({ item }: IEdit) => {
	const dispatch = useAppDispatch();

	const { currencies } = useAppSelector((store) => store.currencies);

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		referralRewardStatus: 'false',
		referralRewardCurrencyId: '',
		referrerRewardAmount: '',
		referredRewardAmount: '',
		referralUserId: '',
		rewardUser: '',
		referralRewardMethod: '',
	});

	const submit = () => {
		const {
			referralRewardStatus,
			referralRewardCurrencyId,
			referralUserId,
			referredRewardAmount,
			referrerRewardAmount,
			referralRewardMethod,
		} = state;
		dispatch(
			editReferralRewardSettings({
				rewardSettingsId: item._id,
				referrerRewardAmount,
				referredRewardAmount,
				referralUserId,
				referralRewardStatus: String(referralRewardStatus),
				referralRewardCurrencyId,
				referralRewardMethod,
			})
		);
		setVisible(false);
	};

	const deleteSetting = () => {
		dispatch(deleteReferralRewardSettings({ rewardSettingsId: item._id }));
		setVisible(false);
	};

	useEffect(() => {
		setState({
			...state,
			...item,
			referralRewardStatus: String(item.referralRewardStatus),
			referralUserId: item.referralUserId?._id,
			referralRewardCurrencyId: item.referralRewardCurrencyId?._id,
			rewardUser: item.referralUserId?.email || item.referralUserId?.username,
		});
	}, [item]);

	return (
		<>
			<Button onClick={() => setVisible(true)}>Edit</Button>

			<Modal
				onOk={submit}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Edit Settings"
			>
				<div>
					<p>Active Status</p>
					<Select
						style={{ width: '100%' }}
						defaultValue={state.referralRewardStatus}
						onChange={(status) =>
							setState({ ...state, referralRewardStatus: status })
						}
					>
						<Select.Option value="true">True</Select.Option>
						<Select.Option value="false">False</Select.Option>
					</Select>
				</div>

				<br />

				<p>New User Reward Amount</p>
				<Input
					value={state.referredRewardAmount}
					onChange={(e) =>
						setState({ ...state, referredRewardAmount: e.target.value })
					}
				/>

				<br />
				<br />

				<p>Old User Reward Amount</p>
				<Input
					value={state.referrerRewardAmount}
					onChange={(e) =>
						setState({ ...state, referrerRewardAmount: e.target.value })
					}
				/>

				<br />
				<br />

				<p>Reward Currency</p>
				<Select
					defaultValue={state.referralRewardCurrencyId}
					onChange={(referralRewardCurrencyId) =>
						setState({ ...state, referralRewardCurrencyId })
					}
					style={{ width: '100%' }}
				>
					{currencies.map((currency: any) => (
						<Select.Option key={currency._id} value={currency._id}>
							{currency.symbol}
						</Select.Option>
					))}
				</Select>

				<br />
				<br />

				<div>
					<p>Reward Payment Method</p>
					<Select
						style={{ width: '100%' }}
						defaultValue={state.referralRewardMethod}
						onChange={(rewardMethod) =>
							setState({ ...state, referralRewardMethod: rewardMethod })
						}
					>
						<Select.Option value="directly-credit">
							Directly Credit
						</Select.Option>
						<Select.Option value="create-fixed-savings">
							Create Fixed Savings
						</Select.Option>
					</Select>
				</div>

				<br />
				<br />

				<p>User to Pay Referral Rewards</p>
				<p>Current User: {state.referralUserId ? state.rewardUser : '--'}</p>
				<Input
					value={state.referralUserId}
					placeholder="Paste the ObjectId of referral user"
					onChange={(e) =>
						setState({ ...state, referralUserId: e.target.value })
					}
				/>

				<Divider />

				<Button danger type="primary" onClick={deleteSetting}>
					Delete Reward Setting
				</Button>
			</Modal>
		</>
	);
};

export default EditReferralRewardSettings;
