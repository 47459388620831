import {
	Button,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Upload,
	Space,
} from 'antd';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
import emailBaseUrl from '../../methods/emailBaseUrl';
import EditorComponent from '../email-newsletter/EditorComponent';
import SendTestNewsLetter from '../email-newsletter/SendTestNewsLetter';
// import PreviewNewsLetter from './PreviewNewsLetter';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
interface ICreateNewsLetter {
	setRefresh: Function;
}
const CreateMultipleEmails: FC<ICreateNewsLetter> = ({ setRefresh }) => {
	const { user } = useSelector((store: any) => store.user);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		subject: '',
		bannerUrl: '',
		previewMessage: '',
		attachment: '',
		access_code: '3345constable',
		creatorEmail: user?.user?.email,
		content: '',
		// countryCode: '',
	});

	const handleCreate = async () => {
		const formData = new FormData();
		formData.append('subject', data?.subject);
		formData.append('bannerUrl', data?.bannerUrl);
		formData.append('previewMessage', data?.previewMessage);
		formData.append('attachment', data?.attachment);
		formData.append('access_code', data?.access_code);
		formData.append('creatorEmail', data?.creatorEmail || user?.user?.email);
		formData.append('content', data?.content);
		try {
			setloading(true);
			const res: any = await emailBaseUrl.post(
				'v1/newsletters/bulk-emails',
				formData
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Draft Saved successfully');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	const handleEditorOnchange = (newText: any) => {
		setData({ ...data, content: newText });
	};

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Send Multiple Email</span>
			</MyButton>

			<Modal
				title="Send Email to Multiple Users"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Create Draft
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Access Code :{' '}
						<Input
							placeholder="input access code"
							value={data?.access_code}
							onChange={(e: any) =>
								setData({ ...data, access_code: e.target.value })
							}
						/>
					</p>
					<p>
						Subject :{' '}
						<Input
							placeholder="input Title"
							value={data?.subject}
							onChange={(e: any) =>
								setData({ ...data, subject: e.target.value })
							}
						/>
					</p>
					<p>
						Preview Message :{' '}
						<Input
							placeholder="input preview message"
							value={data?.previewMessage}
							onChange={(e: any) =>
								setData({ ...data, previewMessage: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={data?.bannerUrl}
							onChange={(e: any) =>
								setData({ ...data, bannerUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://im.ge/upload"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://im.ge/upload
						</a>{' '}
						to Upload/generate banner url, Then select Direct Link to see the
						generated link
					</p>
					<br />
					<p>
						Upload Excel List (CSV):
						<p>
							<Upload
								accept=".txt, .csv"
								showUploadList
								beforeUpload={(file: any) => {
									setData({
										...data,
										attachment: file,
									});
									return false;
								}}
							>
								<Button>Click to Upload</Button>
							</Upload>{' '}
							Click{' '}
							<a
								href="https://docs.google.com/spreadsheets/d/17kp9KLg09KCdK_1svsTS7kclHwWkIW584oD3eR-KkTk/edit?usp=sharing"
								target="_blank"
								rel="noopener noreferrer"
							>
								HERE
							</a>
							{'  '}
							to see a sample of the csv to upload.
						</p>
					</p>
					<Divider />
					<strong>Hi {`${'{{Name}}'}`}</strong>
					<p>
						<EditorComponent
							editorValue={data?.content}
							editorOnchange={handleEditorOnchange}
						/>
					</p>
					<Space direction="horizontal">
						{/* <PreviewNewsLetter /> */}
						<SendTestNewsLetter newsletter={data} typeOfEmail="text" />
					</Space>
					<strong>List Of Test Emails</strong>
					<p>cabafor@gmail.com</p>
					<p> mercyemmanuelotomowo@gmail.com</p>
					<p>ugochukwuaronu@gmail.com</p> <p>emekaece@gmail.com</p>{' '}
					<p>ifihear@gmail.com</p>
					<p>olowogbayigbenga@gmail.com</p> <p>ekwealorobinna112@gmail.com</p>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default CreateMultipleEmails;
