import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../component/Numeral';
import { defaultImage } from '../../methods/utils/general-utils';

interface IGameItem {
	game: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;

const GameItem = ({ game }: IGameItem) => (
	<Style>
		<div>
			<Link to={`./single/${game._id}`} className="title">
				{game.title}
			</Link>
		</div>
		<div className="details">
			<div>
				<p style={{ textTransform: 'capitalize' }}>{game.status}</p>
				<p className="label">Status</p>
			</div>
			{/* <div>
				<p>{game.gameLogic}</p>
				<p className="label">Game Logic</p>
			</div> */}
			<div>
				<p>{game.autoJoin ? 'True' : 'False'}</p>
				<p className="label">Auto Join</p>
			</div>
			<div>
				<p>
					{game.startDate ? moment(game.startDate).format('MMM DD, YY') : '--'}
				</p>
				<p className="label">Start Date</p>
			</div>
			<div>
				<p>{game.endDate ? moment(game.endDate).format('MMM DD, YY') : '--'}</p>
				<p className="label">End Date</p>
			</div>
			<div>
				<div className="currency">
					<img src={game.currencyId?.iconUrl || defaultImage} alt="currency" />
					<p>
						<strong>{game.currencyId?.symbol}</strong>
					</p>
				</div>
				<p className="label">Currency</p>
			</div>
			<div>
				<p>
					<NativeNumber value={game.rewardTokenValue} />{' '}
					{game.currencyId?.symbol}
				</p>
				<p className="label">Reward Amount</p>
			</div>
			<div>
				<p>
					<NativeNumber value={game.maxTokenSpend} /> {game.currencyId?.symbol}
				</p>
				<p className="label">Maximum Spend Amount</p>
			</div>
		</div>
	</Style>
);

export default GameItem;
