import moment from 'moment';
import { useState } from 'react';
import { Button, Modal } from 'antd';

interface INextOfKin {
	details: any;
}
const NextOfKin = ({ details }: INextOfKin) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Button onClick={() => setVisible(true)} type="primary" size="small">
				View Next of Kin
			</Button>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Next of Kin"
				footer={null}
			>
				<div>
					<p>{details?.fullName}</p>
					<p>{details?.email}</p>
					<p>{details?.phoneNumber}</p>
					<p>{details?.relationship}</p>
					<p>{moment(details?.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				</div>
			</Modal>
		</>
	);
};

export default NextOfKin;
