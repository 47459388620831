import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import NativeNumber from '../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getGameStatusCount,
	retrieveGames,
} from '../../methods/redux/reducers/games';
import remakeQueryString from '../../methods/utils/remake-query-string';
import CreateGame from './CreateGame';
import GameItem from './GameItem';
import GameStatusCount from './GameStatusCount';
import { getGlobalEnumsData } from '../../methods/redux/reducers/general';

const Game = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { games } = useAppSelector((store) => store.games);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(20),
				status: 'active',
				// gameLogic: 'Dynamic',
			});
		}
		dispatch(getGameStatusCount());
		dispatch(getGlobalEnumsData());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveGames(queryData));
		}
	}, [searchParams]);

	return (
		<Card>
			<h1>Manage Games</h1>
			<p>
				Manage games, game actions & other game related components on Xend
				Finance.
			</p>
			<br />
			<div>
				<div>
					<CreateGame />
				</div>
				<div style={{ marginTop: '10px', marginBottom: '10px' }}>
					<Link to="./settings">
						<p>Game Settings</p>
					</Link>
				</div>
				<div>
					<Link to="./point-distribution">
						<p>Game Point Distribution</p>
					</Link>
				</div>
			</div>

			<Divider />

			<GameStatusCount />

			<br />

			<SearchFilter
				title="Search Games"
				searchFilters={[
					{
						label: 'Status',
						name: 'status',
						htmlElement: 'select',
						defaultSelectedValue: 'active',
						selectOptions: [
							{ name: 'Pending', value: 'pending' },
							{ name: 'Active', value: 'active' },
							{ name: 'Playing', value: 'playing' },
							{ name: 'Completed', value: 'completed' },
							{ name: 'Deleted', value: 'deleted' },
						],
					},
					{
						label: 'Game Logic',
						name: 'gameLogic',
						htmlElement: 'select',
						defaultSelectedValue: 'Dynamic',
						selectOptions: [
							{ name: 'Static', value: 'Static' },
							{ name: 'Dynamic', value: 'Dynamic' },
						],
					},
					{
						label: 'Start Date',
						name: 'startDate',
						htmlElement: 'datepicker',
					},
					{
						label: 'End Date',
						name: 'endDate',
						htmlElement: 'datepicker',
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={games.paginator.itemCount} />
				</strong>{' '}
				Requests Found
			</p>

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				pageSize={games.paginator.perPage}
				current={games.paginator.currentPage}
				total={games.paginator.itemCount}
			/>

			<br />

			{games?.itemsList?.map((game: any) => (
				<GameItem game={game} key={game._id} />
			))}
			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				pageSize={games.paginator.perPage}
				current={games.paginator.currentPage}
				total={games.paginator.itemCount}
			/>
		</Card>
	);
};

export default Game;
