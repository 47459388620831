import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { truncString } from '../../methods/utils/general-utils';
import SendBulkEmail from './SendBulkEmail';

interface IColumnItems {
	data: any;
}

const Style = styled(Link)`
	all: unset;
	background-color: #f8f8f8;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
	cursor: pointer;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;
const Main = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
`;

const EmailColumn = ({ data }: IColumnItems) => (
	<Main>
		<Style to={`/alerts/multiple-emails/${data?._id}`}>
			<div>
				<p>{data?.subject ? truncString(data?.subject, 20) : '--'}</p>
				<p className="label">Subject</p>
			</div>
			<div>
				<p>
					{data?.previewMessage ? truncString(data?.previewMessage, 20) : '---'}
				</p>
				<p className="label">Preview Message</p>
			</div>
			<div>
				<p>
					{data?.deliveryStatus ? truncString(data?.deliveryStatus, 20) : '---'}
				</p>
				<p className="label">Delivery Status</p>
			</div>
			<div>
				<p>{data?.totalSent || '0'}</p>
				<p className="label">Total Sent</p>
			</div>
			<div>
				<p>{data?.totalFailed || '0'}</p>
				<p className="label">Total Failed</p>
			</div>
			<div>
				<p>{data?.creatorEmail || '---'}</p>
				<p className="label">Sender</p>
			</div>
			<div>
				<p>{data?.emails?.length || 0}</p>
				<p className="label">No of Recipient</p>
			</div>
			<div>
				<p>
					{data.createdAt
						? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Date created</p>
			</div>
			<div>
				<p>
					{data.deliveryDate
						? moment(data.deliveryDate).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Delivery Date</p>
			</div>
		</Style>
		<br />
		<div>
			{data?.deliveryStatus === 'pending' && <SendBulkEmail id={data?._id} />}
		</div>
	</Main>
);
export default EmailColumn;
