import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const AddLevelComponent = styled.div`
	& > button {
		border: none;
		background: transparent;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 12px;
		/* padding: 8px 12px 8px 5px; */
		border-radius: 25px;
		transition: background 400ms ease;

		&:hover {
			background: #e2e2e2;
		}
	}
`;
const AddKYCLevel = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [levelDetails, setInputDetails] = useState({
		levelName: '',
		monthlyWithdrawal: 0,
		dailyWithdrawal: 0,
		id: '',
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(
				`admin/kycLevel/update-kycLevel`,
				levelDetails
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('level added Successfully');
			} else {
				setloading(false);
				message.error(res?.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error('something went wrong');
		}
	};

	return (
		<AddLevelComponent>
			<button type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Add New Level</span>
			</button>
			<Modal
				title="Add Level"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Level Name :{' '}
					<Input
						placeholder="input level name"
						value={levelDetails?.levelName}
						onChange={(e: any) =>
							setInputDetails({ ...levelDetails, levelName: e.target.value })
						}
					/>
				</p>
				<p>
					Maximum Daily Withdrawal (in dollars) :{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.dailyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								dailyWithdrawal: Number(e.target.value),
							})
						}
					/>
				</p>
				<p>
					Maximum Monthly Withdrawal (in dollars):{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.monthlyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								monthlyWithdrawal: Number(e.target.value),
							})
						}
					/>
				</p>
			</Modal>
		</AddLevelComponent>
	);
};

export default AddKYCLevel;
