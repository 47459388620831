import { Card, message, Pagination, Spin } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MyDateSelector from '../../../component/MyDateSelector';
import TableHeaderSection from '../../../component/TableHeaderSection';
import baseuri from '../../../methods/baseuri';
import { referralStatus } from '../../../methods/utils/general-utils';
import { MyEmptyTable } from '../../../robots/email-robot/EmailRobot';
import { MyCard } from '../../../users/users-list/by-usertype/ByUserType';
import { MyTableCard } from '../../../users/users-list/UsersByCountry';
import { MyTable } from '../../../users/users-list/UsersList';
import { DetailsSection } from './UserReferralSection';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;
const ReferralFilter = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});
	const [dataInfo, setDataInfo] = useState({
		loading: false,
	} as any);

	const columns = [
		{
			title: '',
			dataIndex: 'refferalState',
			render: (text: any) => (
				<span
					className="material-icons"
					style={{ color: referralStatus(text).color, opacity: 0.5 }}
				>
					how_to_reg
				</span>
			),
		},
		{
			title: 'Username',
			dataIndex: 'userIdUsedRef',
			render: (user: any) => (
				<>
					<p>
						<Link to={`/user-profile/${user?._id}`}>@{user?.username}</Link>
					</p>
					<p>{user?.email}</p>
				</>
			),
		},
		{
			title: 'Device S/N',
			dataIndex: 'userIdUsedRef',
			render: (text: any) => <strong>{text?.deviceSerialNumber}</strong>,
		},
		{
			title: 'Status',
			dataIndex: 'refferalState',
			render: (text: any) => (
				<span style={{ color: referralStatus(text)?.color }}>
					<strong>{referralStatus(text)?.text}</strong>
				</span>
			),
		},
		{
			title: 'Joined',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			startDate: `${
				searchParams.get('startDate') ||
				moment('2021/10/01').format('YYYY-MM-DD')
			}`,
			endDate: `${
				searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			}`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};

	const getDataInfo = async () => {
		const params1 = getQueryParams();
		setDataInfo({
			...dataInfo,
			loading: true,
		});
		// if (!details) return;
		try {
			const res: any = await baseuri.get(`admin/users/referral-info`, {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setDataInfo({ ...dataInfo, ...res.data.data, loading: false });
			} else {
				setDataInfo({ ...dataInfo, loading: false });
			}
		} catch (error) {
			setDataInfo({ ...dataInfo, loading: false });
		}
	};

	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get('admin/users/filter-referal-users', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (searchParams.get('userId')) {
			handleSearchByDate();
			getDataInfo();
		}
	}, [location.search]);
	return (
		<UsersListPage>
			<MyCard>
				<Spin spinning={dataInfo.loading}>
					<DetailsSection>
						<div className="infoItem">
							Total referrals:{' '}
							<strong>{dataInfo?.totalReferrals || '0'}</strong>
						</div>
						<div className="infoItem">
							Unverified: <strong>{dataInfo?.unverified || '0'}</strong>
						</div>
						<div className="infoItem">
							Unique Referrals:{' '}
							<strong>{dataInfo?.numOfUniqueDeviceIds || '0'}</strong>
						</div>
					</DetailsSection>
				</Spin>
			</MyCard>
			<MainSection>
				<MyDateSelector />
				<MyTableCard>
					<TableHeaderSection
						tableName="User Referrals"
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
					/>
					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.itemsList}
						pagination={false}
					/>

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default ReferralFilter;
