import styled from 'styled-components';
import BackendEnvironment from '../component/BackendEnvironment';
import Notifications from '../component/Notifications';
import ProfilePill from '../component/ProfilePill';
import WalletConnection from '../component/WalletConnection';
import LogoSymbol from './Logo';
import SidebarMobile from './SiderMobile';

const AppHeaderStyle = styled.section`
	background: #ffffff;
	grid-row: 1 / span 1;
	grid-column: 1 / -1;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& .right {
		display: none;
	}

	& .connection {
		display: none;
		margin-right: 10px;
	}

	@media (min-width: 768px) {
		grid-column: 2 / -1;

		& .right {
			display: inline-flex;
			align-items: center;
		}
		& .connection {
			display: block;
		}
	}
`;

const AppHeader = () => (
	<AppHeaderStyle>
		<LogoSymbol section="header" />
		<SidebarMobile />
		<BackendEnvironment />
		<div className="right">
			<Notifications />
			<div className="connection">
				<WalletConnection />
			</div>
			<div>
				<ProfilePill />
			</div>
		</div>
	</AppHeaderStyle>
);

export default AppHeader;
