import { Divider, Input } from 'antd';
import moment from 'moment';
// import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

const SettingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const Users = () => {
	const items = [
		{
			title: 'Users KYC',
			desc: 'All users kyc information on all levels including the status of each of them.',
			link: `/users/kyc?kycStatus=submitted&page=1&pageSize=50&sort=desc`,
			icon: 'verified_user',
		},
		{
			title: 'All Users',
			desc: 'All users Page gives information of the all the users in the database you can filter by country as well.',
			link: `/users/all-users?startDate=2021-10-01&endDate=${moment().format(
				'YYYY-MM-DD'
			)}&perPage=10&pageId=1&sort=desc`,
			icon: 'group',
		},
		{
			title: 'Merchants',
			desc: 'Registered businesses that perform savings actions on the behalf of their customers.',
			link: '/users/merchants',
			icon: 'storefront',
		},
		{
			title: 'Global Search',
			desc: 'Search for a user using email, username, name etc.',
			link: '/users/global-search',
			icon: 'search',
		},
		{
			title: 'Users with Roles [Administrators]',
			desc: 'Administrators, with role management functions.',
			link: '/settings/roles',
			icon: 'admin_panel_settings',
		},
		{
			title: 'User Reports [Queries]',
			desc: 'Retrieve user details that matches various query parameters.',
			link: '/users/reports',
			icon: 'summarize',
		},
	];

	return (
		<section>
			<PageHeader>Users</PageHeader>

			<Input.Search placeholder="Search" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SettingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SettingsItemStyle>
				))}
		</section>
	);
};

export default Users;
