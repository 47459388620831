import { CloseOutlined } from '@ant-design/icons';
import { Input, Button, message, Modal, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface ILockSaving {
	id: any;
	text: string;
	url: string;
}

const LockSavings: FC<ILockSaving> = ({ id, text, url }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		lockedAmount: '0',
		lockedComment: '',
		lockStatus: 'lock',
		[text === 'fixed' ? 'fixedSavingsId' : 'flexibleSavingsId']: id,
	});
	const update = async () => {
		setloading(true);
		try {
			const res: any = await baseuri.put(url, data);

			if (res.status === 200) {
				message.success('updated successfully');
				setloading(false);
				setVisible(false);
			} else {
				message.info(res.data.message);
				setloading(false);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Lock</Button>
			<Modal
				width="350px"
				title="Lock this savings"
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={update}>
						<Button loading={loading} type="primary">
							Lock
						</Button>
					</Popconfirm>,
				]}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<div>
					<p> Lock Amount </p>
					<br />
					<Input
						type="number"
						value={data.lockedAmount}
						onChange={(e) => setData({ ...data, lockedAmount: e.target.value })}
					/>
				</div>
				<br />
				<p>
					Comment :{' '}
					<TextArea
						value={data?.lockedComment}
						onChange={(e: any) =>
							setData({
								...data,
								lockedComment: e.target.value,
							})
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default LockSavings;
