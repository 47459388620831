import { Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { unlockFixedSavingLoan } from '../../methods/redux/reducers/loan';

const ActionSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
	cursor: pointer;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IUnlockLoanFixedSavings {
	fixedSavings: string;
}
const UnlockLoanFixedSavings = ({ fixedSavings }: IUnlockLoanFixedSavings) => {
	const dispatch = useAppDispatch();
	const { loanItemId } = useParams();
	const handle = async () => {
		dispatch(
			unlockFixedSavingLoan({
				loanId: loanItemId,
				fixedSavings,
			})
		);
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Unlock this Fixed Savings ',
			onOk() {
				handle();
			},
		});
	};
	return (
		<ActionSection>
			{loanItemId && <Button onClick={confirm}>Unlock</Button>}
		</ActionSection>
	);
};

export default UnlockLoanFixedSavings;
