import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveCryptoBalances } from '../../methods/redux/reducers/crypto-balances';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import Aggregate from '../../wallets/all-wallet-balances/Aggregate';
import { commas } from '../../methods/utils/general-utils';
import { getSavingsSum } from '../../methods/redux/reducers/dashboard';
import FixedSavingsTab from './fixed-savings-tab';
import FlexibleSavingsTab from './flexible-savings-tab';
import { retrieveTotalAggeragtedStakings } from '../../methods/redux/reducers/staking';
import StakingTab from './staking-tab';
import { retrieveWalletReport } from '../../methods/redux/reducers/robot-report';
import UnremittedFunds from './unremitted-fund-tab';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;

// eslint-disable-next-line prettier/prettier
// const sumTotal = ( data : any[]) =>
// 	data.reduce((acc, item) => acc + item.TotalUsdValue);
const AccountSnapshot = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(getSavingsSum({}));
		dispatch(retrieveTotalAggeragtedStakings());
		dispatch(retrieveWalletReport({ pageId: 1, perPage: 10 }));
	}, []);
	const [searchParams, setSearchParams] = useSearchParams();

	const { cryptoBalances } = useAppSelector((store) => store.cryptoBalances);
	const { savingsSum } = useAppSelector((store) => store.dashboard);
	const { stakingSum } = useAppSelector((store) => store.staking);

	const walletReport = {
		itemsList: [
			{
				_id: '642991a99ab50a61223eba3c',
				userId: '625d6bddb2366fdcd184d0cf',
				email: 'cartrf@live.com',
				walletAddress: '0x983873529f95132BD1812A3B52c98Fb271d2f679',
				currency: 'XEND',
				iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8519.png',
				amount: 3346987.9169539236,
				usdValue: 3346987.9169539236,
				blockchainNetwork: 'Binance Smart Chain Mainnet',
				blockchainNetworkId: 56,
				contractAddress: '0x4a080377f83d669d7bb83b3184a8a5e61b500608',
				createdAt: '2023-04-02T14:31:05.787Z',
				updatedAt: '2023-04-02T14:31:05.787Z',
				id: '642991a99ab50a61223eba3c',
			},

			{
				_id: '642991a99ab50a61223eba3c',
				userId: '625d6bddb2366fdcd184d0cf',
				email: 'cartrf@live.com',
				walletAddress: '0x983873529f95132BD1812A3B52c98Fb271d2f679',
				currency: 'BUSD',
				iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png',
				amount: 3346987.9169539236,
				usdValue: 3346987.9169539236,
				blockchainNetwork: 'Binance Smart Chain Mainnet',
				blockchainNetworkId: 56,
				contractAddress: '0x4a080377f83d669d7bb83b3184a8a5e61b500608',
				createdAt: '2023-04-02T14:31:05.787Z',
				updatedAt: '2023-04-02T14:31:05.787Z',
				id: '642991a99ab50a61223eba3c',
			},
		],
	};
	const sumUnremittedFunds = walletReport.itemsList.reduce(
		(acc, i) => acc + i.usdValue,
		0
	);

	const sumCryptoBalanceByUSD = cryptoBalances.result;
	const totalWalletUSDBal = sumCryptoBalanceByUSD.reduce(
		(acc, i: any) => acc + i.TotalUsdValue,
		0
	);
	const sumStakingBalUSD = stakingSum.result;
	const totalStakingUSDBal = sumStakingBalUSD.reduce(
		(acc, i: any) => acc + i.TotalStakedAmountUsdValue,
		0
	);

	const totalCustodianBalance = sumUnremittedFunds;
	const totalAccountSnapShotBalance =
		totalWalletUSDBal +
		savingsSum.flexibleSavings.totalBalanceUsdValue +
		savingsSum.fixedSavings.totalCapital +
		totalStakingUSDBal;
	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(cryptoBalances.details.paginator.perPage),
			});
		}

		dispatch(getCurrencies({}));
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveCryptoBalances(queryData));
		}
	}, [searchParams]);

	return (
		<Card>
			<h1>Account Snapshot</h1>
			<p>
				This module displays the total amount available in the entire system
			</p>

			<Divider />
			<h1>Total User Account Balance: </h1>
			<p>
				The Figure below Represents the Summation of the figures from each
				section accounting Record
			</p>
			<p>
				<h1> $ {commas(totalAccountSnapShotBalance)}</h1>
			</p>
			<Divider />

			<h2>User Wallet</h2>
			<p>
				<h2>Total User USD Wallet Balance : $ {commas(totalWalletUSDBal)}</h2>
			</p>
			<Aggregate data={cryptoBalances.result} />

			<Divider />

			<h2>User Staking</h2>
			<p>
				<h2>Total User USD Staking Balance : $ {commas(totalStakingUSDBal)}</h2>
			</p>
			<StakingTab data={stakingSum.result} />

			<Divider />

			<h2>User Fixed Savings</h2>
			<p>
				<h2>
					Total User Fixed Savings USD Balance : ${' '}
					{commas(savingsSum.fixedSavings.totalCapital)}
				</h2>
			</p>
			{/* <Aggregate data={cryptoBalances.result} /> */}
			<FixedSavingsTab data={savingsSum.fixedSavings.totalCapital} />

			<Divider />

			<h2>User Flexible Savings</h2>
			<p>
				<h2>
					Total User Flexible Savings USD Balance : ${' '}
					{commas(savingsSum.flexibleSavings.totalBalanceUsdValue)}
				</h2>
			</p>
			<FlexibleSavingsTab
				data={savingsSum.flexibleSavings.totalBalanceUsdValue}
			/>

			<Divider />
			<h1>Total Custodial Balance: </h1>
			<p>
				The Figure below Represents the Summation of the figures from each
				section custodial Record
			</p>
			<p>
				<h1> $ {commas(totalCustodianBalance)}</h1>
			</p>
			<h2>Unremitted Funds</h2>
			<p>
				<h2>
					Total Switch wallet Unremitted USD Balance : ${' '}
					{commas(sumUnremittedFunds)}
				</h2>
			</p>
			<UnremittedFunds data={sumUnremittedFunds} />

			<Divider />
		</Card>
	);
};

export default AccountSnapshot;
