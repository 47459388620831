import { Card } from 'antd';
import styled from 'styled-components';
import { useAppSelector } from '../../methods/redux/hooks';
import SavingsCurrency from './SavingsCurrency';

const ArrangeCurrencies = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	margin-top: 10px;
`;

const SavingsAllowableCurrencies = () => {
	const { currencies } = useAppSelector((store) => store.currencies);

	return (
		<Card>
			<h2>Allowable Savings Currencies</h2>
			<p>
				Supported currencies and toggle to enable or disable individual currency
				for savings.
			</p>
			<ArrangeCurrencies>
				{currencies.map((currency: any) => (
					<SavingsCurrency key={currency._id} currency={currency} />
				))}
			</ArrangeCurrencies>
		</Card>
	);
};

export default SavingsAllowableCurrencies;
