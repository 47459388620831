import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logout } from '../methods/auth';
import { useAppSelector } from '../methods/redux/hooks';

const Style = styled.div`
	height: 100%;
	width: 100%;
	z-index: 2;
	position: fixed;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	padding-top: 20px;
	cursor: wait;

	& .items {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 50px;

		& > div.loading {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			background: orange;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& button {
			cursor: pointer;
		}
	}
`;

const LoadingCover = () => {
	const navigate = useNavigate();
	const { loadingActivities } = useAppSelector((store) => store.general);

	if (loadingActivities.length > 0) {
		return (
			<Style>
				<div className="items">
					<div className="loading">
						<LoadingOutlined style={{ fontSize: 24, color: 'white' }} />
					</div>
					<div>
						<button type="button" onClick={() => logout(navigate)}>
							Logout
						</button>
					</div>
				</div>
			</Style>
		);
	}
	return null;
};

export default LoadingCover;
