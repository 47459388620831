import { Button, Popconfirm } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateCronjobStatus } from '../../methods/redux/reducers/cronjobs';
import DeleteCron from './DeleteCron';
import EditCron from './EditCron';
import TriggerCronjob from './ManuallyTriggerCronjob';

interface ISingleCronjob {
	job: any;
}
interface IStyle {
	color: string;
}
const Style = styled.div<IStyle>`
	background-color: #f8f8f8;
	padding: 15px;
	margin-bottom: 12px;

	& .info {
		font-size: 18px;
		display: inline-flex;
		align-items: center;

		& .status {
			width: 12px;
			height: 12px;
			background-color: ${(p) => p.color};
			margin-right: 20px;
			border-radius: 50%;
		}
	}

	& .details {
		margin-top: 10px;
		border-top: 1px dashed #ddd;
		& > * {
			display: inline-block;
			min-width: 100px;
			max-width: 500px;
			padding: 5px 15px;

			& .label {
				color: #aaa;
				font-size: 13px;
			}
		}
	}
`;

const SingleCronjob = ({ job }: ISingleCronjob) => {
	const dispatch = useAppDispatch();

	const updateStatus = (jobStatus: string) => {
		dispatch(updateCronjobStatus({ jobId: job._id, jobStatus }));
	};

	const chooseColor = (status: any) => {
		if (status === 'active') {
			return 'green';
		}
		if (status === 'cancelled') {
			return 'red';
		}
		if (status === 'paused') {
			return 'orange';
		}
		return 'gray';
	};

	return (
		<Style color={chooseColor(job.status)}>
			<div className="info">
				<div title={job.status} className="status" />
				<p title={job.status}>
					<strong>{job.title}</strong>
				</p>
			</div>
			<div className="details">
				<div>
					<p>
						{job.lastRunAt
							? moment(job.lastRunAt).format('MMM DD, YYYY h:mm:ss a')
							: '--'}
					</p>
					<p className="label">Last run</p>
				</div>
				<div>
					<p>
						<strong>
							{job.nextRunAt
								? moment(job.nextRunAt).format('MMM DD, YYYY h:mm:ss a')
								: '--'}
						</strong>
					</p>
					<p className="label">Next run</p>
				</div>
				<div>
					<p>{job?.lastResponseMessage || '--'}</p>
					<p className="label">Last Response Message</p>
				</div>
				<div>
					<p>{String(job.interval).replace(/_/g, ' ')}</p>
					<p className="label">Interval</p>
				</div>
				<div>
					<p>
						{job.subInterval
							? String(job.subInterval).replace(/_/g, ' ')
							: '--'}
					</p>
					<p className="label">Sub Interval</p>
				</div>
				<div>
					<p>{moment(job.createdAt).format('MMM DD, YYYY h:mm:ss a')}</p>
					<p className="label">Added</p>
				</div>
				<div>
					<p>{moment(job.updatedAt).format('MMM DD, YYYY h:mm:ss a')}</p>
					<p className="label">Updated</p>
				</div>
				<div>
					<p title={job.url}>{job.url}</p>
					<p className="label">URL</p>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<Popconfirm
						title="Are you sure?"
						onConfirm={() => {
							updateStatus(job.status === 'active' ? 'paused' : 'active');
						}}
					>
						<Button
							type="primary"
							disabled={job.status === 'cancelled'}
							size="small"
						>
							{job.status === 'active' ? 'Pause' : 'Activate'}
						</Button>
					</Popconfirm>
					<Popconfirm
						title="Are you sure?"
						onConfirm={() => {
							updateStatus(job.status === 'cancelled' ? 'active' : 'cancelled');
						}}
					>
						<Button size="small">
							{job.status !== 'cancelled' ? 'Disabled' : 'Activate'}
						</Button>
					</Popconfirm>
					<EditCron item={job} />
					<DeleteCron id={job?._id} />
				</div>
				<div>
					<TriggerCronjob jobId={job.id} />
				</div>
			</div>
		</Style>
	);
};

export default SingleCronjob;
