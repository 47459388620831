import { Button, Input, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveInternalSettings,
	updateInternalSettings,
} from '../../methods/redux/reducers/internal-settings';
import { SavingsSettingsStyle } from './SavingsSettings';

const FlexibleSavingsSettings = () => {
	const dispatch = useAppDispatch();

	const settings = useAppSelector((store) => store.internalSettings);

	const [flexibleSettings, setFlexibleSettings] = useState({
		maxAllowedStableCurrencyFlexibleSavingsCount: 0,
		allowFlexibleAutoSave: false,
		updateButtonDisabled: true,
	});

	const toggleAllow = (value: any) => {
		dispatch(
			updateInternalSettings({ allowStableCurrencyFlexibleSavings: value })
		);
	};

	useEffect(() => {
		setFlexibleSettings({
			...flexibleSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	useEffect(() => {
		dispatch(retrieveInternalSettings());
	}, []);

	const updateSettings = () => {
		dispatch(updateInternalSettings(flexibleSettings));
	};

	return (
		<SavingsSettingsStyle>
			<p>
				<strong>Flexible Savings</strong>
			</p>
			<div>
				<p>Toggle allowing of flexible savings on the platform</p>
				<Switch
					size="default"
					onChange={toggleAllow}
					checked={settings.allowStableCurrencyFlexibleSavings}
				/>
			</div>
			<div className="lined-settings">
				<div>
					<p className="label">Maximum Number</p>
					<Input
						onChange={(e) =>
							setFlexibleSettings({
								...flexibleSettings,
								updateButtonDisabled: false,
								maxAllowedStableCurrencyFlexibleSavingsCount: Number(
									e.target.value
								),
							})
						}
						value={
							flexibleSettings.maxAllowedStableCurrencyFlexibleSavingsCount
						}
					/>
				</div>
				<div>
					<p className="label">Autosave</p>
					<Switch
						size="small"
						onChange={(value) =>
							setFlexibleSettings({
								...flexibleSettings,
								updateButtonDisabled: false,
								allowFlexibleAutoSave: value,
							})
						}
						checked={flexibleSettings.allowFlexibleAutoSave}
					/>
				</div>
			</div>
			<div>
				<Button
					type="primary"
					onClick={updateSettings}
					disabled={flexibleSettings.updateButtonDisabled}
				>
					Update Flexible Savings Settings
				</Button>
			</div>
		</SavingsSettingsStyle>
	);
};

export default FlexibleSavingsSettings;
