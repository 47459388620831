import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const retrieveSwapRequest = createAsyncThunk(
	'swap/retrieveSwapRequest',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/swap', { params });
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const retrieveSingleSwapRequest = createAsyncThunk(
	'swap/retrieveSingleSwapRequest',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/swap/single', { params });
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const retrieveSwapRequestCount = createAsyncThunk(
	'swap/retrieveSwapRequestCount',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/swap/status', { params });
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const setSwapToProcessing = createAsyncThunk(
	'swap/setSwapToProcessing',
	async (pendingSwapId: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		await baseuri.post('/admin/swap/start', { pendingSwapId });
		message.success('Swap Request Now in Processing!');
		dispatch(retrieveSingleSwapRequest({ swapTransactionId: pendingSwapId }));
		dispatch(removeFromLoadingQueue(loadingId));
	}
);

export const releaseSwapToPending = createAsyncThunk(
	'swap/releaseSwapToPending',
	async (swapTransactionId: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		await baseuri.post('/admin/swap/release', { swapTransactionId });
		message.success('Swap Request Released!');
		dispatch(retrieveSingleSwapRequest({ swapTransactionId }));
		dispatch(removeFromLoadingQueue(loadingId));
	}
);

export const completeManualSwap = createAsyncThunk(
	'swap/completeManualSwap',
	async (data: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		await baseuri.post('/admin/swap/liquidity/manual', data);
		message.success('Action Completed!');
		dispatch(removeFromLoadingQueue(loadingId));
		dispatch(
			retrieveSingleSwapRequest({ swapTransactionId: data.processingSwapId })
		);
	}
);

export const completeBlockchainSwap = createAsyncThunk(
	'swap/completeBlockchainSwap',
	async (data: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		await baseuri.post('/admin/swap/custodial/complete', data);
		dispatch(removeFromLoadingQueue(loadingId));
	}
);

export const retrieveSwapProtocols = createAsyncThunk(
	'swap/retrieveSwapProtocols',
	async (params: any) => {
		const res = await baseuri.get('/admin/swap/protocol', {
			params: { ...params },
		});

		return res.data.data;
	}
);

export const createSwapProtocol = createAsyncThunk(
	'swap/createSwapProtocol',
	async (data: any) => {
		await baseuri.post('/admin/swap/protocol', data);
		message.success('Swap protocol created successfully!');
	}
);

export const deleteSwapProtocol = createAsyncThunk(
	'swap/deleteSwapProtocol',
	async (params: any) => {
		await baseuri.delete('/admin/swap/protocol', { params });
		message.success('Swap protocol deleted successfully!');
	}
);

export const editSwapProtocol = createAsyncThunk(
	'swap/editSwapProtocol',
	async (data: any, thunkApi) => {
		await baseuri.put('/admin/swap/protocol', data);
		message.success('Edited Successfully!');
		thunkApi.dispatch(retrieveSwapProtocols({}));
	}
);

const swapRx = createSlice({
	name: 'swap',
	initialState: {
		swapRequests: [],
		swapRequestPaginator: {
			itemCount: 0,
			currentPage: 1,
			perPage: 10,
		},
		swapProtocols: {
			itemsList: [],
			paginator: {
				itemCount: 0,
				currentPage: 1,
				perPage: 20,
			},
		},
		swapCount: {
			pending: 0,
			hold: 0,
			processing: 0,
			completed: 0,
			cancelled: 0,
		},
		singleSwapRequest: {
			baseCurrency: { _id: '', symbol: '', iconUrl: '' },
			baseCurrencyAmount: 0,
			baseRefunded: false,
			baseTransactionFee: 0,
			createdAt: '',
			gasFeeInNativeToken: 0,
			baseConvertRequestAmount: 0,
			gasFeeInUSD: 0,
			feeDiscountRule: '',
			feeDiscountPercentage: 0,
			feeCurrencyBalance: 0,
			liquidityProviderFeeInBaseCurrency: 0,
			processedByUserId: { username: null, _id: '', email: '' },
			quoteCurrency: {
				_id: '',
				symbol: '',
				usdValue: 0,
			},
			resultQuoteCurrencyAmountLower: 0,
			resultQuoteCurrencyAmountUpper: 0,
			slippage: 1,
			swapCancellationReason: null,
			swapCompletionTime: null,
			swapData: {
				baseCurrencyBalance: 0,
				details: '',
				transactionFeeInUSD: 0,
				transactionFeeInToken: 0,
				transactionFeeInBaseCurrencyAmount: 0,
				feeTokenCurrencySymbol: '',
				feeCurrencyBalance: 0,
				routeText: '',
				userSwapUseTokenAsFee: false,
				swapUseTokenAsFee: false,
				resultQuoteCurrencyAmountLower: 0,
				resultQuoteCurrencyAmountUpper: 0,
			},
			swapDeadlineMinutes: 0,
			swapFeeTokenId: { _id: '', symbol: '', iconUrl: '', usdValue: 0 },
			swapProtocol: { _id: '', name: '', blockchainNetwork: '' },
			swapStatus: '',
			swapUseTokenAsFee: false,
			userSwapUseTokenAsFee: false,
			transactionBlockchainId: null,
			transactionHash: '',
			transactionSpeed: '',
			updatedAt: '',
			userId: { _id: '', username: '', swapUseTokenAsFee: false },
			walletBaseAmountRefundStatementId: null,
			xendTransactionFee: 0,
			swapHoldReason: '',
			_id: '',
			associatedSwapTransactions: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(retrieveSwapRequest.fulfilled, (store, action) => {
				const state = store;
				state.swapRequests = action.payload.itemsList;
				state.swapRequestPaginator = action.payload.paginator;
			})
			.addCase(retrieveSwapProtocols.fulfilled, (store, action) => {
				const state = store;
				state.swapProtocols = action.payload;
			})
			.addCase(retrieveSingleSwapRequest.fulfilled, (store, action) => {
				const state = store;
				state.singleSwapRequest = {
					...state.singleSwapRequest,
					...action.payload,
				};
			})
			.addCase(retrieveSwapRequestCount.fulfilled, (store, action) => {
				const state = store;
				state.swapCount = action.payload;
			})
			.addCase(releaseSwapToPending.rejected, (state, action) => {
				message.error(action.error.message);
			}),
});

export default swapRx.reducer;
