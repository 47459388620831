import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { commas } from '../../methods/utils/general-utils';

const FixedSavingsProfileStyle = styled.div`
	padding: 15px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		text-transform: capitalize;
	}

	& .details {
		display: flex;
		flex-wrap: wrap;
		justify-items: space-between;
		align-items: flex-end;
		border-top: 1px dashed #ccc;
		margin-top: 10px;

		& > * {
			min-width: 100px;
			margin-right: 15px;
			padding: 5px 0;
			font-size: 13px;

			& .label {
				color: #bbb;
				font-size: 12px;
			}
		}
	}
`;

interface IFixedSavingsProfile {
	fixedSavingsProfile: any;
}
const FixedSavingsProfile = ({ fixedSavingsProfile }: IFixedSavingsProfile) => (
	<FixedSavingsProfileStyle>
		<div>
			<Link
				to={`/core-modules/fixed-savings/single/${fixedSavingsProfile._id}`}
				className="title"
			>
				{fixedSavingsProfile.title} ({fixedSavingsProfile.currencyId?.symbol})
			</Link>
		</div>
		<div className="details">
			<div>
				<Link to={`/user-profile/${fixedSavingsProfile.userId?._id}`}>
					<p>@{fixedSavingsProfile.userId?.username}</p>
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<p>
					{commas(fixedSavingsProfile.cacheCapital)}{' '}
					{fixedSavingsProfile.currencyId?.symbol}
				</p>
				<p className="label">Cache Capital</p>
			</div>
			<div>
				<p>{fixedSavingsProfile.durationDays} days</p>
				<p className="label">Duration</p>
			</div>
			<div>
				<p>
					{moment(fixedSavingsProfile.startDate).format(
						'MMM D, YY / hh:mm:ss a'
					)}
				</p>
				<p className="label">Start Date</p>
			</div>
			<div>
				<p>
					{fixedSavingsProfile.endDate
						? moment(fixedSavingsProfile.endDate).format(
								'MMM D, YY / hh:mm:ss a'
						  )
						: '--'}
				</p>
				<p className="label">End Date</p>
			</div>
			<div>
				<p>
					{commas(fixedSavingsProfile.cacheProfit)}{' '}
					{fixedSavingsProfile.currencyId?.symbol}
				</p>
				<p className="label">Cache Profit</p>
			</div>
			<div>
				<p>
					{fixedSavingsProfile.cacheProfitLastDate
						? moment(fixedSavingsProfile.cacheProfitLastDate).format(
								'MMM DD, YY / hh:mm:ss a'
						  )
						: '--'}
				</p>
				<p className="label">Cache Profit Last Date</p>
			</div>
			<div>
				<p>{commas(fixedSavingsProfile.cacheRewardTokenValue)} USD</p>
				<p className="label">Cache Reward Token</p>
			</div>
			<div>
				<p>{fixedSavingsProfile.payoutStatus}</p>
				<p className="label">Payout Status</p>
			</div>
			<div>
				<p>{fixedSavingsProfile.requestTerminationStatus}</p>
				<p className="label">Termination Request Status</p>
			</div>
			<div>
				<p>
					{moment(fixedSavingsProfile.createdAt).format(
						'MMM D, YY / hh:mm:ss a'
					)}
				</p>
				<p className="label">Created At</p>
			</div>
			<div>
				<p>
					{moment(fixedSavingsProfile.updatedAt).format(
						'MMM D, YY / hh:mm:ss a'
					)}
				</p>
				<p className="label">Updated At</p>
			</div>
			<div>
				<p>@{fixedSavingsProfile.lastUpdatedByUserId?.username}</p>
				<p className="label">Last Updated By</p>
			</div>
		</div>
	</FixedSavingsProfileStyle>
);

export default FixedSavingsProfile;
