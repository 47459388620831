import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveCryptoBalances } from '../../methods/redux/reducers/crypto-balances';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import CryptoBalanceItem from './CryptoBalanceItem';
// import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
// import { MyTable } from '../../users/users-list/UsersList';
import Aggregate from './Aggregate';
import remakeQueryString from '../../methods/utils/remake-query-string';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;

const AllWalletBalances = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { cryptoBalances } = useAppSelector((store) => store.cryptoBalances);
	const { currencies } = useAppSelector((store) => store.currencies);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(cryptoBalances.details.paginator.perPage),
			});
		}

		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(retrieveCryptoBalances(queryData));
		}
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	return (
		<Card>
			<h1>Crypto Balances</h1>
			<p>
				List of wallet balances on the platform for different currencies and
				their aggregate sum.
			</p>

			<Divider />

			<Aggregate data={cryptoBalances.result} />

			<Divider />

			<SearchFilter
				title="Filter by Currency"
				searchFilters={[
					{
						label: 'Currency',
						name: 'currencyId',
						htmlElement: 'select',
						selectOptions: currencySelect,
					},
				]}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of{' '}
				<strong>{cryptoBalances.details.paginator.itemCount} items</strong>{' '}
				found
			</p>

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={cryptoBalances.details.paginator.itemCount}
				current={cryptoBalances.details.paginator.currentPage}
				pageSize={cryptoBalances.details.paginator.perPage}
			/>

			<br />

			{cryptoBalances.details.itemsList.map((item: any) => (
				<CryptoBalanceItem key={item._id} item={item} />
			))}

			<br />

			<Divider />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={cryptoBalances.details.paginator.itemCount}
				current={cryptoBalances.details.paginator.currentPage}
				pageSize={cryptoBalances.details.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default AllWalletBalances;
