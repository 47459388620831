import { Empty, Image, message, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { ImageSection } from '../../users/users-kyc/ImageComponent';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';
import { LastSection } from '../in-app-alert/InAPPNotifications';
import CreateInAppNews from './CreateInAppNews';
import DeleteNews from './DeleteNews';
import EditInAppNews from './EditInAppNews';

const InAppNews = () => {
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState('');
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => ({
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		// if (!location.search) return;
		try {
			const res: any = await baseuri.get('data/news', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!location.search) {
			const params1 = getQueryParams();
			setSearchParams({ ...params1 });
		} else {
			getData();
		}
	}, [location.search, refresh]);

	const columns = [
		{
			title: 'Subject',
			key: 'subject',
			render: (text: any) => <strong>{text?.subject}</strong>,
		},
		{
			title: 'Preview Message',
			key: 'preview',
			render: (text: any) => <strong>{text?.preview}</strong>,
		},
		{
			title: 'Banner',
			key: 'bannerUrl',
			render: (text: any) => (
				<ImageSection>
					{text?.imageUrl ? (
						<Image
							width="100%"
							height="100%"
							src={text?.imageUrl}
							alt="Unable to load Image"
						/>
					) : (
						'NO IMAGE'
					)}
				</ImageSection>
			),
		},
		{
			title: 'Message Body',
			key: 'messageBody',
			render: (text: any) => <strong>{text?.messageBody}</strong>,
		},
		{
			title: 'Featured',
			key: 'featured',
			render: (text: any) => <strong>{text?.featured ? 'Yes' : 'No'}</strong>,
		},
		{
			title: 'Order Number',
			key: 'orderNumber',
			render: (text: any) => <strong>{text?.orderNumber}</strong>,
		},
		{
			title: 'Last Update',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Created By',
			key: 'creatorEmail',
			render: (text: any) => <strong>{text?.authorId?.email}</strong>,
		},
		{
			title: '',
			key: 'subject',
			render: (text: any) => (
				<LastSection>
					<EditInAppNews item={text} setRefresh={setRefresh} />
					<DeleteNews id={text?._id} setRefresh={setRefresh} />
				</LastSection>
			),
		},
	];
	return (
		<div>
			<CreateInAppNews setRefresh={setRefresh} />
			<MyTableCard>
				<TableHeaderSection
					tableName="InApp News"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
				/>

				<MyTable
					locale={{
						emptyText: (
							<Empty
								image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
								// imageStyle={{
								// 	height: 60,
								// }}
								description="No data to display"
							/>
						),
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</div>
	);
};

export default InAppNews;
