import { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Divider,
	Modal,
	Popconfirm,
	Select,
	message,
} from 'antd';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { retrieveFiatCustodialAccounts } from '../../../methods/redux/reducers/currency';
import baseuri from '../../../methods/baseuri';
import NativeNumber from '../../../component/Numeral';

const AccountStyle = styled.button`
	position: relative;
	display: inline;
	border: none;
	background-color: transparent;
	border: 1px solid #ccc;
	padding: 20px;
	margin-right: 20px;
	text-align: left;
`;

const Account = ({ account }: { account: any }) => (
	<AccountStyle>
		<p>Bank: {account.fiatBankId?.symbol}</p>
		<Divider />
		<p>
			<strong>
				<NativeNumber value={account.balance} />{' '}
				{account.fiatCurrencyId?.symbol}
			</strong>
		</p>
	</AccountStyle>
);

const Style = styled(Card)``;

const CustodialAccounts = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	const { custodialAccounts, fiatBanks } = useAppSelector(
		(store) => store.currencies
	);

	const [state, setState] = useState({
		fiatBankId: '',
		fiatCurrencyId: '',
	});

	useEffect(() => {
		dispatch(retrieveFiatCustodialAccounts({}));
	}, []);

	const submit = async () => {
		try {
			const res = await baseuri.post('/admin/fiat-custodial-accounts', state);
			if (res.status === 200) {
				message.success('Added Successfully!');
				setVisible(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Style>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<h1>Custodial Accounts</h1>
					<p>
						Add record fiat custodial wallet where Xend Finance holds fiat funds
					</p>
				</div>
				<Button onClick={() => setVisible(true)}>Add Account</Button>
			</div>

			<Divider />
			{custodialAccounts.itemsList.map((account: any) => (
				<Account key={account._id} account={account} />
			))}

			<Modal
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={submit}>
						<Button type="primary">Add Wallet</Button>
					</Popconfirm>,
				]}
				title="Add Custodial Wallet"
				open={visible}
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Choose Bank</p>
					<Select
						style={{ width: '100%' }}
						size="large"
						onChange={(bank) =>
							setState({
								...state,
								fiatBankId: JSON.parse(bank)._id,
								fiatCurrencyId: JSON.parse(bank).localCurrencyId?._id,
							})
						}
					>
						<Select.Option value="">---</Select.Option>

						{fiatBanks.itemsList.map((bank: any) => (
							<Select.Option value={JSON.stringify(bank)} key={bank._id}>
								{bank.name} ({bank.symbol})
							</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</Style>
	);
};

export default CustodialAccounts;
