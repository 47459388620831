import { ethers } from 'ethers';
import { chooseEnvironment } from './baseuri';
import { erc20 } from './utils/general-utils';

const retrieveRPC = (networkId: number, networkEnv: any) => {
	if (networkEnv === 'mainnet') {
		if (networkId === 1)
			return 'https://mainnet.infura.io/v3/ae49873716f04646a3de12e4bc37bc3f';
		if (networkId === 56) return 'https://bsc-dataseed.binance.org/';
		if (networkId === 137)
			// return 'https://polygon-rpc.com/c9a2fe300dd9496f9ee19bc4cb2c4689';
			return 'https://polygon-mainnet.g.alchemy.com/v2/FDaxG4jpaYHddzfeC_4TMAH2EJoF5jLa';
		if (networkId === 42161) return 'https://arb-pokt.nodies.app';
	}

	// default // testnet
	if (networkId === 1) return '';
	if (networkId === 56)
		return 'https://data-seed-prebsc-1-s1.binance.org:8545/';
	if (networkId === 137) return 'https://rpc-mumbai.maticvigil.com/';
	if (networkId === 42161) return 'https://arb-pokt.nodies.app';
	//
	return '';
};

export const nativeCryptoWalletBalance = async (
	networkId: any,
	walletAddress: any
) => {
	const rpc = retrieveRPC(networkId, chooseEnvironment().env);

	const provider = new ethers.providers.JsonRpcProvider(rpc);
	const balanceOf = await provider.getBalance(walletAddress);

	return ethers.utils.formatUnits(balanceOf);
};

const cryptoWalletBalances = async (
	contractAddress: string,
	networkId: any,
	walletAddress: any
) => {
	const rpc = retrieveRPC(networkId, chooseEnvironment().env);

	const provider = new ethers.providers.JsonRpcProvider(rpc);

	const tokenContract = new ethers.Contract(
		contractAddress,
		erc20 as any,
		provider
	);

	const balanceOf = await tokenContract.balanceOf(walletAddress);
	let amount = ethers.utils.formatUnits(balanceOf);

	if (networkId === 137) {
		amount = ethers.utils.formatUnits(balanceOf, 'mwei');
	}

	return amount;
};

export default cryptoWalletBalances;
