import { useEffect, useState } from 'react';
import styled from 'styled-components';
import NativeNumber from '../component/Numeral';
import cryptoWalletBalances, {
	nativeCryptoWalletBalance,
} from '../methods/crypto-wallet-balances';
import { useAppSelector } from '../methods/redux/hooks';
import { defaultImage } from '../methods/utils/general-utils';

interface ICurrency {
	address: string;
	currency: any;
	blockchainId: string;
	networkId: any;
}
const Currency = ({
	networkId,
	address,
	currency,
	blockchainId,
}: ICurrency) => {
	const { contracts } = useAppSelector((store) => store.currencies);

	const [tokenAmount, setTokenAmount] = useState('0');

	const getAmount = async (contractAddress: any) => {
		const amount = await cryptoWalletBalances(
			contractAddress,
			networkId,
			address
		);
		setTokenAmount(amount);
	};

	useEffect(() => {
		const filterResult = contracts.filter(
			(contract: any) =>
				contract.blockchainNetworkId?._id === blockchainId &&
				contract.currencyId?._id === currency._id
		);

		if (filterResult.length > 0) {
			const { contractAddress } = filterResult[0];
			getAmount(contractAddress);
		}
	}, [contracts]);

	return (
		<div
			className={`amount ${Number(tokenAmount) > 0 ? 'bold' : ''}`}
			key={currency._id}
		>
			<img alt="" src={currency.iconUrl || defaultImage} width={16} />
			<span>
				<NativeNumber value={tokenAmount} decimalPlaces={4} /> {currency.symbol}
			</span>
		</div>
	);
};

interface IBlockchain {
	blockchain: any;
	address: string;
}
const Blockchains = ({ blockchain, address }: IBlockchain) => {
	const { currencies } = useAppSelector((store) => store.currencies);
	return (
		<div>
			<p className="network-name">{blockchain.name}</p>
			<div>
				{currencies.map((currency: any) => (
					<Currency
						key={currency._id}
						address={address}
						blockchainId={blockchain._id}
						networkId={Number(blockchain.networkId)}
						currency={currency}
					/>
				))}
			</div>
		</div>
	);
};

const Style = styled.div`
	border-bottom: 1px solid #eee;
	padding: 10px 0;

	& .address {
		word-break: break-all;
		font-weight: 600;
		font-style: italic;
	}

	& .network {
		word-wrap: break-word;
		display: inline-block;
		margin-right: 20px;
		font-style: italic;
	}

	& .networks {
		display: grid;
		margin-top: 10px;
		grid-template-columns: 1fr;
		column-gap: 20px;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		& .network-name {
			text-transform: uppercase;
			font-style: italic;
			font-size: 11.5px;
			color: #aaa;
			margin-bottom: 5px;
		}

		& .amount {
			margin-right: 5px;
			display: inline-flex;
			align-items: center;
			padding: 2px 5px;
			font-size: 14px;
			border-radius: 3px;

			&.bold {
				font-weight: 600;
			}

			& img {
				margin-right: 7px;
				border-radius: 50%;
				width: 16px;
				height: 16px;
			}
		}
	}
`;

interface IAddresses {
	walletAddress: any;
	network: any;
}

const Addresses = ({ network, walletAddress }: IAddresses) => {
	const [balance, setBalance] = useState('0');

	const getTheNativeBalance = async () => {
		const d = await nativeCryptoWalletBalance(network.networkId, walletAddress);
		setBalance(d);
	};

	useEffect(() => {
		getTheNativeBalance();
	}, [network, walletAddress]);

	return (
		<a
			title="Open Explorer"
			className="network"
			href={`https://${network.explorerUrl}/address/${walletAddress}`}
			target="_blank"
			rel="noreferrer"
		>
			{network.abbreviation}{' '}
			<strong>
				(<NativeNumber value={balance} decimalPlaces={6} />{' '}
				{network.abbreviation})
			</strong>
		</a>
	);
};

interface ICustodialWallets {
	wallet: any;
}

const CustodialWallets = ({ wallet }: ICustodialWallets) => {
	const { blockchains } = useAppSelector((store) => store.currencies);

	return (
		<Style>
			<div>
				<div>
					<p className="address">{wallet.address}</p>
					{blockchains.map((network: any) => (
						<Addresses
							walletAddress={wallet.address}
							network={network}
							key={network._id}
						/>
					))}
				</div>
				<div className="networks">
					{blockchains.map((chain: any) => (
						<Blockchains
							blockchain={chain}
							address={wallet.address}
							key={chain._id}
						/>
					))}
				</div>
			</div>
		</Style>
	);
};

export default CustodialWallets;
