import { Button, Modal } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { adminLiquidateDefaultedLoan } from '../../methods/redux/reducers/loan';

interface ILiquidateLoan {
	loanItem: any;
}

const LiquidateDefaultedLoan = ({ loanItem }: ILiquidateLoan) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const validateLiquidation = () => {
		const hasReachedMaturity = moment().isSameOrAfter(
			loanItem.expectedPaybackDate
		);

		return hasReachedMaturity && !loanItem.repaymentCompleted;
	};

	const handleAdminLiquidate = () => {
		if (validateLiquidation()) {
			dispatch(adminLiquidateDefaultedLoan({ loanId: loanItem._id }));
		}
	};

	return (
		<>
			<Button disabled={validateLiquidation()} onClick={() => setVisible(true)}>
				Liquidate Loan
			</Button>

			<Modal
				open={visible}
				title="Liquidate Defaulted Loan"
				onCancel={() => setVisible(false)}
				okText="Understand? Proceed"
				onOk={() => handleAdminLiquidate()}
			>
				<p>
					Proceed with this action if the customer [
					<strong>@{loanItem.userId?.username}</strong>] has defaulted in loan
					payment. Proceed only if this is true.
				</p>
			</Modal>
		</>
	);
};

export default LiquidateDefaultedLoan;
