import { message, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	Link,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../../component/TableHeaderSection';
import baseuri from '../../../methods/baseuri';
import { commas } from '../../../methods/utils/general-utils';
import { MyEmptyTable } from '../../../robots/email-robot/EmailRobot';
import { MyCard } from '../../../users/users-list/by-usertype/ByUserType';
import { MyTableCard } from '../../../users/users-list/UsersByCountry';
import SingleBulkColumn from './SingleBulkColumn';

const MainPage = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 10px;

	& > div {
		display: flex;
		flex-direction: column;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			font-size: 16px;
		}
	}
`;
const SingleBulkSavings = () => {
	const { id } = useParams();

	const [data, setData] = useState({
		profile: {} as any,
		receivers: { itemsList: [], paginator: {} as any },
	});

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => ({
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const getData = async () => {
		const params1 = getQueryParams();

		setData({
			profile: {},
			receivers: { itemsList: [], paginator: {} as any },
		});
		// if (!location.search) return;
		try {
			const res: any = await baseuri.get(
				'admin/bulk-savings/stable-currency/items',
				{
					params: {
						...params1,
						bulkSavingsId: id,
					},
				}
			);

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
			}
		} catch (error: any) {
			message.error(error.response.data.message);
		}
	};

	useEffect(() => {
		getData();
	}, [location.search]);
	return (
		<MyCard>
			<MainPage>
				<div className="titleSection">
					<span className="value">{data?.profile?.title}</span>
					<span className="label">Title</span>
				</div>
				<div className="titleSection">
					<span className="value">
						$ {commas(data?.profile?.totalAmount || '0')}
					</span>
					<span className="label">Total Amount</span>
				</div>
				<div>
					<span className="value">
						$ {commas(data?.profile?.totalTransactionFee || 0)} BUSD
					</span>
					<span className="label">Transaction Fee</span>
				</div>
				<div>
					<span className="value"> {data?.profile?.status} </span>
					<span className="label">Status</span>
				</div>
				<div>
					<span className="value"> {data?.profile?.durationDays} Days</span>
					<span className="label">Duration</span>
				</div>
				{/* <div>
					<span className="value">
						{moment(data?.profile?.durationDays) > moment()
							? moment(data?.profile?.durationDays).diff(moment(), 'days')
							: '0'}{' '}
						Day Remaining
					</span>
				</div> */}
				<p>
					<Link
						title="View Profile"
						to={`/user-profile/${data?.profile?.funder}`}
					>
						View Funder
					</Link>
				</p>
			</MainPage>
			<MyTableCard>
				<TableHeaderSection
					tableName="All Recipients"
					currentPage={data?.receivers?.paginator?.currentPage || 0}
					totalRecords={data?.receivers?.paginator?.itemCount || 0}
				/>

				{data?.receivers.itemsList?.length > 0 ? (
					data?.receivers?.itemsList?.map((item: any) => (
						<SingleBulkColumn key={item?._id} data={item} />
					))
				) : (
					<MyEmptyTable />
				)}
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					pageSize={data?.receivers?.paginator?.perPage || 0}
					current={data?.receivers?.paginator?.currentPage || 0}
					total={data?.receivers?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</MyCard>
	);
};

export default SingleBulkSavings;
