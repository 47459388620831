import { Button, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { createSwapProtocol } from '../../methods/redux/reducers/swap';

const CreateSwapProtocol = () => {
	const dispatch = useAppDispatch();

	const { blockchains } = useAppSelector((store) => store.currencies);

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		swapProtocolName: '',
		swapProtocolBlockchainNetworkId: '',
	});

	const submit = () => {
		dispatch(createSwapProtocol(state));
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Create Protocol</Button>

			<Modal
				width={320}
				visible={visible}
				onOk={submit}
				title="Create Swap Protocol"
				onCancel={() => setVisible(false)}
			>
				<div>
					<p>Protocol Name</p>
					<Input
						onChange={(e) =>
							setState({ ...state, swapProtocolName: e.target.value })
						}
						value={state.swapProtocolName}
					/>
				</div>
				<br />
				<div>
					<p>Protocol Network</p>
					<Select
						style={{ width: '100%' }}
						onChange={(swapProtocolBlockchainNetworkId) =>
							setState({ ...state, swapProtocolBlockchainNetworkId })
						}
					>
						{blockchains.map((blockchain: any) => (
							<Select.Option value={blockchain._id}>
								{blockchain.abbreviation}
							</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</>
	);
};

export default CreateSwapProtocol;
