import { Button, DatePicker, Input } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';

const HeaderExtraStyle = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	}
`;

const HeaderExtraSection = () => {
	const [searchText, setSearchText] = useState<string>();
	const [searchParams] = useSearchParams();
	const [date, setDate] = useState({
		startDate: `${
			searchParams.get('startDate') || moment('2021/10/01').format('YYYY-MM-DD')
		}`,
		endDate: `${searchParams.get('endDate') || moment().format('YYYY-MM-DD')}`,
	});

	const getQueryParams = () => ({
		startDate: `${
			searchParams.get('startDate') || moment('2021/10/01').format('YYYY-MM-DD')
		}`,
		endDate: `${searchParams.get('endDate') || moment().format('YYYY-MM-DD')}`,
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const navigate = useNavigate();
	// const handleDeepSearch = async (search: any) => {
	// 	setLoading(true);
	// 	try {
	// 		const res: any = await baseuri.get('/admin/search-user', {
	// 			params: { search },
	// 		});
	// 		if (res.status === 200) {
	// 			setUsers({
	// 				...users,
	// 				records: res.data.data,
	// 				currentPage: 1,
	// 				totalRecords: res.data.data?.length,
	// 			});
	// 		}
	// 		setLoading(false);
	// 	} catch (error) {
	// 		setLoading(false);
	// 	}
	// };

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						placeholder="Start"
						allowClear={false}
						format="ll"
						onChange={(date1) =>
							date1?.isSameOrBefore(moment(date.endDate)) &&
							setDate({ ...date, startDate: date1.format('YYYY-MM-DD') })
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={moment(date.startDate)}
						defaultValue={moment(date.startDate)}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						placeholder="End"
						allowClear={false}
						format="ll"
						value={moment(date.endDate)}
						// onChange={(date) =>
						// 	date?.isSameOrAfter(startDate) && setEndDate(date)
						// }
						onChange={(date1) =>
							date1?.isSameOrAfter(moment(date.startDate)) &&
							setDate({ ...date, endDate: date1.format('YYYY-MM-DD') })
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						defaultValue={moment(date.endDate)}
					/>
				</div>
				<div>
					<Button
						type="primary"
						onClick={() => {
							const n = getQueryParams();
							// setSearchParams({ ...n, ...date });
							// navigate(`/users/all-users`);
							navigate({
								pathname: `/users/all-users`,
								search: createSearchParams({ ...n, ...date }).toString(),
							});
						}}
					>
						Search
					</Button>
				</div>
			</div>
			<div>
				<Input.Search
					placeholder="Search with username, email or wallet address"
					enterButton
					// onSearch={(e) => globalSearch(e)}
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					onSearch={() => navigate(`/users/all-users/${searchText}`)}
				/>
			</div>
		</HeaderExtraStyle>
	);
};

export default HeaderExtraSection;
