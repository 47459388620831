import moment from 'moment';
import styled from 'styled-components';

interface IUserPointsItem {
	userPoints: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;

const GameRewardPointItem = ({ userPoints }: IUserPointsItem) => (
	<Style>
		<div>
			<p className="title">{userPoints.userId.fullName}</p>
		</div>
		<div className="details">
			<div>
				<p className="label">Email</p>

				<p style={{ textTransform: 'capitalize' }}>{userPoints.userId.email}</p>
			</div>
			<div>
				<p className="label">User Name</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userPoints.userId.username}
				</p>
			</div>
			<div>
				<p className="label">Game Title</p>

				<p style={{ textTransform: 'capitalize' }}>{userPoints.gameId.title}</p>
			</div>
			<div>
				<p className="label">Game Game Point</p>

				<p style={{ textTransform: 'capitalize' }}>{userPoints.point}</p>
			</div>

			<div>
				<p className="label">User Clamied Game Point</p>

				<p style={{ textTransform: 'capitalize' }}>
					{userPoints.claim === true ? 'true' : 'false'}
				</p>
			</div>

			<div>
				<p className="label">User Claimed Point Date </p>
				<p style={{ textTransform: 'capitalize' }}>
					{' '}
					{userPoints.claimDate
						? moment(userPoints.claimDate).format('MMM DD, YY')
						: '--'}
				</p>
			</div>
		</div>
	</Style>
);

export default GameRewardPointItem;
