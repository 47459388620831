import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getSavingsSum } from '../../methods/redux/reducers/dashboard';
import { commas } from '../../methods/utils/general-utils';

const Style = styled.div`
	& .balance-details {
		font-weight: 600;

		& > * {
			padding: 5px 20px 5px 1px;
			display: inline-block;
		}

		& .label {
			color: #c5c5c5;
			font-weight: 400;
		}
	}
`;

const UserSavingsInfo = () => {
	const dispatch = useAppDispatch();

	const { savingsSum } = useAppSelector((store) => store.dashboard);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		dispatch(getSavingsSum({ userId: user.profile?._id }));
	}, [user]);

	return (
		<Style>
			<Card>
				<p>
					<strong>Daily / Today&apos;s Savings Earnings</strong>
				</p>

				<Divider />

				<div>
					<p>
						<strong>Flexible Savings</strong>
					</p>
					<div className="balance-details">
						<div>
							<p style={{ color: '#1a846b' }}>
								{commas(
									savingsSum?.totalDailyEarnings?.flexibleSavingsDailyEarning
								) || 0}{' '}
								USD
							</p>
							<p className="label">Estimated Payout Today</p>
						</div>
						<div>
							<p>
								{commas(savingsSum?.flexibleSavings?.totalBalanceUsdValue)} USD
							</p>
							<p className="label">Available Balance</p>
						</div>
						<div>
							<p style={{ color: '#d52a4f' }}>
								{savingsSum?.flexibleSavings?.totalWithdrawn} USD
							</p>
							<p className="label">Total Withdrawn</p>
						</div>
					</div>
				</div>
				<Link to={`/core-modules/flexible-savings/${user.profile?._id}`}>
					View @{user.profile?.username}&apos;s flexible savings
				</Link>

				<Divider />

				<div>
					<p>
						<strong>Fixed Savings</strong>
					</p>
					<div className="balance-details">
						<div>
							<p style={{ color: '#1a846b' }}>
								{commas(
									savingsSum?.totalDailyEarnings?.fixedSavingsDailyEarning
								) || 0}{' '}
								USD
							</p>
							<p className="label">Estimated Daily Earnings</p>
						</div>
						<div>
							<p>{commas(savingsSum?.fixedSavings?.totalCapital) || 0} USD</p>
							<p className="label">Total Capital</p>
						</div>
						<div>
							<p>{commas(savingsSum?.fixedSavings?.totalProfit) || 0} USD</p>
							<p className="label">Total Profit</p>
						</div>
						<div>
							<p>{commas(savingsSum?.fixedSavings?.income) || 0} USD</p>
							<p className="label">Income</p>
						</div>
						<div>
							<p>
								{commas(savingsSum?.fixedSavings?.totalRewardTokenValue) || 0}{' '}
								XEND
							</p>
							<p className="label">Total Reward Token</p>
						</div>
						<div>
							<p>
								{commas(savingsSum?.fixedSavings?.totalRewardTokenValueUsd) ||
									0}{' '}
								USD
							</p>
							<p className="label">Total Reward Token in USD</p>
						</div>
					</div>

					<Link to={`/core-modules/fixed-savings/${user.profile?._id}`}>
						View @{user.profile?.username}&apos;s fixed savings
					</Link>
					<div>
						<Link to={`/maintenance/old-plans/${user.profile?._id}`}>
							View @{user.profile?.username}&apos;s old migrated plans
						</Link>
					</div>
				</div>
			</Card>
		</Style>
	);
};

export default UserSavingsInfo;
