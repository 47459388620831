import { Pagination, Card, Divider } from 'antd';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import SearchFilter from '../../component/Filter';
import { retrieveFixedSavingsPendingProfitBuild } from '../../methods/redux/reducers/fixed-savings';
import FixedSavingsProfile from './FixedSavingsProfile';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const FixedSavingsPendingProfitBuildPage = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();

	const { pendingProfitBuild } = useAppSelector((store) => store.fixedSavings);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(20),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(
					retrieveFixedSavingsPendingProfitBuild({ ...queryData, userId })
				);
			} else {
				dispatch(retrieveFixedSavingsPendingProfitBuild(queryData));
			}
		}
	}, [searchParams]);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	return (
		<Card>
			<h1>
				Fixed Savings Pending Payouts
				{userId && (
					<span>
						for{' '}
						<Link to={`/user-profile/${user.profile?._id}`}>
							@{user.profile?.username}
						</Link>
					</span>
				)}
			</h1>

			<Divider />
			<SearchFilter
				title="Search Pending Profit Build"
				searchFilters={
					[
						// {
						// 	label: 'Start Date',
						// 	name: 'startDate',
						// 	htmlElement: 'datepicker',
						// },
						// {
						// 	label: 'End Date',
						// 	name: 'endDate',
						// 	htmlElement: 'datepicker',
						// },
					]
				}
				retrieveQueryString={updateQueryString}
			/>

			<br />

			<p>
				Total of <strong>{pendingProfitBuild.paginator.itemCount} items</strong>{' '}
				found
			</p>
			<Divider />

			<div>
				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={pendingProfitBuild.paginator.itemCount}
					current={pendingProfitBuild.paginator.currentPage}
					pageSize={pendingProfitBuild.paginator.perPage}
				/>
			</div>

			<br />

			{pendingProfitBuild.itemsList.map((profile: any) => (
				<FixedSavingsProfile key={profile._id} fixedSavingsProfile={profile} />
			))}

			<br />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={pendingProfitBuild.paginator.itemCount}
				current={pendingProfitBuild.paginator.currentPage}
				pageSize={pendingProfitBuild.paginator.perPage}
				showSizeChanger
				showQuickJumper
			/>
		</Card>
	);
};

export default FixedSavingsPendingProfitBuildPage;
