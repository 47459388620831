import { Card, message, Pagination, Space } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import SelectItem from 'react-select';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import {
	Country,
	countryCode,
	hideEmail,
} from '../../methods/utils/request-helpers';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import UsersFilter from './UsersFilter';
import { MyTable, UsersListPage } from './UsersList';

const ParamsSection = styled.div`
	display: flex;
	margin-bottom: 5px;
	gap: 4em;
`;
export const MyTableCard = styled(Card)`
	/* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
	margin-top: 20px;
	padding-top: 0px;
	border: 1px solid #d5d3d3;
	border-radius: 10px;
`;

const UsersByCountry = () => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	// const [params, setParams] = useState({
	// 	countryType: `${searchParams.get('countryType') || 'zone'}`,
	// 	countryCode: `${searchParams.get('countryCode') || 'ng'}`,
	// });
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const getQueryParams = () => ({
		countryType: `${searchParams.get('countryType') || 'zone'}`,
		countryCode: `${searchParams.get('countryCode') || 'ng'}`,
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const columns = [
		{
			title: 'Fullname',
			dataIndex: 'fullName',
			key: 'fullName',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text: any) => <h3>{hideEmail(text)}</h3>,
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNo',
			key: 'phoneNo',
			render: (text: any) => <h3>{text}</h3>,
		},
		{
			title: 'Number of Referrals',
			dataIndex: 'referralCount',
			key: 'referralCount',
		},
		{
			title: 'SignUp Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).subtract(1, 'hour').format('lll'),
			key: 'createdAt',
		},

		{
			title: '',
			// key: '_id',
			render: (user: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${user._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
	];
	const countryTypeEnum = [
		{ name: 'Time Zone', value: 'zone' },
		{ name: 'IP Location', value: 'ip' },
		{ name: 'Residence', value: 'residence' },
	];
	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		if (!location.search) return;
		try {
			const res: any = await baseuri.get('/admin/users', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};
	const options = countryCode.map((option) => ({
		value: option.code,
		label: option.name,
	}));
	const options2 = countryTypeEnum.map((option) => ({
		value: option.value,
		label: option.name,
	}));
	useEffect(() => {
		if (!searchParams.get('countryType')) {
			const params1 = getQueryParams();
			setSearchParams({ ...params1 });
		} else {
			getData();
		}
	}, [location.search]);
	return (
		<UsersListPage>
			<UsersFilter />
			<ParamsSection>
				<div>
					Select Country:{' '}
					<SelectItem
						options={options}
						value={Country(
							searchParams.get('countryCode')?.toLocaleUpperCase() || 'NG'
						)}
						placeholder={Country(
							searchParams.get('countryCode')?.toLocaleUpperCase() || 'NG'
						)}
						defaultValue={{ label: 'Nigeria', value: 'NG' }}
						onChange={(e: any) => {
							const params1 = getQueryParams();
							setSearchParams({
								...params1,
								countryCode: e.value.toLocaleLowerCase(),
							});
						}}
					/>
				</div>
				<div>
					Country Type:{' '}
					<SelectItem
						options={options2}
						value={{
							label: countryTypeEnum.find(
								(n) => n.value === searchParams.get('countryType')
							)?.name,
							value:
								searchParams.get('countryType')?.toLocaleUpperCase() || 'zone',
						}}
						defaultValue={{ label: 'Time Zone', value: 'zone' }}
						placeholder="Please Select"
						onChange={(e: any) => {
							const params1 = getQueryParams();
							setSearchParams({ ...params1, countryType: e.value });
							// setParams({ ...params, countryType: e.value });
						}}
					/>
				</div>
			</ParamsSection>
			<MyTableCard>
				<TableHeaderSection
					tableName="Users by Country"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
					downloadURL="https://apiprostaging.xend.finance/api/admin/users"
				/>

				<MyTable
					locale={{
						emptyText: <MyEmptyTable />,
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</UsersListPage>
	);
};

export default UsersByCountry;
