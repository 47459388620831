import { Button, Divider, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';
import { SavingsSettingsStyle } from '../savings/SavingsSettings';

const OtherLoanSettings = () => {
	const dispatch = useAppDispatch();

	const settings = useAppSelector((store) => store.internalSettings);

	const [loanSettings, setLoanSettings] = useState({
		_id: '',
		maxDurationDays: 0,
		minDurationDays: 0,
		minLoanAmountUsd: 0,
		maxLoanAmountUsd: 0,
		loanInterest: 0,
		updateButtonDisabled: true,
	});

	useEffect(() => {
		setLoanSettings({
			...loanSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	const updateSettings = () => {
		const {
			maxDurationDays,
			minDurationDays,
			minLoanAmountUsd,
			maxLoanAmountUsd,
			loanInterest,
		} = loanSettings;

		dispatch(
			updateInternalSettings({
				settingsId: loanSettings._id,
				maxDurationDays,
				minDurationDays,
				minLoanAmountUsd,
				maxLoanAmountUsd,
				loanInterest,
			})
		);
	};

	return (
		<SavingsSettingsStyle>
			<div className="lined-settings">
				<div>
					<p className="label">Max Duration Days</p>
					<Input
						addonAfter="days"
						onChange={(e) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								maxDurationDays: Number(e.target.value),
							})
						}
						value={loanSettings.maxDurationDays}
					/>
				</div>
				<div>
					<p className="label">Min Duration Days</p>
					<Input
						addonAfter="days"
						onChange={(e) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								minDurationDays: Number(e.target.value),
							})
						}
						value={loanSettings.minDurationDays}
					/>
				</div>
				<div>
					<p className="label">Min Loan Amount in USD</p>
					<Input
						addonAfter="usd"
						onChange={(e) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								minLoanAmountUsd: Number(e.target.value),
							})
						}
						value={loanSettings.minLoanAmountUsd}
					/>
				</div>
				<div>
					<p className="label">Max Loan Amount in USD</p>
					<Input
						addonAfter="usd"
						onChange={(e) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								maxLoanAmountUsd: Number(e.target.value),
							})
						}
						value={loanSettings.maxLoanAmountUsd}
					/>
				</div>
				<div>
					<p className="label">Loan Interest Rate</p>
					<Input
						type="number"
						// addonAfter="usd"
						onChange={(e) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								loanInterest: Number(e.target.value),
							})
						}
						value={loanSettings.loanInterest}
					/>
				</div>

				{/* <div>
					<Tooltip title="The least number of days a fixed savings can have">
						<p className="label">
							<span>Minimum Duration</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						addonAfter="days"
						onChange={(e) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								minFixedSavingsDurationDays: Number(e.target.value),
							})
						}
						value={loanSettings.minFixedSavingsDurationDays}
					/>
				</div> */}
			</div>
			<div>
				<Button
					type="primary"
					onClick={updateSettings}
					disabled={loanSettings.updateButtonDisabled}
				>
					Update Loan Savings Settings
				</Button>
			</div>

			<Divider />
		</SavingsSettingsStyle>
	);
};

export default OtherLoanSettings;
