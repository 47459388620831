import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Input, message } from 'antd';
import { useState } from 'react';
import baseuri from '../methods/baseuri';
import { logout } from '../methods/auth';
import { removeToken } from '../methods/utils/auth';

const Style = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	& .start {
		min-width: 300px;
		border: 1px solid #ccc;
		padding: 20px;
	}

	& .end {
		border: 1px solid #ccc;
		padding: 20px;
		min-width: 300px;
		max-width: 600px;
		& .qrcode {
			margin-top: 5px;
			& img {
				border: 1px solid #ccc;
				width: 300px;
			}
		}
	}
`;

const TwoFactorSetup = () => {
	const navigate = useNavigate();

	const [screen, setScreen] = useState<'start' | 'end'>('start');
	const [enableData, setEnableData] = useState({
		loading: false,
		qr: '',
		secret: '',
		code: '',
		password: '',
	});

	const startEnable = async () => {
		try {
			setEnableData({ ...enableData, loading: true });
			const res = await baseuri.post('/auth/2fa/start/enable');
			setEnableData({
				...enableData,
				...res.data.data?.secret,
				loading: false,
			});
			setScreen('end');
		} catch (e: any) {
			setEnableData({ ...enableData, loading: false });
			console.error(e);
		}
	};

	const enable2fa = async () => {
		try {
			const tempAuthData = sessionStorage.getItem('ds-login');
			if (!tempAuthData) {
				console.info('go to login page');
			}
			const tempAuthDataObject = tempAuthData && JSON.parse(tempAuthData);
			if (!tempAuthDataObject.password) {
				console.info('provide input to put password');
			}
			setEnableData({ ...enableData, loading: true });
			await baseuri.post('/auth/2fa/create/enable', {
				secret: enableData.secret,
				code: enableData.code,
				password: tempAuthDataObject.password,
			});

			setEnableData({ ...enableData, loading: false });
			message.success('2FA setup is completed! Login to continue.');

			setTimeout(() => {
				removeToken();
				navigate('/login');
			}, 1000);
		} catch (e: any) {
			setEnableData({ ...enableData, loading: false });
			console.error(e);
		}
	};

	return (
		<Style>
			{screen === 'start' && (
				<div className="start">
					<h1>Enable 2FA</h1>
					<p>
						As an administrator, you will have to enable the 2FA authentication
						for your account to use the admin dashboard.
					</p>
					<Divider />
					<Button type="primary" onClick={startEnable}>
						Start Process
					</Button>
					<Button
						loading={enableData.loading}
						onClick={() => logout(navigate)}
						danger
					>
						Logout
					</Button>
				</div>
			)}
			{screen === 'end' && (
				<div className="end">
					<h1>2FA Setup</h1>
					<br />
					<h2>Steps to complete</h2>
					<ul>
						<ol>1. Open your authenticator app and scan the QRCode below</ol>
						<ol>
							2. Type in the code the app generates for your email into the
							input provided here
						</ol>
					</ul>
					<Divider />
					<p>Scan QRCode</p>
					<div className="qrcode">
						<img src={enableData.qr} alt="scan qrcode" />
					</div>
					<br />
					<div>
						<p>Code from authenticator app</p>
						<Input
							placeholder="Code"
							name="code"
							autoComplete="off"
							value={enableData.code}
							onChange={(e) =>
								setEnableData({ ...enableData, code: e.target.value })
							}
						/>
					</div>
					<br />
					<Button
						loading={enableData.loading}
						type="primary"
						onClick={() => enable2fa()}
					>
						Enable 2FA
					</Button>
				</div>
			)}
		</Style>
	);
};

export default TwoFactorSetup;
