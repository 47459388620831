import { DatePicker, Button, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

interface IAccountantsDateSelector {
	onGetReport: any;
}

const Style = styled.div`
	& .search-inputs {
		display: flex;
		align-items: flex-end;
	}
`;

const AccountantsDateSelector = ({ onGetReport }: IAccountantsDateSelector) => {
	const [state, setState] = useState({
		startDate: '',
		endDate: '',
	});

	const selectedDateHandoff = () => {
		if (state.startDate.length > 2 && state.endDate.length > 2) {
			onGetReport(state);
		} else {
			message.warn('Choose start and end date.');
		}
	};

	return (
		<Style>
			<p>Please select a start and end date before searching for the report.</p>
			<div className="search-inputs">
				<div>
					<p>Start Date</p>
					<DatePicker
						format="MMM DD, YYYY"
						size="large"
						placeholder="Start Date"
						onChange={(e) =>
							setState({ ...state, startDate: e?.format('YYYY-MM-DD') || '' })
						}
					/>
				</div>
				<div>
					<p>End Date</p>
					<DatePicker
						format="MMM DD, YYYY"
						size="large"
						placeholder="End Date"
						onChange={(e) =>
							setState({ ...state, endDate: e?.format('YYYY-MM-DD') || '' })
						}
					/>
				</div>
				<div>
					<Button
						disabled={state.startDate.length < 2 || state.endDate.length < 2}
						type="primary"
						block
						size="large"
						onClick={() => selectedDateHandoff()}
					>
						Get Report
					</Button>
				</div>
			</div>
		</Style>
	);
};

export default AccountantsDateSelector;
