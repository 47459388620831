import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
// import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
// import { getFixedSavings } from '../methods/redux/reducers/fixed-savings';
// import FixedSavingsProfile from './FixedSavingsProfile';
import SearchFilter from '../../component/Filter';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import {
	getFixedSavings,
	getFixedTerminationRequests,
	retrieveFixedSavingsPendingPayouts,
	retrieveFixedSavingsPendingProfitBuild,
} from '../../methods/redux/reducers/fixed-savings';
import FixedSavingsProfile from './FixedSavingsProfile';
import { commas } from '../../methods/utils/general-utils';
import { getSavingsSum } from '../../methods/redux/reducers/dashboard';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const Style = styled.div`
	& .mini-report {
		& > * {
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 10px;
		}
		& .amount {
			font-weight: 600;
			font-size: 16px;

			&.red {
				color: var(--red-color);
			}
		}
	}

	& .out-links {
		display: inline-block;
		margin-right: 20px;
		text-decoration: underline;
	}
`;

const FixedSavings = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const { userId } = useParams();

	const { currencies } = useAppSelector((store) => store.currencies);
	const { savingsSum } = useAppSelector((store) => store.dashboard);
	const { user } = useAppSelector((store) => store.userProfile);

	const {
		pendingProfitBuild,
		fixedSavingsProfile,
		pendingPayout,
		terminationRequests,
	} = useAppSelector((store) => store.fixedSavings);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'active',
				pageId: '1',
				perPage: String(fixedSavingsProfile.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
			dispatch(getSavingsSum({ userId }));
			dispatch(
				retrieveFixedSavingsPendingPayouts({
					perPage: 1,
					userId,
				})
			);
			dispatch(
				retrieveFixedSavingsPendingProfitBuild({
					perPage: 1,
					userId,
				})
			);
		} else {
			dispatch(getSavingsSum({}));
			dispatch(
				retrieveFixedSavingsPendingPayouts({
					perPage: 1,
				})
			);
			dispatch(
				retrieveFixedSavingsPendingProfitBuild({
					perPage: 1,
				})
			);
		}

		dispatch(getCurrencies({}));
	}, []);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(getFixedSavings({ ...queryData, userId }));
			} else {
				dispatch(getFixedSavings(queryData));
			}
		}
		dispatch(
			getFixedTerminationRequests({
				status: 'requested',
				pageId: 1,
				perPage: 20,
			})
		);
	}, [searchParams]);

	const currencySelect = currencies.map((item: any) => {
		if (item.allowSavings) {
			return {
				name: `${item.symbol} *`,
				value: item._id,
			};
		}
		return {
			name: item.symbol,
			value: item._id,
		};
	});

	return (
		<Style>
			<Card>
				<h1>
					Fixed Savings{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile?._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>

				<Divider />

				<div className="mini-report">
					<Card>
						<p className="amount red">
							{commas(savingsSum.totalDailyEarnings.fixedSavingsDailyEarning)}{' '}
							USD
						</p>
						<p>Estimated Payout Today</p>
					</Card>
					<Card>
						<p className="amount">
							{commas(savingsSum.fixedSavings.totalCapital)} USD
						</p>
						<p>Estimated Capital</p>
					</Card>
					<Card>
						<p className="amount">
							{commas(savingsSum.fixedSavings.income)} USD
						</p>
						<p>Estimated Income</p>
					</Card>
					<Card>
						<p className="amount">
							{commas(savingsSum.fixedSavings.totalProfit)} USD
						</p>
						<p>Estimated Profit</p>
					</Card>
					<Card>
						<p className="amount">
							{commas(savingsSum.fixedSavings.totalRewardTokenValue)} XEND (
							{commas(savingsSum.fixedSavings.totalRewardTokenValueUsd)} USD)
						</p>
						<p>Rewards</p>
					</Card>
					<Card>
						<p className="amount">{terminationRequests.paginator.itemCount}</p>
						<Link
							to={`/core-modules/fixed-savings/termination${
								userId ? `/${userId}` : '?status=requested'
							}`}
						>
							Liquidation Requests
						</Link>
					</Card>
					<Card>
						<p className="amount">{pendingPayout.paginator.itemCount}</p>
						<Link
							to={`/core-modules/fixed-savings/pending-payouts${
								userId ? `/${userId}` : ''
							}`}
						>
							Pending Payouts
						</Link>
					</Card>
					<Card>
						<p className="amount">{pendingProfitBuild.paginator.itemCount}</p>
						<Link
							to={`/core-modules/fixed-savings/pending-profit-build${
								userId ? `/${userId}` : ''
							}`}
						>
							Pending Profit Build
						</Link>
					</Card>
				</div>

				<br />

				<div>
					<Link
						title="Fixed Savings Topups"
						className="out-links"
						to={`/core-modules/fixed-savings/top-ups${
							userId ? `/${userId}` : ''
						}`}
					>
						Go To Top-up Items
					</Link>
					<Link
						className="out-links"
						to={`/core-modules/fixed-savings/expiring${
							userId ? `/${userId}` : ''
						}`}
					>
						Expiring
					</Link>
					<Link className="out-links" to="/report/savings">
						Report
					</Link>
					<Link className="out-links" to="/settings/savings">
						Settings
					</Link>
				</div>

				<Divider />
				<SearchFilter
					title="Search Fixed Savings"
					searchFilters={[
						{
							label: 'Status',
							name: 'status',
							defaultSelectedValue: 'active',
							htmlElement: 'select',
							selectOptions: [
								{ name: 'Pending', value: 'pending' },
								{ name: 'Active', value: 'active' },
								{ name: 'Completed', value: 'completed' },
								{ name: 'Terminated', value: 'terminated' },
								{ name: 'Deleted', value: 'deleted' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
						{
							label: 'Start Date',
							name: 'startDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'End Date',
							name: 'endDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'Title',
							name: 'title',
							htmlElement: 'input',
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<p>
					Total of{' '}
					<strong>{fixedSavingsProfile.paginator.itemCount} items</strong> found
				</p>
				<Divider />

				<div>
					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={fixedSavingsProfile.paginator.itemCount}
						current={fixedSavingsProfile.paginator.currentPage}
						pageSize={fixedSavingsProfile.paginator.perPage}
					/>
				</div>

				<br />

				{fixedSavingsProfile.itemsList.map((profile: any) => (
					<FixedSavingsProfile
						key={profile._id}
						fixedSavingsProfile={profile}
					/>
				))}

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={fixedSavingsProfile.paginator.itemCount}
					current={fixedSavingsProfile.paginator.currentPage}
					pageSize={fixedSavingsProfile.paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>
		</Style>
	);
};

export default FixedSavings;
