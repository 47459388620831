import { Button, Input, Modal, Popconfirm } from 'antd';
import { useState } from 'react';
import Uppy from '@uppy/core';
import { Dashboard as UpdloadDash } from '@uppy/react';
import {
	addGameFile,
	retrieveSingleGame,
} from '../../../methods/redux/reducers/games';
import { useAppDispatch } from '../../../methods/redux/hooks';

const AddGameFile = ({
	fileType,
	gameId,
}: {
	gameId: any;
	fileType: 'image' | 'video';
}) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		description: '',
		videoId: '',
		videoDescription: '',
		image: null,
	} as any);

	const uppy = new Uppy({
		allowMultipleUploadBatches: false,
		onBeforeFileAdded: (currentFile) => {
			setState({ ...state, image: currentFile.data });
			return currentFile;
		},
	});

	// const handleChooseFile = (e: any) => {
	// 	console.info(e);
	// };
	//
	const callback = () => {
		dispatch(retrieveSingleGame({ gameId }));
	};

	const handleAddFile = () => {
		const formData = new FormData();
		formData.append('gameId', gameId);
		formData.append('videoId', state.videoId);
		formData.append('videoDescription', state.description);
		formData.append('description', state.description);
		if (state.image) {
			formData.append('imageUrl', state.image);
		}

		addGameFile({ data: formData, callback });
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Add File</Button>

			<Modal
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleAddFile}>
						<Button type="primary">Add File</Button>
					</Popconfirm>,
				]}
				open={visible}
				onCancel={() => setVisible(false)}
				title="Add Game File"
			>
				<div>
					<p>Description</p>
					<Input.TextArea
						value={state.description}
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
					/>
				</div>
				{fileType === 'video' && (
					<>
						<br />
						<div>
							<p>Video Id (YouTube video Id)</p>
							<Input.TextArea
								value={state.videoId}
								onChange={(e) =>
									setState({ ...state, videoId: e.target.value })
								}
							/>
						</div>
					</>
				)}
				<br />
				{fileType === 'image' && (
					<>
						<p>Choose File</p>
						<UpdloadDash uppy={uppy} height={150} />
					</>
				)}
			</Modal>
		</>
	);
};

export default AddGameFile;

/**
 * Game Logic: very important in creating game,
 * only one static game can exist in the system, and
 * the autoJoin must be TRUE.
 *
 * for dynamic game logic, autojoin could be true or not
 *
 * so if a static exists and the end date has not been reached, we cannot create another one.
 *
 * - add gameLogic filter in retrieving all games (and any other important one)
 *
 *
 * - set game father through internal settings
 *
 * - editing of some allowed fields in the game eg autoJoin (only if its a dynamic game logic), title, description etc
 *
 * - creating a game settings for just a particular game,
 *
 * - filter game list by gamelogic
 */
