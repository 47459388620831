import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const getSingleFixedSavings = createAsyncThunk(
	'single-fixed-savings/getSingleFixedSavings',
	async (fixedSavingsId: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get(
			'/admin/savings/stable-currency/fixed/single',
			{ params: { fixedSavingsId } }
		);
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

export const triggerFixedPlanPayout = createAsyncThunk(
	'single-fixed-savings/triggerFixedPlanPayout',
	async (fixedSavingsId: any) => {
		const res = await baseuri.post(
			'/admin/savings/stable-currency/fixed/payout',
			{ fixedSavingsId }
		);
		message.success('Payout has been triggered!');
		return res.data.data;
	}
);

const singleFixedSavings = createSlice({
	name: 'single-fixed-savings',
	initialState: {
		savingsProfile: {
			title: '',
			cacheCapital: 0,
			cacheDayOfYear: [],
			cacheProfit: 0,
			cacheProfitLastDate: null,
			cacheRewardTokenValue: 0,
			cacheRewardValue: 0,
			createdAt: '',
			isCollateral: false,
			currencyId: {
				_id: '',
				name: '',
				symbol: '',
				switch_wallet_id: '1',
			},
			durationDays: 0,
			endDate: '',
			endDateAdjusted: false,
			adjustedEndDate: '',
			lastUpdatedByUserId: '',
			payout: false,
			payoutInitiatorUserId: null,
			payoutStatus: 'pending',
			requestTermination: false,
			requestTerminationApprovalDate: null,
			requestTerminationDate: null,
			requestTerminationInitialDurationDays: 0,
			requestTerminationStatus: 'none',
			savingsForm: 'fixed',
			createdByAutoSave: false,
			startDate: '',
			locked: false,
			status: 'active',
			updatedAt: '',
			userId: {
				username: '',
				_id: '',
			},
			_id: '',
		},
		savingsItems: [],
		transactionStatements: [],
		ledgerBalance: 0,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder.addCase(getSingleFixedSavings.fulfilled, (store, action) => {
			let state = store;
			state = { ...state, ...action.payload };
			return state;
		}),
});

export default singleFixedSavings.reducer;
