import { Tag } from 'antd';
import styled from 'styled-components';
import { useAppSelector } from '../../methods/redux/hooks';

const StatusCountStyle = styled.div`
	& > * {
		padding: 7px 15px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
`;

const StakingsStatusCount = () => {
	const { stakingsCount } = useAppSelector((store) => store.staking);

	return (
		<StatusCountStyle>
			<Tag color="orange">
				<p>Pending</p>
				<p>{stakingsCount.pending}</p>
			</Tag>
			<Tag color="blue">
				<p>Staked</p>
				<p>{stakingsCount.staked}</p>
			</Tag>
			<Tag color="green">
				<p>Completed</p>
				<p>{stakingsCount.completed}</p>
			</Tag>
			<Tag color="gray">
				<p>Deleted</p>
				<p>{stakingsCount.deleted}</p>
			</Tag>
		</StatusCountStyle>
	);
};

export default StakingsStatusCount;
