import moment from 'moment';
import styled from 'styled-components';
import { defaultImageOne } from '../../methods/utils/general-utils';
import UpdateAdminTeamMember from './UpdateAdminTeamMember';
import ViewAdminTeamMembersPermissions from './ViewAdminTeamMembersPermissions';

const AdminUserItemStyle = styled.div`
	justify-content: space-between;
	align-items: flex-end;
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 20px;

	& .details {
		margin-top: 10px;
		border-top: 1px dashed #ccc;
		padding-top: 20px;

		& > * {
			display: inline-block;
			margin-right: 20px;
		}
	}

	& img {
		border-radius: 50%;
		width: 21px;
		height: 21px;
	}

	& .label {
		color: #bbb;
		font-size: 13px;
	}
`;

interface IAdminUserItem {
	item: any;
}
const AdminUserItem = ({ item }: IAdminUserItem) => (
	<AdminUserItemStyle>
		<div>
			<img alt="" src={item.profileUrl || defaultImageOne} />
			<p title={item.email}>
				<strong>{item.fullName}</strong> ({item.role?.title})
			</p>
			<p>{item.email}</p>
		</div>
		<div className="details">
			<div>
				<p>
					{moment(item.lastLogin).format('lll')} (
					{moment(item.lastLogin).fromNow()})
				</p>
				<p className="label">Last Login</p>
			</div>
			<div>
				<p>{moment(item.createdAt).format('lll')}</p>
				<p className="label">Date Added</p>
			</div>
			<ViewAdminTeamMembersPermissions member={item} />
			<UpdateAdminTeamMember member={item} />
		</div>
	</AdminUserItemStyle>
);

export default AdminUserItem;
