import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getExternalWithdrawalGasFeeReport } from '../../../methods/redux/reducers/reports';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import ExternalWithdrawalGasFeeItem from '../../fee-collection/blockchain-fees/ExternalWithdrawalGasFeeItem';
import AccountantsDateSelector from '../DateSelector';
import DownloadCSV from '../DownloadCSV';

const Style = styled(Card)`
	& .search-inputs {
		display: flex;
		align-items: flex-end;
	}

	& .summary {
		border: 1px solid #ddd;
		padding: 20px;

		& .details > div {
			display: inline-block;
			margin-right: 20px;

			& .value {
				font-weight: 600;
				font-size: 18px;

				&.green {
					color: var(--green-color);
				}

				&.red {
					color: var(--red-color);
				}
			}

			& .label {
				font-style: italic;
				font-size: 13px;
			}
		}
	}
`;

const AccountantExternalWithdrawals = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { externalWithdrawals } = useAppSelector(
		(store) => store.reports.blockchainGasFees
	);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(getExternalWithdrawalGasFeeReport({ ...queryData }));
		}
	}, [searchParams]);

	return (
		<Style>
			<h1>Withdrawal Via Crypto</h1>
			<p>Get report for withdrawals via crypto on Xend Finance</p>

			<Divider />

			<AccountantsDateSelector onGetReport={updateQueryString} />

			<br />

			{externalWithdrawals.aggregate.map((network: any) => (
				<div key={network.blockchainNetwork?._id} className="summary">
					<div>
						<p>{network.blockchainNetwork?.name}</p>
					</div>
					<div className="details">
						<div>
							<p className="value">
								<NativeNumber
									value={network.totalGasFeeInUsd}
									decimalPlaces={4}
								/>{' '}
								USD
							</p>
							<p className="label">Total Amount Withdrawn</p>
						</div>
						<div>
							<p className="value">
								<NativeNumber
									value={network.totalGasFeeInNativeToken}
									decimalPlaces={8}
								/>{' '}
								{network.blockchainNetwork?.nativeTokenSymbol} /{' '}
								<NativeNumber
									value={network.totalGasFeeInUsd}
									decimalPlaces={4}
								/>{' '}
								USD
							</p>
							<p className="label">Gas Fee Spent on BSC</p>
						</div>
						<div>
							<p className="value">
								<NativeNumber
									value={network.totalTransactionFee || 0}
									decimalPlaces={4}
								/>{' '}
								USD
							</p>
							<p className="label">Transaction Fees Collected</p>
						</div>
						<div>
							<p className="value">
								<NativeNumber
									value={
										Number(network.totalTransactionFee) -
										Number(network.totalGasFeeInUsd)
									}
									decimalPlaces={4}
								/>{' '}
								USD
							</p>
							<p className="label">Profit on Gas Fees</p>
						</div>
					</div>
				</div>
			))}

			{externalWithdrawals.record.itemsList.length > 0 && (
				<>
					<Divider />

					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={externalWithdrawals.record.paginator.itemCount}
						current={externalWithdrawals.record.paginator.currentPage}
						pageSize={externalWithdrawals.record.paginator.perPage}
					/>

					<br />

					<div>
						<DownloadCSV
							title="external-withdrawals"
							csv={externalWithdrawals.record?.reportCSVFile}
						/>
					</div>

					<br />

					{externalWithdrawals.record.itemsList.map((item: any) => (
						<ExternalWithdrawalGasFeeItem key={item._id} data={item} />
					))}

					<Divider />

					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={externalWithdrawals.record.paginator.itemCount}
						current={externalWithdrawals.record.paginator.currentPage}
						pageSize={externalWithdrawals.record.paginator.perPage}
					/>
				</>
			)}
		</Style>
	);
};

export default AccountantExternalWithdrawals;
