import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getFlexibleSavingsDailyEarnings } from '../../methods/redux/reducers/flexible-savings';
import EarningItem from './EarningItem';
import remakeQueryString from '../../methods/utils/remake-query-string';

const Style = styled.div``;

const DailyEarnings = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { savingsDailyEarns } = useAppSelector(
		(store) => store.flexibleSavings
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: String(savingsDailyEarns.paginator.perPage),
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(getFlexibleSavingsDailyEarnings(queryData));
		}
	}, [searchParams]);

	return (
		<Style>
			<Card>
				<h1>Daily Interest Payout</h1>
				<p>Daily earnings for all flexible savings profiles on Xend Finance.</p>

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={savingsDailyEarns.paginator.itemCount}
					current={savingsDailyEarns.paginator.currentPage}
					pageSize={savingsDailyEarns.paginator.perPage}
				/>

				<br />

				<p>
					Total of{' '}
					<strong>{savingsDailyEarns.paginator.itemCount} items</strong> found.
				</p>

				<br />

				{savingsDailyEarns.itemsList.map((item: any) => (
					<EarningItem key={item._id} item={item} />
				))}

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={savingsDailyEarns.paginator.itemCount}
					current={savingsDailyEarns.paginator.currentPage}
					pageSize={savingsDailyEarns.paginator.perPage}
				/>
			</Card>
		</Style>
	);
};

export default DailyEarnings;
