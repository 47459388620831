import { Button, Popconfirm } from 'antd';
import { useAppDispatch } from '../../methods/redux/hooks';
import { removeTeamMember } from '../../methods/redux/reducers/roles';

interface IRemoveTeamMember {
	userId: any;
	close: any;
}

const RemoveAdminTeamMember = ({ userId, close }: IRemoveTeamMember) => {
	const dispatch = useAppDispatch();

	const submit = () => {
		dispatch(removeTeamMember(userId));
		close();
	};

	return (
		<Popconfirm title="Are you sure?" onConfirm={() => submit()}>
			<Button block danger>
				Remove
			</Button>
		</Popconfirm>
	);
};

export default RemoveAdminTeamMember;
