import { Card, Divider, Pagination } from 'antd';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveSwapRequest,
	retrieveSwapRequestCount,
} from '../../methods/redux/reducers/swap';
import { getBlockchainNetworks } from '../../methods/redux/reducers/currency';
import SwapItem from './SwapItem';
import SearchFilter from '../../component/Filter';
import SwapStatusCount from './SwapCountByStatus';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';

const Swap = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const { userId } = useParams();

	const { user } = useAppSelector((store) => store.userProfile);
	const { swapRequests, swapRequestPaginator } = useAppSelector(
		(store) => store.swap
	);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				swapStatus: 'pending',
				pageId: '1',
				perPage: '20',
			});
		}
		if (userId) {
			dispatch(retrieveSwapRequestCount({ userId }));
		} else {
			dispatch(retrieveSwapRequestCount({}));
		}

		dispatch(getBlockchainNetworks());
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(retrieveSwapRequest({ ...queryData, userId }));
			} else {
				dispatch(retrieveSwapRequest(queryData));
			}
		}
	}, [searchParams]);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	return (
		<div>
			<Card>
				<h1>
					Currency Swap{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile?._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>
				<p>
					Swap transactions can take place within the application, on the
					blockchain, and by a swap robot running at a different location.
				</p>

				<br />

				<div>
					<Link to="/settings/swap">Settings</Link>
				</div>

				<br />

				<SwapStatusCount />

				<br />

				<SearchFilter
					title="Filter Swap Records"
					searchFilters={[
						{
							label: 'Status',
							name: 'swapStatus',
							htmlElement: 'select',
							defaultSelectedValue: 'pending',
							selectOptions: [
								{ value: 'pending', name: 'Pending' },
								{ value: 'hold', name: 'Hold' },
								{ value: 'processing', name: 'Processing' },
								{ value: 'completed', name: 'Completed' },
								{ value: 'cancelled', name: 'Cancelled' },
							],
						},
						{
							label: 'Start Date',
							name: 'startDate',
							htmlElement: 'datepicker',
						},
						{
							label: 'End Date',
							name: 'endDate',
							htmlElement: 'datepicker',
						},
					]}
					retrieveQueryString={updateQueryString}
				/>
				<Divider />
				<p>
					Total: <strong>{swapRequests.length}</strong> items
				</p>
				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					current={swapRequestPaginator.currentPage}
					total={swapRequestPaginator.itemCount}
					pageSize={swapRequestPaginator.perPage}
				/>

				<br />

				{swapRequests.map((item) => (
					<SwapItem swapItem={item} />
				))}
				<Divider />
				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					current={swapRequestPaginator.currentPage}
					pageSize={swapRequestPaginator.perPage}
					total={swapRequestPaginator.itemCount}
				/>
			</Card>
		</div>
	);
};

export default Swap;
