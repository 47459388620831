import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../methods/redux/hooks';
import { resetUserBalance } from '../../methods/redux/reducers/compliance';

interface IResetUserNegativeBalance {
	currencyId: any;
}

const ResetUserNegativeWalletBalance = ({
	currencyId,
}: IResetUserNegativeBalance) => {
	const dispatch = useAppDispatch();
	const { id } = useParams();

	const [visible, setVisible] = useState(false);

	const handleReset = () => {
		dispatch(resetUserBalance({ userId: id, currencyId }));
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Reset</Button>

			<Modal
				onOk={handleReset}
				open={visible}
				onCancel={() => setVisible(false)}
				title="Reset Negative Balance"
				okText="Reset"
			>
				<p>
					Proceeding with this action will set this customer&apos;s wallet
					balances that are negative to zero (0)
				</p>
				<p>Please confirm you know what you&apos;re doing to continue.</p>
			</Modal>
		</>
	);
};

export default ResetUserNegativeWalletBalance;
