import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
export const MyButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	/* padding: 8px 12px 8px 5px; */
	border-radius: 25px;
	transition: background 400ms ease;
	margin-top: 10px;

	&:hover {
		background: #e2e2e2;
	}
`;
const MyModal = styled(Modal)`
	border-radius: 10px;
	overflow: auto;
`;

interface ICreateAdvertBanner {
	setRefresh: Function;
}
const AddSpamDomains: FC<ICreateAdvertBanner> = ({ setRefresh }) => {
	// const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		urlName: '',
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(`admin/spam-temporary-domains`, {
				...data,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('News Created Successfully');
				setRefresh(Math.random());
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	return (
		<div>
			<MyButton type="button" onClick={() => setVisible(true)}>
				<span className="material-icons">add</span>
				<span>Add New Spam Email Domain</span>
			</MyButton>
			<MyModal
				title="Add a suspected email domain"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							create
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Domain URL :{' '}
						<Input
							placeholder="banner url"
							value={data?.urlName}
							onChange={(e: any) =>
								setData({ ...data, urlName: e.target.value })
							}
						/>
					</p>
				</ModalContent>
			</MyModal>
		</div>
	);
};

export default AddSpamDomains;
