import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commas, defaultImageOne } from '../../methods/utils/general-utils';

interface IColumnItems {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const ColumnStaking = ({ data }: IColumnItems) => (
	<Style>
		<div>
			<p style={{ textTransform: 'capitalize' }}>{data.status}</p>
			<p className="label">Status</p>
		</div>
		<div>
			<p style={{ textTransform: 'capitalize' }}>
				{data.migrateStatus ? 'Migrated' : 'Not Migrated'}
			</p>
			<p className="label">Migration Status</p>
		</div>
		<div>
			<Link title="View Profile" to={`/user-profile/${data.userId?._id}`}>
				@{data.userId?.username}
			</Link>
			<p className="label">Customer</p>
		</div>
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img
					alt=""
					style={{ marginRight: 5 }}
					width={18}
					height={18}
					src={data.currencyId?.iconUrl || defaultImageOne}
				/>
				<p>
					{data.amount ? commas(data.amount) : '--'} {data.currencyId?.symbol}
				</p>
			</div>
			<p className="label">Amount</p>
		</div>
		<div>
			<p>
				{data.stakingProfileId?.name} ({data.apr}%)
			</p>
			<p className="label">Staking Profile</p>
		</div>
		<div>
			<p>{data.periodInDays} days</p>
			<p className="label">Period in days</p>
		</div>
		<div>
			<p>{moment(data.createdAt).format('lll')}</p>
			<p className="label">Created</p>
		</div>
	</Style>
);

export default ColumnStaking;
