import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { retrieveInternalSettings } from '../../methods/redux/reducers/internal-settings';
import NewDeviceLoginWithdrawal from './NewDeviceLoginWithdrawal';
import SavingsSettings from './Savings';

const InternalSettings = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(retrieveInternalSettings());
	}, []);

	return (
		<Card>
			<h1>All Internal Settings</h1>

			<Divider />
			<SavingsSettings />
			<NewDeviceLoginWithdrawal />
		</Card>
	);
};

export default InternalSettings;
