import { Card, Empty, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';
import CreateGreeting from './CreateGreeting';
import DeleteGreeting from './DeleteGreeting';
import EditGreeting from './EditGreeting';

const AppGreetingsSection = styled(Card)`
	border-radius: 10px;
	overflow-y: auto;
`;

export const LastSection = styled.div`
	display: flex;
	gap: 2em;
`;
const InAPPNotifications = () => {
	const [refresh, setRefresh] = useState('');
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const getQueryParams = () => ({
		perPage: `${searchParams.get('perPage') || '10'}`,
		pageId: `${searchParams.get('pageId') || '1'}`,
		sort: `${searchParams.get('sort') || 'desc'}`,
	});

	const columns = [
		{
			title: 'Title',
			key: 'title',
			render: (text: any) => <strong>{text?.title}</strong>,
		},
		{
			title: 'Description',
			key: 'description',
			render: (text: any) => <span>{text?.description}</span>,
		},
		{
			title: 'Status',
			key: 'status',
			render: (text: any) => <strong>{text?.status}</strong>,
		},
		{
			title: 'Duration',
			key: 'timeFrame',
			render: (text: any) => <strong>{text?.timeFrame}</strong>,
		},

		{
			title: 'Expiry time',
			dataIndex: 'expiry_time',
			key: 'expiry_time',
			render: (text: any) => <span>{text && moment(text).format('lll')}</span>,
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => <span>{text && moment(text).format('lll')}</span>,
		},

		{
			title: '',
			key: 'subject',
			render: (text: any) => (
				<LastSection>
					<EditGreeting greetingDetails={text} setRefresh={setRefresh} />
					<DeleteGreeting greetingID={text?._id} setRefresh={setRefresh} />
				</LastSection>
			),
		},
		// {
		// 	title: '',
		// 	key: '345334',
		// 	render: (text: any) => (
		// 		<LastSection style={{ width: '100px' }}>
		// 			{text?.deliveryStatus === 'pending' ||
		// 			text?.deliveryStatus === 'paused'
		// 				? text?.isPublished === true && (
		// 						<UpdateNewsLetterStatus
		// 							newsletter={text}
		// 							text={text?.deliveryStatus === 'paused' ? 'Resume' : 'Pause'}
		// 						/>
		// 				  )
		// 				: ''}
		// 			{text?.deliveryStatus === 'pending' &&
		// 				text?.isPublished === false && <SendNewsLetter newsletter={text} />}
		// 			{text?.deliveryStatus === 'saved' && (
		// 				<>
		// 					{' '}
		// 					<SendTestNewsLetter newsletter={text} />
		// 					<EditNewsletter newsLetter={text} />{' '}
		// 				</>
		// 			)}
		// 		</LastSection>
		// 	),
		// },
	];

	const getData = async () => {
		const params1 = getQueryParams();

		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		// if (!location.search) return;
		try {
			const res: any = await baseuri.get('admin/in-app/alert', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!location.search) {
			const params1 = getQueryParams();
			setSearchParams({ ...params1 });
		} else {
			getData();
		}
	}, [location.search, refresh]);
	return (
		<AppGreetingsSection>
			<CreateGreeting setRefresh={setRefresh} />
			<MyTableCard>
				<TableHeaderSection
					tableName="In App Alert"
					currentPage={data?.paginator?.currentPage || 0}
					totalRecords={data?.paginator?.itemCount || 0}
				/>

				<MyTable
					locale={{
						emptyText: (
							<Empty
								image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
								// imageStyle={{
								// 	height: 60,
								// }}
								description="No data to display"
							/>
						),
					}}
					loading={loading}
					columns={columns}
					dataSource={data.itemsList}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(page, pageSize) => {
						const params1 = Object.fromEntries(searchParams.entries());
						setSearchParams({
							...params1,
							pageId: `${page}`,
							perPage: `${pageSize}`,
						});
					}}
					disabled={loading}
					pageSize={data?.paginator?.perPage || 0}
					current={data?.paginator?.currentPage || 0}
					total={data?.paginator?.itemCount || 0}
					showSizeChanger
					size="small"
					showQuickJumper
				/>
			</MyTableCard>
		</AppGreetingsSection>
	);
};

export default InAPPNotifications;
