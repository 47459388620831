import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { commas } from '../../methods/utils/general-utils';

interface ISwapItemStyle {
	statusColor: string;
}
const LoanItemStyle = styled.div<ISwapItemStyle>`
	display: block;
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 10px;
	background-color: #f8f8f8;
	color: #333;
	text-decoration: none;

	&:hover {
		color: #333;
	}

	& .info {
		display: flex;

		& .status {
			display: flex;
			font-size: 12px;

			& > div:first-child {
				display: flex;
				align-items: center;
			}

			& .material-icons {
				font-size: 10px;
				color: ${(p) => p.statusColor};
				margin-right: 5px;
			}
		}

		& p {
			margin: 0;
			padding: 0;
		}
	}

	& .swap {
		display: flex;
		align-items: center;
		margin-top: 10px;

		& img {
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 5px;
		}

		& .base {
			display: flex;
			align-items: center;
		}

		& p {
			font-weight: bold;
			font-size: 18px;
		}
	}

	& .title {
		font-size: 16px;
		font-weight: 600;
	}

	& .details {
		margin-top: 15px;
		border-top: 1px dashed #ccc;
		padding-top: 10px;

		& .user {
			& img {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				margin-right: 5px;
			}
		}

		& > * {
			display: inline-block;
			min-width: 100px;
			margin-right: 15px;
			margin-bottom: 5px;

			& p {
				font-size: 14px;
				margin: 0;
				padding: 0;

				&.label {
					color: gray;
					font-size: 14px;
					font-style: italic;
				}
			}
		}
	}
`;

interface ILoanColumnItem {
	loanItem: any;
}

const LoanItem = ({ loanItem }: ILoanColumnItem) => {
	const swapStatusColor = (status: any) => {
		switch (status) {
			case 'processing':
				return 'orange';
			case 'completed':
				return 'green';
			default:
				return '#f3f3f3';
		}
	};

	return (
		<LoanItemStyle statusColor={swapStatusColor(loanItem.swapStatus)}>
			<div>
				<Link
					to={`/core-modules/loan/item/${loanItem._id}`}
					className="title"
					title="Manage Loan"
				>
					{loanItem.title || '--'}
				</Link>
			</div>
			<div className="details">
				<div className="user" title={loanItem.userId?.fullName}>
					<Link to={`/user-profile/${loanItem.userId?._id}`}>
						@{loanItem.userId?.username}
					</Link>
					<p className="label">Customer</p>
				</div>
				<div>
					<p>{loanItem.status || '--'}</p>
					<p className="label">Status</p>
				</div>
				<div>
					<p>
						{commas(loanItem.loanAmount)} {loanItem.currencyId?.symbol}{' '}
					</p>
					<p className="label">Loan Amount</p>
				</div>

				<div>
					<p>{loanItem.durationDays || '--'} Days</p>
					<p className="label">Duration</p>
				</div>
				<div>
					<p>{loanItem.interest || '0'} %</p>
					<p className="label">Interest</p>
				</div>
				<div>
					<p>
						{commas(loanItem.interestAmount)} {loanItem.currencyId?.symbol}{' '}
					</p>
					<p className="label">Interest Amount</p>
				</div>
				<div>
					<p>
						{commas(loanItem.totalLoanPaid)} {loanItem.currencyId?.symbol}{' '}
					</p>
					<p className="label">Total Loan Paid</p>
				</div>
				<div>
					<p>
						{commas(loanItem.totalLoanRemaining)} {loanItem.currencyId?.symbol}{' '}
					</p>
					<p className="label">Total Loan Remaining</p>
				</div>
				<div>
					<p>{moment(loanItem.createdAt).format('lll')}</p>
					<p className="label">Created</p>
				</div>

				{loanItem.loanApprovalDate && (
					<div>
						<p>{moment(loanItem.loanApprovalDate).format('lll')}</p>
						<p className="label">Loan Approval date</p>
					</div>
				)}
				{loanItem.expectedPaybackDate && (
					<div>
						<p>{moment(loanItem.expectedPaybackDate).format('lll')}</p>
						<p className="label">Expected Payback date</p>
					</div>
				)}
				{loanItem.LoanPaybackDate && (
					<div>
						<p>{moment(loanItem.LoanPaybackDate).format('lll')}</p>
						<p className="label">Expected Payback date</p>
					</div>
				)}
				<div>
					<p>{moment(loanItem.updatedAt).format('lll')}</p>
					<p className="label">Updated at</p>
				</div>
				<div>
					<p>
						@
						{loanItem.lastUpdatedByUserId?.username ||
							loanItem.lastUpdatedByUserId?.email ||
							'--'}
					</p>
					<p className="label">Last Updated By</p>
				</div>
			</div>
		</LoanItemStyle>
	);
};

export default LoanItem;
