import Cleave from 'cleave.js';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';

const NumeralStyle = styled.span`
	& input {
		display: none;
	}
`;

interface INumeral {
	value: any;
	decimalPlaces?: number;
}

const NativeNumber = ({ value, decimalPlaces }: INumeral) => {
	const inputRef = useRef(null);

	const [result, setResult] = useState('0');

	useEffect(() => {
		if (inputRef.current) {
			const cleaveObject = new Cleave(inputRef.current, {
				numeral: true,
				numeralDecimalScale: decimalPlaces,
				numeralThousandsGroupStyle: 'thousand',
			});

			if (cleaveObject) {
				setResult(cleaveObject.properties.result);
			}
		}
	}, [value, decimalPlaces]);

	return (
		<NumeralStyle>
			<input ref={inputRef} value={value} readOnly />
			<span>{result}</span>
		</NumeralStyle>
	);
};

NativeNumber.defaultProps = {
	decimalPlaces: 18,
};

export default NativeNumber;
