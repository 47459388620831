import { Card, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import baseuri from '../../methods/baseuri';
import { MyEmptyTable } from '../../robots/email-robot/EmailRobot';
import EmailBounceDelete from '../../user-settings/user-general-settings/EmailBounceDelete';
import { MyTableCard } from '../../users/users-list/UsersByCountry';
import { MyTable } from '../../users/users-list/UsersList';

export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;
const EmailBounceMaintenance = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const columns = [
		{
			key: 1,
			title: 'User',
			render: (text: any) => (
				<>
					<p>
						<Link title="View Profile" to={`/user-profile/${text?._id}`}>
							{text?.fullName}
						</Link>
					</p>
					<p>
						<Link title="View Profile" to={`/user-profile/${text?._id}`}>
							@{text?.email}
						</Link>
					</p>
				</>
			),
		},
		{
			title: 'SignUp Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).subtract(1, 'hour').format('lll'),
			key: 'createdAt',
		},

		{
			title: '',
			render: (text: any) => <EmailBounceDelete details={text} />,
			key: '453543753',
		},
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getQueryParams = () => {
		const params1 = Object.fromEntries(searchParams.entries());
		return {
			...params1,
			startDate: `${
				searchParams.get('startDate') ||
				moment('2021/10/01').format('YYYY-MM-DD')
			}`,
			endDate: `${
				searchParams.get('endDate') || moment().format('YYYY-MM-DD')
			}`,
			perPage: `${searchParams.get('perPage') || '10'}`,
			pageId: `${searchParams.get('pageId') || '1'}`,
			sort: `${searchParams.get('sort') || 'desc'}`,
		};
	};

	const handleSearchByDate = async () => {
		const params1 = getQueryParams();
		// setSearchParams({ ...params1 });
		setLoading(true);
		setData({
			itemsList: [],
			paginator: {} as any,
		});
		try {
			const res: any = await baseuri.get('admin/users/email-bounce-users', {
				params: {
					...params1,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSearchByDate();
	}, [location.search]);
	return (
		<UsersListPage>
			<MainSection>
				{/* <MyDateSelector /> */}
				<MyTableCard>
					<TableHeaderSection
						tableName="Email Bounce users"
						currentPage={data?.paginator?.currentPage || 0}
						totalRecords={data?.paginator?.itemCount || 0}
						// downloadURL="https://apiprostaging.xend.finance/api/admin/search-user-date"
					/>
					<MyTable
						locale={{
							emptyText: <MyEmptyTable />,
						}}
						loading={loading}
						columns={columns}
						dataSource={data.itemsList}
						pagination={false}
					/>

					<Pagination
						onChange={(page, pageSize) => {
							const params1 = Object.fromEntries(searchParams.entries());
							setSearchParams({
								...params1,
								pageId: `${page}`,
								perPage: `${pageSize}`,
							});
						}}
						disabled={loading}
						pageSize={data?.paginator?.perPage || 0}
						current={data?.paginator?.currentPage || 0}
						total={data?.paginator?.itemCount || 0}
						showSizeChanger
						size="small"
						showQuickJumper
					/>
				</MyTableCard>
			</MainSection>
		</UsersListPage>
	);
};

export default EmailBounceMaintenance;
