import { useEffect, useState } from 'react';
import { Switch, Modal, Input, Button, Select } from 'antd';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const Style = styled.section`
	& .bonus-payer {
		display: flex;
		align-items: center;
	}

	& .currency {
		display: flex;
		margin: 10px 0;

		& img {
			width: 21px;
			height: 21px;
			margin-right: 5px;
		}
	}
`;

const FixedSavingsRewardSettings = () => {
	const dispatch = useAppDispatch();

	const settings: any = useAppSelector((store) => store.internalSettings);
	const { currencies } = useAppSelector((store) => store.currencies);

	const [email, setEmail] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [currencyId, setCurrencyId] = useState('');
	const [bonusPayerUser, setBonusPayerUser] = useState('');

	const updateSettings = async () => {
		setLoading(true);

		let rewardUserId = bonusPayerUser;

		if (
			email &&
			email.length > 0 &&
			email !== settings.fixedSavingsRewardBonusPayerUserId?.email
		) {
			const res = await baseuri.get('/admin/users/get-user', {
				params: { email },
			});
			rewardUserId = res.data.data?._id;
		}

		dispatch(
			updateInternalSettings({
				fixedSavingsRewardBonusPayerUserId: rewardUserId,
				fixedSavingsRewardCryptoTokenId: currencyId,
			})
		);

		setLoading(false);
		setVisible(false);
	};

	useEffect(() => {
		setCurrencyId(settings.fixedSavingsRewardCryptoTokenId?._id);
		setBonusPayerUser(settings.fixedSavingsRewardBonusPayerUserId?._id);
	}, [settings]);

	const toggleAllow = (value: any) => {
		dispatch(updateInternalSettings({ payFixedSavingsRewardBonus: value }));
	};

	return (
		<Style>
			<p>
				<strong>Fixed Savings Reward</strong>
			</p>
			<div>
				<p>Toggle allowing paying of savings bonus for fixed savings</p>
				<Switch
					size="default"
					onChange={toggleAllow}
					checked={settings.payFixedSavingsRewardBonus}
				/>
			</div>

			<section className="lined-settings">
				<div>
					<div className="bonus-payer">
						<span className="material-icons">account_circle</span>
						<div>
							<p>@{settings?.fixedSavingsRewardBonusPayerUserId?.username}</p>
							<p>{settings?.fixedSavingsRewardBonusPayerUserId?.email}</p>
						</div>
					</div>
				</div>
				<div>
					<div className="currency">
						<img
							alt=""
							src={settings?.fixedSavingsRewardCryptoTokenId?.iconUrl}
						/>
						<div>
							<p>{settings?.fixedSavingsRewardCryptoTokenId?.symbol}</p>
						</div>
					</div>
				</div>
			</section>

			<div>
				<Button size="small" onClick={() => setVisible(true)}>
					Update
				</Button>
			</div>

			<Modal
				width={320}
				title="Change Bonus Payer"
				visible={visible}
				onCancel={() => setVisible(false)}
				onOk={updateSettings}
				footer={null}
			>
				<p>
					Change the fixed savings bonus payer. Type in the email address of the
					new reward payer
				</p>

				<br />
				<div>
					<p>Email Address</p>
					<Input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<br />
				<div>
					<p>Currency</p>
					<Select
						style={{ width: '100%' }}
						onChange={(value) => setCurrencyId(value)}
					>
						{currencies.map((currency: any) => (
							<Select.Option value={currency._id} key={currency._id}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<Button
						block
						type="primary"
						onClick={updateSettings}
						loading={loading}
					>
						Confirm & Update
					</Button>
				</div>
			</Modal>
		</Style>
	);
};

export default FixedSavingsRewardSettings;
