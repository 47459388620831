import { Card, Divider, Pagination } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../../component/Numeral';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	retrieveGameActionActivities,
	retrieveSingleGame,
} from '../../../methods/redux/reducers/games';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import GameActionActivitiesItem from '../tasks/GameActionActivitiesItem';

const Style = styled(Card)``;

const GameActionActivities = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { gameId } = useParams();

	const { gameActionActivities } = useAppSelector((store) => store.games);
	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};
	useEffect(() => {
		dispatch(retrieveGameActionActivities({ gameId }));
		dispatch(retrieveSingleGame({ gameId }));
	}, [gameId]);

	console.info('adsd');
	return (
		<Style>
			<h1>Game Action Activities </h1>
			<Divider />
			<br />
			<p>
				Total of{' '}
				<strong>
					<NativeNumber value={gameActionActivities.paginator.itemCount} />{' '}
					items
				</strong>{' '}
				found
			</p>
			<br />
			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={gameActionActivities.paginator.itemCount}
				current={gameActionActivities.paginator.currentPage}
				pageSize={gameActionActivities.paginator.perPage}
			/>

			<br />
			{gameActionActivities.itemsList.map((actionActivity: any) => (
				<GameActionActivitiesItem
					activitiesAction={actionActivity}
					key={actionActivity._id}
				/>
			))}

			<Divider />

			<Pagination
				onChange={(pageId) => updateQueryString({ pageId })}
				total={gameActionActivities.paginator.itemCount}
				current={gameActionActivities.paginator.currentPage}
				pageSize={gameActionActivities.paginator.perPage}
			/>
		</Style>
	);
};

export default GameActionActivities;
