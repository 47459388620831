import styled from 'styled-components';
import GlobalSearchBar from '../general-search/GlobalSearchBar';
import { chooseEnvironment } from '../methods/baseuri';

const BackendEnvironmentStyle = styled.button`
	display: none;
	cursor: pointer;
	border: none;
	background: transparent;
	padding: 10px;
	border-radius: 25px;
	transition: background 360ms ease;

	&:hover {
		background: #f2f2f2;
	}

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		font-size: 15px;

		& span {
			font-size: 15px;
			opacity: 0.6;

			&.arrow {
				margin-top: 5%;
			}
		}

		& p {
			margin: auto 7px;
		}
	}
`;

const Style = styled.div`
	display: none;
	@media (min-width: 768px) {
		display: flex;
		align-items: flex-end;
	}
`;

const BackendEnvironment = () => (
	<Style>
		<BackendEnvironmentStyle>
			<span className="material-icons">lock</span>
			<p>{chooseEnvironment().name}</p>
			<span className="material-icons arrow">keyboard_arrow_down</span>
		</BackendEnvironmentStyle>
		<GlobalSearchBar />
	</Style>
);

export default BackendEnvironment;
