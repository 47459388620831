import { Card, Divider, Button, Tabs } from 'antd';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	lockFixedForLoan,
	retrieveLockableSavings,
	retrieveSingleLoanRequest,
	validateLoanData,
} from '../../methods/redux/reducers/loan';
import { retrieveToken } from '../../methods/utils/auth';
import { commas, defaultImageOne } from '../../methods/utils/general-utils';
import LockableFixedItem from './LockableFixedItem';
import LockedFixedLoan from './LockedFixedLoan';
import DisburseLoan from './DisburseLoan';
import StartLoan from './StartLoan';
import RejectLoan from './RejectLoan';
import TransactionItem from '../../transactions/transaction-statments/TransactionItem';
import NativeNumber from '../../component/Numeral';
import LiquidateLoan from './LiquidateLoan';
import LiquidateDefaultedLoan from './LiquidateDefaultedLoan';

interface IStyle {
	statusColor: any;
}
const SingleLoanItemPageStyle = styled.div<IStyle>`
	& .indication {
		height: 5px;
		background-color: ${(p) => p.statusColor};
		margin-bottom: 20px;
	}

	& .details {
		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-bottom: 15px;
		}
	}

	& .label {
		color: #aaa;
		font-style: italic;
		font-size: 14px;
	}

	& .actions {
		margin-top: 10px;
		& > * {
			display: inline-block;
			margin-right: 20px;
		}
	}
`;

const SingleLoanItemPage = () => {
	const dispatch = useAppDispatch();

	const { loanItemId } = useParams();

	const { singleLoanRequest, lockableFixedSavings, validateInfo } =
		useAppSelector((store) => store.loan);

	const [state, setState] = useState({
		_id: '',
	});

	const [selectedSavingsToLock, setSelectedSavingsToLock] = useState([] as any);
	const [checkTotalCollateral, setCheckTotalCollateral] = useState(0);
	const onChange = (x: any) => {
		if (x.e.target.checked) {
			setSelectedSavingsToLock((prev: any) => [...prev, x.details?._id]);
			setCheckTotalCollateral(
				(prev: any) => prev + Number(x.details.cacheCapital)
			);
		} else {
			setSelectedSavingsToLock((prev: any) =>
				prev.filter((a: any) => a !== x.details?._id)
			);
			setCheckTotalCollateral(
				(prev: any) => prev - Number(x.details.cacheCapital)
			);
		}
	};

	const lockSelectedSavings = () => {
		dispatch(
			lockFixedForLoan({
				loanId: loanItemId,
				fixedSavings: selectedSavingsToLock,
			})
		);
	};

	useEffect(() => {
		const auth = retrieveToken();
		setState({ ...state, ...auth.user });
	}, []);

	useEffect(() => {
		if (loanItemId) {
			dispatch(retrieveSingleLoanRequest({ loanId: loanItemId }));
		}
	}, [loanItemId]);

	useEffect(() => {
		if (loanItemId && singleLoanRequest?.loanData?.userId?._id) {
			dispatch(
				retrieveLockableSavings({
					loanId: loanItemId,
					userId: singleLoanRequest?.loanData?.userId?._id,
				})
			);

			dispatch(
				validateLoanData({
					title: singleLoanRequest.loanData.title,
					currencyId: singleLoanRequest?.loanData?.currencyId?._id,
					loanAmount: singleLoanRequest.loanData.loanAmount,
					durationDays: singleLoanRequest.loanData.durationDays,
					userId: singleLoanRequest.loanData.userId?._id,
				})
			);
		}
	}, [loanItemId, singleLoanRequest?.loanData?.userId?._id]);

	const loanStatusColor = (status: any) => {
		switch (status) {
			case 'pending':
				return 'orange';
			case 'completed':
				return 'green';
			case 'hold':
				return 'maroon';
			default:
				return '#f3f3f3';
		}
	};

	return (
		<SingleLoanItemPageStyle
			statusColor={loanStatusColor(singleLoanRequest?.loanData?.status)}
		>
			<Card>
				<div className="indication" />
				<h1>
					{singleLoanRequest?.loanData?.currencyId.symbol} Loan for{' '}
					<Link
						to={`/user-profile/${singleLoanRequest?.loanData?.userId?._id}`}
					>
						@{singleLoanRequest?.loanData?.userId?.username}
					</Link>
				</h1>
				<strong>({singleLoanRequest?.loanData?.status})</strong>

				<Divider />

				<div>
					<LiquidateLoan loanItem={singleLoanRequest.loanData} />
					<LiquidateDefaultedLoan loanItem={singleLoanRequest.loanData} />
				</div>

				<Divider />

				<div className="details">
					{singleLoanRequest?.loanData?.title && (
						<div>
							<strong>{singleLoanRequest?.loanData?.title}</strong>
							<p className="label">Title</p>
						</div>
					)}
					<div>
						<div style={{ display: 'flex' }}>
							<img
								width={20}
								height={20}
								style={{ marginRight: 5 }}
								src={
									singleLoanRequest?.loanData?.currencyId?.iconUrl ||
									defaultImageOne
								}
								alt=""
							/>
							<p>
								{singleLoanRequest?.loanData?.loanAmount}{' '}
								{singleLoanRequest?.loanData?.currencyId.symbol}
							</p>
						</div>
						<p className="label">Loan Amount</p>
					</div>
					<div>
						<p>{singleLoanRequest?.loanData?.durationDays} Days</p>
						<p className="label">Duration</p>
					</div>
					<div>
						<p>{singleLoanRequest?.loanData?.durationInterest || '0'} %</p>
						<p className="label">Duration Interest</p>
					</div>
					<div>
						<p>{singleLoanRequest?.loanData?.interest || '0'} %</p>
						<p className="label">Interest</p>
					</div>
					<div>
						<p>
							{commas(singleLoanRequest?.loanData?.interestAmount || '0')}
							{singleLoanRequest?.loanData?.currencyId.symbol}
						</p>
						<p className="label">Interest Amount</p>
					</div>
					<div>
						{singleLoanRequest?.loanData?.lastUpdatedByUserId ? (
							<Link
								to={`/user-profile/${singleLoanRequest?.loanData?.lastUpdatedByUserId}`}
							>
								@{singleLoanRequest?.loanData?.lastUpdatedByUserId}
							</Link>
						) : (
							'--'
						)}
						<p className="label">Last Updated By</p>
					</div>
					<div>
						{singleLoanRequest.loanData?.loanApprovalDate ? (
							<p>
								{moment(singleLoanRequest?.loanData?.loanApprovalDate).format(
									'MMM DD, YY / hh:mm:ss a'
								)}
							</p>
						) : (
							'--'
						)}
						<p className="label">Loan Approval Date</p>
					</div>
					<div>
						<p>
							{moment(singleLoanRequest?.loanData?.createdAt).format(
								'MMM DD, YY / hh:mm:ss a'
							)}
						</p>
						<p className="label">Created At</p>
					</div>
					<div>
						<p>
							{moment(singleLoanRequest?.loanData?.updatedAt).format(
								'MMM DD, YY / hh:mm:ss a'
							)}
						</p>
						<p className="label">Updated At</p>
					</div>
					{loanItemId && singleLoanRequest.loanData?.status === 'pending' && (
						<div>
							<StartLoan id={loanItemId} />
						</div>
					)}
					{loanItemId && singleLoanRequest.loanData?.status === 'pending' && (
						<div>
							<RejectLoan id={loanItemId} />
						</div>
					)}
					{loanItemId && singleLoanRequest.loanData?.status === 'processing' && (
						<div>
							<DisburseLoan id={loanItemId} />
						</div>
					)}
				</div>
				<Divider />
				<strong>Loan Repayment Details</strong>
				<div className="details">
					<div>
						<p>
							{commas(singleLoanRequest?.loanData?.repayableAmount)}{' '}
							{singleLoanRequest?.loanData?.currencyId.symbol}
						</p>
						<p className="label">Repayable Amount</p>
					</div>
					<div>
						<p>
							{commas(singleLoanRequest?.loanData?.totalLoanPaid)}{' '}
							{singleLoanRequest?.loanData?.currencyId.symbol}
						</p>
						<p className="label">Total Loan Paid</p>
					</div>
					<div>
						<p>
							{commas(singleLoanRequest?.loanData?.totalLoanRemaining)}{' '}
							{singleLoanRequest?.loanData?.currencyId.symbol}
						</p>
						<p className="label">Total Loan Remaining</p>
					</div>
					<div>
						{singleLoanRequest.loanData?.expectedPaybackDate ? (
							<p>
								{moment(
									singleLoanRequest?.loanData?.expectedPaybackDate
								).format('MMM DD, YY / hh:mm:ss a')}
							</p>
						) : (
							'--'
						)}
						<p className="label">Expected Payback Date</p>
					</div>
					<div>
						{singleLoanRequest.loanData?.LoanPaybackDate ? (
							<p>
								{moment(singleLoanRequest?.loanData?.LoanPaybackDate).format(
									'MMM DD, YY / hh:mm:ss a'
								)}
							</p>
						) : (
							'--'
						)}
						<p className="label">Loan Payback Date</p>
					</div>
					<div>
						<p>
							{singleLoanRequest?.loanData?.payBackPeriodInDays || '0'} Days
						</p>
						<p className="label">Payback Period</p>
					</div>
					<div>
						<p>
							{singleLoanRequest?.loanData?.paybackExtensionDay || '0'} Days
						</p>
						<p className="label">Payback Extension</p>
					</div>
					<div>
						<p>{`${singleLoanRequest?.loanData?.liquidateLoan}`}</p>
						<p className="label">Liquidate Loan</p>
					</div>

					{/* {singleLoanRequest?.associatedSwapTransactions.map(
						(transaction: any) => (
							<TransactionItem
								key={transaction._id}
								transaction={transaction}
							/>
						)
					)} */}
				</div>

				<Divider />

				<p>
					<strong>Validity Details</strong>
				</p>
				<div className="details">
					<div>
						<p>
							{validateInfo.systemAllowLoan ? 'Allowed' : 'Loans Not Allowed'}
						</p>
						<p className="label">System Allow Loans</p>
					</div>
					<div>
						<p>{validateInfo.profileLoanable ? 'Loanable' : 'Not Loanable'}</p>
						<p className="label">Profile Loanable</p>
					</div>
					<div>
						<p>{validateInfo.currencyLoanable ? 'Loanable' : 'Not Loanable'}</p>
						<p className="label">Currency Loanable</p>
					</div>
					<div>
						<p>{validateInfo.fixedSavingsValid ? 'Valid' : 'Not Valid'}</p>
						<p className="label">Fixed Savings Valid</p>
					</div>
					<div>
						<p>{validateInfo.durationValid ? 'Valid' : 'Not Valid'}</p>
						<p className="label">Duration Valid</p>
					</div>
					<div>
						<p>
							<NativeNumber value={validateInfo.loanAmount} />{' '}
							{singleLoanRequest.loanData.currencyId?.symbol}
						</p>
						<p className="label">Loan Amount</p>
					</div>
					<div>
						<p>
							{validateInfo.expectedPaybackDate
								? moment(validateInfo.expectedPaybackDate).format(
										'MMM DD, YY / hh:mm:ss a'
								  )
								: '--'}
						</p>
						<p className="label">Expected Payback Date</p>
					</div>
					<div>
						<p>
							<NativeNumber value={validateInfo.maxLoanAmountUsd} /> USD
						</p>
						<p className="label">Max. Loan Amount</p>
					</div>
					<div>
						<p>
							<NativeNumber value={validateInfo.minLoanAmountUsd} /> USD
						</p>
						<p className="label">Min. Loan Amount</p>
					</div>
					<div>
						<p>
							<NativeNumber value={validateInfo.loanInterest.repayableAmount} />{' '}
							{singleLoanRequest.loanData.currencyId.symbol}
						</p>
						<p className="label">Interest Repayable Amount</p>
					</div>
					<div>
						<p>
							<NativeNumber value={validateInfo.loanInterest.interestAmount} />{' '}
							{singleLoanRequest.loanData.currencyId.symbol}
						</p>
						<p className="label">Interest Amount</p>
					</div>
					<div>
						<p>
							<NativeNumber value={validateInfo.loanInterest.interest} />{' '}
							{singleLoanRequest.loanData.currencyId.symbol}
						</p>
						<p className="label">Interest</p>
					</div>
					<div>
						<p>
							<NativeNumber
								value={validateInfo.loanInterest.durationInterest}
							/>
						</p>
						<p className="label">Duration Interest</p>
					</div>
				</div>
			</Card>
			<br />
			<Card>
				<Tabs
				// defaultActiveKey={`admin/transactions?type=1&state=${txsState}&start=${startDate.format(
				// 	'YYYY-MM-DD'
				// )}&end=${endDate.format('YYYY-MM-DD')}`}
				// onChange={(key) => {
				// 	setQueryURL(key);
				// }}
				>
					<Tabs.TabPane tab="Lockable Fixed Savings" key="1">
						<p>
							<h2>Lockable Fixed Savings Section</h2>
						</p>
						<div
							style={{
								display: 'flex',
								gap: '2em',
								color: `${
									checkTotalCollateral -
										Number(singleLoanRequest?.loanData?.loanAmount) >
									0
										? 'green'
										: 'red'
								}`,
							}}
						>
							{' '}
							<span>Total Selected: </span>
							<div style={{ display: 'flex' }}>
								<img
									width={20}
									height={20}
									style={{ marginRight: 5 }}
									src={
										singleLoanRequest?.loanData?.currencyId?.iconUrl ||
										defaultImageOne
									}
									alt=""
								/>
								<strong>
									{checkTotalCollateral}/
									{singleLoanRequest?.loanData?.loanAmount}{' '}
									{singleLoanRequest?.loanData?.currencyId.symbol}
								</strong>
							</div>
							{selectedSavingsToLock.length > 0 &&
								checkTotalCollateral -
									Number(singleLoanRequest?.loanData?.loanAmount) >
									0 && (
									<Button onClick={() => lockSelectedSavings()}>
										Lock Selected Savings
									</Button>
								)}
						</div>

						{lockableFixedSavings?.content &&
							lockableFixedSavings?.content?.map((profile: any) => (
								<LockableFixedItem
									key={profile._id}
									item={profile}
									onChange={onChange}
								/>
							))}
					</Tabs.TabPane>
					<Tabs.TabPane tab="Locked Fixed Savings" key="2">
						<p>
							<h2>Locked Fixed Savings Section</h2>
						</p>
						{loanItemId && singleLoanRequest?.loanData?.userId?._id && (
							<LockedFixedLoan
								loanId={loanItemId}
								userId={singleLoanRequest?.loanData?.userId?._id}
							/>
						)}
					</Tabs.TabPane>
				</Tabs>
			</Card>

			<br />

			<Card>
				<h2>Associated Transactions</h2>
				<Divider />
				{singleLoanRequest?.transactionStatements?.map((transaction: any) => (
					<TransactionItem key={transaction._id} transaction={transaction} />
				))}
			</Card>
		</SingleLoanItemPageStyle>
	);
};

export default SingleLoanItemPage;
