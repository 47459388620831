import { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { retrieveBlacklistedUsers } from '../../methods/redux/reducers/compliance';

const Style = styled.div`
	width: 100%;
	margin-top: 10px;
	background: red;
	padding: 5px;
	color: white;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 6px;
	text-align: center;
	text-transform: uppercase;
`;

const BlacklistedUserMarker = ({ userId }: { userId: any }) => {
	const dispatch = useAppDispatch();

	const { blacklistedUsers } = useAppSelector((store) => store.compliance);

	const checkForBlacklistRecord = () => {
		const found = blacklistedUsers.itemsList.find(
			(x: any) => x.userId?._id === userId
		);
		return !!found;
	};

	useEffect(() => {
		dispatch(retrieveBlacklistedUsers({ userId }));
	}, [userId]);

	if (checkForBlacklistRecord()) {
		return (
			<Style>
				<p>Blacklisted User</p>
			</Style>
		);
	}

	return null;
};

export default BlacklistedUserMarker;
