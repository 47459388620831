import { Switch } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../../methods/redux/hooks';
import { handleSwitch } from '../../../methods/redux/reducers/fundsRobots';

const SettingsContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const MainSection = styled.section`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
		rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	width: 400px;
	padding: 20px;
	border-radius: 20px;

	/* & . {
    display: flex;
    align-items: flex-end;
  } */
`;
const ItemSection = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin: 10px;

	& .edithIcon {
		display: inline-flex;
		color: #0d0dac;
		font-size: 20px;
		margin-left: 10px;
	}
`;

interface ISettingsSection {
	cronDetails: any;
}

const SettingsSection: FC<ISettingsSection> = ({ cronDetails }) => {
	const dispatch = useAppDispatch();

	return (
		<SettingsContainer>
			{cronDetails[3] && (
				<MainSection>
					{cronDetails
						?.filter((a: any) => a?.name === 'isWithdrawalCronActive')
						?.map((data: any) => (
							<ItemSection key={data._id}>
								<h4>Activate CRON to Handle Withdrawal From Plan</h4>{' '}
								<Switch
									defaultChecked={data?.value === 'true'}
									checkedChildren="On"
									unCheckedChildren="Off"
									size="small"
									onClick={(checked: boolean) =>
										dispatch(handleSwitch({ id: data?._id, value: checked }))
									}
								/>
							</ItemSection>
						))}
					{cronDetails
						?.filter((a: any) => a?.name === 'isDepositCronActive')
						?.map((data: any) => (
							<ItemSection>
								<h4>Activate CRON to Handle Deposit</h4>{' '}
								<Switch
									defaultChecked={data?.value === 'true'}
									checkedChildren="On"
									unCheckedChildren="Off"
									size="small"
									onClick={(checked: boolean) =>
										dispatch(handleSwitch({ id: data?._id, value: checked }))
									}
								/>
							</ItemSection>
						))}

					{cronDetails
						?.filter((a: any) => a?.name === 'isOutGoingWithdrawalCronActive')
						?.map((data: any) => (
							<ItemSection key={data._id}>
								<h4>Activate CRON to handle Outgoing Withdrawal </h4>{' '}
								<Switch
									defaultChecked={data?.value === 'true'}
									checkedChildren="On"
									unCheckedChildren="Off"
									size="small"
									onClick={(checked: boolean) =>
										dispatch(handleSwitch({ id: data?._id, value: checked }))
									}
								/>
							</ItemSection>
						))}

					{cronDetails
						?.filter((a: any) => a?.name === 'processFailedTransfer')
						?.map((data: any) => (
							<ItemSection key={data._id}>
								<h4>Process Failed Transactions </h4>{' '}
								<Switch
									defaultChecked={data?.value === 'true'}
									checkedChildren="On"
									unCheckedChildren="Off"
									size="small"
									onClick={(checked: boolean) =>
										dispatch(handleSwitch({ id: data?._id, value: checked }))
									}
								/>
							</ItemSection>
						))}
				</MainSection>
			)}
		</SettingsContainer>
	);
};

export default SettingsSection;
