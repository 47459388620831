import { Tag, Button, Input, DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import FilterComponentSelect from './filter/Select';

interface IFilter {
	label: string;
	name: any;
	htmlElement: string;
	selectOptions?: Array<any>;
	defaultSelectedValue?: string;
}

interface ISearchFilter {
	title: string;
	searchFilters: Array<IFilter>;
	retrieveQueryString: Function;
	filterOkText?: string;
}

const SearchFilterStyle = styled.div`
	& hr {
		border: 1px solid #eee;
		margin: 20px 1px;
	}
`;

const SingleFilter = styled.div`
	display: inline-block;
	min-width: 150px;
	padding: 5px 5px 5px 0;
`;

const SearchFilter = ({
	title,
	searchFilters,
	retrieveQueryString,
	filterOkText,
}: ISearchFilter) => {
	const [filters, setFilter] = useState<Array<any>>([]);

	const [activeFilters, setActiveFilters] = useState<Array<any>>([]);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());

		delete queryData.pageId;
		delete queryData.perPage;

		const filterListNames = searchFilters
			.filter((x) => Object.keys(queryData).includes(x.name))
			.map((y) => y.label);

		setActiveFilters(filterListNames);
	}, [searchParams]);

	const addFilter = (name: string, value: any) => {
		const existingItem = filters.filter((x: any) => x.name === name);
		if (existingItem.length > 0) {
			const newList = filters.map((x: any) => {
				const single = x;
				if (name === single.name) {
					single.value = value;
					return single;
				}
				return x;
			});

			setFilter(newList);
		} else {
			const newList = filters.concat([{ name, value }]);
			setFilter(newList);
		}
	};

	const submitFilter = () => {
		const filterText: any = {};
		for (let i = 0; i < filters.length; i += 1) {
			const a = filters[i];
			filterText[a.name] = a.value;
		}
		retrieveQueryString({ ...filterText, pageId: '1' });
	};

	const removeAFilter = (filterLabel: string) => {
		const filterOptionItems = searchFilters.filter(
			(x) => x.label === filterLabel
		);

		if (filterOptionItems.length > 0) {
			const filterToRemove = filterOptionItems[0].name;
			const queryData = Object.fromEntries(searchParams.entries());
			delete queryData[filterToRemove];
			retrieveQueryString(queryData, 'remove');
		}
	};

	const retrieveDefaultValue = (filterName: string) => {
		const queryData = Object.fromEntries(searchParams.entries());
		return queryData[filterName];
	};

	return (
		<SearchFilterStyle>
			<h2>{title}</h2>

			<div>
				{searchFilters.map((item) => (
					<SingleFilter key={item.name}>
						<p>{item.label}:</p>
						<div>
							{item.htmlElement === 'input' && (
								<Input
									defaultValue={
										item.defaultSelectedValue ||
										retrieveDefaultValue(item.name) ||
										''
									}
									onChange={(e) => addFilter(item.name, e.target.value)}
								/>
							)}
							{item.htmlElement === 'select' && (
								<FilterComponentSelect
									addFilter={addFilter}
									item={item}
									retrieveDefaultValue={retrieveDefaultValue}
								/>
							)}
							{item.htmlElement === 'datepicker' && (
								<DatePicker
									format="MMM DD, YYYY"
									allowClear={false}
									defaultValue={
										(item.defaultSelectedValue
											? moment(item.defaultSelectedValue)
											: undefined) ||
										(retrieveDefaultValue(item.name)
											? moment(retrieveDefaultValue(item.name))
											: undefined)
									}
									onChange={(e) =>
										addFilter(item.name, e?.format('YYYY-MM-DD'))
									}
								/>
							)}
						</div>
					</SingleFilter>
				))}
				<div style={{ display: 'inline-block' }}>
					<Button
						onClick={submitFilter}
						disabled={filters.length < 1}
						type={filters.length > 0 ? 'primary' : 'default'}
					>
						{filterOkText}
					</Button>
				</div>
			</div>

			<div style={{ display: 'flex', alignItems: 'center' }}>
				<span className="material-symbols-outlined" style={{ fontSize: 21 }}>
					filter_list
				</span>
				<span style={{ marginRight: 7 }}>Filters </span>
				{activeFilters.map((item: any) => (
					<Tag
						key={item}
						color="blue"
						closable
						onClose={() => removeAFilter(item)}
					>
						{item}
					</Tag>
				))}
			</div>
		</SearchFilterStyle>
	);
};

SearchFilter.defaultProps = {
	filterOkText: 'Filter',
};

export default SearchFilter;
