import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const retrieveFundsRobotConfig = createAsyncThunk(
	'fundsRobot/retrieveFundsRobotConfig',
	async () => {
		const res = await baseuri.get('/admin/robot/fund-robot-config');
		return res.data.data;
	}
);

export const retrieveFundsRobotIntervals = createAsyncThunk(
	'fundsRobot/retrieveFundsRobotIntervals',
	async () => {
		const res = await baseuri.get(
			'admin/robot/fund-robot-config/system-cron-time'
		);
		return res.data.data;
	}
);

export const handleSwitch = createAsyncThunk(
	'fundsRobot/handleSwitch',
	async (params: any) => {
		const { id, value } = params;
		const res = await baseuri.put(`admin/robot/fund-robot-config`, {
			id,
			value: `${value}`,
		});
		message.success('config updated');
		return res.data.data;
	}
);

const fundsRobotsRx = createSlice({
	name: 'fundsRobot',
	initialState: {
		config: [],
		fundsInterval: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveFundsRobotIntervals.fulfilled, (store, action) => {
				const state = store;
				state.fundsInterval = action.payload;
				return state;
			})
			.addCase(retrieveFundsRobotConfig.fulfilled, (store, action) => {
				const state = store;
				state.config = action.payload;
				return state;
			})
			.addCase(handleSwitch.fulfilled, (store, action) => {
				const state = store;
				const { _id } = action.payload;

				const updatedData: any = state.config.map((item: any) => {
					if (item._id === _id) {
						return { ...action.payload };
					}
					return item;
				});
				state.config = updatedData;
			});
	},
});

export default fundsRobotsRx.reducer;
