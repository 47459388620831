import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../../../component/Numeral';

interface IData {
	data: any;
}

const Style = styled.div`
	margin-bottom: 10px;
	background-color: #f8f8f8;
	padding: 20px;

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 7px;
		padding-top: 10px;

		& > * {
			display: inline-block;
			margin-right: 20px;
		}

		& .label {
			font-style: italic;
			font-size: 12px;
			color: #aaa;
		}
	}
`;

const ExternalWithdrawalGasFeeItem = ({ data }: IData) => (
	<Style>
		<div>
			<p>
				<strong>
					<NativeNumber value={data.gasPriceInUsd} decimalPlaces={8} /> USD
				</strong>
			</p>
		</div>
		<div className="details">
			<div>
				<p>
					<NativeNumber value={data.finalAmount} /> {data.currencyId?.symbol}
				</p>
				<p className="label">Amount</p>
			</div>
			<div>
				<p>
					{data.gasPriceInNativeToken}{' '}
					{data.blockchainNetworkId?.nativeTokenSymbol}
				</p>
				<p className="label">Gas Fee</p>
			</div>
			<div>
				<p>{data.nativeTokenPrice} USD</p>
				<p className="label">
					{data.blockchainNetworkId?.nativeTokenSymbol} Price
				</p>
			</div>
			<div>
				<p>
					<NativeNumber value={data.fee} decimalPlaces={8} />{' '}
					{data.currencyId?.symbol} /{' '}
					<NativeNumber value={data.feeInUsd || 0} decimalPlaces={4} /> USD{' '}
					{data.p2pRef && <span>(XendBridge)</span>}
				</p>
				<p className="label">Xend Finance Transaction Fee</p>
			</div>
			<div>
				<p>
					<NativeNumber
						decimalPlaces={4}
						value={Number(data.feeInUsd || 0) - Number(data.gasPriceInUsd)}
					/>{' '}
					USD
				</p>
				<p className="label">Profit</p>
			</div>
			<div>
				<Link to={`/user-profile/${data.userId?._id}`}>
					@{data.userId?.username}
				</Link>
				<p className="label">Customer</p>
			</div>
			<div>
				<a
					rel="noreferrer"
					target="_blank"
					href={`https://${data.blockchainNetworkId?.explorerUrl}/tx/${data.transactionHash}`}
				>
					Hash
				</a>
				<p className="label">Transaction Hash</p>
			</div>
			<div>
				<p>{moment(data.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				<p className="label">Last Updated At</p>
			</div>
		</div>
	</Style>
);

export default ExternalWithdrawalGasFeeItem;
