import { Divider, Switch, Tag } from 'antd';
import styled from 'styled-components';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateFeeSettings } from '../../methods/redux/reducers/fee-settings';
import EditFeeSettings from './EditFeeSettings';

const Style = styled.section`
	& .title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	& .name {
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
	}

	& hr {
		border: 0.5px solid #eee;
		margin-top: 10px;
	}

	& .details {
		& > * {
			display: inline-block;
			// border-right: 1px solid green;
			margin-top: 10px;
			padding: 10px;
			min-width: 150px;
			font-size: 15px;

			& .label {
				color: #aaa;
			}

			& .currency {
				display: inline-flex;
				height: 100%;
				align-items: flex-end;

				& img {
					margin-right: 10px;
				}
			}
		}
	}
`;

// 'default',
// 	'deposit',
// 	'withdraw',
// 	'out',
// 				'fund',
// 							'p2pOut',

interface ISingleFeeSettings {
	fee: any;
}

const SingleFeeSettings = ({ fee }: ISingleFeeSettings) => {
	const dispatch = useAppDispatch();

	const toggleFeeSettings = (active: boolean) => {
		dispatch(updateFeeSettings({ strategyId: fee._id, active }));
	};

	return (
		<Style>
			<div className="title">
				<div>
					<p className="name">
						{fee.typeOfFee} ({fee.strategy}){' '}
						{fee.active ? <Tag color="green">Active</Tag> : <Tag>Inactive</Tag>}
					</p>
					<Switch checked={fee.active} onChange={toggleFeeSettings} />
				</div>
				<EditFeeSettings
					feeSettings={fee}
					currencySymbol={fee.currencyId?.symbol || ''}
				/>
			</div>

			<Divider />

			<div className="details">
				<div>
					<p>
						{fee.fee}{' '}
						{fee.strategy === 'percent' ? '%' : fee.currencyId?.symbol}
					</p>
					<p className="label">Fee</p>
				</div>
				<div>
					<p>
						{fee.capFeeAmount} {fee.currencyId?.symbol}
					</p>
					<p className="label">Cap Fee</p>
				</div>
				{fee.currencyId && (
					<div>
						<div className="currency">
							<img
								width={21}
								height={21}
								alt=""
								src={fee.currencyId?.iconUrl}
							/>
							<p>{fee.currencyId?.symbol}</p>
						</div>
						<p className="label">Currency</p>
					</div>
				)}
				<div>
					<p>{fee.xendDiscountPercentage}%</p>
					<p className="label">Discount Percent</p>
				</div>
				<div>
					<p>{fee.xendThreshold}</p>
					<p className="label">Xend Threshold</p>
				</div>
				<div>
					<p>{fee.applyXendDiscount ? <p>Active</p> : <p>Inactive</p>}</p>
					<p className="label">Apply Discount</p>
				</div>
			</div>
		</Style>
	);
};

export default SingleFeeSettings;
