import { Button } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import { MyCard } from '../../users/users-list/by-usertype/ByUserType';
import ToggleRobotUser from '../UpdateUserType';
import AdminManualTwoFactorReset from './AdminManualTwoFactorReset';
import DeletionProcess from './DeletionProcess';
import EmailBounceDelete from './EmailBounceDelete';
import TrustScoreUpdate from './TrustScoreUpdate';
import UserWithdrawalConfig from './UserWithdrawalConfig';

const MainSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
`;
const TitleSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
`;
const UserGeneralSettings = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		dispatch(getUserProfileById(id));
	}, []);
	return (
		<MainSection>
			<strong>
				General Settings for{' '}
				{user?.profile?.fullName || user.profile.username || user.profile.email}
			</strong>
			<UserWithdrawalConfig details={user} />
			<MyCard>
				<TitleSection>
					<h2>Reset User 2fa</h2>
					{user.profile.enabledTwoFactor && (
						<Link to="/maintenance/2fa-reset-request">
							<Button type="primary">Go to Requests</Button>
						</Link>
					)}
					{user.profile.enabledTwoFactor && (
						<AdminManualTwoFactorReset user={user.profile} />
					)}
				</TitleSection>
				<p>Here, admin can clear the already submitted 2fa data of a user</p>
				<p>
					{!user.profile.enabledTwoFactor && (
						<span>
							This user <strong> does not</strong> have 2fa enabled
						</span>
					)}
				</p>
				{user?.profile?.twoFaResetTime &&
					`Last reset: ${moment(user?.profile?.twoFaResetTime).format('lll')}`}
			</MyCard>
			<MyCard>
				<TitleSection>
					<h2>Activate Deletion Process</h2>
					<DeletionProcess details={user} />
				</TitleSection>
				<p>
					The admin can trigger the process for an account deletion from this
					section
				</p>
			</MyCard>
			<MyCard>
				<TitleSection>
					<h2>Delete Bounce User</h2>
					<EmailBounceDelete details={user} />
				</TitleSection>
				<p>
					This section enables the admin the delete this user email address as a
					bounce user email
				</p>
			</MyCard>
			<MyCard>
				<TitleSection>
					<h2>Update Trust score</h2>
					{user?.profile?._id && (
						<TrustScoreUpdate
							id={user?.profile?._id}
							mainScore={user?.profile?.trustScore}
						/>
					)}
				</TitleSection>
				<p>
					This user has a trust score of{' '}
					<strong>{user.profile?.trustScore}%</strong>. This can be updated with
					the button on the right
				</p>
			</MyCard>
			<MyCard>
				<TitleSection>
					{user?.profile?.isRobot ? (
						<h2> Remove as Robot User </h2>
					) : (
						<h2>Set as Robot User</h2>
					)}
					<ToggleRobotUser
						userId={user?.profile?._id}
						isRobot={user?.profile?.isRobot}
					/>
				</TitleSection>
				<p>
					This section enables the admin the delete this user email address as a
					bounce user email
				</p>
			</MyCard>
		</MainSection>
	);
};

export default UserGeneralSettings;
