import { Switch, Button, Select, Divider, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import {
	deleteFeeSettings,
	updateFeeSettings,
} from '../../methods/redux/reducers/fee-settings';

interface IEditSettings {
	feeSettings: any;
	currencySymbol: string;
}

const EditFeeSettings = ({ feeSettings, currencySymbol }: IEditSettings) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);

	const [state, setState] = useState({
		strategyId: '',
		typeOfFee: '',
		currencyId: '',
		strategy: '',
		fee: 0,
		capFeeAmount: 0,
		applyXendDiscount: false,
		xendDiscountPercentage: 0,
		xendThreshold: 0,
		active: false,
	});

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const submitUpdate = () => {
		dispatch(updateFeeSettings(state));
	};

	const deleteFee = () => {
		dispatch(deleteFeeSettings(state.strategyId));
	};

	useEffect(() => {
		setState({ ...state, ...feeSettings, strategyId: feeSettings._id });
	}, [feeSettings]);

	return (
		<div>
			<Button onClick={() => setVisible(true)}>Edit</Button>

			<Modal
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
				title={`Edit Fee Settings (Withdraw ${currencySymbol})`}
			>
				<div>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							columnGap: 20,
						}}
					>
						<div>
							<p>Fee</p>
							<Input
								value={state.fee}
								name="fee"
								onChange={handleInputChange}
							/>
						</div>
						<div>
							<p>Strategy</p>
							<Select
								value={state.strategy}
								style={{ width: '100%' }}
								onChange={(strategy) => setState({ ...state, strategy })}
							>
								<Select.Option value="percent">Percentage</Select.Option>
								<Select.Option value="flat">Flat Fee</Select.Option>
							</Select>
						</div>
					</div>
					<div>
						<p>Cap Fee Amount</p>
						<Input
							name="capFeeAmount"
							value={state.capFeeAmount}
							onChange={handleInputChange}
						/>
					</div>

					<div>
						<p>Apply Discount</p>
						<Switch
							checked={state.applyXendDiscount}
							onChange={(applyXendDiscount) =>
								setState({ ...state, applyXendDiscount })
							}
						/>
					</div>
					<div>
						<p>Discount Percentage</p>
						<Input
							name="xendDiscountPercentage"
							value={state.xendDiscountPercentage}
							onChange={handleInputChange}
							addonAfter="%"
						/>
					</div>
					<div>
						<p>Discount Threshold Amount</p>
						<Input
							name="xendThreshold"
							addonAfter="XEND"
							value={state.xendThreshold}
							onChange={handleInputChange}
						/>
					</div>

					<br />

					<div>
						<Button onClick={submitUpdate} block type="primary">
							Update
						</Button>
					</div>
				</div>

				<Divider />

				<div>
					<Button onClick={deleteFee} danger type="primary">
						Delete this fee
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default EditFeeSettings;
