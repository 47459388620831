import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const getFeeSettings = createAsyncThunk(
	'fee-settings/getFeeSettings',
	async () => {
		const res = await baseuri.get('/admin/fee-settings');
		return res.data.data;
	}
);

export const createFeeSettings = createAsyncThunk(
	'fee-settings/createFeeSettings',
	async (data: any) => {
		const res = await baseuri.post('/admin/add-fee-strategy', data);
		return res.data?.data;
	}
);

export const updateFeeSettings = createAsyncThunk(
	'fee-settings/updateFeeSettings',
	async (data: any) => {
		const dt = data;
		delete dt?.typeOfFee;
		delete dt?.currencyId;
		const res = await baseuri.put('/admin/edit-fee-strategy', dt);
		message.success('Updated successfully');
		return res.data?.data;
	}
);

export const deleteFeeSettings = createAsyncThunk(
	'fee-settings/deleteFeeSettings',
	async (strategyId: any) => {
		const res = await baseuri.delete('/admin/delete-fee-strategy', {
			params: { strategyId },
		});
		return res.data?.data;
	}
);

const feeSettingsRx = createSlice({
	name: 'fee-settings',
	initialState: {
		fees: [],
		loading: false,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getFeeSettings.fulfilled, (store, action) => {
				const state = store;
				state.fees = action.payload;
				state.loading = false;
			})
			.addCase(createFeeSettings.pending, (store) => {
				const state = store;
				state.loading = true;
			})
			.addCase(createFeeSettings.fulfilled, (store, action) => {
				const state = store;
				state.loading = false;
				state.fees = state.fees.concat(action.payload);
			})
			.addCase(createFeeSettings.rejected, (store) => {
				const state = store;
				state.loading = false;
			})
			.addCase(updateFeeSettings.fulfilled, (store, action) => {
				const state = store;
				const updatedRecord: any = state.fees.map((item: any) => {
					if (item._id === action.payload?._id) {
						return { ...item, ...action.payload };
					}
					return item;
				});
				state.fees = updatedRecord;
				state.loading = false;
			});
	},
});

export default feeSettingsRx.reducer;
