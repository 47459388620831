import { Button, Divider, Input, Switch, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';
import FixedSavingsRewardSettings from './FixedSavingsRewardSettings';
import { SavingsSettingsStyle } from './SavingsSettings';

const FixedSavingsSettings = () => {
	const dispatch = useAppDispatch();

	const settings = useAppSelector((store) => store.internalSettings);

	const [fixedSettings, setFixedSettings] = useState({
		_id: '',
		maxAllowedStableCurrencyFixedSavingsCount: 0,
		maxAllowedStableCurrencySavingsTopUpCount: 0,
		payFixedSavingsRewardBonus: false,
		fixedSavingsTopUpLifespan: 0,
		allowFixedAutoSave: false,
		fixedSavingsTerminationPenaltyPercentage: 0,
		updateButtonDisabled: true,
		minFixedSavingsDurationDays: 0,
		maxFixedSavingsDurationDays: 0,
	});

	const toggleAllow = (value: any) => {
		dispatch(
			updateInternalSettings({ allowStableCurrencyFixedSavings: value })
		);
	};

	useEffect(() => {
		setFixedSettings({
			...fixedSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	const updateSettings = () => {
		const {
			maxAllowedStableCurrencyFixedSavingsCount,
			maxAllowedStableCurrencySavingsTopUpCount,
			payFixedSavingsRewardBonus,
			fixedSavingsTopUpLifespan,
			allowFixedAutoSave,
			fixedSavingsTerminationPenaltyPercentage,
			minFixedSavingsDurationDays,
			maxFixedSavingsDurationDays,
		} = fixedSettings;

		dispatch(
			updateInternalSettings({
				settingsId: fixedSettings._id,
				maxAllowedStableCurrencyFixedSavingsCount,
				maxAllowedStableCurrencySavingsTopUpCount,
				payFixedSavingsRewardBonus,
				fixedSavingsTopUpLifespan,
				allowFixedAutoSave,
				fixedSavingsTerminationPenaltyPercentage,
				minFixedSavingsDurationDays,
				maxFixedSavingsDurationDays,
			})
		);
	};

	return (
		<SavingsSettingsStyle>
			<p>
				<strong>Fixed Savings</strong>
			</p>
			<div>
				<p>Toggle allowing of fixed savings on the platform</p>
				<Switch
					size="default"
					onChange={toggleAllow}
					checked={settings.allowStableCurrencyFixedSavings}
				/>
			</div>
			<div className="lined-settings">
				<div>
					<p className="label">Maximum Number</p>
					<Input
						onChange={(e) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								maxAllowedStableCurrencyFixedSavingsCount: Number(
									e.target.value
								),
							})
						}
						value={fixedSettings.maxAllowedStableCurrencyFixedSavingsCount}
					/>
				</div>
				<div>
					<Tooltip title="This is the maximum number of times a user can topup fixed savings in the lifespan of the fixed savings.">
						<p className="label">
							<span>Maximum Topup Count</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						onChange={(e) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								maxAllowedStableCurrencySavingsTopUpCount: Number(
									e.target.value
								),
							})
						}
						value={fixedSettings.maxAllowedStableCurrencySavingsTopUpCount}
					/>
				</div>
				<div>
					<Tooltip title="The least number of days remaining before the completion of a savings plan to allow topup.">
						<p className="label">
							<span>Topup Least Threshold</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						addonAfter="days"
						onChange={(e) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								fixedSavingsTopUpLifespan: Number(e.target.value),
							})
						}
						value={fixedSettings.fixedSavingsTopUpLifespan}
					/>
				</div>
				<div>
					<Tooltip title="The least number of days a fixed savings can have">
						<p className="label">
							<span>Minimum Duration</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						addonAfter="days"
						onChange={(e) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								minFixedSavingsDurationDays: Number(e.target.value),
							})
						}
						value={fixedSettings.minFixedSavingsDurationDays}
					/>
				</div>
				<div>
					<Tooltip title="The maximum number of days a fixed savings can have">
						<p className="label">
							<span>Maximum Duration</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						addonAfter="days"
						onChange={(e) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								maxFixedSavingsDurationDays: Number(e.target.value),
							})
						}
						value={fixedSettings.maxFixedSavingsDurationDays}
					/>
				</div>

				<div>
					<Tooltip title="Percentage penalty for terminating your fixed savings before the end date. The percentage is applied on the income.">
						<p className="label">
							<span>Termination Penalty</span>{' '}
							<span className="material-icons tooltip">info</span>
						</p>
					</Tooltip>
					<Input
						addonAfter="%"
						onChange={(e) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								fixedSavingsTerminationPenaltyPercentage: Number(
									e.target.value
								),
							})
						}
						value={fixedSettings.fixedSavingsTerminationPenaltyPercentage}
					/>
				</div>
				<div>
					<p className="label">Autosave</p>
					<Switch
						size="small"
						onChange={(value) =>
							setFixedSettings({
								...fixedSettings,
								updateButtonDisabled: false,
								allowFixedAutoSave: value,
							})
						}
						checked={fixedSettings.allowFixedAutoSave}
					/>
				</div>
			</div>
			<div>
				<Button
					type="primary"
					onClick={updateSettings}
					disabled={fixedSettings.updateButtonDisabled}
				>
					Update Fixed Savings Settings
				</Button>
			</div>

			<Divider />

			<FixedSavingsRewardSettings />
		</SavingsSettingsStyle>
	);
};

export default FixedSavingsSettings;
