import BlockchainNetworks from './BlockchainNetworks';
import ContractAddresses from './ContractAddresses';
import SupportedCurrencies from './SupportedCurrencies';

const CurrencySettings = () => (
	<div>
		<BlockchainNetworks />

		<br />

		<SupportedCurrencies />

		<br />

		<ContractAddresses />
	</div>
);

export default CurrencySettings;
