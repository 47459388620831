import { Pagination, Divider, Card } from 'antd';
import { useEffect } from 'react';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	getStakingProfiles,
	retrievePendingStakings,
	retrieveUserStakings,
	retrieveUserStakingsCount,
} from '../../methods/redux/reducers/staking';
import { getBlockchainTransactionRecords } from '../../methods/redux/reducers/blockchain-transactions';
// import PendingStakings from './PendingStakings';
import StakingItem from './StakingItem';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import StakingsStatusCount from './StakingCount';
import remakeQueryString from '../../methods/utils/remake-query-string';

const Staking = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const { userId } = useParams();

	const { stakings, profiles } = useAppSelector((store) => store.staking);
	const { currencies } = useAppSelector((store) => store.currencies);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'staked',
				pageId: '1',
				perPage: String(20),
			});
		}

		if (userId) {
			dispatch(retrieveUserStakingsCount({ userId }));
		} else {
			dispatch(retrieveUserStakingsCount({}));
		}

		dispatch(
			getBlockchainTransactionRecords({ actionType: 'staking', perPage: 1 })
		);
		dispatch(getCurrencies({}));
		dispatch(retrievePendingStakings());
		dispatch(getStakingProfiles('active'));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(retrieveUserStakings({ ...queryData, userId }));
			} else {
				dispatch(retrieveUserStakings(queryData));
			}
		}
	}, [searchParams]);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	const profileSelect = profiles.map((item: any) => ({
		name: item.name,
		value: item._id,
	}));

	return (
		<div>
			<Card>
				<h1>
					Staking{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile?._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>
				<p>
					Manage currency staking on Xend Finance mobile. Perform administrator
					functions for staking feature.
				</p>

				<Divider />

				<Link to="/settings/staking">Settings</Link>

				<Divider />

				<StakingsStatusCount />

				<br />

				<SearchFilter
					title="Search Staking Record"
					searchFilters={[
						{
							label: 'Status',
							name: 'status',
							htmlElement: 'select',
							defaultSelectedValue: 'staked',
							selectOptions: [
								{ name: 'Pending', value: 'pending' },
								{ name: 'Staked', value: 'staked' },
								{ name: 'Completed', value: 'completed' },
								{ name: 'Deleted', value: 'deleted' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
						{
							label: 'Staking Profile',
							name: 'stakingProfileId',
							htmlElement: 'select',
							selectOptions: profileSelect,
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<p>
					Total of <strong>{stakings.paginator.itemCount}</strong> found.
				</p>

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={stakings.paginator.itemCount}
					current={stakings.paginator.currentPage}
					pageSize={stakings.paginator.perPage}
				/>

				<br />

				{stakings.itemsList.map((staking: any) => (
					<StakingItem key={staking._id} stakingItem={staking} />
				))}

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={stakings.paginator.itemCount}
					current={stakings.paginator.currentPage}
					pageSize={stakings.paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>

			{/* <br /> */}

			{/* <Card> */}
			{/* 	<h2>Pending Stakings</h2> */}

			{/* 	{pendingStaking.map((staking: any) => ( */}
			{/* 		<PendingStakings staking={staking} key={staking._id} /> */}
			{/* 	))} */}
			{/* </Card> */}

			<br />

			{/* <Card> */}
			{/* 	<h2>Last Blockchain Transaction</h2> */}
			{/* 	{transactions.map((item: any) => ( */}
			{/* 		<div key={item._id}>{item._id}</div> */}
			{/* 	))} */}
			{/* </Card> */}
		</div>
	);
};

export default Staking;
