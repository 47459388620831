import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect } from 'react';
import { Divider } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { retrieveGameBadge } from '../../../methods/redux/reducers/games/actions/game-badge';

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;
const GameBadgeItem = () => {
	const dispatch = useAppDispatch();
	const { gameBadge } = useAppSelector((store) => store.games);
	useEffect(() => {
		dispatch(retrieveGameBadge({}));
	}, []);

	return (
		<div>
			<h2>Game Rank</h2>
			<p>
				This shows a complete list of all the game ranks a user can attain
				during game progress
			</p>
			<Divider />
			{gameBadge.itemsList?.map((badge: any) => (
				<Style>
					{/* <div>
						<Link to={`./game-action/single/${badge._id}`} className="title">
							{badge.key}
						</Link>
					</div> */}
					<Link to={`./single/${badge._id}`} className="title">
						<div className="details">
							<div>
								<p style={{ textTransform: 'capitalize' }}>{badge.key}</p>
								<p className="label">Key</p>
							</div>
							<div>
								<p style={{ textTransform: 'capitalize' }}>
									{badge.requirements}
								</p>
								<p className="label">Requirements</p>
							</div>
							<div>
								<p style={{ textTransform: 'capitalize' }}>{badge.perk}</p>
								<p className="label">Perk</p>
							</div>

							<div>
								<p style={{ textTransform: 'lowercase' }}>{badge.identifier}</p>
								<p className="label">Identifier</p>
							</div>
						</div>
					</Link>
				</Style>
			))}
		</div>
	);
};

export default GameBadgeItem;
