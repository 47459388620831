import { Card, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const GenerateNewAddresses = () => {
	const dispatch = useAppDispatch();
	const { generateNewAddresses } = useAppSelector(
		(store) => store.internalSettings
	);

	return (
		<Card>
			<h2>Generate New Addresses</h2>
			<p>Use SwitchWallet generate new addresses for users</p>

			<br />

			<Switch
				checked={Boolean(generateNewAddresses)}
				onChange={(e) =>
					dispatch(updateInternalSettings({ generateNewAddresses: e }))
				}
			/>
		</Card>
	);
};

export default GenerateNewAddresses;
