import { useState } from 'react';
import { Button, message, Modal, Divider } from 'antd';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

interface IChangeWithdrawalStatus {
	request: any;
}
const Middle = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;
const ChangeWithdrawalStatus = ({ request }: IChangeWithdrawalStatus) => {
	const [visible, setVisible] = useState(false);

	const changeStatus = (status: string) => {
		baseuri
			.put('/admin/wallet/update-withdrawal-request-status', {
				requestId: request?._id,
				status,
			})
			.then(() => {
				setVisible(false);
				message.success('Status Changed Successfully!');
			})
			.catch(() => {
				message.error('Failed to Change Status!');
			});
	};
	return (
		<>
			<Button onClick={() => setVisible(true)}>Review</Button>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Change Withdrawal Request Status"
			>
				<p>
					Review{' '}
					<strong>
						{request.finalAmount} {request.currencyId?.symbol}
					</strong>{' '}
					for @{request?.userId?.username}
				</p>
				<br />
				<p>
					Please make sure you know what you&apos;re about to do before clicking
					on any button here
				</p>

				<br />
				<Middle>
					<Button type="primary" onClick={() => changeStatus('0')}>
						Allow Withdrawal
					</Button>
					{/* <Tag color="blue" onClick={() => changeStatus('0')}>
						Allow Withdrawal
					</Tag> */}
				</Middle>
				<Divider />
				<Button onClick={() => changeStatus('7')}>Set to Hold</Button>
				<Button onClick={() => changeStatus('8')}>Set to Review</Button>
				<Button onClick={() => changeStatus('5')}>Set to Processing</Button>
				<Button onClick={() => changeStatus('6')}>Set to Retry</Button>
				<Button onClick={() => changeStatus('3')}>Set to Cancelled</Button>
			</Modal>
		</>
	);
};

export default ChangeWithdrawalStatus;
