import { Pagination, Card, Divider } from 'antd';
import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import SearchFilter from '../../component/Filter';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getCurrencies } from '../../methods/redux/reducers/currency';
import { retrieveFixedSavingsItems } from '../../methods/redux/reducers/fixed-savings';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import remakeQueryString from '../../methods/utils/remake-query-string';
import FixedSavingsItem from './FixedSavingsItem';

const TopupFixedSavingsItemsPage = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { currencies } = useAppSelector((store) => store.currencies);
	const { fixedItems } = useAppSelector((store) => store.fixedSavings);
	const { user } = useAppSelector((store) => store.userProfile);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				status: 'active',
				pageId: '1',
				perPage: String(fixedItems.paginator.perPage),
			});
		}

		if (userId) {
			dispatch(getUserProfileById(userId));
		}

		dispatch(getCurrencies({}));
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			if (userId) {
				dispatch(retrieveFixedSavingsItems({ ...queryData, userId }));
			} else {
				dispatch(retrieveFixedSavingsItems(queryData));
			}
		}
	}, [searchParams]);

	useEffect(() => {
		if (userId) {
			dispatch(getUserProfileById(userId));
		}
	}, [userId]);

	const currencySelect = currencies.map((item: any) => {
		if (item.allowSavings) {
			return {
				name: `${item.symbol} *`,
				value: item._id,
			};
		}
		return {
			name: item.symbol,
			value: item._id,
		};
	});

	return (
		<div>
			<Card>
				<h1>
					Fixed Savings Topup Items{' '}
					{userId && (
						<span>
							for{' '}
							<Link to={`/user-profile/${user.profile?._id}`}>
								@{user.profile?.username}
							</Link>
						</span>
					)}
				</h1>

				<Divider />

				<SearchFilter
					title="Search Fixed Savings Top-ups"
					searchFilters={[
						{
							label: 'Status',
							name: 'status',
							htmlElement: 'select',
							selectOptions: [
								{ name: 'Pending', value: 'pending' },
								{ name: 'Active', value: 'active' },
								{ name: 'Completed', value: 'completed' },
								{ name: 'Terminated', value: 'terminated' },
								{ name: 'Deleted', value: 'deleted' },
							],
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<p>
					Total of <strong>{fixedItems.paginator.itemCount}</strong> found.
				</p>

				<Divider />

				<div>
					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={fixedItems.paginator.itemCount}
						current={fixedItems.paginator.currentPage}
						pageSize={fixedItems.paginator.perPage}
					/>
				</div>

				<br />

				{fixedItems.itemsList.map((item: any) => (
					<FixedSavingsItem key={item._id} item={item} />
				))}

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={fixedItems.paginator.itemCount}
					current={fixedItems.paginator.currentPage}
					pageSize={fixedItems.paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>
		</div>
	);
};

export default TopupFixedSavingsItemsPage;
