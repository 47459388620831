import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';
import { randomgen } from '../../utils/general-utils';
import { addToLoadingQueue, removeFromLoadingQueue } from './general';

export const retrieveCryptoBalances = createAsyncThunk(
	'cryptoBalances/retrieveCryptoBalances',
	async (params: any, { dispatch }) => {
		const loadingId = randomgen();
		dispatch(addToLoadingQueue(loadingId));
		const res = await baseuri.get('/admin/wallets/wallet-balances', { params });
		dispatch(removeFromLoadingQueue(loadingId));
		return res.data.data;
	}
);

const cryptoBalancesRx = createSlice({
	name: 'cryptoBalances',
	initialState: {
		cryptoBalances: {
			details: {
				itemsList: [],
				paginator: { currentPage: 1, itemCount: 0, perPage: 20 },
			},
			result: [],
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder.addCase(retrieveCryptoBalances.fulfilled, (state, action) => {
			const store = state;
			store.cryptoBalances = action.payload;
		}),
});

export default cryptoBalancesRx.reducer;
