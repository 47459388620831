import { Button, Divider, Input, Modal, Popconfirm, Select } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	assignGameActions,
	retrieveGameActionsForAGame,
} from '../../../methods/redux/reducers/games/actions/game-actions';
import GameActionItem from './ActionItem';

const Style = styled.div`
	& .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

const GameActions = ({ gameId }: { gameId: any }) => {
	const dispatch = useAppDispatch();

	const { singleGameActionPoint, gameActions } = useAppSelector(
		(store) => store.games
	);
	const { currencies } = useAppSelector((store) => store.currencies);

	const [selectedAction, setSelectedAction] = useState({
		associatedCurrencyId: '',
		defaultActionCount: 0,
		defaultCountPoint: 0,
		defaultPointVolume: 0,
		point: 0,
		usdVolume: 0,
		currencyId: '',
	});
	const [visible, setVisible] = useState(false);
	const [gameActionId, setGameActionId] = useState('');
	const [gameActionType, setGameActionType] = useState<
		'default' | 'count' | 'volume'
	>('default');

	useEffect(() => {
		const x: any = gameActions.itemsList.find(
			(action: any) => action._id === gameActionId
		);
		setSelectedAction({ ...x, currencyId: x?.associatedCurrencyId?._id || '' });
	}, [gameActionId]);

	const assignAction = () => {
		const {
			currencyId,
			defaultActionCount,
			defaultCountPoint,
			point,
			usdVolume,
			defaultPointVolume,
		} = selectedAction;

		dispatch(
			assignGameActions({
				gameActionId,
				gameId,
				associatedCurrencyId: currencyId,
				defaultActionCount,
				defaultCountPoint,
				point,
				usdVolume,
				defaultPointVolume,
				typeOfAction: gameActionType,
			})
		);

		setVisible(false);
	};

	useEffect(() => {
		dispatch(retrieveGameActionsForAGame({ gameId }));
	}, []);

	return (
		<Style>
			<div className="top">
				<h2>Game Actions</h2>
				<Button onClick={() => setVisible(true)}>Add Action</Button>
			</div>
			<br />
			<div>
				{singleGameActionPoint.itemsList.map((gameAction: any) => (
					<GameActionItem gameId={gameId} item={gameAction} key={gameAction} />
				))}
			</div>

			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				title="Assign Game Action"
				footer={[
					<Popconfirm onConfirm={assignAction} title="Are you sure?">
						<Button type="primary">Add Action</Button>
					</Popconfirm>,
				]}
			>
				<p>Select a game action to add this particular game.</p>
				<br />

				<div>
					<Select
						style={{ width: '100%' }}
						size="large"
						value={gameActionId}
						onChange={(actionId) => setGameActionId(actionId)}
					>
						<Select.Option value="">--</Select.Option>
						{gameActions.itemsList.map((gameAction: any) => (
							<Select.Option value={gameAction._id} key={gameAction}>
								{gameAction.action}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				{String(gameActionId).length > 0 && (
					<>
						<div>
							<p>Action Type</p>
							<Select
								value={gameActionType}
								onChange={(e) => setGameActionType(e)}
								style={{ width: '100%' }}
								size="large"
							>
								<Select.Option value="default">Default</Select.Option>
								<Select.Option disabled value="count">
									Action Count
								</Select.Option>
								<Select.Option value="volume">Action Volume</Select.Option>
							</Select>
						</div>

						<Divider />
					</>
				)}
				{String(gameActionId).length > 0 && gameActionType === 'default' && (
					<div>
						<div>
							<p>Points</p>
							<Input
								size="large"
								value={selectedAction?.point}
								onChange={(e) =>
									setSelectedAction({
										...selectedAction,
										point: Number(e.target.value),
									})
								}
							/>
						</div>
						<br />
						<div>
							<p>Currency</p>
							<Select
								style={{ width: '100%' }}
								size="large"
								value={selectedAction.currencyId}
								onChange={(currencyId) =>
									setSelectedAction({
										...selectedAction,
										currencyId,
									})
								}
							>
								<Select.Option>--</Select.Option>
								{currencies.map((currency: any) => (
									<Select value={currency._id}>{currency.symbol}</Select>
								))}
							</Select>
						</div>
					</div>
				)}

				{String(gameActionId).length > 0 && gameActionType === 'count' && (
					<>
						<div>
							<p>Counts</p>
							<Input
								size="large"
								value={selectedAction.defaultActionCount}
								onChange={(e) =>
									setSelectedAction({
										...selectedAction,
										defaultActionCount: Number(e.target.value),
									})
								}
							/>
						</div>
						<br />
						<div>
							<p>Count Point</p>
							<Input
								size="large"
								value={selectedAction.defaultCountPoint}
								onChange={(e) =>
									setSelectedAction({
										...selectedAction,
										defaultCountPoint: Number(e.target.value),
									})
								}
							/>
						</div>
						<br />
						<div>
							<p>Currency</p>
							<Select
								style={{ width: '100%' }}
								size="large"
								value={selectedAction?.currencyId}
								onChange={(currencyId) =>
									setSelectedAction({
										...selectedAction,
										currencyId,
									})
								}
							>
								<Select.Option>--</Select.Option>
								{currencies.map((currency: any) => (
									<Select value={currency._id}>{currency.symbol}</Select>
								))}
							</Select>
						</div>
					</>
				)}

				{String(gameActionId).length > 0 && gameActionType === 'volume' && (
					<>
						<div>
							<p>Volume Point</p>
							<Input
								size="large"
								value={selectedAction.defaultPointVolume}
								onChange={(e) =>
									setSelectedAction({
										...selectedAction,
										defaultPointVolume: Number(e.target.value),
									})
								}
							/>
						</div>
						<br />
						<div>
							<p>Volume Amount</p>
							<Input
								size="large"
								value={selectedAction.usdVolume}
								onChange={(e) =>
									setSelectedAction({
										...selectedAction,
										usdVolume: Number(e.target.value),
									})
								}
							/>
						</div>
						<br />
						<div>
							<p>Currency</p>
							<Select
								style={{ width: '100%' }}
								size="large"
								value={selectedAction?.currencyId}
								onChange={(currencyId) =>
									setSelectedAction({
										...selectedAction,
										currencyId,
									})
								}
							>
								<Select.Option>--</Select.Option>
								{currencies.map((currency: any) => (
									<Select value={currency._id}>{currency.symbol}</Select>
								))}
							</Select>
						</div>
					</>
				)}
			</Modal>
		</Style>
	);
};

export default GameActions;
