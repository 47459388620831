import moment from 'moment';
import styled from 'styled-components';

interface IUserItem {
	user: any;
}

const Style = styled.div`
	padding: 20px;
	background-color: #f8f8f8;
	margin-bottom: 10px;

	& .title {
		font-size: 18px;
		font-weight: 600;
		text-transform: capitalize;
	}

	& .details {
		margin-top: 7px;
		border-top: 1px dashed #ddd;
		padding-top: 10px;

		& .label {
			font-style: italic;
			font-size: 14px;
			color: #aaa;
		}

		& .currency {
			display: flex;
			& img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		& > * {
			display: inline-block;
			margin-right: 20px;
			margin-top: 10px;
		}
	}
`;

const GameSubscribersItem = ({ user }: IUserItem) => (
	<Style>
		<div className="details">
			<div>
				<p className="label">Full Name</p>
				<p style={{ textTransform: 'capitalize' }}>{user.userId.fullName}</p>
			</div>
			<div>
				<p className="label">Email</p>

				<p style={{ textTransform: 'capitalize' }}>{user.userId.email}</p>
			</div>
			<div>
				<p className="label">User Name</p>

				<p style={{ textTransform: 'capitalize' }}>{user.userId.username}</p>
			</div>
			<div>
				<p className="label">Game Status</p>

				<p style={{ textTransform: 'capitalize' }}>{user.status}</p>
			</div>

			<div>
				<p className="label">User Joined Game Date </p>
				<p style={{ textTransform: 'capitalize' }}>
					{' '}
					{user.createdAt ? moment(user.createdAt).format('MMM DD, YY') : '--'}
				</p>
			</div>
		</div>
	</Style>
);

export default GameSubscribersItem;
