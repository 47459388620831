import { Button, Card, Divider, Modal, Progress } from 'antd';
import { Line } from 'react-chartjs-2';
import {
	Chart as Chartjs,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getSingleFixedSavings } from '../../methods/redux/reducers/single-fixed-savings';
import AdminTriggerPayoutFixedPlan from './AdminTriggerPayoutFixedPlan';
import ApproveTerminationRequest from './ApproveTerminationRequest';
import AssociatedFixedSavingsTransactionItem from './AssociatedTransactions';
import FixedSavingsProfileAssociatedSavingsItems from './FixedProfileAssociatedSavingsItem';
// import AdminLoan from '../../profiles/users-profile/AdminLoan';
import LockSavings from '../../compliance/locked-savings/LockSavings';
import CancelTerminationRequest from './CancelTerminationRequest';
import RevertSavings from '../../compliance/locked-savings/RevertSavings';
import NativeNumber from '../../component/Numeral';

Chartjs.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const MiddleSection = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
`;

interface IViewPlanProfitTable {
	data: any[];
}

const ViewPlanProfitTable = ({ data }: IViewPlanProfitTable) => {
	const [visible, setVisible] = useState(false);
	return (
		<>
			<Button onClick={() => setVisible(true)} size="small">
				View Table
			</Button>

			<Modal
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Plan Earnings"
			>
				{data.map((item: any) => (
					<div
						key={item.earnDate}
						style={{
							display: 'flex',
							padding: 5,
							background: '#f8f8f8',
							justifyContent: 'space-between',
							marginBottom: 10,
							flexWrap: 'wrap',
						}}
					>
						<div>
							<p>{item.earnDate}</p>
							<p>Earn Date</p>
						</div>
						<div>
							<p>{item.capital}</p>
							<p>Capital</p>
						</div>
						<div>
							<p>{item.profit}</p>
							<p>Profit</p>
						</div>
						<div>
							<p>{item.apy}</p>
							<p>APY</p>
						</div>
					</div>
				))}
			</Modal>
		</>
	);
};

const FixedSavingsDetailsStyle = styled.div`
	& .locked-indicator {
		padding: 3px;
		color: white;
		text-align: center;
		font-size: 12px;
		font-weight: bold;
		background: red;
	}

	& .iscollateral-indicator {
		position: relative;
		top: -25px;
		padding: 4px;
		margin: auto;
		color: white;
		text-align: center;
		font-size: 13.5px;
		font-weight: bold;
		background: orange;
		width: 75%;
	}

	& .title {
		text-transform: capitalize;
	}

	& .profile-details {
		& > div {
			display: inline-block;
			padding: 5px;
			margin-right: 5px;

			& .label {
				font-size: 12px;
				color: gray;
			}
		}
	}
`;

const SingleFixedSavings = () => {
	const dispatch = useAppDispatch();

	const params = useParams();

	const { ledgerBalance, transactionStatements, savingsProfile, savingsItems } =
		useAppSelector((store) => store.fixedSavingsSingle);

	const [state, setState] = useState<any>({
		percentComplete: 44.5,
		daysLeft: 0,
		earnings: [],
	});

	useEffect(() => {
		dispatch(getSingleFixedSavings(params.fixedSavingsId));
	}, [params]);

	useEffect(() => {
		const { endDate, startDate } = savingsProfile;
		let daysLeft = 0;
		let percentComplete = 100;

		const totalDurationInDays =
			moment(endDate).diff(moment(startDate), 'days') + 1;

		if (moment().isBefore(endDate)) {
			daysLeft = moment(endDate).diff(moment(), 'days') + 1;
			percentComplete =
				((Number(totalDurationInDays) - daysLeft) /
					Number(totalDurationInDays)) *
				100;
			percentComplete = Number(percentComplete.toFixed(2));
		}

		let listToUse: any[] = [];
		for (let i = 0; i < savingsProfile.cacheDayOfYear.length; i += 1) {
			listToUse = [...listToUse, ...savingsProfile.cacheDayOfYear[i]];
		}

		setState({ ...state, daysLeft, percentComplete, earnings: listToUse });
	}, [savingsProfile]);

	const uniqueDates = state.earnings
		.map((item: any) => item)
		.map((item: any) => moment(item.earnDate).format('YYYY-MM-DD'))
		.map((d: any) => new Date(d))
		.sort((d1: any, d2: any) => d1 - d2);

	const dataForEachDay = () =>
		state.earnings.map((item: any) => Number(item.capital));

	return (
		<FixedSavingsDetailsStyle>
			{savingsProfile.locked && (
				<div className="locked-indicator">
					<p>Locked</p>
				</div>
			)}

			<Card style={{ position: 'relative' }}>
				{savingsProfile.isCollateral && (
					<div className="iscollateral-indicator">
						<p>This is a colleteral for loan</p>
					</div>
				)}

				<Link to={`/user-profile/${savingsProfile?.userId?._id}`}>
					@{savingsProfile?.userId?.username}
				</Link>
				<h1 className="title">
					{savingsProfile.title} ({savingsProfile.currencyId?.symbol}{' '}
					{savingsProfile.createdByAutoSave && <span>/ Autosave</span>})
				</h1>
				<div className="profile-details">
					<div>
						<p>{savingsProfile.savingsForm}</p>
						<p className="label">Savings Form</p>
					</div>
					<div>
						<p>
							<strong>
								<NativeNumber
									value={savingsProfile.cacheCapital}
									decimalPlaces={4}
								/>{' '}
								{savingsProfile.currencyId?.symbol}
							</strong>
						</p>
						<p className="label">Available Capital</p>
					</div>
					<div>
						<p style={{ color: 'var(--green-color)' }}>
							<strong>
								<NativeNumber
									value={savingsProfile.cacheProfit}
									decimalPlaces={4}
								/>{' '}
								{savingsProfile.currencyId?.symbol}
							</strong>
						</p>
						<p className="label">Total Profit</p>
					</div>
					<div>
						<p>
							<strong>
								<NativeNumber
									decimalPlaces={4}
									value={
										Number(savingsProfile.cacheCapital) +
										Number(savingsProfile.cacheProfit)
									}
								/>{' '}
								{savingsProfile.currencyId?.symbol}
							</strong>
						</p>
						<p className="label">Available Balance</p>
					</div>
					<div>
						<p>
							<strong>
								<NativeNumber
									value={savingsProfile.cacheRewardValue}
									decimalPlaces={4}
								/>{' '}
								USD
							</strong>
						</p>
						<p className="label">Reward in USD</p>
					</div>
					<div>
						<p>
							<strong>
								<NativeNumber
									value={
										Number(savingsProfile.cacheCapital) +
										Number(savingsProfile.cacheProfit) +
										Number(savingsProfile.cacheRewardValue)
									}
									decimalPlaces={4}
								/>{' '}
								USD
							</strong>
						</p>
						<p className="label">Total Available Balance in USD</p>
					</div>
					<div>
						<p>
							<strong>
								<NativeNumber
									decimalPlaces={4}
									value={ledgerBalance + Number(savingsProfile.cacheProfit)}
								/>{' '}
								{savingsProfile.currencyId?.symbol}
							</strong>
						</p>
						<p className="label">Total Balance</p>
					</div>
					<div>
						<p title={String(ledgerBalance)} style={{ color: 'navy' }}>
							<strong>
								<NativeNumber value={ledgerBalance} decimalPlaces={4} />{' '}
								{savingsProfile.currencyId?.symbol}
							</strong>
						</p>
						<p className="label">Total Deposit (Ledger)</p>
					</div>
					<div>
						<p>
							<strong>
								<NativeNumber
									value={savingsProfile.cacheRewardTokenValue}
									decimalPlaces={4}
								/>{' '}
								XEND
							</strong>
						</p>
						<p className="label">Reward Token Amount</p>
					</div>
					<div>
						<p>
							{savingsProfile.cacheProfitLastDate
								? moment(savingsProfile.cacheProfitLastDate).format(
										'MMM DD, YY / hh:mm:ss a'
								  )
								: '--'}
						</p>
						<p className="label">Cache Profit Last Date</p>
					</div>
					<div>
						<p>
							{moment(savingsProfile.startDate).format(
								'MMM DD, YY / hh:mm:ss a'
							)}
						</p>
						<p className="label">Start Date</p>
					</div>
					<div>
						<p>
							{moment(savingsProfile.endDate).format('MMM DD, YY / hh:mm:ss a')}{' '}
							{savingsProfile.endDateAdjusted && <span>(Adjusted)</span>}
						</p>
						<p className="label">End Date</p>
					</div>
					{savingsProfile.adjustedEndDate && (
						<div>
							<p>
								{moment(savingsProfile.adjustedEndDate).format(
									'MMM DD, YY / hh:mm:ss a'
								)}
							</p>
							<p className="label">Adjusted End Date</p>
						</div>
					)}
					<div>
						<p>{savingsProfile.durationDays} days</p>
						<p className="label">Duration in Days</p>
					</div>
					<div>
						<p>{savingsProfile.status}</p>
						<p className="label">Status</p>
					</div>
					<div>
						<p>{savingsProfile.payoutStatus}</p>
						<p className="label">Payout Status</p>
					</div>
					<div>
						<p>{savingsProfile.payout ? 'True' : 'False'}</p>
						<p className="label">Payout</p>
					</div>
					{savingsProfile.requestTermination && (
						<>
							<div>
								<p>
									{savingsProfile.requestTerminationDate
										? moment(savingsProfile.requestTerminationDate).format(
												'MMM DD, YY / hh:mm:ss a'
										  )
										: '--'}
								</p>
								<p className="label">Request Termination</p>
							</div>

							<div>
								<p>
									{savingsProfile.requestTerminationApprovalDate
										? moment(
												savingsProfile.requestTerminationApprovalDate
										  ).format('MMM DD, YY / hh:mm:ss a')
										: '--'}
								</p>
								<p className="label">Request Termination Approval Date</p>
							</div>
						</>
					)}
				</div>
				<MiddleSection>
					{savingsProfile?.requestTermination &&
						savingsProfile?.requestTerminationStatus === 'requested' && (
							<ApproveTerminationRequest
								fixedSavingsId={savingsProfile?._id}
								data={savingsProfile}
							/>
						)}
					{savingsProfile?.requestTermination &&
						savingsProfile?.requestTerminationStatus === 'requested' && (
							<CancelTerminationRequest fixedSavingsId={savingsProfile?._id} />
						)}

					<AdminTriggerPayoutFixedPlan profile={savingsProfile} />

					{/* {savingsProfile?.userId?._id && params?.fixedSavingsId && (
						<AdminLoan
							userId={savingsProfile?.userId?._id}
							fixedSavingsId={params?.fixedSavingsId}
						/>
					)} */}
					{savingsProfile.payoutStatus !== 'completed' && (
						<LockSavings
							id={params?.fixedSavingsId}
							text="fixed"
							url="admin/compliance/lock-fixed-savings"
						/>
					)}
					{savingsProfile.payoutStatus !== 'completed' && (
						<RevertSavings details={savingsProfile} />
					)}
				</MiddleSection>
				<Divider />

				<div style={{ display: 'flex' }}>
					<div>
						<Progress type="circle" percent={state.percentComplete} />
						<p>
							Percent completion. <strong>{state.daysLeft} days left</strong>.
						</p>
					</div>
					<div style={{ flex: 1 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<p>
								<strong>Plan Profits (CacheDayOfYear)</strong>
							</p>
							<ViewPlanProfitTable data={state.earnings} />
						</div>
						<Line
							options={{
								responsive: true,
								aspectRatio: 2 / 0.5,
							}}
							data={{
								labels: uniqueDates.map((item: any) =>
									moment(item).format('MMM DD, YY')
								),
								datasets: [
									{
										label: 'Plan Yeilds',
										data: dataForEachDay(),
										tension: 0.3,
										borderColor: '#1a846d',
										fill: false,
									},
								],
							}}
						/>
					</div>
				</div>

				<Divider />

				<p>
					<strong>Savings Items</strong>
				</p>
				{savingsItems.map((savingsItem: any) => (
					<FixedSavingsProfileAssociatedSavingsItems
						key={savingsItem._id}
						item={savingsItem}
					/>
				))}
				<Divider />

				<p>
					<strong>Associated Transaction Statements</strong>
				</p>
				{transactionStatements.map((transaction: any) => (
					<AssociatedFixedSavingsTransactionItem
						key={transaction._id}
						transaction={transaction}
					/>
				))}
			</Card>
		</FixedSavingsDetailsStyle>
	);
};

export default SingleFixedSavings;
