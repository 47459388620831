import { Button, Input, Space } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import login from '../methods/auth';
import { chooseEnvironment } from '../methods/baseuri';
import { PageHeader } from '../theme/theme';
import NewDeviceOtp from './NewDeviceOtp';
import Verify2FA from './Verify2fa';

const Style = styled.div`
	height: 100%;

	& .with-animation {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;

		& > *:first-child {
			grid-column: 1 / -1;
			grid-row: 1 / -1;
			background-color: #f2f2f2;
			// background-color: rgba(5, 34, 90, 0.5);
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}

		& > .main-action {
			z-index: 5;
			padding: 20px;
			grid-column: 1 / -1;
			grid-row: 2 / span 1;
			background-color: rgba(255, 255, 255, 0.7);
		}

		@media (min-width: 768px) {
			max-width: 1200px;
			margin: auto;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr 1fr 1fr;

			& > *:first-child {
				grid-row: 1 / -1;
				align-items: flex-end;
			}

			& > .main-action {
				grid-row: 2 / span 1;
				grid-column: 2 / span 1;
			}
		}
	}

	& form {
		background-color: white;
		padding: 20px;
		margin-bottom: 20px;
	}

	& .forgot {
		text-decoration: underline;
		display: block;
		width: max-content;
		margin: auto;
		font-size: 16px;
	}
`;

const AuthLogin = () => {
	const navigate = useNavigate();

	const [newDeviceAuth, setNewDeviceAuth] = useState(false);
	const [visible2FAModal, set2FAModalVisible] = useState(false);
	const [auth, setAuth] = useState({
		email: '',
		password: '',
		code: '',
		loading: false,
		newDevice: false,
	});
	const changeLoading = (value: boolean) => {
		setAuth({ ...auth, loading: value });
	};

	const loginAction = async () => {
		const data = { email: auth.email, password: auth.password };
		await login({
			data,
			setLoading: changeLoading,
			openNewDeviceModal: () => {
				setNewDeviceAuth(true);
			},
			open2FAModal: () => {
				set2FAModalVisible(true);
			},
			navigate,
		});
	};

	return (
		<Style>
			<NewDeviceOtp
				email={auth.email}
				password={auth.password}
				show={newDeviceAuth}
				open2FAModal={() => {
					set2FAModalVisible(true);
				}}
				turnOff={() => setNewDeviceAuth(false)}
			/>

			<Verify2FA show={visible2FAModal} />

			<div className="with-animation">
				<div>{/** <Lottie animationData={badguy} /> */}</div>
				<div className="main-action">
					<PageHeader style={{ textAlign: 'center' }}>Admin Auth</PageHeader>

					<form
						onSubmit={(e) => {
							e.preventDefault();
							loginAction();
						}}
					>
						<Space size={15} direction="vertical" style={{ width: '100%' }}>
							<Input
								placeholder="Email"
								type="email"
								size="large"
								value={auth.email}
								onChange={(e) => setAuth({ ...auth, email: e.target.value })}
							/>
							<Input.Password
								placeholder="Password"
								size="large"
								value={auth.password}
								onChange={(e) => setAuth({ ...auth, password: e.target.value })}
							/>
							<Button
								type="primary"
								loading={auth.loading}
								htmlType="submit"
								block
								size="large"
							>
								Enter
							</Button>
						</Space>
					</form>
					{/* <Link className="forgot" to="/reset-password"> */}
					{/* 	Forgot your password? */}
					{/* </Link> */}
					<a
						target="_blank"
						className="forgot"
						href={
							chooseEnvironment().name === 'Live'
								? 'https://pro-dashboard.xend.finance'
								: 'https://lab-admin.xend.finance'
						}
						rel="noreferrer"
					>
						Dashboard
					</a>
				</div>
			</div>
		</Style>
	);
};

export default AuthLogin;
