import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const getTransactions = createAsyncThunk(
	'transactions/getTransactions',
	async (params: any) => {
		const res = await baseuri.get('/admin/statements/transactions', { params });
		return res.data.data;
	}
);

export const getTransactionsReport = createAsyncThunk(
	'transactions/getTransactionsReport',
	async (params: any) => {
		const res = await baseuri.get('/report/statement/transaction', {
			params: { ...params, sort: 'desc' },
		});
		return res.data.data;
	}
);

export const getTransactionsOverview = createAsyncThunk(
	'transactions/getTransactionsOverview',
	async (params: any) => {
		const res = await baseuri.get('/report/statement/transaction/overview', {
			params,
		});
		return res.data.data;
	}
);

export const getOutDebitTransactions = createAsyncThunk(
	'transactions/getOutDebitTransactions',
	async (params: any) => {
		const res = await baseuri.get('/report/wallet/withdrawal', {
			params: { ...params, sort: 'desc' },
		});
		return res.data.data;
	}
);

export const getInCreditTransactions = createAsyncThunk(
	'transactions/getInCreditTransactions',
	async (params: any) => {
		const res = await baseuri.get('/report/wallet/deposits', {
			params: { ...params, sort: 'desc' },
		});
		return res.data.data;
	}
);

const transactionsRx = createSlice({
	name: 'transactions',
	initialState: {
		itemsList: [],
		paginator: {
			itemCount: 0,
			perPage: 20,
			currentPage: 1,
		},
		outDebit: {
			itemsList: [],
			result: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		inCredit: {
			itemsList: [],
			result: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		report: {
			itemsList: [],
			result: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
		overview: {
			itemsList: [],
			result: [],
			paginator: {
				itemCount: 0,
				perPage: 20,
				currentPage: 1,
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getTransactions.fulfilled, (store, action) => {
				let state = store;
				state = { ...state, ...action.payload };
				return state;
			})
			.addCase(getTransactionsReport.fulfilled, (store, action) => {
				const state = store;
				state.report = action.payload;
			})
			.addCase(getTransactionsOverview.fulfilled, (store, action) => {
				const state = store;
				state.overview = action.payload;
			})
			.addCase(getOutDebitTransactions.fulfilled, (store, action) => {
				const state = store;
				state.outDebit = action.payload;
			})
			.addCase(getInCreditTransactions.fulfilled, (store, action) => {
				const state = store;
				state.inCredit = action.payload;
			}),
});

export default transactionsRx.reducer;
