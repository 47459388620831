import { Card, Divider } from 'antd';
import { useEffect } from 'react';
import SingleCurrency from './SingleCurrency';
import AddCurrency from './AddCurrency';
import {
	getContractAddress,
	getCurrencies,
	getDisabledCurrencyWithdrawals,
} from '../../methods/redux/reducers/currency';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';

const SupportedCurrencies = () => {
	const dispatch = useAppDispatch();
	const { currencies } = useAppSelector((store: any) => store.currencies);

	useEffect(() => {
		dispatch(getCurrencies({}));
		dispatch(getContractAddress({}));
		dispatch(getDisabledCurrencyWithdrawals({ isDisabled: 'true' }));
	}, []);

	return (
		<Card>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
				}}
			>
				<h1>Supported Currencies</h1>
				<AddCurrency />
			</div>
			<Divider />
			{currencies.map((item: any) => (
				<SingleCurrency key={item._id} data={item} />
			))}
		</Card>
	);
};

export default SupportedCurrencies;
