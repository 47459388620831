import { Divider, Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../../theme/theme';

const SettingsItemStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const MadWallet = () => {
	const items = [
		{
			title: 'Mad Wallet Report',
			desc: 'This is were all the reports with regards to mad wallet can be viewed',
			link: `/mad-wallet/reports`,
			icon: 'menu_book',
		},
	];

	return (
		<section>
			<PageHeader>Mad Wallet</PageHeader>

			<Input.Search placeholder="Search Users Settings" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<SettingsItemStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</SettingsItemStyle>
				))}
		</section>
	);
};

export default MadWallet;
