import { Divider, Modal, Button, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../methods/redux/hooks';
import {
	toggleDisableWithdrawal,
	updateContractAddress,
} from '../../methods/redux/reducers/currency';

const SingleContractAddressStyle = styled.section`
	& .address {
		display: grid;
		margin-bottom: 30px;
	}

	& .action-buttons {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
	}
`;

interface ISingleContractAddress {
	blockchainIcon: any;
	blockchainAbbreviation: any;
	blockchainId: any;
	currencyId: any;
}

const SingleContractAddress = ({
	blockchainId,
	blockchainAbbreviation,
	blockchainIcon,
	currencyId,
}: ISingleContractAddress) => {
	const dispatch = useAppDispatch();
	const { contracts, disabledCurrencyWithdrawal } = useAppSelector(
		(store) => store.currencies
	);

	const [state, setState] = useState({
		blockchainId: '',
		contractAddress: '',
		decimals: '',
		readOnly: true,
	});

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const filterContracts = () => {
		const x: any = contracts.filter((item: any) => {
			const sameCurrency = item.currencyId?._id === currencyId;
			const sameBlockchainNetwork =
				item.blockchainNetworkId?._id === blockchainId;
			return sameCurrency && sameBlockchainNetwork;
		});

		if (x.length > 0) {
			const d = x[0];
			setState({ ...d, ...state });
		}
	};

	const handleActionButton = () => {
		if (state.readOnly) {
			setState({ ...state, readOnly: false });
		} else {
			const data = {
				currencyId,
				blockchainNetworkId: blockchainId,
				contractAddress: state.contractAddress,
				decimals: state.decimals,
			};
			dispatch(updateContractAddress(data));
			setState({ ...state, readOnly: true });
		}
	};

	const handleDisableToggle = () => {
		dispatch(
			toggleDisableWithdrawal({ currencyId, blockchainNetworkId: blockchainId })
		);
	};

	useEffect(() => {
		filterContracts();
	}, [state.blockchainId, contracts]);

	useEffect(() => {
		setState({ ...state, blockchainId });
	}, []);

	const checkForDisabledWithdrawal = () => {
		const record = disabledCurrencyWithdrawal.find(
			(x: any) =>
				x.isDisabled &&
				x.currencyId?._id === currencyId &&
				x.blockchainNetworkId?._id === blockchainId
		);
		return !!record;
	};

	const decimalOptions = [
		{ name: 'Wei (1)', value: 'wei' },
		{ name: 'Mwei (6)', value: 'mwei' },
		{ name: 'Gwei (9)', value: 'gwei' },
		{ name: 'Ether (18)', value: 'ether' },
	];

	return (
		<SingleContractAddressStyle>
			<div className="address">
				<div style={{ display: 'flex', marginBottom: 5 }}>
					<img
						alt=""
						src={blockchainIcon}
						style={{ width: 24, height: 24, marginRight: 10 }}
					/>
					<p>{blockchainAbbreviation}</p>
				</div>
				<br />
				<p>Contract Address</p>
				<div>
					<Input
						name="contractAddress"
						style={{ width: '100%', marginBottom: 5 }}
						size="large"
						placeholder="ContractAddress"
						value={state.contractAddress}
						onChange={handleInput}
						readOnly={state.readOnly}
					/>
				</div>
				<br />
				<p>Decimals (Eg: ether, mwei)</p>
				<div>
					<Select
						size="large"
						style={{ width: '100%', marginBottom: 5 }}
						onChange={(val) => setState({ ...state, decimals: val })}
					>
						<Select.Option value="">--</Select.Option>
						{decimalOptions.map((dec) => (
							<Select.Option key={dec.value} value={dec.value}>
								{dec.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<div className="action-buttons">
					<div>
						<Button
							block
							type={state.readOnly ? 'default' : 'primary'}
							size="large"
							onClick={handleActionButton}
						>
							{state.readOnly ? 'Enable Edit' : 'Update'}
						</Button>
					</div>
					<div>
						{checkForDisabledWithdrawal() ? (
							<Button
								onClick={handleDisableToggle}
								type="primary"
								size="large"
								block
							>
								Enable Withdrawal
							</Button>
						) : (
							<Button onClick={handleDisableToggle} block size="large" danger>
								Disable Withdrawal for {blockchainAbbreviation}
							</Button>
						)}
					</div>
				</div>
			</div>
			<Divider />
		</SingleContractAddressStyle>
	);
};

interface ICurrencyContractAddress {
	currencyName: string;
	currencyId: any;
}
const CurrencyContractAddresses = ({
	currencyName,
	currencyId,
}: ICurrencyContractAddress) => {
	const [visible, setVisible] = useState(false);
	const { blockchains } = useAppSelector((store) => store.currencies);

	return (
		<div>
			<Button type="text" onClick={() => setVisible(true)}>
				Edit
			</Button>

			<Modal
				footer={null}
				title={`${currencyName} Contract Addresses`}
				open={visible}
				onCancel={() => setVisible(false)}
			>
				<p>
					You can add or edit the contract addresses for this currency / token
					by enabling edit button and clicking update.
				</p>

				<br />

				{blockchains.map((item: any) => (
					<SingleContractAddress
						key={item._id}
						blockchainId={item._id}
						blockchainIcon={item.iconUrl}
						blockchainAbbreviation={item.abbreviation}
						currencyId={currencyId}
					/>
				))}
			</Modal>
		</div>
	);
};

export default CurrencyContractAddresses;
