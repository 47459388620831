import moment from 'moment';
import styled from 'styled-components';
import { commas } from '../../methods/utils/general-utils';

interface IColumnItem {
	data: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;

	& .amount {
		display: flex;
		font-weight: bold;
		font-size: 16px;
		& img {
			margin-right: 7px;
			width: 20px;
			height: 20px;
		}
	}

	& .details {
		border-top: 1px dashed #ccc;
		margin-top: 12px;
		padding: 15px 0;

		& > * {
			display: inline-block;
			min-width: 200px;
			margin-bottom: 7px;
			padding: 5px;
		}

		& .label {
			font-size: 13px;
			color: #808080;
			font-style: italic;
		}
	}
`;

const ColumnOldGasFee = ({ data }: IColumnItem) => (
	<Style>
		<div className="details">
			<div>
				<p>{data.action || '--'}</p>
				<p className="label">Action</p>
			</div>
			<div>
				<p>{commas(data.amount)} </p>
				<p className="label">Amount</p>
			</div>
			<div>
				<p>{commas(data.gasCostUSD)} USD</p>
				<p className="label">Gas Used</p>
			</div>
			<div>
				<p>{data.protocolName || '--'}</p>
				<p className="label">Protocol Name</p>
			</div>
			{/* <div>
				<p>
					<Link title="View Profile" to={`/user-profile/${data.userId?._id}`}>
						<p>@{data.userId?.username}</p>
					</Link>
				</p>
				<p className="label">Customer</p>
			</div> */}
			{/* <div>
				<a
					href={`https://testnet.bscscan/tx/${data.blockchainTransactionHash}`}
					target="_blank"
					rel="noreferrer"
				>
					Hash
				</a>
				<p className="label">Transaction Hash</p>
			</div> */}
			<div>
				<p>
					{data.createdAt
						? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Date Created</p>
			</div>
			<div>
				<p>
					{data.updatedAt
						? moment(data.updatedAt).format('MMM DD, YY / hh:mm:ss a')
						: '--'}
				</p>
				<p className="label">Last Updated</p>
			</div>
		</div>
	</Style>
);

export default ColumnOldGasFee;
