import baseuri from '../baseuri';

const tokenName = 'xfma-token';

export const saveToken = (token: any) => {
	localStorage.setItem(tokenName, JSON.stringify(token));
};

export const removeToken = () => {
	localStorage.removeItem(tokenName);
};

export const retrieveToken = () => {
	const token = localStorage.getItem(tokenName);

	if (!token) {
		return { token: '', accessSecret: null, user: null };
	}

	const x = JSON.parse(token);

	return {
		token: x.accessToken,
		accessSecret: x.accessSecret,
		user: x.user,
	};
};

export const getEmailToken = async () => {
	// const token = localStorage.getItem('emailToken');
	// if (!token) {
	// 	let n;
	try {
		const res: any = await baseuri.get('/admin/robot/auth/reauthenticate');

		if (res.status === 200) {
			// setRobotToken(res.data.data.token);
			localStorage.setItem('emailToken', res.data.data.token);
			// n = res.data.data.token;
		}
	} catch (error) {
		console.info(error);
	}
};

export const isLoggedIn = () => {
	const token = localStorage.getItem(tokenName);
	if (token !== null) {
		const t = JSON.parse(localStorage.getItem(tokenName) || '{}');
		if (t.accessSecret && t.user?.enabledTwoFactor) {
			return {
				data: t,
				response: true,
			};
		}
	}
	return { data: null, response: false };
};

export const isWalletConnectionAllowed = () => {
	const walletConnectionToken = localStorage.getItem(
		`${tokenName}-wallet-conn`
	);
	if (!walletConnectionToken) return false;
	return walletConnectionToken === 'true';
};

export const updateAllowWalletConnection = (update: 'true' | 'false') => {
	localStorage.setItem(`${tokenName}-wallet-conn`, update);
};
