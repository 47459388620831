import { Button, message, Modal } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
`;
interface IDeletionProcess {
	details: any;
}
const DeletionProcess: FC<IDeletionProcess> = ({ details }) => {
	const remove = async (userid: any) => {
		try {
			// setloading(true);
			const res = await baseuri.post(
				`admin/users/activate-account-delete-process`,
				{
					userid,
				}
			);
			if (res.status === 200) {
				// setloading(false);
				message.success('deletion process activated Successfully');
			}
		} catch (e) {
			// setloading(false);
			message.error('Could not complete operation');
		}
	};
	const confirm = () => {
		Modal.confirm({
			title:
				'Are you sure you want to Activate this user account deletion process?',
			onOk() {
				remove(details?.profile?._id);
			},
		});
	};
	return (
		<DeleteSection>
			<Button
				type="primary"
				onClick={confirm}
				disabled={details?.profile?.accountDeletionRequest}
			>
				Activate
			</Button>
		</DeleteSection>
	);
};

export default DeletionProcess;
