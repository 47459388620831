import { Card, Input, Divider } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import baseuri from '../../methods/baseuri';

const XendBridgeSellRequest = () => {
	const [reference, setReference] = useState('');

	const [data, setData] = useState<Array<any>>([]);

	const getData = async () => {
		try {
			const res = await baseuri.get(
				'/admin/p2p/search-xend-bridge-sell-request',
				{
					params: { reference },
				}
			);
			setData(res.data.data);
		} catch (e: any) {
			console.info(e);
		}
	};

	return (
		<Card>
			<h1>Search XendBridge Sell Order Request</h1>

			<br />

			<Input.Search
				value={reference}
				onSearch={getData}
				onChange={(e) => setReference(e.target.value)}
			/>

			<Divider />

			{data.map((record: any) => (
				<div key={record._id}>
					<p>XendBridge Reference</p>
					<p>{record.orderReference}</p>
					<br />
					<p>Amount</p>
					<p>{record.amount}</p>

					<br />
					<p>Destination Address</p>
					<p>{record.destinationAddress}</p>

					<br />
					<p>User Email</p>
					<p>{record.userEmail}</p>

					<br />
					<p>Created</p>
					<p>{moment(record.createdAt).format('MMM DD, YY / hh:mm:ss a')}</p>
				</div>
			))}
		</Card>
	);
};

export default XendBridgeSellRequest;
