import { message, Modal, Tag } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;
	cursor: pointer;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IStart {
	bulkSavingsId: string;
}
const Start: FC<IStart> = ({ bulkSavingsId }) => {
	const start = async () => {
		try {
			const res: any = await baseuri.post(
				`admin/bulk-savings/stable-currency/start`,
				{
					bulkSavingsId,
				}
			);
			if (res.status === 200) {
				message.success('Started');
			} else {
				message.info(res?.data?.error_details);
			}
		} catch (e: any) {
			message.error(e.response.data.error_details);
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Start this Bulk Savings',
			onOk() {
				start();
			},
		});
	};
	return (
		<DeleteSection>
			<Tag color="green" onClick={confirm}>
				Start
			</Tag>
		</DeleteSection>
	);
};

export default Start;
