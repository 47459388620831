import { Card, Divider, message, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TableHeaderSection from '../../component/TableHeaderSection';
import emailBaseUrl from '../../methods/emailBaseUrl';
import { useAppDispatch } from '../../methods/redux/hooks';
import { getUserProfileById } from '../../methods/redux/reducers/user-profile';
import { retrieveToken } from '../../methods/utils/auth';
import { commas, truncString } from '../../methods/utils/general-utils';
import { MyTable } from '../../users/users-list/UsersList';
import DelNewsComp from './DelNewsComp';
import EditNewsletter from './EditNewsletter';
import EmailHeaderSection from './EmailHeaderSection';
import SendNewsLetter from './SendNewsLetter';
import SendTestNewsLetter from './SendTestNewsLetter';
import TerminateNewsLetter from './TerminateNewsLetter';
import UpdateNewsLetterStatus from './UpdateNewsLetterStatus';
import { retrieveMailingLists } from '../../methods/redux/reducers/alerts';

const NewsLetterPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;

const LastSection = styled.div`
	display: flex;
	gap: 0.5em;
`;

const EmailNewsletter = () => {
	const dispatch = useAppDispatch();

	const [refresh, setRefresh] = useState('');
	const [loading, setLoading] = useState(true);
	const [allNewsLetters, setAllNewsLetters] = useState({
		itemsList: [],
		paginator: {} as any,
	});

	const handleGetNewsLetter = async (page = 1, pageSize = 10) => {
		setLoading(true);
		let res: any;

		try {
			res = await emailBaseUrl.get(`/v1/newsletters`, {
				params: {
					pageId: page,
					perPage: pageSize,
				},
			});

			if (res.status === 200) {
				setAllNewsLetters(res?.data?.data);
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error?.response?.data?.data?.message);
		}
	};

	useEffect(() => {
		handleGetNewsLetter();
	}, [refresh]);

	useEffect(() => {
		const auth = retrieveToken();
		dispatch(getUserProfileById(auth.user?._id));
		dispatch(retrieveMailingLists());
	}, []);

	const columns = [
		{
			title: 'Subject',
			key: 'subject',
			render: (text: any) => (
				<Link to={`/alerts/email-newsletter/${text?._id}`}>
					{' '}
					<strong>{truncString(text?.subject, 20)}</strong>
				</Link>
			),
		},
		{
			title: 'Mail List',
			key: 'maillist',
			render: (text: any) => <strong>{text?.maillist}</strong>,
		},
		{
			title: 'Delivery Type',
			key: 'deliveryType',
			render: (text: any) => <strong>{text?.deliveryType}</strong>,
		},
		{
			title: 'Status',
			key: 'deliveryStatus',
			render: (text: any) => <strong>{text?.deliveryStatus}</strong>,
		},
		{
			title: 'Total Sent',
			key: 'totalSent',
			render: (text: any) => <strong>{commas(text?.totalSent, 2)}</strong>,
		},
		{
			title: 'Total Failed',
			key: 'totalFailed',
			render: (text: any) => <strong>{commas(text?.totalFailed, 2)}</strong>,
		},
		{
			title: 'Delivery Date',
			dataIndex: 'deliveryDate',
			key: 'deliveryDate',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Sent By',
			key: 'creatorEmail',
			render: (text: any) => <strong>{text?.creatorEmail}</strong>,
		},
		{
			title: '',
			key: 'subject',
			render: (text: any) => (
				<p style={{ width: '100px' }}>
					{text?.deliveryStatus === 'pending' ||
					text?.deliveryStatus === 'paused' ? (
						<TerminateNewsLetter newsletter={text} setRefresh={setRefresh} />
					) : (
						''
					)}
					{text?.deliveryStatus === 'sent' && (
						<DelNewsComp newsletter={text} setRefresh={setRefresh} />
					)}
					{text?.deliveryStatus === 'saved' && (
						<EditNewsletter newsLetter={text} setRefresh={setRefresh} />
					)}
				</p>
			),
		},
		{
			title: '',
			key: '345334',
			render: (text: any) => (
				<LastSection style={{ width: '100px' }}>
					{text?.deliveryStatus === 'pending' ||
					text?.deliveryStatus === 'paused'
						? text?.isPublished === true && (
								<UpdateNewsLetterStatus
									newsletter={text}
									text={text?.deliveryStatus === 'paused' ? 'Resume' : 'Pause'}
									setRefresh={setRefresh}
								/>
						  )
						: ''}
					{text?.deliveryStatus === 'pending' &&
						text?.isPublished === false && <SendNewsLetter newsletter={text} />}
					{text?.deliveryStatus === 'saved' && (
						<>
							{' '}
							<SendTestNewsLetter newsletter={text} typeOfEmail="text" />
							<EditNewsletter newsLetter={text} setRefresh={setRefresh} />{' '}
						</>
					)}
				</LastSection>
			),
		},
	];

	return (
		<NewsLetterPage>
			<Card>
				<h1>Email Newsletter</h1>

				<Divider />

				<EmailHeaderSection setRefresh={setRefresh} />

				<TableHeaderSection
					tableName="Newsletters"
					currentPage={allNewsLetters?.paginator?.currentPage || 0}
					totalRecords={allNewsLetters?.paginator?.totalRecords || 0}
					// downloadURL="https://apiprostaging.xend.finance/api/admin/get-usertype-referals"
				/>

				<MyTable
					loading={loading}
					columns={columns}
					dataSource={allNewsLetters.itemsList}
					pagination={false}
				/>

				<br />

				<Pagination
					onChange={(page, pageSize) => handleGetNewsLetter(page, pageSize)}
					disabled={loading}
					pageSize={allNewsLetters?.paginator?.perPage || 0}
					current={allNewsLetters?.paginator?.currentPage || 0}
					total={allNewsLetters?.paginator?.itemCount || 0}
				/>
			</Card>
		</NewsLetterPage>
	);
};

export default EmailNewsletter;
