import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import emailBaseUrl from '../../methods/emailBaseUrl';

const SingleNewsletter = () => {
	const { id } = useParams();
	const [data, setData] = useState({
		loading: false,
	});
	const handleGetNewsLetter = async () => {
		setData({ ...data, loading: true });
		let res: any;
		try {
			res = await emailBaseUrl.get(`/v1/newsletters/${id}`);
			if (res.status === 200) {
				setData({ ...res.data.data, loading: false });
			}
			setData({ ...data, loading: false });
		} catch (error: any) {
			setData({ ...data, loading: false });
			message.error(error?.response?.data?.data?.message);
		}
	};
	useEffect(() => {
		handleGetNewsLetter();
	}, []);
	return <div>SingleNewsletter</div>;
};

export default SingleNewsletter;
