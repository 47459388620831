import { Card } from 'antd';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Logout from '../../user/Logout';
import AdminWalletBalances from './WalletBalances';
import Settings from './ProfileSettings';
import EditProfile from './EditProfile';
import { retrieveToken } from '../../methods/utils/auth';
import { defaultImage } from '../../methods/utils/general-utils';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getAdminWalletBalance } from '../../methods/redux/reducers/general';
import AdminNotifications from './Notifications';
import { getBlockchainNetworks } from '../../methods/redux/reducers/currency';

const Style = styled.div`
	& .admin {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		& > div:first-child {
			display: flex;
		}
	}

	& .profile-pic {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		margin-right: 10px;
	}
`;

const MyProfile = () => {
	const dispatch = useAppDispatch();
	const { cryptoBalances, fiatBalances, walletAddress } = useAppSelector(
		(store) => store.general
	);

	const [state, setState] = useState({
		_id: '',
		email: '',
		fullName: '',
		profileUrl: null,
	});

	useEffect(() => {
		const auth = retrieveToken();
		setState({ ...state, ...auth.user });
		dispatch(getBlockchainNetworks());
	}, []);

	useEffect(() => {
		if (state._id && state._id.length > 0) {
			dispatch(getAdminWalletBalance(state._id));
		}
	}, [state._id]);

	return (
		<Style>
			<Card>
				<div className="admin">
					<div>
						<img
							className="profile-pic"
							alt=""
							src={state.profileUrl || defaultImage}
						/>
						<div>
							<p className="name">{state.fullName}</p>
							<p>{state.email}</p>
						</div>
					</div>
					<div>
						<EditProfile data={state} />
					</div>
				</div>
			</Card>

			<br />

			<AdminNotifications />

			<br />

			<Card>
				<AdminWalletBalances
					balances={cryptoBalances.concat(fiatBalances)}
					walletAddress={walletAddress}
				/>
			</Card>

			<br />

			<Settings />

			<br />

			<Card>
				<Logout />
			</Card>
		</Style>
	);
};
export default MyProfile;
