import { message } from 'antd';
import baseuri from './baseuri';
import { removeToken, retrieveToken, saveToken } from './utils/auth';

interface Ilogin {
	data: any;
	setLoading?: Function;
	openNewDeviceModal?: Function;
	open2FAModal?: Function;
	navigate?: Function;
}

export const two2FALogin = async (code: string, navigate: Function) => {
	try {
		const res = await baseuri.post('/auth/2fa/login/user', { code });
		const { data } = res.data;
		saveToken(data);
		navigate('/');
	} catch (e: any) {
		const msg = e.response.data?.message || 'Something went wrong';
		message.error(msg);
	}
};

const login = async ({
	data,
	setLoading,
	openNewDeviceModal,
	open2FAModal,
	navigate,
}: Ilogin) => {
	if (setLoading !== undefined) setLoading(true);
	try {
		const res = await baseuri.post('/auth/login/admin', data);

		const authData = res.data.data;

		if (authData.accessSecret === null) {
			if (open2FAModal) {
				saveToken(authData);
				open2FAModal();
			}
		}

		const is2FAEnabled = authData?.user?.enabledTwoFactor;

		if (is2FAEnabled === false && navigate) {
			saveToken(authData);
			navigate('/two-factor-setup');
		}

		sessionStorage.setItem('ds-login', JSON.stringify(data));

		if (authData.accessSecret && navigate) {
			saveToken(authData);
			navigate('/core-modules');
		}
	} catch (e: any) {
		// 1. if the admin is signing into the admin dashboard from a different device.
		const msg = e.response.data?.message || 'Something went wrong';
		const action = e.response?.data.action;

		if (action && action === 'load_otp_input') {
			if (openNewDeviceModal !== undefined) {
				openNewDeviceModal();
			}
		} else {
			message.error(msg);
		}
	}
	if (setLoading !== undefined) setLoading(false);
};

export const refreshToken = async () => {
	try {
		const loginDetailsText = sessionStorage.getItem('ds-login');
		if (!loginDetailsText) {
			throw new Error('No login details saved');
		}
		const loginDetails = JSON.parse(loginDetailsText);
		const tokenDetails = retrieveToken();

		const data = {
			email: loginDetails?.email,
			password: loginDetails?.password,
			accessSecret: tokenDetails.accessSecret,
		};

		const res = await baseuri.post('/auth/login/auto', data);

		const { accessSecret, accessToken } = res.data.data;

		saveToken({
			...tokenDetails,
			accessSecret,
			accessToken,
		});
	} catch (e) {
		removeToken();
		// message.error('You have been logged out');
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	}
};

export const logout = async (navigate: Function) => {
	try {
		removeToken();
		await baseuri.post('/auth/logout');
		// message.success('Logout Successfully');
		navigate('/login');
	} catch (e: any) {
		navigate('/login');
	}
};

export default login;
