import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { releaseSwapToPending } from '../../methods/redux/reducers/swap';

interface IUnassignRequest {
	request: any;
	adminId: any;
}

const UnAssignSwapRequest = ({ request, adminId }: IUnassignRequest) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const release = () => {
		dispatch(releaseSwapToPending(request._id));
		setVisible(false);
	};
	return (
		<>
			{request.processedByUserId?._id === adminId &&
				request.swapStatus === 'processing' && (
					<Button onClick={() => setVisible(true)}>Release Swap Request</Button>
				)}

			<Modal
				onOk={release}
				okText="Release Request"
				open={visible}
				onCancel={() => setVisible(false)}
				title="Release Swap Request"
			>
				<p>Release swap request for another admin to fulfil.</p>
			</Modal>
		</>
	);
};

export default UnAssignSwapRequest;
