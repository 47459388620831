import { Divider, Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from '../theme/theme';

const ExtrasStyle = styled(Link)`
	padding: 20px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 400ms ease;

	& .desc {
		width: 75%;
		font-size: 15px;
		color: #969696;
		margin-top: 10px;
	}

	& .icon {
		width: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		margin-right: 10px;
	}

	&:hover {
		background-color: #fafafa;
	}
`;

const ExtrasPage = () => {
	const items = [
		{
			title: 'Games',
			desc: 'Manage games, game actions and participation on Xend Finance.',
			link: '/extras/games',
			icon: 'gamepad',
		},
		// {
		// 	title: 'Highest Referrals',
		// 	desc: '',
		// 	// link: '/extras/top-referrals',
		// 	link: '/extras',
		// 	icon: 'leaderboard',
		// },
		{
			title: 'Monthly Savers',
			desc: 'List of users that saved on the last week of a month',
			link: '/extras/monthly-savers',
			icon: 'calendar_view_month',
		},
		{
			title: 'Daily Savers',
			desc: 'List of users that saved on the last week of a month',
			link: '/extras/daily-savers',
			icon: 'date_range',
		},
		{
			title: 'Weekly Savers',
			desc: 'List of users that saved on the last week of a month',
			link: '/extras/weekly-savers',
			icon: 'event_repeat',
		},
		{
			title: 'TGIF Savers',
			desc: 'Different settings for the smooth running of the app.',
			link: '/extras/tgif',
			icon: 'today',
		},
	];

	return (
		<section>
			<PageHeader>Admin Extras</PageHeader>

			<Input.Search placeholder="Search Settings" />

			<Divider />

			<p>Frequently visited</p>

			<Divider />

			{items
				.sort((prev, next) => prev.title.localeCompare(next.title))
				.map((item) => (
					<ExtrasStyle key={item.title} to={item.link}>
						<div style={{ display: 'flex' }}>
							<div className="icon">
								<span className="material-icons">{item.icon}</span>
							</div>
							<div>
								<p>
									<strong>{item.title}</strong>
								</p>
								<p className="desc">{item.desc}</p>
							</div>
						</div>
						<div className="arrow">
							<span className="material-icons">keyboard_arrow_right</span>
						</div>
					</ExtrasStyle>
				))}
		</section>
	);
};

export default ExtrasPage;
