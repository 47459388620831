import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IColumnItems {
	data: any;
}

const Style = styled.div`
	all: unset;
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 15px;
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;

	& > * {
		display: inline-block;
		margin-right: 15px;

		& .label {
			color: #aaa;
			font-style: italic;
			font-size: 10px;
		}
		& .value {
			color: #aaa;
			font-style: italic;
		}
	}
`;

const ColumnItem = ({ data }: IColumnItems) => (
	<Style>
		<div>
			<p>
				{data.createdAt
					? moment(data.createdAt).format('MMM DD, YY / hh:mm:ss a')
					: '--'}
			</p>
			<p className="label">Request Date</p>
		</div>
		<div>
			<p>
				<Link title="View Profile" to={`/user-profile/${data?.userId?._id}`}>
					<p>{data?.fullName || data?.email}</p>
					<p>{data?.userId?.username && `@${data?.userId?.username}`}</p>
				</Link>
			</p>
			<p className="label">User</p>
		</div>
		<div>
			<p>{data?.reasonForDisable || '---'}</p>
			<p className="label">Reset Reason</p>
		</div>
		<div>
			<Link to={`/maintenance/single-2fa/${data?._id}`}>Manage</Link>
		</div>
	</Style>
);

export default ColumnItem;
