import { Button } from 'antd';
import { useState } from 'react';
import { manual2FaResetByAdmin } from '../../methods/redux/reducers/maintenance';

interface IAdminManualTwoFactorReset {
	user: any;
}
const AdminManualTwoFactorReset = ({ user }: IAdminManualTwoFactorReset) => {
	const [loading, setLoading] = useState(false);

	const manualyReset = async () => {
		setLoading(true);
		await manual2FaResetByAdmin(user._id);
		setLoading(false);
	};

	if (user.enabledTwoFactor) {
		return (
			<Button onClick={manualyReset} loading={loading} disabled={loading}>
				Manually Reset 2FA
			</Button>
		);
	}

	return null;
};

export default AdminManualTwoFactorReset;
