import moment from 'moment';
import { useState } from 'react';
import { Button, Modal } from 'antd';

interface IViewPaymentMethods {
	bankDetails: any[];
}
const ViewPaymentMethods = ({ bankDetails }: IViewPaymentMethods) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Button onClick={() => setVisible(true)} type="primary" size="small">
				View Payment Methods
			</Button>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Bank Details"
				footer={null}
			>
				{bankDetails.map((info: any) => (
					<div key={info._id}>
						<p>{info.accountName}</p>
						<p>{info.accountNumber}</p>
						<p>{info.bankName}</p>
						<p>{info.paymentMethod}</p>
						<p>{moment(info.updatedAt).format('MMM DD, YY / hh:mm:ss a')}</p>
					</div>
				))}
			</Modal>
		</>
	);
};

export default ViewPaymentMethods;
