import styled from 'styled-components';
import { commas, defaultImage } from '../../methods/utils/general-utils';

const Style = styled.div`
	display: grid;
	grid-template-columns: 1fr;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	& .item {
		border: 1px solid #d5d3d3;
		padding: 20px;
		margin-right: 10px;
		margin-bottom: 10px;

		& .amount {
			font-size: 18px;
		}

		& .details {
			& > * {
				display: inline-block;
				margin-right: 20px;

				& img {
					width: 24px;
					height: 24px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}

			& .label {
				font-size: 13px;
				color: #808080;
				font-style: italic;
			}
		}
	}
`;

interface IStakings {
	data: any[];
}
const StakingTab = ({ data }: IStakings) => (
	<Style>
		{data.map((item: any) => (
			<div className="item" key={item._id}>
				<p>
					<strong>Total {item.currency?.symbol} Balances</strong>
				</p>

				<br />

				<div className="details">
					<div>
						<img alt="" src={item.currency?.iconUrl || defaultImage} />
						<p className="amount">
							<strong>{commas(item?.totalStakedAmount)}</strong>
						</p>
						<p className="label">Total Staked Amount</p>
					</div>
					<div>
						<p className="amount">
							<strong>{commas(item?.TotalStakedAmountUsdValue)} USD</strong>
						</p>
						<p className="label">Amount in USD</p>
					</div>
					<div>
						<p className="amount">
							{commas(item?.currency?.usdValue)} {item.currency.symbol} / USD
						</p>
						<p className="label">Token Price</p>
					</div>
				</div>
			</div>
		))}
	</Style>
);

export default StakingTab;
