import { Button, Modal, Divider, Popconfirm } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../methods/redux/hooks';
import { removeAdditionalPermission } from '../../methods/redux/reducers/roles';
import AdditionalPermissions from './AdditionalPermissions';

interface IRemovePersonalPermission {
	permissionName: any;
	member: any;
	close: any;
}
const PersonalPermission = ({
	permissionName,
	member,
	close,
}: IRemovePersonalPermission) => {
	const dispatch = useAppDispatch();

	const removePersonalPermission = () => {
		dispatch(
			removeAdditionalPermission({ userId: member?._id, name: permissionName })
		);
		close();
	};
	return (
		<Popconfirm
			title={`Are you sure you want to remove this permission from ${member?.fullName}`}
			onConfirm={() => removePersonalPermission()}
		>
			<button type="button" title="Click to Remove">
				{permissionName}
			</button>
		</Popconfirm>
	);
};

interface IViewAdminTeamMembersPermissions {
	member: any;
}

const ViewAdminTeamMembersPermissions = ({
	member,
}: IViewAdminTeamMembersPermissions) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Button type="link" onClick={() => setVisible(true)}>
				See {member.fullName}&apos;s Permissions
			</Button>

			<Modal
				visible={visible}
				footer={null}
				onCancel={() => setVisible(false)}
				title={`${member.fullName}'s Permissions`}
			>
				<p>
					<strong>Roles From Assigned Role</strong>
				</p>
				{member?.role?.permissions?.map((item: any) => (
					<p>{item}</p>
				))}
				{member?.permissions?.length > 0 && (
					<div>
						<Divider />
						<p>
							<strong>Personally Assigned Roles</strong>
						</p>
						{member?.permissions?.map((item: any) => (
							<PersonalPermission
								permissionName={item}
								member={member}
								close={() => setVisible(false)}
								key={item}
							/>
						))}
					</div>
				)}
				<br />
				<br />
				<AdditionalPermissions userId={member._id} />
			</Modal>
		</>
	);
};

export default ViewAdminTeamMembersPermissions;
