import { Divider, Pagination, Card } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import { getEarlyTerminationReport } from '../../../methods/redux/reducers/accountant-report';
import remakeQueryString from '../../../methods/utils/remake-query-string';
import TransactionItem from '../../../transactions/transaction-statments/TransactionItem';
import AccountantsDateSelector from '../DateSelector';
import DownloadCSV from '../DownloadCSV';

const Style = styled(Card)``;

const EarlyTerminationOfFixedSavings = () => {
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	const { earlyTermination } = useAppSelector(
		(store) => store.accountantsReport
	);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(
				getEarlyTerminationReport({
					...queryData,
				})
			);
		}
	}, [searchParams]);

	return (
		<Style>
			<h1>Early Termination of Fixed Savings</h1>

			<Divider />

			<AccountantsDateSelector onGetReport={updateQueryString} />

			{earlyTermination.record.itemsList.length > 0 && (
				<>
					<Divider />

					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={earlyTermination.record.paginator.itemCount}
						current={earlyTermination.record.paginator.currentPage}
						pageSize={earlyTermination.record.paginator.perPage}
					/>

					<br />

					<div>
						<DownloadCSV
							title="early-temination"
							csv={earlyTermination.record?.reportCSVFile}
						/>
					</div>

					<br />

					{earlyTermination.record.itemsList.map((item: any) => (
						<TransactionItem key={item._id} transaction={item} />
					))}

					<Divider />
					<Pagination
						onChange={(pageId) => updateQueryString({ pageId })}
						total={earlyTermination.record.paginator.itemCount}
						current={earlyTermination.record.paginator.currentPage}
						pageSize={earlyTermination.record.paginator.perPage}
					/>
				</>
			)}
		</Style>
	);
};

export default EarlyTerminationOfFixedSavings;
