import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NativeNumber from '../component/Numeral';
import { useAppDispatch, useAppSelector } from '../methods/redux/hooks';
import {
	retrieveNegativeAccountBalances,
	retrieveNegativeFlexibleSavingsBalances,
} from '../methods/redux/reducers/compliance';
import { getFixedTerminationRequests } from '../methods/redux/reducers/fixed-savings';
import { retrieveLoanRequestThatAreDue } from '../methods/redux/reducers/loan';

const Style = styled.div`
	& .notice {
		background-color: var(--red-color);
		color: white;
		padding: 5px;
		border-radius: 5px;
		margin-bottom: 5px;

		& a {
			color: #4fd52a;
		}
	}
`;

const UrgentNotifications = () => {
	const dispatch = useAppDispatch();

	const { negativeAccounts, negativeFlexibleAccounts } = useAppSelector(
		(store) => store.compliance
	);
	const { duePayments } = useAppSelector((store) => store.loan);
	const { terminationRequests } = useAppSelector((store) => store.fixedSavings);

	useEffect(() => {
		dispatch(retrieveNegativeAccountBalances({ perPage: 1 }));
		dispatch(retrieveNegativeFlexibleSavingsBalances({ perPage: 1 }));
		dispatch(getFixedTerminationRequests({ perPage: 1, status: 'requested' }));
		dispatch(retrieveLoanRequestThatAreDue({ perPage: 1 }));
	}, []);

	return (
		<Style>
			{negativeAccounts.paginator.itemCount > 0 && (
				<p className="notice">
					We have <NativeNumber value={negativeAccounts.paginator.itemCount} />{' '}
					account balances with negative values. Please{' '}
					<Link to="/compliance/negative-balances">click here</Link> to resolve.
				</p>
			)}

			{negativeFlexibleAccounts.paginator.itemCount > 0 && (
				<p className="notice">
					We have{' '}
					<NativeNumber value={negativeFlexibleAccounts.paginator.itemCount} />{' '}
					flexible savings with negative balances. Please{' '}
					<Link to="/compliance/negative-flexible-balances">click here</Link> to
					resolve.
				</p>
			)}

			{terminationRequests.paginator.itemCount > 0 && (
				<p className="notice">
					We have{' '}
					<NativeNumber value={terminationRequests.paginator.itemCount} /> Fixed
					Savings Termination request. Please{' '}
					<Link to="/core-modules/fixed-savings/termination">click here</Link>{' '}
					to attend.
				</p>
			)}

			{duePayments.paginator.itemCount > 0 && (
				<p className="notice">
					We have <NativeNumber value={duePayments.paginator.itemCount} /> loans
					that are due for payment. Please{' '}
					<Link to="/core-modules/loan-requests-due">click here</Link> to
					attend.
				</p>
			)}
		</Style>
	);
};

export default UrgentNotifications;
