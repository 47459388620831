import { Button, Card, Divider, Modal, Select, Switch } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

interface IChangeDefaultSwapToken {
	currentTokenId: any;
}

const ChangeDefaultSwapToken = ({
	currentTokenId,
}: IChangeDefaultSwapToken) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [swapFeeTokenId, setSwapFeeTokenId] = useState('');
	const { currencies } = useAppSelector((store) => store.currencies);

	const submit = () => {
		dispatch(updateInternalSettings({ swapFeeTokenId }));
		setVisible(false);
	};

	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Change Token
			</Button>

			<Modal
				onOk={submit}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Change Default Swap Fee Token"
			>
				<Select
					defaultValue={currentTokenId}
					style={{ width: '100%' }}
					onChange={(tokenId) => setSwapFeeTokenId(tokenId)}
				>
					{currencies.map((currency: any) => (
						<Select.Option value={currency._id} key={currency._id}>
							{currency.symbol}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</>
	);
};

const GeneralSwapSettings = () => {
	const dispatch = useAppDispatch();

	const { allowSwapping, swapUseTokenAsFee, swapFeeTokenId } = useAppSelector(
		(store) => store.internalSettings
	);

	const [state, setState] = useState({
		allowSwapping: false,
		swapUseTokenAsFee: false,
	});

	useEffect(() => {
		setState({ ...state, allowSwapping, swapUseTokenAsFee });
	}, [allowSwapping, swapUseTokenAsFee]);

	return (
		<Card>
			<h2>General Swap Settings</h2>
			<div>
				<p>Allow Swapping on Xend Finance</p>
				<Switch
					checked={state.allowSwapping}
					onChange={(value) => {
						setState({ ...state, allowSwapping: value });
						dispatch(updateInternalSettings({ allowSwapping: value }));
					}}
				/>
			</div>

			<Divider />
			<h2>Default Swap Token</h2>

			<div>
				<div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
					<img
						src={swapFeeTokenId.iconUrl}
						alt=""
						style={{ marginRight: 10, width: 20, height: 20 }}
					/>
					<p>{swapFeeTokenId.symbol}</p>
				</div>

				<ChangeDefaultSwapToken currentTokenId={swapFeeTokenId?._id} />
			</div>

			<br />

			<div>
				<Checkbox
					checked={state.swapUseTokenAsFee}
					onChange={(e) => {
						setState({ ...state, swapUseTokenAsFee: e.target.checked });
						dispatch(
							updateInternalSettings({ swapUseTokenAsFee: e.target.checked })
						);
					}}
				>
					Use {swapFeeTokenId.symbol} as currency for paying swap transaction
					fees.
				</Checkbox>
			</div>
		</Card>
	);
};

export default GeneralSwapSettings;
