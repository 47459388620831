import { createSlice } from '@reduxjs/toolkit';

const entryRx = createSlice({
	name: 'entry',
	initialState: {
		name: null,
	},
	reducers: {
		updateEntryName: (state, action) => {
			const store = state;
			store.name = action.payload;
			return store;
		},
	},
});

export const { updateEntryName } = entryRx.actions;

export default entryRx.reducer;
