import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface IRevertSavings {
	details: any;
}

const RevertSavings: FC<IRevertSavings> = ({ details }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);

	const update = async () => {
		setloading(true);
		try {
			const res: any = await baseuri.post(
				`admin/savings/stable-currency/fixed/revert`,
				{ fixedSavingsId: details?._id }
			);

			if (res.status === 200) {
				message.success('Reverted Successfully');
				setloading(false);
				setVisible(false);
			} else {
				message.info(res.data.message);
				setloading(false);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.message);
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)} type="primary">
				Revert
			</Button>
			<Modal
				width="350px"
				title="Revert this savings"
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={update}>
						<Button loading={loading} type="primary">
							Revert
						</Button>
					</Popconfirm>,
				]}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<div>
					This action will cancel this fixed savings and the capital of{' '}
					<strong>
						{details?.cacheCapital} {details?.currencyId?.symbol}
					</strong>{' '}
					return to the user wallet, transaction fee charged earlier is ignored
				</div>
			</Modal>
		</>
	);
};

export default RevertSavings;
