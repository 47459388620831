import {
	Popconfirm,
	Button,
	Divider,
	Input,
	Modal,
	Select,
	Switch,
} from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../methods/redux/hooks';
import {
	deleteRole,
	updateAdminRole,
} from '../../../methods/redux/reducers/roles';

interface IUpdateRole {
	data: any;
}

const UpdateRole = ({ data }: IUpdateRole) => {
	const dispatch = useAppDispatch();

	const { permissions } = useAppSelector((store) => store.roles);

	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		title: '',
		description: '',
		_id: '',
		isActive: false,
		slug: '',
		permissions: [],
	});

	useEffect(() => {
		setState({ ...state, ...data });
	}, [data]);

	const updateRole = () => {
		dispatch(updateAdminRole({ ...state, roleId: data._id }));
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Update</Button>

			<Modal
				visible={visible}
				title="Update Role"
				onCancel={() => setVisible(false)}
				footer={null}
			>
				<div>
					<p>Active Status</p>
					<Switch
						checked={state.isActive}
						onChange={(isActive) => setState({ ...state, isActive })}
					/>

					<br />
					<br />

					<p>Title</p>
					<Input
						value={state.title}
						onChange={(e) => setState({ ...state, title: e.target.value })}
					/>

					<br />
					<br />

					<p>Description</p>
					<Input.TextArea
						value={state.description}
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
					/>

					<br />
					<br />

					<p>Permissions</p>
					<Select
						size="large"
						mode="multiple"
						allowClear
						defaultValue={state.permissions}
						style={{ width: '100%' }}
						onChange={(e) => setState({ ...state, permissions: e })}
					>
						{permissions.map((item) => (
							<Select.Option key={item}>{item}</Select.Option>
						))}
					</Select>

					<br />
					<br />

					<Popconfirm title="Are you sure?" onConfirm={() => updateRole()}>
						<Button size="large" type="primary" block>
							Update Role
						</Button>
					</Popconfirm>
				</div>

				<Divider />

				<div>
					<Popconfirm
						title="Are you sure?"
						onConfirm={() => dispatch(deleteRole(data._id))}
					>
						<Button danger type="primary">
							Delete Role
						</Button>
					</Popconfirm>
				</div>
			</Modal>
		</>
	);
};

export default UpdateRole;
