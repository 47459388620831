import styled from 'styled-components';
import { Button, Card } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import QuickEmail from './QuickEmail';
import UpdateAdminComment from './UpdateAdminComment';
import AdminTransfer from '../AdminTransfer';
import SendStatement from './SendStatement';

const Section = styled(Card)`
	& .items {
		display: flex;
		position: relative;
		flex-wrap: wrap;
		gap: 1em;
		margin-top: 10px;
	}
`;

interface IUserQuickActions {
	details: any;
}
const UserQuickActions = ({ details }: IUserQuickActions) => {
	const navigate = useNavigate();
	const { id } = useParams();

	return (
		<Section>
			<strong>Quick Actions</strong>
			<div className="items">
				<QuickEmail userId={id} />

				<Button
					icon={<SolutionOutlined />}
					onClick={() => navigate(`/user-settings/${id}/info`)}
				>
					KYC Manual Upload
				</Button>

				<UpdateAdminComment userId={id} details={details} />

				{details?.profile?._id && <AdminTransfer details={details} />}

				{details?.profile && (
					<SendStatement
						userId={details?.profile?._id}
						userEmail={details?.profile?.email}
					/>
				)}
			</div>
		</Section>
	);
};

export default UserQuickActions;
