import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import NativeNumber from '../../component/Numeral';
import emailBaseUrl from '../../methods/emailBaseUrl';
import CreateNewsLetter from './CreateNewsLetter';

interface IEmailHeaderSection {
	setRefresh: Function;
}

const Style = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 50px;
	flex-wrap: wrap;

	& > div:first-child {
		display: flex;

		& > div {
			margin-right: 20px;
			font-size: 18px;

			& .label {
				font-style: italic;
				font-size: 14px;
			}
		}
	}
`;

const EmailHeaderSection: FC<IEmailHeaderSection> = ({ setRefresh }) => {
	const navigate = useNavigate();

	const [count, setCount] = useState({
		totalSubscribers: 0,
		totalUnSubscribers: 0,
	});
	const getUsersNumber = async () => {
		const res = await emailBaseUrl.get(`/v1/subscribers`);

		if (res.status === 200) {
			setCount(res?.data?.data);
		}
	};

	useEffect(() => {
		getUsersNumber();
	}, []);

	return (
		<Style>
			<div>
				<div>
					<p>
						<strong>
							<NativeNumber value={count?.totalSubscribers} />
						</strong>
					</p>
					<p className="label">Total Subscribers</p>
				</div>
				<div>
					<p>
						<strong>
							<NativeNumber value={count?.totalUnSubscribers} />
						</strong>
					</p>
					<p className="label">UnSubscribed Users</p>
				</div>
			</div>

			<div>
				<CreateNewsLetter setRefresh={setRefresh} />
				<Button
					size="large"
					type="primary"
					style={{ marginLeft: 10 }}
					onClick={() => {
						navigate('/alerts/email-newsletter-builder');
					}}
				>
					Use Builder
				</Button>
			</div>
		</Style>
	);
};

export default EmailHeaderSection;
