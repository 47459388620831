import { Button } from 'antd';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';
import { deleteSwapProtocol } from '../../methods/redux/reducers/swap';
import EditSwapProtocol from './EditSwapProtocol';

interface ISwapProtocolItem {
	protocol: any;
}

const Styles = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 5px;
`;

const SwapProtocolItem = ({ protocol }: ISwapProtocolItem) => {
	const dispatch = useAppDispatch();

	const { swapDefaultProtocol } = useAppSelector(
		(store) => store.internalSettings
	);

	const deleteProtocol = () => {
		dispatch(deleteSwapProtocol({ swapProtocolId: protocol._id }));
	};

	const setDefaultSwapProtocol = () => {
		dispatch(updateInternalSettings({ swapDefaultProtocol: protocol._id }));
	};

	return (
		<Styles>
			<div>
				<p>{protocol.name}</p>
			</div>
			<div>
				<p>{protocol.blockchainNetwork?.abbreviation}</p>
			</div>
			<div>
				{protocol._id === swapDefaultProtocol?._id ? (
					'Default'
				) : (
					<Button size="small" onClick={setDefaultSwapProtocol}>
						Set as default
					</Button>
				)}
			</div>
			<div>
				<EditSwapProtocol protocol={protocol} />
				<Button danger size="small" onClick={deleteProtocol}>
					Delete
				</Button>
			</div>
		</Styles>
	);
};

export default SwapProtocolItem;
